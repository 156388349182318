import type { ObjectSchema } from 'yup';
import { string, object, number } from 'yup';
import type { ScenarioCopyDialogFormData } from './types.js';

export function getValidationSchema(
  isSuperAdmin: boolean,
  shared?: boolean
): ObjectSchema<ScenarioCopyDialogFormData | undefined> {
  return object<ScenarioCopyDialogFormData>({
    newName: string().required(),
    companyId: isSuperAdmin && !shared ? number().required() : number().notRequired()
  });
}
