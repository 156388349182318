import React, { useCallback, useMemo, memo } from 'react';
import type { ILinkDefaultProps, IPosition, INode } from '@mrblenny/react-flow-chart';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { ActiveLinkStatus, LinkColor } from '../../../../../models/scenarios/customLink.js';
import CustomArrowHead from './CustomArrowHead/index.js';

import { toSvgPosition, getCustomCurveControlPoints, getArrowHeadData } from './utils.js';
import useStyles from './styles.js';

import { ScenarioChartSelectors } from '../../../../../selectors/index.js';
import type IntentNodeProperties from 'app/models/intents/intentNodeProperties.js';

const FirstPosHeight = 11;
const PosHeight = 30;

// NOTE: Most of this component is copy-pasted from react-flow-chart sources
const CustomLink: React.FC<ILinkDefaultProps> = ({
  className,
  config,
  link,
  startPos,
  endPos,
  isHovered,
  isSelected,
  onLinkMouseEnter,
  onLinkMouseLeave,
  onLinkClick
}) => {
  const nodes = useSelector(ScenarioChartSelectors.chartNodes);

  const StartPositinLinkedScenario: IPosition = useMemo(() => {
    if (nodes) {
      const nodesArray = Object.values(nodes);
      if (nodesArray.length > 0) {
        const nodeFiltred: INode<IntentNodeProperties>[] = nodesArray?.filter(
          (x: INode<IntentNodeProperties, undefined>) => x.id == link.from.nodeId
        );
        if (nodeFiltred.length > 0) {
          const firstElem = nodeFiltred.shift();
          let offSet = 0;
          if (firstElem?.properties.linkedScenarios && firstElem?.properties.linkedScenarios.length > 0)
            offSet = firstElem?.properties.linkedScenarios.length;
          offSet = offSet < 5 ? offSet : 4;
          return {
            x: startPos.x,
            y: startPos.y + (offSet == 1 ? FirstPosHeight : PosHeight) * offSet * -1
          };
        }
      }
    }
    return {
      x: startPos.x,
      y: startPos.y
    };
  }, [nodes, startPos.x, startPos.y, link.from.nodeId]);

  const classes = useStyles();

  const { properties } = link;
  const linkColor = useMemo(() => {
    switch (properties?.linkStatus) {
      case ActiveLinkStatus.Correct:
        return LinkColor.Correct;
      case ActiveLinkStatus.Incorrect:
        return LinkColor.Incorrect;
      default:
        return LinkColor.Default;
    }
  }, [properties]);
  const [startControlPoint, endControlPoint] = useMemo(
    () => getCustomCurveControlPoints(StartPositinLinkedScenario, endPos),
    [StartPositinLinkedScenario, endPos]
  );
  const [midPoint, midAngle] = useMemo(
    () => getArrowHeadData(StartPositinLinkedScenario, startControlPoint, endControlPoint, endPos),
    [StartPositinLinkedScenario, startControlPoint, endControlPoint, endPos]
  );

  // the four-point Bezier curve, built into SVG
  const lineSvgDef = useMemo(
    () =>
      `M${toSvgPosition(StartPositinLinkedScenario)} C ${toSvgPosition(startControlPoint)} ${toSvgPosition(
        endControlPoint
      )} ${toSvgPosition(endPos)}`,
    [StartPositinLinkedScenario, startControlPoint, endControlPoint, endPos]
  );
  const onMouseEnter = useCallback(() => {
    onLinkMouseEnter({ config, linkId: link.id });
  }, [onLinkMouseEnter, config, link.id]);
  const onMouseLeave = useCallback(() => {
    onLinkMouseLeave({ config, linkId: link.id });
  }, [onLinkMouseLeave, config, link.id]);
  const onClick = useCallback(
    (e) => {
      onLinkClick({ config, linkId: link.id });
      e.stopPropagation();
    },
    [config, link.id, onLinkClick]
  );
  return (
    <svg className={classNames(classes.root, className)}>
      <circle r="4" cx={StartPositinLinkedScenario.x} cy={StartPositinLinkedScenario.y} fill={linkColor} />
      {/* Main line */}
      <path d={lineSvgDef} stroke={linkColor} strokeWidth="3" fill="none" />
      {/* Arrowhead in the middle */}
      <CustomArrowHead translateTo={midPoint} rotateDeg={midAngle} fill={linkColor} />
      {/* Thick line to make selection easier */}
      <path
        d={lineSvgDef}
        stroke={linkColor}
        strokeWidth="20"
        fill="none"
        strokeLinecap="round"
        strokeOpacity={isHovered || isSelected ? 0.1 : 0}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      />
      <circle r="4" cx={endPos.x} cy={endPos.y} fill={linkColor} />
    </svg>
  );
};

export default memo(CustomLink);
