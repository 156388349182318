import { createAction } from '@reduxjs/toolkit';

import type { LoadMode } from 'app/store/types.js';
import type {
  CourseDto,
  CreateCourseCommand,
  CreateScheduledCourseCommand,
  ScheduledCourseAssignmentCommand,
  ScheduledCourseDto,
  UpdateCourseCommand,
  UserDto,
  UserGroupDto
} from '@/generated-api/index.js';
import { courseManagementSlice } from 'app/components/CourseManagement/store/courseManagementReducer.js';
import type { CompanyDepartmentDto } from '@/generated-api/scenes/api.js';

export const enum Type {
  CREATE_COURSE = 'CREATE_COURSE',
  DELETE_COURSE = 'DELETE_COURSE',
  REQUEST_COURSE = 'REQUEST_COURSE',
  CREATE_COURSE_SCENARIO = 'CREATE_COURSE_SCENARIO',
  DELETE_COURSE_SCENARIO = 'DELETE_COURSE_SCENARIO',
  CREATE_SCHEDULED_COURSE = 'CREATE_SCHEDULED_COURSE',
  DELETE_SCHEDULED_COURSE = 'DELETE_SCHEDULED_COURSE',
  UPDATE_SCHEDULED_COURSE = 'UPDATE_SCHEDULED_CORUSE',
  REQUEST_SCHEDULED_COURSES = 'REQUEST_SCHEDULED_COURSES',
  REQUEST_COMPANY_DEPARTMENTS = 'REQUEST_COMPANY_DEPARTMENTS',
  CREATE_SCHEDULED_COURSE_USER = 'CREATE_SCHEDULED_COURSE_USER',
  DELETE_SCHEDULED_COURSE_USER = 'DELETE_SCHEDULED_COURSE_USER',
  CREATE_SCHEDULED_COURSE_USER_GROUP = 'CREATE_SCHEDULED_COURSE_USER_GROUP',
  DELETE_SCHEDULED_COURSE_USER_GROUP = 'DELETE_SCHEDULED_COURSE_USER_GROUP',
  REQUEST_SCHEDULED_COURSE_AVAILABLE_USERS = 'REQUEST_SCHEDULED_COURSE_AVAILABLE_USERS',
  REQUEST_SCHEDULED_COURSE_ALL_ASSIGNED_USERS = 'REQUEST_SCHEDULED_COURSE_ALL_ASSIGNED_USERS',
  REQUEST_SCHEDULED_COURSE_AVAILABLE_USER_GROUPS = 'REQUEST_SCHEDULED_COURSE_AVAILABLE_USER_GROUPS'
}

const prefix = courseManagementSlice.name;

export const requestCompanyDepartments = createAction(
  Type.REQUEST_COMPANY_DEPARTMENTS,
  (companyId: number, onError: () => void, onSuccess: (departments: CompanyDepartmentDto[]) => void) => ({
    payload: {
      companyId,
      onError,
      onSuccess
    }
  })
);

export const requestCourse = createAction(
  Type.REQUEST_COURSE,
  (courseId: number, onSuccess?: (course: CourseDto) => void) => ({
    payload: {
      courseId,
      onSuccess
    }
  })
);

export const loadCourses = createAction<{ loadMode: LoadMode; companyId?: number }>(`${prefix}/loadCourses`);

export const updateCourse = createAction(`${prefix}/updateCourses`, (courseId: number, data: UpdateCourseCommand) => ({
  payload: {
    courseId,
    data
  }
}));

export const deleteCourse = createAction(Type.DELETE_COURSE, (courseId: number, onSuccess: () => void) => ({
  payload: {
    courseId,
    onSuccess
  }
}));

export const createCourse = createAction(
  Type.CREATE_COURSE,
  (data: CreateCourseCommand, onSuccess: (course: CourseDto) => void) => ({
    payload: {
      data,
      onSuccess
    }
  })
);

export const createCourseScenario = createAction(
  Type.CREATE_COURSE_SCENARIO,
  (courseId: number, sceneId: number, onSuccess: () => void) => ({
    payload: {
      courseId,
      sceneId,
      onSuccess
    }
  })
);

export const deleteCourseScenario = createAction(
  Type.DELETE_COURSE_SCENARIO,
  (courseId: number, sceneId: number, onSuccess: () => void) => ({
    payload: {
      courseId,
      sceneId,
      onSuccess
    }
  })
);

export const createScheduledCourse = createAction(
  Type.CREATE_SCHEDULED_COURSE,
  (courseId: number, data: CreateScheduledCourseCommand, onSuccess: (scheduledCourse: ScheduledCourseDto) => void) => ({
    payload: {
      data,
      courseId,
      onSuccess
    }
  })
);

export const deleteScheduledCourse = createAction(
  Type.DELETE_SCHEDULED_COURSE,
  (courseId: number, scheduledCourseId: number, onSuccess: () => void) => ({
    payload: {
      courseId,
      scheduledCourseId,
      onSuccess
    }
  })
);

// export const updateScheduledCourse = createAction

export const requestScheduledCourseAllAssignedUsers = createAction(
  Type.REQUEST_SCHEDULED_COURSE_ALL_ASSIGNED_USERS,
  (scheduledCourseId: number, onSuccess: (users: UserDto[]) => void) => ({
    payload: {
      scheduledCourseId,
      onSuccess
    }
  })
);

export const requestScheduledCourseAvailableUsers = createAction(
  Type.REQUEST_SCHEDULED_COURSE_AVAILABLE_USERS,
  (courseId: number, scheduledCourseId: number, onSuccess: (users: UserDto[]) => void) => ({
    payload: {
      courseId,
      scheduledCourseId,
      onSuccess
    }
  })
);

export const requestScheduledCourseAvailableUserGroups = createAction(
  Type.REQUEST_SCHEDULED_COURSE_AVAILABLE_USER_GROUPS,
  (courseId: number, scheduledCourseId: number, onSuccess: (userGroups: UserGroupDto[]) => void) => ({
    payload: {
      courseId,
      scheduledCourseId,
      onSuccess
    }
  })
);

export const createScheduledCourseUser = createAction(
  Type.CREATE_SCHEDULED_COURSE_USER,
  (courseId, scheduledCourseId: number, data: ScheduledCourseAssignmentCommand, onSuccess: () => void) => ({
    payload: {
      data,
      courseId,
      scheduledCourseId,
      onSuccess
    }
  })
);

export const deleteScheduledCourseUser = createAction(
  Type.DELETE_SCHEDULED_COURSE_USER,
  (courseId: number, scheduledCourseId: number, userId: number, onSuccess: () => void) => ({
    payload: {
      userId,
      courseId,
      scheduledCourseId,
      onSuccess
    }
  })
);

export const createScheduledCourseUserGroup = createAction(
  Type.CREATE_SCHEDULED_COURSE_USER_GROUP,
  (courseId, scheduledCourseId: number, data: ScheduledCourseAssignmentCommand, onSuccess: () => void) => ({
    payload: {
      data,
      courseId,
      scheduledCourseId,
      onSuccess
    }
  })
);

export const deleteScheduledCourseUserGroup = createAction(
  Type.DELETE_SCHEDULED_COURSE_USER_GROUP,
  (courseId: number, scheduledCourseId: number, userGroupId: number, onSuccess: () => void) => ({
    payload: {
      courseId,
      userGroupId,
      scheduledCourseId,
      onSuccess
    }
  })
);

export const requestScheduledCourses = createAction(Type.REQUEST_SCHEDULED_COURSES);

export * from 'app/components/CourseManagement/store/courseManagementReducer.js';
