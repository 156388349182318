import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Card, CardContent, Box, Button, Grid, Typography } from '@material-ui/core';

import { generatePath, Route, Switch, useParams } from 'react-router';

import useStyles from './useStyles.js';
import { DateFormat } from 'app/app.constants.js';
import { CourseManagementRoutes } from 'app/utils/routes.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import NavigationTab from 'app/components/NavigationTab.js';
import NavigationTabs from 'app/components/NavigationTabs.js';
import Detail from 'app/components/CourseManagement/Details/Detail.js';
import DetailsContainer from 'app/components/CourseManagement/Details/DetailsContainer.js';
import { courseById } from 'app/components/CourseManagement/store/courseManagementSelectors.js';
import ScheduledCourseUsersList from 'app/components/CourseManagement/ScheduledCourse/ScheduledCourseUsersList.js';
import ScheduledCourseUserGroupsList from 'app/components/CourseManagement/ScheduledCourse/ScheduledCourseUserGroupsList.js';
import ScheduledCourseNotifications from '../ScheduledCourse/Notifications/ScheduledCourseNotifications.js';
import { ChevronLeft } from '@material-ui/icons';
import UpdateScheduledCourseDialog from '../ScheduledCourse/UpdateScheduledCourseDialog.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const ScheduledCourseCard: React.FC = () => {
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const formatDate = useFormatDate();

  const { courseId, scheduledCourseId } = useParams<{ courseId: string; scheduledCourseId: string }>();

  const course = useSelector((state) => courseById(state, courseId));

  const courseSchedulePath = generatePath(CourseManagementRoutes.Schedule, { courseId });

  const scheduledCoursePath = generatePath(CourseManagementRoutes.ScheduledCourse, { courseId, scheduledCourseId });

  const scheduledCourseUserGroupsPath = generatePath(CourseManagementRoutes.ScheduledCourseUserGroups, {
    courseId,
    scheduledCourseId
  });

  const scheduledCourseNotificationsPath = generatePath(CourseManagementRoutes.ScheduledCourseNotifications, {
    courseId,
    scheduledCourseId
  });

  const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);

  if (!course) return null;

  const scheduledCourse = course.scheduledCourses.find((c) => c.id.toString() === scheduledCourseId); // TODO Separate reducer?

  if (!scheduledCourse) return null;

  return (
    <>
      <Card square classes={{ root: classes.card }}>
        <Box paddingX={2} marginBottom={2}>
          <Button startIcon={<ChevronLeft />} variant="text" color="secondary" component={Link} to={courseSchedulePath}>
            {translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.backTo))} {course?.title ?? ''}
          </Button>
        </Box>
        <Box paddingX={2} marginBottom={2}>
          <Typography variant="h4">
            {translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourse))}
          </Typography>
          <Typography variant="caption">{course?.title}</Typography>
        </Box>
        <Box paddingX={2} marginBottom={2}>
          <DetailsContainer>
            <Grid container spacing={2}>
              <Grid item container spacing={4}>
                <Grid item>
                  <Detail
                    label={translate(
                      nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.department)
                    )}
                  >
                    {scheduledCourse.departmentName ??
                      translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.defaultDepartment))}
                  </Detail>
                </Grid>
                <Grid item>
                  <Detail
                    label={translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.startDate))}
                  >
                    {formatDate(scheduledCourse.startDate)}
                  </Detail>
                </Grid>
                <Grid item>
                  <Detail
                    label={translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.endDate))}
                  >
                    {formatDate(scheduledCourse.endDate)}
                  </Detail>
                </Grid>
                <Grid item>
                  <Detail label={translate(nameof.full<I18nCourseManagementNs>((n) => n.table.repeat))}>Never</Detail>
                </Grid>
              </Grid>
              <Grid item container direction="row" justifyContent="space-between">
                <Grid item container spacing={4} xs={10}>
                  <Grid item>
                    <Detail
                      label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.created))}
                    >
                      {formatDate(scheduledCourse.createdDate, DateFormat.Time)}
                    </Detail>
                  </Grid>
                  <Grid item>
                    <Detail
                      label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.createdBy))}
                    >
                      {scheduledCourse.createdByUserName ?? 'Unknown'}
                    </Detail>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={() => setUpdateDialogOpen(true)}>
                    {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.edit))}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DetailsContainer>
        </Box>
        <Box paddingX={2}>
          <NavigationTabs className={classes.tabs}>
            <NavigationTab
              label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.users))}
              value={scheduledCoursePath}
            />
            <NavigationTab
              label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.groups))}
              value={scheduledCourseUserGroupsPath}
            />
            <NavigationTab
              label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.notifications))}
              value={scheduledCourseNotificationsPath}
            />
          </NavigationTabs>
        </Box>
        <CardContent classes={{ root: classes.cardContentRoot }}>
          <Switch>
            <Route exact path={CourseManagementRoutes.ScheduledCourse}>
              <ScheduledCourseUsersList course={course} courseId={courseId} scheduledCourse={scheduledCourse} />
            </Route>
            <Route exact path={CourseManagementRoutes.ScheduledCourseUserGroups}>
              <ScheduledCourseUserGroupsList course={course} courseId={courseId} scheduledCourse={scheduledCourse} />
            </Route>
            <Route exact path={CourseManagementRoutes.ScheduledCourseNotifications}>
              <ScheduledCourseNotifications scheduledCourse={scheduledCourse} />
            </Route>
          </Switch>
        </CardContent>
      </Card>
      <UpdateScheduledCourseDialog
        scheduledCourse={scheduledCourse}
        onClose={() => setUpdateDialogOpen(false)}
        open={updateDialogOpen}
      />
    </>
  );
};

export default ScheduledCourseCard;
