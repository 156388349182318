import type { QualityLevelColorType } from 'app/models/common/QualityLevel.js';
import { QualityLevelColor } from 'app/models/common/QualityLevel.js';
import { QualityLevel } from '@/generated-api/index.js';

const MapColorToEnum = (color?: string): QualityLevel => {
  const mappedColor = color as QualityLevelColorType[keyof QualityLevelColorType];

  switch (mappedColor) {
    case '#FFEB3B':
    case '#F44336':
    case '#808080':
    case '#4CAF50': {
      for (const quality in QualityLevelColor) {
        const qualityEnum = +quality as QualityLevel;

        if (QualityLevelColor[qualityEnum] === mappedColor) {
          if (qualityEnum in QualityLevel) {
            return qualityEnum;
          }
        }
      }
    }

    default:
      return QualityLevel.NUMBER_0;
  }
};

const MapEnumToColor = (color?: QualityLevel): string => {
  return QualityLevelColor[color as QualityLevel] ?? '';
};

export { MapColorToEnum, MapEnumToColor };
