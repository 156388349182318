import type { I18nGlobalAssetsNs } from './interfaces.js';

export const GlobalAssetsDictionaryEn: I18nGlobalAssetsNs = {
  globalAssetsLibrary: {
    selectLanguage: 'Select language',
    language: 'Language',
    freeTextSearch: 'Free text search',
    search: 'Search',
    create: 'Create',
    showAll: 'Show all',
    exportData: 'Export data',
    importData: 'Import data'
  },
  categoryList: {
    categoryListIsEmpty: 'Category list is empty',
    noEntriesFound: 'No entries found',
    deletedOrganization: 'Deleted Organization',
    shared: 'Shared'
  },
  categoryCard: {
    intents: 'Intents',
    filter: 'Filter'
  },
  createCategoryDialog: {
    newCategory: 'New Category',
    categoryTitle: 'Category title',
    sharedCategory: 'Shared Category',
    createCategory: 'Create Category'
  },
  deleteCategoryDialog: {
    deleteCategory: 'Delete Category',
    confirmationText: 'Are you sure you want to delete category'
  },
  noCategoryData: {
    selectaCategory: 'Select a Category',
    anErrorOccurred: 'An error occurred'
  },
  rightPane: {
    categoryData: 'Category data',
    createdBy: 'Created by',
    createdDate: 'Created date',
    intentsCount: 'Intents count',
    admin: 'Admin',
    sharedWith: 'Shared with',
    intentData: 'Intent data'
  },
  templateConnections: {
    unableToLoadConnectionsFor: 'Unable to load connections for',
    admin: 'Admin',
    noConnectionsFound: 'No connections found',
    createdBy: 'Created by',
    createdDate: 'Created date',
    samplesCount: 'Samples count',
    connections: 'Connections'
  },
  addIntentDialog: {
    addSample: 'Add sample',
    sampleText: 'Sample text',
    enterSampleText: 'Enter sample text'
  },
  deleteIntentDialog: {
    anErrorOccurred: 'An error occurred',
    deleteSample: 'Delete sample',
    confirmationText: 'Are you sure you want to delete sample'
  },
  deleteTemplateDialog: {
    anErrorOccurred: 'An error occurred',
    deleteIntent: 'Delete Intent',
    confirmationText: 'Are you sure you want to delete'
  },
  editableTemplateTitle: {
    thisFieldIsRequired: 'This field is required',
    anErrorOccurred: 'An error occurred'
  },
  createTemplateDialog: {
    newIntent: 'New Intent',
    intentTitle: 'Intent title'
  },
  templateActions: {
    createIntent: 'Create Intent'
  },
  templatesList: {
    noTemplatesAdded: 'No templates added',
    noTargetOrganizationFound: 'No target Organization found',
    intent: 'Intent',
    copiedTo: 'Copied to',
    noIntentsAdded: 'No Intents added',
    addSample: 'Add sample',
    deleteIntent: 'Delete Intent',
    copyIntentToOrganization: 'Copy intent to organization',
    copyIntentId: 'Copy Intent ID',
    and: 'and',
    more: 'more'
  },
  moveTemplateConfirmDialog: {
    title: 'Move Intent',
    confirmationText: 'Are you sure you want to move the Intent to this Category? This will merge it with another Intent with the same name.'
  }
};

export const GlobalAssetsDictionaryDe: I18nGlobalAssetsNs = {
  globalAssetsLibrary: {
    selectLanguage: 'Sprache auswählen',
    language: 'Sprache',
    freeTextSearch: 'Freitextsuche',
    search: 'Suchen',
    create: 'Erstellen',
    showAll: 'Alle anzeigen',
    exportData: 'Daten exportieren',
    importData: 'Daten importieren'
  },
  categoryList: {
    categoryListIsEmpty: 'Kategorieliste ist leer',
    noEntriesFound: 'Keine Einträge gefunden',
    deletedOrganization: 'Gelöschte Organisation',
    shared: 'Geteilt'
  },
  categoryCard: {
    intents: 'Absichten',
    filter: 'Filter'
  },
  createCategoryDialog: {
    newCategory: 'Neue Kategorie',
    categoryTitle: 'Kategorietitel',
    sharedCategory: 'Geteilte Kategorie',
    createCategory: 'Kategorie erstellen'
  },
  deleteCategoryDialog: {
    deleteCategory: 'Kategorie löschen',
    confirmationText: 'Sind Sie sicher, dass Sie die Kategorie löschen möchten'
  },
  noCategoryData: {
    selectaCategory: 'Wählen Sie eine Kategorie',
    anErrorOccurred: 'Ein Fehler ist aufgetreten'
  },
  rightPane: {
    categoryData: 'Kategoriedaten',
    createdBy: 'Erstellt von',
    createdDate: 'Erstellungsdatum',
    intentsCount: 'Anzahl der Absichten',
    admin: 'Admin',
    sharedWith: 'Geteilt mit',
    intentData: 'Absichtsdaten'
  },
  templateConnections: {
    unableToLoadConnectionsFor: 'Verbindungen konnten nicht geladen werden für',
    admin: 'Admin',
    noConnectionsFound: 'Keine Verbindungen gefunden',
    createdBy: 'Erstellt von',
    createdDate: 'Erstellungsdatum',
    samplesCount: 'Anzahl der Beispiele',
    connections: 'Verbindungen'
  },
  addIntentDialog: {
    addSample: 'Beispiel hinzufügen',
    sampleText: 'Beispieltext',
    enterSampleText: 'Geben Sie den Beispieltext ein'
  },
  deleteIntentDialog: {
    anErrorOccurred: 'Ein Fehler ist aufgetreten',
    deleteSample: 'Beispiel löschen',
    confirmationText: 'Sind Sie sicher, dass Sie das Beispiel löschen möchten'
  },
  deleteTemplateDialog: {
    anErrorOccurred: 'Ein Fehler ist aufgetreten',
    deleteIntent: 'Absicht löschen',
    confirmationText: 'Sind Sie sicher, dass Sie löschen möchten'
  },
  editableTemplateTitle: {
    thisFieldIsRequired: 'Dieses Feld ist erforderlich',
    anErrorOccurred: 'Ein Fehler ist aufgetreten'
  },
  createTemplateDialog: {
    newIntent: 'Neue Absicht',
    intentTitle: 'Absichtstitel'
  },
  templateActions: {
    createIntent: 'Absicht erstellen'
  },
  templatesList: {
    noTemplatesAdded: 'Keine Vorlagen hinzugefügt',
    noTargetOrganizationFound: 'Keine Zielorganisation gefunden',
    intent: 'Absicht',
    copiedTo: 'Kopiert nach',
    noIntentsAdded: 'Keine Absichten hinzugefügt',
    addSample: 'Beispiel hinzufügen',
    deleteIntent: 'Absicht löschen',
    copyIntentToOrganization: 'Absicht in Organisation kopieren',
    copyIntentId: 'Absichts-ID kopieren',
    and: 'und',
    more: 'mehr'
  },
  moveTemplateConfirmDialog: {
    title: 'Absicht verschieben',
    confirmationText: 'Sind Sie sicher, dass Sie die Absicht in diese Kategorie verschieben möchten? Dies wird sie mit einer anderen Absicht mit demselben Namen zusammenführen.'
  }  
};

export const GlobalAssetsDictionarySw: I18nGlobalAssetsNs = {
  globalAssetsLibrary: {
    selectLanguage: 'Select language',
    language: 'Language',
    freeTextSearch: 'Free text search',
    search: 'Search',
    create: 'Create',
    showAll: 'Show all',
    exportData: 'Export data',
    importData: 'Import data'
  },
  categoryList: {
    categoryListIsEmpty: 'Category list is empty',
    noEntriesFound: 'No entries found',
    deletedOrganization: 'Deleted Organization',
    shared: 'Shared'
  },
  categoryCard: {
    intents: 'Intents',
    filter: 'Filter'
  },
  createCategoryDialog: {
    newCategory: 'New Category',
    categoryTitle: 'Category title',
    sharedCategory: 'Shared Category',
    createCategory: 'Create Category'
  },
  deleteCategoryDialog: {
    deleteCategory: 'Delete Category',
    confirmationText: 'Are you sure you want to delete category'
  },
  noCategoryData: {
    selectaCategory: 'Select a Category',
    anErrorOccurred: 'An error occurred'
  },
  rightPane: {
    categoryData: 'Category data',
    createdBy: 'Created by',
    createdDate: 'Created date',
    intentsCount: 'Intents count',
    admin: 'Admin',
    sharedWith: 'Shared with',
    intentData: 'Intent data'
  },
  templateConnections: {
    unableToLoadConnectionsFor: 'Unable to load connections for',
    admin: 'Admin',
    noConnectionsFound: 'No connections found',
    createdBy: 'Created by',
    createdDate: 'Created date',
    samplesCount: 'Samples count',
    connections: 'Connections'
  },
  addIntentDialog: {
    addSample: 'Add sample',
    sampleText: 'Sample text',
    enterSampleText: 'Enter sample text'
  },
  deleteIntentDialog: {
    anErrorOccurred: 'An error occurred',
    deleteSample: 'Delete sample',
    confirmationText: 'Are you sure you want to delete sample'
  },
  deleteTemplateDialog: {
    anErrorOccurred: 'An error occurred',
    deleteIntent: 'Delete Intent',
    confirmationText: 'Are you sure you want to delete'
  },
  editableTemplateTitle: {
    thisFieldIsRequired: 'This field is required',
    anErrorOccurred: 'An error occurred'
  },
  createTemplateDialog: {
    newIntent: 'New Intent',
    intentTitle: 'Intent title'
  },
  templateActions: {
    createIntent: 'Create Intent'
  },
  templatesList: {
    noTemplatesAdded: 'No templates added',
    noTargetOrganizationFound: 'No target Organization found',
    intent: 'Intent',
    copiedTo: 'Copied to',
    noIntentsAdded: 'No Intents added',
    addSample: 'Add sample',
    deleteIntent: 'Delete Intent',
    copyIntentToOrganization: 'Copy intent to organization',
    copyIntentId: 'Copy Intent ID',
    and: 'and',
    more: 'more'
  },
  moveTemplateConfirmDialog: {
    title: 'Move Intent',
    confirmationText: 'Are you sure you want to move the Intent to this Category? This will merge it with another Intent with the same name.'
  }
};

export const GlobalAssetsDictionaryNo: I18nGlobalAssetsNs = {
  globalAssetsLibrary: {
    selectLanguage: 'Velg språk',
    language: 'Språk',
    freeTextSearch: 'Fritekstsøk',
    search: 'Søk',
    create: 'Opprett',
    showAll: 'Vis alle',
    exportData: 'Eksporter data',
    importData: 'Importer data'
  },
  categoryList: {
    categoryListIsEmpty: 'Kategoriliste er tom',
    noEntriesFound: 'Ingen elementer funnet',
    deletedOrganization: 'Slettet Organisasjon',
    shared: 'Delt'
  },
  categoryCard: {
    intents: 'Intenter',
    filter: 'Filter'
  },
  createCategoryDialog: {
    newCategory: 'Ny Kategory',
    categoryTitle: 'Kategori-tittel',
    sharedCategory: 'Delt Kategori',
    createCategory: 'Opprett Kategori'
  },
  deleteCategoryDialog: {
    deleteCategory: 'Slett Kategori',
    confirmationText: 'Er du sikker på at du vil slette Kategorien'
  },
  noCategoryData: {
    selectaCategory: 'Velg en Kategori',
    anErrorOccurred: 'En feil oppstod'
  },
  rightPane: {
    categoryData: 'Kategoridata',
    createdBy: 'Opprettet av',
    createdDate: 'Opprettet dato',
    intentsCount: 'Intentantall',
    admin: 'Administrator',
    sharedWith: 'Delt med',
    intentData: 'Intentdata'
  },
  templateConnections: {
    unableToLoadConnectionsFor: 'Kan ikke laste koblinger for',
    admin: 'Administrator',
    noConnectionsFound: 'Ingen koblinger funnet',
    createdBy: 'Opprettet av',
    createdDate: 'Opprettet dato',
    samplesCount: 'Antall tekstfraser',
    connections: 'Koblinger'
  },
  addIntentDialog: {
    addSample: 'Legg til tekstfrase',
    sampleText: 'Tekstfrase',
    enterSampleText: 'Skriv inn tekstfrase'
  },
  deleteIntentDialog: {
    anErrorOccurred: 'En feil oppstod',
    deleteSample: 'Slett tekstfrase',
    confirmationText: 'Er du sikker på at du vil slette tekstfrase'
  },
  deleteTemplateDialog: {
    anErrorOccurred: 'En feil oppstod',
    deleteIntent: 'Slett Intent',
    confirmationText: 'Er du sikker på du vil slette'
  },
  editableTemplateTitle: {
    thisFieldIsRequired: 'Dette feltet er obligatorisk',
    anErrorOccurred: 'En feil oppstod'
  },
  createTemplateDialog: {
    newIntent: 'Ny Intent',
    intentTitle: 'Intent-tittel'
  },
  templateActions: {
    createIntent: 'Opprett Intent'
  },
  templatesList: {
    noTemplatesAdded: 'Ingen maler lagt til',
    noTargetOrganizationFound: 'Ingen mål-Organisasjon funnet',
    intent: 'Intent',
    copiedTo: 'Kopiert til',
    noIntentsAdded: 'Ingen Intenter lagt til',
    addSample: 'Legg til tekstfrase',
    deleteIntent: 'Slett Intent',
    copyIntentToOrganization: 'Kopier Intent til Organisasjon',
    copyIntentId: 'Kopier Intent-ID',
    and: 'og',
    more: 'flere'
  },
  moveTemplateConfirmDialog: {
    title: 'Flytt Intent',
    confirmationText: 'Er du sikker på at du vil flytte Intenten til denne kategorien? Dette vil sammenslå den med en annen Intent med samme navn.'
  }
};

export const GlobalAssetsDictionaryNl: I18nGlobalAssetsNs = {
  globalAssetsLibrary: {
    selectLanguage: 'Select language',
    language: 'Language',
    freeTextSearch: 'Free text search',
    search: 'Search',
    create: 'Create',
    showAll: 'Show all',
    exportData: 'Export data',
    importData: 'Import data'
  },
  categoryList: {
    categoryListIsEmpty: 'Category list is empty',
    noEntriesFound: 'No entries found',
    deletedOrganization: 'Deleted Organization',
    shared: 'Shared'
  },
  categoryCard: {
    intents: 'Intents',
    filter: 'Filter'
  },
  createCategoryDialog: {
    newCategory: 'New Category',
    categoryTitle: 'Category title',
    sharedCategory: 'Shared Category',
    createCategory: 'Create Category'
  },
  deleteCategoryDialog: {
    deleteCategory: 'Delete Category',
    confirmationText: 'Are you sure you want to delete category'
  },
  noCategoryData: {
    selectaCategory: 'Select a Category',
    anErrorOccurred: 'An error occurred'
  },
  rightPane: {
    categoryData: 'Category data',
    createdBy: 'Created by',
    createdDate: 'Created date',
    intentsCount: 'Intents count',
    admin: 'Admin',
    sharedWith: 'Shared with',
    intentData: 'Intent data'
  },
  templateConnections: {
    unableToLoadConnectionsFor: 'Unable to load connections for',
    admin: 'Admin',
    noConnectionsFound: 'No connections found',
    createdBy: 'Created by',
    createdDate: 'Created date',
    samplesCount: 'Samples count',
    connections: 'Connections'
  },
  addIntentDialog: {
    addSample: 'Add sample',
    sampleText: 'Sample text',
    enterSampleText: 'Enter sample text'
  },
  deleteIntentDialog: {
    anErrorOccurred: 'An error occurred',
    deleteSample: 'Delete sample',
    confirmationText: 'Are you sure you want to delete sample'
  },
  deleteTemplateDialog: {
    anErrorOccurred: 'An error occurred',
    deleteIntent: 'Delete Intent',
    confirmationText: 'Are you sure you want to delete'
  },
  editableTemplateTitle: {
    thisFieldIsRequired: 'This field is required',
    anErrorOccurred: 'An error occurred'
  },
  createTemplateDialog: {
    newIntent: 'New Intent',
    intentTitle: 'Intent title'
  },
  templateActions: {
    createIntent: 'Create Intent'
  },
  templatesList: {
    noTemplatesAdded: 'No templates added',
    noTargetOrganizationFound: 'No target Organization found',
    intent: 'Intent',
    copiedTo: 'Copied to',
    noIntentsAdded: 'No Intents added',
    addSample: 'Add sample',
    deleteIntent: 'Delete Intent',
    copyIntentToOrganization: 'Copy intent to organization',
    copyIntentId: 'Copy Intent ID',
    and: 'and',
    more: 'more'
  },
  moveTemplateConfirmDialog: {
    title: 'Move Intent',
    confirmationText: 'Are you sure you want to move the Intent to this Category? This will merge it with another Intent with the same name.'
  }
};

export const GlobalAssetsDictionaryIs: I18nGlobalAssetsNs = {
  globalAssetsLibrary: {
    selectLanguage: 'Select language',
    language: 'Language',
    freeTextSearch: 'Free text search',
    search: 'Search',
    create: 'Create',
    showAll: 'Show all',
    exportData: 'Export data',
    importData: 'Import data'
  },
  categoryList: {
    categoryListIsEmpty: 'Category list is empty',
    noEntriesFound: 'No entries found',
    deletedOrganization: 'Deleted Organization',
    shared: 'Shared'
  },
  categoryCard: {
    intents: 'Intents',
    filter: 'Filter'
  },
  createCategoryDialog: {
    newCategory: 'New Category',
    categoryTitle: 'Category title',
    sharedCategory: 'Shared Category',
    createCategory: 'Create Category'
  },
  deleteCategoryDialog: {
    deleteCategory: 'Delete Category',
    confirmationText: 'Are you sure you want to delete category'
  },
  noCategoryData: {
    selectaCategory: 'Select a Category',
    anErrorOccurred: 'An error occurred'
  },
  rightPane: {
    categoryData: 'Category data',
    createdBy: 'Created by',
    createdDate: 'Created date',
    intentsCount: 'Intents count',
    admin: 'Admin',
    sharedWith: 'Shared with',
    intentData: 'Intent data'
  },
  templateConnections: {
    unableToLoadConnectionsFor: 'Unable to load connections for',
    admin: 'Admin',
    noConnectionsFound: 'No connections found',
    createdBy: 'Created by',
    createdDate: 'Created date',
    samplesCount: 'Samples count',
    connections: 'Connections'
  },
  addIntentDialog: {
    addSample: 'Add sample',
    sampleText: 'Sample text',
    enterSampleText: 'Enter sample text'
  },
  deleteIntentDialog: {
    anErrorOccurred: 'An error occurred',
    deleteSample: 'Delete sample',
    confirmationText: 'Are you sure you want to delete sample'
  },
  deleteTemplateDialog: {
    anErrorOccurred: 'An error occurred',
    deleteIntent: 'Delete Intent',
    confirmationText: 'Are you sure you want to delete'
  },
  editableTemplateTitle: {
    thisFieldIsRequired: 'This field is required',
    anErrorOccurred: 'An error occurred'
  },
  createTemplateDialog: {
    newIntent: 'New Intent',
    intentTitle: 'Intent title'
  },
  templateActions: {
    createIntent: 'Create Intent'
  },
  templatesList: {
    noTemplatesAdded: 'No templates added',
    noTargetOrganizationFound: 'No target Organization found',
    intent: 'Intent',
    copiedTo: 'Copied to',
    noIntentsAdded: 'No Intents added',
    addSample: 'Add sample',
    deleteIntent: 'Delete Intent',
    copyIntentToOrganization: 'Copy intent to organization',
    copyIntentId: 'Copy Intent ID',
    and: 'and',
    more: 'more'
  },
  moveTemplateConfirmDialog: {
    title: 'Move Intent',
    confirmationText: 'Are you sure you want to move the Intent to this Category? This will merge it with another Intent with the same name.'
  }
};

export const GlobalAssetsDictionaryUk: I18nGlobalAssetsNs = {
  globalAssetsLibrary: {
    selectLanguage: 'Select language',
    language: 'Language',
    freeTextSearch: 'Free text search',
    search: 'Search',
    create: 'Create',
    showAll: 'Show all',
    exportData: 'Export data',
    importData: 'Import data'
  },
  categoryList: {
    categoryListIsEmpty: 'Category list is empty',
    noEntriesFound: 'No entries found',
    deletedOrganization: 'Deleted Organization',
    shared: 'Shared'
  },
  categoryCard: {
    intents: 'Intents',
    filter: 'Filter'
  },
  createCategoryDialog: {
    newCategory: 'New Category',
    categoryTitle: 'Category title',
    sharedCategory: 'Shared Category',
    createCategory: 'Create Category'
  },
  deleteCategoryDialog: {
    deleteCategory: 'Delete Category',
    confirmationText: 'Are you sure you want to delete category'
  },
  noCategoryData: {
    selectaCategory: 'Select a Category',
    anErrorOccurred: 'An error occurred'
  },
  rightPane: {
    categoryData: 'Category data',
    createdBy: 'Created by',
    createdDate: 'Created date',
    intentsCount: 'Intents count',
    admin: 'Admin',
    sharedWith: 'Shared with',
    intentData: 'Intent data'
  },
  templateConnections: {
    unableToLoadConnectionsFor: 'Unable to load connections for',
    admin: 'Admin',
    noConnectionsFound: 'No connections found',
    createdBy: 'Created by',
    createdDate: 'Created date',
    samplesCount: 'Samples count',
    connections: 'Connections'
  },
  addIntentDialog: {
    addSample: 'Add sample',
    sampleText: 'Sample text',
    enterSampleText: 'Enter sample text'
  },
  deleteIntentDialog: {
    anErrorOccurred: 'An error occurred',
    deleteSample: 'Delete sample',
    confirmationText: 'Are you sure you want to delete sample'
  },
  deleteTemplateDialog: {
    anErrorOccurred: 'An error occurred',
    deleteIntent: 'Delete Intent',
    confirmationText: 'Are you sure you want to delete'
  },
  editableTemplateTitle: {
    thisFieldIsRequired: 'This field is required',
    anErrorOccurred: 'An error occurred'
  },
  createTemplateDialog: {
    newIntent: 'New Intent',
    intentTitle: 'Intent title'
  },
  templateActions: {
    createIntent: 'Create Intent'
  },
  templatesList: {
    noTemplatesAdded: 'No templates added',
    noTargetOrganizationFound: 'No target Organization found',
    intent: 'Intent',
    copiedTo: 'Copied to',
    noIntentsAdded: 'No Intents added',
    addSample: 'Add sample',
    deleteIntent: 'Delete Intent',
    copyIntentToOrganization: 'Copy intent to organization',
    copyIntentId: 'Copy Intent ID',
    and: 'and',
    more: 'more'
  },
  moveTemplateConfirmDialog: {
    title: 'Move Intent',
    confirmationText: 'Are you sure you want to move the Intent to this Category? This will merge it with another Intent with the same name.'
  }
};
