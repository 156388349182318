import type { FC } from 'react';
import React from 'react';
import { IconButton, Input, InputAdornment, FormControl } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search.js';
import clsx from 'clsx';
import useStyles from '../styles.js';

import { useTranslation } from 'react-i18next';
import type { I18nScenarioSectionNs } from '@/i18n/dictionaries/interfaces.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';

export interface searchScenarioProps {
  search: (newVal: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

const SearchScenario: FC<searchScenarioProps> = ({ search }) => {
  const classes = useStyles();
  const [translate] = useTranslation(I18nNamespace.ScenarioSection);
  return (
    <FormControl className={clsx(classes.margin, classes.textField, classes.white)}>
      <Input
        onChange={search}
        id="standard-adornment-weight"
        placeholder={translate(nameof.full<I18nScenarioSectionNs>((l) => l.search))}
        classes={{ root: classes.white, underline: classes.inputClasses }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle visibility">
              <SearchIcon classes={{ root: classes.white }} />
            </IconButton>
          </InputAdornment>
        }
        aria-describedby="standard-weight-helper-text"
        inputProps={{
          'aria-label': 'weight'
        }}
      />
    </FormControl>
  );
};
export default SearchScenario;
