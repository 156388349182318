import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import type { CreateScheduledCallCommand as Model } from '@/generated-api/index.js';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { useDispatch, useSelector } from 'react-redux';
import { scenariosLoading, scenariosSelector } from 'app/selectors/scenarioSelectors.js';
import { getScenarios } from 'app/actions/scenarios/scenarioAction.js';
import { getUsers } from 'app/actions/userManagement/userManagementActions.js';
import * as userManagementSelectors from 'app/selectors/userManagementSelectors.js';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import useScheduledCallSchema from './schema.js';
import { ScheduledCallsClient } from 'app/apis/api.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import callManagementSlice from 'app/components/CallManagement/store/callManagementSlice.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import _ from 'lodash';
import type { Theme } from '@material-ui/core/styles/index.js';
import logger from 'app/utils/logger.js';
import { UserSelectors } from 'app/selectors/index.js';
import UpdateIcon from '@material-ui/icons/Update';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScheduledCallsNs } from '@/i18n/dictionaries/interfaces.js';

const { actions: callManagementActions } = callManagementSlice;

export type CreateCallDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    dateTimePicker: {
      width: '100%'
    },
    dateTimeContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    setNowButton: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      whiteSpace: 'nowrap',
      minWidth: '140px',
    },
    setNowIcon: {
      marginRight: theme.spacing(1),
    },
  });
});

function CreateCallDialog({ isOpen, onClose }: CreateCallDialogProps): JSX.Element {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScenarios());
    dispatch(getUsers());
  }, [dispatch]);

  const [submitState, setSubmitState] = useState<{
    data: Model;
    status: ProcessStatus;
  }>({
    status: ProcessStatus.Idle,
    data: {} as Model
  });

  const scenarios = useSelector(scenariosSelector);
  const isScenariosLoading = useSelector(scenariosLoading);

  const users = useSelector(userManagementSelectors.users);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const isUsersLoading = !useSelector(userManagementSelectors.usersLoaded);

  const currentUser = useSelector(UserSelectors.CurrentUserSelector);

  const [translate] = useTranslation([I18nNamespace.ScheduledCalls]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const schema = useScheduledCallSchema();

  const scenariosMenuItems = useMemo(
    () =>
      [
        <MenuItem key={0} value={0}>
          {translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.noScenarioSelected))}
        </MenuItem>
      ].concat(
        _.sortBy(scenarios, [(scenario) => scenario.name?.toLowerCase()]).map((scenario) => (
          <MenuItem key={scenario.id} value={scenario.id}>
            <Tooltip key={scenario.id} title={scenario.description || ''}>
              <span>{scenario.name}</span>
            </Tooltip>
          </MenuItem>
        ))
      ),
    [scenarios, translate]
  );

  const usersMenuItems = useMemo(
    () =>
      [
        <MenuItem key={0} value={0}>
          {translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.noUserSelected))}
        </MenuItem>
      ].concat(
        _.sortBy(users, [(user) => user.name?.toLowerCase()]).map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {`${user.name} ${isSuperAdmin ? ` (${user.companyName})` : ''}`}
          </MenuItem>
        ))
      ),
    [users, isSuperAdmin, translate]
  );

  const { control, handleSubmit, watch, setValue, errors, clearErrors, reset } = useForm<Model>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      phoneNumber: '',
      sceneId: 0,
      scheduledTime: moment().format('YYYY-MM-DDTHH:mm'),
      userId: 0
    }
  });

  const { userId, scheduledTime } = watch();

  const isCustomUserSelected = userId === 0 || userId === -1;
  const startNow = moment().isSameOrAfter(scheduledTime, 'minute');

  useEffect(() => {
    if (isCustomUserSelected) {
      return; // Don't change the phone number if custom user is selected
    }
    const user = users.find((u) => u.id === userId);
    if (user && user.phone) {
      setValue('phoneNumber', user.phone);
      clearErrors('phoneNumber');
    }
  }, [userId, isCustomUserSelected, users, setValue, clearErrors]);

  const unmountedRef = useUnmounted();

  useEffect(() => {
    if (submitState.status !== ProcessStatus.StartProcess) {
      return;
    }

    const submitData = async () => {
      try {
        setSubmitState((s) => ({
          ...s,
          status: ProcessStatus.Processing
        }));

        const user = users.find((u) => u.id === userId);

        const created = await ScheduledCallsClient.scheduledCallsCreate({
          ...submitState.data,
          userId: submitState.data.userId > 0 ? submitState.data.userId : null,
          targetName: user?.name ?? '',
          companyId: user?.companyId ?? currentUser.companyId
        });
        dispatch(callManagementActions.callCreated(created.data));

        onClose();
      } catch (e) {
        logger.error(e);
      } finally {
        if (!unmountedRef.current) {
          setSubmitState((s) => ({
            ...s,
            status: ProcessStatus.Idle
          }));
        }
      }
    };

    submitData();
  }, [dispatch, onClose, submitState, unmountedRef, users, userId, currentUser.companyId]);

  const onSubmit = handleSubmit((data) => {
    setSubmitState({
      status: ProcessStatus.StartProcess,
      data: schema.cast(data)
    });
  });

  const classes = useStyles();

  // Reset form when dialog is closed
  useEffect(() => {
    if (!isOpen) {
      reset({
        phoneNumber: '',
        sceneId: 0,
        scheduledTime: moment().format('YYYY-MM-DDTHH:mm'),
        userId: 0
      });
    }
  }, [isOpen, reset]);

  // Wrap onClose to reset form before closing
  const handleClose = () => {
    reset({
      phoneNumber: '',
      sceneId: 0,
      scheduledTime: moment().format('YYYY-MM-DDTHH:mm'),
      userId: 0
    });
    onClose();
  };

  const handleSetNow = () => {
    setValue('scheduledTime', moment().format('YYYY-MM-DDTHH:mm'), { shouldValidate: true });
  };

  return (
    <Dialog open={isOpen} maxWidth={'sm'} fullWidth onClose={handleClose}>
      <DialogTitle>{translate(nameof.full<I18nScheduledCallsNs>((n) => n.scheduleCall))}</DialogTitle>
      <DialogContent>
        <Box overflow={'hidden'} pb={4}>
          <form id="schedule-call-form" onSubmit={onSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      select
                      fullWidth
                      margin="normal"
                      label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.scenario))}
                      {...props}
                      disabled={isScenariosLoading}
                      inputRef={props.ref}
                      error={!!errors?.sceneId?.message}
                      helperText={errors?.sceneId?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.thisFieldIsRequired))}
                      placeholder={translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.selectaScenario))}
                    >
                      {scenariosMenuItems}
                    </TextField>
                  )}
                  control={control}
                  name={nameof.full<Model>((c) => c.sceneId)}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      margin="normal"
                      select
                      fullWidth
                      label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.user))}
                      {...props}
                      inputRef={props.ref}
                      disabled={isUsersLoading}
                      error={!!errors?.userId?.message}
                      helperText={errors?.userId?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.youMustSelectaUser))}
                      placeholder={translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.selectaUser))}
                    >
                      {usersMenuItems}
                    </TextField>
                  )}
                  control={control}
                  name={nameof.full<Model>((c) => c.userId)}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.phoneNumber))}
                      {...props}
                      inputRef={props.ref}
                      error={!!errors?.phoneNumber?.message}
                      helperText={errors?.phoneNumber?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.phoneNumberMissing))}
                    />
                  )}
                  control={control}
                  name={nameof.full<Model>((c) => c.phoneNumber)}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.dateTimeContainer}>
                  <Controller
                    render={(props) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        type="datetime-local"
                        label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.scheduledTime))}
                        {...props}
                        InputProps={{
                          classes: { root: classes.dateTimePicker }
                        }}
                        inputRef={props.ref}
                        error={!!errors?.scheduledTime?.message}
                        helperText={errors?.scheduledTime?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.thisFieldIsRequired))}
                      />
                    )}
                    control={control}
                    name={nameof.full<Model>((c) => c.scheduledTime)}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.setNowButton}
                    onClick={handleSetNow}
                    startIcon={<UpdateIcon className={classes.setNowIcon} />}
                  >
                    {translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.setToNow))}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Button
          color={'primary'}
          form="schedule-call-form"
          variant='contained'
          type={'submit'}
          disabled={submitState.status === ProcessStatus.Processing}
        >
          {submitState.status === ProcessStatus.Processing ? (
            <CircularProgress color="secondary" size={24} />
          ) : startNow ? (
            translate(nameof.full<I18nScheduledCallsNs>((n) => n.createCallForm.startNow))
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.confirm))
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCallDialog;
