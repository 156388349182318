import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  AccordionDetails,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Typography,
  Chip,
} from '@material-ui/core';
import { Add, Delete, Clear } from '@material-ui/icons';
import { makeStyles, styled } from '@material-ui/core/styles';
import { useDebounce } from 'use-debounce';
import Fuse from 'fuse.js';
import { produce } from 'immer';
import CustomAccordion from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/CustomAccordion.js';
import AddTemplate from './AddTemplate.js';
import IntentsInfoTooltip from './IntentsInfoTooltip.js';
import { IntentEditingActions } from 'app/actions/scenarios/intentEditingAction.js';
import type { IntentEditingFormData } from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/types.js';
import type { BlockDto, CompanyDto, GlobalAssetFullDto, GlobalAssetIntentDto } from '@/generated-api/index.js';
import {
  categoriesSelector,
  isGlobalAssetsLibraryStatusLoadingSelector
} from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySelectors.js';
import { currentChartScenarioLang } from 'app/selectors/scenarioSelectors.js';
import { userCompanyIdSelector } from 'app/selectors/userSelectors.js';
import { companiesSelector } from 'app/selectors/companiesSelector.js';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import { I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type TemplateId = GlobalAssetIntentDto['id'];

interface ExtendedGlobalAssetIntentDto extends GlobalAssetIntentDto {
  companyId?: number;
  companyName?: string;
  categoryId?: number;
  categoryTitle?: string;
  shared?: boolean;
}

const fieldName = nameof.full<BlockDto>((b) => b.globalAssetIntents);

const useStyles = makeStyles((theme) => {
  return {
    select: {
      whiteSpace: 'normal'
    },
    menu: {
      maxWidth: 400,
      maxHeight: 600
    },
    listItemRoot: {
      whiteSpace: 'normal'
    },
    sharedCategory: {
      color: 'forestgreen'
    },
    sharedIntent: {
      color: 'green'
    },
    compactListItemText: {
      margin: 0,
      '& .MuiListItemText-secondary': {
        marginTop: 2,
      },
    },
    compactSampleText: {
      marginLeft: 5,
      lineHeight: 1.2,
    },
    searchResultItem: {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
  };
});

const HighlightedText = styled('span')(({ theme }) => ({
  fontWeight: 'bold',
}));

const SharedText = styled('span')(({ theme }) => ({
  color: 'green',
}));

const highlightMatches = (text: string, searchTerm: string) => {
  if (!searchTerm) return text;
  const lowerText = text.toLowerCase();
  const lowerSearchTerm = searchTerm.toLowerCase();
  const parts = [];
  let lastIndex = 0;

  while (true) {
    const index = lowerText.indexOf(lowerSearchTerm, lastIndex);
    if (index === -1) break;

    if (index > lastIndex) {
      parts.push(text.slice(lastIndex, index));
    }
    parts.push(
      <HighlightedText key={index}>
        {text.slice(index, index + searchTerm.length)}
      </HighlightedText>
    );
    lastIndex = index + searchTerm.length;
  }

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return <>{parts}</>;
};

const GlobalAssets: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const dispatch = useDispatch();
  const { control } = useFormContext<IntentEditingFormData>();
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const templatesArray = useWatch<BlockDto['globalAssetIntents']>({
    name: fieldName,
    control: control
  });
  const templateIds = useMemo(() => templatesArray?.map((t) => t.id) ?? [], [templatesArray]);

  const isEmpty = (templateIds?.length ?? 0) === 0;

  const categories = useSelector(categoriesSelector) as GlobalAssetFullDto[];
  const isLoading = useSelector(isGlobalAssetsLibraryStatusLoadingSelector);
  const companies = useSelector(companiesSelector) as CompanyDto[];
  const userCompanyId = useSelector(userCompanyIdSelector);
  const scenarioLanguage = useSelector(currentChartScenarioLang);

  const [addingInfo, setAddingInfo] = useState({ index: 0, isAdding: false });

  const onStartAdd = useCallback((index: number) => {
    setAddingInfo({
      index,
      isAdding: true
    });
  }, []);

  const resetAddingInfo = useCallback(() => {
    setAddingInfo({
      index: 0,
      isAdding: false
    });
  }, []);

  const onConfirmAdd = useCallback((templateId: TemplateId) => {
    const array = templatesArray ?? [];
    const updated = produce(array, (array) => {
      array.splice(addingInfo.index + 1, 0, { id: templateId });
    });
    // @ts-ignore // TODO ???
    dispatch(IntentEditingActions.updateGlobalAssets(updated));
    resetAddingInfo();
  }, [templatesArray, addingInfo.index, dispatch, resetAddingInfo]);

  const onDelete = useCallback((templateId: TemplateId) => {
    const array = templatesArray ?? [];
    dispatch(IntentEditingActions.updateGlobalAssets(array.filter((t) => t.id !== templateId)));
  }, [templatesArray, dispatch]);
  const templates = useMemo(() => {
    if (!templateIds) {
      return [];
    }

    const result: ExtendedGlobalAssetIntentDto[] = [];
    for (let i = 0; i < categories.length; i++) {
      for (let j = 0; j < categories[i].intents.length; j++) {
        const template = categories[i].intents[j];
        if (templateIds.includes(template.id)) {
          result.push({ ...template, shared: !categories[i].companyId });
        }
      }
    }

    return result;
  }, [categories, templateIds]);

  const templatesPart1 = useMemo(() => templates.slice(0, addingInfo.index + 1), [templates, addingInfo.index]);
  const templatesPart2 = useMemo(() => templates.slice(addingInfo.index + 1), [templates, addingInfo.index]);

  const allTemplatesWithSamples = useMemo(() => {
    return categories
      .filter(category => category.language === scenarioLanguage)
      .flatMap(category => 
        category.intents.map(intent => ({
          intent: {
            ...intent,
            categoryId: category.id,
            categoryTitle: category.title,
            companyId: category.companyId,
            companyName: companies.find(company => company.id === category.companyId)?.name || 'Shared',
            shared: !category.companyId,
          } as ExtendedGlobalAssetIntentDto,
          samples: intent.samples.map(sample => sample.text)
        }))
      );
  }, [categories, companies, scenarioLanguage]);

  const fuse = useMemo(() => {
    return new Fuse(allTemplatesWithSamples, {
      keys: ['intent.title', 'samples'],
      threshold: 0.4, // Adjusted for better performance
      includeMatches: true,
      minMatchCharLength: 2,
      shouldSort: true,
      findAllMatches: false,
    });
  }, [allTemplatesWithSamples]);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300); // 300ms debounce
  const [searchResults, setSearchResults] = useState<Array<{ intent: ExtendedGlobalAssetIntentDto, matchingSamples: string[] }>>([]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      const results = fuse.search(debouncedSearchTerm);
      const processedResults = results.map(result => ({
        intent: result.item.intent,
        matchingSamples: result.matches
          ?.filter(match => match.key === 'samples')
          .flatMap(match => match.value as string)
          .slice(0, 3) || [] // Limit to 3 matching samples per intent
      }));
      setSearchResults(processedResults.slice(0, 6)); // Limit results to 6 items
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm, fuse]);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchTerm('');
  }, []);

  const handleSearchResultSelect = useCallback((template: ExtendedGlobalAssetIntentDto) => {
    onConfirmAdd(template.id);
    setSearchTerm('');
  }, [onConfirmAdd]);

  const classes = useStyles();

  let innerContent: JSX.Element;

  useEffect(() => {
    if (disabled) {
      resetAddingInfo();
    }
  }, [disabled, resetAddingInfo]);

  if (isLoading) {
    innerContent = (
      <Box textAlign={'center'} width={'100%'}>
        <CircularProgress color="secondary" size={24} />
      </Box>
    );
  } else {
    innerContent = (
      <Box flex={'1 1 100%'} width={'100%'}>
        <TextField
          fullWidth
          variant="outlined"
          label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.searchIntents))}
          placeholder={translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.searchPlaceholder))}
          value={searchTerm}
          onChange={handleSearchChange}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {searchTerm && (
                  <IconButton
                    aria-label="clear search"
                    onClick={handleClearSearch}
                    edge="end"
                  >
                    <Clear />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />

        {searchTerm && (
          <Box mt={2}>
            <Typography variant="subtitle1">
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.searchResults))}
            </Typography>
            {searchResults.length > 0 ? (
              <List dense>
                {searchResults.map(({ intent, matchingSamples }) => (
                  <ListItem
                    key={intent.id}
                    button
                    onClick={() => handleSearchResultSelect(intent)}
                    disabled={templateIds.includes(intent.id) || disabled}
                    className={classes.searchResultItem}
                  >
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography component="span" variant="body1" noWrap>
                            {highlightMatches(intent.title, searchTerm)}
                          </Typography>
                          <Typography
                            component={intent.companyId === null ? SharedText : 'span'}
                            variant="body2"
                            color="textSecondary"
                            style={{ marginLeft: 4 }}
                          >
                            {categories.find(cat => cat.id === intent.categoryId)?.title || 'Unknown Category'}
                          </Typography>
                          {intent.companyId !== userCompanyId && (
                            <Typography
                              component={intent.companyId === null ? SharedText : 'span'}
                              variant="body2"
                              color="textSecondary"
                              style={{ marginLeft: 4 }}
                            >
                              ({intent.companyName})
                            </Typography>
                          )}
                        </React.Fragment>
                      }
                      secondary={
                        matchingSamples.length > 0 ? (
                          <React.Fragment>
                            {matchingSamples.map((sample, index) => (
                              <Typography
                                key={index}
                                component="span"
                                display='block'
                                variant="caption"
                                color="textSecondary"
                                className={classes.compactSampleText}
                              >
                                {highlightMatches(sample, searchTerm)}
                              </Typography>
                            ))}
                          </React.Fragment>
                        ) : null
                      }
                      classes={{ root: classes.compactListItemText }}
                    />
                    <ListItemSecondaryAction>
                      {templateIds.includes(intent.id) ? (
                        <Chip
                          label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.alreadyAdded))}
                          size="small"
                          color="primary"
                        />
                      ) : (
                        <IconButton
                          edge="end"
                          aria-label="add"
                          onClick={() => handleSearchResultSelect(intent)}
                          disabled={disabled}
                        >
                          <Add />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.noSearchResults))}
              </Typography>
            )}
          </Box>
        )}

        <Box mt={2}>
          {!isEmpty && templatesPart1.map((t, index) => (
                <Template
                  key={t.id}
                  template={t}
                  onAdd={() => onStartAdd(index)}
                  onDelete={() => onDelete(t.id)}
                  disabled={disabled}
                />
              ))}
          {addingInfo.isAdding && (
            <>
                <Box py={2}>
                  <AddTemplate existingIds={templateIds} onConfirm={onConfirmAdd} onCancel={resetAddingInfo} />
                </Box>
            </>
          )}
          {!isEmpty && templatesPart2.map((t, index) => (
                <Template
                  key={t.id}
                  template={t}
                  onAdd={() => onStartAdd(templatesPart1.length + index)}
                  onDelete={() => onDelete(t.id)}
                  disabled={disabled}
                />
              ))}
        </Box>

        {!addingInfo.isAdding && (
          <Box
            mt={1}
            display={'flex'}
            width={'100%'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            flexGrow={1}
          >
            <Box mt={0}>
              <IconButton
                disabled={disabled}
                onClick={() => {
                  onStartAdd(templateIds.length);
                }}
              >
                <Add />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <CustomAccordion
      title={translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.globalAssets))}
      badgeNumber={templateIds?.length}
    >
      <AccordionDetails>{innerContent}</AccordionDetails>
    </CustomAccordion>
  );
};

const Template = React.memo(function Template({
  template,
  onDelete,
  onAdd,
  disabled
}: {
  template: ExtendedGlobalAssetIntentDto;
  onDelete: () => void;
  onAdd: () => void;
  disabled: boolean;
}) {
  const isTooltipDisplayed = !!template?.samples.length;
  const classes = useStyles();

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Box py={1}>
        <div className={template.shared ? classes.sharedCategory : ''}>
          {template.title}
          {isTooltipDisplayed && <IntentsInfoTooltip template={template} />}
        </div>
      </Box>

      <Box display={'flex'} ml={'auto'}>
        <IconButton
          disabled={disabled}
          onClick={() => {
            onDelete();
          }}
        >
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
});

export default GlobalAssets;
