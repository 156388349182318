import type { RootState } from 'app/reducers/index.js';
import { LoadingStatus } from 'app/store/types.js';

function create<T>(selector: (state: RootState['callManagement']) => T): (state: RootState) => T {
  return (state: RootState) => selector(state.callManagement);
}

export const callManagementSelector = create((s) => s);
export const callManagementLoadingStatusSelector = create((s) => s.loadingStatus);

export const callsSelector = create((s) => s.calls);
export const isCallsStatusLoadingSelector = create((s) => s.loadingStatus === LoadingStatus.Loading);
export const isCallsStatusSucceedSelector = create((s) => s.loadingStatus === LoadingStatus.Succeeded);
export const isCallsStatusFailedSelector = create((s) => s.loadingStatus === LoadingStatus.Failed);
export const isCallsStatusNotIdleSelector = create((s) => s.loadingStatus !== LoadingStatus.Idle);
