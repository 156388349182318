import type { ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import WebsocketContext from './WebsocketContext.js';
import type { HubConnection } from '@microsoft/signalr';
import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { getUrlFromSwaggerUrl } from 'app/apis/api.js';
import { useMsal } from '@azure/msal-react';
import { apiLoginRequest } from '@/authConfig.js';
import logger from 'app/utils/logger.js';

const getWebsocketUrl = (): string => `${getUrlFromSwaggerUrl(process.env.REACT_APP_SCENES)}/hubs/training` || '';

interface WebsocketProviderProps {
  children: ReactNode;
}

const WebsocketProvider: React.FC<WebsocketProviderProps> = ({ children }) => {
  const { instance, accounts } = useMsal();

  const [connection] = useState<HubConnection>(() => {
    const connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(getWebsocketUrl(), {
        transport: HttpTransportType.ServerSentEvents,
        accessTokenFactory: async () =>
          (await instance.acquireTokenSilent({ ...apiLoginRequest, account: accounts[0] })).accessToken
      })
      .withAutomaticReconnect()
      .build();

    connection.keepAliveIntervalInMilliseconds = 1000 * 60 * 20; // 20 minutes
    connection.serverTimeoutInMilliseconds = 1000 * 60 * 360; // 360  minutes

    return connection;
  });

  const startConnection = useCallback(async () => {
    try {
      await connection.start();
      logger.log('Websocket successfully opened!');
    } catch (e) {
      logger.error('Websocket initialization failed', e);
    }
  }, [connection]);

  const stopConnection = useCallback(async () => {
    await connection.stop();
  }, [connection]);

  const subscribe = useCallback(
    (methodName: string, newMethod: (...args: any[]) => void) => {
      connection.on(methodName, newMethod);
    },
    [connection]
  );

  const value = useMemo(() => {
    return {
      connection: connection,
      startConnection,
      stopConnection,
      subscribe
    };
  }, [subscribe, stopConnection, startConnection, connection]);

  return <WebsocketContext.Provider value={value}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProvider;
