import React, { useEffect } from 'react';
import type { MedicalProtocolDto } from '@/generated-api/index.js';
import EditableCardTitle from 'app/components/CourseManagement/EditableCardTitle/EditableCardTitle.js';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { MedicalIndexClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import logger from 'app/utils/logger.js';

const { actions } = medicalIndexSlice;

const schema = object<Partial<MedicalProtocolDto>>({
  title: string().trim().required('This field is required')
});

export type EditableTitleProps = {
  medIndex: MedicalProtocolDto;
};

const nameFieldKey = nameof.full<MedicalProtocolDto>((m) => m.title);

function EditableTitle({ medIndex }: EditableTitleProps): JSX.Element {
  const { errors, reset, getValues, control, handleSubmit } = useForm<MedicalProtocolDto>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      title: medIndex.title
    }
  });

  const dispatch = useDispatch();
  const onSubmit = handleSubmit(
    async (data) => {
      if (!medIndex) {
        return;
      }

      try {
        const { data: updatedMedIndex } = await MedicalIndexClient.medicalIndexUpdateMedicalIndex(medIndex.id, data);

        dispatch(actions.indexUpdated(updatedMedIndex));
      } catch (e) {
        logger.error(e);
      }
    },
    (data) => {
      logger.error('An error occurred updating protocol reference title', data);
    }
  );

  useEffect(() => {
    if (medIndex && getValues(nameFieldKey) !== medIndex?.title) {
      reset({
        title: medIndex.title
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medIndex?.title]);

  return (
    <Controller
      control={control}
      name={nameFieldKey}
      render={({ value, onChange, onBlur }) => {
        return (
          <EditableCardTitle
            value={value}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              onSubmit();
            }}
            error={errors.title?.message}
          />
        );
      }}
    />
  );
}

export default EditableTitle;
