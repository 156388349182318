import React, { useEffect, useMemo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListSubheader,
  MenuItem,
  Select,
  makeStyles
} from '@material-ui/core';
import AddVariableDialog from './AddVariableDialog.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import { useQuery } from '@tanstack/react-query';
import { getCompanyVariablesQuery, getVariablesQuery } from 'app/queries/variableQueries.js';
import VariableLine from './VariableLine.js';
import { useSelector } from 'react-redux';
import { CompaniesSelector, ScenarioSelectors } from 'app/selectors/index.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import type { CompanyVariableDto, VariableDto } from '@/generated-api/index.js';
import { Add } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

// type Variable = {
//   name: string;
//   value: string;
// };

type ScenarioVariablesDialogProps = {
  open: boolean;
  onClose: () => void;
  scenarioId: number;
};

type ScenarioVariablesContentProps = {
  variablesWithCompanyVariables: {
    variable: VariableDto;
    companyVariable: CompanyVariableDto;
  }[];
  scenarioId: number;
  selectedCompanyId: number;
  isSharedScenario: boolean;
};

export const ScenarioVariablesContent = (props: ScenarioVariablesContentProps) => {
  const { variablesWithCompanyVariables, scenarioId, selectedCompanyId, isSharedScenario } = props;

  return (
    <List>
      {variablesWithCompanyVariables.map((obj) => (
        <VariableLine
          variables={variablesWithCompanyVariables.map((v) => v.variable)}
          variable={obj.variable}
          key={obj.variable.name}
          scenarioId={scenarioId}
          companyVariable={obj.companyVariable}
          selectedCompanyId={selectedCompanyId}
          isSharedScenario={isSharedScenario}
        />
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomMargin: {
    marginBottom: theme.spacing(2)
  }
}));

const ScenarioVariablesDialog = (props: ScenarioVariablesDialogProps) => {
  const { open, onClose, scenarioId } = props;
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const isShared = !useSelector(ScenarioSelectors.getScenarioById(scenarioId))?.companyId;
  const companies = useSelector(CompaniesSelector.companiesSelector);
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const variablesQuery = useMemo(() => getVariablesQuery(scenarioId), [scenarioId]);
  const queryData = useQuery(variablesQuery);
  const variables = queryData.data;

  // TODO? Get all company variables on a scenario at once or "per companyid"? (per companyid will make call each time one selects a company)
  // Only get this if the scenario is shared (disable if not shared)
  const companyVariablesQuery = useMemo(() => getCompanyVariablesQuery(scenarioId, !isShared), [scenarioId, isShared]);
  const companyVariablesQueryData = useQuery(companyVariablesQuery);
  const companyVariables = companyVariablesQueryData.data;

  const [selectedCompanyId, setSelectedCompanyId] = React.useState<number>(0);

  const variablesWithCompanyVariables = useMemo(
    () =>
      variables?.map((variable) => {
        // return early if scenario is not shared
        if (!isShared) return { variable, companyVariable: undefined };

        const companyVariable = companyVariables?.find(
          (cv) => cv.variableId === variable.id && (isSuperAdmin ? cv.companyId === selectedCompanyId : true)
        );
        if (!companyVariable && (selectedCompanyId || !isSuperAdmin)) {
          return {
            variable,
            companyVariable: {
              id: 0,
              companyId: selectedCompanyId,
              value: '',
              variableId: variable.id
            }
          };
        }

        return {
          variable,
          companyVariable
        };
      }) ?? [],
    [variables, companyVariables, selectedCompanyId, isShared, isSuperAdmin]
  );

  const [isAddOpen, openAdd, closeAdd] = useBoolState(false);

  useEffect(() => {
    if (open) {
      setSelectedCompanyId(0);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title">
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioVariablesDialog.scenarioVariables))}
      </DialogTitle>
      <DialogContent>
        {isSuperAdmin && isShared ? (
          <Select
            value={selectedCompanyId}
            onChange={(e) => setSelectedCompanyId(e.target.value as number)}
            className={classes.bottomMargin}
          >
            <MenuItem value={0}>
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioVariablesDialog.defaultVariables))}
            </MenuItem>
            <ListSubheader>
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioVariablesDialog.organizations))}
            </ListSubheader>
            {companies
              ?.filter((c) => !!c.id && !!c.name)
              .map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.name}
                </MenuItem>
              ))}
          </Select>
        ) : null}
        <ScenarioVariablesContent
          selectedCompanyId={selectedCompanyId}
          variablesWithCompanyVariables={variablesWithCompanyVariables}
          scenarioId={scenarioId}
          isSharedScenario={isShared}
        />
        {(isSuperAdmin && isShared) || !isShared ? (
          <Button onClick={openAdd} color="primary" variant="contained" startIcon={<Add />}>
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioVariablesDialog.addVariable))}
          </Button>
        ) : null}
      </DialogContent>
      <AddVariableDialog open={isAddOpen} onClose={closeAdd} variables={variables} scenarioId={scenarioId} />
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioVariablesDialog;
