import type { AxiosResponse } from 'axios';
import type { Action } from 'redux-actions';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { CompaniesClient } from 'app/apis/api.js';
import { CompaniesSelector } from 'app/selectors/index.js';
import type { CompanyDto, CreateCompanyCommand } from '@/generated-api/index.js';
import { CompanyManagementActions } from 'app/actions/companyManagment/companyManagementActions.js';

export function* getCompanies() {
  yield put(CompanyManagementActions.setCompaniesAreLoading(true));
  try {
    const response: AxiosResponse<Array<CompanyDto>> = yield call([
      CompaniesClient,
      CompaniesClient.companiesFilterCompanies
    ]);

    yield put(CompanyManagementActions.setCompanies(response.data));
  } finally {
    yield put(CompanyManagementActions.setCompaniesAreLoading(false));
  }
}

function* addCompany(action: Action<CreateCompanyCommand>) {
  yield put(CompanyManagementActions.setCompaniesAreLoading(true));
  try {
    const response: AxiosResponse<CompanyDto> = yield call(
      [CompaniesClient, CompaniesClient.companiesAddCompany],
      action.payload
    );

    const currentCompanies: CompanyDto[] = yield select(CompaniesSelector.companiesSelector);
    yield put(
      CompanyManagementActions.setCompanies([
        ...currentCompanies,
        {
          id: response.data.id,
          name: response.data.name,
          medicalProtocols: response.data.medicalProtocols
        }
      ])
    );
  } finally {
    yield put(CompanyManagementActions.setCompaniesAreLoading(false));
  }
}

function* editCompany(action: Action<CompanyDto>) {
  yield put(CompanyManagementActions.setCompaniesAreLoading(true));
  try {
    const response: AxiosResponse<CompanyDto> = yield call(
      [CompaniesClient, CompaniesClient.companiesEditCompany],
      action.payload
    );

    const currentCompanies: CompanyDto[] = yield select(CompaniesSelector.companiesSelector);
    const updatedCompanies = currentCompanies.map((c) =>
      c.id === response.data.id ? { ...c, name: response.data.name } : c
    );

    yield put(CompanyManagementActions.setCompanies(updatedCompanies));
  } finally {
    yield put(CompanyManagementActions.setCompaniesAreLoading(false));
  }
}

function* deleteCompany(action: Action<CompanyManagementActions.CompanyDeletionActionType>) {
  yield put(CompanyManagementActions.setCompaniesAreLoading(true));
  try {
    yield call([CompaniesClient, CompaniesClient.companiesDeleteCompany], {
      id: action.payload.id,
      onCompanyDeleteAction: action.payload.deletionAction as number,
      destinationCompanyId: action.payload.moveToCompanyId
    });

    const currentCompanies: CompanyDto[] = yield select(CompaniesSelector.companiesSelector);
    yield put(CompanyManagementActions.setCompanies(currentCompanies.filter((c) => c.id !== action.payload.id)));
  } finally {
    yield put(CompanyManagementActions.setCompaniesAreLoading(false));
  }
}

function* watchGetCompanies() {
  yield takeLatest(CompanyManagementActions.Type.GET_COMPANIES, getCompanies);
}

function* watchAddCompany() {
  yield takeLatest(CompanyManagementActions.Type.ADD_COMPANY, addCompany);
}

function* watchEditCompany() {
  yield takeLatest(CompanyManagementActions.Type.EDIT_COMPANY, editCompany);
}

function* watchDeleteCompany() {
  yield takeLatest(CompanyManagementActions.Type.DELETE_COMPANY, deleteCompany);
}

export function* watchCompanyManagement(): Generator {
  yield fork(watchGetCompanies);
  yield fork(watchAddCompany);
  yield fork(watchEditCompany);
  yield fork(watchDeleteCompany);
}
