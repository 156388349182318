import { ScheduledCallStatus, IntentProcessingProvider } from '@/generated-api/index.js';

export const CallStatusMap = {
  [ScheduledCallStatus.NUMBER_0]: 'Initial',
  [ScheduledCallStatus.NUMBER_1]: 'In Progress',
  [ScheduledCallStatus.NUMBER_2]: 'Finished',
  [ScheduledCallStatus.NUMBER_3]: 'Canceled',
  [ScheduledCallStatus.NUMBER_4]: 'Queued',
  [ScheduledCallStatus.NUMBER_5]: 'Not completed',
  [ScheduledCallStatus.NUMBER_6]: 'No answer',
  [ScheduledCallStatus.NUMBER_7]: 'Busy',
  [ScheduledCallStatus.NUMBER_8]: 'Canceled (wrong number)',
  [ScheduledCallStatus.NUMBER_9]: 'Error'
};

export const IntentProcessingProviderMap = {
  [IntentProcessingProvider.NUMBER_0]: 'FuzzySet',
  [IntentProcessingProvider.NUMBER_1]: 'IntentML'
};
