import { EventEmitter } from 'eventemitter3';
import { StatusCodes } from 'http-status-codes';
import { loginRequest } from '@/authConfig.js';
import type { AxiosError } from 'axios';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { UserApi, SceneApi, SpeechApi, CompanyApi, DialogSessionApi } from '@/generated-api/index.js';
import {
  VoicesApi,
  CourseApi,
  AccountApi,
  ProcessApi,
  CompaniesApi,
  UserGroupApi,
  ScenariosApi,
  Configuration,
  MedicalIndexApi,
  GlobalAssetsApi,
  ScheduledCallsApi,
  DialogStatisticsApi
} from '@/generated-api/index.js';
import type { IPublicClientApplication } from '@azure/msal-browser';
import { axiosInstance } from '@/axiosInstance.js';

let UserClient: UserApi;
let SceneClient: SceneApi;
let SpeechClient: SpeechApi;
let VoicesClient: VoicesApi;
let CourseClient: CourseApi;
let AccountClient: AccountApi;
let CompanyClient: CompanyApi;
let ScenarioClient: ScenariosApi;
let CompaniesClient: CompaniesApi;
let UserGroupClient: UserGroupApi;
let MedicalIndexClient: MedicalIndexApi;
let GlobalAssetsClient: GlobalAssetsApi;
let DProcessorProcessClient: ProcessApi;
let DialogSessionClient: DialogSessionApi;
let ScheduledCallsClient: ScheduledCallsApi;
let DialogStatisticsClient: DialogStatisticsApi;

const emitter = new EventEmitter();

export function getUrlFromSwaggerUrl(urlWithSwagger: string | undefined): string {
  return !!urlWithSwagger
    ? `${urlWithSwagger.startsWith('https') ? 'https' : 'http'}://${urlWithSwagger.split('//')[1].split('/')[0]}`
    : '';
}

const setAccessTokenRequestInterceptor = (instance: IPublicClientApplication) => {
  const { logout } = instance;

  // const onRequest = async (config: AxiosRequestConfig) => {
  //   console.log('on request');
  //   const response = await acquireTokenSilent({ ...loginRequest, account: accounts[0] });

  //   config.headers.Authorization = `Bearer ${response.accessToken}`;

  //   return config;
  // };

  const onResponseError = (error: AxiosError) => {
    const rejection = Promise.reject(error);

    if (!error.response) return rejection;

    emitter.emit('Interceptor error', error.response);

    if (error.response.status === StatusCodes.UNAUTHORIZED) {
      logout({ postLogoutRedirectUri: window.location.origin });
      return rejection;
    }

    return rejection;
  };

  // axiosInstance.interceptors.request.use(onRequest, null);
  axiosInstance.interceptors.response.use(null, onResponseError);
};

export const initializeApiClients = (instance: IPublicClientApplication): void => {
  const config = new Configuration();

  const basePath = getUrlFromSwaggerUrl(process.env.REACT_APP_SCENES);

  setAccessTokenRequestInterceptor(instance);

  UserClient = new UserApi(config, basePath, axiosInstance);
  SceneClient = new SceneApi(config, basePath, axiosInstance);
  SpeechClient = new SpeechApi(config, basePath, axiosInstance);
  VoicesClient = new VoicesApi(config, basePath, axiosInstance);
  CourseClient = new CourseApi(config, basePath, axiosInstance);
  AccountClient = new AccountApi(config, basePath, axiosInstance);
  CompanyClient = new CompanyApi(config, basePath, axiosInstance);
  ScenarioClient = new ScenariosApi(config, basePath, axiosInstance);
  CompaniesClient = new CompaniesApi(config, basePath, axiosInstance);
  UserGroupClient = new UserGroupApi(config, basePath, axiosInstance);
  MedicalIndexClient = new MedicalIndexApi(config, basePath, axiosInstance);
  GlobalAssetsClient = new GlobalAssetsApi(config, basePath, axiosInstance);
  DialogSessionClient = new DialogSessionApi(config, basePath, axiosInstance);
  ScheduledCallsClient = new ScheduledCallsApi(config, basePath, axiosInstance);
  DialogStatisticsClient = new DialogStatisticsApi(config, basePath, axiosInstance);
  DProcessorProcessClient = new ProcessApi(config, basePath, axiosInstance);
};

export {
  emitter,
  axiosInstance,
  UserClient,
  SceneClient,
  SpeechClient,
  VoicesClient,
  CourseClient,
  AccountClient,
  CompanyClient,
  ScenarioClient,
  CompaniesClient,
  UserGroupClient,
  GlobalAssetsClient,
  MedicalIndexClient,
  DialogSessionClient,
  ScheduledCallsClient,
  DialogStatisticsClient,
  DProcessorProcessClient
};
