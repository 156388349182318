import React from 'react';
import { Controller } from 'react-hook-form';

import type { BaseTextFieldProps } from '@material-ui/core/TextField/index.js';

import type { CompanySelectFieldProps } from './types.js';
import { MenuItem, Typography, TextField, Grid, CircularProgress } from '@material-ui/core';

const CompanySelectField: React.FC<CompanySelectFieldProps> = ({
  classes,
  companies,
  companiesLoading,
  textFieldProps,
  ...conditionProps
}) => {
  const companiesRendered = React.useMemo(
    () =>
      companies.map((c) => (
        <MenuItem key={c.id} value={c.id}>
          <Typography variant="inherit">{c.name}</Typography>
        </MenuItem>
      )),
    [companies]
  );

  const commonTextFieldProps: BaseTextFieldProps = {
    className: classes?.fieldContainer ?? undefined,
    children: companiesRendered,
    fullWidth: true,
    select: true,
    SelectProps: {
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null,
        autoFocus: false
      }
    },
    ...textFieldProps
  };

  const field = conditionProps.isForm ? (
    <Controller as={TextField} control={conditionProps.control} name={conditionProps.name} {...commonTextFieldProps} />
  ) : (
    <TextField
      value={(conditionProps as any).value}
      onChange={(event) => (conditionProps as any).onChange(event.target.value)}
      {...(commonTextFieldProps as any)}
    />
  );

  return (
    <Grid item container xs className={classes?.root ?? undefined}>
      {companiesLoading ? (
        <Grid
          className={classes?.circularProgressContainer ?? undefined}
          item
          container
          xs
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
      ) : (
        field
      )}
    </Grid>
  );
};

export default CompanySelectField;
