/* eslint-disable react/display-name */
import { Route } from 'react-router';
import { makeStyles } from '@material-ui/core/styles/index.js';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider } from '@material-ui/core';

import { LoadingStatus } from 'app/store/types.js';
import { MyCoursesRoutes } from 'app/utils/routes.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import type { DialogStatisticDto } from '@/generated-api/index.js';
import SkeletonWrapper from 'app/components/SkeletonWrapper.js';
import KPIDetailsDialog from 'app/components/DashboardPage/KPIDetails/KPIDetailsDialog.js';
import UserScheduledCourseCard from 'app/components/CourseManagement/UserScheduledCourseCard.js';
import NoCourseData from 'app/components/CourseManagement/CourseCard/NoCourseData/NoCourseData.js';
import ScheduledCourseList from 'app/components/CourseManagement/CourseList/ScheduledCourseList.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const MyCourses: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(CourseManagementActions.requestScheduledCourses());
  }, [dispatch]);

  const loadingStatus = useSelector(CourseManagementSelectors.requestScheduledCoursesLoadingStatus);

  const [isKpiDetailsDialogOpen, openKpiDetailsDialog, closeKpiDetailsDialog] = useBoolState(false);

  const [selectedDialogStatistic, setSelectedDialogStatistic] = useState<DialogStatisticDto>();

  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  
  const handleDisplayKpiDetails = useCallback(
    (dialogStatistic: DialogStatisticDto) => {
      setSelectedDialogStatistic(dialogStatistic);

      openKpiDetailsDialog();
    },
    [openKpiDetailsDialog]
  );

  return (
    <Box overflow={'hidden'} height={'100%'} display={'flex'}>
      <Grid container>
        <Grid xs={5} md={4} lg={3} item classes={{ root: classes.section }}>
          <Box paddingX={2} paddingY={2}>
            <Typography variant="h6" gutterBottom>
              {translateCommon(nameof.full<I18nCommonNs>((n) => n.navigation.myCourses))}
            </Typography>
            <Divider />
          </Box>
          <Box overflow={'auto'} paddingX={2}>
            <SkeletonWrapper loadingStatus={loadingStatus}>
              <ScheduledCourseList />
            </SkeletonWrapper>
          </Box>
        </Grid>
        <Grid xs={7} md={8} lg={9} item container classes={{ root: classes.section }}>
          <Route exact path={MyCoursesRoutes.Root}>
            <NoCourseData loadingStatus={LoadingStatus.Succeeded} isScenariosLoading={false} />
          </Route>
          <Route path={MyCoursesRoutes.ScheduledCourse}>
            <UserScheduledCourseCard handleDisplayKpiDetails={handleDisplayKpiDetails} />
            <KPIDetailsDialog
              onClose={closeKpiDetailsDialog}
              dialogStatistic={selectedDialogStatistic}
              isOpen={!!selectedDialogStatistic && isKpiDetailsDialogOpen}
            />
          </Route>
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  section: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default MyCourses;
