import * as React from 'react';
import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';
import clsx from 'clsx';
import { Button, FormControl, FormLabel, Grid } from '@material-ui/core';
export interface InlineColorPickerProps {
  colors: Array<string>;
  currentColor: string | undefined;
  disabled?: boolean;
  onSelect: (color: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      padding: theme.spacing(),
      textAlign: 'center',
      color: theme.palette.text.secondary
    },
    button: {
      width: 156,
      height: 24
    },
    selected: {
      border: '2px solid black'
    },
    marginTop: {
      marginTop: 30
    }
  })
);

const InlineColorPicker: React.FC<InlineColorPickerProps> = (props) => {
  const classes = useStyles();

  const callColorSelect = (x: string) => {
    props.currentColor != x ? props.onSelect(x) : props.onSelect('#999');
  };

  return (
    <FormControl fullWidth className={classes.marginTop}>
      <Grid justifyContent="center" className={classes.paper} container spacing={1}>
        {props.colors.map((x: string, i: number) => {
          return (
            <Grid key={i} item>
              <Button
                data-cy={x}
                disabled={props.disabled}
                onClick={() => callColorSelect(x)}
                className={clsx(classes.button, {
                  [classes.selected]: x == props.currentColor
                })}
                style={{ backgroundColor: x }}
              >
                <span> </span>
              </Button>
            </Grid>
          );
        })}
      </Grid>
    </FormControl>
  );
};

export default InlineColorPicker;
