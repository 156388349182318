import type { RootState } from 'app/reducers/index.js';
import { LoadingStatus } from 'app/store/types.js';

function create<T>(selector: (state: RootState['globalAssetsLibrary']) => T): (state: RootState) => T {
  return (state: RootState) => selector(state.globalAssetsLibrary);
}

export const globalAssetsLibrarySelector = create((s) => s);
export const globalAssetsLibraryLoadingStatusSelector = create((s) => s.status);

export const categoriesSelector = create((s) => s.data);
export const isGlobalAssetsLibraryStatusLoadingSelector = create((s) => s.status === LoadingStatus.Loading);
export const isGlobalAssetsLibraryStatusSucceedSelector = create((s) => s.status === LoadingStatus.Succeeded);
export const isGlobalAssetsLibraryStatusFailedSelector = create((s) => s.status === LoadingStatus.Failed);
export const isGlobalAssetsLibraryStatusNotIdleSelector = create((s) => s.status !== LoadingStatus.Idle);

// export const updatingGroupsSelector = create((s) => s.updatingGroups);
