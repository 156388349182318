import type { RootState } from '../reducers/rootReducer.js';
import VoiceRecognitionStatus from '../models/voiceRecognition/VoiceRecognitionStatus.js';

export const recognitionStatusSelector = (state: RootState) => state.voiceRecognitionReducer.recognitionStatusSelector;
export const isPausedSelector = (state: RootState) => state.voiceRecognitionReducer.isPaused;
export const isDialogActive = (state: RootState) =>
  state.voiceRecognitionReducer.recognitionStatusSelector !== VoiceRecognitionStatus.UserStoppedSpeaking;
// export const isSpeaking = (state: RootState) =>
//   state.voiceRecognitionReducer.recognitionStatusSelector === VoiceRecognitionStatus.UserIsSpeaking;
export const latestInhouseText = (state: RootState): string => state.voiceRecognitionReducer.latestInhouseText;
export const useFallbackSttSelector = (state: RootState): boolean => state.voiceRecognitionReducer.useFallbackStt;
export const fallbackSttTextSelector = (state: RootState): string => state.voiceRecognitionReducer.fallbackSttText;
