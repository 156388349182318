import type { RootState } from 'app/reducers/index.js';
import { LoadingStatus } from 'app/store/types.js';

function create<T>(selector: (state: RootState['groupManagement']) => T): (state: RootState) => T {
  return (state: RootState) => selector(state.groupManagement);
}

export const groupsManagementSelector = create((s) => s);
export const groupsManagementLoadingStatusSelector = create((s) => s.loadingStatus);

export const groupsSelector = create((s) => s.groups);
export const isGroupsStatusLoadingSelector = create((s) => s.loadingStatus === LoadingStatus.Loading);
export const isGroupsStatusSucceedSelector = create((s) => s.loadingStatus === LoadingStatus.Succeeded);
export const isGroupsStatusFailedSelector = create((s) => s.loadingStatus === LoadingStatus.Failed);
export const isGroupsStatusNotIdleSelector = create((s) => s.loadingStatus !== LoadingStatus.Idle);

export const updatingGroupsSelector = create((s) => s.updatingGroups);

export const createUserGroupLoadingStatus = create((state) => state.loadingStatuses.createUserGroup);
