import { AudioSelectors } from 'app/selectors/index.js';
import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import logger from 'app/utils/logger.js';
import { VoiceRecognitionActions } from 'app/actions/voiceRecognition/voiceRecognitionActions.js';
import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';

const audioWorker = new Worker(new URL('./audioWorker.js', import.meta.url));

export interface AudioPlayerRef {
  play: () => void;
  pause: () => void;
}

const AudioPlayer = forwardRef<AudioPlayerRef, { onAllAudioPlayed?: () => void }>(({ onAllAudioPlayed }, ref) => {
  const audio = useSelector(AudioSelectors.audioSelector).voice;
  const audioQueueRef = useRef([]);
  const mediaSourceRef = useRef<MediaSource | null>(null);
  const sourceBufferRef = useRef<SourceBuffer | null>(null);
  const sequenceNumberRef = useRef(0);
  const audioElementRef = useRef<HTMLAudioElement | null>(null);
  const shouldStartPlayingRef = useRef(false);

  const chunksBufferedCount = useRef(0);
  const audioChunksReceivedCount = useRef(0);
  const checkForBufferingComplete = useRef(false);

  const dispatch = useDispatch();

  const resetState = () => {
    audioQueueRef.current = [];
    sequenceNumberRef.current = 0;
    chunksBufferedCount.current = 0;
    audioChunksReceivedCount.current = 0;
    checkForBufferingComplete.current = false;

    if (audioElementRef.current) {
      audioElementRef.current.src = '';
      audioElementRef.current.load();
    }

    if (mediaSourceRef.current) {
        mediaSourceRef.current.removeEventListener('sourceopen', handleSourceOpen);
        mediaSourceRef.current = new MediaSource();
        mediaSourceRef.current.addEventListener('sourceopen', handleSourceOpen);

        if (audioElementRef.current) {
          audioElementRef.current.src = URL.createObjectURL(mediaSourceRef.current);
        }
    }
  };

  useEffect(() => {
    mediaSourceRef.current = new MediaSource();
    mediaSourceRef.current.addEventListener('sourceopen', handleSourceOpen);

    audioElementRef.current = new Audio(URL.createObjectURL(mediaSourceRef.current));
    audioElementRef.current.addEventListener('ended', handleAudioEnded);
    audioElementRef.current.addEventListener('waiting', () => {

      if (audioChunksReceivedCount.current > 0 &&
        audioChunksReceivedCount.current === chunksBufferedCount.current) {
        onAllAudioPlayed();
        resetState();
      }
    });
  }, []);

  const handleSourceOpen = () => {
    sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer('audio/mpeg');
    sourceBufferRef.current.addEventListener('updateend', handleUpdateEnd);

    if (audioQueueRef.current.length > 0 && !sourceBufferRef.current.updating ) {
      appendNextChunk();
    }
  };

  const handleUpdateEnd = () => {
    chunksBufferedCount.current++
    if (shouldStartPlayingRef.current) {
      shouldStartPlayingRef.current = false;
      playAudio();
    }
    if (audioQueueRef.current.length > 0 && !sourceBufferRef.current.updating) {
      appendNextChunk();
    }
  };

  const handleAudioEnded = () => {
    if (audioQueueRef.current.length === 0) {
      if (onAllAudioPlayed) {
        onAllAudioPlayed();
      }
    } else {
      appendNextChunk();
    }
  };

  // Constants for retry mechanism
  const MAX_RETRIES = 5;
  const RETRY_DELAY_MS = 100;

  const appendNextChunk = (retries = 0) => {
    if (audioQueueRef.current.length > 0) {
      if (!sourceBufferRef.current.updating) {
        const nextChunk = audioQueueRef.current.shift();
        try {
          sourceBufferRef.current.appendBuffer(nextChunk.audioData);
        } catch (error) {
          logger.error("[AudioPlayer] Error appending chunk:", error);
        }
      } else if (retries < MAX_RETRIES) {
        setTimeout(() => appendNextChunk(retries + 1), RETRY_DELAY_MS);
      } else {
        logger.error("[AudioPlayer] Max retries reached, unable to append chunk.");
      }
    }
  };

  const playAudio = () => {
    if (audioElementRef.current.paused) {
      audioElementRef.current.play().catch((error) => {
        logger.error("[AudioPlayer] Error during playback: ", error);
      });
    }
  };

  const pauseAudio = () => {
    if (!audioElementRef.current.paused) {
      audioElementRef.current.pause();
      resetState();
    } 
  };

  const enqueueAudioChunk = (audioData, sequence) => {
    audioQueueRef.current = [...audioQueueRef.current, { audioData, sequence }];
    audioQueueRef.current.sort((a, b) => a.sequence - b.sequence);
    appendNextChunk();
  };

  const playBase64AudioChunk = (base64Chunk) => {
    if (!base64Chunk) {
      return;
    }
    audioWorker.postMessage({ base64Chunk, sequence: sequenceNumberRef.current });
    sequenceNumberRef.current += 1;
  };

  // Listen to changes to audio in redux
  useEffect(() => {
    playBase64AudioChunk(audio);
  }, [audio]);

  useEffect(() => {
    audioWorker.onmessage = (e) => {
      const { audioData, sequence, error } = e.data;
      if (sequence === 0) {
        dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.DPResponseWasLoaded));
        dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.AudioIsPlaying));
        shouldStartPlayingRef.current = true;
      }
      if (error) {
        logger.error('[AudioPlayer] Worker error:', error);
      } else if (audioData) {
        audioChunksReceivedCount.current++;
        enqueueAudioChunk(audioData, sequence);
        
      }
    };
  }, []);

  // Expose play and pause methods to parent through ref
  useImperativeHandle(ref, () => ({
    play: playAudio,
    pause: pauseAudio,
  }));

  return <div>AUDIO PLAYER</div>;
});

AudioPlayer.propTypes = {
  onAllAudioPlayed: PropTypes.func,
};

export default AudioPlayer;
