import React, { useMemo } from 'react';
import { Box, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { ScenarioChartSelectors } from 'app/selectors/index.js';
import type { INode } from '@mrblenny/react-flow-chart/src/types/chart.js';
import type IntentNodeProperties from 'app/models/intents/intentNodeProperties.js';
import type { TabPanelProps } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TabPanel.js';
import TabPanel from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TabPanel.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

export type BlocksReachTimeTabProps = {
  index: TabPanelProps['index'];
  value: TabPanelProps['value'];
};

function BlocksReachTimeTab({ index, value }: BlocksReachTimeTabProps): JSX.Element {
  const nodes = useSelector(ScenarioChartSelectors.chartNodes);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const filteredNodes = useMemo(() => {
    const nodesWithReachTime: INode<IntentNodeProperties>[] = [];

    for (const key in nodes) {
      if (nodes[key].properties.secondsFromDialogStartEstimate) {
        nodesWithReachTime.push(nodes[key]);
      }
    }

    return nodesWithReachTime;
  }, [nodes]);

  return (
    <TabPanel index={index} value={value}>
      <Box py={2}>
        {filteredNodes.length ? (
          <NodesReachTimeList nodes={filteredNodes} />
        ) : (
          translate(nameof.full<I18nScenarioEditorNs>((n) => n.blockReachTimeSummaryTab.noSetupReachTimeFound))
        )}
      </Box>
    </TabPanel>
  );
}

type NodesReachTimeListProps = {
  nodes: INode<IntentNodeProperties>[];
};

function NodesReachTimeList({ nodes }: NodesReachTimeListProps) {
  return (
    <List>
      {nodes.map((n) => (
        <NodeReachTime key={n.id} title={n.properties.name} globalScope={n.properties.secondsFromDialogStartEstimate} />
      ))}
    </List>
  );
}

function NodeReachTime({ title, globalScope }: { title: string; globalScope: number }) {
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <ListItem>
      <ListItemText
        primaryTypographyProps={{
          variant: 'subtitle1'
        }}
        primary={<b>{title}</b>}
        secondaryTypographyProps={{
          component: 'div'
        }}
        secondary={
          <>
            <Typography variant="body2" color="textPrimary">
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.blockReachTimeSummaryTab.fromDialogStart))} {globalScope}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
}

export default BlocksReachTimeTab;
