import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
const { actions } = medicalIndexSlice;
import { MedicalIndexClient } from 'app/apis/api.js';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { medicalIndexesLoadingStatusSelector } from 'app/store/MedicalIndex/medicalIndexSelectors.js';
import { LoadingStatus, LoadMode } from 'app/store/types.js';
import type { Unwrap } from 'app/types/UtilityType.js';

export function* loadMedicalIndexSaga(action: ReturnType<typeof actions.load>) {
  const status: ReturnType<typeof medicalIndexesLoadingStatusSelector> = yield select(
    medicalIndexesLoadingStatusSelector
  );

  if (status === LoadingStatus.Loading) {
    return;
  }

  if (action && action.payload === LoadMode.Soft && status === LoadingStatus.Succeeded) {
    return;
  }

  yield put(actions.loadingStarted());

  try {
    const response: Unwrap<typeof MedicalIndexClient.medicalIndexGetMedicalIndexList> = yield MedicalIndexClient.medicalIndexGetMedicalIndexList();
    yield put(actions.loadingFinished(response.data?.length ? response.data : []));
  } catch (e) {
    yield put(actions.loadingFinished([], e as Error));
  }
}

export function* watchMedicalIndexSagas() {
  yield all([takeEvery(actions.load.toString(), loadMedicalIndexSaga)]);
}
