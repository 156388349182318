import React, { useCallback, useMemo } from 'react';

import { Box, Chip, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import type { CustomNode } from 'app/models/intents/customNode.js';
import { ScenarioChartStoreActions } from 'app/actions/scenarios/scenarioChartStoreAction.js';
import classNames from 'classnames';
import type { MedicalProtocolActionDto, MedicalProtocolChapterDto, MedicalProtocolDto } from '@/generated-api/index.js';
import { ScenarioChartSelectors } from 'app/selectors/index.js';
import type { INode } from '@mrblenny/react-flow-chart/src/types/chart.js';
import type IntentNodeProperties from 'app/models/intents/intentNodeProperties.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import type { TabPanelProps } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TabPanel.js';
import TabPanel from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TabPanel.js';
import { medicalIndexStateSelector } from 'app/store/MedicalIndex/medicalIndexSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

export type MedialIndexesSummaryTabProps = {
  index: TabPanelProps['index'];
  value: TabPanelProps['value'];
};

type ResponseActionInfo = {
  medicalIndex: MedicalProtocolDto;
  chapter: MedicalProtocolChapterDto;
  responseAction: MedicalProtocolActionDto;
};

type ResponseActionsMap = Map<MedicalProtocolActionDto['id'], ResponseActionInfo>;
type NodesMap = Map<INode['id'], IntentNodeProperties>;

type ChapterLevelMap = Map<MedicalProtocolChapterDto, Map<ResponseActionInfo, NodesMap>>;
type MedicalIndexLevelMap = Map<MedicalProtocolDto, ChapterLevelMap>;

const useStyles = makeStyles({
  nodeSummaryChip: {
    borderWidth: '2px',
    '& + &': {
      marginLeft: '4px'
    }
  }
});

function MedicalIndexesSummaryTab({ index, value }: MedialIndexesSummaryTabProps): JSX.Element {
  const nodes = useSelector(ScenarioChartSelectors.chartNodes);
  const medicalIndexes = useSelector(medicalIndexStateSelector).indexes;

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const groupedByMedicalIndex = useMemo(() => {
    const responseActionsMap = medicalIndexes.reduce<ResponseActionsMap>((map, mi) => {
      mi.chapters.forEach((chapter) => {
        chapter.actions.forEach((action) => {
          const indexInfo: ResponseActionInfo = {
            medicalIndex: mi,
            chapter: chapter,
            responseAction: action
          };

          map.set(action.id, indexInfo);
        });
      });

      return map;
    }, new Map());

    const groupedByMedicalIndex: MedicalIndexLevelMap = new Map();

    if (!responseActionsMap.size) {
      return groupedByMedicalIndex;
    }

    for (const nodeId in nodes) {
      nodes[nodeId].properties?.medicalProtocolActions?.forEach(({ id: indexId }) => {
        // nodes[nodeId].properties?.amkResponseActionIds?.forEach((indexId) => {
        const responseInfo = responseActionsMap.get(indexId);
        if (!responseInfo) return;
        const chapter = responseInfo.chapter;
        const medicalIndex = responseInfo.medicalIndex;
        const node = nodes[nodeId].properties;

        if (!groupedByMedicalIndex.has(medicalIndex)) {
          groupedByMedicalIndex.set(medicalIndex, new Map());
        }

        if (!groupedByMedicalIndex.get(medicalIndex).has(chapter)) {
          groupedByMedicalIndex.get(medicalIndex).set(chapter, new Map());
        }

        if (!groupedByMedicalIndex.get(medicalIndex).get(chapter).has(responseInfo)) {
          groupedByMedicalIndex.get(medicalIndex).get(chapter).set(responseInfo, new Map());
        }

        groupedByMedicalIndex.get(medicalIndex).get(chapter).get(responseInfo).set(nodeId, node);
      });
    }

    return groupedByMedicalIndex;
  }, [nodes, medicalIndexes]);

  const groupedByChapters = useMemo(() => {
    const medIndexes: JSX.Element[] = [];
    groupedByMedicalIndex.forEach((chaptersMap, medIndex) => {
      medIndexes.push(<GroupedByMedicalIndex key={medIndex.id} medicalIndex={medIndex} chapters={chaptersMap} />);
    });

    return medIndexes;
  }, [groupedByMedicalIndex]);

  return (
    <TabPanel index={index} value={value}>
      <Box pt={2}>
        {groupedByChapters.length
          ? groupedByChapters
          : translate(nameof.full<I18nScenarioEditorNs>((n) => n.medicalIndexesSummaryTab.noProtocolReferencesFound))}
      </Box>
    </TabPanel>
  );
}

function GroupedByMedicalIndex({
  medicalIndex,
  chapters
}: {
  medicalIndex: MedicalProtocolDto;
  chapters: ChapterLevelMap;
}) {
  let totalNodes = 0;
  chapters.forEach((responseActionsMap) => {
    responseActionsMap.forEach((nodesMap) => {
      // TODO: need to calculate ids instead of size
      totalNodes += nodesMap.size;
    });
  });

  const groupedByMedIndexes = useMemo(() => {
    const components: JSX.Element[] = [];
    chapters.forEach((responseActionsMap, chapter) => {
      components.push(<GroupedBySections key={chapter.id} chapter={chapter} responsesMap={responseActionsMap} />);
    });

    return components;
  }, [totalNodes]);

  return (
    <div>
      <Box pb={2}>
        <Typography variant={'h5'}>{medicalIndex.title}</Typography>
      </Box>
      {groupedByMedIndexes}
    </div>
  );
}

function GroupedBySections({
  chapter,
  responsesMap
}: {
  chapter: MedicalProtocolChapterDto;
  responsesMap: Map<ResponseActionInfo, NodesMap>;
}) {
  const indicesComponents: JSX.Element[] = [];
  responsesMap.forEach((nodesMap, responseActionInfo) => {
    indicesComponents.push(
      <IndexNodes key={responseActionInfo.responseAction.id} indexInfo={responseActionInfo} nodes={nodesMap} />
    );
  });

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <div data-cy="scenario-summary-section-container">
      <Box pb={1.5}>
        <Typography variant={'h6'}>
          <b>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.medicalIndexesSummaryTab.section))}</b>
          {chapter.title}
        </Typography>
      </Box>
      {indicesComponents}
    </div>
  );
}

function IndexNodes({ indexInfo, nodes }: { indexInfo: ResponseActionInfo; nodes: NodesMap }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onRemove = useCallback(
    (nodeId: CustomNode['id']) => {
      dispatch(
        ScenarioChartStoreActions.setNodeAdvanced({
          id: nodeId,
          node: (node) => {
            return {
              ...node,
              properties: {
                ...node.properties,
                medicalProtocolActions: node.properties.medicalProtocolActions.filter(
                  ({ id }) => id !== indexInfo.responseAction.id
                )
              }
            };
          }
        })
      );
    },
    [dispatch, indexInfo.responseAction.id]
  );

  const chips: JSX.Element[] = [];
  nodes.forEach((node, id) =>
    chips.push(
      <Chip
        key={id}
        className={classNames(classes.nodeSummaryChip)}
        style={{ borderColor: node.labelColor }}
        size="small"
        variant="outlined"
        label={node.name}
        onDelete={() => onRemove(id)}
        data-cy="scenario-summary-node-chip"
      />
    )
  );

  return (
    <Box pb={1.5} data-cy="scenario-summary-index-container">
      <span>{indexInfo.responseAction.name}</span>
      <Box display={'flex'} alignItems={'center'}>
        {chips}
      </Box>
    </Box>
  );
}

export default MedicalIndexesSummaryTab;
