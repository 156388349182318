import React, { useEffect, useState } from 'react';
import type { CompanyDto, MedicalProtocolDto } from '@/generated-api/index.js';
import { IconButton } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline.js';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import { useDispatch } from 'react-redux';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { MedicalIndexClient } from 'app/apis/api.js';
import { CompanyManagementActions } from 'app/actions/companyManagment/companyManagementActions.js';
import logger from 'app/utils/logger.js';

const { getCompanies } = CompanyManagementActions;

const useClasses = makeStyles((theme) => {
  const height = 48;

  return {
    root: {
      height: height + theme.spacing(2),
      padding: theme.spacing(1),
      display: 'grid',
      gridTemplateColumns: `1fr ${height}px`,
      alignItems: 'center',
      columnGap: theme.spacing(2)
    }
  };
});

export type UsedByCompanyProps = {
  medIndex: MedicalProtocolDto;
  company: CompanyDto;
  disabled: boolean;
};

function UsedByCompany({ medIndex, company, disabled }: UsedByCompanyProps): JSX.Element {
  const classes = useClasses();

  const [submitStatus, setSubmitStatus] = useState(ProcessStatus.Idle);

  const dispatch = useDispatch();

  const unmountedRef = useUnmounted();
  useEffect(() => {
    if (submitStatus !== ProcessStatus.StartProcess) {
      return;
    }

    setSubmitStatus(ProcessStatus.Processing);

    const process = async () => {
      try {
        await MedicalIndexClient.medicalIndexDeleteMedIndexAssignment(medIndex.id, [company.id]);
        dispatch(getCompanies());
      } catch (e) {
        logger.error('Some error occurred during deleting company assignment', e);
      } finally {
        if (!unmountedRef.current) {
          setSubmitStatus(ProcessStatus.Idle);
        }
      }
    };

    process();
  }, [submitStatus, medIndex, dispatch, company.id, unmountedRef]);

  const onDelete = () => setSubmitStatus(ProcessStatus.StartProcess);
  const isButtonDisabled = disabled || submitStatus !== ProcessStatus.Idle;

  return (
    <div key={company.id} className={classes.root}>
      <span>{company.name}</span>
      <IconButton onClick={onDelete} disabled={isButtonDisabled}>
        <DeleteOutlineIcon />
      </IconButton>
    </div>
  );
}

export default UsedByCompany;
