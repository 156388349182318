import { handleActions } from 'redux-actions';
import { VoiceRecognitionActions } from 'app/actions/voiceRecognition/voiceRecognitionActions.js';
import VoiceRecognitionStatus from '../../models/voiceRecognition/VoiceRecognitionStatus.js';
import { DialogProcessorActions } from 'app/actions/dialogprocessor/dialogProcessorActions.js';

export interface IRecognitionState {
  recognitionStatusSelector: VoiceRecognitionStatus;
  isPaused: boolean;
  latestInhouseText: string;
  useFallbackStt: boolean;
  fallbackSttText: string;
}

const initialState: IRecognitionState = {
  recognitionStatusSelector: VoiceRecognitionStatus.UserStoppedSpeaking,
  isPaused: false,
  latestInhouseText: '',
  useFallbackStt: false,
  fallbackSttText: ''
};

export const VoiceRecognitionReducer = handleActions<IRecognitionState, string | boolean | any>(
  {
    [DialogProcessorActions.Type.SET_STORE_RESPONSE_DATA]: (state) => {
      return { ...state, recognitionStatusSelector: VoiceRecognitionStatus.DPResponseWasLoaded };
    },
    [VoiceRecognitionActions.Type.SET_STORE_RECOGNITION_STATUS]: (state, action): IRecognitionState => {
      return { ...state, recognitionStatusSelector: action.payload as VoiceRecognitionStatus };
    },
    [VoiceRecognitionActions.Type.SET_LATEST_INHOUSE_TEXT]: (state, action): IRecognitionState => {
      return { ...state, latestInhouseText: action.payload as string };
    },
    [VoiceRecognitionActions.Type.SET_IS_PAUSED]: (state, action): IRecognitionState => {
      return { ...state, isPaused: action.payload as boolean };
    },
    [VoiceRecognitionActions.Type.SET_USE_FALLBACK_STT]: (state, action): IRecognitionState => {
      return { ...state, useFallbackStt: action.payload as boolean };
    },
    [VoiceRecognitionActions.Type.SET_FALLBACK_STT_TEXT]: (state, action): IRecognitionState => {
      return { ...state, fallbackSttText: action.payload as string };
    }
  },
  initialState
);
