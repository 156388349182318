import { makeStyles } from '@material-ui/core';

export default makeStyles({
  root: {
    width: 'fit-content'
  },
  appBarInner: {
    background: '#f9f9f9',
    borderBottomWidth: 2,
    borderBottomColor: '#ddd',
    borderBottomStyle: 'solid'
  },
  title: {
    flexGrow: 1,
    color: 'black'
  },
  actionDefault: {
    color: '#4071b0'
  },
  action: {
    fontSize: 18,
    marginBottom: 3
  },
  actionPlay: {
    color: '#cb1a1a'
  },
  actionStop: {
    color: '#4071b0'
  },
  border: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 3,
    height: 24
  },
  borderStop: {
    borderColor: '#4071b0'
  },
  borderPlay: {
    borderColor: '#cb1a1a'
  },
  dividerVert: {
    borderRightWidth: 2,
    borderRightColor: '#ddd',
    borderRightStyle: 'solid'
  },
  transformNone: {
    textTransform: 'none'
  }
});
