// allow type `object` for generic
// eslint-disable-next-line @typescript-eslint/ban-types
export function deepCopy<T extends string | number | boolean | undefined | object | null | undefined>(obj: T): T {
  // Handle the 3 simple types, and null or undefined
  if (obj === null || typeof obj !== 'object') return obj;

  // Handle Date
  if (obj instanceof Date) {
    const copy = new Date();
    copy.setTime(obj.getTime());
    return copy as T;
  }

  // Handle Array
  if (obj instanceof Array) {
    const copy: unknown[] = [];
    obj.forEach((elem) => copy.push(deepCopy(elem)));
    return copy as T;
  }

  // Handle Object
  if (obj instanceof Object) {
    const copy: Record<string, unknown> = {};
    Object.entries(obj).forEach(([attr, value]) => {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = deepCopy(value);
      }
    });

    return copy as T;
  }

  throw new Error("Unable to copy: type isn't supported.");
}
