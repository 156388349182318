import { makeStyles } from '@material-ui/core/styles/index.js';
import type { TransitionWeightInputProps } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TransitionWeights/TransitionWeightInput.js';
import TransitionWeightInput from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TransitionWeights/TransitionWeightInput.js';
import React from 'react';
import type { DialogStatisticDto } from '@/generated-api/index.js';
import { QualityLevel } from '@/generated-api/index.js';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    flex: '1 1 100%',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    justifyItems: 'center',
    alignItems: 'center'
  }
}));

export const qualities = [
  QualityLevel.NUMBER_3,
  QualityLevel.NUMBER_2,
  QualityLevel.NUMBER_1,
  QualityLevel.NUMBER_0
] as const;

export type TransitionWeightsGridProps = {
  statistic: DialogStatisticDto;
};

function TransitionWeightsGrid({ statistic }: TransitionWeightsGridProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {qualities.map((q) => (
        <div key={q}>{createTransitionsForOutput(q, statistic)}</div>
      ))}
    </div>
  );
}

function createTransitionsForOutput(output: TransitionWeightInputProps['output'], statistic: DialogStatisticDto) {
  return qualities.map((input) => {
    const value = statistic.qualityParams?.find((qp) => qp.origin === output && qp.destination === input)?.value ?? 0;

    return <TransitionWeightInput key={String(input) + output} output={output} input={input} disabled value={value} />;
  });
}

export default TransitionWeightsGrid;
