import React from 'react';
import { Delete } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit.js';
import { Grid, IconButton, Tooltip } from '@material-ui/core';

import type UserManaged from 'app/models/userManagement/userManaged.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';

type ActionsProps = {
  user: UserManaged;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
};

const Actions: React.FC<ActionsProps> = ({ user, onEdit, onDelete }: ActionsProps) => {

  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Grid onClick={(e) => e.stopPropagation()}>
      <Tooltip title={translate(nameof.full<I18nUserManagementNs>((n) => n.labels.editUser))} placement="bottom">
        <IconButton size="small" data-cy="editUserButton" onClick={() => onEdit(user.id)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={translate(nameof.full<I18nUserManagementNs>((n) => n.labels.deleteUser))} placement="bottom">
        <IconButton size="small" onClick={() => onDelete(user.id)}>
          <Delete />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

export default Actions;
