import { useState, useCallback } from 'react';
import type { Dispatch, Action } from 'redux';
import type { IFlowChartCallbacks } from '@mrblenny/react-flow-chart';

import { ScenarioChartEditingActions } from '../../../actions/scenarios/scenarioChartEditingActions.js';
import { noop } from '../../../utils/index.js';
import { IntentActions } from '../../../actions/scenarios/intentAction.js';
import { IntentEditingActions } from '../../../actions/scenarios/intentEditingAction.js';
import type { IOnActionButtonClickHandler } from '../../../utils/flowChart/configManager.js';

export function getFlowChartCallbacks(dispatch: Dispatch): IFlowChartCallbacks {
  // capture the return value of dispatch to match types with callbacks
  const voidDispatch = (action: Action) => {
    dispatch(action);
  };

  return {
    onDragNode: (input) => voidDispatch(ScenarioChartEditingActions.onDragNode(input)),
    onDragCanvasStop: (input) => voidDispatch(ScenarioChartEditingActions.onDragCanvasStop(input)),
    onLinkMouseEnter: (input) => voidDispatch(ScenarioChartEditingActions.onLinkMouseEnter(input)),
    onLinkMouseLeave: (input) => voidDispatch(ScenarioChartEditingActions.onLinkMouseLeave(input)),
    onLinkClick: (input) => voidDispatch(ScenarioChartEditingActions.onLinkClick(input)),
    onCanvasClick: noop,
    onNodeClick: (input) => voidDispatch(ScenarioChartEditingActions.onNodeClick(input)),
    onZoomCanvas: (input) => voidDispatch(ScenarioChartEditingActions.onZoomCanvas(input)),
    onNodeSizeChange: (input) => voidDispatch(ScenarioChartEditingActions.onNodeSizeChange(input)),
    // FYI: When a link is created, these methods are attached to window event handlers.
    // They are captured and will not receive updated chart state.
    // See https://github.com/MrBlenny/react-flow-chart/blob/master/src/components/Port/Port.wrapper.tsx for clarification.
    onLinkStart: (input) => voidDispatch(ScenarioChartEditingActions.onLinkStart(input)),
    onLinkMove: (input) => voidDispatch(ScenarioChartEditingActions.onLinkMove(input)),
    onLinkComplete: (input) => voidDispatch(ScenarioChartEditingActions.onLinkComplete(input)),
    onLinkCancel: (input) => voidDispatch(ScenarioChartEditingActions.onLinkCancel(input)),
    // This is not needed, node positioning is handled by onDragNode action
    onDragNodeStop: (input) => voidDispatch(ScenarioChartEditingActions.onDragNodeStop(input)),
    // Node hover state isn't used or handled in any meaningful way.
    onNodeMouseEnter: noop,
    onNodeMouseLeave: noop,
    // This action changes port position relative to the node position. However, since our nodes never change size,
    // there is no need to recalculate port positions.
    // Also, this action is fired on every single port mount, which is just evil.
    onPortPositionChange: noop,
    // no need for these actions
    onDragCanvas: noop,
    onCanvasDrop: noop,
    onDeleteKey: noop,
    onNodeDoubleClick: (input) => voidDispatch(ScenarioChartEditingActions.onNodeDoubleClick(input))
  };
}

interface NodeActionMenuState {
  nodeId: string;
  nodeAnchor: Element;
}

type HandleCloseMenu = () => void;
type HandleCopyNode = () => void;
type HandleDeleteNode = () => void;
type HandleSetIntentTypeAsStart = () => void;
type HandleCopyNodeToScenario = (scenarioId: number) => void;

export function useNodeActionCallbacks(
  dispatch: Dispatch
): [
  Element | undefined,
  string,
  IOnActionButtonClickHandler,
  HandleCloseMenu,
  HandleCopyNode,
  HandleDeleteNode,
  HandleSetIntentTypeAsStart,
  HandleCopyNodeToScenario
] {
  const [nodeMenuState, setNodeMenuState] = useState<NodeActionMenuState>();

  const handleNodeActionButtonClick = useCallback<IOnActionButtonClickHandler>(
    (input) => {
      setNodeMenuState({
        nodeId: input.nodeId,
        nodeAnchor: input.nodeAnchor
      });
    },
    [setNodeMenuState]
  );

  const handleCloseMenu = useCallback<HandleCloseMenu>(() => setNodeMenuState(undefined), [setNodeMenuState]);

  const handleCopyNode = useCallback<HandleCopyNode>(() => {
    handleCloseMenu();
    if (!!nodeMenuState?.nodeId) {
      dispatch(IntentActions.copyIntent(nodeMenuState?.nodeId));
    }
  }, [nodeMenuState, dispatch, handleCloseMenu]);

  const handleDeleteNode = useCallback<HandleDeleteNode>(() => {
    handleCloseMenu();
    if (!!nodeMenuState?.nodeId) {
      dispatch(IntentActions.removeIntent(nodeMenuState?.nodeId));
    }
  }, [nodeMenuState, dispatch, handleCloseMenu]);

  const handleCopyNodeToScenario = useCallback<HandleCopyNodeToScenario>(
    (scenarioId: number) => {
      handleCloseMenu();
      if (!!nodeMenuState?.nodeId) {
        dispatch(
          IntentActions.copyIntentToScenario({
            intentId: nodeMenuState?.nodeId,
            scenarioId
          })
        );
      }
    },
    [nodeMenuState, dispatch, handleCloseMenu]
  );

  const handleSetIntentTypeAsStart = useCallback<HandleSetIntentTypeAsStart>(() => {
    handleCloseMenu();
    if (!!nodeMenuState?.nodeId) {
      dispatch(IntentEditingActions.setIntentTypeAsStart(nodeMenuState?.nodeId));
    }
  }, [nodeMenuState, handleCloseMenu, dispatch]);

  return [
    nodeMenuState?.nodeAnchor,
    nodeMenuState?.nodeId ?? '',
    handleNodeActionButtonClick,
    handleCloseMenu,
    handleCopyNode,
    handleDeleteNode,
    handleSetIntentTypeAsStart,
    handleCopyNodeToScenario
  ];
}
