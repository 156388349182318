import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import customHistory from '@/core/customHistory.js';

const reactPlugin = new ReactPlugin();
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true
};
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.APP_INSIGHTS_CONNECTION_STRING,
    // enableAutoRouteTracking: true,
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      [reactPlugin.identifier]: { history: customHistory },
      [clickPluginInstance.identifier]: clickPluginConfig
    },
    distributedTracingMode: DistributedTracingModes.AI,
    enableCorsCorrelation: true
  }
});

// console.log(process.env.APP_INSIGHTS_CONNECTION_STRING);

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((envelope) => {
  if (!envelope.tags) {
    envelope.tags = [];
  }
  envelope.tags.push({ 'ai.cloud.role': 'HiSklls' });
});
appInsights.trackPageView();

export default appInsights;
