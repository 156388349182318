import type { FunctionComponent } from 'react';
import React from 'react';
import clsx from 'clsx';
import { Box, Chip, Tooltip } from '@material-ui/core';
import useStyles from './styles.js';
import InputIcon from '@material-ui/icons/Input.js';
import ScoreInfoTooltipContent from './ScoreInfoTooltipContent.js';
import { withStyles } from '@material-ui/core/styles/index.js';
import type { IScoresChanges } from 'app/models/statistic/scoresChanges.js';
import type { DialogStatisticIntentWithDuplicates } from 'app/models/statistic/statistic.js';

interface IScenarioProgress {
  handleSelectIntent: (intent: DialogStatisticIntentWithDuplicates) => void;
  selectedIntentId: number | undefined;
  scoresChanges?: IScoresChanges;
  intents: DialogStatisticIntentWithDuplicates[];
}

export const ScenarioProgress: FunctionComponent<IScenarioProgress> = (props) => {
  const { intents, selectedIntentId, handleSelectIntent } = props;
  const classes = useStyles();
  // const checkForScoresData = (intent: DialogStatisticIntentWithDuplicates): boolean => {
  //   return (
  //     (!intent.scores || intent.scores === 0) &&
  //     (!intent.color || (intent.color as number) === 0) &&
  //     (!intent.previousColor || (intent.previousColor as number) === 0) &&
  //     (!intent.duplicates || intent.duplicates.length === 0)
  //   );
  // };

  return (
    <Box overflow={'auto'}>
      <Box display="flex" flexWrap="nowrap" alignItems="center">
        {intents?.map((intent, index) => {
          return [
            <StyledTooltip
              classes={{ tooltip: classes.tooltip }}
              arrow
              key={intent.id}
              interactive
              title={
                <ScoreInfoTooltipContent
                  scoresChanges={{
                    scores: intent.scores,
                    color: intent.color,
                    previousColor: intent.previousColor,
                    duplicates: intent.duplicates
                  }}
                  actions={intent.medicalProtocolActions}
                  isInitialBlock={index === 0}
                />
              }
              placement="bottom"
              // disableHoverListener={checkForScoresData(intent)}
            >
              <Chip
                classes={{
                  root: intent.id == selectedIntentId ? clsx(classes.chip, classes.chipSelected) : classes.chip,
                  icon: classes.chipIcon
                }}
                color="primary"
                icon={<InputIcon />}
                label={index}
                onClick={() => handleSelectIntent(intent)}
              />
            </StyledTooltip>,
            index < intents?.length - 1 ? '-' : null
          ];
        })}
      </Box>
    </Box>
  );
};

const StyledTooltip = withStyles((theme) => {
  return {
    tooltip: {
      fontSize: theme.typography.body1.fontSize,
      maxWidth: 350
    }
  };
})(Tooltip);
