import type { I18nGroupManagementNs } from './interfaces.js';

export const GroupManagementDictionaryEn: I18nGroupManagementNs = {
  create: 'Create',
  selectaGroup: 'Select a group',
  members: 'Members',
  department: 'Department',
  createdBy: 'Created by',
  createdDate: 'Created date',
  membersCount: 'Members count',
  createGroupForm: {
    createGroup: 'Create group',
    organization: 'Organization',
    department: 'Department',
    title: 'Title',
    defaultDepartment: 'Default'
  },
  deleteGroupForm: {
    deleteGroup: 'Delete group',
    confirmationText: 'Are you sure you want to delete'
  },
  memberList: {
    noUsersAddedYet: 'No users added yet'
  }
};

export const GroupManagementDictionaryDe: I18nGroupManagementNs = {
  create: 'Erstellen',
  selectaGroup: 'Wählen Sie eine Gruppe aus',
  members: 'Mitglieder',
  department: 'Abteilung',
  createdBy: 'Erstellt von',
  createdDate: 'Erstellungsdatum',
  membersCount: 'Anzahl der Mitglieder',
  createGroupForm: {
    createGroup: 'Gruppe erstellen',
    organization: 'Organisation',
    department: 'Abteilung',
    title: 'Titel',
    defaultDepartment: 'Standard'
  },
  deleteGroupForm: {
    deleteGroup: 'Gruppe löschen',
    confirmationText: 'Sind Sie sicher, dass Sie löschen möchten'
  },
  memberList: {
    noUsersAddedYet: 'Noch keine Benutzer hinzugefügt'
  }
};

export const GroupManagementDictionarySw: I18nGroupManagementNs = {
  create: 'Create',
  selectaGroup: 'Select a group',
  members: 'Members',
  department: 'Department',
  createdBy: 'Created by',
  createdDate: 'Created date',
  membersCount: 'Members count',
  createGroupForm: {
    createGroup: 'Create group',
    organization: 'Organization',
    department: 'Department',
    title: 'Title',
    defaultDepartment: 'Default'
  },
  deleteGroupForm: {
    deleteGroup: 'Delete group',
    confirmationText: 'Are you sure you want to delete'
  },
  memberList: {
    noUsersAddedYet: 'No users added yet'
  }
};

export const GroupManagementDictionaryNo: I18nGroupManagementNs = {
  create: 'Opprett',
  selectaGroup: 'Velg en gruppe',
  members: 'Medlemmer',
  department: 'Avdeling',
  createdBy: 'Opprettet av',
  createdDate: 'Opprettet',
  membersCount: 'Medlemsantall',
  createGroupForm: {
    createGroup: 'Opprett gruppe',
    organization: 'Organisasjon',
    department: 'Avdeling',
    title: 'Tittel',
    defaultDepartment: 'Default'
  },
  deleteGroupForm: {
    deleteGroup: 'Slett gruppe',
    confirmationText: 'Er du sikker på at du vil slette'
  },
  memberList: {
    noUsersAddedYet: 'Ingen brukere lagt til ennå'
  }
};

export const GroupManagementDictionaryNl: I18nGroupManagementNs = {
  create: 'Create',
  selectaGroup: 'Select a group',
  members: 'Members',
  department: 'Department',
  createdBy: 'Created by',
  createdDate: 'Created date',
  membersCount: 'Members count',
  createGroupForm: {
    createGroup: 'Create group',
    organization: 'Organization',
    department: 'Department',
    title: 'Title',
    defaultDepartment: 'Default'
  },
  deleteGroupForm: {
    deleteGroup: 'Delete group',
    confirmationText: 'Are you sure you want to delete'
  },
  memberList: {
    noUsersAddedYet: 'No users added yet'
  }
};

export const GroupManagementDictionaryIs: I18nGroupManagementNs = {
  create: 'Create',
  selectaGroup: 'Select a group',
  members: 'Members',
  department: 'Department',
  createdBy: 'Created by',
  createdDate: 'Created date',
  membersCount: 'Members count',
  createGroupForm: {
    createGroup: 'Create group',
    organization: 'Organization',
    department: 'Department',
    title: 'Title',
    defaultDepartment: 'Default'
  },
  deleteGroupForm: {
    deleteGroup: 'Delete group',
    confirmationText: 'Are you sure you want to delete'
  },
  memberList: {
    noUsersAddedYet: 'No users added yet'
  }
};

export const GroupManagementDictionaryUk: I18nGroupManagementNs = {
  create: 'Create',
  selectaGroup: 'Select a group',
  members: 'Members',
  department: 'Department',
  createdBy: 'Created by',
  createdDate: 'Created date',
  membersCount: 'Members count',
  createGroupForm: {
    createGroup: 'Create group',
    organization: 'Organization',
    department: 'Department',
    title: 'Title',
    defaultDepartment: 'Default'
  },
  deleteGroupForm: {
    deleteGroup: 'Delete group',
    confirmationText: 'Are you sure you want to delete'
  },
  memberList: {
    noUsersAddedYet: 'No users added yet'
  }
};
