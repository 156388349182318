import React, { useCallback, useMemo } from 'react';
import type { UserDto, UserGroupDto } from '@/generated-api/index.js';
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete.js';
import { users as usersSelector } from 'app/selectors/userManagementSelectors.js';
import { useDispatch, useSelector } from 'react-redux';
import type UserManaged from 'app/models/userManagement/userManaged.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import { UserGroupClient } from 'app/apis/api.js';
import { GroupManagementActions } from 'app/components/GroupManagement/store/groupManagementActions.js';
import AddGroupMember from 'app/components/GroupManagement/GroupCard/MemberList/AddGroupMember/AddGroupMember.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGroupManagementNs } from '@/i18n/dictionaries/interfaces.js';

export type MemberListProps = { group: UserGroupDto };

const useStyles = makeStyles((theme) => {
  return {
    listContainer: {
      overflow: 'auto',
      paddingRight: theme.spacing(3)
    },

    listItem: {
      borderBottom: `1px solid ${theme.palette.divider}`
    }
  };
});

function MemberList({ group }: MemberListProps): JSX.Element {
  const classes = useStyles();
  const users = useSelector(usersSelector);

  const [translate] = useTranslation([I18nNamespace.GroupManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const usersMap = useMemo(() => {
    return users.reduce<Map<UserManaged['id'], UserManaged>>((map, user) => {
      map.set(user.id, user);
      return map;
    }, new Map());
  }, [users]);

  const usersToDisplay = useMemo(() => {
    return group.users;
  }, [usersMap, group]);

  const dispatch = useDispatch();

  const onDelete = useCallback(
    async (id: UserManaged['id']) => {
      if (!group) {
        return;
      }

      await UserGroupClient.userGroupDeleteUsers(group.id, {
        ids: [id]
      });

      dispatch(
        GroupManagementActions.groupMemberDeleted({
          id: group.id,
          userId: id
        })
      );
    },
    [dispatch, group]
  );

  const addUserControl = useMemo(() => <AddGroupMember group={group} />, [group]);

  if (!usersToDisplay.length) {
    return (
      <>
        {addUserControl}
        <Box color={'action.disabled'} py={4} textAlign={'center'}>
          <Typography> {translate(nameof.full<I18nGroupManagementNs>((n) => n.memberList.noUsersAddedYet))}</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      {addUserControl}
      <List className={classes.listContainer}>
        {usersToDisplay.map((u) => (
          <MemberItem key={u.id} user={u} onDelete={() => onDelete(u.id)} />
        ))}
      </List>
    </>
  );
}

function MemberItem({ user, onDelete }: { user: UserDto; onDelete: () => void }) {
  const classes = useStyles();

  return (
    <ListItem className={classes.listItem} dense>
      <ListItemText primary={user.name} secondary={user.companyName} />
      <ListItemSecondaryAction>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

export default MemberList;
