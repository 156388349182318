import React, { useContext, useMemo, useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  Typography,
  Tooltip,
  Menu,
  MenuItem
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy.js';
import LabelImportantIcon from '@material-ui/icons/LabelImportant.js';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles/index.js';
import { categoriesSelector } from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySelectors.js';
import type { GlobalAssetFullDto, GlobalAssetSampleDto, GlobalAssetIntentDto } from '@/generated-api/index.js';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import EditableIntent from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/EditableIntent.js';
import AddIntentDialog from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/AddIntentDialog.js';
import DeleteIntentDialog from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/DeleteIntentDialog.js';
import DeleteTemplateDialog from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/DeleteTemplateDialog.js';
import EditableTemplateTitle from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/EditableTemplateTitle.js';
import { GlobalAssetsLibraryContext } from 'app/components/GlobalAssetsLibrary/Context/GlobalAssetsLibraryContext.js';
import clsx from 'clsx';
import { GlobalAssetsClient } from 'app/apis/api.js';
import { companiesSelector } from 'app/selectors/companiesSelector.js';
import { CurrentUserSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import { addInfo } from 'app/actions/snackbar/snackbar.js';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import { LoadMode } from 'app/store/types.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';
import { useDrag } from 'react-dnd';

const ItemTypes = {
  TEMPLATE: 'template',
};

export type TemplatesListProps = { category: GlobalAssetFullDto; disableEdit: boolean };

function TemplatesList({ category, disableEdit }: TemplatesListProps): JSX.Element {
  const categories = useSelector(categoriesSelector);
  const { searchText } = useContext(GlobalAssetsLibraryContext); // Access searchText from context

  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const templates = useMemo(() => {
    const foundTemplates = categories.find((c) => c.id === category.id)?.intents ?? [];
  
    return foundTemplates
      .filter(template => {
        if (!searchText) {
          return true; // Include all templates if no search text
        }
  
        const lowercaseSearchText = searchText.toLowerCase();
        
        const titleMatches = template.title?.toLowerCase().includes(lowercaseSearchText);
        
        const anySampleMatches = template.samples.some(sample => 
          sample.text?.toLowerCase().includes(lowercaseSearchText)
        );
  
        return titleMatches || anySampleMatches;
      })
      .sort((a, b) => b.createdDate.localeCompare(a.createdDate));
  }, [category.id, categories, searchText]);
  

  const [addIntentDialogState, setAddIntentDialogState] = useState<{
    isAddIntentDialogOpen: boolean;
    template: null | GlobalAssetIntentDto;
  }>({
    isAddIntentDialogOpen: false,
    template: null
  });

  const closeAddIntentDialog = () => {
    setAddIntentDialogState({
      isAddIntentDialogOpen: false,
      template: null
    });
  };

  const onAddIntent = (template: GlobalAssetIntentDto) =>
    setAddIntentDialogState({
      isAddIntentDialogOpen: true,
      template
    });

  const [deleteIntentDialogState, setDeleteIntentDialogState] = useState<{
    isDeleteIntentDialogOpen: boolean;
    template: null | GlobalAssetIntentDto;
    intent: null | GlobalAssetSampleDto;
  }>({
    isDeleteIntentDialogOpen: false,
    template: null,
    intent: null
  });

  const onDeleteIntent = (template: GlobalAssetIntentDto, intent: GlobalAssetSampleDto) => {
    setDeleteIntentDialogState({
      isDeleteIntentDialogOpen: true,
      template,
      intent
    });
  };

  const closeDeleteIntentDialog = () => {
    setDeleteIntentDialogState({
      isDeleteIntentDialogOpen: false,
      template: null,
      intent: null
    });
  };

  const [deleteTemplateDialogState, setDeleteTemplateDialogState] = useState<{
    isDeleteTemplateDialogOpen: boolean;
    template: null | GlobalAssetIntentDto;
  }>({
    isDeleteTemplateDialogOpen: false,
    template: null
  });

  const onDeleteTemplate = (template: GlobalAssetIntentDto) => {
    setDeleteTemplateDialogState({
      isDeleteTemplateDialogOpen: true,
      template
    });
  };

  const closeDeleteTemplateDialog = () => {
    setDeleteTemplateDialogState({
      isDeleteTemplateDialogOpen: false,
      template: null
    });
  };

  if (!templates.length) {
    return (
      <Box color={'action.disabled'} py={4} textAlign={'center'}>
        <Typography>{translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.noTemplatesAdded))}</Typography>
      </Box>
    );
  }

  return (
    <>
      {templates.map((template) => (
        <TemplateItem
          disableEdit={disableEdit}
          key={template.id}
          template={template}
          onAddIntent={() => onAddIntent(template)}
          onDeleteIntent={(intent) => onDeleteIntent(template, intent)}
          onDeleteTemplate={() => onDeleteTemplate(template)}
        />
      ))}

      <AddIntentDialog
        isOpen={addIntentDialogState.isAddIntentDialogOpen}
        onClose={closeAddIntentDialog}
        template={addIntentDialogState.template}
      />

      <DeleteIntentDialog
        isOpen={deleteIntentDialogState.isDeleteIntentDialogOpen}
        onClose={closeDeleteIntentDialog}
        intent={deleteIntentDialogState.intent}
        template={deleteIntentDialogState.template}
        categoryId={category.id}
      />

      <DeleteTemplateDialog
        isOpen={deleteTemplateDialogState.isDeleteTemplateDialogOpen}
        onClose={closeDeleteTemplateDialog}
        template={deleteTemplateDialogState.template}
      />
    </>
  );
}

const useTemplateItemStyles = makeStyles((theme) => {
  return {
    root: {
      margin: theme.spacing(2, 0)
    },
    selected: {
      outline: '0.2rem solid'
    },
    expand: {
      transform: (props: { isExpanded: boolean }) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'),
      marginRight: theme.spacing(1),
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandCaption: {
      cursor: 'pointer'
    }
  };
});

function TemplateItem({
  template,
  onAddIntent,
  onDeleteTemplate,
  onDeleteIntent,
  disableEdit
}: {
  template: GlobalAssetIntentDto;
  onDeleteIntent: (intent: GlobalAssetSampleDto) => void;
  onDeleteTemplate: () => void;
  onAddIntent: () => void;
  disableEdit: boolean;
}) {
  const countToDisplayOnCollapsed = 3;

  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [isExpanded, setIsExpanded] = useState(false);
  const expandable = template.samples.length > countToDisplayOnCollapsed;
  const dispatch = useDispatch();

  const companies = useSelector(companiesSelector);
  const currentUser = useSelector(CurrentUserSelector);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);

  const samplesArray = Array.isArray(template.samples) ? template.samples : [];

  const [topIntents, collapsibleIntents] = useMemo(
    () =>
      expandable
        ? [samplesArray.slice(0, countToDisplayOnCollapsed), samplesArray.slice(countToDisplayOnCollapsed)]
        : [samplesArray, []],
    [expandable, samplesArray, countToDisplayOnCollapsed]
  );

  const classes = useTemplateItemStyles({ isExpanded });
  const toggleExpand = () => setIsExpanded((e) => !e);
  const isEmpty = !template.samples.length;
  const { selectedTemplate, setSelectedTemplate } = useContext(GlobalAssetsLibraryContext);
  const selected = selectedTemplate?.id === template.id;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const popupCopyToCompanyMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeCopyToCompanyMenu = () => {
    setAnchorEl(null);
  };

  // Memoized template with samples
  const memoizedTemplate = useMemo(() => ({ ...template, samples: [...template.samples] }), [template.samples]);

  const copyTemplateToCompany = async (companyId) => {
    try {
      closeCopyToCompanyMenu();
      const targetCompany = companies.find((x) => x.id === currentUser.companyId);
      if (!targetCompany) throw new Error(translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.noTargetOrganizationFound)));
      await GlobalAssetsClient.globalAssetsCopyIntentToCompany(template.id, companyId);
      addInfo({
        infoText: `${translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.intent))} ${
          template.title
        } ${translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.copiedTo))} ${targetCompany.name}`
      });
      // Also, addInfo does not work?
      // Refresh global assets library
      dispatch(globalAssetsLibrarySlice.actions.load(LoadMode.Hard));
    } catch (e) {
      logger.error(e);
    }
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.TEMPLATE,
    item: memoizedTemplate,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [memoizedTemplate]);

  return (
    <Card
      ref={drag}
      classes={{
        root: classes.root,
      }}
      className={clsx({ [classes.selected]: selected })}
      onClick={() => setSelectedTemplate(template)}
    >
      <CardContent>
        <Box mb={1}>
          <EditableTemplateTitle disable={disableEdit} template={template} />
        </Box>
        {isEmpty && (
          <Box color={'action.disabled'} mt={3} mb={1} textAlign={'center'}>
            <Typography>{translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.noIntentsAdded))}</Typography>
          </Box>
        )}
        {topIntents.map((i) => (
          <EditableIntent
            disableEdit={disableEdit}
            key={i.id}
            intent={i}
            template={template}
            onDelete={() => onDeleteIntent(i)}
          />
        ))}
        {expandable && !isExpanded && (
          <Box py={1} onClick={toggleExpand} className={classes.expandCaption}>
            {`...${translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.and))} ${
              template.samples.length - countToDisplayOnCollapsed
            } ${translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.more))}`}
          </Box>
        )}
        {expandable && (
          <Collapse in={isExpanded} unmountOnExit>
            {collapsibleIntents.map((i) => (
              <EditableIntent
                disableEdit={disableEdit}
                key={i.id}
                intent={i}
                template={template}
                onDelete={() => onDeleteIntent(i)}
              />
            ))}
          </Collapse>
        )}
      </CardContent>

      <CardActions>
        <Box display="flex" flexGrow={1} alignItems="center" px={1}>
          <Box display="flex" flexGrow={1}>
            <Button onClick={onAddIntent}>
              {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.addSample))}
            </Button>
            {!disableEdit ? (
              <Box ml={1}>
                <Button onClick={onDeleteTemplate}>
                  {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.deleteIntent))}
                </Button>
              </Box>
            ) : null}
          </Box>
          {isSuperAdmin && (
            <Tooltip
              title={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.copyIntentToOrganization))}
            >
              <IconButton onClick={popupCopyToCompanyMenu}>
                <LabelImportantIcon />
              </IconButton>
            </Tooltip>
          )}
          <Menu
            id={`${template.id}-copy-to-company-menu`}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={closeCopyToCompanyMenu}
            PaperProps={{
              style: {
                maxHeight: 500,
                width: '50ch'
              }
            }}
          >
            {companies
              .filter((x) => x.id !== currentUser.companyId)
              .map((option) => (
                <MenuItem key={option.id} value={option.id} onClick={() => copyTemplateToCompany(option.id)}>
                  {option.name}
                </MenuItem>
              ))}
          </Menu>

          <Tooltip title={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templatesList.copyIntentId))}>
            <IconButton onClick={() => navigator.clipboard.writeText(`${template.id}`)}>
              <FileCopyIcon />
            </IconButton>
          </Tooltip>
          {expandable && (
            <IconButton
              className={classes.expand}
              onClick={toggleExpand}
              aria-expanded={isExpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
        </Box>
      </CardActions>
    </Card>
  );
}

export default TemplatesList;
