import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles
} from '@material-ui/core';
import LoadingButton from 'app/components/LoadingButton.js';
import useScenarioMutations from 'app/mutations/scenarioMutations.js';
import { CompaniesSelector, ScenarioSelectors } from 'app/selectors/index.js';
import { LoadingStatus } from 'app/store/types.js';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type SharedScenarioSettingsDialogProps = {
  open: boolean;
  onClose: () => void;
  scenarioId: number;
};

const useStyles = makeStyles({
  divider: {
    marginTop: 10,
    marginBottom: 10
  },
  select: {
    minWidth: 150
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
});

export const SharedScenarioSettingsDialog = (props: SharedScenarioSettingsDialogProps) => {
  const { open, onClose, scenarioId } = props;
  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [convert, setConvert] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState<number | ''>('');

  const companies = useSelector(CompaniesSelector.companiesSelector)?.filter((c) => !!c.name) ?? [];
  const scenario = useSelector(ScenarioSelectors.getScenarioById(scenarioId));
  const [sharedCompanies, setSelectedSharedCompanies] = useState<number[]>(
    scenario?.sharedCompanies?.map((c) => c.id) ?? []
  );

  useEffect(() => {
    setSelectedSharedCompanies(scenario?.sharedCompanies?.map((c) => c.id) ?? []);
  }, [scenario?.sharedCompanies]);

  const { addCompaniesToShared, removeCompaniesFromShared, convertSharedToScenario } = useScenarioMutations();

  const onSave = async () => {
    const originalSharedCompanies = scenario?.sharedCompanies?.map((c) => c.id) ?? [];

    const sharedCompaniesToAdd = sharedCompanies.filter((id) => !originalSharedCompanies.includes(id));
    const sharedCompaniesToRemove = originalSharedCompanies.filter((id) => !sharedCompanies.includes(id));

    if (sharedCompaniesToAdd.length) {
      await addCompaniesToShared.mutateAsync({ scenarioId, companies: sharedCompaniesToAdd });
    }

    if (sharedCompaniesToRemove.length) {
      await removeCompaniesFromShared.mutateAsync({ scenarioId, companies: sharedCompaniesToRemove });
    }
    onClose();
  };

  const hasSharedCompaniesChanged = useMemo(() => {
    const ids = scenario?.sharedCompanies?.map((c) => c.id) ?? [];
    if (!ids.length && !sharedCompanies.length) return false;
    return ids.length !== sharedCompanies.length || !ids.every((id) => sharedCompanies.includes(id));
  }, [scenario?.sharedCompanies, sharedCompanies]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.sharedScenarioSettingsDialog.sharedScenarioSettings))}
      </DialogTitle>
      <DialogContent>
        <Box>
          <FormControl>
            <Typography>
              {translate(
                nameof.full<I18nScenarioEditorNs>((n) => n.sharedScenarioSettingsDialog.organizationsSharedWith)
              )}
              :
            </Typography>
            <Grid container item xs={12}>
              <Select
                className={classes.select}
                multiple
                displayEmpty
                label={translate(
                  nameof.full<I18nScenarioEditorNs>((n) => n.sharedScenarioSettingsDialog.selectOrganizations)
                )}
                id="shared-company-select"
                placeholder="None"
                value={sharedCompanies}
                onChange={(e) => setSelectedSharedCompanies(e.target.value as number[])}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {!(selected as number[])?.length ? <Typography>None</Typography> : null}
                    {(selected as number[]).map((value) => (
                      <Chip key={value} label={companies?.find((c) => c.id === value)?.name} className={classes.chip} />
                    ))}
                  </div>
                )}
              >
                {companies?.map((company) => (
                  <MenuItem value={company.id} key={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </FormControl>
          <Box className={classes.divider}>
            {convert ? (
              <Grid container direction="row" alignItems="flex-end" spacing={1}>
                <Grid item>
                  <FormControl className={classes.select}>
                    <InputLabel id="convert-company-select-label">
                      {translate(
                        nameof.full<I18nScenarioEditorNs>((n) => n.sharedScenarioSettingsDialog.selectAnOrganization)
                      )}
                    </InputLabel>
                    <Select
                      id="convert-company-select"
                      labelId="convert-company-select-label"
                      value={selectedCompany}
                      placeholder={translate(
                        nameof.full<I18nScenarioEditorNs>((n) => n.sharedScenarioSettingsDialog.selectAnOrganization)
                      )}
                      onChange={(e) => setSelectedCompany(e.target.value as number)}
                      fullWidth
                    >
                      {companies?.map((company) => (
                        <MenuItem value={company.id} key={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    disabled={convertSharedToScenario.isLoading}
                    onClick={() => {
                      setConvert(false);
                      setSelectedCompany('');
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    disabled={!selectedCompany || convertSharedToScenario.isLoading}
                    variant="outlined"
                    color="default"
                    onClick={async () => {
                      await convertSharedToScenario.mutateAsync({ scenarioId, companyId: Number(selectedCompany) });
                      setConvert(false);
                      onClose();
                    }}
                    loadingStatus={convertSharedToScenario.isLoading ? LoadingStatus.Loading : LoadingStatus.Idle}
                  >
                    {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioActionsSection.convert))}
                  </LoadingButton>
                </Grid>
              </Grid>
            ) : (
              <Button onClick={() => setConvert(true)} variant="contained" color="primary">
                {translate(
                  nameof.full<I18nScenarioEditorNs>((n) => n.sharedScenarioSettingsDialog.convertToNormalScenario)
                )}
              </Button>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={addCompaniesToShared.isLoading || removeCompaniesFromShared.isLoading}
          onClick={onClose}
        >
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={
            convert ||
            !hasSharedCompaniesChanged ||
            addCompaniesToShared.isLoading ||
            removeCompaniesFromShared.isLoading
          }
          onClick={onSave}
          loadingStatus={
            addCompaniesToShared.isLoading || removeCompaniesFromShared.isLoading
              ? LoadingStatus.Loading
              : LoadingStatus.Idle
          }
        >
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.save))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default SharedScenarioSettingsDialog;
