import React, { useCallback, useEffect } from 'react';

interface CursorPosition {
  x: number | null;
  y: number | null;
}

const defaultCursorPosition = {
  x: null,
  y: null
};

interface UseCursorPosition {
  cursorPosition: CursorPosition;
  setCursorPosition: (cursorPosition: CursorPosition) => void;
  resetCursorPosition: () => void;
}

/**
 * @param initialCursorPosition Initial cursor position
 * @returns Cursor position and helper methods to set and reset the current position
 */
const useCursorPosition = (initialCursorPosition: CursorPosition = defaultCursorPosition): UseCursorPosition => {
  const [cursorPosition, setCursorPosition] = React.useState<CursorPosition>(initialCursorPosition);

  const resetCursorPosition = useCallback(() => setCursorPosition(initialCursorPosition), [initialCursorPosition]);

  // reset cursor position if initialCursorPosition changes
  useEffect(resetCursorPosition, [initialCursorPosition, resetCursorPosition]);

  return {
    cursorPosition,
    setCursorPosition,
    resetCursorPosition
  };
};

export default useCursorPosition;
