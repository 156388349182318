import { useSelector } from 'react-redux';

import { UserSelectors } from 'app/selectors/index.js';
import type { RoleId } from 'app/models/users/role.js';

const useHasAccess = (roles?: RoleId[]): boolean => {
  const userRole = useSelector(UserSelectors.RoleSelector);
  const currentUser = useSelector(UserSelectors.CurrentUserSelector);

  if (!roles) {
    return true;
  }

  if (!roles.length) {
    return true;
  }

  if (!currentUser) {
    return false;
  }

  return !!roles.includes(userRole);
};

export default useHasAccess;
