import type { I18nCourseManagementNs } from './interfaces.js';

export const CourseManagementDictionaryEn: I18nCourseManagementNs = {
  selectaCourse: 'Select a course',
  scheduledCourse: 'Scheduled course',
  updateScheduledCourse: 'Update scheduled course',
  courses: 'Courses',
  create: 'Create',
  scenarios: 'scenarios',
  noScenariosYet: 'No scenarios yet',
  courseListIsEmpty: 'Course list is empty',
  createCourse: 'Create course',
  deleteCourse: 'Delete course',
  scenarioList: {
    addScenario: 'Add scenario',
    selectScenario: 'Select scenario',
    noScenariosAvailable: 'No scenarios available',
    name: 'Name',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  courseStatus: {
    ongoing: 'Ongoing',
    scheduled: 'Scheduled',
    completed: 'Completed',
    to: 'to',
    filter: 'Filter',
    noScheduledCourses: 'No scheduled courses'
  },
  courseForm: {
    title: 'Title',
    organization: 'Organization'
  },
  createScheduledCourseForm: {
    scheduleCourse: 'Schedule course',
    department: 'Department',
    startDate: 'Start date',
    endDate: 'End date'
  },
  table: {
    users: 'Users',
    groups: 'Groups',
    repeat: 'Repeat',
    never: 'Never'
  },
  deleteScheduledCourseForm: {
    deleteScheduledCourse: 'Delete scheduled course',
    confirmationText: 'Are you really sure you want to remove this from the schedule of'
  },
  scheduledCourseCard: {
    backTo: 'Back to',
    created: 'Created',
    createdBy: 'Created by',
    addUser: 'Add user',
    users: 'Users',
    groups: 'Groups',
    notifications: 'Notifications',
    name: 'Name',
    added: 'Added',
    defaultDepartment: 'Default',
    scenarios: 'Scenarios',
    schedule: 'Schedule'
  },
  addUserForm: {
    selectUser: 'Select user',
    noUsersAvailable: 'No users available'
  },
  removeUserForm: {
    removeUser: 'Remove user',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  groups: {
    addGroup: 'Add group',
    titel: 'Title',
    added: 'Added',
    confirmationText: 'Are you really sure you want to remove',
    removeGroup: 'Remove group',
    from: 'from',
    selectGroup: 'Select group',
    noGroupsAvailable: 'No groups available'
  },
  notifications: {
    addNotification: 'Add notification',
    date: 'Date',
    customText: 'Custom text',
    status: 'Status',
    dateAndTime: 'Date and time',
    optional: 'optional',
    done: 'Done',
    pending: 'Pending',
    editNotification: 'Edit notification',
    removeNotification: 'Remove notification',
    confirmationText: 'Are you sure you want to delete this notification?'
  },
  myCourseCard: {
    scenario: 'Scenario',
    status: 'Status',
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    call: 'Call',
    dispatchedTo: 'dispatched to',
    user: 'User'
  }
};

export const CourseManagementDictionaryDe: I18nCourseManagementNs = {
  selectaCourse: 'Wählen Sie einen Kurs aus',
  scheduledCourse: 'Geplanter Kurs',
  updateScheduledCourse: 'Geplanten Kurs aktualisieren',
  courses: 'Kurse',
  create: 'Erstellen',
  scenarios: 'Szenarien',
  noScenariosYet: 'Noch keine Szenarien',
  courseListIsEmpty: 'Kursliste ist leer',
  createCourse: 'Kurs erstellen',
  deleteCourse: 'Kurs löschen',
  scenarioList: {
    addScenario: 'Szenario hinzufügen',
    selectScenario: 'Szenario auswählen',
    noScenariosAvailable: 'Keine Szenarien verfügbar',
    name: 'Name',
    confirmationText: 'Sind Sie wirklich sicher, dass Sie',
    from: 'von'
  },
  courseStatus: {
    ongoing: 'Laufend',
    scheduled: 'Geplant',
    completed: 'Abgeschlossen',
    to: 'bis',
    filter: 'Filter',
    noScheduledCourses: 'Keine geplanten Kurse'
  },
  courseForm: {
    title: 'Titel',
    organization: 'Organisation'
  },
  createScheduledCourseForm: {
    scheduleCourse: 'Kurs planen',
    department: 'Abteilung',
    startDate: 'Startdatum',
    endDate: 'Enddatum'
  },
  table: {
    users: 'Benutzer',
    groups: 'Gruppen',
    repeat: 'Wiederholen',
    never: 'Nie'
  },
  deleteScheduledCourseForm: {
    deleteScheduledCourse: 'Geplanten Kurs löschen',
    confirmationText: 'Sind Sie wirklich sicher, dass Sie dies aus dem Zeitplan von entfernen möchten'
  },
  scheduledCourseCard: {
    backTo: 'Zurück zu',
    created: 'Erstellt',
    createdBy: 'Erstellt von',
    addUser: 'Benutzer hinzufügen',
    users: 'Benutzer',
    groups: 'Gruppen',
    notifications: 'Benachrichtigungen',
    name: 'Name',
    added: 'Hinzugefügt',
    defaultDepartment: 'Standard',
    scenarios: 'Szenarien',
    schedule: 'Zeitplan'
  },
  addUserForm: {
    selectUser: 'Benutzer auswählen',
    noUsersAvailable: 'Keine Benutzer verfügbar'
  },
  removeUserForm: {
    removeUser: 'Benutzer entfernen',
    confirmationText: 'Sind Sie wirklich sicher, dass Sie',
    from: 'von'
  },
  groups: {
    addGroup: 'Gruppe hinzufügen',
    titel: 'Titel',
    added: 'Hinzugefügt',
    confirmationText: 'Sind Sie wirklich sicher, dass Sie',
    removeGroup: 'Gruppe entfernen',
    from: 'von',
    selectGroup: 'Gruppe auswählen',
    noGroupsAvailable: 'Keine Gruppen verfügbar'
  },
  notifications: {
    addNotification: 'Benachrichtigung hinzufügen',
    date: 'Datum',
    customText: 'Benutzerdefinierter Text',
    status: 'Status',
    dateAndTime: 'Datum und Uhrzeit',
    optional: 'optional',
    done: 'Erledigt',
    pending: 'Ausstehend',
    editNotification: 'Benachrichtigung bearbeiten',
    removeNotification: 'Benachrichtigung entfernen',
    confirmationText: 'Sind Sie sicher, dass Sie diese Benachrichtigung löschen möchten?'
  },
  myCourseCard: {
    scenario: 'Szenario',
    status: 'Status',
    procedureScore: 'Verfahrenspunktzahl',
    reachTimeScore: 'Erreichbarkeitspunktzahl',
    transitionScore: 'Übergangspunktzahl',
    call: 'Anruf',
    dispatchedTo: 'weitergeleitet an',
    user: 'Benutzer'
  }
};

export const CourseManagementDictionarySw: I18nCourseManagementNs = {
  selectaCourse: 'Select a course',
  scheduledCourse: 'Scheduled course',
  updateScheduledCourse: 'Update scheduled course',
  courses: 'Courses',
  create: 'Create',
  scenarios: 'scenarios',
  noScenariosYet: 'No scenarios yet',
  courseListIsEmpty: 'Course list is empty',
  createCourse: 'Create course',
  deleteCourse: 'Delete course',
  scenarioList: {
    addScenario: 'Add scenario',
    selectScenario: 'Select scenario',
    noScenariosAvailable: 'No scenarios available',
    name: 'Name',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  courseStatus: {
    ongoing: 'Ongoing',
    scheduled: 'Scheduled',
    completed: 'Completed',
    to: 'to',
    filter: 'Filter',
    noScheduledCourses: 'No scheduled courses'
  },
  courseForm: {
    title: 'Title',
    organization: 'Organization'
  },
  createScheduledCourseForm: {
    scheduleCourse: 'Schedule course',
    department: 'Department',
    startDate: 'Start date',
    endDate: 'End date'
  },
  table: {
    users: 'Users',
    groups: 'Groups',
    repeat: 'Repeat',
    never: 'Never'
  },
  deleteScheduledCourseForm: {
    deleteScheduledCourse: 'Delete scheduled course',
    confirmationText: 'Are you really sure you want to remove this from the schedule of'
  },
  scheduledCourseCard: {
    backTo: 'Back to',
    created: 'Created',
    createdBy: 'Created by',
    addUser: 'Add user',
    users: 'Users',
    groups: 'Groups',
    notifications: 'Notifications',
    name: 'Name',
    added: 'Added',
    defaultDepartment: 'Default',
    scenarios: 'Scenarios',
    schedule: 'Schedule'
  },
  addUserForm: {
    selectUser: 'Select user',
    noUsersAvailable: 'No users available'
  },
  removeUserForm: {
    removeUser: 'Remove user',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  groups: {
    addGroup: 'Add group',
    titel: 'Title',
    added: 'Added',
    confirmationText: 'Are you really sure you want to remove',
    removeGroup: 'Remove group',
    from: 'from',
    selectGroup: 'Select group',
    noGroupsAvailable: 'No groups available'
  },
  notifications: {
    addNotification: 'Add notification',
    date: 'Date',
    customText: 'Custom text',
    status: 'Status',
    dateAndTime: 'Date and time',
    optional: 'optional',
    done: 'Done',
    pending: 'Pending',
    editNotification: 'Edit notification',
    removeNotification: 'Remove notification',
    confirmationText: 'Are you sure you want to delete this notification?'
  },
  myCourseCard: {
    scenario: 'Scenario',
    status: 'Status',
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    call: 'Call',
    dispatchedTo: 'dispatched to',
    user: 'User'
  }
};

export const CourseManagementDictionaryNo: I18nCourseManagementNs = {
  selectaCourse: 'Velg et kurs',
  scheduledCourse: 'Planlagt kurs',
  updateScheduledCourse: 'Rediger planlagt kurs',
  courses: 'Kurs',
  create: 'Opprett',
  scenarios: 'scenarier',
  noScenariosYet: 'Ingen scenarier ennå',
  courseListIsEmpty: 'Kurslisten er tom',
  createCourse: 'Opprett kurs',
  deleteCourse: 'Slett kurs',
  scenarioList: {
    addScenario: 'Legg til scenario',
    selectScenario: 'Velg scenario',
    noScenariosAvailable: 'Ingen scenarier tilgjengelige',
    name: 'Navn',
    confirmationText: 'Er du sikker på at du vil fjerne',
    from: 'fra'
  },
  courseStatus: {
    ongoing: 'Pågående',
    scheduled: 'Planlagt',
    completed: 'Ferdig',
    to: 'til',
    filter: 'Filter',
    noScheduledCourses: 'Ingen planlagte kurs'
  },
  courseForm: {
    title: 'Tittel',
    organization: 'Organisasjon'
  },
  createScheduledCourseForm: {
    scheduleCourse: 'Planlegg kurs',
    department: 'Avdeling',
    startDate: 'Startdato',
    endDate: 'Sluttdato'
  },
  table: {
    users: 'Brukere',
    groups: 'Grupper',
    repeat: 'Gjenta',
    never: 'Aldri'
  },
  deleteScheduledCourseForm: {
    deleteScheduledCourse: 'Slett planlagt kurs',
    confirmationText: 'Er du sikker på at du ønsker å slette det planlagte kurset'
  },
  scheduledCourseCard: {
    backTo: 'Tilbake til',
    created: 'Opprettet',
    createdBy: 'Opprettet av',
    addUser: 'Legg til bruker',
    users: 'Brukere',
    groups: 'Grupper',
    notifications: 'Varsler',
    name: 'Navn',
    added: 'Lagt til',
    defaultDepartment: 'Default',
    scenarios: 'Scenarier',
    schedule: 'Tidsplan'
  },
  addUserForm: {
    selectUser: 'Velg bruker',
    noUsersAvailable: 'Ingen tilgjengelige brukere'
  },
  removeUserForm: {
    removeUser: 'Fjern bruker',
    confirmationText: 'Er du sikker på at du ønsker å fjerne',
    from: 'fra'
  },
  groups: {
    addGroup: 'Legg til gruppe',
    titel: 'Tittel',
    added: 'Lagt til',
    confirmationText: 'Er du sikker på at du vil fjerne',
    removeGroup: 'Fjern gruppe',
    from: 'fra',
    selectGroup: 'Velg gruppe',
    noGroupsAvailable: 'Ingen grupper tilgjengelige'
  },
  notifications: {
    addNotification: 'Legg til varsel',
    date: 'Dato',
    customText: 'Fritekst',
    status: 'Status',
    dateAndTime: 'Dato og tid',
    optional: 'valgfri',
    done: 'Ferdig',
    pending: 'Pending',
    editNotification: 'Rediger varsel',
    removeNotification: 'Fjern varsel',
    confirmationText: 'Er du sikker på at du vil fjerne dette varselet?'
  },
  myCourseCard: {
    scenario: 'Scenario',
    status: 'Status',
    procedureScore: 'Indekspoeng',
    reachTimeScore: 'Tidspoeng',
    transitionScore: 'Overgangspoeng',
    call: 'Ring',
    dispatchedTo: 'oppringt til',
    user: 'Bruker'
  }
};

export const CourseManagementDictionaryNl: I18nCourseManagementNs = {
  selectaCourse: 'Select a course',
  scheduledCourse: 'Scheduled course',
  updateScheduledCourse: 'Update scheduled course',
  courses: 'Courses',
  create: 'Create',
  scenarios: 'scenarios',
  noScenariosYet: 'No scenarios yet',
  courseListIsEmpty: 'Course list is empty',
  createCourse: 'Create course',
  deleteCourse: 'Delete course',
  scenarioList: {
    addScenario: 'Add scenario',
    selectScenario: 'Select scenario',
    noScenariosAvailable: 'No scenarios available',
    name: 'Name',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  courseStatus: {
    ongoing: 'Ongoing',
    scheduled: 'Scheduled',
    completed: 'Completed',
    to: 'to',
    filter: 'Filter',
    noScheduledCourses: 'No scheduled courses'
  },
  courseForm: {
    title: 'Title',
    organization: 'Organization'
  },
  createScheduledCourseForm: {
    scheduleCourse: 'Schedule course',
    department: 'Department',
    startDate: 'Start date',
    endDate: 'End date'
  },
  table: {
    users: 'Users',
    groups: 'Groups',
    repeat: 'Repeat',
    never: 'Never'
  },
  deleteScheduledCourseForm: {
    deleteScheduledCourse: 'Delete scheduled course',
    confirmationText: 'Are you really sure you want to remove this from the schedule of'
  },
  scheduledCourseCard: {
    backTo: 'Back to',
    created: 'Created',
    createdBy: 'Created by',
    addUser: 'Add user',
    users: 'Users',
    groups: 'Groups',
    notifications: 'Notifications',
    name: 'Name',
    added: 'Added',
    defaultDepartment: 'Default',
    scenarios: 'Scenarios',
    schedule: 'Schedule'
  },
  addUserForm: {
    selectUser: 'Select user',
    noUsersAvailable: 'No users available'
  },
  removeUserForm: {
    removeUser: 'Remove user',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  groups: {
    addGroup: 'Add group',
    titel: 'Title',
    added: 'Added',
    confirmationText: 'Are you really sure you want to remove',
    removeGroup: 'Remove group',
    from: 'from',
    selectGroup: 'Select group',
    noGroupsAvailable: 'No groups available'
  },
  notifications: {
    addNotification: 'Add notification',
    date: 'Date',
    customText: 'Custom text',
    status: 'Status',
    dateAndTime: 'Date and time',
    optional: 'optional',
    done: 'Done',
    pending: 'Pending',
    editNotification: 'Edit notification',
    removeNotification: 'Remove notification',
    confirmationText: 'Are you sure you want to delete this notification?'
  },
  myCourseCard: {
    scenario: 'Scenario',
    status: 'Status',
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    call: 'Call',
    dispatchedTo: 'dispatched to',
    user: 'User'
  }
};

export const CourseManagementDictionaryIs: I18nCourseManagementNs = {
  selectaCourse: 'Select a course',
  scheduledCourse: 'Scheduled course',
  updateScheduledCourse: 'Update scheduled course',
  courses: 'Courses',
  create: 'Create',
  scenarios: 'scenarios',
  noScenariosYet: 'No scenarios yet',
  courseListIsEmpty: 'Course list is empty',
  createCourse: 'Create course',
  deleteCourse: 'Delete course',
  scenarioList: {
    addScenario: 'Add scenario',
    selectScenario: 'Select scenario',
    noScenariosAvailable: 'No scenarios available',
    name: 'Name',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  courseStatus: {
    ongoing: 'Ongoing',
    scheduled: 'Scheduled',
    completed: 'Completed',
    to: 'to',
    filter: 'Filter',
    noScheduledCourses: 'No scheduled courses'
  },
  courseForm: {
    title: 'Title',
    organization: 'Organization'
  },
  createScheduledCourseForm: {
    scheduleCourse: 'Schedule course',
    department: 'Department',
    startDate: 'Start date',
    endDate: 'End date'
  },
  table: {
    users: 'Users',
    groups: 'Groups',
    repeat: 'Repeat',
    never: 'Never'
  },
  deleteScheduledCourseForm: {
    deleteScheduledCourse: 'Delete scheduled course',
    confirmationText: 'Are you really sure you want to remove this from the schedule of'
  },
  scheduledCourseCard: {
    backTo: 'Back to',
    created: 'Created',
    createdBy: 'Created by',
    addUser: 'Add user',
    users: 'Users',
    groups: 'Groups',
    notifications: 'Notifications',
    name: 'Name',
    added: 'Added',
    defaultDepartment: 'Default',
    scenarios: 'Scenarios',
    schedule: 'Schedule'
  },
  addUserForm: {
    selectUser: 'Select user',
    noUsersAvailable: 'No users available'
  },
  removeUserForm: {
    removeUser: 'Remove user',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  groups: {
    addGroup: 'Add group',
    titel: 'Title',
    added: 'Added',
    confirmationText: 'Are you really sure you want to remove',
    removeGroup: 'Remove group',
    from: 'from',
    selectGroup: 'Select group',
    noGroupsAvailable: 'No groups available'
  },
  notifications: {
    addNotification: 'Add notification',
    date: 'Date',
    customText: 'Custom text',
    status: 'Status',
    dateAndTime: 'Date and time',
    optional: 'optional',
    done: 'Done',
    pending: 'Pending',
    editNotification: 'Edit notification',
    removeNotification: 'Remove notification',
    confirmationText: 'Are you sure you want to delete this notification?'
  },
  myCourseCard: {
    scenario: 'Scenario',
    status: 'Status',
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    call: 'Call',
    dispatchedTo: 'dispatched to',
    user: 'User'
  }
};

export const CourseManagementDictionaryUk: I18nCourseManagementNs = {
  selectaCourse: 'Select a course',
  scheduledCourse: 'Scheduled course',
  updateScheduledCourse: 'Update scheduled course',
  courses: 'Courses',
  create: 'Create',
  scenarios: 'scenarios',
  noScenariosYet: 'No scenarios yet',
  courseListIsEmpty: 'Course list is empty',
  createCourse: 'Create course',
  deleteCourse: 'Delete course',
  scenarioList: {
    addScenario: 'Add scenario',
    selectScenario: 'Select scenario',
    noScenariosAvailable: 'No scenarios available',
    name: 'Name',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  courseStatus: {
    ongoing: 'Ongoing',
    scheduled: 'Scheduled',
    completed: 'Completed',
    to: 'to',
    filter: 'Filter',
    noScheduledCourses: 'No scheduled courses'
  },
  courseForm: {
    title: 'Title',
    organization: 'Organization'
  },
  createScheduledCourseForm: {
    scheduleCourse: 'Schedule course',
    department: 'Department',
    startDate: 'Start date',
    endDate: 'End date'
  },
  table: {
    users: 'Users',
    groups: 'Groups',
    repeat: 'Repeat',
    never: 'Never'
  },
  deleteScheduledCourseForm: {
    deleteScheduledCourse: 'Delete scheduled course',
    confirmationText: 'Are you really sure you want to remove this from the schedule of'
  },
  scheduledCourseCard: {
    backTo: 'Back to',
    created: 'Created',
    createdBy: 'Created by',
    addUser: 'Add user',
    users: 'Users',
    groups: 'Groups',
    notifications: 'Notifications',
    name: 'Name',
    added: 'Added',
    defaultDepartment: 'Default',
    scenarios: 'Scenarios',
    schedule: 'Schedule'
  },
  addUserForm: {
    selectUser: 'Select user',
    noUsersAvailable: 'No users available'
  },
  removeUserForm: {
    removeUser: 'Remove user',
    confirmationText: 'Are you really sure you want to remove',
    from: 'from'
  },
  groups: {
    addGroup: 'Add group',
    titel: 'Title',
    added: 'Added',
    confirmationText: 'Are you really sure you want to remove',
    removeGroup: 'Remove group',
    from: 'from',
    selectGroup: 'Select group',
    noGroupsAvailable: 'No groups available'
  },
  notifications: {
    addNotification: 'Add notification',
    date: 'Date',
    customText: 'Custom text',
    status: 'Status',
    dateAndTime: 'Date and time',
    optional: 'optional',
    done: 'Done',
    pending: 'Pending',
    editNotification: 'Edit notification',
    removeNotification: 'Remove notification',
    confirmationText: 'Are you sure you want to delete this notification?'
  },
  myCourseCard: {
    scenario: 'Scenario',
    status: 'Status',
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    call: 'Call',
    dispatchedTo: 'dispatched to',
    user: 'User'
  }
};
