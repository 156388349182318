import { handleActions } from 'redux-actions';
import { Type as SnackbarActions } from 'app/actions/snackbar/snackbar.js';
import { Guid } from 'guid-typescript';
import type { IError, IInfo, IWarning } from '../../models/snackbar/snackbar.js';

export interface ISnackBarElementsState {
  errors: IError[];
  info: IInfo[];
  warnings: IWarning[];
}

const initialState: ISnackBarElementsState = {
  errors: [],
  info: [],
  warnings: []
};

export const snackBarElementsReducer = handleActions<ISnackBarElementsState, string | any>(
  {
    [SnackbarActions.ADD_ERROR]: (state, action): ISnackBarElementsState => {
      return { ...state, errors: [...state.errors, { id: `${Guid.create()}`, errorText: action.payload.errorText }] };
    },
    [SnackbarActions.DELETE_ERROR]: (state, action): ISnackBarElementsState => {
      return { ...state, errors: state.errors.filter((error) => error.id !== action.payload.id) };
    },
    [SnackbarActions.ADD_INFO]: (state, action): ISnackBarElementsState => {
      return { ...state, info: [...state.info, { id: `${Guid.create()}`, infoText: action.payload.infoText }] };
    },
    [SnackbarActions.DELETE_INFO_BY_TEXT]: (state, action): ISnackBarElementsState => {
      return { ...state, info: state.info.filter((infoObj) => infoObj.infoText !== action.payload.infoText) };
    },
    [SnackbarActions.DELETE_INFO]: (state, action): ISnackBarElementsState => {
      return { ...state, info: state.info.filter((infoObj) => infoObj.id !== action.payload.id) };
    },
    [SnackbarActions.ADD_WARNING]: (state, action): ISnackBarElementsState => {
      return {
        ...state,
        warnings: [
          ...state.warnings,
          {
            id: `${Guid.create()}`,
            warningText: action.payload.warningText,
            timer: action.payload.timer,
            link: action.payload.link,
            linkText: action.payload.linkText
          }
        ]
      };
    },
    [SnackbarActions.DELETE_WARNING]: (state, action): ISnackBarElementsState => {
      return { ...state, warnings: state.warnings.filter((warning) => warning.id !== action.payload.id) };
    },
    [SnackbarActions.DELETE_WARNING_BY_TEXT]: (state, action): ISnackBarElementsState => {
      return {
        ...state,
        warnings: state.warnings.filter((warning) => warning.warningText !== action.payload.warningText)
      };
    }
  },
  initialState
);
