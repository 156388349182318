import React from 'react';
import type { BoxProps, Theme } from '@material-ui/core';
import { Box, makeStyles } from '@material-ui/core';

const DetailsContainer: React.FC<BoxProps> = ({ children, ...rest }) => {
  const classes = useStyles();

  return (
    <Box padding={2} className={classes.root} {...rest}>
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.grey[50],
    border: `1px dashed ${theme.palette.divider}`
  }
}));

export default DetailsContainer;
