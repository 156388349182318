import { useHistory } from 'react-router';
import React, { useCallback } from 'react';
import { DialogContentText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import type { CourseDto } from '@/generated-api/index.js';
import { CourseManagementRoutes } from 'app/utils/routes.js';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type DeleteCourseDialogProps = {
  open: boolean;
  course: CourseDto;
  onClose: () => void;
  onExited: () => void;
};

const DeleteCourseDialog: React.FC<DeleteCourseDialogProps> = ({ open, course, onClose, onExited }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const onCourseDeleted = useCallback(() => {
    onClose();
    history.replace(CourseManagementRoutes.Root);
  }, [onClose, history]);

  const deleteCourse = useCallback(() => {
    dispatch(CourseManagementActions.deleteCourse(course.id, onCourseDeleted));
  }, [course, onCourseDeleted, dispatch]);

  const loadingStatus = useSelector(CourseManagementSelectors.deleteCourseLoadingStatus);

  return (
    <DeleteConfirmationDialog
      title={translate(nameof.full<I18nCourseManagementNs>((n) => n.deleteCourse))}
      confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
      onClose={onClose}
      onConfirm={deleteCourse}
      loadingStatus={loadingStatus}
      DialogProps={{
        open,
        TransitionProps: {
          onExited
        }
      }}
    >
      <DialogContentText>
        {translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarioList.confirmationText))}{' '}
        <b>{`${course?.title}`}</b>?
      </DialogContentText>
    </DeleteConfirmationDialog>
  );
};

export default DeleteCourseDialog;
