import { useBoolState } from 'app/utils/customHooks/index.js';
import React from 'react';
import { Box, Button } from '@material-ui/core';
import type { GlobalAssetFullDto } from '@/generated-api/index.js';
import CreateTemplateDialog from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/TemplateActions/CreateTemplateDialog/CreateTemplateDialog.js';
import { Add } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

export type TemplateActionsProps = {
  category: GlobalAssetFullDto;
};

function TemplateActions({ category }: TemplateActionsProps): JSX.Element {
  const [isDialogOpen, openDialog, closeDialog] = useBoolState();

  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <>
      <Box display={'flex'} mt={1}>
        <Button startIcon={<Add />} onClick={openDialog}>
          {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templateActions.createIntent))}
        </Button>
      </Box>
      <CreateTemplateDialog category={category} isOpen={isDialogOpen} onClose={closeDialog} />
    </>
  );
}

export default TemplateActions;
