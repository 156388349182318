import type { ObjectSchema } from 'yup';
import { mixed, object, string, array, number, boolean } from 'yup';

import type {
  Sex,
  TtsProvider,
  SttProvider,
  MedicalProtocolChapterDto,
  SceneContextDto,
  SceneContextFactDto,
  SceneContextLocationDto
} from '@/generated-api/scenes/api.js';
import type {
  TransitionScoresSettingsFormType,
  ScenarioSettingsType
} from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

export function useValidationSchema(): ObjectSchema<ScenarioSettingsType> {
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return object<ScenarioSettingsType>({
    name: string().required(translate(nameof.full<I18nScenarioEditorNs>((n) => n.validation.thisFieldIsRequired))),
    langCode: string().required(),
    sex: mixed<Sex>().required(),
    ttsProvider: mixed<TtsProvider>().required(),
    medicalProtocolChapters: array<MedicalProtocolChapterDto>(),
    transitionScoresSettings: mixed<TransitionScoresSettingsFormType>().required(),
    scenarioContextIntro: string().nullable().trim(),
    sttProvider: mixed<SttProvider>().required(),
    contexts: array<SceneContextDto>(
      object().shape({
        facts: array<SceneContextFactDto>(
          object().shape({
            text: string().required().max(100000)
          })
        ),
        locations: array<SceneContextLocationDto>(
          object().shape({
            location: string().required().max(100)
          })
        )
      })
    ),
    intentProcessingProvider: number().nullable().notRequired(),
    createSharedScenario: boolean().nullable().notRequired()
    // id: new Ref,
    // description: new Ref,
    // voiceName: new Ref,
    // medicalProtocolChapterIds: new Ref,
    // playScenarioContextOn: new Ref,
    // defaultPersonaId: new Ref
  }).required();
}
