import type { TextFieldProps } from '@material-ui/core';
import { Box, TextField, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import VariableSuggestions from './VariableSuggestions.js';
import type { VariableDto } from '@/generated-api/index.js';

type VariableSuggestionInputProps = {
  inputProps: TextFieldProps;
  value: string;
  onChange: (value: string) => void;
  variables: VariableDto[];
};

const useStyles = makeStyles((theme) => ({
  text: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& .MuiInputBase-input': {
      lineHeight: '1.3em', // add slightly more air between lines, default is 1.2em
    },
  },  
}));

export const VARIABLE_MATCH_REGEX = /@\w+/g;

/**
 * The TextField props "multiline", "fullWidth" and "maxRows" have default values of "true", "true", 3
 */
const VariableSuggestionInput = React.forwardRef<
  HTMLInputElement,
  VariableSuggestionInputProps
>((props, ref) => {
  const { value, onChange, variables, inputProps } = props;
  const classes = useStyles();

  const [hasSuggestions, setHasSuggestions] = React.useState(false);
  const textFieldRef = React.useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const oldValue = value;
      const oldCount = oldValue.match(/@/g)?.length ?? 0;
      const newCount = event.target.value.match(/@/g)?.length ?? 0;

      onChange(event.target.value);

      if (newCount > oldCount) {
        setHasSuggestions(true);
        setAnchorEl(event.target);
      } else if (newCount < oldCount) {
        setHasSuggestions(false);
        setAnchorEl(null);
      }
    },
    [onChange, value]
  );

  const html = React.useMemo(() => {
    let copy = value.replaceAll('<', '&lt;').replaceAll('>', '&gt;');
    const matches = copy.match(VARIABLE_MATCH_REGEX);

    if (Array.isArray(matches) && matches.length > 0) {
      for (const match of matches) {
        const replaceRegex = new RegExp(`(?<!<span.*">)${match}`);
        if (variables.find((v) => v.name.toLowerCase() === match.slice(1).toLowerCase())) {
          copy = copy.replace(replaceRegex, `<span style="color: blue;">${match}</span>`);
        } else {
          copy = copy.replace(replaceRegex, `<span style="color: red;" title="Variable not found">${match}</span>`);
        }
      }
    }
    return copy;
  }, [value, variables]);

  return (
    <>
      <Box display={'block'}>
        <TextField
          {...inputProps}
          value={value}
          inputRef={(input) => {
            if (typeof ref === 'function') {
              ref(input);
            } else {
              if (ref) {
                ref.current = input;
              }
            }
            textFieldRef.current = input;
          }}
          disabled={inputProps.disabled}
          fullWidth={inputProps.fullWidth ?? true}
          multiline={inputProps.multiline ?? true}
          maxRows={inputProps.maxRows ?? 3}
          onChange={handleChange}
          className={classes.text}  // apply the shared class
        />

        <VariableSuggestions
          hasSuggestions={hasSuggestions}
          setHasSuggestions={setHasSuggestions}
          field={textFieldRef}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          value={value}
          setValue={onChange}
          variables={variables}
        />
      </Box>
    </>
  );
});

export default VariableSuggestionInput;
