import type { I18nProtocolReferencesNs } from './interfaces.js';

export const ProtocolReferencesDictionaryEn: I18nProtocolReferencesNs = {
  search: 'Search',
  create: 'Create',
  chapters: 'Chapters',
  createChapter: 'Create Chapter',
  chaptersCount: 'Chapters count',
  usedBy: 'Used by',
  freeTextSearch: 'Free text search',
  noEntriesFound: 'No entries found',
  listIsEmpty: 'Protocol References list is empty',
  notAssigned: 'Not assigned',
  selectaProtocolReference: 'Select a Protocol Reference',
  createReferenceForm: {
    createProtocolReference: 'Create Protocol Reference',
    protocolReferenceTitle: 'Protocol Reference title',
    newProtocolReference: 'New Protocol Reference'
  },
  deleteReferenceForm: {
    deleteProtocolReference: 'Delete Protocol Reference',
    confirmationText: 'Are you sure you want to delete'
  },
  chapterCard: {
    addAction: 'Add Action',
    deleteChapter: 'Delete Chapter',
    noChaptersAdded: 'No Chapters added'
  },
  createChapterForm: {
    createChapter: 'Create Chapter',
    chapterTitle: 'Chapter title',
    newChapter: 'New Chapter'
  },
  deleteChapterForm: {
    deleteChapter: 'Delete Chapter',
    confirmationText: 'Are you sure you want to delete'
  },
  addActionForm: {
    addAction: 'Add Action',
    actionText: 'Action text',
    enterActionText: 'Enter Action text'
  },
  deleteActionForm: {
    deleteAction: 'Delete Action',
    confirmationText: 'Are you sure you want to delete'
  }
};

export const ProtocolReferencesDictionaryDe: I18nProtocolReferencesNs = {
  search: 'Suche',
  create: 'Erstellen',
  chapters: 'Kapitel',
  createChapter: 'Kapitel erstellen',
  chaptersCount: 'Anzahl der Kapitel',
  usedBy: 'Verwendet von',
  freeTextSearch: 'Freitextsuche',
  noEntriesFound: 'Keine Einträge gefunden',
  listIsEmpty: 'Die Liste der Protokollreferenzen ist leer',
  notAssigned: 'Nicht zugewiesen',
  selectaProtocolReference: 'Wählen Sie eine Protokollreferenz',
  createReferenceForm: {
    createProtocolReference: 'Protokollreferenz erstellen',
    protocolReferenceTitle: 'Titel der Protokollreferenz',
    newProtocolReference: 'Neue Protokollreferenz'
  },
  deleteReferenceForm: {
    deleteProtocolReference: 'Protokollreferenz löschen',
    confirmationText: 'Sind Sie sicher, dass Sie Folgendes löschen möchten'
  },
  chapterCard: {
    addAction: 'Aktion hinzufügen',
    deleteChapter: 'Kapitel löschen',
    noChaptersAdded: 'Keine Kapitel hinzugefügt'
  },
  createChapterForm: {
    createChapter: 'Kapitel erstellen',
    chapterTitle: 'Kapiteltitel',
    newChapter: 'Neues Kapitel'
  },
  deleteChapterForm: {
    deleteChapter: 'Kapitel löschen',
    confirmationText: 'Sind Sie sicher, dass Sie Folgendes löschen möchten'
  },
  addActionForm: {
    addAction: 'Aktion hinzufügen',
    actionText: 'Aktionstext',
    enterActionText: 'Aktionstext eingeben'
  },
  deleteActionForm: {
    deleteAction: 'Aktion löschen',
    confirmationText: 'Sind Sie sicher, dass Sie Folgendes löschen möchten'
  }
};

export const ProtocolReferencesDictionarySw: I18nProtocolReferencesNs = {
  search: 'Search',
  create: 'Create',
  chapters: 'Chapters',
  createChapter: 'Create Chapter',
  chaptersCount: 'Chapters count',
  usedBy: 'Used by',
  freeTextSearch: 'Free text search',
  noEntriesFound: 'No entries found',
  listIsEmpty: 'Protocol References list is empty',
  notAssigned: 'Not assigned',
  selectaProtocolReference: 'Select a Protocol Reference',
  createReferenceForm: {
    createProtocolReference: 'Create Protocol Reference',
    protocolReferenceTitle: 'Protocol Reference title',
    newProtocolReference: 'New Protocol Reference'
  },
  deleteReferenceForm: {
    deleteProtocolReference: 'Delete Protocol Reference',
    confirmationText: 'Are you sure you want to delete'
  },
  chapterCard: {
    addAction: 'Add Action',
    deleteChapter: 'Delete Chapter',
    noChaptersAdded: 'No Chapters added'
  },
  createChapterForm: {
    createChapter: 'Create Chapter',
    chapterTitle: 'Chapter title',
    newChapter: 'New Chapter'
  },
  deleteChapterForm: {
    deleteChapter: 'Delete Chapter',
    confirmationText: 'Are you sure you want to delete'
  },
  addActionForm: {
    addAction: 'Add Action',
    actionText: 'Action text',
    enterActionText: 'Enter Action text'
  },
  deleteActionForm: {
    deleteAction: 'Delete Action',
    confirmationText: 'Are you sure you want to delete'
  }
};

export const ProtocolReferencesDictionaryNo: I18nProtocolReferencesNs = {
  search: 'Søk',
  create: 'Opprett',
  chapters: 'Kapitler',
  createChapter: 'Opprett Kapittel',
  chaptersCount: 'Antall Kapitler',
  usedBy: 'Brukt av',
  freeTextSearch: 'Fritekstsøk',
  noEntriesFound: 'Ingen funnet',
  listIsEmpty: 'Listen er tom',
  notAssigned: 'Ikke tildelt',
  selectaProtocolReference: 'Velg en Indeksreferanse',
  createReferenceForm: {
    createProtocolReference: 'Opprett Indeksreferanse',
    protocolReferenceTitle: 'Indeksreferanse-tittel',
    newProtocolReference: 'Ny Indeksreferanse'
  },
  deleteReferenceForm: {
    deleteProtocolReference: 'Slett Indeksreferanse',
    confirmationText: 'Er du sikker på du vil slette'
  },
  chapterCard: {
    addAction: 'Legg til Aksjon',
    deleteChapter: 'Slett Kapittel',
    noChaptersAdded: 'Ingen Kapitler lagt til'
  },
  createChapterForm: {
    createChapter: 'Opprett Kapittel',
    chapterTitle: 'Kapittel-tittel',
    newChapter: 'Nytt Kapittel'
  },
  deleteChapterForm: {
    deleteChapter: 'Slett Kapittel',
    confirmationText: 'Er du sikker på du vil slette'
  },
  addActionForm: {
    addAction: 'Legg til Aksjon',
    actionText: 'Aksjonstekst',
    enterActionText: 'Skriv inn Aksjonstekst'
  },
  deleteActionForm: {
    deleteAction: 'Slett Aksjon',
    confirmationText: 'Er du sikker på du vil slette'
  }
};

export const ProtocolReferencesDictionaryNl: I18nProtocolReferencesNs = {
  search: 'Search',
  create: 'Create',
  chapters: 'Chapters',
  createChapter: 'Create Chapter',
  chaptersCount: 'Chapters count',
  usedBy: 'Used by',
  freeTextSearch: 'Free text search',
  noEntriesFound: 'No entries found',
  listIsEmpty: 'Protocol References list is empty',
  notAssigned: 'Not assigned',
  selectaProtocolReference: 'Select a Protocol Reference',
  createReferenceForm: {
    createProtocolReference: 'Create Protocol Reference',
    protocolReferenceTitle: 'Protocol Reference title',
    newProtocolReference: 'New Protocol Reference'
  },
  deleteReferenceForm: {
    deleteProtocolReference: 'Delete Protocol Reference',
    confirmationText: 'Are you sure you want to delete'
  },
  chapterCard: {
    addAction: 'Add Action',
    deleteChapter: 'Delete Chapter',
    noChaptersAdded: 'No Chapters added'
  },
  createChapterForm: {
    createChapter: 'Create Chapter',
    chapterTitle: 'Chapter title',
    newChapter: 'New Chapter'
  },
  deleteChapterForm: {
    deleteChapter: 'Delete Chapter',
    confirmationText: 'Are you sure you want to delete'
  },
  addActionForm: {
    addAction: 'Add Action',
    actionText: 'Action text',
    enterActionText: 'Enter Action text'
  },
  deleteActionForm: {
    deleteAction: 'Delete Action',
    confirmationText: 'Are you sure you want to delete'
  }
};

export const ProtocolReferencesDictionaryIs: I18nProtocolReferencesNs = {
  search: 'Search',
  create: 'Create',
  chapters: 'Chapters',
  createChapter: 'Create Chapter',
  chaptersCount: 'Chapters count',
  usedBy: 'Used by',
  freeTextSearch: 'Free text search',
  noEntriesFound: 'No entries found',
  listIsEmpty: 'Protocol References list is empty',
  notAssigned: 'Not assigned',
  selectaProtocolReference: 'Select a Protocol Reference',
  createReferenceForm: {
    createProtocolReference: 'Create Protocol Reference',
    protocolReferenceTitle: 'Protocol Reference title',
    newProtocolReference: 'New Protocol Reference'
  },
  deleteReferenceForm: {
    deleteProtocolReference: 'Delete Protocol Reference',
    confirmationText: 'Are you sure you want to delete'
  },
  chapterCard: {
    addAction: 'Add Action',
    deleteChapter: 'Delete Chapter',
    noChaptersAdded: 'No Chapters added'
  },
  createChapterForm: {
    createChapter: 'Create Chapter',
    chapterTitle: 'Chapter title',
    newChapter: 'New Chapter'
  },
  deleteChapterForm: {
    deleteChapter: 'Delete Chapter',
    confirmationText: 'Are you sure you want to delete'
  },
  addActionForm: {
    addAction: 'Add Action',
    actionText: 'Action text',
    enterActionText: 'Enter Action text'
  },
  deleteActionForm: {
    deleteAction: 'Delete Action',
    confirmationText: 'Are you sure you want to delete'
  }
};

export const ProtocolReferencesDictionaryUk: I18nProtocolReferencesNs = {
  search: 'Search',
  create: 'Create',
  chapters: 'Chapters',
  createChapter: 'Create Chapter',
  chaptersCount: 'Chapters count',
  usedBy: 'Used by',
  freeTextSearch: 'Free text search',
  noEntriesFound: 'No entries found',
  listIsEmpty: 'Protocol References list is empty',
  notAssigned: 'Not assigned',
  selectaProtocolReference: 'Select a Protocol Reference',
  createReferenceForm: {
    createProtocolReference: 'Create Protocol Reference',
    protocolReferenceTitle: 'Protocol Reference title',
    newProtocolReference: 'New Protocol Reference'
  },
  deleteReferenceForm: {
    deleteProtocolReference: 'Delete Protocol Reference',
    confirmationText: 'Are you sure you want to delete'
  },
  chapterCard: {
    addAction: 'Add Action',
    deleteChapter: 'Delete Chapter',
    noChaptersAdded: 'No Chapters added'
  },
  createChapterForm: {
    createChapter: 'Create Chapter',
    chapterTitle: 'Chapter title',
    newChapter: 'New Chapter'
  },
  deleteChapterForm: {
    deleteChapter: 'Delete Chapter',
    confirmationText: 'Are you sure you want to delete'
  },
  addActionForm: {
    addAction: 'Add Action',
    actionText: 'Action text',
    enterActionText: 'Enter Action text'
  },
  deleteActionForm: {
    deleteAction: 'Delete Action',
    confirmationText: 'Are you sure you want to delete'
  }
};
