import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';

import useStyles from './styles.js';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline.js';
import { ScenarioProgress } from './ScenarioProgress/index.js';
import { Emotion } from 'app/models/common/Emotion.js';
import Playback from 'app/components/DashboardPage/IntentDescription/Playback/Playback.js';
import useScenarioPlayback from 'app/components/DashboardPage/IntentDescription/hooks/useScenarioPlayback.js';
import IntentActionsAndInfo from 'app/components/DashboardPage/IntentDescription/IntentActionsAndInfo.js';
import type { DialogStatisticIntentDto } from '@/generated-api/index.js';
import { Emotion as EmotionDto } from '@/generated-api/index.js';
import { getDialogStatisticsFullQuery } from 'app/queries/dialogStatisticsQueries.js';
import { useRouteMatch } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { getDialogStatisticScenarioVersionQuery } from 'app/queries/scenarioQueries.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

export const IntentDescription = (): JSX.Element => {
  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const statisticsRouteMatch = useRouteMatch<{ id: string | undefined }>({
    path: '/dashboard/:id',
    strict: false,
    exact: true
  });

  const statisticId = !!statisticsRouteMatch?.params?.id ? Number(statisticsRouteMatch.params.id) : undefined;

  const query = useMemo(() => getDialogStatisticsFullQuery(statisticId), [statisticId]);
  const { data: dialogStats } = useQuery(query);

  const sceneDialogStatisticQuery = useMemo(
    () => getDialogStatisticScenarioVersionQuery(dialogStats?.sceneId, dialogStats?.sceneVersionId),
    [dialogStats?.sceneId, dialogStats?.sceneVersionId]
  );
  const { data: sceneDialogStatistic } = useQuery(sceneDialogStatisticQuery);

  const [currentIntentIndex, setCurrentIntentIndex] = useState(0);

  const intent = useMemo(() => {
    if (dialogStats?.intents) {
      return dialogStats?.intents[currentIntentIndex] ?? null;
    }

    return null;
  }, [currentIntentIndex, dialogStats]);

  const { playState, onStop, togglePause, onUserSpeechFinished, onAiSpeechFinished } = useScenarioPlayback(
    setCurrentIntentIndex,
    dialogStats
  );

  useEffect(() => {
    if (dialogStats) {
      setCurrentIntentIndex(0);
    }

    onStop();
  }, [dialogStats, onStop]);

  const handleIntentChangeOuter = (intent: DialogStatisticIntentDto) => {
    onStop();
    const index = dialogStats?.intents.indexOf(intent);
    setCurrentIntentIndex(index);
  };

  useEffect(() => {
    if (!intent) {
      onStop();
    }
  }, [intent, onStop]);

  const emotion = useMemo(() => {
    // if (!intent?.emotion) {
    //   // if (!intent?.detectedMoods) {
    //   return Emotion.Neutral;
    // }

    switch (intent?.emotion) {
      case EmotionDto.NUMBER_0:
        return Emotion.Neutral;
      case EmotionDto.NUMBER_1:
        return Emotion.Happy;
      case 2:
        return Emotion.Sad;
      case 3:
        return Emotion.Anger;
      default:
        return Emotion.Neutral;
    }

    //return (intent.emotion as unknown) as Emotion; // emotion contians the emotion enum value

    // const moodsSTR = JSON.stringify(intent.detectedMoods);
    // if (moodsSTR) {
    //   const values: number[] = Object.values(JSON.parse(moodsSTR));
    //   const mainEmotion = Math.max(...values);
    //   const index = values.indexOf(mainEmotion);

    // }

    // return Emotion.Neutral;
  }, [intent]);

  return (
    <Card className={classes.root} data-cy="scenarioDetails">
      <CardHeader
        title={translate(nameof.full<I18nDashboardNs>((n) => n.intentDescription.conversationPlayback))}
        titleTypographyProps={{ variant: 'h6' }}
        subheader=""
        classes={{ root: classes.header, title: classes.headerTitle }}
      />

      <CardContent>
        {intent && sceneDialogStatistic && dialogStats?.intents && dialogStats?.intents?.length > 0 ? (
          <>
            <div className={classes.conversationContainer}>
              <div className={clsx(classes.message, classes.received)}>
                <Typography component="p">{intent.userSays}</Typography>
              </div>
              <div className={clsx(classes.message, classes.sent)}>
                <Typography component="p">{intent.responseText}</Typography>
              </div>
            </div>

            <IntentActionsAndInfo
              emotion={emotion}
              intent={intent}
              stats={dialogStats}
              sceneDialogStatistic={sceneDialogStatistic}
            />

            <ScenarioProgress
              intents={dialogStats.intents}
              scoresChanges={{ scores: intent.scores, color: intent.color, previousColor: intent.previousColor }}
              selectedIntentId={intent.id}
              handleSelectIntent={handleIntentChangeOuter}
            />

            {!!dialogStats?.callRecordingUrl ?
              <audio controls preload="metadata" src={dialogStats.callRecordingUrl}/> :
              <Playback
                intent={intent}
                sceneDialogStatistic={sceneDialogStatistic}
                onUserSpeechFinished={onUserSpeechFinished}
                onAiSpeechFinished={onAiSpeechFinished}
                playState={playState}
                togglePause={togglePause}
                onStop={onStop}
              />}
          </>
        ) : (
          <Typography align="center" variant="h5" className={classes.noData}>
            {translate(nameof.full<I18nDashboardNs>((n) => n.intentDescription.selectaConversationToPlayBack))}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
