import React from 'react';

import { Dialog, DialogTitle } from '@material-ui/core';

import type { ScenarioCopyDialogProps } from './types.js';
import { useScenarioIdFromParams } from './utils.js';
import ScenarioCopyDialogForm from './ScenarioCopyDialogForm/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const ScenarioCopyDialog: React.FC<ScenarioCopyDialogProps> = ({ scenarioId, open, onClose }) => {
  const idFromParams = useScenarioIdFromParams();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="copy-dialog-title">
      <DialogTitle id="copy-dialog-title">
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialog.copyScenario))}
      </DialogTitle>
      {/* TODO parseint might throw error*/}
      <ScenarioCopyDialogForm onCancel={onClose} scenarioId={scenarioId ?? Number.parseInt(idFromParams, 10)} />
    </Dialog>
  );
};

export default ScenarioCopyDialog;
