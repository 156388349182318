import { handleActions } from 'redux-actions';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
import type { LinkedScenario, ICurrentScenario } from '../../models/intents/intentNodeProperties.js';
import type { UserSaysPhrases } from 'app/models/scenarios/scenario.js';
import type { SceneDto } from '@/generated-api/index.js';

export interface IScenarioState {
  isStreamingEnabledForScenario: boolean;
  scenarios: Array<SceneDto>;
  scenariosLoading: boolean;
  isDetailedScenarioUpdate: boolean;
  detailedScenarioLoading: boolean;
  detailedScenarioIsOpening: boolean;
  scenarioHasNotBeenModified: boolean;
  isDetailedScenarioUpdating: boolean;
  parentScenario?: Array<LinkedScenario>;
  rootScenario?: SceneDto;
  currentScenario?: ICurrentScenario;
  linkedScenarios: Array<LinkedScenario>;
  currentTabSelected: number;
  toNextlinkedScenario: boolean;
  userSaysPhrasesByScenarioId?: UserSaysPhrases;
}

const initialState: IScenarioState = {
  scenarios: new Array<SceneDto>(),
  scenariosLoading: false,
  detailedScenarioLoading: false,
  detailedScenarioIsOpening: false,
  scenarioHasNotBeenModified: true,
  isDetailedScenarioUpdate: true,
  isDetailedScenarioUpdating: false,
  parentScenario: undefined,
  rootScenario: undefined,
  currentScenario: undefined,
  linkedScenarios: new Array<LinkedScenario>(),
  currentTabSelected: 0,
  toNextlinkedScenario: false,
  userSaysPhrasesByScenarioId: undefined,
  isStreamingEnabledForScenario: false
};

export const scenarioReducer = handleActions<
  IScenarioState,
  Array<SceneDto> | boolean | LinkedScenario | Array<LinkedScenario> | number | UserSaysPhrases
>(
  {
    [ScenarioActions.Type.SET_IS_STREAMING_ENABLED_FOR_SCENARIO]: (state, action): IScenarioState => {
      return { ...state, isStreamingEnabledForScenario: action.payload as boolean };
    },
    [ScenarioActions.Type.SET_SCENARIOS]: (state, action): IScenarioState => {
      return { ...state, scenarios: action.payload as Array<SceneDto> };
    },
    [ScenarioActions.Type.SET_IS_DETAILED_SCENARIO_UPDATE]: (state, action): IScenarioState => {
      return { ...state, isDetailedScenarioUpdate: action.payload as boolean };
    },
    [ScenarioActions.Type.SET_DETAILED_SCENARIO_DATA_BY_SCENARIO_ID]: (state, action): IScenarioState => {
      return { ...state, userSaysPhrasesByScenarioId: action.payload as UserSaysPhrases };
    },
    [ScenarioActions.Type.SET_IS_CURRENT_SCENARIO_UPDATING]: (state, action): IScenarioState => {
      return { ...state, isDetailedScenarioUpdating: action.payload as boolean };
    },
    [ScenarioActions.Type.SET_SCENARIO_HAS_NOT_BEEN_MODIFIED]: (state, action): IScenarioState => {
      return { ...state, scenarioHasNotBeenModified: action.payload as boolean };
    },
    [ScenarioActions.Type.SET_SCENARIOS_LOADING]: (state, action): IScenarioState => {
      return { ...state, scenariosLoading: action.payload as boolean };
    },
    [ScenarioActions.Type.SET_DETAILED_SCENARIO_LOADING]: (state, action): IScenarioState => {
      return { ...state, detailedScenarioLoading: action.payload as boolean };
    },
    [ScenarioActions.Type.SET_DETAILED_SCENARIO_IS_OPENING]: (state, action): IScenarioState => {
      return { ...state, detailedScenarioIsOpening: action.payload as boolean };
    },
    [ScenarioActions.Type.SET_ROOT_SCENARIO]: (state, action): IScenarioState => {
      return { ...state, rootScenario: action.payload as SceneDto };
    },
    [ScenarioActions.Type.SET_PARENT_SCENARIO]: (state, action): IScenarioState => {
      return { ...state, parentScenario: action.payload as Array<LinkedScenario> };
    },
    [ScenarioActions.Type.SET_CURRENT_SCENARIO]: (state, action): IScenarioState => {
      return { ...state, currentScenario: action.payload as ICurrentScenario };
    },
    [ScenarioActions.Type.SET_CURRENT_SCENARIO_SAVE_STATUS]: (state, action): IScenarioState => {
      return {
        ...state,
        currentScenario: state.currentScenario && {
          ...state.currentScenario,
          isScenarioSaved: action.payload as boolean
        }
      };
    },
    [ScenarioActions.Type.SET_LINKED_SCENARIOS]: (state, action): IScenarioState => {
      return { ...state, linkedScenarios: action.payload as Array<LinkedScenario> };
    },
    [ScenarioActions.Type.SET_CURRENT_TAB_SELECTED]: (state, action): IScenarioState => {
      return { ...state, currentTabSelected: action.payload as number };
    },
    [ScenarioActions.Type.TO_NEXT_LINKED_SCENARIO]: (state, action): IScenarioState => {
      return { ...state, toNextlinkedScenario: action.payload as boolean };
    }
  },
  initialState
);
