import React from 'react';
import { Box, Grid, CircularProgress } from '@material-ui/core';

const DefaultLoadingPage: React.FC = () => (
  <Box
    top={0}
    left={0}
    right={0}
    bottom={0}
    height="100%"
    display="flex"
    position="fixed"
    component={Grid}
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress />
  </Box>
);

export default DefaultLoadingPage;
