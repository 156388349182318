import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { DialogStatisticDto, DialogStatisticKpiDetailsDto } from '@/generated-api/index.js';
import {
  Box,
  Tab,
  Grid,
  Tabs,
  Button,
  Dialog,
  useTheme,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';

import { DateFormat } from 'app/app.constants.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import { DialogStatisticsClient } from 'app/apis/api.js';
import Detail from 'app/components/CourseManagement/Details/Detail.js';
import ReachTimeScores from 'app/components/DashboardPage/KPIDetails/ReachTimeScores.js';
import DetailsContainer from 'app/components/CourseManagement/Details/DetailsContainer.js';
import TransitionScores from 'app/components/DashboardPage/KPIDetails/TransitionScores.js';
import TabPanel from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TabPanel.js';
import ProcedureScoresTab from 'app/components/DashboardPage/KPIDetails/ProcedureScores.js';
import KIPDetailsTabHeader from 'app/components/DashboardPage/KPIDetails/KIPDetailsTabHeader.js';
import ConversationAnalysis from './ConversationAnalysis.js';
import { useSelector } from 'react-redux';
import { isUserSuperAdminSelector, isUserAnyAdminSelector } from 'app/selectors/userSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

const defaultKpiDetails: DialogStatisticKpiDetailsDto = {
  procedureScores: [],
  reachTimeScores: [],
  transitionScores: []
};

type KPIDetailsDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  dialogStatistic: DialogStatisticDto | undefined;
};

const fetchKpiDetails: (dialogStatisticId: number) => Promise<DialogStatisticKpiDetailsDto> = (dialogStatisticId) =>
  DialogStatisticsClient.dialogStatisticsGetKpiDetails(dialogStatisticId).then((response) => response.data);

const KPIDetailsDialog = ({ isOpen, onClose, dialogStatistic }: KPIDetailsDialogProps): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>('');

  const dialogStatisticId = dialogStatistic?.id;

  const { data } = useQuery<DialogStatisticKpiDetailsDto>({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!dialogStatisticId && isOpen,
    queryKey: ['dialogstatistic-kpi-details', dialogStatisticId],
    queryFn: () => fetchKpiDetails(dialogStatisticId),
    placeholderData: defaultKpiDetails
  });

  const formatDate = useFormatDate(DateFormat.Time);

  const theme = useTheme();

  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const isAnyAdmin = useSelector(isUserAnyAdminSelector);

  const { t: translate } = useTranslation([I18nNamespace.Dashboard]);
  const { t: translateCommon } = useTranslation([I18nNamespace.Common]);

  const tabs = [
    translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.procedureScore)),
    translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.reachTimeScore)),
    translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.transitionScore)),
    translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.analysisPreview))
  ] as const;

  const [procedureScoreTab, reachTimeScoreTab, transitionScoreTab, analysisTab] = tabs;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="md"
      onClose={onClose}
      TransitionProps={{
        onExited: () => setActiveTab(procedureScoreTab)
      }}
    >
      <DialogTitle>{translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetails.kpiDetails))}</DialogTitle>
      <DialogContent>
        <Box marginBottom={1}>
          <DetailsContainer>
            <Grid container spacing={3}>
              <Grid item>
                <Detail label={translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.organization))}>
                  {dialogStatistic?.companyName}
                </Detail>
              </Grid>
              <Grid item>
                <Detail label={translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.scenario))}>
                  {dialogStatistic?.sceneName}
                </Detail>
              </Grid>
              <Grid item>
                <Detail label={translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.user))}>
                  {dialogStatistic?.respondent?.userName ??
                    translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.unknown))}
                </Detail>
              </Grid>
              <Grid item>
                <Detail label={translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.start))}>
                  {dialogStatistic && formatDate(dialogStatistic?.start)}
                </Detail>
              </Grid>
              <Grid item>
                <Detail label={translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.end))}>
                  {dialogStatistic && formatDate(dialogStatistic?.end)}
                </Detail>
              </Grid>
            </Grid>
          </DetailsContainer>
        </Box>
        <Box marginBottom={2}>
          <Tabs
            value={activeTab}
            onChange={(_event, value) => setActiveTab(value)}
            style={{
              borderBottom: `1px solid ${theme.palette.divider}`
            }}
            scrollButtons="auto"
            variant="scrollable"
          >
            <Tab label={procedureScoreTab} value={procedureScoreTab} />
            <Tab label={reachTimeScoreTab} value={reachTimeScoreTab} />
            <Tab label={transitionScoreTab} value={transitionScoreTab} />
            {isAnyAdmin && <Tab label={analysisTab} value={analysisTab} />}
          </Tabs>
        </Box>

        <TabPanel index={procedureScoreTab} value={activeTab}>
          <KIPDetailsTabHeader>
            {procedureScoreTab}: {dialogStatistic?.procedureScores} / {dialogStatistic?.procedureScoresMax}
          </KIPDetailsTabHeader>
          <ProcedureScoresTab kpiDetails={data} />
        </TabPanel>

        <TabPanel index={reachTimeScoreTab} value={activeTab}>
          <KIPDetailsTabHeader>
            {reachTimeScoreTab}: {dialogStatistic?.reachTimeScores} / {dialogStatistic?.reachTimeScoresMax}
          </KIPDetailsTabHeader>
          <ReachTimeScores kpiDetails={data} />
        </TabPanel>

        <TabPanel index={transitionScoreTab} value={activeTab}>
          <KIPDetailsTabHeader>
            {transitionScoreTab}: {dialogStatistic?.transitionScores}
          </KIPDetailsTabHeader>
          <TransitionScores kpiDetails={data} />
        </TabPanel>
        {isAnyAdmin && (
          <TabPanel index={analysisTab} value={activeTab}>
            {activeTab === analysisTab ? (
              <ConversationAnalysis fullHeight analyses={data?.conversationAnalyses ?? []} />
            ) : null}
          </TabPanel>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KPIDetailsDialog;
