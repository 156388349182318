import { DialogProcessorStoredBlock } from 'app/models/dialogs/dialogProcess.js';

export const getTextFromSpeak = (str: string): string => {
  return str?.replace(/<\/?[^>]+>/g, '');
};

export const readFileAsync = (file: any): Promise<null | undefined | string | ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};
