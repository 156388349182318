import React from 'react';
import { LoadingStatus } from 'app/store/types.js';
import { Box, Card, CircularProgress, Typography } from '@material-ui/core';
import useStyles from '../useStyles.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

export type NoCourseDataProps = {
  loadingStatus: LoadingStatus;
  isScenariosLoading: boolean;
};

function NoCourseData({ loadingStatus, isScenariosLoading }: NoCourseDataProps): JSX.Element {
  let loadingIcon: React.ReactNode = null;
  let caption: React.ReactNode = null;

  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  if (isScenariosLoading) {
    loadingIcon = <CircularProgress size={80} />;
  } else {
    switch (loadingStatus) {
      case LoadingStatus.Idle:
      case LoadingStatus.Loading:
        loadingIcon = <CircularProgress size={80} />;
        break;

      case LoadingStatus.Succeeded:
        caption = translate(nameof.full<I18nCourseManagementNs>((n) => n.selectaCourse));
        break;

      case LoadingStatus.Failed:
        caption = 'An error occurred :(';
        break;
    }
  }

  return (
    <Card square classes={{ root: classes.cardEmpty }}>
      {loadingIcon ?? (
        <Box color={'action.disabled'}>
          <Typography variant={'h4'}>{caption}</Typography>
        </Box>
      )}
    </Card>
  );
}

export default NoCourseData;
