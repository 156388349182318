import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LoadMode } from 'app/store/types.js';
import { LoadingStatus } from 'app/store/types.js';
import type { MedicalProtocolDto } from '@/generated-api/index.js';

export type MedicalIndexDtoState = {
  status: LoadingStatus;
  error: Error | null;
  indexes: MedicalProtocolDto[];
};

const initialState: MedicalIndexDtoState = {
  status: LoadingStatus.Idle,
  error: null,
  indexes: []
};

const medicalIndexSlice = createSlice({
  initialState,
  name: 'medicalIndex',
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    load(state, action: PayloadAction<LoadMode>) {},
    loadingStarted(state) {
      state.status = LoadingStatus.Loading;
    },
    loadingFinished: {
      prepare(payload: MedicalProtocolDto[], error?: Error) {
        return { payload, error };
      },
      reducer(state, action: PayloadAction<MedicalProtocolDto[], string, never, Error | undefined>) {
        if (action.error) {
          state.error = action.error;
          state.status = LoadingStatus.Failed;
        } else {
          state.indexes = action.payload;
          state.status = LoadingStatus.Succeeded;
        }
      }
    },
    indexCreated(state, { payload }: PayloadAction<MedicalProtocolDto>) {
      state.indexes.push(payload);
    },
    indexUpdated(state, { payload }: PayloadAction<MedicalProtocolDto>) {
      const index = state.indexes.findIndex((c) => c.id === payload.id);
      state.indexes[index] = payload;
    },
    indexRemoved(state, { payload }: PayloadAction<MedicalProtocolDto['id']>) {
      state.indexes = state.indexes.filter((i) => i.id !== payload);
    }
  }
});

export default medicalIndexSlice;
