import moment from 'moment';
import type { Order } from '../models/models.js';

export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: number | string | Date }, b: { [key in Key]: number | string | Date }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export const genericSort = <T>(array: T[], comparator: (a: T, b: T) => number) => {
  const convertedArray = array.map((el, index) => [el, index] as [T, number]);
  convertedArray.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return convertedArray.map((el) => el[0]);
};

export const computeDuration = (startTime: Date, endTime: Date) => {
  const duration = moment.duration(moment(endTime.toString()).diff(moment(startTime.toString())));

  // duration in hours
  const hours = Math.floor(duration.hours());

  // duration in minutes
  const minutes = Math.floor(duration.minutes());
  const seconds = Math.floor(duration.seconds());

  return `${`${hours}`.length === 1 ? `0${hours}` : hours}:${`${minutes}`.length === 1 ? `0${minutes}` : minutes}:${
    `${seconds}`.length === 1 ? `0${seconds}` : seconds
  }`;
};
