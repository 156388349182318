import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { Button, Drawer } from '@material-ui/core';

import DoubleArrowRightIcon from '@material-ui/icons/DoubleArrow.js';

import { ScenarioChartStoreActions } from '../../../../actions/scenarios/scenarioChartStoreAction.js';
import { ScenarioChartSelectors } from '../../../../selectors/index.js';
import IntentEditingForm from './IntentEditingForm/IntentEditingForm.js';

import useStyles from './styles.js';

const IntentEditingComponent: React.FC = () => {
  const dispatch = useDispatch();
  const selectedNodeId = useSelector(ScenarioChartSelectors.selectedNodeId);
  const showIntentEdit = useSelector(ScenarioChartSelectors.showIntentEdit);

  const onOpenEditor = useCallback(() => {
    dispatch(ScenarioChartStoreActions.onShowIntentEdit(true));
  }, [dispatch]);
  const onCloseEditor = useCallback(() => {
    dispatch(ScenarioChartStoreActions.onShowIntentEdit(false));
  }, [dispatch]);

  useEffect(() => {
    if (selectedNodeId === undefined) {
      onCloseEditor();
    }
  }, [selectedNodeId, onCloseEditor]);
  const classes = useStyles();

  const hasSelectedIntent = selectedNodeId !== undefined;

  const buttonClasses = {
    root: classes.button
  };
  return (
    <>
      <div
        className={classNames(
          classes.buttonContainer,
          classes.openButtonContainer,
          classes.offsetPosition,
          hasSelectedIntent ? classes.displayFlex : classes.displayNone
        )}
      >
        <Button variant="contained" disableElevation classes={buttonClasses} onClick={onOpenEditor}>
          <DoubleArrowRightIcon className={classes.doubleArrowLeft} />
        </Button>
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={showIntentEdit}
        classes={{
          paper: classNames(classes.offsetPosition, classes.drawerPaper),
          paperAnchorDockedRight: classes.drawerBorderUnset
        }}
        SlideProps={{
          mountOnEnter: true,
          unmountOnExit: true
        }}
      >
        <div className={classNames(classes.displayFlex, classes.buttonContainer)}>
          <Button size="small" variant="contained" disableElevation classes={buttonClasses} onClick={onCloseEditor}>
            <DoubleArrowRightIcon />
          </Button>
        </div>
        {showIntentEdit && (
          <div className={classes.formContainer} data-cy="openedEdditingPanel">
            <IntentEditingForm />
          </div>
        )}
      </Drawer>
    </>
  );
};
export default IntentEditingComponent;
