import type UserManaged from '../../models/userManagement/userManaged.js';
import type { ActionCreatorGeneric } from 'app/types/ReduxHelperTypes.js';
import type { UserDto } from '@/generated-api/index.js';

export enum Type {
  GET_USERS = 'GET_USERS',
  ADD_USER = 'ADD_USER',
  EDIT_USER = 'EDIT_USER',
  SET_USERS = 'SET_USERS',
  SET_USERS_LOADED = 'SET_USERS_LOADED',
  USER_DELETED = 'USER_DELETED',
  USER_UPDATED = 'USER_UPDATED',
  DELETE_USER = 'DELETE_USER'
}

export type PayloadType = {
  [Type.GET_USERS]: void;
  [Type.DELETE_USER]: { id: number; onSuccess: () => void };
  [Type.ADD_USER]: { user: UserManaged; onSuccess?: () => void };
  [Type.EDIT_USER]: { user: UserManaged; onSuccess?: () => void };
  [Type.SET_USERS]: UserManaged[];
  [Type.USER_UPDATED]: UserDto;
  [Type.USER_DELETED]: number;

  // TODO: get rid of these
  [Type.SET_USERS_LOADED]: boolean;
};

type ActionCreator<EnumValue> = ActionCreatorGeneric<EnumValue, PayloadType>;

export const getUsers: ActionCreator<Type.GET_USERS> = () => ({
  type: Type.GET_USERS
});

export const addUser: ActionCreator<Type.ADD_USER> = (payload) => ({
  type: Type.ADD_USER,
  payload
});

export const deleteUser: ActionCreator<Type.DELETE_USER> = (payload) => ({
  type: Type.DELETE_USER,
  payload
});

export const editUser: ActionCreator<Type.EDIT_USER> = (payload) => ({
  type: Type.EDIT_USER,
  payload
});

export const setUsers: ActionCreator<Type.SET_USERS> = (payload) => ({
  type: Type.SET_USERS,
  payload
});

export const setUsersLoaded: ActionCreator<Type.SET_USERS_LOADED> = (payload) => ({
  type: Type.SET_USERS_LOADED,
  payload
});

export const userUpdated: ActionCreator<Type.USER_UPDATED> = (payload) => ({
  type: Type.USER_UPDATED,
  payload
});

export const userDeleted: ActionCreator<Type.USER_DELETED> = (payload) => ({
  type: Type.USER_DELETED,
  payload
});
