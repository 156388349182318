import type { RootState } from '../reducers/rootReducer.js';
import { CurrentUserSelector } from './userSelectors.js';

export const companiesSelector = (state: RootState) => state.companyReducer.companies;
export const companiesAreLoadingSelector = (state: RootState) => state.companyReducer.companiesAreLoading;

export const currentUserCompany = (state: RootState) =>
  state.companyReducer.companies.find((x) => x.id == CurrentUserSelector(state)?.companyId)?.name;

export const currentUserCompanyMedicalIndexIds = (state: RootState) =>
  state.companyReducer.companies.find((x) => x.id == state.userReducer.currentUser?.companyId)?.medicalProtocols;
