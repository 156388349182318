import type { IPosition } from '@mrblenny/react-flow-chart';

const hardCodedCenter: IPosition = {
  x: 5000,
  y: 5000
};

const newNodeOffset: IPosition = {
  x: 20,
  y: -50
};

export function getNodePosition(coords: IPosition): IPosition {
  return {
    x: hardCodedCenter.x + coords.x,
    y: hardCodedCenter.y + coords.y
  };
}

export function getNodeDecartPosition(coords: IPosition): IPosition {
  return {
    x: coords.x - hardCodedCenter.x,
    y: coords.y - hardCodedCenter.y
  };
}

export function getChartOffset(centerOffset: IPosition, chartSize: IPosition, scale: number): IPosition {
  return {
    x: -(hardCodedCenter.x - centerOffset.x - chartSize.x / 2) * scale,
    y: -(hardCodedCenter.y - centerOffset.y - chartSize.y / 2) * scale
  };
}

export function getCenterCoordinates(chartOffset: IPosition, chartSize: IPosition, scale: number): IPosition {
  return {
    x: -(chartSize.x / 2 - chartOffset.x / scale - hardCodedCenter.x),
    y: -(chartSize.y / 2 - chartOffset.y / scale - hardCodedCenter.y)
  };
}

export function getNewNodePosition(knownPositions: IPosition[]) {
  const minPosition = getMinPosition(knownPositions);
  minPosition.x += newNodeOffset.x;
  minPosition.y += newNodeOffset.y;
  return minPosition;
}

export function getCopyNodePosition(sourcePosition: IPosition) {
  return {
    x: sourcePosition.x + newNodeOffset.x,
    y: sourcePosition.y + newNodeOffset.y
  };
}

/// pure helpers

export function getMinPosition(positions: IPosition[]): IPosition {
  return positions.reduce<IPosition>(
    (minPos, pos) => {
      minPos.x = Math.min(minPos.x, pos.x);
      minPos.y = Math.min(minPos.y, pos.y);
      return minPos;
    },
    { x: Number.MAX_VALUE, y: Number.MAX_VALUE }
  );
}

export function getAvgPosition(positions: IPosition[]) {
  const posCount = positions.length;
  const summedPositions = positions.reduce(
    (sum, pos) => ({
      x: sum.x + pos.x,
      y: sum.y + pos.y
    }),
    { x: 0, y: 0 }
  );

  return {
    x: summedPositions.x / posCount,
    y: summedPositions.y / posCount
  };
}
