import type { MouseEvent } from 'react';
import React, { useState, useRef, useCallback, useMemo, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import type { IPortsDefaultProps } from '@mrblenny/react-flow-chart';
import { PortsGroupDefault } from '@mrblenny/react-flow-chart';
import { ScenarioChartEditingActions } from '../../../../../actions/scenarios/scenarioChartEditingActions.js';
import useStyles from './styles.js';
import ScenarioLinkComponent from './ScenarioLinkComponent/index.js';

const topLinksPosHeight = 30;
const topChipsPosHeight = 38;

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace CustomPortsNs {
  export type Props = IPortsDefaultProps;
}

const CustomPortsComponent: React.FC<CustomPortsNs.Props> = ({ node, children, config }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [hoveredPort, setHoveredPort] = useState<null | { hoveredPortNodeId: string; portId: string }>(null);

  const nOfScenarios = node.properties.linkedScenarios!.length!;

  const topOffsetLinks = useMemo(() => {
    const linkedScenarios = node.properties.linkedScenarios!;
    if (linkedScenarios.length == 1) return topLinksPosHeight / 2;
    if (linkedScenarios.length < 5) return nOfScenarios * topLinksPosHeight;
    else return 4 * topLinksPosHeight;
  }, [node, nOfScenarios]);

  const topOffsetChips = useMemo(() => {
    const linkedScenarios = node.properties.linkedScenarios!;
    if (linkedScenarios.length < 5) {
      return 0;
    } else {
      return (nOfScenarios - 4) * topChipsPosHeight * -1;
    }
  }, [node, nOfScenarios]);

  const containerRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const handleHoveredPort = useCallback(
    (portId = '') => {
      setHoveredPort(!!portId ? { hoveredPortNodeId: node.id, portId } : null);
    },
    [setHoveredPort, node.id]
  );

  useEffect(() => {
    if (!!hoveredPort) {
      dispatch(ScenarioChartEditingActions.onHoveredPort({ hoveredPort }));
    } else if (!isFirstRender.current && !hoveredPort) {
      dispatch(ScenarioChartEditingActions.onHoveredPort({ hoveredPort: null }));
    } else {
      isFirstRender.current = false;
    }
  }, [dispatch, hoveredPort]);
  const handleMouseOnEnterPortIn = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      handleHoveredPort('portIn');
    },
    [handleHoveredPort]
  );
  const handleMouseOnEnterPortOut = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      handleHoveredPort('portOut');
    },
    [handleHoveredPort]
  );
  const handleMouseOnLeavePort = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      handleHoveredPort();
    },
    [handleHoveredPort]
  );

  const inputPorts = useMemo(() => children.filter((child) => ['input', 'left'].includes(child.props.port.type)), [
    children
  ]);
  const outputPorts = useMemo(() => children.filter((child) => ['output', 'right'].includes(child.props.port.type)), [
    children
  ]);
  const portsGroupTop = useMemo(() => children.filter((child) => ['top'].includes(child.props.port.type)), [children]);
  const portsGroupBottom = useMemo(() => children.filter((child) => ['bottom'].includes(child.props.port.type)), [
    children
  ]);

  return (
    <div>
      <PortsGroupDefault config={config} side="top">
        {portsGroupTop}
      </PortsGroupDefault>
      <PortsGroupDefault config={config} side="bottom">
        {portsGroupBottom}
      </PortsGroupDefault>
      <div
        onMouseEnter={handleMouseOnEnterPortIn}
        onMouseLeave={handleMouseOnLeavePort}
        className={classNames(classes.common, classes.customPortGroupLeft)}
      >
        {inputPorts}
      </div>
      <div
        style={{ marginBottom: topOffsetLinks }}
        onMouseEnter={handleMouseOnEnterPortOut}
        onMouseLeave={handleMouseOnLeavePort}
        className={classNames(classes.common, classes.customPortGroupRight)}
      >
        {outputPorts}
      </div>
      <div
        style={{ bottom: topOffsetChips }}
        ref={containerRef}
        className={classNames(classes.common, classes.customPortGroupLinkedScenario)}
      >
        <ScenarioLinkComponent
          linkedScenarios={node.properties.linkedScenarios!}
          containerDOMWidth={containerRef.current?.clientWidth!}
        />
      </div>
    </div>
  );
};

export default memo(CustomPortsComponent);
