import React, { useEffect, useMemo, useState } from 'react';
import type { UserGroupDto } from '@/generated-api/index.js';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, TextField, Box, Button, ListSubheader } from '@material-ui/core';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { UserGroupClient } from 'app/apis/api.js';
import { GroupManagementActions } from 'app/components/GroupManagement/store/groupManagementActions.js';
import { CurrentUserSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import useUsersAndCompanyGroupedByCompanyId from 'app/utils/customHooks/useUsersAndCompanyGroupedByCompanyId.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGroupManagementNs } from '@/i18n/dictionaries/interfaces.js';

export type AddGroupMemberProps = {
  group: UserGroupDto;
};

enum ProcessStatus {
  StartProcess,
  Processing,
  Idle
}

function AddGroupMember({ group }: AddGroupMemberProps): JSX.Element {
  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);
  const currentUserCompanyId = useSelector(CurrentUserSelector).companyId;

  const usersCompanyGroupedByCompany = useUsersAndCompanyGroupedByCompanyId();

  const [translate] = useTranslation([I18nNamespace.GroupManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const options = useMemo(() => {
    if (!group?.users) {
      return [];
    }

    const elements: JSX.Element[] = [];
    for (const companyId in usersCompanyGroupedByCompany) {
      if (!isUserSuperAdmin) {
        if (companyId !== `${currentUserCompanyId}`) {
          continue;
        }
      }

      const grouped = usersCompanyGroupedByCompany[companyId];
      const filteredUsers = _.differenceWith(grouped.users, group?.users, (u, u2) => u.id === u2.id);

      if (filteredUsers.length) {
        const companyTitle = grouped.company?.name ?? companyId;
        elements.push(
          <ListSubheader key={companyId} color={'primary'}>
            {companyTitle}
          </ListSubheader>
        );
        elements.push(
          ...filteredUsers.map((u) => (
            <MenuItem key={u.id} value={u.id}>
              {`${u.name}`}
            </MenuItem>
          ))
        );
      }
    }

    return elements;
  }, [usersCompanyGroupedByCompany, group?.users, isUserSuperAdmin, currentUserCompanyId]);

  const [selected, setSelected] = useState(0);

  const [submitData, setSubmitData] = useState({
    status: ProcessStatus.Idle,
    id: 0
  });

  const dispatch = useDispatch();
  const unmountedRef = useUnmounted();
  useEffect(() => {
    if (submitData.status !== ProcessStatus.StartProcess) {
      return;
    }

    if (!submitData.id) {
      return;
    }

    const request = async () => {
      try {
        setSubmitData((s) => ({
          ...s,
          status: ProcessStatus.Processing
        }));

        const updated = await UserGroupClient.userGroupAddUsers(group.id, {
          users: [submitData.id]
        });

        dispatch(GroupManagementActions.groupUpdated(updated.data));
      } catch (e) {
        logger.error(e);
      } finally {
        if (!unmountedRef.current) {
          setSubmitData({
            id: 0,
            status: ProcessStatus.Idle
          });
        }
      }
    };

    request();
  }, [dispatch, group.id, group.users, submitData, unmountedRef]);
  // }, [dispatch, group.id, group.userIds, submitData, unmountedRef]);

  const onAdd = () => {
    setSubmitData({
      status: ProcessStatus.StartProcess,
      id: selected
    });
  };

  return (
    <Box display={'flex'} alignItems={'center'} pt={2} pb={3}>
      <TextField
        SelectProps={{
          title: 'Select a user to add'
        }}
        variant="outlined"
        size="small"
        fullWidth
        select
        value={selected}
        onChange={(e) => setSelected(Number.parseInt(e.target.value, 10))}
      >
        {options}
      </TextField>

      <Box ml={2}>
        <Button disabled={!selected || submitData.status === ProcessStatus.Processing} onClick={onAdd}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.add))}
        </Button>
      </Box>
    </Box>
  );
}

export default AddGroupMember;
