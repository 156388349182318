import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardHeader, CardContent, Tab, Tabs, Box, useTheme, Typography } from '@material-ui/core';
import type { DialogStatisticDto, DialogStatisticKpiDetailsDto } from '@/generated-api/index.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import { DialogStatisticsClient } from 'app/apis/api.js';
import ReachTimeScores from 'app/components/DashboardPage/KPIDetails/ReachTimeScores.js';
import TransitionScores from 'app/components/DashboardPage/KPIDetails/TransitionScores.js';
import TabPanel from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TabPanel.js';
import ProcedureScoresTab from 'app/components/DashboardPage/KPIDetails/ProcedureScores.js';
import KIPDetailsTabHeader from 'app/components/DashboardPage/KPIDetails/KIPDetailsTabHeader.js';
import useStyles from './styles.js';
import { DateFormat } from 'app/app.constants.js';
import ConversationAnalysis from '../KPIDetails/ConversationAnalysis.js';
import { isUserSuperAdminSelector, isUserAnyAdminSelector } from 'app/selectors/userSelectors.js';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

const defaultKpiDetails: DialogStatisticKpiDetailsDto = {
  procedureScores: [],
  reachTimeScores: [],
  transitionScores: []
};

const fetchKpiDetails: (dialogStatisticId: number) => Promise<DialogStatisticKpiDetailsDto> = (dialogStatisticId) =>
  DialogStatisticsClient.dialogStatisticsGetKpiDetails(dialogStatisticId).then((response) => response.data);

type KpiDetailsCardProps = {
  dialogStatistic: DialogStatisticDto | undefined;
};

export const KpiDetailsCard = ({ dialogStatistic }: KpiDetailsCardProps): JSX.Element => {
  const classes = useStyles();

  const dialogStatisticId = dialogStatistic?.id;
  const { data } = useQuery<DialogStatisticKpiDetailsDto>({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!dialogStatisticId,
    queryKey: ['dialogstatistic-kpi-details', dialogStatisticId],
    queryFn: () => fetchKpiDetails(dialogStatisticId),
    placeholderData: defaultKpiDetails
  });

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const tabs = [
    translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiProcedureScores)),
    translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiReachTime)),
    translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiTransitionScores)),
    translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.analysisPreview))
  ];
  const [procedureScoreTab, reachTimeScoreTab, transitionScoreTab, analysisTab] = tabs;
  const [activeTab, setActiveTab] = useState<string>(procedureScoreTab);
  const theme = useTheme();
  const isAnyAdmin = useSelector(isUserAnyAdminSelector);

  return (
    <Card className={classes.root} data-cy="kpiDetails">
      <CardHeader
        title={translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetails.kpiDetails))}
        titleTypographyProps={{ variant: 'h6' }}
        subheader=""
        classes={{ root: classes.header, title: classes.headerTitle }}
      />
      <CardContent className={classes.content}>
        {dialogStatistic ? (
          <>
            <Box marginBottom={2}>
              <Tabs
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
                style={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab label={procedureScoreTab} value={procedureScoreTab} />
                <Tab label={reachTimeScoreTab} value={reachTimeScoreTab} />
                <Tab label={transitionScoreTab} value={transitionScoreTab} />
                {isAnyAdmin && <Tab label={analysisTab} value={analysisTab} />}
              </Tabs>
            </Box>

            <TabPanel index={procedureScoreTab} value={activeTab}>
              <KIPDetailsTabHeader>
                {dialogStatistic &&
                  `${procedureScoreTab}: ${dialogStatistic?.procedureScores} / ${dialogStatistic?.procedureScoresMax}`}
              </KIPDetailsTabHeader>
              <ProcedureScoresTab kpiDetails={data} />
            </TabPanel>

            <TabPanel index={reachTimeScoreTab} value={activeTab}>
              <KIPDetailsTabHeader>
                {dialogStatistic &&
                  `${reachTimeScoreTab}: ${dialogStatistic?.reachTimeScores} / ${dialogStatistic?.reachTimeScoresMax}`}
              </KIPDetailsTabHeader>
              <ReachTimeScores kpiDetails={data} />
            </TabPanel>

            <TabPanel index={transitionScoreTab} value={activeTab}>
              <KIPDetailsTabHeader>
                {dialogStatistic && `${transitionScoreTab}: ${dialogStatistic?.transitionScores}`}
              </KIPDetailsTabHeader>
              <TransitionScores kpiDetails={data} />
            </TabPanel>
            {isAnyAdmin && (
              <TabPanel index={analysisTab} value={activeTab}>
                {activeTab === analysisTab ? (
                  <ConversationAnalysis analyses={data?.conversationAnalyses ?? []} />
                ) : null}
              </TabPanel>
            )}
          </>
        ) : (
          <Typography align="center" variant="h5" className={classes.noData}>
            {translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetails.selectaConversationToSeeStatistics))}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
