import type { QualityLevel } from '@/generated-api/scenes/api.js';
import type { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

type QualityIconProps = {
  quality: QualityLevel;
};

const useQualityIconStyles = makeStyles<Theme, QualityIconProps, 'qualityIcon'>((theme) => {
  return createStyles({
    qualityIcon: ({ quality }) => ({
      width: 16,
      aspectRatio: '2 / 3',
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.qualityLevel[quality]
    })
  });
});

const QualityIcon = (props: QualityIconProps) => {
  const classes = useQualityIconStyles(props);
  return <span className={classes.qualityIcon} />;
};

export default QualityIcon;
