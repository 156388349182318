import React, { useEffect } from 'react';
import type { UpdateUserGroupCommand, UserGroupDto } from '@/generated-api/index.js';
import EditableCardTitle from 'app/components/CourseManagement/EditableCardTitle/EditableCardTitle.js';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { UserGroupClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import { GroupManagementActions } from 'app/components/GroupManagement/store/groupManagementActions.js';
import logger from 'app/utils/logger.js';

const schema = object<UpdateUserGroupCommand>({
  title: string().trim().required('This field is required')
});

export type EditableGroupTitleProps = {
  group: UserGroupDto;
};

const titleFieldKey = nameof.full<UpdateUserGroupCommand>((m) => m.title);

function EditableGroupTitle({ group }: EditableGroupTitleProps): JSX.Element {
  const { errors, reset, getValues, control, watch, handleSubmit } = useForm<UpdateUserGroupCommand>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      title: group?.title
    }
  });

  const dispatch = useDispatch();
  const onSubmit = handleSubmit(
    async (data) => {
      if (!group) {
        return;
      }

      await UserGroupClient.userGroupUpdate(group.id, data);
      dispatch(
        GroupManagementActions.groupTitleUpdated({
          id: group.id,
          title: data.title
        })
      );
    },
    (data) => {
      logger.error('An error occurred updating title', data);
    }
  );

  useEffect(() => {
    if (group && getValues(titleFieldKey) !== group?.title) {
      reset({
        title: group.title
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group?.title]);

  return (
    <Controller
      control={control}
      name={nameof.full<UpdateUserGroupCommand>((m) => m.title)}
      render={({ value, onChange, onBlur }) => {
        return (
          <EditableCardTitle
            value={value}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              onSubmit();
            }}
            error={errors.title?.message}
          />
        );
      }}
    />
  );
}

export default EditableGroupTitle;
