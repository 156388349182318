import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tooltip, Typography } from '@material-ui/core';
import PauseIcon from '@material-ui/icons/Pause.js';
import PlayArrowIcon from '@material-ui/icons/PlayArrow.js';
import StopIcon from '@material-ui/icons/Stop.js';
import AudioActions from 'app/actions/audio/audioAction.js';
import { DialogProcessorActions } from 'app/actions/dialogprocessor/dialogProcessorActions.js';
import { ScenarioChartStoreActions } from 'app/actions/scenarios/scenarioChartStoreAction.js';
import { addInfo, deleteInfoByText } from 'app/actions/snackbar/snackbar.js';
import { statisticActions } from 'app/actions/statistics/statisticActions.js';
import { VoiceRecognitionActions } from 'app/actions/voiceRecognition/voiceRecognitionActions.js';
import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';
import {
  AudioSelectors,
  DialogProcessorSelector,
  RecognitionSelector,
  ScenarioChartSelectors,
  ScenarioSelectors,
  VoiceRecognitionSelector
} from 'app/selectors/index.js';
import { useBoolState, usePrevious } from 'app/utils/customHooks/index.js';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import SpeechTimer from '../SpeechTimer/index.js';
import { useAudioRecorder } from './hooks/mediaHooks/useAudioRecorder.js';
import useStyles from './style.js';
import useScenarioIntro from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/VoiceRecognition/VoiceRecognition/hooks/useScenarioIntro.js';
import { isLastBlockSelector } from 'app/selectors/dialogProcessorSelector.js';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';
import { EndReason, SttProvider } from '@/generated-api/index.js';
import LoadingButton from 'app/components/LoadingButton.js';
import { useDialogProcessorMutation } from 'app/middleware/sagas/dialogProcessor/dialogProcessorSaga.js';
import useAudioStore from 'app/zustand/audioStore.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';
import { useAudioStreamingHub } from './hooks/audioStreamingHub.js';

import AudioPlayer, { AudioPlayerRef } from 'app/components/Audio/AudioPlayer.js';
import { isStreamingEnabledForScenario } from 'app/selectors/scenarioSelectors.js';
/**
 * TODO LIST:
 * 1. Migrate most items only used here and in the related hooks away from Redux to native react state or react-query
 * 2. Migrate the POST calls to mutations and/or queries with react-query
 * 3. Use additional steps to avoid redundant calls/rerenders/execution of useEffects
 *   - A new step between UserStoppedSpeaking and AudioIsPlaying ? Something like ProcessingUserAudio where it fetches the audio to be played next?
 *
 */

const ChromeBrowserVoiceRecognition: React.FC<Record<string, unknown>> = () => {
  // Nothing to touch here:
  const classes = useStyles();
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  // Normal react state, probably keeps last block id processed
  const [lastProcessedBlockId, setLastProcessedBlockId] = useState<number>();

  /**
   * Gets language of current scenario
   * Used for browser native STT (?) via the SpeechRecognition API (?)
   */
  const currentScenarioLang: string | undefined = useSelector(ScenarioSelectors.currentChartScenarioLang);
  /**
   * True if redux is fetching the scenario, false else
   * This is probably never needed as this component is probably not rendered when the scenario is being fetched anyway...
   * Only used here?
   * This is probably used when using linked scenarios!!!
   * Used in combination with toNextLinkedScenario
   */
  const detailedScenarioIsOpening = useSelector(ScenarioSelectors.detailedScenarioIsOpening);
  // These are just nodes and links and start node id, probably fine
  const nodes = useSelector(ScenarioChartSelectors.chartNodes);
  const links = useSelector(ScenarioChartSelectors.chartLinks);
  const startedNodeId = useSelector(ScenarioChartSelectors.startNodeId);

  /**
   * If this is FALSE the scenario has unsaved changes (?)
   * The selector is ONLY USED HERE
   * setIsCurrentScenarioUpdate is the setter/action in redux
   * it is set to TRUE in saveDetailedScenario (scenarioSaga)
   * also to TRUE in restoreScenario
   * and to FALSE in updateScenarioChart
   * could probably be replaced with something that gets the save status of the current scenario but ok this can be here
   * TODO Should probably only be used to disable/enable the Start buttons and NOTHING else (?)
   * Remove from useEffect to avoid dep on that..
   * (scenarioHasNotBeenModifiedSelector is probably something duplicate of this... or use both...)
   */
  const isDetailedScenarioUpdate = useSelector(ScenarioSelectors.isDetailedScenarioUpdate);
  /**
   * Set to false in ScenarioSection's handleSelectScenario (selecting a new scenario..)
   * Set to true in dialogProcessorSaga's getDPResponse if (last?)block's scene id is not the current scenario's id?
   * Dont touch?
   * only used here?
   */
  const toNextLinkedScenario = useSelector(ScenarioSelectors.toNextLinkedScenario);
  /**
   * Current scenario
   */
  const currentChartScenario = useSelector(ScenarioSelectors.currentChartScenario);
  /**
   * Only used here
   * Is set in this component x2 - resets in 2 different useEffects and used in the same ones
   * And in speechToTextSaga x3 - in getAudioOfLastIntent - which again is used in useAudioRecorder's handleStop
   * TODO If getAudioOfLast intent is moved to a mutation(?) this can probably be a react state instead?
   * TODO Is the value of this used at all? I only see it being used as a truthy/falsy value here...?
   */
  const latestInhouseText = useSelector(VoiceRecognitionSelector.latestInhouseText);
  /**
   * Only set in getDPResponse but used in multiple sagas, but only in this react component
   * Keeps history of blocks and ai / text / voice?
   * In audioSaga's getAudio -> only "obj.blocks.currentBlock" is used (text/emotion/id)
   * In dashboardSaga's postDialogStatistics (should be moved to another saga?) -> only "obj.sessionId" is used
   * In dialogProcessorSaga's getDPResponse -> only "obj.blocks.currentBlock" and "obj.sessionId" is used..
   * Here:
   * handleDialogEnd -> obj.blocks.currentBlock.id
   * "first" useEffect -> obj.blocks.currentBlock.id and obj.isFallback
   * "second" useEffect -> obj.blocks.currentBlock.id and obj.blocks.lastBlock.id
   * TODO Remove allBlocks / isLoaded from the state? (and maybe only store the properties used for the blocks?)
   * DONE -> Removed allBlocks / isLoaded and updated useEffect deps to only include the objects used
   */
  const dialogProcessor = useSelector(DialogProcessorSelector.dialogProcessorSelector);
  /**
   * Only used here
   * Is set to empty string / null here, and in audioSaga's getAudio -> first empty then the response
   * Contains the audio in base64 format
   */
  const audio = useSelector(AudioSelectors.audioSelector);
  /**
   * Probably the thing that drives the logic the most
   * Used here, in speechTimer, in useAudioRecorder
   * Is set here, in useAudioRecorder, useScenarioIntro and in audioSaga
   * Also set when setting response data in getDPResponse
   */
  const recognitionStatus = useSelector(RecognitionSelector.recognitionStatusSelector);
  /**
   * Used here and in useAudioRecorder
   * Is set here (handlePause and when UserStoppedSpeaking)
   */
  const paused = useSelector(RecognitionSelector.isPausedSelector);
  const [manualDialog, setManualDialog] = useState(false);

  /**
   * Used here, ScenarioActionsSection, IntentEditingForm, ScenarioEditorPage, ScenarioGeneralSettings
   * Disables editing etc when dialog is active
   * A short way to use recognitionStatus !== UserStoppedSpeaking
   */
  const isDialogActive = useSelector(RecognitionSelector.isDialogActive);
  /**
   * Used here and in ScenarioDisplay
   * Is only changed when user edits the scenario -> false / when saving is completed -> true
   */

  // useEffect(() => {
  //   console.log('[VOICERECOGNITION.STATE] ', recognitionStatus);
  // }, [recognitionStatus]);
  

  const scenarioHasNotBeenModified = useSelector(ScenarioSelectors.scenarioHasNotBeenModifiedSelector);
  const lastResponse = useSelector(DialogProcessorSelector.dialogProcessorSelector);
  const sessionId = lastResponse?.sessionId;
  const useStreaming = useSelector(ScenarioSelectors.isStreamingEnabledForScenario);

  const audioElement = useRef<HTMLAudioElement>(null);
  const audioPlayerRef = useRef<AudioPlayerRef>(null);
  const { stopStream, startStream, encodedVoice, setEncodedVoice, stopRecorder } = useAudioRecorder(manualDialog);
  let { finalTranscript, resetTranscript, listening } = useSpeechRecognition();
  const audioVoiceRoude = useMemo(() => (!!audio.voice ? `data:audio/wav;base64, ${audio.voice}` : ''), [audio.voice]);
  const isUsingInhouseStt = useMemo(() => currentChartScenario?.sttProvider === SttProvider.NUMBER_1, [
    currentChartScenario
  ]);

  const timeoutRef = useRef<NodeJS.Timeout | number | null>(null);
  const timeoutConfig = useAudioStore((state) => state.timeout);
  const disableTimeoutConfig = useAudioStore((state) => state.disableTimeout);

  const timeoutCallback = useCallback(() => {
    if (recognitionStatus === VoiceRecognitionStatus.UserIsReallySpeaking) {
      logger.warn('Timeout!');
      stopRecorder();
    }
  }, [recognitionStatus, stopRecorder]);

  useEffect(() => {
    if (disableTimeoutConfig) return;
    logger.log(`finalTranscript was updated: ${finalTranscript}`);
    if (recognitionStatus === VoiceRecognitionStatus.UserIsReallySpeaking && finalTranscript) {
      logger.warn('Timeout was set!');
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        timeoutCallback();
      }, timeoutConfig);
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [disableTimeoutConfig, finalTranscript, recognitionStatus, timeoutCallback, timeoutConfig]);

  const previousRecognitionStatus = usePrevious(recognitionStatus);

  const startRecognition = useCallback(async () => {
    const support = SpeechRecognition.browserSupportsSpeechRecognition();
    if (!support) logger.warn('Users browser did not support speech recognition');
    return SpeechRecognition.startListening({
      language: currentScenarioLang,
      continuous: support
    });
  }, [currentScenarioLang]);

  const handleDialogEnd = useCallback(() => {
    if (startedNodeId) {
      dispatch(
        ScenarioChartStoreActions.setNodeProperty({
          id: dialogProcessor.blocks.currentBlock?.id ?? Number.parseInt(startedNodeId, 10),
          propertyName: 'isDialogProcessOnTheNode',
          propertyValue: false
        })
      );
    }
        
    dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.UserStoppedSpeaking));
    stopStream(); // TODO Might not be the right place??
    // logger.log('setting recog status to USS in handleDialogEnd');
  }, [startedNodeId, dispatch, stopStream, dialogProcessor.blocks.currentBlock?.id]);

  /* Statistics */
  const [lastUserText, setLastUserText] = useState('');
  const [startTime, setStartTime] = useState(new Date().toISOString());
  const [trackTime, setTrackTime] = useState<null | string>(null);

  const startListening = useCallback(() => {
    logger.log('[REC.AUDIO] start listening was called');
    startStream();
    if (!listening) startRecognition();
  }, [listening, startRecognition, startStream]);

  const stopListening = useCallback(() => {
    logger.log('[REC.AUDIO] stop listening was called');
    
    // stopStream();
    if (listening) SpeechRecognition.stopListening();
  }, [listening]);

  const getDPResponseDataMutation = useDialogProcessorMutation();

  useEffect(() => {
    // ***
    // *** IT IS SOMETHING WITH THIS OR THE ABOVE^^ (or the deleted stopRecording :thinking:)
    // ***
    if (finalTranscript && recognitionStatus === VoiceRecognitionStatus.BeforeLoadingDPResponse) {
      // logger.log('loadingDPResponse was called');
      // only call this if finalTranscript.length AND recognitionStatus === DPGetResponseData
      // Set state in case of fallback
      dispatch(VoiceRecognitionActions.setFallbackSttText(finalTranscript));

      if (!isUsingInhouseStt) {
        if (!encodedVoice) return;

        setLastUserText(finalTranscript);
        dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.LoadingDPResponse));
        getDPResponseDataMutation.mutate(
          {
            text: finalTranscript,
            speechBase64: encodedVoice,
            end: new Date().toISOString(),
            start: startTime
          },
          {
            onSettled: () => {
              // logger.log('onSettled in mutate');
              setStartTime(new Date().toISOString());
              // reset speech-to-text result
              resetTranscript();
            }
          }
        );
      }
    }

    if (finalTranscript && recognitionStatus == VoiceRecognitionStatus.DPResponseWasLoaded && isUsingInhouseStt) {
      resetTranscript();
    }
  }, [
    dispatch,
    encodedVoice,
    finalTranscript,
    getDPResponseDataMutation,
    isUsingInhouseStt,
    recognitionStatus,
    resetTranscript,
    startTime
  ]);





  const handlePause = () => {
    dispatch(VoiceRecognitionActions.setIsPaused(true));
    stopListening();    
    logger.log('[handlePause] Paused');
    if (!useStreaming) {
      audioElement.current.pause();      
    } else {
      audioPlayerRef.current?.pause();
    }
  };

  const handleUnpause = () => {
    dispatch(VoiceRecognitionActions.setIsPaused(false));
    if (recognitionStatus === VoiceRecognitionStatus.AudioWasEnded) {
      // logger.log('Pausing audio playback?');
      if (!useStreaming) {
        audioElement.current.pause();
      } else {
        audioPlayerRef.current?.pause();
      }
    }
    logger.log('Unpaused');
    dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.BeforeUserIsSpeaking));
  };

  const onEndedPlaying = () => {
    console.log('[STREAMING.AUDIO] onEndedPlaying');
    resetTranscript();
    dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.AudioWasEnded));
  };

  useEffect(() => {
    const audioElementRef = audioElement.current;
    const onPlaying = () => {
      logger.log(`audioElement with title: ${audioElementRef?.title} started playing (play event)`);
    };

    
    const onErrorAudioLog = (err: Event) => {
      logger.log('audioElement error ');
      logger.log(err);
    };

    if (audioElementRef && recognitionStatus === VoiceRecognitionStatus.AudioIsPlaying) {
      audioElementRef.addEventListener('play', onPlaying);
      audioElementRef.addEventListener('ended', onEndedPlaying);
      audioElementRef.addEventListener('error', onErrorAudioLog);
    }
    return () => {
      if (audioElementRef) {
        audioElementRef.removeEventListener('play', onPlaying);
        audioElementRef.removeEventListener('ended', onEndedPlaying);
        audioElementRef.removeEventListener('error', onErrorAudioLog);
      }
    };
  }, [recognitionStatus, dispatch]);

  useEffect(() => {
    if (useStreaming && recognitionStatus === VoiceRecognitionStatus.AudioIsPlaying && !paused) {
      audioPlayerRef.current?.play();
    }
    if (!useStreaming && recognitionStatus === VoiceRecognitionStatus.AudioIsPlaying && !!audioVoiceRoude && !paused) {    
        audioElement.current.play();
    }
  }, [audioVoiceRoude, recognitionStatus, paused]);

  const isLastBlock = useSelector(isLastBlockSelector);

  useEffect(() => {
    if (isLastBlock && recognitionStatus === VoiceRecognitionStatus.AudioWasEnded) {
      handleDialogEnd();
    }
  }, [isLastBlock, handleDialogEnd, recognitionStatus]);

  useEffect(() => {
    if (
      trackTime &&
      encodedVoice &&
      dialogProcessor.blocks.currentBlock &&
      (isUsingInhouseStt ? latestInhouseText : lastUserText) &&
      (lastProcessedBlockId !== dialogProcessor.blocks.currentBlock.id || dialogProcessor.isFallback)
    ) {
      setLastProcessedBlockId(dialogProcessor.blocks.currentBlock?.id);
      if (isUsingInhouseStt) dispatch(VoiceRecognitionActions.setLatestInhouseText(''));
      setLastUserText('');
      setEncodedVoice(null);
    }
  }, [
    dialogProcessor.blocks.currentBlock,
    dialogProcessor.isFallback,
    dispatch,
    encodedVoice,
    isUsingInhouseStt,
    lastProcessedBlockId,
    lastUserText,
    latestInhouseText,
    setEncodedVoice,
    trackTime
  ]);

  useEffect(() => {
    if (detailedScenarioIsOpening && !toNextLinkedScenario) {
      dispatch(AudioActions.setAudioAIResponse(''));
      dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.UserStoppedSpeaking));
      // logger.log('setting recog status to USS in useEffect');
    }
  }, [dispatch, detailedScenarioIsOpening, toNextLinkedScenario]);

  useEffect(() => {
    if (isDialogActive) {
      dispatch(addInfo({ infoText: 'Dialog is active', timer: 99999999 }));
    } else {
      dispatch(deleteInfoByText({ infoText: 'Dialog is active' }));
    }
  }, [dispatch, isDialogActive]);

  /* Statistics END */

  // Audio is playing was called twice!!!

  /* VoiceRecognitionStatus.AudioIsPlaying */
  useEffect(() => {
    // audio playback
    if (recognitionStatus === VoiceRecognitionStatus.AudioIsPlaying) {
      // logger.log('AIP useEffect');
      if (!!dialogProcessor.blocks.currentBlock && !!dialogProcessor.blocks.currentBlock?.id) {
        dispatch(
          ScenarioChartStoreActions.setNodeProperty({
            id: dialogProcessor.blocks.currentBlock?.id,
            propertyName: 'isDialogProcessOnTheNode',
            propertyValue: true,
            isUnique: true
          })
        );
      }
      stopListening(); 
    }
  }, [dialogProcessor.blocks.currentBlock, dispatch, recognitionStatus, stopListening]);

  /* VoiceRecognitionStatus.AudioWasEnded */
  useEffect(() => {
    if (recognitionStatus === VoiceRecognitionStatus.AudioWasEnded) {
      // logger.log('AWE useEffect');
      // playback audio ended

      if (!isLastBlock) {
        // start speech to text recognition
        dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.BeforeUserIsSpeaking));
        // logger.log('setting recog status to UIS in switch');
      }
      const outgoingLinks = Object.values(links).map((l) => l.from.nodeId);
      if (
        dialogProcessor.blocks.currentBlock?.id &&
        nodes[dialogProcessor.blocks.currentBlock?.id].type === CustomNodeTypeEnum.Service &&
        !outgoingLinks.includes(`${dialogProcessor.blocks.currentBlock?.id}`)
      ) {
        dispatch(
          ScenarioChartStoreActions.setNodeProperty({
            id: dialogProcessor.blocks.lastBlock?.id,
            propertyName: 'isDialogProcessOnTheNode',
            propertyValue: true,
            isUnique: true
          })
        );
      }
      // clear audio src buffer
      dispatch(AudioActions.setAudioAIResponse(null));
    }
  }, [
    dialogProcessor.blocks.currentBlock?.id,
    dialogProcessor.blocks.lastBlock?.id,
    dispatch,
    isLastBlock,
    links,
    nodes,
    recognitionStatus
  ]);

  /* VoiceRecognitionStatus.UserIsSpeaking */
  useEffect(() => {
    if (recognitionStatus === VoiceRecognitionStatus.BeforeUserIsSpeaking) {
      // logger.log('case BUIS');
      if (isDetailedScenarioUpdate && !paused) {
        dispatch(DialogProcessorActions.resetBlocks());
        setLastProcessedBlockId(null);
        // logger.log('the second start listening');
        startListening();
        setTrackTime(new Date().toISOString());
        dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.UserIsReallySpeaking));
      }
    }
  }, [dispatch, isDetailedScenarioUpdate, paused, recognitionStatus, startListening]);

  // useEffect(() => {
  //   if (recognitionStatus === VoiceRecognitionStatus.UserIsReallySpeaking) {
  //     logger.log('case UIRS');
  //   }
  // }, [recognitionStatus]);

  /* VoiceRecognitionStatus.UserStoppedSpeaking */
  useEffect(() => {
    if (recognitionStatus === VoiceRecognitionStatus.UserStoppedSpeaking) {
      dispatch(AudioActions.setAudioAIResponse(''));
      dispatch(VoiceRecognitionActions.setIsPaused(false));
      stopListening();

      if (
        previousRecognitionStatus !== undefined &&
        previousRecognitionStatus !== VoiceRecognitionStatus.UserStoppedSpeaking
      ) {
        dispatch(
          statisticActions.stopStatistic({
            endReason: EndReason.NUMBER_1
          })
        );
      }
      if (isUsingInhouseStt) dispatch(VoiceRecognitionActions.setLatestInhouseText(''));
      setLastUserText('');
    }
  }, [dispatch, isUsingInhouseStt, previousRecognitionStatus, recognitionStatus, stopListening]);

  /* VoiceRecognitionStatus.DPResponseWasLoaded */
  useEffect(() => {
    if (recognitionStatus === VoiceRecognitionStatus.DPResponseWasLoaded) {
      if (isUsingInhouseStt && !latestInhouseText) {
        dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.AudioWasEnded));
        // logger.log('setting recog status to AWE in useEffect-switch');
        return;
      }
      if (useStreaming) {
        dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.AudioIsPlaying));
      } else {
        // get audio text-to-speech for AI response
        dispatch(AudioActions.getAudio());
      }
    }
  }, [dispatch, isUsingInhouseStt, latestInhouseText, recognitionStatus]);


  const [_audioConnection, startAudioConnection, _stopAudioConnection] = useAudioStreamingHub();
  
  const { playIntroThenStart, loadingStatus } = useScenarioIntro(startAudioConnection);
  
  const startManualOnClick = useCallback(() => {
    setManualDialog(true);
    playIntroThenStart();
  }, [playIntroThenStart]);

  const stopListeningBtnOnClick = useCallback(() => {
    stopListening();
    stopRecorder();
  }, [stopListening, stopRecorder]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  if (!currentScenarioLang) return null;

  return (
    <>
      { !useStreaming && <audio ref={audioElement} src={audioVoiceRoude} style={{ display: 'none' }} /> }
      { useStreaming && <AudioPlayer ref={audioPlayerRef} onAllAudioPlayed={onEndedPlaying}/> }
      <SpeechTimer paused={paused} />
      <>
        <Button
          data-cy="startScenarioButton"
          startIcon={
            <StopIcon
              data-cy="stopScenarioButton"
              className={clsx(
                classes.action,
                classes.actionStop,
                recognitionStatus === VoiceRecognitionStatus.UserStoppedSpeaking ? classes.disableStopA : ''
              )}
            />
          }
          disabled={!isDialogActive}
          onClick={handleDialogEnd}
        >
          <Typography className={classes.labelMargin}>
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.chromeBrowserVoiceRecognition.stop))}
          </Typography>
        </Button>

        {recognitionStatus !== VoiceRecognitionStatus.UserStoppedSpeaking && (
          <>
            {' '}
            <Button
              data-cy="pausecenarioButton"
              startIcon={
                <PauseIcon data-cy="stopScenarioButton" className={clsx(classes.action, classes.actionStop)} />
              }
              disabled={paused}
              onClick={handlePause}
            >
              <Typography className={classes.labelMargin}>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.chromeBrowserVoiceRecognition.pauseDialog))}
              </Typography>
            </Button>
            <Button
              data-cy="scenarioStartButton"
              startIcon={<PlayArrowIcon className={clsx(classes.action, classes.actionPlay)} />}
              disabled={!paused}
              onClick={handleUnpause}
              size="small"
            >
              <Typography className={classes.labelMargin}>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.chromeBrowserVoiceRecognition.resumeDialog))}
              </Typography>
            </Button>
          </>
        )}
        {recognitionStatus === VoiceRecognitionStatus.UserStoppedSpeaking && (
          <Tooltip
            title={
              !scenarioHasNotBeenModified
                ? translate(nameof.full<I18nScenarioEditorNs>((n) => n.chromeBrowserVoiceRecognition.saveAllChanges))
                : ''
            }
          >
            <div>
              <LoadingButton
                loadingStatus={loadingStatus}
                data-cy="scenarioStartButton"
                startIcon={
                  <PlayArrowIcon
                    className={clsx(
                      classes.action,
                      classes.actionPlay,
                      recognitionStatus !== VoiceRecognitionStatus.UserStoppedSpeaking ? classes.disablePlayA : ''
                    )}
                  />
                }
                disabled={isDialogActive || !scenarioHasNotBeenModified}
                onClick={playIntroThenStart}
                size="small"
              >
                <Typography className={classes.labelMargin}>
                  {translate(nameof.full<I18nScenarioEditorNs>((n) => n.chromeBrowserVoiceRecognition.start))}
                </Typography>
              </LoadingButton>
            </div>
          </Tooltip>
        )}
        {/* {recognitionStatus === VoiceRecognitionStatus.UserStoppedSpeaking ? (
          <Tooltip title={!scenarioHasNotBeenModified ? 'Save all changes to start scenario' : ''}>
            <div>
              <LoadingButton
                loadingStatus={loadingStatus}
                data-cy="scenarioStartButton"
                startIcon={
                  <PlayArrowIcon
                    className={clsx(
                      classes.action,
                      classes.actionPlay,
                      recognitionStatus !== VoiceRecognitionStatus.UserStoppedSpeaking ? classes.disablePlayA : ''
                    )}
                  />
                }
                disabled={isDialogActive || !scenarioHasNotBeenModified}
                onClick={startManualOnClick}
                size="small"
              >
                <Typography className={classes.labelMargin}>Start manual</Typography>
              </LoadingButton>
            </div>
          </Tooltip>
        ) : null} */}
      </>
      <Dialog open={manualDialog} hideBackdrop>
        <DialogTitle>
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.chromeBrowserVoiceRecognition.manualRun))}
        </DialogTitle>
        <DialogContent>
          <Box>
            <Button
              size="large"
              onClick={stopListeningBtnOnClick}
              disabled={recognitionStatus !== VoiceRecognitionStatus.UserIsReallySpeaking}
              variant="outlined"
            >
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.chromeBrowserVoiceRecognition.stopListening))}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setManualDialog(false);
              handleDialogEnd();
            }}
            color="secondary"
            variant="outlined"
          >
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.chromeBrowserVoiceRecognition.stopScenarioRun))}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChromeBrowserVoiceRecognition;
