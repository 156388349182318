import clsx from 'clsx';
import Save from '@material-ui/icons/Save.js';
import Close from '@material-ui/icons/Close.js';
import FolderIcon from '@material-ui/icons/Folder.js';
import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles.js';
import { ListItem, ListItemText, ListItemIcon, IconButton, TextField, Grid } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import type { I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';
import type { I18nScenarioSectionNs } from '@/i18n/dictionaries/interfaces.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';

interface EditableSceneGroupListItemProps {
  onClose: () => void;
  classes: ClassNameMap;
  defaultValue?: string | null;
  handleSave: (sceneGroupName: string) => void;
}

const EditableSceneGroupListItem: FC<EditableSceneGroupListItemProps> = ({
  classes,
  onClose,
  handleSave,
  defaultValue
}): JSX.Element => {
  const [sceneGroupName, setSceneGroupName] = useState<string>(defaultValue ?? '');

  const [translate] = useTranslation(I18nNamespace.ScenarioSection, I18nNamespace.Common);

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>): void => {
      event.preventDefault();
      handleSave(sceneGroupName);
    },
    [handleSave, sceneGroupName]
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => setSceneGroupName(event.target.value),
    []
  );

  const inputRef = useCallback((input: HTMLInputElement): void => {
    if (!input) return;

    setTimeout(() => {
      input.focus();
    }, 100);
  }, []);

  return (
    <ListItem classes={{ selected: classes.selected }} className={clsx(classes.withB, classes.withTBP)}>
      <Grid container onSubmit={submitForm} component="form" alignItems="center" wrap="nowrap">
        <ListItemIcon className={classes.subMenuWidth}>
          <FolderIcon classes={{ root: classes.white }} className={classes.subMenuWidth} />
        </ListItemIcon>
        <ListItemText>
          <TextField
            color="secondary"
            onChange={onChange}
            value={sceneGroupName}
            autoFocus
            size="small"
            type="text"
            placeholder={translate(nameof.full<I18nScenarioSectionNs>((l) => l.folderName))}
            inputRef={inputRef}
            InputProps={{
              className: classes.white
            }}
          />
        </ListItemText>
        <IconButton
          size="small"
          onClick={onClose}
          title={translate(nameof.full<I18nCommonNs>((l) => l.buttonLabels.cancel))}
        >
          <Close className={classes.white} />
        </IconButton>
        <IconButton size="small" type="submit" title={translate(nameof.full<I18nCommonNs>((l) => l.buttonLabels.save))}>
          <Save className={classes.white} />
        </IconButton>
      </Grid>
    </ListItem>
  );
};

export default EditableSceneGroupListItem;
