import type { DialogStatisticDto } from '@/generated-api/index.js';

import { dropRight } from 'lodash';
import type { DialogStatisticFull, DialogStatisticIntentWithDuplicates } from 'app/models/statistic/statistic.js';

const getAmkIdsDuplicate = (amkIdsNotDoubled: number[], currentIntent: DialogStatisticIntentWithDuplicates) => {
  return amkIdsNotDoubled
    .filter((amkNotDuplicatedId) => currentIntent.medicalProtocolActions.some(({ id }) => amkNotDuplicatedId === id))
    .map((amkActionIdDoubled) => ({
      intentInd: `${currentIntent.number}`,
      medicalProtocolActionId: amkActionIdDoubled
    }));
};

const addAmkDublic = (
  intentsWithDubl: DialogStatisticIntentWithDuplicates,
  currentIntent: DialogStatisticIntentWithDuplicates
): DialogStatisticIntentWithDuplicates => {
  const amkWithoutAlreadyDoubled = currentIntent.medicalProtocolActions
    ?.map((a) => a.id)
    .filter((amkResponseActionId) => {
      return !(
        intentsWithDubl.duplicates &&
        intentsWithDubl?.duplicates.some((dublicate) => dublicate.medicalProtocolActionId === amkResponseActionId)
      );
    });
  return {
    ...intentsWithDubl,
    duplicates: intentsWithDubl.duplicates
      ? [...intentsWithDubl.duplicates, ...getAmkIdsDuplicate(amkWithoutAlreadyDoubled, intentsWithDubl)]
      : getAmkIdsDuplicate(amkWithoutAlreadyDoubled, intentsWithDubl)
  };
};

const getIntentWithAmkActionName = (
  intent: DialogStatisticIntentWithDuplicates
): DialogStatisticIntentWithDuplicates => {
  return {
    ...intent,
    duplicates: []
  };
};

const getIntentWithAmkDubl = (
  intent: DialogStatisticIntentWithDuplicates,
  intents: DialogStatisticIntentWithDuplicates[]
): DialogStatisticIntentWithDuplicates => {
  return !!intents.length
    ? intents.reduce((intentsWithDubl, currentIntent) => {
        return addAmkDublic(intentsWithDubl, currentIntent);
      }, intent)
    : intent;
};

export const getIntentsWithduplicatesAmkNames = (fullDialogStatistic: DialogStatisticDto): DialogStatisticFull => {
  const { intents } = fullDialogStatistic;
  const intentsWithduplicates = intents.map((intent, ind) => {
    return getIntentWithAmkDubl(getIntentWithAmkActionName(intent), dropRight(intents, intents.length - ind));
  });
  return { ...fullDialogStatistic, intents: intentsWithduplicates };
};
