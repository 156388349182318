import { Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';

type OverflowTooltipProps = {
  title: string;
  children: JSX.Element | JSX.Element[] | React.ReactNode | null;
};

const OverflowTooltip = (props: OverflowTooltipProps) => {
  const { title, children } = props;
  const [hover, setHover] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  const compareSize = () => {
    const elm = textRef.current;
    const shoudShow = elm
      ? elm.offsetWidth < elm.scrollWidth ||
        elm.offsetHeight < elm.scrollHeight ||
        elm.scrollWidth > elm.clientWidth ||
        elm.scrollHeight > elm.clientHeight
      : false;
    setHover(shoudShow);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);

  return (
    <Tooltip title={title} disableHoverListener={!hover}>
      <Typography
        variant="body2"
        component={'div'}
        ref={textRef}
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTooltip;
