import type { I18nScenarioEditorNs } from './interfaces.js';

export const ScenarioEditorDictionaryEn: I18nScenarioEditorNs = {
  intentActionsMenu: {
    removeNode: 'Remove block',
    removeLink: 'Remove link',
    startIntentCantBeRemoved: 'Start block cannot be removed',
    addIntent: 'Add intent',
    removeAllLinks: 'Remove all links',
    deleteAllLinks: 'Delete all links',
    confirmDeleteAll: 'Are you sure you want to delete all links? (Not saved until clicking save)'
  },
  scenarioActionsSection: {
    save: 'Save',
    copy: 'Copy',
    delete: 'Delete',
    summary: 'Summary',
    settings: 'Settings',
    changelog: 'Changelog',
    variables: 'Variables',
    convertToShared: 'Convert to shared',
    sharedSettings: 'Shared settings',
    confirmConvert: 'Are you sure you want to convert this to a shared scenario?',
    warningConvert: 'NB! You might lose some company specific settings (like company voice) when converting!',
    convert: 'Convert'
  },
  confirmationDialog: {
    confirmRestore: 'Confirm Restore',
    confirmationText: 'Are you sure you want to restore the scenario to version',
    restore: 'Restore'
  },
  scenarioChangelogDialog: {
    restore: 'Restore',
    changeType: 'Change type',
    oldValue: 'Old value',
    newValue: 'New value',
    blockName: 'Block name',
    changelog: 'Changelog',
    version: 'Version',
    updatedBy: 'Updated by',
    updatedAt: 'Updated at',
    close: 'Close'
  },
  scenarioCopyDialogForm: {
    copy: 'Copy',
    nameOfCopiedScenario: 'Name of copied scenario',
    copyToOrganization: 'Copy to organization',
    targetLanguage: 'Target language',
    targetLanguageTranslate: 'Target language (select another language to translate with AI)',
    unsavedChanges: 'If you have unsaved changes, they will not be reflected in the copy.',
    copyingScenario: 'Copying scenario in the background. This may take a while. The copy will load when it is ready.'
  },
  scenarioCopyDialog: {
    copyScenario: 'Copy scenario'
  },
  responseSettings: {
    noResponsesAddedYet: 'No responses added yet'
  },
  defaultPersonaSettings: {
    selectPersonaForThisIntent: 'Select AI Persona for this Intent'
  },
  globalAssets: {
    language: 'Language',
    category: 'Category',
    intent: 'Intent',
    globalAssets: 'Intents',
    noAssetsAddedYet: 'No intents added yet',
    searchIntents: 'Search intents',
    searchResults: 'Search Results',
    matchingSamples: 'Matching intents',
    alreadyAdded: 'Already added',
    noSearchResults: 'No results found',
    addTemplate: 'Add Template',
    searchPlaceholder: 'Enter text to search for intents...',
  },
  intentEditingForm: {
    intentId: 'Intent ID',
    intentName: 'Intent name',
    blockType: 'Block type',
    aiResponse: 'AI response',
    fallbackResponse: 'Fallback response',
    protocolReference: 'Protocol Reference',
    bindTheChapter: 'Bind the chapter to a scenario to choose Protocol Reference',
    persona: 'Persona'
  },
  intentNameSettings: {
    intentName: 'Intent name'
  },
  intentReachTime: {
    intentReachTime: 'Intent reach time',
    fromDialogStart: 'From dialog start (seconds)'
  },
  userSaysSettings: {
    userSays: 'User says'
  },
  nodeActionMenu: {
    copyToCurrentScenario: 'Copy to current scenario',
    copyTo: 'Copy to...',
    blockHasIncomingLinks: 'Block has incoming links',
    blockIsAlreadyStartBlock: 'Block is already start block',
    setAsStartIntent: 'Set as Start Intent'
  },
  useScenarioIntro: {
    microphonePermissionNotGranted:
      'Microphone permission not granted in browser. Check out the tutorial in the User Guides page or ',
    microphoneNotDetected: 'Microphone not detected',
    followThisGuide: 'follow this guide to enable it in your browser settings.'
  },
  chromeBrowserVoiceRecognition: {
    stop: 'Stop',
    pauseDialog: 'Pause dialog',
    resumeDialog: 'Resume dialog',
    saveAllChanges: 'Save all changes to start scenario',
    start: 'Start',
    manualRun: 'Manual run',
    stopListening: 'Stop listening',
    stopScenarioRun: 'Stop scenario run'
  },
  scenarioDisplay: {
    leavePage: 'Leave page?',
    changesYouMadeWillNotBeSaved: 'Changes you made will not be saved.'
  },
  scenarioGeneralSettings: {
    thisVoiceUsesElevenLabs: 'This voice uses Eleven Labs Turbo v2 and is recommended for lower latency.',
    none: 'None',
    scenarioName: 'Scenario name',
    descriptionOptional: 'Description (optional)',
    language: 'Language',
    textToSpeechProvider: 'Text-to-speech provider',
    speechToTextProvider: 'Speech-to-text provider',
    enableLargeLanguageModel: 'Enable Large Language Model (LLM)',
    createSharedScenario: 'Create shared scenario',
    deleted: 'Deleted',
    voice: 'Voice',
    protocolReference: 'Protocol Reference',
    female: 'Female',
    male: 'Male'
  },
  validation: {
    thisFieldIsRequired: 'This field is required'
  },
  scenarioIntroAudioSettingsTab: {
    introAudio: 'Intro audio',
    writeAnIntroText: 'Write an intro text that is read when the scenario starts',
    enable: 'Enable'
  },
  personaDialog: {
    thisFieldIsRequired: 'This field is required',
    personaSettings: 'Persona settings',
    ai: 'AI',
    user: 'User',
    male: 'Male',
    female: 'Female',
    role: 'Role',
    age: 'Age',
    textToSpeechProvider: 'Text-to-speech provider',
    voice: 'Voice',
    useAsDefault: 'Use as default',
    save: 'Save',
    type: 'Type',
    gender: 'Gender'
  },
  sceneContextFactsCard: {
    facts: 'Facts',
    newFact: 'New Fact',
    noFactsAddedYet: 'No Facts added yet'
  },
  sceneContextLocationsCard: {
    newLocation: 'New Location',
    locations: 'Locations',
    noLocationsAddedYet: 'No Locations added yet'
  },
  sceneContextPersonaCard: {
    personas: 'Personas',
    noPersonasAddedYet: 'No Personas added yet'
  },
  blockReachTimeSummaryTab: {
    noSetupReachTimeFound: 'No setup reach time found',
    fromDialogStart: 'From dialog start (seconds)'
  },
  medicalIndexesSummaryTab: {
    noProtocolReferencesFound: 'No Protocol References found',
    section: 'Section'
  },
  scenarioSummaryDialog: {
    protocolReferences: 'Protocol References',
    intentsReachTime: 'Intents reach time',
    summary: 'Summary',
    close: 'Close'
  },
  sharedScenarioSettingsDialog: {
    sharedScenarioSettings: 'Shared scenario settings',
    organizationsSharedWith: 'Organizations shared with',
    selectOrganizations: 'Select organizations',
    selectAnOrganization: 'Select an organization',
    convertToNormalScenario: 'Convert to normal scenario'
  },
  addVariableDialog: {
    addVariable: 'Add variable',
    example: 'Example',
    name: 'Name',
    value: 'Value',
    variableName: 'Variable name',
    variableNameCantContainSpaces: 'Variable name cannot contain spaces'
  },
  scenarioVariablesDialog: {
    scenarioVariables: 'Scenario variables',
    organizations: 'Organizations',
    addVariable: 'Add variable',
    defaultVariables: 'Default Variables'
  },
  variableLine: {
    variable: 'Variable',
    nameCantContainSpace: 'Name cannot contain spaces',
    defaultValue: 'Default value',
    value: 'Value',
    organizationValue: 'Organization value',
    update: 'Update',
    no: 'No',
    yes: 'Yes',
    remove: 'Remove',
    areYouSure: 'Are you sure?'
  },
  variableSuggestions: {
    addNewVariable: 'Add new variable'
  },
  scenarioEditorPage: {
    hasMadeChanges: 'has made changes to the scenario. Reload the page to get the latest version.',
    copy: 'Copy',
    confirmationText: 'Are you sure you want to delete this scenario?'
  },
  scenarioSettingsDialog: {
    general: 'General',
    aiContext: 'AI context',
    transitionWeights: 'Transition weights',
    introAudio: 'Intro audio',
    scenarioSettings: 'Scenario settings',
    save: 'Save'
  }
};

export const ScenarioEditorDictionaryDe: I18nScenarioEditorNs = {
  intentActionsMenu: {
    removeNode: 'Block entfernen',
    removeLink: 'Verbindung entfernen',
    startIntentCantBeRemoved: 'Startblock kann nicht entfernt werden',
    addIntent: 'Intent hinzufügen',
    removeAllLinks: 'Alle Verbindungen entfernen',
    deleteAllLinks: 'Alle Verbindungen löschen',
    confirmDeleteAll: 'Sind Sie sicher, dass Sie alle Verbindungen löschen möchten? (Wird erst beim Klicken auf Speichern gespeichert)'
  },
  scenarioActionsSection: {
    save: 'Speichern',
    copy: 'Kopieren',
    delete: 'Löschen',
    summary: 'Zusammenfassung',
    settings: 'Einstellungen',
    changelog: 'Änderungsprotokoll',
    variables: 'Variablen',
    convertToShared: 'In gemeinsames Szenario umwandeln',
    sharedSettings: 'Gemeinsame Einstellungen',
    confirmConvert: 'Sind Sie sicher, dass Sie dies in ein gemeinsames Szenario umwandeln möchten?',
    warningConvert: 'Achtung! Bei der Umwandlung gehen möglicherweise einige unternehmensspezifische Einstellungen (wie die Unternehmensstimme) verloren!',
    convert: 'Umwandeln'
  },
  confirmationDialog: {
    confirmRestore: 'Wiederherstellen bestätigen',
    confirmationText: 'Sind Sie sicher, dass Sie das Szenario auf Version wiederherstellen möchten',
    restore: 'Wiederherstellen'
  },
  scenarioChangelogDialog: {
    restore: 'Wiederherstellen',
    changeType: 'Änderungstyp',
    oldValue: 'Alter Wert',
    newValue: 'Neuer Wert',
    blockName: 'Blockname',
    changelog: 'Änderungsprotokoll',
    version: 'Version',
    updatedBy: 'Aktualisiert von',
    updatedAt: 'Aktualisiert am',
    close: 'Schließen'
  },
  scenarioCopyDialogForm: {
    copy: 'Kopieren',
    nameOfCopiedScenario: 'Name des kopierten Szenarios',
    copyToOrganization: 'In Organisation kopieren',
    targetLanguage: 'Zielsprache',
    targetLanguageTranslate: 'Zielsprache (wählen Sie eine andere Sprache aus, um mit KI zu übersetzen)',
    unsavedChanges: 'Wenn Sie ungespeicherte Änderungen haben, werden diese nicht in der Kopie übernommen.',
    copyingScenario: 'Szenario wird im Hintergrund kopiert. Dies kann eine Weile dauern. Die Kopie wird geladen, wenn sie fertig ist.'
  },
  scenarioCopyDialog: {
    copyScenario: 'Szenario kopieren'
  },
  responseSettings: {
    noResponsesAddedYet: 'Noch keine Antworten hinzugefügt'
  },
  defaultPersonaSettings: {
    selectPersonaForThisIntent: 'AI-Persona für diesen Intent auswählen'
  },
  globalAssets: {
    language: 'Sprache',
    category: 'Kategorie',
    intent: 'Intent',
    globalAssets: 'Intenten',
    noAssetsAddedYet: 'Noch keine intenten hinzugefügt',
    searchIntents: 'Beispiele suchen',
    searchResults: 'Suchergebnisse',
    matchingSamples: 'Passende Beispiele',
    alreadyAdded: 'Bereits hinzugefügt',
    noSearchResults: 'Keine Suchergebnisse',
    addTemplate: 'Vorlage hinzufügen',
    searchPlaceholder: 'Nach Intenten suchen'
  },
  intentEditingForm: {
    intentId: 'Intent-ID',
    intentName: 'Intent-Name',
    blockType: 'Blocktyp',
    aiResponse: 'KI-Antwort',
    fallbackResponse: 'Fallback-Antwort',
    protocolReference: 'Protokollreferenz',
    bindTheChapter: 'Binden Sie das Kapitel an ein Szenario, um die Protokollreferenz auszuwählen',
    persona: 'Persona'
  },
  intentNameSettings: {
    intentName: 'Intent-Name'
  },
  intentReachTime: {
    intentReachTime: 'Intent-Erreichungszeit',
    fromDialogStart: 'Ab Dialogstart (Sekunden)'
  },
  userSaysSettings: {
    userSays: 'Benutzer sagt'
  },
  nodeActionMenu: {
    copyToCurrentScenario: 'In aktuelles Szenario kopieren',
    copyTo: 'Kopieren nach...',
    blockHasIncomingLinks: 'Block hat eingehende Verbindungen',
    blockIsAlreadyStartBlock: 'Block ist bereits Startblock',
    setAsStartIntent: 'Als Start-Intent festlegen'
  },
  useScenarioIntro: {
    microphonePermissionNotGranted:
      'Mikrofonberechtigung im Browser nicht erteilt. Schauen Sie sich das Tutorial auf der Seite Benutzerhandbücher an oder ',
    microphoneNotDetected: 'Mikrofon nicht erkannt',
    followThisGuide: 'folgen Sie dieser Anleitung, um es in Ihren Browsereinstellungen zu aktivieren.'
  },
  chromeBrowserVoiceRecognition: {
    stop: 'Stopp',
    pauseDialog: 'Dialog pausieren',
    resumeDialog: 'Dialog fortsetzen',
    saveAllChanges: 'Alle Änderungen speichern, um das Szenario zu starten',
    start: 'Start',
    manualRun: 'Manueller Durchlauf',
    stopListening: 'Hören beenden',
    stopScenarioRun: 'Szenario-Durchlauf beenden'
  },
  scenarioDisplay: {
    leavePage: 'Seite verlassen?',
    changesYouMadeWillNotBeSaved: 'Ihre vorgenommenen Änderungen werden nicht gespeichert.'
  },
  scenarioGeneralSettings: {
    thisVoiceUsesElevenLabs: 'Diese Stimme verwendet Eleven Labs Turbo v2 und wird für eine geringere Latenz empfohlen.',
    none: 'Keine',
    scenarioName: 'Szenarioname',
    descriptionOptional: 'Beschreibung (optional)',
    language: 'Sprache',
    textToSpeechProvider: 'Text-to-Speech-Anbieter',
    speechToTextProvider: 'Speech-to-Text-Anbieter',
    enableLargeLanguageModel: 'Großes Sprachmodell (LLM) aktivieren',
    createSharedScenario: 'Gemeinsames Szenario erstellen',
    deleted: 'Gelöscht',
    voice: 'Stimme',
    protocolReference: 'Protokollreferenz',
    female: 'Weiblich',
    male: 'Männlich'
  },
  validation: {
    thisFieldIsRequired: 'Dieses Feld ist erforderlich'
  },
  scenarioIntroAudioSettingsTab: {
    introAudio: 'Intro-Audio',
    writeAnIntroText: 'Schreiben Sie einen Einführungstext, der beim Start des Szenarios vorgelesen wird',
    enable: 'Aktivieren'
  },
  personaDialog: {
    thisFieldIsRequired: 'Dieses Feld ist erforderlich',
    personaSettings: 'Persona-Einstellungen',
    ai: 'KI',
    user: 'Benutzer',
    male: 'Männlich',
    female: 'Weiblich',
    role: 'Rolle',
    age: 'Alter',
    textToSpeechProvider: 'Text-to-Speech-Anbieter',
    voice: 'Stimme',
    useAsDefault: 'Als Standard verwenden',
    save: 'Speichern',
    type: 'Typ',
    gender: 'Geschlecht'
  },
  sceneContextFactsCard: {
    facts: 'Fakten',
    newFact: 'Neuer Fakt',
    noFactsAddedYet: 'Noch keine Fakten hinzugefügt'
  },
  sceneContextLocationsCard: {
    newLocation: 'Neuer Standort',
    locations: 'Standorte',
    noLocationsAddedYet: 'Noch keine Standorte hinzugefügt'
  },
  sceneContextPersonaCard: {
    personas: 'Personas',
    noPersonasAddedYet: 'Noch keine Personas hinzugefügt'
  },
  blockReachTimeSummaryTab: {
    noSetupReachTimeFound: 'Keine Einrichtungs-Erreichungszeit gefunden',
    fromDialogStart: 'Ab Dialogstart (Sekunden)'
  },
  medicalIndexesSummaryTab: {
    noProtocolReferencesFound: 'Keine Protokollreferenzen gefunden',
    section: 'Abschnitt'
  },
  scenarioSummaryDialog: {
    protocolReferences: 'Protokollreferenzen',
    intentsReachTime: 'Intents-Erreichungszeit',
    summary: 'Zusammenfassung',
    close: 'Schließen'
  },
  sharedScenarioSettingsDialog: {
    sharedScenarioSettings: 'Einstellungen für gemeinsames Szenario',
    organizationsSharedWith: 'Organisationen, mit denen geteilt wird',
    selectOrganizations: 'Organisationen auswählen',
    selectAnOrganization: 'Eine Organisation auswählen',
    convertToNormalScenario: 'In normales Szenario umwandeln'
  },
  addVariableDialog: {
    addVariable: 'Variable hinzufügen',
    example: 'Beispiel',
    name: 'Name',
    value: 'Wert',
    variableName: 'Variablenname',
    variableNameCantContainSpaces: 'Variablenname darf keine Leerzeichen enthalten'
  },
  scenarioVariablesDialog: {
    scenarioVariables: 'Szenario-Variablen',
    organizations: 'Organisationen',
    addVariable: 'Variable hinzufügen',
    defaultVariables: 'Standard-Variablen'
  },
  variableLine: {
    variable: 'Variable',
    nameCantContainSpace: 'Name darf keine Leerzeichen enthalten',
    defaultValue: 'Standardwert',
    value: 'Wert',
    organizationValue: 'Organisationswert',
    update: 'Aktualisieren',
    no: 'Nein',
    yes: 'Ja',
    remove: 'Entfernen',
    areYouSure: 'Sind Sie sicher?'
  },
  variableSuggestions: {
    addNewVariable: 'Neue Variable hinzufügen'
  },
  scenarioEditorPage: {
    hasMadeChanges: 'hat Änderungen am Szenario vorgenommen. Laden Sie die Seite neu, um die neueste Version zu erhalten.',
    copy: 'Kopieren',
    confirmationText: 'Sind Sie sicher, dass Sie dieses Szenario löschen möchten?'
  },
  scenarioSettingsDialog: {
    general: 'Allgemein',
    aiContext: 'KI-Kontext',
    transitionWeights: 'Übergangsgewichte',
    introAudio: 'Intro-Audio',
    scenarioSettings: 'Szenario-Einstellungen',
    save: 'Speichern'
  }
};

export const ScenarioEditorDictionarySw: I18nScenarioEditorNs = {
  intentActionsMenu: {
    removeNode: 'Remove block',
    removeLink: 'Remove link',
    startIntentCantBeRemoved: 'Start block cannot be removed',
    addIntent: 'Add intent',
    removeAllLinks: 'Remove all links',
    deleteAllLinks: 'Delete all links',
    confirmDeleteAll: 'Are you sure you want to delete all links? (Not saved until clicking save)'
  },
  scenarioActionsSection: {
    save: 'Save',
    copy: 'Copy',
    delete: 'Delete',
    summary: 'Summary',
    settings: 'Settings',
    changelog: 'Changelog',
    variables: 'Variables',
    convertToShared: 'Convert to shared',
    sharedSettings: 'Shared settings',
    confirmConvert: 'Are you sure you want to convert this to a shared scenario?',
    warningConvert: 'NB! You might lose some company specific settings (like company voice) when converting!',
    convert: 'Convert'
  },
  confirmationDialog: {
    confirmRestore: 'Confirm Restore',
    confirmationText: 'Are you sure you want to restore the scenario to version',
    restore: 'Restore'
  },
  scenarioChangelogDialog: {
    restore: 'Restore',
    changeType: 'Change type',
    oldValue: 'Old value',
    newValue: 'New value',
    blockName: 'Block name',
    changelog: 'Changelog',
    version: 'Version',
    updatedBy: 'Updated by',
    updatedAt: 'Updated at',
    close: 'Close'
  },
  scenarioCopyDialogForm: {
    copy: 'Copy',
    nameOfCopiedScenario: 'Name of copied scenario',
    copyToOrganization: 'Copy to organization',
    targetLanguage: 'Target language',
    targetLanguageTranslate: 'Target language (select another language to translate with AI)',
    unsavedChanges: 'If you have unsaved changes, they will not be reflected in the copy.',
    copyingScenario: 'Copying scenario in the background. This may take a while. The copy will load when it is ready.'
  },
  scenarioCopyDialog: {
    copyScenario: 'Copy scenario'
  },
  responseSettings: {
    noResponsesAddedYet: 'No responses added yet'
  },
  defaultPersonaSettings: {
    selectPersonaForThisIntent: 'Select AI Persona for this Intent'
  },
  globalAssets: {
    language: 'Språk',
    category: 'Kategori',
    intent: 'Intent',
    globalAssets: 'Intenter',
    noAssetsAddedYet: 'Inga intenter tillagda ännu',
    searchIntents: 'Sök i intenter',
    searchResults: 'Sökresultat',
    matchingSamples: 'Matchande intenter',
    alreadyAdded: 'Redan tillagd',
    noSearchResults: 'Inga sökresultat',
    addTemplate: 'Lägg till mall',
    searchPlaceholder: 'Sök efter intenter',
  },
  intentEditingForm: {
    intentId: 'Intent ID',
    intentName: 'Intent name',
    blockType: 'Block type',
    aiResponse: 'AI response',
    fallbackResponse: 'Fallback response',
    protocolReference: 'Protocol Reference',
    bindTheChapter: 'Bind the chapter to a scenario to choose Protocol Reference',
    persona: 'Persona'
  },
  intentNameSettings: {
    intentName: 'Intent name'
  },
  intentReachTime: {
    intentReachTime: 'Intent reach time',
    fromDialogStart: 'From dialog start (seconds)'
  },
  userSaysSettings: {
    userSays: 'User says'
  },
  nodeActionMenu: {
    copyToCurrentScenario: 'Copy to current scenario',
    copyTo: 'Copy to...',
    blockHasIncomingLinks: 'Block has incoming links',
    blockIsAlreadyStartBlock: 'Block is already start block',
    setAsStartIntent: 'Set as Start Intent'
  },
  useScenarioIntro: {
    microphonePermissionNotGranted:
      'Microphone permission not granted in browser. Check out the tutorial in the User Guides page or ',
    microphoneNotDetected: 'Microphone not detected',
    followThisGuide: 'follow this guide to enable it in your browser settings.'
  },
  chromeBrowserVoiceRecognition: {
    stop: 'Stop',
    pauseDialog: 'Pause dialog',
    resumeDialog: 'Resume dialog',
    saveAllChanges: 'Save all changes to start scenario',
    start: 'Start',
    manualRun: 'Manual run',
    stopListening: 'Stop listening',
    stopScenarioRun: 'Stop scenario run'
  },
  scenarioDisplay: {
    leavePage: 'Leave page?',
    changesYouMadeWillNotBeSaved: 'Changes you made will not be saved.'
  },
  scenarioGeneralSettings: {
    thisVoiceUsesElevenLabs: 'This voice uses Eleven Labs Turbo v2 and is recommended for lower latency.',
    none: 'None',
    scenarioName: 'Scenario name',
    descriptionOptional: 'Description (optional)',
    language: 'Language',
    textToSpeechProvider: 'Text-to-speech provider',
    speechToTextProvider: 'Speech-to-text provider',
    enableLargeLanguageModel: 'Enable Large Language Model (LLM)',
    createSharedScenario: 'Create shared scenario',
    deleted: 'Deleted',
    voice: 'Voice',
    protocolReference: 'Protocol Reference',
    female: 'Female',
    male: 'Male'
  },
  validation: {
    thisFieldIsRequired: 'This field is required'
  },
  scenarioIntroAudioSettingsTab: {
    introAudio: 'Intro audio',
    writeAnIntroText: 'Write an intro text that is read when the scenario starts',
    enable: 'Enable'
  },
  personaDialog: {
    thisFieldIsRequired: 'This field is required',
    personaSettings: 'Persona settings',
    ai: 'AI',
    user: 'User',
    male: 'Male',
    female: 'Female',
    role: 'Role',
    age: 'Age',
    textToSpeechProvider: 'Text-to-speech provider',
    voice: 'Voice',
    useAsDefault: 'Use as default',
    save: 'Save',
    type: 'Type',
    gender: 'Gender'
  },
  sceneContextFactsCard: {
    facts: 'Facts',
    newFact: 'New Fact',
    noFactsAddedYet: 'No Facts added yet'
  },
  sceneContextLocationsCard: {
    newLocation: 'New Location',
    locations: 'Locations',
    noLocationsAddedYet: 'No Locations added yet'
  },
  sceneContextPersonaCard: {
    personas: 'Personas',
    noPersonasAddedYet: 'No Personas added yet'
  },
  blockReachTimeSummaryTab: {
    noSetupReachTimeFound: 'No setup reach time found',
    fromDialogStart: 'From dialog start (seconds)'
  },
  medicalIndexesSummaryTab: {
    noProtocolReferencesFound: 'No Protocol References found',
    section: 'Section'
  },
  scenarioSummaryDialog: {
    protocolReferences: 'Protocol References',
    intentsReachTime: 'Intents reach time',
    summary: 'Summary',
    close: 'Close'
  },
  sharedScenarioSettingsDialog: {
    sharedScenarioSettings: 'Shared scenario settings',
    organizationsSharedWith: 'Organizations shared with',
    selectOrganizations: 'Select organizations',
    selectAnOrganization: 'Select an organization',
    convertToNormalScenario: 'Convert to normal scenario'
  },
  addVariableDialog: {
    addVariable: 'Add variable',
    example: 'Example',
    name: 'Name',
    value: 'Value',
    variableName: 'Variable name',
    variableNameCantContainSpaces: 'Variable name cannot contain spaces'
  },
  scenarioVariablesDialog: {
    scenarioVariables: 'Scenario variables',
    organizations: 'Organizations',
    addVariable: 'Add variable',
    defaultVariables: 'Default Variables'
  },
  variableLine: {
    variable: 'Variable',
    nameCantContainSpace: 'Name cannot contain spaces',
    defaultValue: 'Default value',
    value: 'Value',
    organizationValue: 'Organization value',
    update: 'Update',
    no: 'No',
    yes: 'Yes',
    remove: 'Remove',
    areYouSure: 'Are you sure?'
  },
  variableSuggestions: {
    addNewVariable: 'Add new variable'
  },
  scenarioEditorPage: {
    hasMadeChanges: 'has made changes to the scenario. Reload the page to get the latest version.',
    copy: 'Copy',
    confirmationText: 'Are you sure you want to delete this scenario?'
  },
  scenarioSettingsDialog: {
    general: 'General',
    aiContext: 'AI context',
    transitionWeights: 'Transition weights',
    introAudio: 'Intro audio',
    scenarioSettings: 'Scenario settings',
    save: 'Save'
  }
};

export const ScenarioEditorDictionaryNo: I18nScenarioEditorNs = {
  intentActionsMenu: {
    removeNode: 'Fjern blokk',
    removeLink: 'Fjern lenker',
    startIntentCantBeRemoved: 'Scenariet må ha en startblokk',
    addIntent: 'Legg til Intent',
    removeAllLinks: 'Fjern alle lenker',
    deleteAllLinks: 'Slett alle lenker',
    confirmDeleteAll: 'Er du sikker på du vil slette alle lenker?'
  },
  scenarioActionsSection: {
    save: 'Lagre',
    copy: 'Kopier',
    delete: 'Slett',
    summary: 'Oppsummering',
    settings: 'Innstillinger',
    changelog: 'Endringslogg',
    variables: 'Variabler',
    convertToShared: 'Konverter til delt',
    sharedSettings: 'Delt scenario',
    confirmConvert: 'Er du sikker på du vil konvertere dette til et delt scenario?',
    warningConvert: 'NB! Du kan miste organisasjonsspesifikke innstillinger når du konverterer.',
    convert: 'Konverter'
  },
  confirmationDialog: {
    confirmRestore: 'Bekreft gjenoppretting',
    confirmationText: 'Er du sikker på du vil gjenopprette scenario til versjon',
    restore: 'Gjenopprett'
  },
  scenarioChangelogDialog: {
    restore: 'Gjenopprett',
    changeType: 'Endringstype',
    oldValue: 'Gammel verdi',
    newValue: 'Ny verdi',
    blockName: 'Blokknavn',
    changelog: 'Endringslogg',
    version: 'Versjon',
    updatedBy: 'Oppdatert av',
    updatedAt: 'Oppdatert',
    close: 'Lukk'
  },
  scenarioCopyDialogForm: {
    copy: 'Kopi',
    nameOfCopiedScenario: 'Navn på kopiert scenario',
    copyToOrganization: 'Kopier til organisasjon',
    targetLanguage: 'Språk',
    targetLanguageTranslate: 'Språk (velg et annet språk for å oversette med AI)',
    unsavedChanges: 'Hvis du har endringer som ikke er lagret vil de ikke bli reflektert i kopien.',
    copyingScenario: 'Kopierer scenario i bakgrunnen. Dette kan ta en del tid. Kopien vil laste når den er ferdig.'
  },
  scenarioCopyDialog: {
    copyScenario: 'Kopier scenario'
  },
  responseSettings: {
    noResponsesAddedYet: 'Ingen responser lagt til ennå'
  },
  defaultPersonaSettings: {
    selectPersonaForThisIntent: 'AI-persona'
  },
  globalAssets: {
    language: 'Språk',
    category: 'Kategori',
    intent: 'Intent',
    globalAssets: 'Intenter',    
    noAssetsAddedYet: 'Ingen intenter er lagt til ennå',
    searchIntents: 'Søk i intenter',
    searchResults: 'Søkeresultater',
    matchingSamples: 'Matchende intenter',
    alreadyAdded: 'Allerede lagt til',
    noSearchResults: 'Ingen søkeresultater',
    addTemplate: 'Legg til mal',
    searchPlaceholder: 'Søk etter intenter',
  },
  intentEditingForm: {
    intentId: 'Intent ID',
    intentName: 'Intentnavn',
    blockType: 'Blokktype',
    aiResponse: 'AI-respons',
    fallbackResponse: 'Fallback-svar',
    protocolReference: 'Indeksreferanse',
    bindTheChapter: 'Koble en Indeksreferanse til scenariet for å score på indeks',
    persona: 'Persona'
  },
  intentNameSettings: {
    intentName: 'Intentnavn'
  },
  intentReachTime: {
    intentReachTime: 'Intent-tid',
    fromDialogStart: 'Fra samtalestart (sekunder)'
  },
  userSaysSettings: {
    userSays: 'Brukerfrase'
  },
  nodeActionMenu: {
    copyToCurrentScenario: 'Kopier til dette scenariet',
    copyTo: 'Kopier til...',
    blockHasIncomingLinks: 'Blokk har innkommende lenker',
    blockIsAlreadyStartBlock: 'Blokk er allerede startblokk',
    setAsStartIntent: 'Sett som startblokk'
  },
  useScenarioIntro: {
    microphonePermissionNotGranted:
      'Mikrofontillatelse ikke gitt i nettleser. Sjekk videoen på siden for Hjelpevideoer eller ',
    microphoneNotDetected: 'Mikrofon ikke detektert',
    followThisGuide: 'følg denne guiden for å tillate det i nettleserens innstillinger.'
  },
  chromeBrowserVoiceRecognition: {
    stop: 'Stopp',
    pauseDialog: 'Sett samtale på pause',
    resumeDialog: 'Gjenoppta samtale',
    saveAllChanges: 'Lagre alle endringer for å starte scenario',
    start: 'Start',
    manualRun: 'Manuell samtale',
    stopListening: 'Stopp å lytte',
    stopScenarioRun: 'Stopp samtale'
  },
  scenarioDisplay: {
    leavePage: 'Forlat side_',
    changesYouMadeWillNotBeSaved: 'Endringer du har gjort vil ikke bli lagret.'
  },
  scenarioGeneralSettings: {
    thisVoiceUsesElevenLabs: 'Denne stemmen bruker Eleven Labs Turbo v2 og er anbefalt for å redusere latenstid.',
    none: 'Ingen',
    scenarioName: 'Scenarionavn',
    descriptionOptional: 'Beskrivelse (valgfri)',
    language: 'Språk',
    textToSpeechProvider: 'Talesyntesetjeneste',
    speechToTextProvider: 'Talegjenkjenningstjeneste',
    enableLargeLanguageModel: 'Bruk stor språkmodell (LLM)',
    createSharedScenario: 'Opprett delt scenario',
    deleted: 'Slettet',
    voice: 'Stemme',
    protocolReference: 'Indeksreferanse',
    female: 'Kvinne',
    male: 'Mann'
  },
  validation: {
    thisFieldIsRequired: 'Dette feltet er obligatorisk'
  },
  scenarioIntroAudioSettingsTab: {
    introAudio: 'Introlyd',
    writeAnIntroText: 'Skriv en introtekst som blir lest opp når scenariet starter',
    enable: 'Bruk'
  },
  personaDialog: {
    thisFieldIsRequired: 'Dette feltet er obligatorisk',
    personaSettings: 'Personainnstillinger',
    ai: 'AI',
    user: 'Bruker',
    male: 'Mann',
    female: 'Kvinne',
    role: 'Rolle',
    age: 'Alder',
    textToSpeechProvider: 'Talesyntesetjeneste',
    voice: 'Stemme',
    useAsDefault: 'Bruk som standard',
    save: 'Lagre',
    type: 'Type',
    gender: 'Kjønn'
  },
  sceneContextFactsCard: {
    facts: 'Fakta',
    newFact: 'Nytt Faktum',
    noFactsAddedYet: 'Ingen fakta lagt til ennå'
  },
  sceneContextLocationsCard: {
    newLocation: 'Ny Lokasjon',
    locations: 'Lokasjoner',
    noLocationsAddedYet: 'Ingen Lokasjoner lagt til ennå'
  },
  sceneContextPersonaCard: {
    personas: 'Personaer',
    noPersonasAddedYet: 'Ingen Personaer lagt til ennå'
  },
  blockReachTimeSummaryTab: {
    noSetupReachTimeFound: 'Ingen tidsmål funnet',
    fromDialogStart: 'Fra samtalestart (sekunder)'
  },
  medicalIndexesSummaryTab: {
    noProtocolReferencesFound: 'Ingen Indeksreferanse funnet',
    section: 'Seksjon'
  },
  scenarioSummaryDialog: {
    protocolReferences: 'Indeksreferanse',
    intentsReachTime: 'Intent-tidsmål',
    summary: 'Oppsummering',
    close: 'Lukk'
  },
  sharedScenarioSettingsDialog: {
    sharedScenarioSettings: 'Innstillinger for delt scenario',
    organizationsSharedWith: 'Organisasjoner delt med',
    selectOrganizations: 'Velg Organisasjon',
    selectAnOrganization: 'Velg en Organisasjon',
    convertToNormalScenario: 'Konverter til vanlig scenario'
  },
  addVariableDialog: {
    addVariable: 'Legg til variabel',
    example: 'Eksempel',
    name: 'Navn',
    value: 'Verdi',
    variableName: 'Variabelnavn',
    variableNameCantContainSpaces: 'Variabelnavn kan ikke inneholde mellomrom'
  },
  scenarioVariablesDialog: {
    scenarioVariables: 'Scenariovariabler',
    organizations: 'Organisasjoner',
    addVariable: 'Legg til variabel',
    defaultVariables: 'Default-variabler'
  },
  variableLine: {
    variable: 'Variabel',
    nameCantContainSpace: 'Navn kan ikke inneholde mellomrom',
    defaultValue: 'Standardverdi',
    value: 'Verdi',
    organizationValue: 'Organisasjonsspesifikk verdi',
    update: 'Oppdater',
    no: 'Nei',
    yes: 'Ja',
    remove: 'Fjern',
    areYouSure: 'Er du sikker?'
  },
  variableSuggestions: {
    addNewVariable: 'Legg til ny variabel'
  },
  scenarioEditorPage: {
    hasMadeChanges: 'har gjort endringer på scenariet. Last inn siden på nytt for å få de nyeste endringene.',
    copy: 'Kopier',
    confirmationText: 'Er du sikker på du vil slette dette scenariet?'
  },
  scenarioSettingsDialog: {
    general: 'Generelt',
    aiContext: 'AI-kontekst',
    transitionWeights: 'Overgangsvekter',
    introAudio: 'Introlydklipp',
    scenarioSettings: 'Scenarioinnstillinger',
    save: 'Lagre'
  }
};

export const ScenarioEditorDictionaryNl: I18nScenarioEditorNs = {
  intentActionsMenu: {
    removeNode: 'Remove block',
    removeLink: 'Remove link',
    startIntentCantBeRemoved: 'Start block cannot be removed',
    addIntent: 'Add intent',
    removeAllLinks: 'Remove all links',
    deleteAllLinks: 'Delete all links',
    confirmDeleteAll: 'Are you sure you want to delete all links? (Not saved until clicking save)'
  },
  scenarioActionsSection: {
    save: 'Save',
    copy: 'Copy',
    delete: 'Delete',
    summary: 'Summary',
    settings: 'Settings',
    changelog: 'Changelog',
    variables: 'Variables',
    convertToShared: 'Convert to shared',
    sharedSettings: 'Shared settings',
    confirmConvert: 'Are you sure you want to convert this to a shared scenario?',
    warningConvert: 'NB! You might lose some company specific settings (like company voice) when converting!',
    convert: 'Convert'
  },
  confirmationDialog: {
    confirmRestore: 'Confirm Restore',
    confirmationText: 'Are you sure you want to restore the scenario to version',
    restore: 'Restore'
  },
  scenarioChangelogDialog: {
    restore: 'Restore',
    changeType: 'Change type',
    oldValue: 'Old value',
    newValue: 'New value',
    blockName: 'Block name',
    changelog: 'Changelog',
    version: 'Version',
    updatedBy: 'Updated by',
    updatedAt: 'Updated at',
    close: 'Close'
  },
  scenarioCopyDialogForm: {
    copy: 'Copy',
    nameOfCopiedScenario: 'Name of copied scenario',
    copyToOrganization: 'Copy to organization',
    targetLanguage: 'Target language',
    targetLanguageTranslate: 'Target language (select another language to translate with AI)',
    unsavedChanges: 'If you have unsaved changes, they will not be reflected in the copy.',
    copyingScenario: 'Copying scenario in the background. This may take a while. The copy will load when it is ready.'
  },
  scenarioCopyDialog: {
    copyScenario: 'Copy scenario'
  },
  responseSettings: {
    noResponsesAddedYet: 'No responses added yet'
  },
  defaultPersonaSettings: {
    selectPersonaForThisIntent: 'Select AI Persona for this Intent'
  },
  globalAssets: {
    language: 'Language',
    category: 'Category',
    intent: 'Intent',
    globalAssets: 'Intents',
    noAssetsAddedYet: 'No intents added yet',
    searchIntents: 'Search intents',
    searchResults: 'Search results',
    matchingSamples: 'Matching intents',
    alreadyAdded: 'Already added',
    noSearchResults: 'No search results',
    addTemplate: 'Add template',
    searchPlaceholder: 'Search for intents',
  },
  intentEditingForm: {
    intentId: 'Intent ID',
    intentName: 'Intent name',
    blockType: 'Block type',
    aiResponse: 'AI response',
    fallbackResponse: 'Fallback response',
    protocolReference: 'Protocol Reference',
    bindTheChapter: 'Bind the chapter to a scenario to choose Protocol Reference',
    persona: 'Persona'
  },
  intentNameSettings: {
    intentName: 'Intent name'
  },
  intentReachTime: {
    intentReachTime: 'Intent reach time',
    fromDialogStart: 'From dialog start (seconds)'
  },
  userSaysSettings: {
    userSays: 'User says'
  },
  nodeActionMenu: {
    copyToCurrentScenario: 'Copy to current scenario',
    copyTo: 'Copy to...',
    blockHasIncomingLinks: 'Block has incoming links',
    blockIsAlreadyStartBlock: 'Block is already start block',
    setAsStartIntent: 'Set as Start Intent'
  },
  useScenarioIntro: {
    microphonePermissionNotGranted:
      'Microphone permission not granted in browser. Check out the tutorial in the User Guides page or ',
    microphoneNotDetected: 'Microphone not detected',
    followThisGuide: 'follow this guide to enable it in your browser settings.'
  },
  chromeBrowserVoiceRecognition: {
    stop: 'Stop',
    pauseDialog: 'Pause dialog',
    resumeDialog: 'Resume dialog',
    saveAllChanges: 'Save all changes to start scenario',
    start: 'Start',
    manualRun: 'Manual run',
    stopListening: 'Stop listening',
    stopScenarioRun: 'Stop scenario run'
  },
  scenarioDisplay: {
    leavePage: 'Leave page?',
    changesYouMadeWillNotBeSaved: 'Changes you made will not be saved.'
  },
  scenarioGeneralSettings: {
    thisVoiceUsesElevenLabs: 'This voice uses Eleven Labs Turbo v2 and is recommended for lower latency.',
    none: 'None',
    scenarioName: 'Scenario name',
    descriptionOptional: 'Description (optional)',
    language: 'Language',
    textToSpeechProvider: 'Text-to-speech provider',
    speechToTextProvider: 'Speech-to-text provider',
    enableLargeLanguageModel: 'Enable Large Language Model (LLM)',
    createSharedScenario: 'Create shared scenario',
    deleted: 'Deleted',
    voice: 'Voice',
    protocolReference: 'Protocol Reference',
    female: 'Female',
    male: 'Male'
  },
  validation: {
    thisFieldIsRequired: 'This field is required'
  },
  scenarioIntroAudioSettingsTab: {
    introAudio: 'Intro audio',
    writeAnIntroText: 'Write an intro text that is read when the scenario starts',
    enable: 'Enable'
  },
  personaDialog: {
    thisFieldIsRequired: 'This field is required',
    personaSettings: 'Persona settings',
    ai: 'AI',
    user: 'User',
    male: 'Male',
    female: 'Female',
    role: 'Role',
    age: 'Age',
    textToSpeechProvider: 'Text-to-speech provider',
    voice: 'Voice',
    useAsDefault: 'Use as default',
    save: 'Save',
    type: 'Type',
    gender: 'Gender'
  },
  sceneContextFactsCard: {
    facts: 'Facts',
    newFact: 'New Fact',
    noFactsAddedYet: 'No Facts added yet'
  },
  sceneContextLocationsCard: {
    newLocation: 'New Location',
    locations: 'Locations',
    noLocationsAddedYet: 'No Locations added yet'
  },
  sceneContextPersonaCard: {
    personas: 'Personas',
    noPersonasAddedYet: 'No Personas added yet'
  },
  blockReachTimeSummaryTab: {
    noSetupReachTimeFound: 'No setup reach time found',
    fromDialogStart: 'From dialog start (seconds)'
  },
  medicalIndexesSummaryTab: {
    noProtocolReferencesFound: 'No Protocol References found',
    section: 'Section'
  },
  scenarioSummaryDialog: {
    protocolReferences: 'Protocol References',
    intentsReachTime: 'Intents reach time',
    summary: 'Summary',
    close: 'Close'
  },
  sharedScenarioSettingsDialog: {
    sharedScenarioSettings: 'Shared scenario settings',
    organizationsSharedWith: 'Organizations shared with',
    selectOrganizations: 'Select organizations',
    selectAnOrganization: 'Select an organization',
    convertToNormalScenario: 'Convert to normal scenario'
  },
  addVariableDialog: {
    addVariable: 'Add variable',
    example: 'Example',
    name: 'Name',
    value: 'Value',
    variableName: 'Variable name',
    variableNameCantContainSpaces: 'Variable name cannot contain spaces'
  },
  scenarioVariablesDialog: {
    scenarioVariables: 'Scenario variables',
    organizations: 'Organizations',
    addVariable: 'Add variable',
    defaultVariables: 'Default Variables'
  },
  variableLine: {
    variable: 'Variable',
    nameCantContainSpace: 'Name cannot contain spaces',
    defaultValue: 'Default value',
    value: 'Value',
    organizationValue: 'Organization value',
    update: 'Update',
    no: 'No',
    yes: 'Yes',
    remove: 'Remove',
    areYouSure: 'Are you sure?'
  },
  variableSuggestions: {
    addNewVariable: 'Add new variable'
  },
  scenarioEditorPage: {
    hasMadeChanges: 'has made changes to the scenario. Reload the page to get the latest version.',
    copy: 'Copy',
    confirmationText: 'Are you sure you want to delete this scenario?'
  },
  scenarioSettingsDialog: {
    general: 'General',
    aiContext: 'AI context',
    transitionWeights: 'Transition weights',
    introAudio: 'Intro audio',
    scenarioSettings: 'Scenario settings',
    save: 'Save'
  }
};

export const ScenarioEditorDictionaryIs: I18nScenarioEditorNs = {
  intentActionsMenu: {
    removeNode: 'Remove block',
    removeLink: 'Remove link',
    startIntentCantBeRemoved: 'Start block cannot be removed',
    addIntent: 'Add intent',
    removeAllLinks: 'Remove all links',
    deleteAllLinks: 'Delete all links',
    confirmDeleteAll: 'Are you sure you want to delete all links? (Not saved until clicking save)'
  },
  scenarioActionsSection: {
    save: 'Save',
    copy: 'Copy',
    delete: 'Delete',
    summary: 'Summary',
    settings: 'Settings',
    changelog: 'Changelog',
    variables: 'Variables',
    convertToShared: 'Convert to shared',
    sharedSettings: 'Shared settings',
    confirmConvert: 'Are you sure you want to convert this to a shared scenario?',
    warningConvert: 'NB! You might lose some company specific settings (like company voice) when converting!',
    convert: 'Convert'
  },
  confirmationDialog: {
    confirmRestore: 'Confirm Restore',
    confirmationText: 'Are you sure you want to restore the scenario to version',
    restore: 'Restore'
  },
  scenarioChangelogDialog: {
    restore: 'Restore',
    changeType: 'Change type',
    oldValue: 'Old value',
    newValue: 'New value',
    blockName: 'Block name',
    changelog: 'Changelog',
    version: 'Version',
    updatedBy: 'Updated by',
    updatedAt: 'Updated at',
    close: 'Close'
  },
  scenarioCopyDialogForm: {
    copy: 'Copy',
    nameOfCopiedScenario: 'Name of copied scenario',
    copyToOrganization: 'Copy to organization',
    targetLanguage: 'Target language',
    targetLanguageTranslate: 'Target language (select another language to translate with AI)',
    unsavedChanges: 'If you have unsaved changes, they will not be reflected in the copy.',
    copyingScenario: 'Copying scenario in the background. This may take a while. The copy will load when it is ready.'
  },
  scenarioCopyDialog: {
    copyScenario: 'Copy scenario'
  },
  responseSettings: {
    noResponsesAddedYet: 'No responses added yet'
  },
  defaultPersonaSettings: {
    selectPersonaForThisIntent: 'Select AI Persona for this Intent'
  },
  globalAssets: {
    language: 'Tungumál',
    category: 'Flokkur',
    intent: 'Intent',
    globalAssets: 'Intents',
    noAssetsAddedYet: 'No intents added yet',
    searchIntents: 'Leita að intentum',
    searchResults: 'Leitarniðurstöður',
    matchingSamples: 'Samsvarandi intent',
    alreadyAdded: 'Þegar bætt við',
    noSearchResults: 'Engar leitarniðurstöður',
    addTemplate: 'Bæta við sniðmáti',
    searchPlaceholder: 'Leita að intentum',
  },
  intentEditingForm: {
    intentId: 'Intent ID',
    intentName: 'Intent name',
    blockType: 'Block type',
    aiResponse: 'AI response',
    fallbackResponse: 'Fallback response',
    protocolReference: 'Protocol Reference',
    bindTheChapter: 'Bind the chapter to a scenario to choose Protocol Reference',
    persona: 'Persona'
  },
  intentNameSettings: {
    intentName: 'Intent name'
  },
  intentReachTime: {
    intentReachTime: 'Intent reach time',
    fromDialogStart: 'From dialog start (seconds)'
  },
  userSaysSettings: {
    userSays: 'User says'
  },
  nodeActionMenu: {
    copyToCurrentScenario: 'Copy to current scenario',
    copyTo: 'Copy to...',
    blockHasIncomingLinks: 'Block has incoming links',
    blockIsAlreadyStartBlock: 'Block is already start block',
    setAsStartIntent: 'Set as Start Intent'
  },
  useScenarioIntro: {
    microphonePermissionNotGranted:
      'Microphone permission not granted in browser. Check out the tutorial in the User Guides page or ',
    microphoneNotDetected: 'Microphone not detected',
    followThisGuide: 'follow this guide to enable it in your browser settings.'
  },
  chromeBrowserVoiceRecognition: {
    stop: 'Stop',
    pauseDialog: 'Pause dialog',
    resumeDialog: 'Resume dialog',
    saveAllChanges: 'Save all changes to start scenario',
    start: 'Start',
    manualRun: 'Manual run',
    stopListening: 'Stop listening',
    stopScenarioRun: 'Stop scenario run'
  },
  scenarioDisplay: {
    leavePage: 'Leave page?',
    changesYouMadeWillNotBeSaved: 'Changes you made will not be saved.'
  },
  scenarioGeneralSettings: {
    thisVoiceUsesElevenLabs: 'This voice uses Eleven Labs Turbo v2 and is recommended for lower latency.',
    none: 'None',
    scenarioName: 'Scenario name',
    descriptionOptional: 'Description (optional)',
    language: 'Language',
    textToSpeechProvider: 'Text-to-speech provider',
    speechToTextProvider: 'Speech-to-text provider',
    enableLargeLanguageModel: 'Enable Large Language Model (LLM)',
    createSharedScenario: 'Create shared scenario',
    deleted: 'Deleted',
    voice: 'Voice',
    protocolReference: 'Protocol Reference',
    female: 'Female',
    male: 'Male'
  },
  validation: {
    thisFieldIsRequired: 'This field is required'
  },
  scenarioIntroAudioSettingsTab: {
    introAudio: 'Intro audio',
    writeAnIntroText: 'Write an intro text that is read when the scenario starts',
    enable: 'Enable'
  },
  personaDialog: {
    thisFieldIsRequired: 'This field is required',
    personaSettings: 'Persona settings',
    ai: 'AI',
    user: 'User',
    male: 'Male',
    female: 'Female',
    role: 'Role',
    age: 'Age',
    textToSpeechProvider: 'Text-to-speech provider',
    voice: 'Voice',
    useAsDefault: 'Use as default',
    save: 'Save',
    type: 'Type',
    gender: 'Gender'
  },
  sceneContextFactsCard: {
    facts: 'Facts',
    newFact: 'New Fact',
    noFactsAddedYet: 'No Facts added yet'
  },
  sceneContextLocationsCard: {
    newLocation: 'New Location',
    locations: 'Locations',
    noLocationsAddedYet: 'No Locations added yet'
  },
  sceneContextPersonaCard: {
    personas: 'Personas',
    noPersonasAddedYet: 'No Personas added yet'
  },
  blockReachTimeSummaryTab: {
    noSetupReachTimeFound: 'No setup reach time found',
    fromDialogStart: 'From dialog start (seconds)'
  },
  medicalIndexesSummaryTab: {
    noProtocolReferencesFound: 'No Protocol References found',
    section: 'Section'
  },
  scenarioSummaryDialog: {
    protocolReferences: 'Protocol References',
    intentsReachTime: 'Intents reach time',
    summary: 'Summary',
    close: 'Close'
  },
  sharedScenarioSettingsDialog: {
    sharedScenarioSettings: 'Shared scenario settings',
    organizationsSharedWith: 'Organizations shared with',
    selectOrganizations: 'Select organizations',
    selectAnOrganization: 'Select an organization',
    convertToNormalScenario: 'Convert to normal scenario'
  },
  addVariableDialog: {
    addVariable: 'Add variable',
    example: 'Example',
    name: 'Name',
    value: 'Value',
    variableName: 'Variable name',
    variableNameCantContainSpaces: 'Variable name cannot contain spaces'
  },
  scenarioVariablesDialog: {
    scenarioVariables: 'Scenario variables',
    organizations: 'Organizations',
    addVariable: 'Add variable',
    defaultVariables: 'Default Variables'
  },
  variableLine: {
    variable: 'Variable',
    nameCantContainSpace: 'Name cannot contain spaces',
    defaultValue: 'Default value',
    value: 'Value',
    organizationValue: 'Organization value',
    update: 'Update',
    no: 'No',
    yes: 'Yes',
    remove: 'Remove',
    areYouSure: 'Are you sure?'
  },
  variableSuggestions: {
    addNewVariable: 'Add new variable'
  },
  scenarioEditorPage: {
    hasMadeChanges: 'has made changes to the scenario. Reload the page to get the latest version.',
    copy: 'Copy',
    confirmationText: 'Are you sure you want to delete this scenario?'
  },
  scenarioSettingsDialog: {
    general: 'General',
    aiContext: 'AI context',
    transitionWeights: 'Transition weights',
    introAudio: 'Intro audio',
    scenarioSettings: 'Scenario settings',
    save: 'Save'
  }
};

export const ScenarioEditorDictionaryUk: I18nScenarioEditorNs = {
  intentActionsMenu: {
    removeNode: 'Remove block',
    removeLink: 'Remove link',
    startIntentCantBeRemoved: 'Start block cannot be removed',
    addIntent: 'Add intent',
    removeAllLinks: 'Remove all links',
    deleteAllLinks: 'Delete all links',
    confirmDeleteAll: 'Are you sure you want to delete all links? (Not saved until clicking save)'
  },
  scenarioActionsSection: {
    save: 'Save',
    copy: 'Copy',
    delete: 'Delete',
    summary: 'Summary',
    settings: 'Settings',
    changelog: 'Changelog',
    variables: 'Variables',
    convertToShared: 'Convert to shared',
    sharedSettings: 'Shared settings',
    confirmConvert: 'Are you sure you want to convert this to a shared scenario?',
    warningConvert: 'NB! You might lose some company specific settings (like company voice) when converting!',
    convert: 'Convert'
  },
  confirmationDialog: {
    confirmRestore: 'Confirm Restore',
    confirmationText: 'Are you sure you want to restore the scenario to version',
    restore: 'Restore'
  },
  scenarioChangelogDialog: {
    restore: 'Restore',
    changeType: 'Change type',
    oldValue: 'Old value',
    newValue: 'New value',
    blockName: 'Block name',
    changelog: 'Changelog',
    version: 'Version',
    updatedBy: 'Updated by',
    updatedAt: 'Updated at',
    close: 'Close'
  },
  scenarioCopyDialogForm: {
    copy: 'Copy',
    nameOfCopiedScenario: 'Name of copied scenario',
    copyToOrganization: 'Copy to organization',
    targetLanguage: 'Target language',
    targetLanguageTranslate: 'Target language (select another language to translate with AI)',
    unsavedChanges: 'If you have unsaved changes, they will not be reflected in the copy.',
    copyingScenario: 'Copying scenario in the background. This may take a while. The copy will load when it is ready.'
  },
  scenarioCopyDialog: {
    copyScenario: 'Copy scenario'
  },
  responseSettings: {
    noResponsesAddedYet: 'No responses added yet'
  },
  defaultPersonaSettings: {
    selectPersonaForThisIntent: 'Select AI Persona for this Intent'
  },
  globalAssets: {
    language: 'Language',
    category: 'Category',
    intent: 'Intent',
    globalAssets: 'Intents',
    noAssetsAddedYet: 'No intents added yet',
    searchIntents: 'Search intents',
    searchResults: 'Search results',
    matchingSamples: 'Matching intents',
    alreadyAdded: 'Already added',
    noSearchResults: 'No search results',
    addTemplate: 'Add template',
    searchPlaceholder: 'Search for intents',
  },
  intentEditingForm: {
    intentId: 'Intent ID',
    intentName: 'Intent name',
    blockType: 'Block type',
    aiResponse: 'AI response',
    fallbackResponse: 'Fallback response',
    protocolReference: 'Protocol Reference',
    bindTheChapter: 'Bind the chapter to a scenario to choose Protocol Reference',
    persona: 'Persona'
  },
  intentNameSettings: {
    intentName: 'Intent name'
  },
  intentReachTime: {
    intentReachTime: 'Intent reach time',
    fromDialogStart: 'From dialog start (seconds)'
  },
  userSaysSettings: {
    userSays: 'User says'
  },
  nodeActionMenu: {
    copyToCurrentScenario: 'Copy to current scenario',
    copyTo: 'Copy to...',
    blockHasIncomingLinks: 'Block has incoming links',
    blockIsAlreadyStartBlock: 'Block is already start block',
    setAsStartIntent: 'Set as Start Intent'
  },
  useScenarioIntro: {
    microphonePermissionNotGranted:
      'Microphone permission not granted in browser. Check out the tutorial in the User Guides page or ',
    microphoneNotDetected: 'Microphone not detected',
    followThisGuide: 'follow this guide to enable it in your browser settings.'
  },
  chromeBrowserVoiceRecognition: {
    stop: 'Stop',
    pauseDialog: 'Pause dialog',
    resumeDialog: 'Resume dialog',
    saveAllChanges: 'Save all changes to start scenario',
    start: 'Start',
    manualRun: 'Manual run',
    stopListening: 'Stop listening',
    stopScenarioRun: 'Stop scenario run'
  },
  scenarioDisplay: {
    leavePage: 'Leave page?',
    changesYouMadeWillNotBeSaved: 'Changes you made will not be saved.'
  },
  scenarioGeneralSettings: {
    thisVoiceUsesElevenLabs: 'This voice uses Eleven Labs Turbo v2 and is recommended for lower latency.',
    none: 'None',
    scenarioName: 'Scenario name',
    descriptionOptional: 'Description (optional)',
    language: 'Language',
    textToSpeechProvider: 'Text-to-speech provider',
    speechToTextProvider: 'Speech-to-text provider',
    enableLargeLanguageModel: 'Enable Large Language Model (LLM)',
    createSharedScenario: 'Create shared scenario',
    deleted: 'Deleted',
    voice: 'Voice',
    protocolReference: 'Protocol Reference',
    female: 'Female',
    male: 'Male'
  },
  validation: {
    thisFieldIsRequired: 'This field is required'
  },
  scenarioIntroAudioSettingsTab: {
    introAudio: 'Intro audio',
    writeAnIntroText: 'Write an intro text that is read when the scenario starts',
    enable: 'Enable'
  },
  personaDialog: {
    thisFieldIsRequired: 'This field is required',
    personaSettings: 'Persona settings',
    ai: 'AI',
    user: 'User',
    male: 'Male',
    female: 'Female',
    role: 'Role',
    age: 'Age',
    textToSpeechProvider: 'Text-to-speech provider',
    voice: 'Voice',
    useAsDefault: 'Use as default',
    save: 'Save',
    type: 'Type',
    gender: 'Gender'
  },
  sceneContextFactsCard: {
    facts: 'Facts',
    newFact: 'New Fact',
    noFactsAddedYet: 'No Facts added yet'
  },
  sceneContextLocationsCard: {
    newLocation: 'New Location',
    locations: 'Locations',
    noLocationsAddedYet: 'No Locations added yet'
  },
  sceneContextPersonaCard: {
    personas: 'Personas',
    noPersonasAddedYet: 'No Personas added yet'
  },
  blockReachTimeSummaryTab: {
    noSetupReachTimeFound: 'No setup reach time found',
    fromDialogStart: 'From dialog start (seconds)'
  },
  medicalIndexesSummaryTab: {
    noProtocolReferencesFound: 'No Protocol References found',
    section: 'Section'
  },
  scenarioSummaryDialog: {
    protocolReferences: 'Protocol References',
    intentsReachTime: 'Intents reach time',
    summary: 'Summary',
    close: 'Close'
  },
  sharedScenarioSettingsDialog: {
    sharedScenarioSettings: 'Shared scenario settings',
    organizationsSharedWith: 'Organizations shared with',
    selectOrganizations: 'Select organizations',
    selectAnOrganization: 'Select an organization',
    convertToNormalScenario: 'Convert to normal scenario'
  },
  addVariableDialog: {
    addVariable: 'Add variable',
    example: 'Example',
    name: 'Name',
    value: 'Value',
    variableName: 'Variable name',
    variableNameCantContainSpaces: 'Variable name cannot contain spaces'
  },
  scenarioVariablesDialog: {
    scenarioVariables: 'Scenario variables',
    organizations: 'Organizations',
    addVariable: 'Add variable',
    defaultVariables: 'Default Variables'
  },
  variableLine: {
    variable: 'Variable',
    nameCantContainSpace: 'Name cannot contain spaces',
    defaultValue: 'Default value',
    value: 'Value',
    organizationValue: 'Organization value',
    update: 'Update',
    no: 'No',
    yes: 'Yes',
    remove: 'Remove',
    areYouSure: 'Are you sure?'
  },
  variableSuggestions: {
    addNewVariable: 'Add new variable'
  },
  scenarioEditorPage: {
    hasMadeChanges: 'has made changes to the scenario. Reload the page to get the latest version.',
    copy: 'Copy',
    confirmationText: 'Are you sure you want to delete this scenario?'
  },
  scenarioSettingsDialog: {
    general: 'General',
    aiContext: 'AI context',
    transitionWeights: 'Transition weights',
    introAudio: 'Intro audio',
    scenarioSettings: 'Scenario settings',
    save: 'Save'
  }
};
