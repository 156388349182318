import { createAction } from '@reduxjs/toolkit';
import type { LoadMode } from 'app/store/types.js';
import { groupManagementSlice } from 'app/components/GroupManagement/store/groupManagementSlice.js';
import type { CreateUserGroupCommand, UserGroupDto } from '@/generated-api/index.js';

const prefix = groupManagementSlice.name;
const loadGroups = createAction<LoadMode>(`${prefix}/loadGroups`);
const updateGroups = createAction<unknown | unknown[]>(`${prefix}/updateGroups`);
const deleteGroups = createAction<unknown | unknown[]>(`${prefix}/deleteGroups`);

export const createUserGroup = createAction(
  `${prefix}/createCourse`,
  (data: CreateUserGroupCommand, onSuccess: (userGroup: UserGroupDto) => void) => ({
    payload: {
      data,
      onSuccess
    }
  })
);

export const GroupManagementActions = {
  loadGroups,
  updateGroups,
  deleteGroups,
  createUserGroup,
  ...groupManagementSlice.actions
};
