import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'app/store/types.js';
import differenceWith from 'lodash/differenceWith.js';
import type { UserGroupDto } from '@/generated-api/index.js';

export type GroupManagementState = {
  groups: UserGroupDto[];
  loadingStatus: LoadingStatus;
  loadingError: Error | null;

  updatingGroups: UserGroupDto['id'][];
  updatingGroupsError: Error | null;

  loadingStatuses: { [key: string]: LoadingStatus };
};

const initialState: GroupManagementState = {
  groups: [],
  loadingStatus: LoadingStatus.Idle,
  loadingError: null,

  updatingGroups: [],
  updatingGroupsError: null,

  loadingStatuses: {
    createUserGroup: LoadingStatus.Idle
  }
};

type GroupId = UserGroupDto['id'];

export const groupManagementSlice = createSlice({
  name: 'groupManagement',
  initialState,
  reducers: {
    loadStarted(state) {
      state.loadingStatus = LoadingStatus.Loading;
    },
    loadGroupFinished: {
      prepare(payload: UserGroupDto[], error?: Error) {
        return { payload, error };
      },
      reducer(state, action: PayloadAction<UserGroupDto[], string, never, Error | undefined>) {
        if (action.error) {
          state.loadingError = action.error;
          state.loadingStatus = LoadingStatus.Failed;
        } else {
          state.loadingError = null;
          state.loadingStatus = LoadingStatus.Succeeded;
          state.groups = action.payload;
        }
      }
    },

    updateGroupsStarted(state, action: PayloadAction<GroupId[]>) {
      state.updatingGroups = action.payload;
    },
    updateGroupsFinished: {
      prepare(payload: unknown[], error?: Error) {
        return { payload, error };
      },
      reducer(state, action: PayloadAction<unknown[], string, never, Error | undefined>) {
        if (action.error) {
          state.updatingGroupsError = action.error;
          state.updatingGroups = [];
        } else {
          state.updatingGroupsError = null;
          state.updatingGroups = [];
          state.groups = differenceWith(state.groups, action.payload, (g, id) => g.id === id);
        }
      }
    },

    groupCreated(state, action: PayloadAction<UserGroupDto>) {
      state.groups.push(action.payload);
    },

    groupsDeleted(state, action: PayloadAction<GroupId[]>) {
      state.groups = differenceWith(state.groups, action.payload, (group, id) => {
        return group.id === id;
      });
    },

    groupTitleUpdated(
      state,
      action: PayloadAction<{
        id: UserGroupDto['id'];
        title: string;
      }>
    ) {
      const group = state.groups.find((g) => g.id === action.payload.id);
      group.title = action.payload.title;
    },

    groupMemberDeleted(
      state,
      action: PayloadAction<{
        id: UserGroupDto['id'];
        userId: number;
      }>
    ) {
      const group = state.groups.find((g) => g.id === action.payload.id);
      group.users = group.users.filter(({ id }) => id !== action.payload.userId);
    },

    groupUpdated(state, action: PayloadAction<UserGroupDto>) {
      const index = state.groups.findIndex((g) => g.id === action.payload.id);
      state.groups.splice(index, 1, action.payload);
    },
    setCreateUserGroupLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.createUserGroup = action.payload;
    }
  }
});
