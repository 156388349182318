import React, { useCallback } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete.js';
import DeleteGroupDialog from 'app/components/GroupManagement/GroupCard/Actions/DeleteGroupDialog.js';
import type { UserGroupDto } from '@/generated-api/index.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import useDeleteGroup from 'app/components/GroupManagement/GroupCard/Actions/hooks/useDeleteGroup.js';
import { useDispatch, useSelector } from 'react-redux';
import { GroupManagementActions } from 'app/components/GroupManagement/store/groupManagementActions.js';
import { updatingGroupsSelector } from 'app/components/GroupManagement/store/groupManagementSelectors.js';
import AppRoutes from 'app/utils/routes.js';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nGroupManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

export type ActionsProps = {
  group: UserGroupDto;
};

function Actions({ group }: ActionsProps): JSX.Element {
  const isGroupsUpdating = !!useSelector(updatingGroupsSelector).length;
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBoolState(false);

  const [submitDeleteGroup, isDeleting] = useDeleteGroup();
  const [translate] = useTranslation([I18nNamespace.GroupManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDeleteHandler = useCallback(() => {
    submitDeleteGroup(group.id, () => {
      closeDeleteDialog();
      dispatch(GroupManagementActions.groupsDeleted([group.id]));
      history.replace(AppRoutes.GroupManagement);
    });
  }, [closeDeleteDialog, dispatch, group, history, submitDeleteGroup]);

  return (
    <Box display={'flex'}>
      <Box ml={'auto'} mr={2}>
        <Button
          startIcon={<DeleteIcon />}
          variant={'contained'}
          onClick={openDeleteDialog}
          disabled={isDeleting || isGroupsUpdating}
        >
          {isDeleting ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))
          )}
        </Button>
      </Box>

      <DeleteGroupDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        group={group}
        deleteGroup={onDeleteHandler}
        isDeleting={isDeleting}
      />
    </Box>
  );
}

export default Actions;
