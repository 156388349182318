import type { ConversationAnalysisDto } from '@/generated-api/index.js';
import React from 'react';
import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import type { GridColumns } from '@material-ui/data-grid';
import type { IconContainerProps } from '@material-ui/lab';
import { Rating } from '@material-ui/lab';
import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied
} from '@material-ui/icons';
import { Box, makeStyles } from '@material-ui/core';
import OverflowTooltip from 'app/components/utils/OverflowTooltip.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

type ConversationAnalysisProps = {
  analyses: ConversationAnalysisDto[];
  fullHeight?: boolean;
};

const customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
  1: {
    icon: <SentimentVeryDissatisfied />,
    label: 'Very Dissatisfied'
  },
  2: {
    icon: <SentimentDissatisfied />,
    label: 'Dissatisfied'
  },
  3: {
    icon: <SentimentSatisfied />,
    label: 'Neutral'
  },
  4: {
    icon: <SentimentSatisfiedAlt />,
    label: 'Satisfied'
  },
  5: {
    icon: <SentimentVerySatisfied />,
    label: 'Very Satisfied'
  }
};

const useStyles = makeStyles(() => ({
  table: {
    height: 295,
    width: '100%'
  },
  fullHeight: {
    width: '100%'
  }
}));

const IconContainer = (props: IconContainerProps) => {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
};

const ConversationAnalysis = (props: ConversationAnalysisProps) => {
  const { analyses, fullHeight } = props;
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.Dashboard]);

  const hideFooter = analyses.length <= 25;

  const columns: GridColumns = [
    {
      field: 'score',
      headerName: translate(nameof.full<I18nDashboardNs>((n) => n.conversationAnalysis.score)),
      width: 140,
      renderCell: (data) => {
        const row = data.row as ConversationAnalysisDto;

        return (
          <Rating
            value={row.score ?? 0}
            disabled={row.score === null}
            readOnly
            IconContainerComponent={IconContainer}
          />
        );
      }
    },
    {
      field: 'category',
      headerName: translate(nameof.full<I18nDashboardNs>((n) => n.conversationAnalysis.category)),
      flex: 1,
      renderCell: (data) => {
        const row = data.row as ConversationAnalysisDto;

        return <OverflowTooltip title={row.category}>{row.category}</OverflowTooltip>;
      }
    },
    {
      field: 'comment',
      headerName: translate(nameof.full<I18nDashboardNs>((n) => n.conversationAnalysis.comment)),
      flex: 2,
      renderCell: (data) => {
        const row = data.row as ConversationAnalysisDto;

        return <OverflowTooltip title={row.comment}>{row.comment}</OverflowTooltip>;
      }
    }
  ];

  return (
    <Box style={{ width: '100%' }}>
      <SimpleDataGrid
        autoHeight={fullHeight}
        columns={columns}
        rows={analyses}
        className={fullHeight ? classes.fullHeight : classes.table}
        scrollbarSize={17}
        density={fullHeight ? 'standard' : 'compact'}
        hideFooterPagination={hideFooter}
        hideFooter={hideFooter}
      />
    </Box>
  );
};

export default ConversationAnalysis;