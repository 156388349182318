import { makeStyles } from '@material-ui/core/styles/index.js';

export default makeStyles({
  userSaysStartBlock: {
    position: 'absolute',
    animation: '$userSaysStartBlock 0.4s cubic-bezier(.28,.51,.46,1)',
    width: 350,
    borderRadius: 5,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#ddd',
    backgroundColor: '#ffffff99',
    height: 'auto',
    maxHeight: 150,
    overflowY: 'auto'
  },
  tooltipUserPhrases: {
    paddingLeft: 16
  },
  chipDefaultWidth: {
    width: 280,
    justifyContent: 'space-between'
  },
  flowChartContainer: {
    width: '100%',
    height: '75vh',
    overflow: 'hidden'
  },
  '@keyframes userSaysStartBlock': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-2rem)scale(0.9)'
    },

    '100%': {
      opacity: 1,
      transform: 'translateX(0rem)scale(1)'
    }
  }
});
