import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Typography, IconButton, Tooltip, Checkbox, FormControlLabel } from '@material-ui/core';
import { useBoolState, useUnmounted } from 'app/utils/customHooks/index.js';
import { getUsers } from 'app/actions/userManagement/userManagementActions.js';
import type { GridColumns, GridSortModel } from '@material-ui/data-grid';
import { DataGrid } from '@material-ui/data-grid';
import { ScheduledCallsClient } from 'app/apis/api.js';
import callManagementSlice from 'app/components/CallManagement/store/callManagementSlice.js';
import { callsSelector, isCallsStatusLoadingSelector } from './store/callManagementSelectors.js';
import { CallStatusMap } from 'app/components/CallManagement/config.js';
import { format, parseISO, isAfter, subMinutes } from 'date-fns';
import { DateFormat } from 'app/app.constants.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import type { ScheduledCallDto } from '@/generated-api/index.js';
import { ScheduledCallStatus } from '@/generated-api/index.js';
import CreateCallDialog from 'app/components/CallManagement/CreateCallDialog.js';
import EditCallDialog from 'app/components/CallManagement/EditCallDialog.js';
import { getScenarios } from 'app/actions/scenarios/scenarioAction.js';
import { Add, Clear, Edit } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScheduledCallsNs } from '@/i18n/dictionaries/interfaces.js';

const { actions } = callManagementSlice;

function CallManagement(): JSX.Element {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.ScheduledCalls]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [showUpcomingOnly, setShowUpcomingOnly] = useState(true);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'scheduledTime',
      sort: 'asc',
    },
  ]);

  useEffect(() => {
    dispatch(getScenarios());
    dispatch(getUsers());

    const load = async () => {
      try {
        dispatch(actions.loadCallsStarted());
        const { data } = await ScheduledCallsClient.scheduledCallsGet();
        dispatch(actions.loadCallsFinished(data.data));
      } catch (e) {
        dispatch(actions.loadCallsFinished([], e as Error));
      }
    };

    load();
  }, [dispatch]);

  const calls = useSelector(callsSelector);

  const filteredCalls = useMemo(() => {
    if (showUpcomingOnly) {
      const fiveMinutesAgo = subMinutes(new Date(), 5);
      return calls.filter(call => 
        isAfter(parseISO(call.scheduledTime), fiveMinutesAgo) && 
        call.status !== ScheduledCallStatus.NUMBER_3 // Exclude cancelled calls
      );
    }
    return calls;
  }, [calls, showUpcomingOnly]);

  const isCallsLoading = useSelector(isCallsStatusLoadingSelector);

  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolState(false);
  const [editCallState, setEditCallState] = useState<{ call: ScheduledCallDto | null; isDialogOpen: boolean }>({
    call: null,
    isDialogOpen: false
  });

  const [cancellingCalls, setCancellingCalls] = useState<number[]>([]);

  const unmountedRef = useUnmounted();
  const cancelCall = useCallback(
    (id: number) => {
      const request = async () => {
        try {
          setCancellingCalls((calls) => [...calls, id]);
          const { data: updatedCall } = await ScheduledCallsClient.scheduledCallsUpdateStatus(
            id,
            ScheduledCallStatus.NUMBER_3
          ); // cancelled
          dispatch(actions.callUpdated(updatedCall));
        } finally {
          if (!unmountedRef.current) {
            setCancellingCalls((calls) => calls.filter((callId) => callId !== id));
          }
        }
      };

      request();
    },
    [unmountedRef]
  );

  const formatDate = useFormatDate(DateFormat.Time);
  const renderDateCell = useCallback(
    (data) => {
      if (!data.value) return null;
      return formatDate(data.value as string);
    },
    [formatDate]
  );

  const columns = useMemo<GridColumns>(
    () => [
      {
        field: 'sceneName',
        headerName: translate(nameof.full<I18nScheduledCallsNs>((n) => n.scenario)),
        flex: 2
      },
      {
        field: 'companyName',
        headerName: translate(nameof.full<I18nScheduledCallsNs>((n) => n.organization)),
        flex: 2
      },
      {
        field: 'targetName',
        headerName: translate(nameof.full<I18nScheduledCallsNs>((n) => n.user)),
        flex: 1
      },
      {
        field: 'scheduledTime',
        headerName: translate(nameof.full<I18nScheduledCallsNs>((n) => n.time)),
        renderCell: renderDateCell,
        type: 'date',
        width: 170
      },
      {
        field: 'phoneNumber',
        headerName: translate(nameof.full<I18nScheduledCallsNs>((n) => n.phoneNumber)),
        filterable: false,
        disableColumnMenu: true,
        width: 170
      },
      {
        field: 'status',
        headerName: translate(nameof.full<I18nScheduledCallsNs>((n) => n.status)),
        flex: 1,
        type: 'singleSelect',
        valueOptions: Object.values(CallStatusMap),
        valueFormatter: ({ value }) => CallStatusMap[value as ScheduledCallStatus],
        valueParser: (value) => +Object.entries(CallStatusMap).find(([, text]) => text === value)?.[0]
      },
      {
        field: 'actions',
        headerName: translate(nameof.full<I18nScheduledCallsNs>((n) => n.actions)),
        width: 150,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: function Actions({ row: call }) {
          return (
            <div onClick={(e) => e.stopPropagation()}>
              {call.status === ScheduledCallStatus.NUMBER_0 && (
                <Tooltip title={translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallSettings))}>
                  <IconButton onClick={() => setEditCallState({ call, isDialogOpen: true })} size={'small'}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              )}

              {(call.status === ScheduledCallStatus.NUMBER_0 ||
                call.status === ScheduledCallStatus.NUMBER_4 ||
                call.status === ScheduledCallStatus.NUMBER_1) && (
                <Tooltip title={translate(nameof.full<I18nScheduledCallsNs>((n) => n.cancelCall))}>
                  <IconButton
                    disabled={cancellingCalls.includes(call.id)}
                    onClick={() => {
                      cancelCall(call.id);
                    }}
                    size={'small'}
                  >
                    {cancellingCalls.includes(call.id) ? <CircularProgress size={24} /> : <Clear />}
                  </IconButton>
                </Tooltip>
              )}
            </div>
          );
        }
      }
    ],
    [cancellingCalls, cancelCall]
  );

  const handleSortModelChange = (newModel: GridSortModel) => {
    setSortModel(newModel);
  };

  return (
    <Box p={4} width={'100%'} height={700}>
      <Typography variant={'h4'}>{translate(nameof.full<I18nScheduledCallsNs>((n) => n.scheduledCalls))}</Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" py={2}>
        <Button startIcon={<Add />} onClick={openCreateDialog}>
          {translate(nameof.full<I18nScheduledCallsNs>((n) => n.scheduleCall))}
        </Button>
        <FormControlLabel
          control={
            <Checkbox
              checked={showUpcomingOnly}
              onChange={(e) => setShowUpcomingOnly(e.target.checked)}
              color="primary"
            />
          }
          label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.showUpcomingCallsOnly))}
        />
      </Box>
      <DataGrid 
        columns={columns} 
        rows={filteredCalls} 
        pageSize={10} 
        loading={isCallsLoading} 
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      />

      <CreateCallDialog isOpen={isCreateDialogOpen} onClose={closeCreateDialog} />
      <EditCallDialog
        isOpen={editCallState.isDialogOpen}
        onClose={() => {
          setEditCallState({
            call: null,
            isDialogOpen: false
          });
        }}
        call={editCallState.call}
      />
    </Box>
  );
}

export default CallManagement;
