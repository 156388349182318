import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import type { Action } from 'redux-actions';
import { DProcessorProcessClient } from 'app/apis/api.js';
import type { ProcessDialogCommand, ProcessResultDto, SceneDto } from '@/generated-api/index.js';
import { SttProvider } from '@/generated-api/index.js';
import type { IDProcessState } from 'app/models/dialogs/dialogProcess.js';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
import { DialogProcessorActions } from 'app/actions/dialogprocessor/dialogProcessorActions.js';

import type { AxiosResponse } from 'axios';
import {
  DialogProcessorSelector,
  ScenarioChartSelectors,
  ScenarioSelectors
} from 'app/selectors/index.js';
import type { CustomChart } from 'app/models/scenarios/customChart.js';
import type { LinkedScenario } from 'app/models/intents/intentNodeProperties.js';
import { VoiceRecognitionActions } from 'app/actions/voiceRecognition/voiceRecognitionActions.js';
import logger from 'app/utils/logger.js';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';

function* getDPResponse(params: Action<{ text: string; speechBase64?: string; start?: string; end?: string }>) {
  const currentChart: CustomChart = yield select(ScenarioChartSelectors.scenarioChart);
  const scenarioLinks: LinkedScenario[] = yield select(ScenarioSelectors.linkedScenarios);
  const currentChartScenario: SceneDto = yield select(ScenarioSelectors.currentChartScenario);
  const currentMessage = params.payload.text;
  const useStreaming: boolean = yield select(ScenarioSelectors.isStreamingEnabledForScenario);
  const lastResponse: IDProcessState = yield select(DialogProcessorSelector.dialogProcessorSelector);
  const lastBlock = !!lastResponse.blocks.currentBlock ? lastResponse.blocks.currentBlock : null;
  const intentProcessingProvider = currentChartScenario.intentProcessingProvider;

  try {
    const payload: ProcessDialogCommand = {
      sessionId: lastResponse.sessionId,
      text: currentMessage,
      sceneId: lastBlock?.sceneId ?? currentChart.properties.id,
      speech: params.payload.speechBase64,
      intentProcessingProvider: intentProcessingProvider
    };

    const response: AxiosResponse<ProcessResultDto> = yield call(
      [DProcessorProcessClient, DProcessorProcessClient.processProcessDialog],
      payload
    );

    if (!useStreaming) {
      yield put(DialogProcessorActions.setResponseDataStore(response.data));
    }

    if (response.data.lastBlock && response.data.lastBlock.sceneId !== currentChart.properties.id) {
      yield put(ScenarioActions.toNextLinkedScenario(true));
      yield put(
        ScenarioActions.switchCurrentScenarioTo(
          scenarioLinks.findIndex((x) => x.sceneId === response.data.lastBlock.sceneId)
        )
      );
    }
  } catch (error) {
    logger.log('Error getDPResponse: ', error);
  } finally {
    yield put(VoiceRecognitionActions.setUseFallbackStt(false));
  }
}

// Watcher saga to monitor the GET_RESPONSE_DATA action
function* watchGetDPResponse() {
  yield takeEvery(DialogProcessorActions.Type.GET_RESPONSE_DATA, getDPResponse);
}

// Root saga for dialog processor-related sagas
export function* watchAllDPResponseSaga() {
  yield fork(watchGetDPResponse);
}

export const useDialogProcessorMutation = () => {
  const dispatch = useDispatch();
  const currentChart: CustomChart = useSelector(ScenarioChartSelectors.scenarioChart);
  const scenarioLinks: LinkedScenario[] = useSelector(ScenarioSelectors.linkedScenarios);
  const currentChartScenario: SceneDto = useSelector(ScenarioSelectors.currentChartScenario);
  const lastResponse: IDProcessState = useSelector(DialogProcessorSelector.dialogProcessorSelector);
  const useStreaming = useSelector(ScenarioSelectors.isStreamingEnabledForScenario);

  const getDPResponseMutation = useMutation({
    mutationFn: async (params: { text: string; speechBase64?: string; start?: string; end?: string }) => {
      const currentMessage = params.text;
      const lastBlock = !!lastResponse.blocks.currentBlock ? lastResponse.blocks.currentBlock : null;
      const intentProcessingProvider = currentChartScenario.intentProcessingProvider;

      const payload: ProcessDialogCommand = {
        sessionId: lastResponse.sessionId,
        text: currentMessage,
        sceneId: lastBlock?.sceneId ?? currentChart.properties.id,
        speech: params.speechBase64,
        intentProcessingProvider: intentProcessingProvider
      };

      return DProcessorProcessClient.processProcessDialog(payload);
    },
    onSuccess: (response) => {
      if (!useStreaming) {
        dispatch(DialogProcessorActions.setResponseDataStore(response.data));
      }

      if (response.data.lastBlock && response.data.lastBlock.sceneId !== currentChart.properties.id) {
        dispatch(ScenarioActions.toNextLinkedScenario(true));
        dispatch(
          ScenarioActions.switchCurrentScenarioTo(
            scenarioLinks.findIndex((x) => x.sceneId === response.data.lastBlock.sceneId)
          )
        );
      }
    },
    onError: (error) => {
      logger.log('Error getDPResponse: ', error);
    },
    onSettled: () => {
      logger.log('onSettled in useMutation');
      dispatch(VoiceRecognitionActions.setUseFallbackStt(false));
    }
  });

  return getDPResponseMutation;
};
