import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { GroupManagementActions } from './groupManagementActions.js';
import { isGroupsStatusLoadingSelector, isGroupsStatusNotIdleSelector } from './groupManagementSelectors.js';

import { LoadingStatus, LoadMode } from 'app/store/types.js';
import type { AxiosResponse } from 'axios';
import type { PartialListUserGroupDto, UserGroupDto } from '@/generated-api/index.js';
import { UserGroupClient } from 'app/apis/api.js';

function* loadGroups(action: ReturnType<typeof GroupManagementActions.loadGroups>) {
  const isLoading: boolean = yield select(isGroupsStatusLoadingSelector);

  if (isLoading) {
    return;
  }

  if (action.payload === LoadMode.Soft) {
    if (yield select(isGroupsStatusNotIdleSelector)) {
      return;
    }
  }

  yield put(GroupManagementActions.loadStarted());
  try {
    const response: AxiosResponse<PartialListUserGroupDto> = yield UserGroupClient.userGroupGet();
    yield put(GroupManagementActions.loadGroupFinished(response.data.data?.length ? response.data.data : []));
  } catch (e) {
    yield put(GroupManagementActions.loadGroupFinished([], e as Error));
  }
}

function* updateGroups(action: ReturnType<typeof GroupManagementActions.updateGroups>) {
  try {
    const groupsToUpdate = Array.isArray(action.payload) ? action.payload : [action.payload];
    yield put(GroupManagementActions.updateGroupsStarted(groupsToUpdate.map((g) => g.id)));

    const updatedGroups: AxiosResponse<UserGroupDto>[] = yield all(
      groupsToUpdate.map((c) => call([UserGroupClient, UserGroupClient.userGroupUpdate], c.id, c))
    );

    yield put(GroupManagementActions.updateGroupsFinished(updatedGroups.map((r) => r.data)));
  } catch (e) {
    yield put(GroupManagementActions.updateGroupsFinished([], e as Error));
  }
}

function* createUserGroup(action: ReturnType<typeof GroupManagementActions.createUserGroup>) {
  const { data, onSuccess } = action.payload;

  yield put(GroupManagementActions.setCreateUserGroupLoadingStatus(LoadingStatus.Loading));

  try {
    const response: AxiosResponse<UserGroupDto> = yield call([UserGroupClient, UserGroupClient.userGroupCreate], data);

    yield put(GroupManagementActions.groupCreated(response.data));

    yield put(GroupManagementActions.setCreateUserGroupLoadingStatus(LoadingStatus.Succeeded));

    onSuccess(response.data);
  } catch (e) {
    yield put(GroupManagementActions.setCreateUserGroupLoadingStatus(LoadingStatus.Failed));
  }
}

function* groupManagementSaga() {
  yield all([
    yield takeEvery(GroupManagementActions.loadGroups.toString(), loadGroups),
    yield takeEvery(GroupManagementActions.updateGroups.toString(), updateGroups),
    yield takeEvery(GroupManagementActions.createUserGroup.toString(), createUserGroup)
  ]);
}

export default groupManagementSaga;
