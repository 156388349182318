import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import type { ScheduledCallDto, UpdateScheduledCallCommand as Model } from '@/generated-api/index.js';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { useDispatch, useSelector } from 'react-redux';
import { scenariosLoading, scenariosSelector } from 'app/selectors/scenarioSelectors.js';
import * as userManagementSelectors from 'app/selectors/userManagementSelectors.js';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import useScheduledCallSchema from './schema.js';
import { ScheduledCallsClient } from 'app/apis/api.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import callManagementSlice from 'app/components/CallManagement/store/callManagementSlice.js';
import { CompaniesSelector } from 'app/selectors/index.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import _ from 'lodash';
import type { Theme } from '@material-ui/core/styles/index.js';
import logger from 'app/utils/logger.js';
import UpdateIcon from '@material-ui/icons/Update';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScheduledCallsNs } from '@/i18n/dictionaries/interfaces.js';

const { actions: callManagementActions } = callManagementSlice;

export type EditCallDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  call: ScheduledCallDto | null;
};

const useStyles = makeStyles<Theme>((theme) => {
  return createStyles({
    dateTimePicker: {
      width: '100%'
    },
    errorMessage: {
      color: 'red',
      marginBottom: '1rem'
    },
    dateTimeContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    setNowButton: {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      whiteSpace: 'nowrap',
      minWidth: '140px',
    },
    setNowIcon: {
      marginRight: theme.spacing(1),
    },
  });
});

function EditCallDialog({ isOpen, onClose, call }: EditCallDialogProps): JSX.Element {
  const dispatch = useDispatch();
  const schema = useScheduledCallSchema();
  const classes = useStyles();

  const [submitState, setSubmitState] = useState<{
    data: Model;
    status: ProcessStatus;
  }>({
    status: ProcessStatus.Idle,
    data: {} as Model
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const scenarios = useSelector(scenariosSelector);
  const users = useSelector(userManagementSelectors.users);
  const companies = useSelector(CompaniesSelector.companiesSelector);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const isScenariosLoading = useSelector(scenariosLoading);
  const isUsersLoading = !useSelector(userManagementSelectors.usersLoaded);

  const [translate] = useTranslation([I18nNamespace.ScheduledCalls]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const scenariosMenuItems = useMemo(
    () =>
      [
        <MenuItem key={0} value={0}>
          {translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.noScenarioSelected))}
        </MenuItem>
      ].concat(
        _.sortBy(scenarios, [(scenario) => scenario.name?.toLowerCase()]).map((scenario) => (
          <MenuItem key={scenario.id} value={scenario.id}>
            <Tooltip key={scenario.id} title={scenario.description || ''}>
              <span>{scenario.name}</span>
            </Tooltip>
          </MenuItem>
        ))
      ),
    [scenarios, translate]
  );

  const usersMenuItems = useMemo(
    () =>
      [
        <MenuItem key={-1} value={-1}>
          {translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.noUserSelected))}
        </MenuItem>
      ].concat(
        _.sortBy(users, [(user) => user.name?.toLowerCase()]).map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {`${user.name} ${isSuperAdmin ? ` (${companies.find((c) => c.id === user.companyId)?.name})` : ''}`}
          </MenuItem>
        ))
      ),
    [users, companies, isSuperAdmin, translate]
  );

  const { control, handleSubmit, watch, setValue, errors, clearErrors, reset, getValues } = useForm<Model>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      phoneNumber: '',
      sceneId: 0,
      scheduledTime: moment().format('YYYY-MM-DDTHH:mm'),
      targetName: '',
      userId: -1, // TODO -1 = custom?
    }
  });

  const { userId, scheduledTime } = watch();
  const isCustomUserSelected = userId === -1;
  const startNow = moment().isSameOrAfter(scheduledTime, 'minute');

  useEffect(() => {
    if (call) {
      reset({
        ...call,
        userId: call.userId ?? -1,
        scheduledTime: moment(call.scheduledTime).format('YYYY-MM-DDTHH:mm')
      });
      setErrorMessage(null);
    }
  }, [call, reset]);

  useEffect(() => {
    if (!getValues('userId')) {
      return;
    }

    if (isCustomUserSelected) {
      setValue('targetName', '');
      setValue('phoneNumber', '');
    } else {
      const user = users.find((u) => u.id === userId);

      setValue('targetName', user ? `${user.name}` : '');
      clearErrors('phoneNumber');
    }
  }, [userId, isCustomUserSelected, users, setValue, getValues, clearErrors]);

  const unmountedRef = useUnmounted();

  useEffect(() => {
    if (submitState.status !== ProcessStatus.StartProcess) {
      return;
    }

    const submitData = async () => {
      if (!call || !call.id) {
        setErrorMessage(translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.errorMissingCallData)));
        setSubmitState((s) => ({ ...s, status: ProcessStatus.Idle }));
        return;
      }

      try {
        setSubmitState((s) => ({
          ...s,
          status: ProcessStatus.Processing
        }));

        const updated = await ScheduledCallsClient.scheduledCallsUpdate(call.id, submitState.data);
        dispatch(callManagementActions.callUpdated(updated.data));

        onClose();
      } catch (e) {
        logger.error(e);
        setErrorMessage(translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.errorUpdatingCall)));
      } finally {
        if (!unmountedRef.current) {
          setSubmitState((s) => ({
            ...s,
            status: ProcessStatus.Idle
          }));
        }
      }
    };

    submitData();
  }, [dispatch, onClose, submitState, unmountedRef, call, translate]);

  const onSubmit = handleSubmit(
    (data) => {
      console.log('Form submitted successfully with data:', data);
      setSubmitState({
        status: ProcessStatus.StartProcess,
        data: schema.cast(data)
      });
    },
    (errors) => {
      console.error('Form validation errors:', errors);
    }
  );

  const handleSetNow = () => {
    setValue('scheduledTime', moment().format('YYYY-MM-DDTHH:mm'), { shouldValidate: true });
  };

  return (
    <Dialog open={isOpen} maxWidth={'sm'} fullWidth onClose={onClose}>
      <DialogTitle>{translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.title))}</DialogTitle>
      <DialogContent>
        <Box overflow={'hidden'} pb={4}>
          {errorMessage && (
            <Typography className={classes.errorMessage}>
              {errorMessage}
            </Typography>
          )}
          <form id="edit-call-form" onSubmit={onSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      select
                      fullWidth
                      margin="normal"
                      label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.scenario))}
                      {...props}
                      disabled={isScenariosLoading || !call}
                      inputRef={props.ref}
                      error={!!errors?.sceneId?.message}
                      helperText={errors?.sceneId?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.thisFieldIsRequired))}
                      placeholder={translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.selectScenario))}
                    >
                      {scenariosMenuItems}
                    </TextField>
                  )}
                  control={control}
                  name={nameof.full<Model>((c) => c.sceneId)}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      select
                      fullWidth
                      margin="normal"
                      label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.user))}
                      {...props}
                      inputRef={props.ref}
                      disabled={isUsersLoading || !call}
                      error={!!errors?.userId?.message}
                      helperText={errors?.userId?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.youMustSelectaUser))}
                      placeholder={translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.selectUser))}
                    >
                      {usersMenuItems}
                    </TextField>
                  )}
                  control={control}
                  name={nameof.full<Model>((c) => c.userId)}
                />
              </Grid>
              <Controller
                render={(props) =>
                  isCustomUserSelected && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        margin="normal"
                        label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.targetUserName))}
                        {...props}
                        inputRef={props.ref}
                        disabled={!call}
                        error={!!errors?.targetName?.message}
                        helperText={errors?.targetName?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.thisFieldIsRequired))}
                      />
                    </Grid>
                  )
                }
                control={control}
                name={nameof.full<Model>((c) => c.targetName)}
              />
              <Grid item xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      fullWidth
                      margin="normal"
                      label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.phoneNumber))}
                      {...props}
                      inputRef={props.ref}
                      disabled={!call}
                      error={!!errors?.phoneNumber?.message}
                      helperText={errors?.phoneNumber?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.phoneNumberMissing))}
                    />
                  )}
                  control={control}
                  name={nameof.full<Model>((c) => c.phoneNumber)}
                />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.dateTimeContainer}>
                  <Controller
                    render={(props) => (
                      <TextField
                        fullWidth
                        margin="normal"
                        type="datetime-local"
                        label={translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.scheduledTime))}
                        {...props}
                        InputProps={{
                          classes: { root: classes.dateTimePicker }
                        }}
                        inputRef={props.ref}
                        disabled={!call}
                        error={!!errors?.scheduledTime?.message}
                        helperText={errors?.scheduledTime?.message && translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.thisFieldIsRequired))}
                      />
                    )}
                    control={control}
                    name={nameof.full<Model>((c) => c.scheduledTime)}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.setNowButton}
                    onClick={handleSetNow}
                    disabled={!call}
                    startIcon={<UpdateIcon className={classes.setNowIcon} />}
                  >
                    {translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.setToNow))}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Button
          color={'primary'}
          form="edit-call-form"
          variant='contained'
          type={'submit'}
          disabled={submitState.status === ProcessStatus.Processing || !call}
        >
          {submitState.status === ProcessStatus.Processing ? (
            <CircularProgress color="secondary" size={24} />
          ) : startNow ? (
            translate(nameof.full<I18nScheduledCallsNs>((n) => n.editCallForm.startNow))
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.confirm))
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCallDialog;
