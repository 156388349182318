import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../useStyles.js';
import type { UserGroupDto } from '@/generated-api/index.js';
import { useSelector } from 'react-redux';
import { users as usersSelector } from 'app/selectors/userManagementSelectors.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import { DateFormat } from 'app/app.constants.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nGroupManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

export type RightPaneProps = {
  group?: UserGroupDto;
};

function RightPane({ group }: RightPaneProps): JSX.Element {
  const classes = useStyles();

  const users = useSelector(usersSelector);
  const [translate] = useTranslation([I18nNamespace.GroupManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const createdBy = useMemo(() => {
    if (!group?.createdBy) {
      return null;
    }

    return users.find((u) => u.id === group?.createdBy)?.name;
  }, [users, group?.createdBy]);

  const formatDate = useFormatDate();

  return (
    <div className={classes.summary}>
      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nGroupManagementNs>((n) => n.department))}
      </Typography>
      <Typography component="span" className={classes.summaryItem}>
        {group.departmentName ??
          translate(nameof.full<I18nGroupManagementNs>((n) => n.createGroupForm.defaultDepartment))}
      </Typography>
      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nGroupManagementNs>((n) => n.createdBy))}
      </Typography>
      <Typography component="span" className={classes.summaryItem}>
        {createdBy}
      </Typography>

      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nGroupManagementNs>((n) => n.createdDate))}
      </Typography>
      <Typography component="span" className={classes.summaryItem}>
        {formatDate(group.createdDate, DateFormat.Time)}
      </Typography>

      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nGroupManagementNs>((n) => n.membersCount))}
      </Typography>
      <Typography component="span" className={classes.summaryItem}>
        {group?.users?.length}
      </Typography>
    </div>
  );
}

export default RightPane;
