import React, { useContext, useMemo } from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import useStyles from '../useStyles.js';
import type { GlobalAssetFullDto } from '@/generated-api/index.js';
import { useSelector } from 'react-redux';
import { users as usersSelector } from 'app/selectors/userManagementSelectors.js';
import TemplateConnections from 'app/components/GlobalAssetsLibrary/CategoryCard/RightPane/TemplateConnections/TemplateConnections.js';
import { GlobalAssetsLibraryContext } from '../../Context/GlobalAssetsLibraryContext.js';
import { companiesSelector } from 'app/selectors/companiesSelector.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

export type RightPaneProps = {
  category?: GlobalAssetFullDto;
};

function RightPane({ category }: RightPaneProps): JSX.Element {
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const users = useSelector(usersSelector);
  const companies = useSelector(companiesSelector);
  const { selectedTemplate } = useContext(GlobalAssetsLibraryContext);

  const createdBy = useMemo(() => {
    if (!category?.createdBy) {
      return null;
    }

    const createdByUser = users.find((u) => u.id === category?.createdBy);
    if (!createdByUser) return null;
    const createdByCompany = companies.find((c) => c.id === createdByUser.companyId);
    return `${createdByUser.name}${createdByCompany?.name != null ? ` (${createdByCompany.name})` : null}`;
  }, [category?.createdBy, users, companies]);

  return (
    <>
      <Typography variant="h6" component="div" className={classes.summaryItem}>
        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.rightPane.categoryData))}
      </Typography>
      <Divider />
      <div className={classes.summary}>
        <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
          {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.rightPane.createdBy))}
        </Typography>
        <Typography component="span" className={classes.summaryItem}>
          {createdBy ?? 'Administrator'}
        </Typography>

        <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
          {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.rightPane.createdDate))}
        </Typography>
        <Typography component="span" className={classes.summaryItem}>
          {new Date(category.createdDate).toLocaleString()}
        </Typography>

        <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
          {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.rightPane.intentsCount))}
        </Typography>
        <Typography component="span" className={classes.summaryItem}>
          {category?.intents.length}
        </Typography>

        {!category.companyId ? (
          <>
            <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
              {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.rightPane.sharedWith))}
            </Typography>
            <Typography component="span" className={classes.summaryItem}>
              {category.sharedCompanies?.map((c) => c.name).join(', ')}
            </Typography>
          </>
        ) : null}
      </div>

      {selectedTemplate && (
        <Box mt={2}>
          <Typography variant="h6" component="div" className={classes.summaryItem}>
            {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.rightPane.intentData))}
          </Typography>
          <Divider />
          <div className={classes.summary}>
            <TemplateConnections />
          </div>
        </Box>
      )}
    </>
  );
}

export default RightPane;
