import type { RootState } from 'app/reducers/index.js';
import { LoadingStatus } from 'app/store/types.js';

function create<T>(selector: (state: RootState['medicalIndex']) => T): (state: RootState) => T {
  return (state: RootState) => selector(state.medicalIndex);
}

export const medicalIndexStateSelector = (state: RootState) => state.medicalIndex;

export const indexesSelector = create((s) => s.indexes);

export const medicalIndexesLoadingStatusSelector = create((s) => s.status);
export const isMedicalIndexesStatusLoadingSelector = create((s) => s.status === LoadingStatus.Loading);
export const isMedicalIndexesStatusSucceedSelector = create((s) => s.status === LoadingStatus.Succeeded);
export const isMedicalIndexesStatusFailedSelector = create((s) => s.status === LoadingStatus.Failed);
export const isMedicalIndexesStatusNotIdleSelector = create((s) => s.status !== LoadingStatus.Idle);
