import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: (props: { color: string }) => ({
      height: theme.spacing(2),
      borderWidth: 2,
      borderColor: props.color,
      borderStyle: 'solid'
    }),
    bar: (props: { color: string }) => ({
      backgroundColor: props.color
    }),
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    }
  })
);
