import type { IPort } from '@mrblenny/react-flow-chart';

const inputPortId = 'portIn';
const outputPortId = 'portOut';
const linkedScenarioId = 'linkedScenario';

export function getInputPortId() {
  return inputPortId;
}
export function getOutputPortId() {
  return outputPortId;
}
export function getLinkedScenarioId() {
  return linkedScenarioId;
}

// NRU: theoretically, port positions can be calculated like the node size, but there are too many variables to do that now
export default function getDefaultPorts(isStartNode: boolean): Record<string, IPort> {
  return {
    [inputPortId]: {
      id: inputPortId,
      type: isStartNode ? 'startingPoint' : 'input',
      position: {
        x: -4,
        y: 173
      }
    },
    [outputPortId]: {
      id: outputPortId,
      type: 'output',
      position: {
        x: 224,
        y: 173
      }
    },
    [linkedScenarioId]: {
      id: linkedScenarioId,
      type: linkedScenarioId
    }
  };
}
