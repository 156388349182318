import type { FC } from 'react';
import React, { useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type IntentNodeProperties from 'app/models/intents/intentNodeProperties.js';
import type {
  MedicalProtocolActionDto,
  MedicalProtocolChapterDto,
  MedicalProtocolDto,
  SceneDto
} from '@/generated-api/index.js';
import { Checkbox, Chip, ListItemIcon, ListItemText, ListSubheader, MenuItem, TextField } from '@material-ui/core';
import type { INode } from '@mrblenny/react-flow-chart';

import { ScenarioChartEditingActions } from 'app/actions/scenarios/scenarioChartEditingActions.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import { medicalIndexStateSelector } from 'app/store/MedicalIndex/medicalIndexSelectors.js';
import setChartState = ScenarioChartEditingActions.setChartState;
import { setCurrentScenarioSaveStatus, setScenarioHasNotBeenModified } from 'app/actions/scenarios/scenarioAction.js';

type ChapterAmkActionsMap = Map<MedicalProtocolChapterDto, { actions: MedicalProtocolActionDto[] }[]>;

const useStyles = makeStyles((theme) => {
  return {
    menu: {
      maxWidth: 400,
      maxHeight: 600
    },
    listItemRoot: {
      whiteSpace: 'normal'
    },
    listSubheaderRoot: {
      lineHeight: '1.25em',
      padding: theme.spacing(2)
    }
  };
});

const emptyArray: number[] = [];

const ProtocolReferenceSettings: FC<{
  scenario?: SceneDto;
  intent: INode<IntentNodeProperties, undefined>;
  onInitializeMedicalProtocolActions: (medicalProtocolActions: MedicalProtocolActionDto[]) => void;
  disabled?: boolean;
  canSelectAction: boolean;
}> = ({ scenario, intent, disabled, ...props }) => {
  const dispatch = useDispatch();

  const medicalIndexes = useSelector(medicalIndexStateSelector).indexes;

  const [chapters, chapterToMedIndexMap] = useMemo(() => {
    const map = medicalIndexes.reduce<Map<MedicalProtocolChapterDto, MedicalProtocolDto>>((map, mi) => {
      mi.chapters.forEach((c) => {
        map.set(c, mi);
      });
      return map;
    }, new Map());

    return [Array.from(map.keys()), map];
  }, [medicalIndexes]);

  const [groupedByChapters, actions] = useMemo(() => {
    const groupedByChapters: ChapterAmkActionsMap = new Map();
    const totalActions: MedicalProtocolActionDto[] = [];

    scenario?.medicalProtocolChapters?.forEach(({ id }) => {
      let responseActions: MedicalProtocolActionDto[] | undefined;
      chapters
        .filter((c) => c.id == id)
        .forEach((c) => {
          responseActions = c?.actions;

          if (responseActions) {
            if (groupedByChapters.has(c)) {
              groupedByChapters.get(c).push({
                actions: responseActions
              });
            } else {
              groupedByChapters.set(c, [
                {
                  actions: responseActions
                }
              ]);
            }

            totalActions.push(...responseActions);
          }
        });
    });

    return [groupedByChapters, totalActions];
  }, [chapters, scenario]);

  const valueRenderer = () => (
    <div>
      {intent?.properties.medicalProtocolActions?.map(({ id }) => {
        const response = actions.find((action) => action.id == id);
        return <Chip key={response?.id} label={response?.name} />;
      })}
    </div>
  );

  const value = intent?.properties.medicalProtocolActions.map((a) => a.id)
    ? [...intent?.properties.medicalProtocolActions.map((a) => a.id)]
    : emptyArray;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const originalActions = intent.properties.medicalProtocolActions;
    if (originalActions) {
      originalActions.splice(0, originalActions.length);
      const actionsFound = actions.filter((a) => ((e.target.value as undefined) as number[]).includes(a.id));
      originalActions.push(...actionsFound);
    } else {
      intent.properties.medicalProtocolActions =
        ((e.target.value as unknown) as number[]).map<MedicalProtocolActionDto>((s) => ({ id: s })) ?? [];
    }

    // TODO: get rid of this
    dispatch(
      setChartState((state) => ({
        ...state,
        nodes: {
          ...state.nodes
        }
      }))
    );

    dispatch(setCurrentScenarioSaveStatus(false));
    dispatch(setScenarioHasNotBeenModified(false));
  };

  const classes = useStyles();
  const selectRef = useRef();

  const menu = useMemo(() => {
    const components: React.ReactNode[] = [];
    const valueSet = new Set(value);
    const sortedChapters = Array.from(groupedByChapters.keys()).sort((c1, c2) => c1.title.localeCompare(c2.title));

    sortedChapters.forEach((chapter) => {
      const chapterGrouped = groupedByChapters.get(chapter);

      chapterGrouped.forEach(({ actions }) => {
        const medIndex = chapterToMedIndexMap.get(chapter);

        components.push(
          <ListSubheader
            classes={{
              root: classes.listSubheaderRoot
            }}
            key={chapter.id}
          >{`${medIndex.title}: ${chapter.title}`}</ListSubheader>
        );

        components.push(
          ...actions.map((action) => (
            <MenuItem key={action.id} value={action.id}>
              <ListItemIcon>
                <Checkbox
                  checked={valueSet.has(action.id)}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `${action.id}` }}
                />
              </ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemRoot
                }}
                disableTypography
                primary={action?.name}
              />
            </MenuItem>
          ))
        );
      });
    });

    return components;
  }, [chapterToMedIndexMap, classes.listItemRoot, classes.listSubheaderRoot, groupedByChapters, value]);

  return (
    !!props.canSelectAction && (
      <TextField
        ref={selectRef}
        onChange={onChange}
        SelectProps={{
          multiple: true,
          value: value,
          renderValue: valueRenderer,
          MenuProps: {
            classes: {
              paper: classes.menu
            },
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'center'
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: 'center'
            },
            getContentAnchorEl: null
          }
        }}
        disabled={disabled}
        select
        fullWidth
      >
        {menu}
      </TextField>
    )
  );
};

export default ProtocolReferenceSettings;
