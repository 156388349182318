import type { IPosition } from '@mrblenny/react-flow-chart';
import { getDirectional } from '@mrblenny/react-flow-chart';

export function toSvgPosition(pos: IPosition): string {
  return pos.x + ',' + pos.y;
}

export function getCustomCurveControlPoints(startPoint: IPosition, endPoint: IPosition): [IPosition, IPosition] {
  const { width, height } = getDirectional(startPoint, endPoint);

  const curveX = Math.max(width / 3, height / 3);

  const startControl: IPosition = {
    x: startPoint.x + curveX,
    y: startPoint.y
  };
  const endControl: IPosition = {
    x: endPoint.x - curveX,
    y: endPoint.y
  };

  return [startControl, endControl];
}

/**
 * Compute the derivative of the four-point Bezier curve at t = 0.5.
 * @param p1 First coefficient. Corresponds to the start point of the curve.
 * @param p2 Second coefficient. Corresponds to the start control point.
 * @param p3 Third coefficient. Corresponds to the end control point.
 * @param p4 Fourth coefficient. Corresponds to the end point of the curve.
 * @returns The computed derivative.
 */
function computeMidPointDerivative(p1: number, p2: number, p3: number, p4: number): number {
  return 0.25 * (p2 - p1 + p4 - p3) + 0.5 * (p3 - p2);
}

/**
 * Compute the angle at the middle point of the four-point Bezier curve.
 * @param start Start point of the curve.
 * @param startControl Start control point.
 * @param endControl End control point.
 * @param end End point of the curve.
 * @returns The computed angle in radians.
 */
function computeMidPointAngle(
  start: IPosition,
  startControl: IPosition,
  endControl: IPosition,
  end: IPosition
): number {
  return Math.atan2(
    computeMidPointDerivative(start.y, startControl.y, endControl.y, end.y),
    computeMidPointDerivative(start.x, startControl.x, endControl.x, end.x)
  );
}

/**
 * Get the data necessary to
 * @param start Start point of the curve.
 * @param startControl Start control point.
 * @param endControl End control point.
 * @param end End point of the curve.
 * @returns An array of two elements: the middle point on the curve and the angle of inclination in degrees.
 */
export function getArrowHeadData(
  start: IPosition,
  startControl: IPosition,
  endControl: IPosition,
  end: IPosition
): [IPosition, number] {
  // NOTE: this simple calculation only works because the target curve is symmetrical. If it's not,
  // the mid point should be calculated by using the cubic Bezier formula with t = 0.5 and the four points as parameters.
  return [
    {
      x: (start.x + end.x) / 2,
      y: (start.y + end.y) / 2
    },
    computeMidPointAngle(start, startControl, endControl, end) * (180 / Math.PI)
  ];
}
