import type { Store } from 'redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';
import type { RootState } from 'app/reducers/rootReducer.js';
import { rootReducer } from 'app/reducers/rootReducer.js';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../middleware/sagas/rootSaga.js';
import { produce } from 'immer';

import AudioAction from 'app/actions/audio/audioAction.js';
import { ActionType as DashboardActionType } from 'app/actions/dasboard/dashboardActions.js';

export function configureStore(initialState?: RootState): [Store<RootState>] {
  // Redux DevTools extension configuration
  const devTools = composeWithDevToolsLogOnlyInProduction({
    // trace: true,
    // traceLimit: 25,
    actionSanitizer: (action) => {
      switch (action.type) {
        case DashboardActionType.SetAggregateVoice:
          return {
            ...action,
            payload: Array.isArray((action as any).payload.aggregateVoices)
              ? `[[AUDIO_BLOB]] x${(action as any).payload.aggregateVoices.length}`
              : (action as any).payload
          };

        case AudioAction.Type.SET_AUDIO_STORE:
          return { ...action, payload: (action as any).payload && '[[AUDIO_BLOB]]' };
      }

      return action;
    },

    stateSanitizer: (state) => {
      const s = state as RootState;
      const updatedState: RootState = {
        ...s,
        audioReducer: produce(s.audioReducer, (r) => {
          if (r.voice) {
            r.voice = '[[AUDIO_BLOB]]';
          }
        }),
        dashboardReducer: produce(s.dashboardReducer, (r) => {
          if (Array.isArray(r.aggregateVoices)) {
            r.aggregateVoices = `[[AUDIO_BLOB]] x${r.aggregateVoices.length}` as any;
          }
        })
      };

      return updatedState as any;
    }
  });

  const sagaMiddleware = createSagaMiddleware();

  // Apply middleware and Redux DevTools enhancement
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || devTools;
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

  if (import.meta.hot) {
    import.meta.hot.accept('app/reducers/rootReducer.js', () => {
      store.replaceReducer(rootReducer);
    });
  }

  sagaMiddleware.run(rootSaga);

  return [store];
}
