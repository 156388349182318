import type { RootState } from '../reducers/rootReducer.js';
import { chartScenarioProperties } from './scenarioChartSelectors.js';
import type { ICurrentScenario, LinkedScenario } from '../models/intents/intentNodeProperties.js';
import type { SceneDto } from '@/generated-api/scenes/api.js';

export const scenariosSelector = (state: RootState) => state.scenarioReducer.scenarios;
export const scenariosLoading = (state: RootState) => state.scenarioReducer.scenariosLoading;
export const detailedScenarioLoading = (state: RootState) => state.scenarioReducer.detailedScenarioLoading;
export const detailedScenarioIsOpening = (state: RootState) => state.scenarioReducer.detailedScenarioIsOpening;
export const scenarioHasNotBeenModifiedSelector = (state: RootState) =>
  state.scenarioReducer.scenarioHasNotBeenModified;
export const isDetailedScenarioUpdate = (state: RootState) => state.scenarioReducer.isDetailedScenarioUpdate;
export const isDetailedScenarioUpdating = (state: RootState) => state.scenarioReducer.isDetailedScenarioUpdating;
export const isStreamingEnabledForScenario = (state: RootState) => state.scenarioReducer.isStreamingEnabledForScenario;

export const getScenarioById = (scenarioId: number) => (state: RootState): SceneDto =>
  state.scenarioReducer.scenarios.find((s: SceneDto) => s.id === scenarioId);
export const currentChartScenario = (state: RootState): SceneDto | undefined => {
  const chartProps = chartScenarioProperties(state);
  return state.scenarioReducer.scenarios.find((s) => s.id === chartProps.id);
};
export const currentChartScenarioLang = (state: RootState): string | undefined => {
  const currentScenario = currentChartScenario(state);
  return currentScenario?.langCode;
};
export const rootScenario = (state: RootState): SceneDto | undefined => {
  return state.scenarioReducer.rootScenario;
};
export const parentScenario = (state: RootState): Array<LinkedScenario> | undefined => {
  return state.scenarioReducer.parentScenario;
};
export const currentScenario = (state: RootState): ICurrentScenario | undefined => {
  return state.scenarioReducer.currentScenario;
};
export const linkedScenarios = (state: RootState): Array<LinkedScenario> => {
  return state.scenarioReducer.linkedScenarios;
};

export const currentTabSelected = (state: RootState): number => {
  return state.scenarioReducer.currentTabSelected;
};
export const toNextLinkedScenario = (state: RootState): boolean => {
  return state.scenarioReducer.toNextlinkedScenario;
};
export const userSaysPhrasesByScenarioId = (state: RootState) => state.scenarioReducer.userSaysPhrasesByScenarioId;
