import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Tooltip,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  ListSubheader
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add.js';
import EditIcon from '@material-ui/icons/Edit.js';
import DeleteIcon from '@material-ui/icons/Delete.js';
import CancelIcon from '@material-ui/icons/Cancel.js';

import type { DepartmentManagerProps, ManagedDepartment } from './types.js';
import DepartmentNameTextField from './DepartmentNameTextField/index.js';
import useStyles from './styles.js';
import DepartmentDeletionMenu from './DepartmentDeletionMenu/index.js';
import { Add, Cancel } from '@material-ui/icons';

function isAdd(comp?: ManagedDepartment) {
  return !!comp && !comp?.id;
}

const DepartmentManager: React.FC<DepartmentManagerProps> = ({ departments, onClose, ...props }) => {
  const dispatch = useDispatch();

  const [departmentToEdit, setDepartmentToEdit] = React.useState<ManagedDepartment>();
  const [departmentToDeleteId, setDepartmentToDeleteId] = React.useState<number>();
  const deletionMenuRef = React.useRef<HTMLButtonElement>();

  const resetSelection = () => {
    setDepartmentToEdit(undefined);
    setDepartmentToDeleteId(undefined);
    deletionMenuRef.current = undefined;
  };

  const onAddDepartment = () => {
    props.onAddDepartment(departmentToEdit);
    resetSelection();
  };

  const onEditDepartment = () => {
    props.onEditDepartment(departmentToEdit);
    resetSelection();
  };

  const onDeleteDepartment = (id: number) => {
    props.onDeleteDepartment({ id });
    resetSelection();
  };

  const classes = useStyles();
  return (
    <>
      <List
        className={classes.root}
        subheader={
          <ListSubheader className={classes.subheader}>
            {isAdd(departmentToEdit) ? (
              <DepartmentNameTextField
                companyName={departmentToEdit?.name}
                onChange={(name) => setDepartmentToEdit({ name })}
                onSave={onAddDepartment}
                onDiscard={resetSelection}
              />
            ) : (
              <>
                <Typography variant="inherit">Department Manager</Typography>
                <ListItemSecondaryAction>
                  <Tooltip title="Add department">
                    <IconButton onClick={() => setDepartmentToEdit({ name: '' })}>
                      <Add />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Close manager">
                    <IconButton onClick={onClose}>
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </>
            )}
            <Divider />
          </ListSubheader>
        }
      >
        {departments.map((c) => (
          <ListItem key={c.id}>
            {c.id === departmentToEdit?.id ? (
              <DepartmentNameTextField
                companyName={departmentToEdit?.name}
                onChange={(name) => setDepartmentToEdit((ce: ManagedDepartment) => ({ ...ce, name }))}
                onSave={onEditDepartment}
                onDiscard={resetSelection}
              />
            ) : (
              <>
                <ListItemText primary={c.name}></ListItemText>
                <ListItemSecondaryAction>
                  <Tooltip title="Edit department">
                    <IconButton onClick={() => setDepartmentToEdit({ ...c })}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete department">
                    <span>
                      <IconButton
                        onClick={(event) => {
                          setDepartmentToDeleteId(c.id);
                          deletionMenuRef.current = event.currentTarget;
                          onDeleteDepartment(c.id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
        ))}
      </List>
      {/* <DepartmentDeletionMenu
        open={!!departmentToDeleteId}
        onClose={resetSelection}
        anchorEl={deletionMenuRef.current}
        onDeleteDepartment={onDeleteDepartment}
        moveToDepartments={React.useMemo(
          () => departments.filter((c) => c.id !== departmentToDeleteId),
          [departments, departmentToDeleteId]
        )}
      /> */}
    </>
  );
};

export default DepartmentManager;
