// src/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/NavigationDrawerCompanyScenariosListItem.tsx

import React from 'react';
import ScenarioListItem from '../ScenarioListItem.js';
import type { SceneDto } from '@/generated-api/scenes/api.js';

type NavigationDrawerCompanyScenariosListItemProps = {
  item: SceneDto;
  companyId: number;
  handleRightClick: (event: React.MouseEvent<HTMLElement>, scene: SceneDto) => void;
  handleSelectScenario: () => void;
  classes: {
    selected: string;
    withB: string;
    withTBP: string;
    sharedScenario: string;
    subMenuWidth: string;
    white: string;
  };
  sceneId: number;
};

const NavigationDrawerCompanyScenariosListItem: React.FC<NavigationDrawerCompanyScenariosListItemProps> = ({
  item,
  companyId,
  handleRightClick,
  handleSelectScenario,
  classes,
  sceneId
}) => (
  <ScenarioListItem
    scene={item}
    classes={classes}
    companyId={companyId}
    handleRightClick={handleRightClick}
    handleSelectScenario={handleSelectScenario}
    isSelected={item.id === sceneId}
  />
);

export default NavigationDrawerCompanyScenariosListItem;
