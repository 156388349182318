import React from 'react';
import { useSelector } from 'react-redux';
import ImageIcon from '@material-ui/icons/Image.js';
import { Link as RouterLink } from 'react-router-dom';
import { generatePath, matchPath, useLocation } from 'react-router';
import { Avatar, Link, List, ListItem, ListItemAvatar, ListItemText, Typography, Box } from '@material-ui/core';

import type { CourseDto } from '@/generated-api/index.js';
import { CourseManagementRoutes } from 'app/utils/routes.js';
import { coursesSelector } from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const CourseList: React.FC = () => {
  const courses = useSelector(coursesSelector);
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const location = useLocation();

  if (!courses.length) {
    return (
      <Box py={3} display={'flex'} justifyContent={'center'} color={'action.disabled'}>
        <Typography>{translate(nameof.full<I18nCourseManagementNs>((n) => n.courseListIsEmpty))}</Typography>
      </Box>
    );
  }

  return (
    <List>
      {courses.map((course: CourseDto) => {
        const path = generatePath(CourseManagementRoutes.Course, { courseId: course.id });

        const match = matchPath(location.pathname, {
          path
        });

        return (
          <ListItem key={course.id} component={RouterLink} selected={!!match} to={path}>
            <ListItemAvatar>
              <Avatar variant={'square'}>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<Link component={Typography}>{course.title}</Link>}
              secondary={
                course.scenesCount
                  ? `${course.scenesCount} ${translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarios))}`
                  : translate(nameof.full<I18nCourseManagementNs>((n) => n.noScenariosYet))
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default CourseList;
