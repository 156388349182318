import type IntentPlayback from 'app/models/dashboard/intentPlayback.js';
import type { AddDialogStatisticCommand, PartialListDialogStatisticDto, SortType } from '@/generated-api/index.js';
import type { DialogStatisticFull } from 'app/models/statistic/statistic.js';

export enum ActionType {
  SetSelectedRowById = 'dashboard/SET_STORE_SELECTED_ROW',
  SetRow = 'dashboard/SET_ROW',
  SetAggregateVoice = 'dashboard/SET_AGGREGATE_VOICE',

  GetDialogStatistics = 'dashboard/GET_DIALOG_STATISTICS',
  PostDialogStatistics = 'dashboard/POST_DIALOG_STATISTICS',
  PutDialogStatistics = 'dashboard/PUT_DIALOG_STATISTICS',
  SetStatisticsLoading = 'dashboard/SET_DIALOG_STATISTICS_LOADING'
}

export type PayloadType = {
  [ActionType.SetSelectedRowById]: string;
  [ActionType.SetRow]: { fullDialogStatistic?: DialogStatisticFull };
  [ActionType.SetAggregateVoice]: { aggregateVoices?: IntentPlayback[] };
  [ActionType.PostDialogStatistics]: AddDialogStatisticCommand;
  [ActionType.GetDialogStatistics]: {
    skip: number;
    take: number;
    order: SortType;
    orderBy: string;
    from: Date;
    to: Date;
    search: string;
  };
  [ActionType.PutDialogStatistics]: PartialListDialogStatisticDto;
  [ActionType.SetStatisticsLoading]: boolean;
};

type SpecificPayload<T> = T extends keyof PayloadType ? (PayloadType[T] extends never ? never : PayloadType[T]) : never;

export type Action<T> = T extends keyof PayloadType
  ? {
      type: T;
    } & (SpecificPayload<T> extends void ? unknown : { payload: SpecificPayload<T> })
  : never;

type ActionCreator<T, P = SpecificPayload<T>> = T extends keyof PayloadType ? (payload: P) => Action<T> : never;

export const setRow: ActionCreator<ActionType.SetRow> = (payload) => {
  return {
    type: ActionType.SetRow,
    payload
  };
};

export const setSelectedRow: ActionCreator<ActionType.SetSelectedRowById> = (id) => {
  return {
    type: ActionType.SetSelectedRowById,
    payload: id
  };
};

export const setAggregatedVoices: ActionCreator<ActionType.SetAggregateVoice> = (payload) => {
  return {
    type: ActionType.SetAggregateVoice,
    payload
  };
};

export const postDialogStatistics: ActionCreator<ActionType.PostDialogStatistics> = (
  statistics: AddDialogStatisticCommand
) => {
  return {
    type: ActionType.PostDialogStatistics,
    payload: statistics
  };
};

export const getDialogStatistics: ActionCreator<ActionType.GetDialogStatistics> = (payload) => {
  return {
    type: ActionType.GetDialogStatistics,
    payload
  };
};

export const putDialogStatistics: ActionCreator<ActionType.PutDialogStatistics> = (dialogStatistics) => {
  return {
    type: ActionType.PutDialogStatistics,
    payload: dialogStatistics
  };
};

export const setStatisticsLoading: ActionCreator<ActionType.SetStatisticsLoading, boolean> = (loading) => {
  return {
    type: ActionType.SetStatisticsLoading,
    payload: loading
  };
};
