import React, { useCallback } from 'react';
import { DialogContentText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import type { CourseDto, ScheduledCourseDto, ScheduledCourseUserGroupDto } from '@/generated-api/index.js';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type DeleteScheduledCourseUserGroupDialogProps = {
  open: boolean;
  courseId: string;
  course: CourseDto;
  onClose: () => void;
  onExited: () => void;
  scheduledCourse: ScheduledCourseDto;
  scheduledCourseUserGroup: ScheduledCourseUserGroupDto;
};

const DeleteScheduledCourseUserGroupDialog: React.FC<DeleteScheduledCourseUserGroupDialogProps> = ({
  open,
  course,
  onClose,
  onExited,
  scheduledCourse,
  scheduledCourseUserGroup
}) => {
  const dispatch = useDispatch();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const onDeleteScheduledCourse = useCallback(
    () =>
      dispatch(
        CourseManagementActions.deleteScheduledCourseUserGroup(
          course.id,
          scheduledCourse.id,
          scheduledCourseUserGroup.userGroupId,
          onClose
        )
      ),
    [course, dispatch, scheduledCourse, onClose, scheduledCourseUserGroup]
  );

  const loadingStatus = useSelector(CourseManagementSelectors.deleteScheduledCourseUserGroupLoadingStatus);

  return (
    <DeleteConfirmationDialog
      onClose={onClose}
      title={translate(nameof.full<I18nCourseManagementNs>((n) => n.groups.removeGroup))}
      confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.remove))}
      loadingStatus={loadingStatus}
      onConfirm={onDeleteScheduledCourse}
      DialogProps={{
        open: open,
        TransitionProps: {
          onExited: onExited
        }
      }}
    >
      <DialogContentText>
        {translate(nameof.full<I18nCourseManagementNs>((n) => n.groups.confirmationText))}{' '}
        <b>{scheduledCourseUserGroup?.title}</b> {translate(nameof.full<I18nCourseManagementNs>((n) => n.groups.from))}{' '}
        <b>{course?.title}</b>?
      </DialogContentText>
    </DeleteConfirmationDialog>
  );
};

export default DeleteScheduledCourseUserGroupDialog;
