import type ISimpleDialogProps from '../../../utils/ISimpleDialogProps.js';
import type { SceneDto } from '@/generated-api/index.js';

export interface ScenarioSettingsComponentProps extends ISimpleDialogProps {
  scenarioId?: SceneDto['id'];
}

export const enum ScenarioSettingsLanguageVals {
  English = 'en-US',
  Norwegian = 'nb-NO',
  Danish = 'da-DK',
  German = 'de-DE',
  Icelandic = 'is-IS',
  EnglishUk = 'en-GB',
  EnglishAustralia = 'en-AU',
  Swedish = 'sv-SE',
  SpanishSpain = 'es-ES',
  SpanishMexico = 'es-MX',
  SpanishUS = 'es-US',
  ArabicUae = 'ar-AE'
}
