import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress
} from '@material-ui/core';
import type { GlobalAssetIntentDto } from '@/generated-api/index.js';
import { UserGroupDto } from '@/generated-api/index.js';
import { Controller, useForm } from 'react-hook-form';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { GlobalAssetsClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import { yupResolver } from '@hookform/resolvers/yup';
import useIntentValidationSchema from 'app/components/GlobalAssetsLibrary/CategoryCard/hooks/useIntentValidationSchema.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

export type AddIntentDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  template: GlobalAssetIntentDto;
};

type SubmitState = {
  text: string;
  status: ProcessStatus;
};

const { actions } = globalAssetsLibrarySlice;

function AddIntentDialog({ isOpen, onClose, template }: AddIntentDialogProps): JSX.Element {
  const schema = useIntentValidationSchema('intent', template);
  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const { control, handleSubmit, errors } = useForm<{ intent: string }>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      intent: ''
    }
  });

  const [submitState, setSubmitState] = useState<SubmitState>({
    status: ProcessStatus.Idle,
    text: null
  });

  const onSubmit = handleSubmit((data) => {
    if (submitState.status !== ProcessStatus.Idle) {
      return;
    }

    setSubmitState({
      text: data.intent,
      status: ProcessStatus.StartProcess
    });
  });

  const unmountedRef = useUnmounted();

  const dispatch = useDispatch();

  useEffect(() => {
    if (submitState.status !== ProcessStatus.StartProcess) {
      return;
    }

    setSubmitState((s) => ({ ...s, status: ProcessStatus.Processing }));

    const process = async () => {
      try {
        const { data } = await GlobalAssetsClient.globalAssetsCreateSample(
          template.id,
          JSON.stringify(submitState.text)
        );

        dispatch(actions.templateUpdated(data));

        if (!unmountedRef.current) {
          setSubmitState({ text: null, status: ProcessStatus.Idle });
        }

        onClose();
      } catch (e) {
        logger.error(e);

        if (!unmountedRef.current) {
          setSubmitState({ text: null, status: ProcessStatus.Idle });
        }
      }
    };

    process();
  }, [dispatch, submitState, template, unmountedRef, onClose]);

  const processing = submitState.status !== ProcessStatus.Idle;

  const error = errors['intent'];

  return (
    <Dialog open={isOpen} maxWidth={'xs'} fullWidth>
      <form id="create-intent-form" onSubmit={onSubmit}>
        <DialogTitle>{translate(nameof.full<I18nGlobalAssetsNs>((n) => n.addIntentDialog.addSample))}</DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="intent"
            render={(props) => (
              <TextField
                {...props}
                autoFocus
                error={!!error}
                helperText={error?.message}
                label={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.addIntentDialog.sampleText))}
                placeholder={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.addIntentDialog.enterSampleText))}
                fullWidth
                multiline
                maxRows={5}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
          <Button color="primary" form="create-intent-form" type="submit" disabled={processing}>
            {processing ? (
              <CircularProgress color="secondary" size={24} />
            ) : (
              translate(nameof.full<I18nGlobalAssetsNs>((n) => n.addIntentDialog.addSample))
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddIntentDialog;
