import type { RootState } from 'app/reducers/index.js';
import type { UserDto } from '@/generated-api/index.js';
import { RoleId } from 'app/models/users/role.js';
import type { LoadingStatus } from 'app/store/types.js';

export const CurrentUserSelector: (state: RootState) => UserDto = (state) => state.userReducer.currentUser;

export const CurrentUserLoadingStatusSelector: (state: RootState) => LoadingStatus = (state) =>
  state.userReducer.currentUserLoadingStatus;

export const CurrentUserNotFoundSelector: (state: RootState) => boolean = (state) =>
  state.userReducer.currentUserNotFound;

export const RoleSelector: (state: RootState) => RoleId = (state) =>
  (state.userReducer.currentUser?.role as unknown) as RoleId;

const createHasRoleSelector = (role: RoleId) => (state: RootState): boolean => RoleSelector(state) === role;

export const isUserSuperAdminSelector = createHasRoleSelector(RoleId.SUPERADMIN);

export const isUserOwnerSelector = createHasRoleSelector(RoleId.OWNER);

export const isUserAdminSelector = createHasRoleSelector(RoleId.ADMIN);

export const isUserMemberSelector = createHasRoleSelector(RoleId.MEMBER);

export const isUserAnyAdminSelector: (state: RootState) => boolean = (state) =>
  isUserSuperAdminSelector(state) || isUserOwnerSelector(state) || isUserAdminSelector(state);

export const userCompanyIdSelector: (state: RootState) => number | undefined = (state) =>
  state.userReducer.currentUser?.companyId;
