import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import type { Action } from 'redux-actions';
import { DialogStatisticsClient } from 'app/apis/api.js';
import * as DashboardActions from 'app/actions/dasboard/dashboardActions.js';
import type { AxiosResponse } from 'axios';
import type { PartialListDialogStatisticDto, SortType } from '@/generated-api/index.js';
import { EndReason } from '@/generated-api/index.js';

import { DialogProcessorSelector } from 'app/selectors/index.js';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
import type { IDProcessState } from 'app/models/dialogs/dialogProcess.js';
import type { DialogStatisticIntentWithUserVoice } from 'app/models/statistic/statistic.js';
import logger from 'app/utils/logger.js';

function* postDialogStatistics({
  payload: { intents, start, end }
}: Action<{ intents: DialogStatisticIntentWithUserVoice[]; start: string; end: string }>) {
  try {
    // if (!intents.length) return;

    yield put(ScenarioActions.setDetailedScenarioLoading(true));

    const dialogProcessorHistory: IDProcessState = yield select(DialogProcessorSelector.dialogProcessorSelector);

    yield call([DialogStatisticsClient, DialogStatisticsClient.dialogStatisticsAddDialogStatistic], {
      sessionId: dialogProcessorHistory.sessionId,
      endReason: EndReason.NUMBER_0 // Finished
    });
  } catch (error) {
    logger.error(error);
    yield put(ScenarioActions.setDetailedScenarioLoading(false));
  } finally {
    yield put(ScenarioActions.setDetailedScenarioLoading(false));
  }
}

function* getDialogStatistics({
  payload: { skip, take, order, orderBy, from, to, search }
}: Action<{ skip: number; take: number; order: SortType; orderBy: string; from: Date; to: Date; search: string }>) {
  try {
    yield put(DashboardActions.setStatisticsLoading(true));
    const response: AxiosResponse<PartialListDialogStatisticDto> = yield call(
      [DialogStatisticsClient, DialogStatisticsClient.dialogStatisticsListDialogStatisticsService],
      skip,
      take,
      order,
      orderBy,
      from?.toISOString(),
      to?.toISOString(),
      search
    );
    yield put(DashboardActions.putDialogStatistics(response.data));
  } catch (e) {
    logger.error('Cannot get dialog statistics');
  } finally {
    yield put(DashboardActions.setStatisticsLoading(false));
  }
}

export default function* watchAllDashboardsSaga() {
  yield all([
    yield takeEvery(DashboardActions.ActionType.GetDialogStatistics, getDialogStatistics),
    yield takeEvery(DashboardActions.ActionType.PostDialogStatistics, postDialogStatistics)
  ]);
}
