import { makeStyles } from '@material-ui/core/styles/index.js';

export default makeStyles({
  container: {
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  title: {
    marginBottom: 15
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
});
