import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppBar, Tabs, Tab, Button, Toolbar, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack.js';

import { ScenarioSelectors, ScenarioChartSelectors, RecognitionSelector } from '../../../selectors/index.js';

import * as ScenarioActions from '../../../actions/scenarios/scenarioAction.js';

import type { LinkedScenarioTBProps } from './types.js';

import { a11yProps } from './utils.js';
import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';

const LinkedScenarioTabBarComponent: React.FC<LinkedScenarioTBProps> = (props) => {
  const { scenarioId } = props;
  const dispatch = useDispatch();

  const scenarios = useSelector(ScenarioSelectors.scenariosSelector);
  const parentScenario = useSelector(ScenarioSelectors.parentScenario);
  const links = useSelector(ScenarioSelectors.linkedScenarios);
  const value = useSelector(ScenarioSelectors.currentTabSelected);
  const nodes = useSelector(ScenarioChartSelectors.chartNodes);
  const recognitionStatus = useSelector(RecognitionSelector.recognitionStatusSelector);

  const handleChange = (event: React.ChangeEvent<object>, newValue: number) => {
    if (!props.isDisabled) {
      dispatch(ScenarioActions.switchCurrentScenarioTo(newValue));
    }
  };

  const handleGoBack = () => {
    dispatch(ScenarioActions.goBackToParentScenario());
  };

  useEffect(() => {
    if (!!scenarioId && scenarios.length > 0) {
      dispatch(ScenarioActions.setLinks(scenarioId));
    }
  }, [dispatch, nodes, scenarioId, scenarios]);

  useEffect(() => {
    if (!!scenarioId && scenarios.length > 0) {
      dispatch(ScenarioActions.initToolBar(scenarioId));
    }
  }, [dispatch, scenarioId, scenarios]);

  return (
    <>
      <AppBar position="static">
        {((parentScenario && parentScenario?.length > 0) || links.length > 1) && (
          <Toolbar>
            {parentScenario && (
              <Button
                style={{ color: 'white' }}
                onClick={() => handleGoBack()}
                startIcon={<ArrowBackIcon />}
                disabled={recognitionStatus === VoiceRecognitionStatus.UserIsReallySpeaking}
              >
                <Typography style={{ fontSize: 12 }}>{parentScenario[parentScenario.length - 1].sceneName}</Typography>
              </Button>
            )}
            <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto">
              {links.map((x, i) => (
                <Tab
                  style={{ fontSize: 12 }}
                  key={i}
                  label={x.sceneName}
                  {...a11yProps(i)}
                  disabled={recognitionStatus === VoiceRecognitionStatus.UserIsReallySpeaking}
                />
              ))}
            </Tabs>
          </Toolbar>
        )}
      </AppBar>
    </>
  );
};

export default LinkedScenarioTabBarComponent;
