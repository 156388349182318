import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { readFileAsync } from '../../utils.js';
import RecordRTC from 'recordrtc';
import { getMediaStream, stopMediaStream } from './useMediaStream.js';
import { useDispatch, useSelector } from 'react-redux';
import { RecognitionSelector, ScenarioSelectors } from 'app/selectors/index.js';
import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';
import { VoiceRecognitionActions } from 'app/actions/voiceRecognition/voiceRecognitionActions.js';
import type { Harker } from '../../../../../../../../hark/hark.js';
import Hark from '../../../../../../../../hark/hark.js';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { SttProvider } from '@/generated-api/index.js';
import useAudioStore from 'app/zustand/audioStore.js';
import logger from 'app/utils/logger.js';

// const threshold = -70;
// const streamCheckInterval = 300;

export const useAudioRecorder = (manualDialog: boolean) => {
  // const [blob, setBlob] = useState<Blob | null>(null);
  const [stream, setStream] = useState<null | MediaStream>(null);
  const { threshold, interval } = useAudioStore((state) => ({
    threshold: state.threshold,
    interval: state.interval
  }));
  // const { stream, startStream, stopStream } = useMediaStream();
  const [encodedVoice, setEncodedVoice] = useState<string | null>(null);
  const { resetTranscript, listening } = useSpeechRecognition();

  const recognitionStatusSelector = useSelector(RecognitionSelector.recognitionStatusSelector);
  const currentChartScenario = useSelector(ScenarioSelectors.currentChartScenario);
  const isUsingInhouseStt = useMemo(() => currentChartScenario?.sttProvider === SttProvider.NUMBER_1, [
    currentChartScenario
  ]);

  const dispatch = useDispatch();

  const harkSpeechObsRef = useRef<Harker | null>(null);
  const isUsingInhouseSttRef = useRef(isUsingInhouseStt);
  isUsingInhouseSttRef.current = isUsingInhouseStt;

  // Why in a ref? It's a bool so the reference is lost on first update??
  const pausedGlobal = useSelector(RecognitionSelector.isPausedSelector);
  const isTrackPausedRef = useRef(pausedGlobal);
  isTrackPausedRef.current = pausedGlobal;

  const lastUsedStreamRef = useRef<MediaStream | null>(null);
  const lastUsedRecorderRef = useRef<RecordRTC | null>(null);

  const MIN_BLOB_SIZE = 1024 * 44;

  const getUserVoiceEncoded: (blob: Blob) => Promise<string | null> = useCallback(async (blob: Blob) => {
    const content = await readFileAsync(blob);

    if (typeof content === 'string') {
      return (content as string).replace(/^data:.+;base64,/, '');
    }

    return null;
  }, []);

  // This should handle everything after Hark sends the stopped_speaking and until audio (AI response) should play
  // const handleUserStoppedSpeaking = useCallback(async () => {
  //   logger.log('useAudioRecorder - handleStop was called');

  //   // Checking if audio (?) is paused or we already stopped recording
  //   const isStopped =
  //     isTrackPausedRef.current ||
  //     lastUsedRecorderRef.current == null ||
  //     lastUsedRecorderRef.current.getState() === 'stopped';

  //   if (isStopped) {
  //     logger.log('isStopped was true');
  //     return;
  //   }

  //   // Stop recording
  //   lastUsedRecorderRef.current.stopRecording(async () => {
  //     logger.log('is recognitionStatusSelector USS??: ' + recognitionStatusSelector);
  //     // Avoid repeat calls
  //     // TODO can probably remove!
  //     if (recognitionStatusSelector === VoiceRecognitionStatus.UserStoppedSpeaking) return;

  //     const newBlob = lastUsedRecorderRef.current.getBlob();

  //     // Get blob and check size
  //     logger.log('blob size: ' + newBlob?.size);
  //     if (newBlob && newBlob.size >= MIN_BLOB_SIZE) {
  //       logger.log('newBlock was ok and size was over min size');
  //       if (listening) {
  //         SpeechRecognition.stopListening();
  //       }

  //       // setBlob(newBlob);

  //       logger.log('Before getUserVoiceEncoded');
  //       const encoded = await getUserVoiceEncoded(newBlob);

  //       if (isUsingInhouseSttRef.current) {
  //         dispatch(VoiceRecognitionActions.parseLatestAudio({ audio: newBlob, speechBase64: encoded }));
  //       } else {
  //         logger.log('not using inhouse stt');
  //         // TODO we should be using the browsers stt!!!
  //         setEncodedVoice(encoded);
  //         dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.BeforeLoadingDPResponse));
  //       }
  //     } else {
  //       // Blob too small - reset recording (show user some feedback)
  //       logger.log('blob was too small?');
  //       lastUsedRecorderRef.current.reset();
  //       lastUsedRecorderRef.current.startRecording();

  //       if (listening) {
  //         resetTranscript();
  //         SpeechRecognition.stopListening();
  //       }
  //     }
  //   });
  // }, []);

  const handleStop = useCallback(async () => {
    // logger.log('useAudioRecorder - handleStop was called');
    const isStopped =
      isTrackPausedRef.current ||
      lastUsedRecorderRef.current == null ||
      lastUsedRecorderRef.current.getState() === 'stopped';

    if (isStopped) {
      // logger.log('isStopped was true');
      return;
    }

    lastUsedRecorderRef.current.stopRecording(async () => {
      // logger.log('is recognitionStatusSelector USS??: ' + recognitionStatusSelector);
      if (recognitionStatusSelector === VoiceRecognitionStatus.UserStoppedSpeaking) return;

      const newBlob = lastUsedRecorderRef.current.getBlob();

      // logger.log('blob size: ' + newBlob?.size);
      if (newBlob && newBlob.size >= MIN_BLOB_SIZE) {
        // logger.log('newBlock was ok and size was over min size');
        if (listening) {
          SpeechRecognition.stopListening();
        }

        // setBlob(newBlob);

        // logger.log('Before getUserVoiceEncoded');
        const encoded = await getUserVoiceEncoded(newBlob);

        if (isUsingInhouseSttRef.current) {
          dispatch(VoiceRecognitionActions.parseLatestAudio({ audio: newBlob, speechBase64: encoded }));
        } else {
          // logger.log('not using inhouse stt');
          // TODO we should be using the browsers stt!!!
          setEncodedVoice(encoded);
          dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.BeforeLoadingDPResponse));
        }
      } else {
        // logger.log('blob was too small?');
        lastUsedRecorderRef.current.reset();
        lastUsedRecorderRef.current.startRecording();

        if (listening) {
          resetTranscript();
          SpeechRecognition.stopListening();
        }
      }
    });
  }, [MIN_BLOB_SIZE, dispatch, getUserVoiceEncoded, listening, recognitionStatusSelector, resetTranscript]);

  useEffect(() => {
    if (!stream) {
      handleStop();
      return;
    }
  }, [stream, handleStop]);

  // useEffect(() => {
  //   if (!stream) {
  //     logger.log('Stopped because the stream did not exist any longer');
  //     handleStop();
  //     return;
  //   }
  const startStream = useCallback(async () => {
    let mediaStream = stream;
    if (!mediaStream) {
      mediaStream = await getMediaStream();
    }

    // logger.log('STARTING NEW STREAM: ', new Date().getTime());
    // const mediaStream = await getMediaStream();
    // logger.log('FINISHED GETTING NEW STREAM: ', new Date().getTime());
    // let currentRecorder: RecordRTC;
    let isNewSpeechObsRequired = true;
    if (lastUsedRecorderRef.current === null || lastUsedStreamRef.current !== mediaStream) {
      lastUsedRecorderRef.current?.destroy();
      lastUsedRecorderRef.current = new RecordRTC(mediaStream, {
        type: 'audio',
        mimeType: 'audio/wav',
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        disableLogs: false,
        checkForInactiveTracks: false,
        desiredSampRate: 22050,
        audioBitsPerSecond: 96000
      });

      lastUsedStreamRef.current = mediaStream;
    } else {
      isNewSpeechObsRequired = false;
    }

    // logger.log('FINISHED RECORDRTC INIT: ', new Date().getTime());

    if (isNewSpeechObsRequired && !manualDialog) {
      harkSpeechObsRef.current?.stop();

      const speechObserver = Hark(mediaStream, {
        play: false,
        threshold: threshold,
        interval: interval
      });

      logger.warn(`HARK - Started. Threshold: ${threshold}, Interval: ${interval}`, new Date().toISOString());

      // The speaking event is emitted when Hark first detects an interval with decibel level over threshold.
      speechObserver.on('speaking', () => {
        // logger.log('HARK STARTED SPEAKING', new Date().getTime());
        logger.warn('HARK - Started speaking', new Date().toISOString());
      });

      // The stopped_speaking event is emitted when Hark detects an interval with decibel level under threshold.
      // It is only emitted if the speaking event has been emitted first.
      // The timeout is used to prevent the recording from going on forever if
      // Hark doesn't emit the stopped_speaking event for whatever reason.
      speechObserver.on('stopped_speaking', () => {
        logger.warn('HARK - Stopped speaking', new Date().toISOString());
        handleStop();
      });

      // logger.log('FINISHED HARK INIT: ', new Date().getTime());
      harkSpeechObsRef.current = speechObserver;
    }
    setStream(mediaStream);
  }, [stream, manualDialog, threshold, interval, handleStop]);

  const stopStream = useCallback(async () => {
    stopMediaStream(stream);
    setStream(null);
    harkSpeechObsRef.current?.stop();
  }, [stream]);
  // useEffect(() => {
  //   if (!blob) return;

  //   logger.log('blob is set?');
  //   getUserVoiceEncoded(blob).then(setEncodedVoice);
  // }, [blob, getUserVoiceEncoded]);

  useEffect(() => {
    if (stream?.active) {
      switch (recognitionStatusSelector) {
        case VoiceRecognitionStatus.AudioIsPlaying:
          // logger.log('AIP in useAudioRecorders stream useEffect');
          harkSpeechObsRef.current?.suspend();
          break;
        case VoiceRecognitionStatus.UserIsReallySpeaking:
          // logger.log('RESUMED HARK: ', new Date().getTime());
          // logger.log('UIRS in useAudioRecorders stream useEffect');
          harkSpeechObsRef.current?.resume();
          lastUsedRecorderRef.current?.reset();
          lastUsedRecorderRef.current?.startRecording();
          break;
      }
    }
  }, [stream, recognitionStatusSelector]);

  return {
    stopStream,
    startStream,
    encodedVoice,
    setEncodedVoice,
    recorder: lastUsedRecorderRef.current,
    stopRecorder: handleStop
  };
};
