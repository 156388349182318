import React, { useContext, useEffect, useMemo, useState } from 'react';
import { GlobalAssetsLibraryContext } from 'app/components/GlobalAssetsLibrary/Context/GlobalAssetsLibraryContext.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { GlobalAssetsClient } from 'app/apis/api.js';
import type { GlobalAssetConnection } from '@/generated-api/index.js';
import { Box, Link, Typography } from '@material-ui/core';
import useStyles from '../../useStyles.js';
import AppRoutes from 'app/utils/routes.js';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { users as usersSelector } from 'app/selectors/userManagementSelectors.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

type State = {
  connections: GlobalAssetConnection[] | null;
  loading: boolean;
};

function TemplateConnections(): JSX.Element {
  const users = useSelector(usersSelector);
  const { selectedTemplate } = useContext(GlobalAssetsLibraryContext);
  const [{ connections, loading }, setState] = useState<State>({ connections: null, loading: true });
  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const createdBy = useMemo(() => {
    if (!selectedTemplate?.createdBy) {
      return null;
    }

    return users.find((u) => u.id === selectedTemplate?.createdBy)?.name;
  }, [users, selectedTemplate?.createdBy]);

  const unmountedRef = useUnmounted();

  useEffect(() => {
    if (!selectedTemplate?.id) {
      setState({
        connections: null,
        loading: false
      });

      return;
    }

    if (selectedTemplate?.id) {
      setState((s) => ({
        ...s,
        loading: true
      }));
    }

    const load = async () => {
      try {
        const { data } = await GlobalAssetsClient.globalAssetsGetTemplatesConnections(selectedTemplate?.id);

        if (!unmountedRef.current) {
          setState({
            connections: data,
            loading: false
          });
        }
      } catch (e) {
        logger.error(
          translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templateConnections.unableToLoadConnectionsFor)),
          selectedTemplate?.id
        );
      }
    };

    load();
  }, [selectedTemplate, unmountedRef]);

  const classes = useStyles();
  if (!selectedTemplate?.id) {
    return null;
  }

  return (
    <>
      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templateConnections.createdBy))}
      </Typography>
      <Typography component="span" className={classes.summaryItem}>
        {createdBy ?? translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templateConnections.admin))}
      </Typography>

      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templateConnections.createdDate))}
      </Typography>
      <Typography component="span" className={classes.summaryItem}>
        {new Date(selectedTemplate.createdDate).toLocaleString()}
      </Typography>

      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templateConnections.samplesCount))}
      </Typography>
      <Typography component="span" className={classes.summaryItem}>
        {selectedTemplate?.samples.length}
      </Typography>

      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templateConnections.connections))}
      </Typography>
      <Box className={clsx('text-truncate', classes.summaryItem)}>
        {!loading &&
          (connections?.length ? (
            connections.map((connection) => {
              return (
                <Link
                  key={connection.sceneId}
                  href={`${AppRoutes.Scene}/${connection.sceneId}`}
                  display={'block'}
                  variant={'body1'}
                  className="text-truncate"
                  target="_blank"
                  rel="noopener"
                >
                  {connection.name}
                </Link>
              );
            })
          ) : (
            <Typography>
              {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.templateConnections.noConnectionsFound))}
            </Typography>
          ))}
      </Box>
    </>
  );
}

export default TemplateConnections;
