import { isNil } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Box, Grid, Card, TextField, IconButton, Typography, CardContent, CardHeader } from '@material-ui/core';

import type { SceneContextPersonaFormValues } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/PersonaDialog.js';
import PersonaDialog from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/PersonaDialog.js';

import { useBoolState } from 'app/utils/customHooks/index.js';
import type { SceneContextDto, SceneContextPersonaDto } from '@/generated-api/index.js';
import type { ScenarioSettingsType } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';
import logger from 'app/utils/logger.js';
import { Add, Delete, Edit } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type SceneContextPersonasCardProps = {
  index: number;
  scenarioId?: number;
};

const SceneContextPersonasCard: React.FunctionComponent<SceneContextPersonasCardProps> = ({ index, scenarioId }) => {
  const contextsName = nameof.full<ScenarioSettingsType>((u) => u.contexts);
  const personasName = nameof.full<SceneContextDto>((u) => u.personas);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const name = `${contextsName}[${index}].${personasName}`;

  const [isOpen, setOpen, setClosed] = useBoolState();

  const { control, setValue } = useFormContext<ScenarioSettingsType>();

  const { fields, append, remove } = useFieldArray({
    name,
    control,
    keyName: 'key'
  });

  const personas: SceneContextPersonaDto[] = useWatch({
    control,
    name
  });

  const langCode: string = useWatch({ name: nameof.full<ScenarioSettingsType>((u) => u.langCode) });

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);

  const defaultPersonaId: number = useWatch({
    name: nameof.full<ScenarioSettingsType>((u) => u.defaultPersonaId)
  });

  const onSubmit = useCallback(
    (values: SceneContextPersonaFormValues) => {
      logger.log(values);
      const updatedValues: SceneContextPersonaDto = {
        ...values,
        gender: parseInt(values.gender, 10),
        type: parseInt(values.type, 10)
      };

      if (updatedValues.isDefault) {
        setValue('defaultPersonaId', updatedValues.id);

        setValue(
          // @ts-ignore // ???
          name,
          personas.map((persona) => ({
            ...persona,
            isDefault: false
          }))
        );
      } else {
        setValue('defaultPersonaId', null);
      }

      // creating new persona
      if (isNil(selectedIndex)) {
        append(updatedValues);
      }
      // editing existing persona
      else {
        // @ts-ignore // ???
        setValue(`${name}[${selectedIndex}]`, updatedValues);
      }

      setClosed();
    },
    [append, name, personas, selectedIndex, setClosed, setValue]
  );

  const onSelectPersona = (index: number) => {
    setSelectedIndex(index);
    setOpen();
  };

  const personaFormOnExited = useCallback(() => {
    setSelectedIndex(undefined);
  }, [setSelectedIndex]);

  const selectedPersona = personas[selectedIndex];

  return (
    <Card>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h5">
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.sceneContextPersonaCard.personas))}
            </Typography>
            <IconButton onClick={() => setOpen()}>
              <Add />
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        <Grid container>
          {!fields.length && (
            <Grid container justifyContent="center">
              <Typography>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.sceneContextPersonaCard.noPersonasAddedYet))}
              </Typography>
            </Grid>
          )}
          {fields.map((field, index) => {
            const prefix = `${name}[${index}]`;

            return (
              <SceneContextPersonaField
                field={field}
                index={index}
                key={field.key}
                prefix={prefix}
                remove={remove}
                control={control}
                onSelectPersona={onSelectPersona}
              />
            );
          })}
        </Grid>
        <PersonaDialog
          open={isOpen}
          onClose={setClosed}
          onSubmit={onSubmit}
          defaultValues={selectedPersona}
          onExited={personaFormOnExited}
          langCode={langCode}
          defaultPersonaId={defaultPersonaId}
          scenarioId={scenarioId}
        />
      </CardContent>
    </Card>
  );
};

const SceneContextPersonaField: React.FunctionComponent<any> = (props) => {
  const { field, prefix, control, onSelectPersona, remove, index } = props;

  const selectedRoleName: string = useWatch({
    control,
    name: `${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.roleName)}`
  });

  return (
    <Grid item xs={12} key={field.id} container wrap="nowrap">
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.isDefault)}`}
        control={control}
        defaultValue={field.isDefault}
        render={(field) => (
          <TextField
            {...field}
            disabled
            fullWidth
            margin="normal"
            value={field.value ? `${selectedRoleName} (default)` : selectedRoleName}
          />
        )}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.gender)}`}
        control={control}
        defaultValue={field.gender}
        render={() => <input type="hidden" />}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.roleName)}`}
        control={control}
        defaultValue={field.roleName}
        render={() => <input type="hidden" />}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.voiceName)}`}
        control={control}
        defaultValue={field.voiceName}
        render={() => <input type="hidden" />}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.ttsProvider)}`}
        control={control}
        defaultValue={field.ttsProvider}
        render={() => <input type="hidden" />}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.id)}`}
        control={control}
        defaultValue={field.id}
        render={() => <input type="hidden" />}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.age)}`}
        control={control}
        defaultValue={field.age}
        render={() => <input type="hidden" />}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.sceneContextPersonaRoleId)}`}
        control={control}
        defaultValue={field.sceneContextPersonaRoleId}
        render={() => <input type="hidden" />}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.type)}`}
        control={control}
        defaultValue={field.type}
        render={() => <input type="hidden" />}
      />
      <Controller
        name={`${prefix}.${nameof.full<SceneContextPersonaDto>((u) => u.isDefault)}`}
        control={control}
        defaultValue={field.isDefault}
        render={() => <input type="hidden" />}
      />
      <Grid item>
        <IconButton onClick={() => onSelectPersona(index)}>
          <Edit />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={() => remove(index)}>
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SceneContextPersonasCard;
