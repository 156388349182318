import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'app/store/types.js';
import type { CourseDto, ScheduledCourseDto } from '@/generated-api/index.js';

export type CourseManagementState = {
  courses: CourseDto[];
  scheduledCourses: ScheduledCourseDto[];
  loadingStatuses: { [key: string]: LoadingStatus };
  loadingStatus: LoadingStatus;
  loadingError: Error | null;
};

const initialState: CourseManagementState = {
  courses: [],
  scheduledCourses: [],
  loadingStatus: LoadingStatus.Idle,
  loadingError: null,
  loadingStatuses: {
    createCourse: LoadingStatus.Idle,
    updateCourse: LoadingStatus.Idle,
    deleteCourse: LoadingStatus.Idle,
    createCourseScene: LoadingStatus.Idle,
    deleteCourseScene: LoadingStatus.Idle,
    createScheduledCourse: LoadingStatus.Idle,
    deleteScheduledCourse: LoadingStatus.Idle,
    requestScheduledCourses: LoadingStatus.Idle,
    createScheduledCourseUser: LoadingStatus.Idle,
    deleteScheduledCourseUser: LoadingStatus.Idle,
    createScheduledCourseUserGroup: LoadingStatus.Idle,
    deleteScheduledCourseUserGroup: LoadingStatus.Idle
  }
};

export const courseManagementSlice = createSlice({
  name: 'courseManagement',
  initialState,
  reducers: {
    setCreateCourseLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.createCourse = action.payload;
    },
    setUpdateCourseLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.updateCourse = action.payload;
    },
    setDeleteCourseLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.deleteCourse = action.payload;
    },
    setCreateCourseSceneLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.createCourseScene = action.payload;
    },
    setDeleteCourseSceneLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.deleteCourseScene = action.payload;
    },
    setCreateScheduledCourseLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.createScheduledCourse = action.payload;
    },
    setDeleteScheduledCourseLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.createScheduledCourse = action.payload;
    },
    setCreateScheduledCourseUserLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.createScheduledCourseUser = action.payload;
    },
    setDeleteScheduledCourseUserLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.deleteScheduledCourseUser = action.payload;
    },
    setCreateScheduledCourseUserGroupLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.createScheduledCourseUserGroup = action.payload;
    },
    setDeleteScheduledCourseUserGroupLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.deleteScheduledCourseUserGroup = action.payload;
    },
    setRequestScheduledCoursesLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.loadingStatuses.requestScheduledCourses = action.payload;
    },
    loadStarted(state) {
      state.loadingStatus = LoadingStatus.Loading;
    },
    loadCoursesFinished: {
      prepare(payload: CourseDto[], error?: Error) {
        return { payload, error };
      },
      reducer(state, action: PayloadAction<CourseDto[], string, never, Error | undefined>) {
        if (action.error) {
          state.loadingError = action.error;
          state.loadingStatus = LoadingStatus.Failed;
        } else {
          state.loadingError = null;
          state.loadingStatus = LoadingStatus.Succeeded;
          state.courses = action.payload;
        }
      }
    },
    courseCreated(state, action: PayloadAction<CourseDto>) {
      state.courses.push(action.payload);
    },
    courseDeleted(state, action: PayloadAction<number>) {
      state.courses = state.courses.filter((c) => c.id !== action.payload);
    },
    courseUpdated(state, action: PayloadAction<CourseDto>) {
      const index = state.courses.findIndex((c) => c.id === action.payload.id);

      state.courses[index] = action.payload;
    },
    receiveScheduledCourses(state, action: PayloadAction<ScheduledCourseDto[]>) {
      state.scheduledCourses = action.payload;
    }
  }
});

export const {
  loadStarted,
  courseUpdated,
  courseCreated,
  courseDeleted,
  loadCoursesFinished,
  receiveScheduledCourses,
  setCreateCourseLoadingStatus,
  setUpdateCourseLoadingStatus,
  setDeleteCourseLoadingStatus,
  setCreateCourseSceneLoadingStatus,
  setDeleteCourseSceneLoadingStatus,
  setCreateScheduledCourseLoadingStatus,
  setDeleteScheduledCourseLoadingStatus,
  setRequestScheduledCoursesLoadingStatus,
  setCreateScheduledCourseUserLoadingStatus,
  setDeleteScheduledCourseUserLoadingStatus,
  setCreateScheduledCourseUserGroupLoadingStatus,
  setDeleteScheduledCourseUserGroupLoadingStatus
} = courseManagementSlice.actions;

export default courseManagementSlice.reducer;
