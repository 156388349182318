const enum VoiceRecognitionStatus {
  AudioIsPlaying = 'AudioIsPlaying',
  AudioWasEnded = 'AudioWasEnded',
  BeforeUserIsSpeaking = 'BeforeUserIsSpeaking',
  UserStoppedSpeaking = 'UserStoppedSpeaking',
  DPResponseWasLoaded = 'DPResponseWasLoaded',
  UserIsReallySpeaking = 'UserIsReallySpeaking',
  LoadingDPResponse = 'LoadingDPResponse',
  BeforeLoadingDPResponse = 'BeforeLoadingDPResponse'
}

export default VoiceRecognitionStatus;
