import { ScenarioClient } from 'app/apis/api.js';

export const getVariablesQuery = (scenarioId: number) => {
  return {
    queryKey: ['variables', scenarioId],
    queryFn: async () => {
      const response = await ScenarioClient.scenariosGetVariables(scenarioId);
      return response.data ?? [];
    },
    enabled: !!scenarioId
  };
};

export const getCompanyVariablesQuery = (scenarioId: number, disabled?: boolean) => {
  return {
    queryKey: ['companyVariables', scenarioId],
    queryFn: async () => {
      const response = await ScenarioClient.scenariosGetCompanyVariables(scenarioId);
      return response.data ?? [];
    },
    enabled: !disabled
  };
};
