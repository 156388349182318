import { useCallback, useEffect, useState } from 'react';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { MedicalIndexClient } from 'app/apis/api.js';
import { ProcessStatus } from 'app/types/UtilityType.js';
import type { MedicalProtocolDto } from '@/generated-api/index.js';
import { useDispatch } from 'react-redux';
import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import logger from 'app/utils/logger.js';

const { actions } = medicalIndexSlice;

function useDeleteMedIndex(onDeleted: () => void, medIndexId: MedicalProtocolDto['id']) {
  const [status, setStatus] = useState(ProcessStatus.Idle);
  const unmountedRef = useUnmounted();

  const dispatch = useDispatch();

  useEffect(() => {
    if (status !== ProcessStatus.StartProcess) {
      return;
    }

    setStatus(ProcessStatus.Processing);

    const submit = async () => {
      try {
        await MedicalIndexClient.medicalIndexDeleteMedicalIndex(medIndexId);

        dispatch(actions.indexRemoved(medIndexId));
        onDeleted();
      } catch (e) {
        logger.error(e);
      } finally {
        if (!unmountedRef.current) {
          setStatus(ProcessStatus.Idle);
        }
      }
    };

    submit();
  }, [medIndexId, dispatch, onDeleted, status, unmountedRef]);

  return [
    useCallback(() => {
      setStatus(ProcessStatus.StartProcess);
    }, []),
    status !== ProcessStatus.Idle
  ] as const;
}

export default useDeleteMedIndex;
