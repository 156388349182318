import type { ProcessDialogCommand, ProcessResultDto } from '@/generated-api/index.js';
import { DialogProcessorActions } from 'app/actions/dialogprocessor/dialogProcessorActions.js';
import { VoiceRecognitionActions } from 'app/actions/voiceRecognition/voiceRecognitionActions.js';
import { DProcessorProcessClient, SpeechClient } from 'app/apis/api.js';
import {
  DialogProcessorSelector,
  RecognitionSelector,
  ScenarioChartSelectors,
  ScenarioSelectors
} from 'app/selectors/index.js';
import type { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import type { Action } from 'redux-actions';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
import logger from 'app/utils/logger.js';
import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';

function* getAudioOfLastIntent(action: Action<{ audio: null | Blob; speechBase64?: string }>) {
  const speechBase64 = action.payload.speechBase64 ?? '';

  try {
    const language: string = yield select(ScenarioSelectors.currentChartScenarioLang);
    const data = new FormData();
    data.append('file', action.payload.audio as Blob);
    const result: AxiosResponse<string> = yield call(
      [SpeechClient, SpeechClient.speechRecognizeSpeech],
      language,
      action.payload.audio
    );

    const recognizedText = result.data?.trim();

    // TODO get this string from somewhere else? / different string (/null?)
    if (recognizedText === '##!!!ERROR_STRING!!!##') {
      // fallback to browser STT
      yield put(VoiceRecognitionActions.setUseFallbackStt(true));
      const fallbackString = yield select(RecognitionSelector.fallbackSttTextSelector);

      yield put(VoiceRecognitionActions.setLatestInhouseText(fallbackString));
      yield put(
        DialogProcessorActions.getResponseData({
          speechBase64,
          text: fallbackString
        })
      );
    } else {
      yield put(VoiceRecognitionActions.setLatestInhouseText(recognizedText));
      yield put(
        DialogProcessorActions.getResponseData({
          speechBase64,
          text: recognizedText
        })
      );
    }
  } catch (e) {
    logger.error(e);

    yield put(VoiceRecognitionActions.setLatestInhouseText(''));
    yield put(DialogProcessorActions.getResponseData({ text: '', speechBase64 }));
  }
}

export default function* watchSpeechToTextSaga() {
  yield all([yield takeLatest(VoiceRecognitionActions.Type.PARSE_LATEST_AUDIO, getAudioOfLastIntent)]);
}

const RECOGNIZE_SPEECH_ERROR = '##!!!ERROR_STRING!!!##';

// Replace multiple redux functions into one or several "react" functions
export const useSpeechToTextHelpers = () => {
  const dispatch = useDispatch();
  const currentChart = useSelector(ScenarioChartSelectors.scenarioChart);
  const scenarioLinks = useSelector(ScenarioSelectors.linkedScenarios);
  const currentChartScenario = useSelector(ScenarioSelectors.currentChartScenario);
  const lastResponse = useSelector(DialogProcessorSelector.dialogProcessorSelector);
  const language = useSelector(ScenarioSelectors.currentChartScenarioLang);
  const fallbackString = useSelector(RecognitionSelector.fallbackSttTextSelector);
  const useStreaming = useSelector(ScenarioSelectors.isStreamingEnabledForScenario);

  const getDPResponseNoRedux = useCallback(
    async (props: { text: string; speechBase64?: string }) => {
      const { text: currentMessage, speechBase64 } = props;
      // getResponseData -> getDPResponse in dialogProcessorSaga
      const lastBlock = !!lastResponse.blocks.currentBlock ? lastResponse.blocks.currentBlock : null;
      const intentProcessingProvider = currentChartScenario.intentProcessingProvider;

      try {
        const payload: ProcessDialogCommand = {
          sessionId: lastResponse.sessionId,
          text: currentMessage,
          sceneId: lastBlock?.sceneId ?? currentChart.properties.id,
          speech: speechBase64,
          intentProcessingProvider: intentProcessingProvider
        };

        const response: AxiosResponse<ProcessResultDto> = await DProcessorProcessClient.processProcessDialog(payload);

        // also used in VoiceRecognitionReducer
        if (!useStreaming) {
          dispatch(DialogProcessorActions.setResponseDataStore(response.data));
        } 
          
        if (response.data.lastBlock && response.data.lastBlock.sceneId != currentChart.properties.id) {
          dispatch(ScenarioActions.toNextLinkedScenario(true));
          dispatch(
            ScenarioActions.switchCurrentScenarioTo(
              scenarioLinks.findIndex((x) => x.sceneId == response.data.lastBlock.sceneId)
            )
          );
        }
      } catch (error) {
        logger.log('Error getDPResponse: ', error);
      } finally {
        dispatch(VoiceRecognitionActions.setUseFallbackStt(false));
      }
    },
    [
      currentChart.properties.id,
      currentChartScenario.intentProcessingProvider,
      dispatch,
      lastResponse.blocks.currentBlock,
      lastResponse.sessionId,
      scenarioLinks
    ]
  );

  const getAudioOfLastIntentNoRedux = useCallback(
    async ({ audio, speechBase64 = '' }: { audio: null | Blob; speechBase64?: string }) => {
      let text: string;
      try {
        const result = ((await SpeechClient.speechRecognizeSpeech(language, audio)) as unknown) as AxiosResponse<
          string
        >;

        const recognizedText = result.data?.trim();
        text = recognizedText;

        // TODO get this string from somewhere else? / different string (/null?)
        if (recognizedText === RECOGNIZE_SPEECH_ERROR) {
          // fallback to browser STT
          text = fallbackString;

          // TODO Replace with inline vars or react state if really needed
          dispatch(VoiceRecognitionActions.setUseFallbackStt(true));
        }
      } catch (e) {
        logger.error(e);
      }

      // yield put(DialogProcessorActions.getResponseData({ text, speechBase64 }));
      dispatch(VoiceRecognitionActions.setLatestInhouseText(text));
      getDPResponseNoRedux({ text, speechBase64 });
    },
    [dispatch, fallbackString, getDPResponseNoRedux, language]
  );

  return {
    getAudioOfLastIntentNoRedux,
    getDPResponseNoRedux
  };
};
