import React, { useMemo, useState } from 'react';
import { Box, Card, CardContent, Collapse, Grid, IconButton, Typography } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index.js';
import classNames from 'classnames';
import TransitionWeightsGrid from 'app/components/DashboardPage/TransitionWeights/TransitionWeightsGrid.js';
import { useSelector } from 'react-redux';
import { displayingDialogSelector } from 'app/selectors/dashboardSelectors.js';
import { scenariosSelector } from 'app/selectors/scenarioSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

const useStyles = makeStyles((theme) => {
  return {
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expand_open: {
      transform: 'rotate(180deg)'
    }
  };
});

function TransitionWeightsInfo(): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const dialog = useSelector(displayingDialogSelector);
  const scenarios = useSelector(scenariosSelector);

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const scenario = useMemo(() => {
    if (dialog?.id == null || !scenarios) {
      return null;
    }

    return scenarios.find((s) => s.id === dialog.sceneId);
  }, [scenarios, dialog]);

  const classes = useStyles();

  return (
    <Box hidden={!dialog}>
      <Card>
        <CardContent>
          <Box display={'flex'} alignItems={'center'}>
            <Box mr={2}>
              <IconButton
                className={classNames(classes.expand, { [classes.expand_open]: isOpen })}
                onClick={() => setIsOpen((o) => !o)}
              >
                <ExpandMore />
              </IconButton>
            </Box>
            <Typography variant={'h6'}>
              {translate(nameof.full<I18nDashboardNs>((n) => n.transitionWeightsInfo.intentsTransitionWeightsInfo))}
            </Typography>
          </Box>
          <Collapse in={isOpen}>
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <Box py={2}>
                  {!scenario && (
                    <Typography>
                      {translate(
                        nameof.full<I18nDashboardNs>((n) => n.transitionWeightsInfo.unableToFindCorrespondingScenario)
                      )}
                    </Typography>
                  )}
                  {scenario && <TransitionWeightsGrid statistic={dialog} />}
                </Box>
              </Grid>
            </Grid>
          </Collapse>
        </CardContent>
      </Card>
    </Box>
  );
}

export default TransitionWeightsInfo;
