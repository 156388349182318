import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import AppRoutes from 'app/utils/routes.js';
import { useParams } from 'react-router';
import { categoriesSelector } from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySelectors.js';
import { debounce } from 'lodash';
import { GlobalAssetFullDto } from '@/generated-api/index.js';
import { CurrentUserSelector } from 'app/selectors/userSelectors.js';
import { companiesSelector } from 'app/selectors/companiesSelector.js';
import { Image } from '@material-ui/icons';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';
import axios from 'axios';
import logger from 'app/utils/logger.js';
import { GlobalAssetsClient } from 'app/apis/api.js';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import MoveTemplateConfirmDialog from './MoveTemplateConfirmDialog.js';
import { GlobalAssetsLibraryContext } from './Context/GlobalAssetsLibraryContext.js';

export type CategoryListProps = {
  isShowingAll: boolean;
  language: string;
};

const ItemTypes = {
  TEMPLATE: 'template',
};

const useStyles = makeStyles((theme) => ({
  sharedCategory: {
    color: 'forestgreen',
  },
  highlightDroppable: {
    backgroundColor: 'lightgray',
    transition: 'background-color 0.3s ease', // Smooth transition
  },
  highlightNonDroppable: {
    backgroundColor: '',
    transition: 'background-color 0.3s ease', // Smooth transition
  },
}));

const CategoryListItem = ({ category, id, classes, isShowingAll, companies, translate, moveTemplateToCategory }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.TEMPLATE,
    canDrop: (item) => !!category.companyId,
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        moveTemplateToCategory(item, category);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const getHighlightClass = () => {
    if (!isOver) return '';
    return canDrop ? classes.highlightDroppable : classes.highlightNonDroppable;
  };

  return (
    <ListItem
      key={category.id}
      selected={id === String(category.id)}
      ref={drop}
      className={getHighlightClass()}
    >
      <ListItemAvatar>
        <Avatar variant={'square'}>
          <Image />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Link
            component={RouterLink}
            className={!!category.companyId ? '' : classes.sharedCategory}
            to={`${AppRoutes.GlobalAssetsLibrary}/${category.id}`}
          >
            {category.title +
              (isShowingAll
                ? ` (${
                    category.companyId
                      ? companies.find(company => company.id === category.companyId)?.name ??
                        translate(nameof.full<I18nGlobalAssetsNs>((n) => n.categoryList.deletedOrganization))
                      : translate(nameof.full<I18nGlobalAssetsNs>((n) => n.categoryList.shared))
                  })`
                : '')}
          </Link>
        }
      />
    </ListItem>
  );
};

function CategoryList({ language, isShowingAll = false }: CategoryListProps): JSX.Element {
  const classes = useStyles();
  const categories = useSelector(categoriesSelector);
  const currentUser = useSelector(CurrentUserSelector);
  const companies = useSelector(companiesSelector);

  const { searchText } = useContext(GlobalAssetsLibraryContext); // Access searchText from context

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [templateToMove, setTemplateToMove] = useState(null);
  const [targetCategory, setTargetCategory] = useState(null);

  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const samplesCountArray = useMemo(() => {
    return categories.map((c) => c.intents.map((t) => t.samples.length));
  }, [categories]);
  
  const filteredByAttributes = useMemo(() => {
    const filteredByLanguage = categories.filter((c) => c.language === language);
    return isShowingAll
      ? filteredByLanguage
      : filteredByLanguage.filter((c) => c.companyId === currentUser.companyId || !c.companyId);
  }, [language, categories, isShowingAll, currentUser, samplesCountArray.flat().join(",")]);

  const [sortedCategories, setSortedCategories] = useState([]);

  useEffect(() => {
    const sorted = [...filteredByAttributes].sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));
    setSortedCategories(sorted);
  }, [filteredByAttributes]);

  const { id } = useParams<{ id: string }>();
  const [filteredCategories, setFilteredCategories] = useState(filteredByAttributes);

  const debouncedFilter = useRef(
    debounce((searchText: string, categories: GlobalAssetFullDto[]) => {
      if (!searchText) {
        setFilteredCategories(categories ?? []);
        return;
      }

      const searchTextLowerCase = searchText.toString().toLocaleLowerCase();

      const filtered = categories.filter((c) => {
        return (
          c.title.toLocaleLowerCase().includes(searchTextLowerCase) ||
          c.intents.some(
            (t) =>
              t.title.toLocaleLowerCase().includes(searchTextLowerCase) ||
              t.samples.some((i) => i.text.toLocaleLowerCase().includes(searchTextLowerCase))
          )
        );
      });

      setFilteredCategories(filtered);
    }, 300)
  );

  useEffect(() => {
    const filter = debouncedFilter.current;
    filter(searchText, sortedCategories);

    return () => {
      filter.cancel(); // Cleanup function to cancel debounce when component unmounts
    };
  }, [searchText, sortedCategories]);

  if (!categories.length) {
    return (
      <Box py={3} display={'flex'} justifyContent={'center'} color={'action.disabled'}>
        <Typography>{translate(nameof.full<I18nGlobalAssetsNs>((n) => n.categoryList.categoryListIsEmpty))}</Typography>
      </Box>
    );
  }

  if (!filteredCategories.length) {
    return (
      <Box py={3} display={'flex'} justifyContent={'center'} color={'action.disabled'}>
        <Typography>{translate(nameof.full<I18nGlobalAssetsNs>((n) => n.categoryList.noEntriesFound))}</Typography>
      </Box>
    );
  }

  const dispatch = useDispatch();

  const handleConfirmMove = () => {
    if (!templateToMove || !targetCategory) return;

    moveTemplateToCategoryInternal(templateToMove, targetCategory);
    setConfirmationDialogOpen(false);
    setTemplateToMove(null);
    setTargetCategory(null);
  };

  const handleCancelMove = () => {
    setConfirmationDialogOpen(false);
    setTemplateToMove(null);
    setTargetCategory(null);
  };

  const moveTemplateToCategoryInternal = (template, category) => {
    const source = axios.CancelToken.source();

    GlobalAssetsClient.globalAssetsMoveAndMergeIntentToCategory(template.id, category.id, {
      cancelToken: source.token
    }).then(response => {
      dispatch(globalAssetsLibrarySlice.actions.templateMoved({ template, category }));
    }).catch((error) => {
      if (axios.isCancel(error)) {
        logger.log('Request canceled:', error.message);
      } else {
        logger.error('Error moving template:', error);
      }
    });

    return () => {
      source.cancel('Component unmounted');
    };
  };

  const moveTemplateToCategory = async (template, category) => {
    // Check if a template with the same name already exists in the target category
    const existingTemplate = category.intents.find((t) => t.title === template.title);
    if (existingTemplate) {
      setTemplateToMove(template);
      setTargetCategory(category);
      setConfirmationDialogOpen(true);
    } else {
      moveTemplateToCategoryInternal(template, category);
    }
  };

  return (
    <>
      <List>
        {filteredCategories.map(c => (
          <CategoryListItem
            key={c.id}
            category={c}
            id={id}
            classes={classes}
            isShowingAll={isShowingAll}
            companies={companies}
            translate={translate}
            moveTemplateToCategory={moveTemplateToCategory}
          />
        ))}
      </List>

      <MoveTemplateConfirmDialog
        isOpen={confirmationDialogOpen}
        onConfirm={handleConfirmMove}
        onCancel={handleCancelMove}
        templateTitle={templateToMove?.title}
        targetCategoryTitle={targetCategory?.title}
      />
    </>
  );
}

export default CategoryList;
