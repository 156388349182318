import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  makeStyles
} from '@material-ui/core';

import type { ArrayElementType } from 'app/types/UtilityType.js';
import BlockReachTimeSummaryTab from 'app/components/ScenarioEditorPage/ScenarioSummaryDialog/BlockReachTimeSummaryTab.js';
import MedicalIndexesSummaryTab from 'app/components/ScenarioEditorPage/ScenarioSummaryDialog/MedicalIndexesSummaryTab.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

export type ScenarioSummaryDialogProps = {
  open: boolean;
  onClose: () => void;
};

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: 600
  },
  tabsContainer: {
    flex: '1 1 0%',
    overflow: 'auto'
  }
});

const ScenarioSummaryDialog: React.FC<ScenarioSummaryDialogProps> = ({ open, onClose }) => {
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const tabs = [
    translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSummaryDialog.protocolReferences)),
    translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSummaryDialog.intentsReachTime))
  ] as const;
  type TabsType = ArrayElementType<typeof tabs>;
  const [activeTab, setActiveTab] = useState<TabsType>(tabs[0]);
  const classes = useStyles();

  return (
    <Dialog id={'scenario-summary-dialog'} maxWidth={'md'} fullWidth open={open} keepMounted onClose={onClose}>
      <DialogTitle>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSummaryDialog.summary))}</DialogTitle>

      <DialogContent className={classes.content}>
        <Tabs
          value={activeTab}
          onChange={(_event, value) => {
            setActiveTab(value);
          }}
        >
          <Tab label={tabs[0]} value={tabs[0]} />
          <Tab label={tabs[1]} value={tabs[1]} />
        </Tabs>

        <Box py={1} className={classes.tabsContainer}>
          <MedicalIndexesSummaryTab index={tabs[0]} value={activeTab} />
          <BlockReachTimeSummaryTab index={tabs[1]} value={activeTab} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" size="small" onClick={onClose}>
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSummaryDialog.close))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioSummaryDialog;
