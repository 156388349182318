import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Breadcrumbs } from '@material-ui/core';
import { ScenarioSelectors, ScenarioChartSelectors } from '../../../selectors/index.js';
import useStyles from './styles.js';

import type { LinkedScenario } from 'app/models/intents/intentNodeProperties.js';

const BreadCrumbs = (): JSX.Element => {
  const parentScenario = useSelector(ScenarioSelectors.parentScenario);
  const currentScenario = useSelector(ScenarioSelectors.currentScenario);
  const nodes = useSelector(ScenarioChartSelectors.chartNodes);

  const classes = useStyles();

  const currentScenarioRender = () => {
    return (
      <Typography classes={{ root: classes.root }} className={classes.redText}>
        {currentScenario?.sceneName}
      </Typography>
    );
  };

  const showCurrentScenario = useMemo(() => {
    const allLinks = Object.values(nodes)
      .filter((x: any) => x.properties.linkedScenarios != undefined)
      .map((x: any) => x.properties.linkedScenarios!);
    const accumulatedLinks = allLinks.reduce((accumulator, currentValue) => [...accumulator, ...currentValue], []);

    if (parentScenario?.length! > 0 || accumulatedLinks.length == 0) return currentScenarioRender();
    else return undefined;
  }, [parentScenario, nodes]);

  return (
    <Breadcrumbs maxItems={3} aria-label="breadcrumb">
      {parentScenario &&
        parentScenario.map((x: LinkedScenario, i: number) => {
          return (
            <Typography key={x.sceneId} classes={{ root: classes.root }}>
              {x.sceneName}
            </Typography>
          );
        })}

      {showCurrentScenario}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
