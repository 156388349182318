import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { CircularProgress, Tooltip, Grid, IconButton, MenuItem, Typography, TextField } from '@material-ui/core';

import ListAltIcon from '@material-ui/icons/ListAlt.js';

import DepartmentManager from './DepartmentManager/index.js';

import type { BaseTextFieldProps } from '@material-ui/core/TextField/index.js';

import { useToggleState } from '../../../../utils/customHooks/index.js';

import type { UserFormData } from '../types.js';
import type { UserDepartmentFieldProps } from './types.js';
import useStyles from './styles.js';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { CompanyDepartmentDto } from '@/generated-api/index.js';

type DepartmentSelectFieldClassKey = 'root' | 'circularProgressContainer' | 'fieldContainer';

// NOTE: `isForm` is supposed to remain the same throughout the component's lifetime.
type DepartmentSelectFieldProps = {
  classes?: Partial<Record<DepartmentSelectFieldClassKey, string>>;

  departments: CompanyDepartmentDto[];
  departmentsLoading: boolean;

  textFieldProps?: BaseTextFieldProps;
} & (
  | {
      isForm: true;
      control: Control;
      name: string;
    }
  | {
      isForm: false;
      value: string;
      onChange: (newVal: string) => void;
    }
);

const DepartmentSelectField: React.FC<DepartmentSelectFieldProps> = ({
  classes,
  departments,
  departmentsLoading,
  textFieldProps,
  ...conditionProps
}) => {
  const departmentsRendered = React.useMemo(() => {
    const deps = departments.map((c) => (
      <MenuItem key={c.id} value={c.id}>
        <Typography variant="inherit">{c.name}</Typography>
      </MenuItem>
    ));
    const noneItem = (
      <MenuItem key="no-department" value={null}>
        <Typography variant="inherit">None</Typography>
      </MenuItem>
    );

    return [noneItem, ...deps];
  }, [departments]);

  const commonTextFieldProps: BaseTextFieldProps = {
    className: classes?.fieldContainer ?? undefined,
    children: departmentsRendered,
    fullWidth: true,
    select: true,
    SelectProps: {
      MenuProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null,
        autoFocus: false
      }
    },
    ...textFieldProps
  };

  const field = conditionProps.isForm ? (
    <Controller as={TextField} control={conditionProps.control} name={conditionProps.name} {...commonTextFieldProps} />
  ) : (
    <TextField
      value={(conditionProps as any).value}
      onChange={(event) => (conditionProps as any).onChange(event.target.value)}
      {...(commonTextFieldProps as any)}
    />
  );

  return (
    <Grid item container xs className={classes?.root ?? undefined}>
      {departmentsLoading ? (
        <Grid
          className={classes?.circularProgressContainer ?? undefined}
          item
          container
          xs
          alignItems="center"
          justify="center"
        >
          <CircularProgress />
        </Grid>
      ) : (
        field
      )}
    </Grid>
  );
};

const UserDepartmentField: React.FC<UserDepartmentFieldProps> = ({
  departments,
  control,
  error,
  label,
  helperText,
  onAddDepartment,
  onEditDepartment,
  onDeleteDepartment
}) => {
  const departmentsLoading = false;
  const [departmentManagerOpen, toggleDepartmentManager] = useToggleState();

  const classes = useStyles();
  return (
    <Grid item container data-cy="departmentGrid">
      {departmentsLoading ? (
        <Grid item container xs alignItems="center" justify="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          <DepartmentSelectField
            isForm
            classes={{
              root: classNames({ [classes.displayNone]: departmentManagerOpen })
            }}
            departments={departments}
            departmentsLoading={departmentsLoading}
            control={control}
            name={nameof<UserFormData>((f) => f.departmentId)}
            textFieldProps={{
              label,
              error,
              helperText
            }}
          />
          <Grid item className={classNames({ [classes.displayNone]: departmentManagerOpen })}>
            <Tooltip title="Manage departments" placement="left">
              <IconButton onClick={toggleDepartmentManager}>
                <ListAltIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          {departmentManagerOpen && (
            <Grid item xs>
              <DepartmentManager
                onClose={toggleDepartmentManager}
                departments={departments}
                onAddDepartment={onAddDepartment}
                onEditDepartment={onEditDepartment}
                onDeleteDepartment={onDeleteDepartment}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default UserDepartmentField;
