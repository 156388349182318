import React from 'react';
import type { IPosition } from '@mrblenny/react-flow-chart';

import { toSvgPosition } from '../utils.js';

import type { CustomArrowHeadProps } from './types.js';

// no need to make this a React component
function CustomArrowHead({
  arrowHeight = 15,
  arrowWidth = 15,
  translateTo,
  rotateDeg,
  fill
}: CustomArrowHeadProps): JSX.Element {
  const arrowTip: IPosition = {
    x: arrowWidth,
    y: 0
  };

  const arrowLeftPoint: IPosition = {
    x: -arrowWidth / 4,
    y: arrowHeight / 2
  };

  const arrowRightPoint: IPosition = {
    x: -arrowWidth / 4,
    y: -arrowHeight / 2
  };

  return (
    <path
      transform={`translate(${translateTo.x} ${translateTo.y}) rotate(${rotateDeg})`}
      d={`M0,0 L${toSvgPosition(arrowRightPoint)} L${toSvgPosition(arrowTip)} L${toSvgPosition(arrowLeftPoint)} Z`}
      fill={fill}
    />
  );
}

export default CustomArrowHead;
