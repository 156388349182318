import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';

import {
  TextField,
  Typography,
  DialogActions,
  DialogContent,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  IconButton
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';

import CompanySelectField from '../../../utils/CompanySelectField/index.js';

import { CompaniesSelector, ScenarioSelectors } from '../../../../selectors/index.js';
import { CompanyManagementActions } from '../../../../actions/companyManagment/companyManagementActions.js';
import * as ScenarioActions from '../../../../actions/scenarios/scenarioAction.js';

import type { ScenarioCopyDialogFormData, ScenarioCopyDialogFormProps } from './types.js';
import useStyles from './styles.js';
import { getValidationSchema } from './validation.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import { yupResolver } from '@hookform/resolvers/yup';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nCoreNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const Languages = {
  Danish: 'da-DK',
  'English (UK)': 'en-GB',
  'English (US)': 'en-US',
  'English (Australia)': 'en-AU',
  German: 'de-DE',
  Icelandic: 'is-IS',
  Norwegian: 'nb-NO',
  'Spanish (Mexico)': 'es-MX',
  'Spanish (Spain)': 'es-ES',
  'Spanish (US)': 'es-US',
  Swedish: 'sv-SE',
  'Arabic (UAE)': 'ar-AE'
};

const ScenarioCopyDialogForm: React.FC<ScenarioCopyDialogFormProps> = ({ scenarioId, onCancel }) => {
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const [translateCore] = useTranslation([I18nNamespace.Core]);

  const scenarioToCopy = useSelector(ScenarioSelectors.getScenarioById(scenarioId));
  const companies = useSelector(CompaniesSelector.companiesSelector);
  const companiesLoading = useSelector(CompaniesSelector.companiesAreLoadingSelector);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);

  const { handleSubmit, errors, control } = useForm<ScenarioCopyDialogFormData>({
    resolver: yupResolver(getValidationSchema(isSuperAdmin, !scenarioToCopy?.companyId)),
    defaultValues: {
      newName:
        scenarioToCopy?.name !== undefined
          ? scenarioToCopy?.name +
            ' - ' +
            translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialogForm.copy))
          : '',
      companyId: isSuperAdmin ? scenarioToCopy?.companyId : undefined,
      targetLanguage: scenarioToCopy?.langCode ?? 'en-US'
    }
  });

  React.useEffect(() => {
    if (isSuperAdmin && companies.length === 0) {
      dispatch(CompanyManagementActions.getCompanies());
    }
  }, []);

  const dispatch = useDispatch();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCopy = React.useCallback(
    async (values: ScenarioCopyDialogFormData) => {
      dispatch(ScenarioActions.copyScenario({ ...values, id: scenarioId }));
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
        onCancel();
      }, 5000);
    },
    [dispatch, scenarioId, onCancel]
  );

  const classes = useStyles();
  const [vt] = useTranslation(I18nNamespace.Validation);
  return (
    <>
      <DialogContent>
        <Controller
          className={classes.spacing}
          as={TextField}
          name={nameof<ScenarioCopyDialogFormData>((f) => f.newName)}
          control={control}
          label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialogForm.nameOfCopiedScenario))}
          fullWidth
          error={!!errors.newName}
          helperText={errors.newName && vt(errors.newName?.message as string)}
        />
        {isSuperAdmin && !!scenarioToCopy.companyId ? (
          <CompanySelectField
            classes={{
              circularProgressContainer: classes.spacing,
              fieldContainer: classes.spacing
            }}
            companies={companies}
            companiesLoading={companiesLoading}
            isForm
            control={control}
            name={nameof<ScenarioCopyDialogFormData>((f) => f.companyId)}
            textFieldProps={{
              label: translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialogForm.copyToOrganization)),
              error: !!errors.companyId,
              helperText: errors.companyId && vt(errors.companyId?.message as string)
            }}
          />
        ) : null}
        <FormControl className={classes.spacing} fullWidth>
          <InputLabel id="target-language-label">
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialogForm.targetLanguageTranslate))}
          </InputLabel>
          <Controller
            as={
              <Select labelId="target-language-label">
                {/* {Object.entries(Languages).map(([label, value]) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))} */}
                <MenuItem key={Languages['Norwegian']} data-cy="Norwegian" value={Languages['Norwegian']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.norwegian))}
                </MenuItem>
                <MenuItem key={Languages['English (US)']} data-cy="English (US)" value={Languages['English (US)']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishus))}
                </MenuItem>
                <MenuItem key={Languages['English (UK)']} data-cy="English (UK)" value={Languages['English (UK)']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishuk))}
                </MenuItem>
                <MenuItem
                  key={Languages['English (Australia)']}
                  data-cy="English (Australia)"
                  value={Languages['English (Australia)']}
                >
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishAustralia))}
                </MenuItem>
                <MenuItem key={Languages['German']} data-cy="German" value={Languages['German']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.german))}
                </MenuItem>
                <MenuItem key={Languages['Danish']} data-cy="Danish" value={Languages['Danish']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.danish))}
                </MenuItem>
                <MenuItem key={Languages['Swedish']} data-cy="Swedish" value={Languages['Swedish']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.swedish))}
                </MenuItem>
                <MenuItem key={Languages['Icelandic']} data-cy="Icelandic" value={Languages['Icelandic']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.icelandic))}
                </MenuItem>
                <MenuItem
                  key={Languages['Spanish (Spain)']}
                  data-cy="Spanish (Spain)"
                  value={Languages['Spanish (Spain)']}
                >
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishSpain))}
                </MenuItem>
                <MenuItem key={Languages['Spanish (US)']} data-cy="Spanish (US)" value={Languages['Spanish (US)']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishUs))}
                </MenuItem>
                <MenuItem
                  key={Languages['Spanish (Mexico)']}
                  data-cy="Spanish (Mexico)"
                  value={Languages['Spanish (Mexico)']}
                >
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishMexico))}
                </MenuItem>
                <MenuItem key={Languages['Arabic (UAE)']} data-cy="Arabic (UAE)" value={Languages['Arabic (UAE)']}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.arabicUae))}
                </MenuItem>
              </Select>
            }
            name={nameof<ScenarioCopyDialogFormData>((f) => f.targetLanguage)}
            control={control}
            label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialogForm.targetLanguage))}
            fullWidth
          />
        </FormControl>
        <Typography className={classes.spacing}>
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialogForm.unsavedChanges))}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="small" variant="outlined" color="primary" onClick={onCancel}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          disabled={!!Object.keys(errors).length}
          onClick={handleSubmit(handleCopy, (e) => logger.log(e))}
        >
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialogForm.copy))}
        </Button>
      </DialogActions>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="info">
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioCopyDialogForm.copyingScenario))}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ScenarioCopyDialogForm;
