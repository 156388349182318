import type { I18nCommonNs } from './interfaces.js';

export const CommonDictionaryEn: I18nCommonNs = {
  signOut: 'Sign out',
  language: 'Language',
  memberGuides: 'Member guides',
  adminGuides: 'Admin guides',
  audioSettings: {
    audioSettings: 'Audio settings',
    microphone: 'Microphone',
    testMicrophone: 'Test microphone',
    inputLevel: 'Input level',
    audioThreshold: 'Audio threshold',
    audioInterval: 'Audio interval',
    audioTimeout: 'Audio timeout',
    disableTimeout: 'Disable timeout',
    disable: 'Disable',
    onlyDefaultSupported: 'Only the default microphone is supported. Select a different microphone in the OS',
    systemSettings: 'system settings.',
    thresholdTooltip:
      'The threshold for when we should detect speech, where -100dB is silence and 0dB is the maximum volume. Set to a lower number (closer to -100) if you need to speak loud for it to detect speech, or higher (closer to 0) when speech is detected all the time (like background noise).',
    intervalTooltip:
      "The interval at which we should detect if speaking has started or stopping. Higher number allows for more 'speaking breaks', but adds a longer delay after speaking.",
    timeoutTooltip:
      'The timeout (ms) for when we should stop speech recognition after text has been detected from the speech. Resets if new speech is detected inside the interval.',
    disableTooltip: 'Disable the timeout function. Useful if you have a good microphone and/or silent environment.',
    noMicrophoneFound: 'No microphones found or premissions are not granted.'
  },
  languageSettings: {
    selectLanguage: 'Select language',
    explanationText:
      'automatically detects your browser language. Select one of the supported langauges to override it.'
  },
  buttonLabels: {
    ok: 'OK',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    submit: 'Submit',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    add: 'Add',
    remove: 'Remove',
    viewDetails: 'View details',
    reset: 'Reset',
    rename: 'Rename',
    close: 'Close'
  },
  navigation: {
    dashboard: 'Dashboard',
    scenarios: 'Scenarios',
    userManagement: 'User Management',
    groupManagement: 'Group Management',
    courseManagement: 'Course Management',
    myCourses: 'My Courses',
    globalAssetsLibrary: 'Global Assets Library',
    protocolReferences: 'Protocol References',
    scheduledCalls: 'Scheduled Calls',
    userGuides: 'User Guides'
  },
  userRoles: {
    0: 'Member',
    1: 'Admin',
    2: 'Owner',
    3: 'SuperAdmin'
  }
};

export const CommonDictionaryNo: I18nCommonNs = {
  signOut: 'Logg ut',
  language: 'Språk',
  memberGuides: 'Guider for Medlem',
  adminGuides: 'Guider for Administrator',
  audioSettings: {
    audioSettings: 'Lydinnstillinger',
    microphone: 'Mikrofon',
    testMicrophone: 'Test mikrofon',
    inputLevel: 'Lydnivå',
    audioThreshold: 'Terskelverdi',
    audioInterval: 'Lydintervall',
    audioTimeout: 'Timeout-verdi',
    disableTimeout: 'Deaktiver timeout',
    disable: 'Deaktiver',
    onlyDefaultSupported: 'Bare standardmikrofon er støttet. Velg en annen mikrofon i operativsystemets ',
    systemSettings: 'systeminnstillinger.',
    thresholdTooltip:
      'Terskelverdien for når systemet skal detektere tale, hvor -100dB er stillhet og 0dB er maksvolum. Sett denne til et lavere tall (nærmere -100) hvis du må snakke høyt for at det skal bli gjenkjent, eller en høyere terskelverdi (nærmere 0) hvis opptaket ikke stopper når du slutter å snakke (f.eks. på grunn av bakgrunnsstøy).',
    intervalTooltip:
      'Tidsintervallet for hvor ofte systemet sjekker om du har startet eller stoppet å snakke. Et høyere tall gjør at du kan ta flere snakkepauser, men det gir også ekstra latenstid når du slutter å snakke.',
    timeoutTooltip:
      'Timeout-verdien (ms) for når talegjenkjenningen skal stoppe etter at tekst har blitt detektert. Verdien blir nullstilt hvis det blir detektert mer tale innenfor intervallet. Denne vil overstyre og stoppe mikrofonen hvis opptaket aldri stopper pga. bakgrunnsstøy.',
    disableTooltip: 'Deaktiver timeout-funksjonen.',
    noMicrophoneFound: 'Ingen mikrofon tilgjengelig eller du har ikke gitt mikrofontilgang.'
  },
  languageSettings: {
    selectLanguage: 'Velg språk',
    explanationText: 'detekterer automatisk språket i nettleseren din. Velg et av de støttede språkene for å overstyre.'
  },
  buttonLabels: {
    ok: 'OK',
    cancel: 'Avbryt',
    confirm: 'Bekreft',
    save: 'Lagre',
    submit: 'Lagre',
    edit: 'Rediger',
    update: 'Oppdater',
    delete: 'Slett',
    add: 'Legg til',
    remove: 'Fjern',
    viewDetails: 'Se detaljer',
    reset: 'Nullstill',
    rename: 'Gi nytt navn',
    close: 'Lukk'
  },
  navigation: {
    dashboard: 'Dashbord',
    scenarios: 'Scenarier',
    userManagement: 'Brukere',
    groupManagement: 'Grupper',
    courseManagement: 'Kurs',
    myCourses: 'Mine kurs',
    globalAssetsLibrary: 'Globalt innhold',
    protocolReferences: 'Indeksreferanser',
    scheduledCalls: 'Telefon',
    userGuides: 'Hjelpevideoer'
  },
  userRoles: {
    0: 'Medlem',
    1: 'Administrator',
    2: 'Eier',
    3: 'SuperAdmin'
  }
};

export const CommonDictionaryDe: I18nCommonNs = {
  signOut: 'Abmelden',
  language: 'Sprache',
  memberGuides: 'Mitgliederanleitungen',
  adminGuides: 'Admin-Anleitungen',
  audioSettings: {
    audioSettings: 'Audioeinstellungen',
    microphone: 'Mikrofon',
    testMicrophone: 'Mikrofon testen',
    inputLevel: 'Eingangspegel',
    audioThreshold: 'Audioschwelle',
    audioInterval: 'Audiointerval',
    audioTimeout: 'Audio-Timeout',
    disableTimeout: 'Timeout deaktivieren',
    disable: 'Deaktivieren',
    onlyDefaultSupported: 'Nur das Standardmikrofon wird unterstützt. Wählen Sie in den Betriebssystemeinstellungen ein anderes Mikrofon aus.',
    systemSettings: 'Systemeinstellungen.',
    thresholdTooltip:
      'Die Schwelle, ab der wir Sprache erkennen sollten, wobei -100 dB Stille und 0 dB die maximale Lautstärke ist. Stellen Sie einen niedrigeren Wert ein (näher an -100), wenn Sie laut sprechen müssen, damit Sprache erkannt wird, oder einen höheren Wert (näher an 0), wenn ständig Sprache erkannt wird (wie Hintergrundgeräusche).',
    intervalTooltip:
      'Das Intervall, in dem wir erkennen sollten, ob das Sprechen begonnen oder gestoppt hat. Eine höhere Zahl ermöglicht mehr "Sprechpausen", fügt aber eine längere Verzögerung nach dem Sprechen hinzu.',
    timeoutTooltip:
      'Das Timeout (ms), nach dem wir die Spracherkennung stoppen sollten, nachdem Text aus der Sprache erkannt wurde. Wird zurückgesetzt, wenn innerhalb des Intervalls neue Sprache erkannt wird.',
    disableTooltip: 'Deaktivieren Sie die Timeout-Funktion. Nützlich, wenn Sie ein gutes Mikrofon und/oder eine ruhige Umgebung haben.',
    noMicrophoneFound: 'Keine Mikrofone gefunden oder Berechtigungen sind nicht erteilt.'
  },
  languageSettings: {
    selectLanguage: 'Sprache auswählen',
    explanationText:
      'Erkennt automatisch die Sprache Ihres Browsers. Wählen Sie eine der unterstützten Sprachen aus, um sie zu überschreiben.'
  },
  buttonLabels: {
    ok: 'OK',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    save: 'Speichern',
    submit: 'Absenden',
    edit: 'Bearbeiten',
    update: 'Aktualisieren',
    delete: 'Löschen',
    add: 'Hinzufügen',
    remove: 'Entfernen',
    viewDetails: 'Details anzeigen',
    reset: 'Zurücksetzen',
    rename: 'Umbenennen',
    close: 'Schließen'
  },
  navigation: {
    dashboard: 'Dashboard',
    scenarios: 'Szenarien',
    userManagement: 'Benutzerverwaltung',
    groupManagement: 'Gruppenverwaltung',
    courseManagement: 'Kursverwaltung',
    myCourses: 'Meine Kurse',
    globalAssetsLibrary: 'Globale Asset-Bibliothek',
    protocolReferences: 'Protokollreferenzen',
    scheduledCalls: 'Geplante Anrufe',
    userGuides: 'Benutzeranleitungen'
  },
  userRoles: {
    0: 'Mitglied',
    1: 'Admin',
    2: 'Besitzer',
    3: 'SuperAdmin'
  }
};

export const CommonDictionarySw: I18nCommonNs = {
  signOut: 'Sign out',
  language: 'Language',
  memberGuides: 'Member guides',
  adminGuides: 'Admin guides',
  audioSettings: {
    audioSettings: 'Audio settings',
    microphone: 'Microphone',
    testMicrophone: 'Test microphone',
    inputLevel: 'Input level',
    audioThreshold: 'Audio threshold',
    audioInterval: 'Audio interval',
    audioTimeout: 'Audio timeout',
    disableTimeout: 'Disable timeout',
    disable: 'Disable',
    onlyDefaultSupported: 'Only the default microphone is supported. Select a different microphone in the OS',
    systemSettings: 'system settings.',
    thresholdTooltip:
      'The threshold for when we should detect speech, where -100dB is silence and 0dB is the maximum volume. Set to a lower number (closer to -100) if you need to speak loud for it to detect speech, or higher (closer to 0) when speech is detected all the time (like background noise).',
    intervalTooltip:
      "The interval at which we should detect if speaking has started or stopping. Higher number allows for more 'speaking breaks', but adds a longer delay after speaking.",
    timeoutTooltip:
      'The timeout (ms) for when we should stop speech recognition after text has been detected from the speech. Resets if new speech is detected inside the interval.',
    disableTooltip: 'Disable the timeout function. Useful if you have a good microphone and/or silent environment.',
    noMicrophoneFound: 'No microphones found or premissions are not granted.'
  },
  languageSettings: {
    selectLanguage: 'Select language',
    explanationText:
      'automatically detects your browser language. Select one of the supported langauges to override it.'
  },
  buttonLabels: {
    ok: 'OK',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    submit: 'Submit',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    add: 'Add',
    remove: 'Remove',
    viewDetails: 'View details',
    reset: 'Reset',
    rename: 'Rename',
    close: 'Close'
  },
  navigation: {
    dashboard: 'Dashboard',
    scenarios: 'Scenarios',
    userManagement: 'User Management',
    groupManagement: 'Group Management',
    courseManagement: 'Course Management',
    myCourses: 'My Courses',
    globalAssetsLibrary: 'Global Assets Library',
    protocolReferences: 'Protocol References',
    scheduledCalls: 'Scheduled Calls',
    userGuides: 'User Guides'
  },
  userRoles: {
    0: 'Member',
    1: 'Admin',
    2: 'Owner',
    3: 'SuperAdmin'
  }
};

export const CommonDictionaryNl: I18nCommonNs = {
  signOut: 'Sign out',
  language: 'Language',
  memberGuides: 'Member guides',
  adminGuides: 'Admin guides',
  audioSettings: {
    audioSettings: 'Audio settings',
    microphone: 'Microphone',
    testMicrophone: 'Test microphone',
    inputLevel: 'Input level',
    audioThreshold: 'Audio threshold',
    audioInterval: 'Audio interval',
    audioTimeout: 'Audio timeout',
    disableTimeout: 'Disable timeout',
    disable: 'Disable',
    onlyDefaultSupported: 'Only the default microphone is supported. Select a different microphone in the OS',
    systemSettings: 'system settings.',
    thresholdTooltip:
      'The threshold for when we should detect speech, where -100dB is silence and 0dB is the maximum volume. Set to a lower number (closer to -100) if you need to speak loud for it to detect speech, or higher (closer to 0) when speech is detected all the time (like background noise).',
    intervalTooltip:
      "The interval at which we should detect if speaking has started or stopping. Higher number allows for more 'speaking breaks', but adds a longer delay after speaking.",
    timeoutTooltip:
      'The timeout (ms) for when we should stop speech recognition after text has been detected from the speech. Resets if new speech is detected inside the interval.',
    disableTooltip: 'Disable the timeout function. Useful if you have a good microphone and/or silent environment.',
    noMicrophoneFound: 'No microphones found or premissions are not granted.'
  },
  languageSettings: {
    selectLanguage: 'Select language',
    explanationText:
      'automatically detects your browser language. Select one of the supported langauges to override it.'
  },
  buttonLabels: {
    ok: 'OK',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    submit: 'Submit',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    add: 'Add',
    remove: 'Remove',
    viewDetails: 'View details',
    reset: 'Reset',
    rename: 'Rename',
    close: 'Close'
  },
  navigation: {
    dashboard: 'Dashboard',
    scenarios: 'Scenarios',
    userManagement: 'User Management',
    groupManagement: 'Group Management',
    courseManagement: 'Course Management',
    myCourses: 'My Courses',
    globalAssetsLibrary: 'Global Assets Library',
    protocolReferences: 'Protocol References',
    scheduledCalls: 'Scheduled Calls',
    userGuides: 'User Guides'
  },
  userRoles: {
    0: 'Member',
    1: 'Admin',
    2: 'Owner',
    3: 'SuperAdmin'
  }
};

export const CommonDictionaryIs: I18nCommonNs = {
  signOut: 'Sign out',
  language: 'Language',
  memberGuides: 'Member guides',
  adminGuides: 'Admin guides',
  audioSettings: {
    audioSettings: 'Audio settings',
    microphone: 'Microphone',
    testMicrophone: 'Test microphone',
    inputLevel: 'Input level',
    audioThreshold: 'Audio threshold',
    audioInterval: 'Audio interval',
    audioTimeout: 'Audio timeout',
    disableTimeout: 'Disable timeout',
    disable: 'Disable',
    onlyDefaultSupported: 'Only the default microphone is supported. Select a different microphone in the OS',
    systemSettings: 'system settings.',
    thresholdTooltip:
      'The threshold for when we should detect speech, where -100dB is silence and 0dB is the maximum volume. Set to a lower number (closer to -100) if you need to speak loud for it to detect speech, or higher (closer to 0) when speech is detected all the time (like background noise).',
    intervalTooltip:
      "The interval at which we should detect if speaking has started or stopping. Higher number allows for more 'speaking breaks', but adds a longer delay after speaking.",
    timeoutTooltip:
      'The timeout (ms) for when we should stop speech recognition after text has been detected from the speech. Resets if new speech is detected inside the interval.',
    disableTooltip: 'Disable the timeout function. Useful if you have a good microphone and/or silent environment.',
    noMicrophoneFound: 'No microphones found or premissions are not granted.'
  },
  languageSettings: {
    selectLanguage: 'Select language',
    explanationText:
      'automatically detects your browser language. Select one of the supported langauges to override it.'
  },
  buttonLabels: {
    ok: 'OK',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    submit: 'Submit',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    add: 'Add',
    remove: 'Remove',
    viewDetails: 'View details',
    reset: 'Reset',
    rename: 'Rename',
    close: 'Close'
  },
  navigation: {
    dashboard: 'Dashboard',
    scenarios: 'Scenarios',
    userManagement: 'User Management',
    groupManagement: 'Group Management',
    courseManagement: 'Course Management',
    myCourses: 'My Courses',
    globalAssetsLibrary: 'Global Assets Library',
    protocolReferences: 'Protocol References',
    scheduledCalls: 'Scheduled Calls',
    userGuides: 'User Guides'
  },
  userRoles: {
    0: 'Member',
    1: 'Admin',
    2: 'Owner',
    3: 'SuperAdmin'
  }
};

export const CommonDictionaryUk: I18nCommonNs = {
  signOut: 'Sign out',
  language: 'Language',
  memberGuides: 'Member guides',
  adminGuides: 'Admin guides',
  audioSettings: {
    audioSettings: 'Audio settings',
    microphone: 'Microphone',
    testMicrophone: 'Test microphone',
    inputLevel: 'Input level',
    audioThreshold: 'Audio threshold',
    audioInterval: 'Audio interval',
    audioTimeout: 'Audio timeout',
    disableTimeout: 'Disable timeout',
    disable: 'Disable',
    onlyDefaultSupported: 'Only the default microphone is supported. Select a different microphone in the OS',
    systemSettings: 'system settings.',
    thresholdTooltip:
      'The threshold for when we should detect speech, where -100dB is silence and 0dB is the maximum volume. Set to a lower number (closer to -100) if you need to speak loud for it to detect speech, or higher (closer to 0) when speech is detected all the time (like background noise).',
    intervalTooltip:
      "The interval at which we should detect if speaking has started or stopping. Higher number allows for more 'speaking breaks', but adds a longer delay after speaking.",
    timeoutTooltip:
      'The timeout (ms) for when we should stop speech recognition after text has been detected from the speech. Resets if new speech is detected inside the interval.',
    disableTooltip: 'Disable the timeout function. Useful if you have a good microphone and/or silent environment.',
    noMicrophoneFound: 'No microphones found or premissions are not granted.'
  },
  languageSettings: {
    selectLanguage: 'Select language',
    explanationText:
      'automatically detects your browser language. Select one of the supported langauges to override it.'
  },
  buttonLabels: {
    ok: 'OK',
    cancel: 'Cancel',
    confirm: 'Confirm',
    save: 'Save',
    submit: 'Submit',
    edit: 'Edit',
    update: 'Update',
    delete: 'Delete',
    add: 'Add',
    remove: 'Remove',
    viewDetails: 'View details',
    reset: 'Reset',
    rename: 'Rename',
    close: 'Close'
  },
  navigation: {
    dashboard: 'Dashboard',
    scenarios: 'Scenarios',
    userManagement: 'User Management',
    groupManagement: 'Group Management',
    courseManagement: 'Course Management',
    myCourses: 'My Courses',
    globalAssetsLibrary: 'Global Assets Library',
    protocolReferences: 'Protocol References',
    scheduledCalls: 'Scheduled Calls',
    userGuides: 'User Guides'
  },
  userRoles: {
    0: 'Member',
    1: 'Admin',
    2: 'Owner',
    3: 'SuperAdmin'
  }
};
