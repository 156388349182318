import type { AiVoiceResponseDto, VariableDto } from '@/generated-api/index.js';
import { Box, IconButton, ListItem, ListItemIcon } from '@material-ui/core';
import React from 'react';
import EmotionSwitcher from './EmotionSwitcher/EmotionSwitcher.js';
import VariableSuggestionInput from 'app/components/ScenarioEditorPage/Variables/VariableSuggestionInput.js';
import { Add, Delete } from '@material-ui/icons';

type ResponseListItemProps = {
  index: number;
  response: AiVoiceResponseDto;
  isDeleteButtonDisplayed: boolean;
  onChange: <T>(propName: string, value: T, index: number) => void;
  onDelete: (index: number) => void;
  onAdd: (index: number) => void;
  disabled?: boolean;
  variables: VariableDto[];
};

const ResponseListItem = (props: ResponseListItemProps) => {
  const { index, response, isDeleteButtonDisplayed, onChange, onDelete, onAdd, disabled, variables } = props;

  const handleChange = React.useCallback(
    (value: string) =>
      onChange(
        nameof.full<AiVoiceResponseDto>((r) => r.text),
        value,
        index
      ),
    [index, onChange]
  );

  return (
    <ListItem disableGutters dense>
      <ListItemIcon>
        <EmotionSwitcher
          value={response.emotion}
          onChange={(emotion) => {
            onChange(
              nameof.full<AiVoiceResponseDto>((r) => r.emotion),
              emotion,
              index
            );
          }}
        />
      </ListItemIcon>

      <Box px={1} flexGrow={1}>
        <VariableSuggestionInput
          inputProps={{
            disabled: disabled,
            InputProps: {
              inputProps: {
                maxRows: 5
              }
            },
          }}
          value={response.text}
          variables={variables}
          onChange={handleChange}
        />
      </Box>
      <ListItemIcon>
        {isDeleteButtonDisplayed && (
          <IconButton
            onClick={() => {
              onDelete(index);
            }}
          >
            <Delete />
          </IconButton>
        )}
        <IconButton
          onClick={() => {
            onAdd(index);
          }}
        >
          <Add />
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
};

export default ResponseListItem;
