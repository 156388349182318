import { makeStyles } from '@material-ui/core/styles/index.js';

const userFormWidth = '20%';

export default makeStyles({
  userFormContainer: {
    width: userFormWidth,
    minWidth: 250,
    padding: 8
  }
});
