import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from 'app/store/types.js';
import type { ScheduledCallDto } from '@/generated-api/index.js';
import { ScheduledCallStatus } from '@/generated-api/index.js';

export type CallManagementState = {
  calls: ScheduledCallDto[];
  loadingStatus: LoadingStatus;
  loadingError: Error | null;
};

const initialState: CallManagementState = {
  calls: [],
  loadingStatus: LoadingStatus.Idle,
  loadingError: null
};

export const callManagementSlice = createSlice({
  name: 'callManagement',
  initialState,
  reducers: {
    loadCallsStarted(state) {
      state.loadingStatus = LoadingStatus.Loading;
    },
    loadCallsFinished: {
      prepare(payload: ScheduledCallDto[], error?: Error) {
        return { payload, error };
      },
      reducer(state, action: PayloadAction<ScheduledCallDto[], string, never, Error | undefined>) {
        if (action.error) {
          state.loadingError = action.error;
          state.loadingStatus = LoadingStatus.Failed;
        } else {
          state.loadingError = null;
          state.loadingStatus = LoadingStatus.Succeeded;
          state.calls = action.payload;
        }
      }
    },

    callCreated(state, action: PayloadAction<ScheduledCallDto>) {
      state.calls.unshift(action.payload);
    },

    callUpdated(state, action: PayloadAction<ScheduledCallDto>) {
      const index = state.calls.findIndex((call) => call.id === action.payload.id);
      state.calls[index] = action.payload;
    }
  }
});

export default callManagementSlice;
