import { makeStyles } from '@material-ui/core/styles/index.js';

export default makeStyles({
  root: {
    overflow: 'visible',
    position: 'absolute',
    cursor: 'pointer',
    left: 0,
    right: 0
  }
});
