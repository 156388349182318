import type { ChangeEventHandler, FocusEventHandler } from 'react';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Box, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles/index.js';
import classNames from 'classnames';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline.js';
import type { TypographyProps } from '@material-ui/core/Typography/Typography.js';

export type EditableInputProps = {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  typographyProps?: TypographyProps;
  multiline?: boolean;
  error?: string;
};

const useStyles = makeStyles((theme) => {
  return {
    text: {
      marginLeft: theme.spacing(-1),
      padding: theme.spacing(1, 1),
      ...theme.shape,

      // TODO: refer to the height of the text field
      minHeight: 40,
      lineHeight: '1.4em',
      display: 'flex',
      alignItems: 'center',

      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    }
  };
});

const EditableInput = forwardRef<HTMLInputElement, EditableInputProps>(function EditableInput(
  { value, error, typographyProps, ...props },
  ref
) {
  const [edit, setEdit] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (edit) {
      inputRef.current?.focus();
    }
  }, [edit]);

  const classes = useStyles();

  const displayTextField = edit || error != null;

  return (
    <>
      <Box display={displayTextField ? 'block' : 'none'}>
        <TextField
          inputRef={(e) => {
            if (typeof ref === 'function') {
              ref(e);
            } else {
              if (ref) {
                ref.current = e;
              }
            }
            inputRef.current = e;
          }}
          {...props}
          value={value ?? ''}
          InputProps={{
            ...(error != null && {
              endAdornment: (
                <Tooltip title={<Typography>{error}</Typography>}>
                  <InputAdornment position={'end'}>
                    <ErrorOutlineIcon color={'error'} />
                  </InputAdornment>
                </Tooltip>
              )
            })
          }}
          onFocus={(e) => {
            setEdit(true);
            props.onFocus?.(e);
          }}
          error={error != null}
          onBlur={(e) => {
            props.onBlur?.(e);
            setEdit(false);
          }}
          fullWidth
          size="small"
          variant="outlined"
        />
      </Box>

      <Box display={displayTextField ? 'none' : 'block'}>
        <Typography {...typographyProps} className={classNames(classes.text)} onClick={() => setEdit(true)}>
          {value}
        </Typography>
      </Box>
    </>
  );
});

export default EditableInput;
