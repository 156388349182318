import { createTheme } from '@material-ui/core/styles/index.js';
import type { QualityLevelColorType } from 'app/models/common/QualityLevel.js';
import { QualityLevelColor } from 'app/models/common/QualityLevel.js';
import type { CSSProperties } from '@material-ui/core/styles/withStyles.js';

declare module '@material-ui/core/styles/createPalette.js' {
  interface Palette {
    qualityLevel: QualityLevelColorType;
  }

  interface PaletteOptions {
    qualityLevel: QualityLevelColorType;
  }
}

declare module '@material-ui/core/styles/createMixins.js' {
  interface Mixins {
    denseToolbar: CSSProperties;
  }
}

const theme = createTheme({
  mixins: {
    denseToolbar: {
      minHeight: 48
    }
  },
  palette: {
    primary: {
      main: '#304156'
    },
    secondary: {
      light: '#1f2d3d',
      main: '#001528!important'
    },
    success: {
      main: '#4CAF50'
    },
    error: {
      main: '#F44336'
    },
    qualityLevel: QualityLevelColor
  },
  overrides: {
    MuiTab: {
      wrapper: {
        textTransform: 'none'
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '.text-truncate': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        '.full-width': {
          width: '100% !important'
        }
      }
    },
    MuiMenu: {
      list: {
        // https://github.com/mui-org/material-ui/issues/25064#issuecomment-783948395
        backgroundColor: 'inherit'
      }
    },

    MuiListSubheader: {
      root: {
        // https://github.com/mui-org/material-ui/issues/18200#issuecomment-578349237
        pointerEvents: 'none'
      }
    },
    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          color: '#777'
        }
      }
    }
  }
});

export default theme;
