import type { ISize } from '@mrblenny/react-flow-chart';

const innerNodeSizePx = 220;
const outerNodeBorderWidthPx = 3;

export function getInnerNodeSizePx() {
  return innerNodeSizePx;
}

export function getOuterNodeBorderWidthPx() {
  return outerNodeBorderWidthPx;
}

export function getDefaultNodeSize(): ISize {
  const size = innerNodeSizePx + outerNodeBorderWidthPx * 2;
  return {
    width: size,
    height: size
  };
}
