import type { INode } from '@mrblenny/react-flow-chart/src/types/chart.js';
import type IntentNodeProperties from './intentNodeProperties.js';

export type CustomNodeType = 'normal' | 'start' | 'end' | 'service';

export enum CustomNodeTypeEnum {
  Normal = 'Normal',
  Start = 'Start',
  End = 'End',
  Service = 'service'
}

export type CustomNode = INode<IntentNodeProperties>;
