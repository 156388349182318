import React, { useMemo } from 'react';

import { Tooltip, Menu, MenuItem, ListItemIcon, Typography } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { ScenarioChartSelectors } from 'app/selectors/index.js';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';

import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined.js';
import ChevronRightIcon from '@material-ui/icons/ChevronRight.js';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined.js';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff.js';

import CopyToScenarioMenu from './CopyToScenarioMenu/index.js';

import { useBoolState } from '../../../../utils/customHooks/index.js';

import type { NodeActionMenuProps } from './types.js';
import useStyles from './styles.js';

import type { RootState } from 'app/reducers/index.js';
import { chartNodes } from 'app/selectors/scenarioChartSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const NodeActionMenu: React.FC<NodeActionMenuProps> = (props) => {
  const {
    anchorEl,
    nodeId,
    onClose,
    onClickClone,
    onClickDelete,
    onClickSetIntentTypeAsStart,
    onClickCloneToScenario,
    currentScenarioId
  } = props;
  const [scenarioSelectOpen, openScenarioSelect, closeScenarioSelect] = useBoolState();
  const scenarioMenuRef = React.useRef<HTMLLIElement>(null);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const isInitialNode = useSelector<RootState, boolean>((state) => {
    return chartNodes(state)?.[nodeId]?.type === CustomNodeTypeEnum.Start;
  });

  const currentChart = useSelector(ScenarioChartSelectors.scenarioChart);

  const classes = useStyles();
  const listItemIconClasses = {
    root: classes.listItemIconRoot
  };

  const hasIncomingLinks: boolean = useMemo(() => {
    return Object.values(currentChart.links).some((x) => x.to.nodeId == nodeId);
  }, [currentChart.links, nodeId]);

  const isNodeTypeAsStartBlock: boolean = useMemo(() => {
    return Object.values(currentChart.nodes).some((x) => x.id == nodeId && x.type == CustomNodeTypeEnum.Start);
  }, [currentChart.nodes, nodeId]);

  return (
    <>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
        MenuListProps={{
          dense: true
        }}
        getContentAnchorEl={undefined}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem button onClick={onClickClone}>
          <ListItemIcon classes={listItemIconClasses}>
            <FileCopyOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.nodeActionMenu.copyToCurrentScenario))}
          </Typography>
        </MenuItem>
        <MenuItem button onClick={openScenarioSelect} ref={scenarioMenuRef}>
          <ListItemIcon classes={listItemIconClasses}>
            <FileCopyOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.nodeActionMenu.copyTo))}
          </Typography>
          <ChevronRightIcon fontSize="small" />
        </MenuItem>
        <MenuItem button onClick={onClickDelete} disabled={isInitialNode}>
          <ListItemIcon classes={listItemIconClasses}>
            <DeleteOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
          </Typography>
        </MenuItem>
        <Tooltip
          disableHoverListener={!hasIncomingLinks && !isNodeTypeAsStartBlock}
          placement="right"
          title={
            hasIncomingLinks
              ? translate(nameof.full<I18nScenarioEditorNs>((n) => n.nodeActionMenu.blockHasIncomingLinks))
              : translate(nameof.full<I18nScenarioEditorNs>((n) => n.nodeActionMenu.blockIsAlreadyStartBlock))
          }
        >
          <div>
            <MenuItem
              disabled={hasIncomingLinks || isNodeTypeAsStartBlock}
              button
              onClick={onClickSetIntentTypeAsStart}
            >
              <ListItemIcon classes={listItemIconClasses}>
                <FlightTakeoffIcon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.nodeActionMenu.setAsStartIntent))}
              </Typography>
            </MenuItem>
          </div>
        </Tooltip>
      </Menu>
      <CopyToScenarioMenu
        open={scenarioSelectOpen}
        anchorEl={scenarioMenuRef.current}
        onClose={closeScenarioSelect}
        currentScenarioId={currentScenarioId}
        onClickCloneToScenario={onClickCloneToScenario}
      />
    </>
  );
};

export default NodeActionMenu;
