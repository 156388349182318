import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import useKPIDetailsScore from 'app/components/DashboardPage/KPIDetails/useKPIDetailsScore.js';

type KPIDetailsScoreProps = {
  score: number;
};

const KPIDetailsScore: React.FC<KPIDetailsScoreProps> = ({ score }) => {
  const { color, prefix } = useKPIDetailsScore(score);

  const classes = useStyles();

  return (
    <Typography
      variant="body2"
      className={classes.root}
      style={{
        color: color
      }}
    >
      {`${prefix === '+' ? prefix : ''}${score}`}
    </Typography>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export default KPIDetailsScore;
