import clsx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';

import { Drawer, ListItem, List, ListItemIcon, ListItemText } from '@material-ui/core';

import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount.js';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle.js';
import SchoolIcon from '@material-ui/icons/School.js';
import SpeedIcon from '@material-ui/icons/Speed.js';
import InfoIcon from '@material-ui/icons/Info.js';
import HelpIcon from '@material-ui/icons/Help.js';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications.js';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck.js';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks.js';
import PhoneIcon from '@material-ui/icons/Phone.js';

import useStyles from './styles.js';

import { GuiStateSelectors } from '../../selectors/index.js';
import ScenarioSection from './ScenarioSection/scenarioSection.js';

import AppRoutes from '../../utils/routes.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';
import { isUserAnyAdminSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import { LocalLibrary } from '@material-ui/icons';

export default function NavigationDrawer(): JSX.Element {
  const [translate] = useTranslation(I18nNamespace.Common);
  const classes = useStyles();
  const sideBarOpenState = useSelector(GuiStateSelectors.sideBarStateSelector);

  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);
  const isUserAnyAdmin = useSelector(isUserAnyAdminSelector);

  const usersRouteMatch = useRouteMatch({
    path: AppRoutes.UserManagement,
    strict: false
  });

  const groupManagementRouteMatch = useRouteMatch({
    path: AppRoutes.GroupManagement,
    strict: false
  });

  const introductionRouteMatch = useRouteMatch({
    path: AppRoutes.Introduction,
    strict: false
  });

  const dashboardRouteMatch = useRouteMatch({
    path: AppRoutes.Dashboard,
    strict: false
  });

  const courseManagementRouteMatch = useRouteMatch({
    path: AppRoutes.CourseManagement,
    strict: false
  });

  const myCoursesRouteMatch = useRouteMatch({
    path: AppRoutes.MyCourses,
    strict: false
  });

  const globalAssetsLibraryRouteMatch = useRouteMatch({
    path: AppRoutes.GlobalAssetsLibrary,
    strict: false
  });

  const medicalIndexesRouteMatch = useRouteMatch({
    path: AppRoutes.MedicalIndexManager,
    strict: false
  });

  const callsRouteMatch = useRouteMatch({
    path: AppRoutes.CallManagement,
    strict: false
  });

  const userGuidesRouteMatch = useRouteMatch({
    path: AppRoutes.UserGuides,
    strict: false
  });

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      id="sideBarState"
      open={sideBarOpenState}
      data-cy={sideBarOpenState}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <List className={classes.withoutTBP} subheader={<li />}>

        <ListItem
          className={clsx(classes.withTBP)}
          selected={!!dashboardRouteMatch}
          button
          data-cy="dashboard"
          component={Link}
          to={AppRoutes.Dashboard}
        >
          <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
            <SpeedIcon classes={{ root: classes.white }} />
          </ListItemIcon>
          <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.dashboard))} />
        </ListItem>

        <ScenarioSection />
        {isUserAnyAdmin && (
          <ListItem
            className={clsx(classes.withTBP)}
            selected={!!usersRouteMatch}
            button
            component={Link}
            to={AppRoutes.UserManagement}
          >
            <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
              <SupervisorAccountIcon classes={{ root: classes.white }} />
            </ListItemIcon>
            <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.userManagement))} />
          </ListItem>
        )}

        {isUserAnyAdmin && (
          <ListItem
            className={clsx(classes.withTBP)}
            button
            component={Link}
            to={AppRoutes.GroupManagement}
            selected={!!groupManagementRouteMatch}
          >
            <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
              <SupervisedUserCircle classes={{ root: classes.white }} />
            </ListItemIcon>
            <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.groupManagement))} />
          </ListItem>
        )}

        {isUserAnyAdmin && (
          <ListItem
            className={clsx(classes.withTBP)}
            button
            component={Link}
            to={AppRoutes.CourseManagement}
            selected={!!courseManagementRouteMatch}
          >
            <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
              <SchoolIcon classes={{ root: classes.white }} />
            </ListItemIcon>
            <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.courseManagement))} />
          </ListItem>
        )}
        <ListItem
          className={clsx(classes.withTBP)}
          button
          component={Link}
          to={AppRoutes.MyCourses}
          selected={!!myCoursesRouteMatch}
        >
          <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
            <LocalLibrary classes={{ root: classes.white }} />
          </ListItemIcon>
          <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.myCourses))} />
        </ListItem>

        {isUserAnyAdmin && (
          <ListItem
            className={clsx(classes.withTBP)}
            button
            component={Link}
            to={AppRoutes.GlobalAssetsLibrary}
            selected={!!globalAssetsLibraryRouteMatch}
          >
            <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
              <LibraryAddCheckIcon classes={{ root: classes.white }} />
            </ListItemIcon>
            <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.globalAssetsLibrary))} />
          </ListItem>
        )}

        {isUserAnyAdmin && (
          <ListItem
            className={clsx(classes.withTBP)}
            button
            component={Link}
            to={AppRoutes.MedicalIndexManager}
            selected={!!medicalIndexesRouteMatch}
          >
            <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
              <LibraryBooksIcon classes={{ root: classes.white }} />
            </ListItemIcon>
            <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.protocolReferences))} />
          </ListItem>
        )}

        {isUserAnyAdmin && (
          <ListItem
            className={clsx(classes.withTBP)}
            button
            component={Link}
            to={AppRoutes.CallManagement}
            selected={!!callsRouteMatch}
          >
            <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
              <PhoneIcon classes={{ root: classes.white }} />
            </ListItemIcon>
            <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.scheduledCalls))} />
          </ListItem>
        )}

        <ListItem
          className={clsx(classes.withTBP)}
          button
          component={Link}
          to={AppRoutes.UserGuides}
          selected={!!userGuidesRouteMatch}
        >
          <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
            <HelpIcon classes={{ root: classes.white }} />
          </ListItemIcon>
          <ListItemText primary={translate(nameof.full<I18nCommonNs>((l) => l.navigation.userGuides))} />
        </ListItem>
      </List>
    </Drawer>
  );
}
