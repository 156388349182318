import { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { MenuItem, Menu, Fade } from '@material-ui/core';
import type { SceneDto, SceneGroupDto } from '@/generated-api/scenes/api.js';
import type { FC } from 'react';
import React, { useMemo, useState, useCallback } from 'react';

import useStyles from '../styles.js';
import { SceneClient } from 'app/apis/api.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import { ScenarioSelectors, UserSelectors } from 'app/selectors/index.js';
import useCursorPosition from 'app/components/NavigationDrawer/hooks/useCursorPosition.js';
import NavigationDrawerCompanyScenarios from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/NavigationDrawerCompanyScenarios.js';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getScenarioGroupsQuery } from 'app/queries/scenarioQueries.js';
import type { SceneGroupWithScenes, SceneWithCompanyName } from './types.js';

import { useTranslation } from 'react-i18next';
import type { I18nScenarioSectionNs } from '@/i18n/dictionaries/interfaces.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';

export interface NavigationDrawerScenarioListProps {
  filterScenes: SceneWithCompanyName[];
  handleSelectScenario: () => void;
  isShowGroupsEnabled: boolean;
}

const NavigationDrawerScenarioList: FC<NavigationDrawerScenarioListProps> = ({
  filterScenes,
  handleSelectScenario,
  isShowGroupsEnabled
}): JSX.Element => {
  const classes = useStyles();
  const [translate] = useTranslation(I18nNamespace.ScenarioSection);
  const isUserAnyAdmin = useSelector(UserSelectors.isUserAnyAdminSelector);
  const isUserSuperAdmin = useSelector(UserSelectors.isUserSuperAdminSelector);
  const currentUser = useSelector(UserSelectors.CurrentUserSelector);

  const currentScenario = useSelector(ScenarioSelectors.currentScenario);

  const queryClient = useQueryClient();
  const sceneGroupsQueryData = useQuery(getScenarioGroupsQuery);
  const sceneGroups = useMemo(() => sceneGroupsQueryData.data ?? [], [sceneGroupsQueryData.data]);

  const sceneId = useMemo(() => {
    return currentScenario?.sceneId;
  }, [currentScenario]);

  const [lastRightClickedScene, setLastRightClickedScene] = React.useState<SceneDto>();

  const [selectedItem, setSelectedItem] = useState<number | null>();

  const handleClickOnSubmenu = (_key: number) => {
    selectedItem != _key ? setSelectedItem(_key) : setSelectedItem(null);
  };

  const [selectedSceneGroupId, setSelectedSceneGroupId] = useState<number | null>();
  const handleClickOnGroupSubmenu = (_key: number) => {
    selectedSceneGroupId != _key ? setSelectedSceneGroupId(_key) : setSelectedSceneGroupId(null);
  };

  const scenesByCompanyId = useMemo(() => {
    const group = groupBy(filterScenes, (el: SceneWithCompanyName) => {
      return el.companyName;
    });
    return Object.values(group);
  }, [filterScenes]);

  const sceneIdsInCompanyGroups = useMemo(() => {
    return Object.entries(groupBy(sceneGroups, (sg) => sg.companyId)).map(([id, sgArr]) => ({
      companyId: id,
      sceneIds: sgArr.flatMap((sg) => sg.sceneIds)
    }));
  }, [sceneGroups]);

  const groupMoveMenuRef = React.useRef<HTMLLIElement>(null);
  const groupRemoveMenuRef = React.useRef<HTMLLIElement>(null);
  const [groupMoveMenuOpen, openGroupMoveMenu, closeGroupMoveMenu] = useBoolState();

  const [selectedScenes, setSelectedScenes] = useState<number[]>([]);
  const [isGroupAddEnabled, showAddGroup, closeAddGroup] = useBoolState();

  const { cursorPosition, setCursorPosition, resetCursorPosition } = useCursorPosition();

  const getGroupsWithScenes = useCallback<(scenes: SceneDto[], companyId: number) => SceneGroupWithScenes[]>(
    (scenes: SceneDto[], companyId: number) => {
      return sceneGroups
        .filter((x) => x.companyId == companyId)
        .map((sg) => {
          const sceneGroupScenes = scenes.filter(
            (scene) =>
              (companyId == scene.companyId || scene.sharedCompanies?.some((sc) => sc.id === companyId)) &&
              sg.sceneIds.includes(scene.id)
          );
          return { ...sg, scenes: sceneGroupScenes, disableActions: false };
        });
    },
    [sceneGroups]
  );

  const isRemoveFromGroupDisabled = useMemo(() => {
    if (!lastRightClickedScene) return true;

    return !sceneGroups.some(
      (x) =>
        (x.companyId === lastRightClickedScene.companyId ||
          lastRightClickedScene.sharedCompanies?.some((sc) => sc.id === x.companyId)) &&
        x.sceneIds.includes(lastRightClickedScene.id)
    );
  }, [lastRightClickedScene, sceneGroups]);

  const handleRightClick = (event: React.MouseEvent<HTMLElement>, scene: SceneDto) => {
    if (!isUserAnyAdmin) return;

    event.preventDefault();
    setCursorPosition({
      x: event.clientX - 2,
      y: event.clientY - 4
    });
    setSelectedScenes([scene.id]);
    setLastRightClickedScene(scene);
  };

  const handleCloseMenu = useCallback(() => {
    closeGroupMoveMenu();
    resetCursorPosition();
  }, [closeGroupMoveMenu, resetCursorPosition]);

  const createSceneGroup = useCallback(
    async (groupName: string, companyId?: number): Promise<SceneGroupDto> => {
      const response = await SceneClient.sceneCreateGroup(companyId ?? undefined, groupName);
      queryClient.invalidateQueries(getScenarioGroupsQuery.queryKey);
      closeAddGroup();

      return response.data;
    },
    [closeAddGroup, queryClient]
  );

  const moveScenesToGroup = useCallback(
    async (groupId: number, selectedSceneIds: number[]) => {
      await SceneClient.sceneAddSceneToGroup(groupId, selectedSceneIds);
      queryClient.invalidateQueries(getScenarioGroupsQuery.queryKey);
      handleCloseMenu();

      setSelectedSceneGroupId(groupId);
    },
    [handleCloseMenu, queryClient]
  );

  const removeScenesFromGroup = useCallback(
    async (groupId: number, selectedSceneIds: number[]) => {
      await SceneClient.sceneRemoveScenesFromGroup(groupId, selectedSceneIds);
      queryClient.invalidateQueries(getScenarioGroupsQuery.queryKey);
      handleCloseMenu();
    },
    [handleCloseMenu, queryClient]
  );

  const contextMenu = (sceneGroupId?: number) => {
    const companySceneGroups = sceneGroups.filter(
      (x) => x.companyId === (lastRightClickedScene?.companyId ?? currentUser.companyId)
    );

    return (
      <>
        <Menu
          keepMounted
          TransitionComponent={Fade}
          open={cursorPosition.y !== null}
          onClose={handleCloseMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            cursorPosition.y !== null && cursorPosition.x !== null
              ? { top: cursorPosition.y, left: cursorPosition.x }
              : undefined
          }
        >
          <MenuItem onClick={openGroupMoveMenu} ref={groupMoveMenuRef}>
            {translate(nameof.full<I18nScenarioSectionNs>((l) => l.movetoFolder))}
          </MenuItem>
          <MenuItem
            ref={groupRemoveMenuRef}
            disabled={isRemoveFromGroupDisabled || !sceneGroupId}
            onClick={() => removeScenesFromGroup(sceneGroupId, [lastRightClickedScene.id])}
          >
            {translate(nameof.full<I18nScenarioSectionNs>((l) => l.removeFromFolder))}
          </MenuItem>
        </Menu>
        <Menu
          keepMounted
          open={groupMoveMenuOpen}
          onClose={handleCloseMenu}
          PaperProps={{
            onMouseLeave: () => {
              closeGroupMoveMenu();
            }
          }}
          anchorEl={groupMoveMenuRef.current}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top'
          }}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top'
          }}
        >
          {!isGroupAddEnabled && (
            <MenuItem
              divider={!!companySceneGroups.length}
              onClick={() => {
                handleCloseMenu();
                showAddGroup();
              }}
            >
              {translate(nameof.full<I18nScenarioSectionNs>((l) => l.createNew))}
            </MenuItem>
          )}
          {companySceneGroups.map((sceneGroup) => (
            <MenuItem
              key={'moveToGroup' + sceneGroup.id}
              onClick={() => moveScenesToGroup(sceneGroup.id, selectedScenes)}
            >
              {sceneGroup.title}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  const onCreateSceneGroup = useCallback(
    async (sceneGroupName) => {
      const sceneGroup = await createSceneGroup(sceneGroupName, lastRightClickedScene?.companyId);

      if (!sceneGroup) return;
      if (!lastRightClickedScene) return;

      await moveScenesToGroup(sceneGroup.id, [lastRightClickedScene.id]);
    },
    [createSceneGroup, lastRightClickedScene, moveScenesToGroup]
  );

  const hasMultipleCompanies = scenesByCompanyId.length > 1;

  return (
    <>
      {scenesByCompanyId.map((scenes: SceneWithCompanyName[]) => {
        const initialScene = scenes.find((scene) => scene.companyId !== null) || scenes[0];

        let companyId = currentUser.companyId;

        if (isUserSuperAdmin) {
          companyId = initialScene?.companyId;
        }

        const open = selectedItem === companyId || false;

        let groupsWithScenes = getGroupsWithScenes(scenes, companyId);

        const sceneIdsInGroupsForCompany =
          sceneIdsInCompanyGroups.find((obj) => obj.companyId === companyId?.toString())?.sceneIds ?? [];

        if (!companyId) {
          // For scenes with sharedCompanies, set these in folders for the companies?!?
          const mapping = new Map<number, { title: string; sceneIds: number[] }>();

          if (isUserSuperAdmin) {
            for (const scene of scenes) {
              if (scene.sharedCompanies?.length) {
                for (const shared of scene.sharedCompanies) {
                  const found = mapping.get(shared.id);

                  if (found) found.sceneIds.push(scene.id);
                  else mapping.set(shared.id, { title: shared.name, sceneIds: [scene.id] });
                }
              }
            }
          }

          groupsWithScenes = Array.from(mapping).map(([companyId, { title, sceneIds }], index) => {
            // where a scene is in one of the scenegroups for a company, add subgroup instead of directly
            const finalScenes = new Set(sceneIds);

            const subGroups: SceneGroupWithScenes[] = [];
            const filteredGroups = sceneGroups.filter((x) => x.companyId === companyId);
            for (const group of filteredGroups) {
              const scenesToAdd = [];
              for (const sceneId of sceneIds) {
                if (group.sceneIds?.includes(sceneId)) {
                  scenesToAdd.push(sceneId);
                  finalScenes.delete(sceneId);
                }
              }

              if (scenesToAdd.length) {
                subGroups.push({
                  scenes: scenes.filter((x) => scenesToAdd.includes(x.id)),
                  id: group.id,
                  title: group.title,
                  companyId,
                  sceneIds: scenesToAdd,
                  disableActions: true
                });
              }
            }

            return {
              scenes: scenes.filter((x) => finalScenes.has(x.id)),
              companyId,
              id: -1 - index,
              sceneIds: Array.from(finalScenes),
              title,
              subGroups,
              disableActions: true
            };
          });
        }

        return (
          <NavigationDrawerCompanyScenarios
            key={companyId}
            selectedItem={selectedItem}
            scenes={scenes}
            companyId={companyId}
            initialScene={initialScene}
            open={hasMultipleCompanies ? open : true}
            handleClickOnSubmenu={handleClickOnSubmenu}
            isShowGroupsEnabled={isShowGroupsEnabled}
            groupsWithScenes={groupsWithScenes}
            classes={classes}
            sceneId={sceneId}
            selectedSceneGroupId={selectedSceneGroupId}
            contextMenu={contextMenu}
            handleRightClick={handleRightClick}
            handleSelectScenario={handleSelectScenario}
            isGroupAddEnabled={isGroupAddEnabled}
            closeAddGroup={closeAddGroup}
            onCreateSceneGroup={onCreateSceneGroup}
            sceneIdsInGroups={sceneIdsInGroupsForCompany}
            handleClickOnGroupSubmenu={handleClickOnGroupSubmenu}
            displayCompanyName={hasMultipleCompanies}
          />
        );
      })}
    </>
  );
};

export default NavigationDrawerScenarioList;
