import React from 'react';
import { Typography, useTheme } from '@material-ui/core';

const KIPDetailsTabHeader: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography
      style={{
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.typography.subtitle1.fontSize
      }}
    >
      {children}
    </Typography>
  );
};

export default KIPDetailsTabHeader;
