/* eslint-disable react/display-name */
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath, useParams } from 'react-router';
import type { GridCellParams, GridColumns } from '@material-ui/data-grid';
import type { ChangeEvent } from 'react';
import React, { useEffect, useMemo, useState, useCallback } from 'react';

import callManagementSlice from 'app/components/CallManagement/store/callManagementSlice.js';

import {
  Box,
  Card,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  CardContent,
  ListItemText,
  Link as MuiLink,
  Tooltip,
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import useMenu from 'app/hooks/useMenu.js';
import { CourseClient, ScheduledCallsClient } from 'app/apis/api.js';
import { SceneRoutes } from 'app/utils/routes.js';
import { LoadingStatus } from 'app/store/types.js';
import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import useCardStyles from 'app/components/CourseManagement/CourseCard/useStyles.js';
import { CurrentUserSelector, isUserAnyAdminSelector } from 'app/selectors/userSelectors.js';
import type { DialogStatisticDto, ScheduledCourseSceneResultDto, UserDto } from '@/generated-api/index.js';
import NoCourseData from 'app/components/CourseManagement/CourseCard/NoCourseData/NoCourseData.js';
import LinearProgressWithLabel from 'app/components/utils/styledTableElements/LinearProgressWithLabel/index.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';
import { MoreVert, Phone } from '@material-ui/icons';
import { current } from '@reduxjs/toolkit';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const { actions: callManagementActions } = callManagementSlice;

interface UserScheduledCoursesMenuProps {
  dialogStatistic: DialogStatisticDto;
  handleDisplayKpiDetails: (dialogStatistic: DialogStatisticDto) => void;
}

const UserScheduledCoursesMenu: React.FC<UserScheduledCoursesMenuProps> = ({
  dialogStatistic,
  handleDisplayKpiDetails
}) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();

  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          handleClick(event);
        }}
      >
        <MoreVert />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          handleClose();
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleDisplayKpiDetails(dialogStatistic)}>
          <ListItemText primary="View details" />
        </MenuItem>
      </Menu>
    </>
  );
};

type UserScheduledCourseParams = {
  scheduledCourseId: string;
};

interface UserScheduledCourseCardProps {
  handleDisplayKpiDetails: (dialogStatistic: DialogStatisticDto) => void;
}

const UserScheduledCourseCard: React.FC<UserScheduledCourseCardProps> = ({ handleDisplayKpiDetails }) => {
  const classes = useCardStyles();

  const isAnyAdmin = useSelector(isUserAnyAdminSelector);
  const { scheduledCourseId } = useParams<UserScheduledCourseParams>();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const currentUser = useSelector(CurrentUserSelector);
  const initialSelectedUserId = currentUser.id;

  const scheduledCourse = useSelector((state) =>
    CourseManagementSelectors.scheduledCourseById(state, scheduledCourseId)
  );

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Loading);
  const [results, setResults] = useState<ScheduledCourseSceneResultDto[]>();
  const [allAssignedUsers, setAllAssignedUsers] = useState<UserDto[]>();
  const [selectedUserId, setSelectedUserId] = useState<number>(initialSelectedUserId);

  const setSelectedUserIdAndLoadResults = useCallback(
    (userId: number) => {
      setSelectedUserId(userId);
      CourseClient.courseGetUserScheduledCourseResults(
        Number.parseInt(scheduledCourseId, 10),
        userId
      ).then((response) => setResults(response.data));
    },
    [scheduledCourseId]
  );

  useEffect(() => {
    setLoadingStatus(LoadingStatus.Loading);
    if (isAnyAdmin) {
      CourseClient.courseGetScheduledCourseAllAssignedUsers(Number.parseInt(scheduledCourseId, 10))
        .then((response) => {
          setAllAssignedUsers(response.data);
        })
        .catch(() => setLoadingStatus(LoadingStatus.Failed));
    }
    CourseClient.courseGetUserScheduledCourseResults(Number.parseInt(scheduledCourseId, 10), selectedUserId)
      .then((response) => setResults(response.data))
      .then(() => setLoadingStatus(LoadingStatus.Succeeded))
      .catch(() => setLoadingStatus(LoadingStatus.Failed));
  }, [isAnyAdmin, scheduledCourseId, selectedUserId]);

  const dispatch = useDispatch();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const columns: GridColumns = useMemo(
    () => [
      {
        sortable: false, // Does not work currently, because value is a nested property
        field: 'name',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.scenario)),
        flex: 2,
        renderCell: (data: GridCellParams) => {
          const { scene } = data.row as ScheduledCourseSceneResultDto;

          const path = generatePath(SceneRoutes.Scene, {
            sceneId: scene.id
          });

          const handlePhoneClick = async () => {
            try {
              const created = await ScheduledCallsClient.scheduledCallsCreate({
                sceneId: scene.id,
                scheduledTime: moment().utc().format('YYYY-MM-DDTHH:mm'),
                targetName: currentUser.name,
                userId: currentUser.id,
                phoneNumber: currentUser.phone
              });
              dispatch(callManagementActions.callCreated(created.data));
              setSnackbarMessage(
                `${translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.scenario))} ${
                  scene.name
                } ${translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.dispatchedTo))} ${
                  currentUser.phone
                }`
              );
              setIsSnackbarOpen(true);
            } catch (error) {
              console.error('Error creating scheduled call:', error);
            }
          };

          return (
            <Grid container alignItems="center" wrap="nowrap">
              {currentUser.phone && (
                <Grid item>
                  <Tooltip
                    title={`${translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.call))} ${
                      currentUser.phone
                    }`}
                  >
                    <IconButton size="medium" onClick={handlePhoneClick}>
                      <Phone fontSize="medium" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <Tooltip title={isAnyAdmin ? scene.description ?? '' : ''}>
                  <MuiLink to={path} component={Link} className={classes.link}>
                    {scene.name}
                  </MuiLink>
                </Tooltip>
              </Grid>
            </Grid>
          );
        }
      },
      {
        field: 'status',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.status)),
        flex: 1,
        sortable: false // Does not work currently, because value is a nested property
      },
      {
        field: 'procedureScores',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.procedureScore)),
        flex: 1,
        renderCell: (data: GridCellParams) => {
          const { dialogStatistic } = data.row as ScheduledCourseSceneResultDto;

          if (!dialogStatistic) return null;

          return (
            <Grid container direction="column">
              <LinearProgressWithLabel
                value={dialogStatistic.procedureScores}
                scoresMax={dialogStatistic.procedureScoresMax}
              />
            </Grid>
          );
        }
      },
      {
        field: 'reachTimeScores',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.reachTimeScore)),
        flex: 1,
        sortable: false, // Does not work currently, because value is a nested property
        renderCell: (data: GridCellParams) => {
          const { dialogStatistic } = data.row as ScheduledCourseSceneResultDto;

          if (!dialogStatistic) return null;

          return (
            <Grid container direction="column">
              <LinearProgressWithLabel
                value={dialogStatistic.reachTimeScores}
                scoresMax={dialogStatistic.reachTimeScoresMax}
              />
            </Grid>
          );
        }
      },
      {
        field: 'transitionScores',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.transitionScore)),
        flex: 1,
        sortable: false, // Does not work currently, because value is a nested property
        renderCell: (data: GridCellParams) => {
          const { dialogStatistic } = data.row as ScheduledCourseSceneResultDto;

          if (!dialogStatistic) return null;

          return dialogStatistic.transitionScores;
        }
      },
      {
        field: 'kpi',
        headerName: ' ',
        width: 50,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (data: GridCellParams) => {
          const { dialogStatistic } = data.row as ScheduledCourseSceneResultDto;

          if (!dialogStatistic) return null;

          return (
            <UserScheduledCoursesMenu
              dialogStatistic={dialogStatistic}
              handleDisplayKpiDetails={handleDisplayKpiDetails}
            />
          );
        }
      }
    ],
    [translate, currentUser.phone, currentUser.name, currentUser.id, isAnyAdmin, classes.link, dispatch, handleDisplayKpiDetails]
  );

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  if (!results) return null;
  if (!scheduledCourse) return null;

  if (loadingStatus === LoadingStatus.Loading) {
    return <NoCourseData loadingStatus={loadingStatus} isScenariosLoading={false} />;
  }

  return (
    <>
      <Card square classes={{ root: classes.card }}>
        <Box paddingTop={3} paddingX={2} marginBottom={2}>
          <Typography variant="h4">{scheduledCourse.courseTitle}</Typography>
          {isAnyAdmin && (
            <TextField
              select
              label={translate(nameof.full<I18nCourseManagementNs>((n) => n.myCourseCard.user))}
              margin="normal"
              value={selectedUserId}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSelectedUserIdAndLoadResults((event.target.value as unknown) as number);
              }}
            >
              {allAssignedUsers.map((user: UserDto) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>

        <CardContent classes={{ root: classes.cardContentRoot }}>
          <SimpleDataGrid
            autoHeight
            rows={results}
            loading={false}
            columns={columns}
            disableSelectionOnClick
            hideFooterSelectedRowCount
            isCellEditable={() => false}
            isRowSelectable={() => false}
            getRowId={(row: ScheduledCourseSceneResultDto) => row.scene.id}
          />
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UserScheduledCourseCard;
