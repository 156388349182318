import { useCallback } from 'react';
import { format } from 'date-fns-tz';

import { DateFormat } from 'app/app.constants.js';

const useFormatDate: (defaultFormat?: string) => (dateString: string, dateFormat?: string) => string = (
  defaultFormat = DateFormat.Date
) => {
  const formatDate = useCallback(
    (dateString: string, dateFormat?: string) => {
      const date = new Date(dateString);

      return format(date, dateFormat ?? defaultFormat);
    },
    [defaultFormat]
  );

  return formatDate;
};

export default useFormatDate;
