import { handleActions } from 'redux-actions';
import AudioActions from 'app/actions/audio/audioAction.js';

export interface IAudioState {
  isLoaded: boolean;
  voice: string;
}

const initialState: IAudioState = {
  isLoaded: false,
  voice: ''
};

export const audioReducer = handleActions<IAudioState, boolean | { count: number } | any>(
  {
    [AudioActions.Type.SET_STORE_IS_LOADED]: (state, action): IAudioState => {
      return { ...state, isLoaded: action.payload as boolean };
    },
    [AudioActions.Type.SET_AUDIO_STORE]: (state, action): IAudioState => {
      return { ...state, voice: action.payload as string };
    }
  },
  initialState
);
