import type {
  AddCompanyVariableCommand,
  AddVariableCommand,
  UpdateCompanyVariableCommand,
  UpdateVariableCommand
} from '@/generated-api/index.js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getDetailedScenario,
  saveDetailedScenario,
  updateScenarioDetails
} from 'app/actions/scenarios/scenarioAction.js';
import { ScenarioClient } from 'app/apis/api.js';
import { getCompanyVariablesQuery, getVariablesQuery } from 'app/queries/variableQueries.js';
import { ScenarioChartSelectors, ScenarioSelectors } from 'app/selectors/index.js';
import { updateChangedVariableName } from 'app/utils/variableUtils.js';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useVariableMutations = (scenarioId: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const variableQuery = useMemo(() => getVariablesQuery(scenarioId), [scenarioId]);

  const currentChartScenario = useSelector(ScenarioSelectors.currentChartScenario);
  const currentChart = useSelector(ScenarioChartSelectors.scenarioChart);

  const addVariable = useMutation({
    mutationFn: async (addVariableCommand: AddVariableCommand) => {
      await ScenarioClient.scenariosAddVariable(scenarioId, addVariableCommand);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(variableQuery.queryKey);
      dispatch(
        getDetailedScenario({
          sceneId: scenarioId,
          chartDOMWidth: 0,
          chartDOMHeight: 0
        })
      );
    }
  });

  const addVariables = useMutation({
    mutationFn: async (commands: AddVariableCommand[]) => {
      await ScenarioClient.scenariosAddMultipleVariables(scenarioId, commands);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(variableQuery.queryKey);
      dispatch(
        getDetailedScenario({
          sceneId: scenarioId,
          chartDOMWidth: 0,
          chartDOMHeight: 0
        })
      );
    }
  });

  const updateVariable = useMutation({
    mutationFn: async (vars: { oldName?: string; updateVariableCommand: UpdateVariableCommand }) => {
      const { updateVariableCommand } = vars;
      await ScenarioClient.scenariosUpdateVariable(scenarioId, updateVariableCommand);
    },
    onSuccess: (_, vars) => {
      const { oldName, updateVariableCommand } = vars;
      if (oldName && oldName !== updateVariableCommand.name && updateVariableCommand.name) {
        updateChangedVariableName(
          currentChartScenario,
          Object.values(currentChart.nodes),
          oldName,
          updateVariableCommand.name
        );
        dispatch(updateScenarioDetails(currentChartScenario));
        dispatch(saveDetailedScenario());
      } else {
        dispatch(
          getDetailedScenario({
            sceneId: scenarioId,
            chartDOMWidth: 0,
            chartDOMHeight: 0
          })
        );
      }
      queryClient.invalidateQueries(variableQuery.queryKey);
    }
  });

  const removeVariable = useMutation({
    mutationFn: async (variableName: string) => {
      await ScenarioClient.scenariosRemoveVariable(scenarioId, variableName);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(variableQuery.queryKey);
      dispatch(
        getDetailedScenario({
          sceneId: scenarioId,
          chartDOMWidth: 0,
          chartDOMHeight: 0
        })
      );
    }
  });

  return { addVariable, addVariables, updateVariable, removeVariable };
};

export const useCompanyVariableMutations = (scenarioId: number, companyId?: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const companyVariablesQuery = useMemo(() => getCompanyVariablesQuery(scenarioId), [scenarioId]);

  const addCompanyVariable = useMutation({
    mutationFn: async (command: AddCompanyVariableCommand) => {
      await ScenarioClient.scenariosAddCompanyVariable(scenarioId, companyId, command);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyVariablesQuery.queryKey);
      dispatch(
        getDetailedScenario({
          sceneId: scenarioId,
          chartDOMWidth: 0,
          chartDOMHeight: 0
        })
      );
    }
  });

  const updateCompanyVariable = useMutation({
    mutationFn: async (data: { companyVariableId: number; command: UpdateCompanyVariableCommand }) => {
      await ScenarioClient.scenariosUpdateCompanyVariable(scenarioId, data.companyVariableId, data.command);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyVariablesQuery.queryKey);
      dispatch(
        getDetailedScenario({
          sceneId: scenarioId,
          chartDOMWidth: 0,
          chartDOMHeight: 0
        })
      );
    }
  });

  const removeCompanyVariable = useMutation({
    mutationFn: async (companyVariableId: number) => {
      await ScenarioClient.scenariosRemoveCompanyVariable(scenarioId, companyVariableId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(companyVariablesQuery.queryKey);
      dispatch(
        getDetailedScenario({
          sceneId: scenarioId,
          chartDOMWidth: 0,
          chartDOMHeight: 0
        })
      );
    }
  });

  return { addCompanyVariable, updateCompanyVariable, removeCompanyVariable };
};
