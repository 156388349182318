import { debounce, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import type { Action } from 'redux-actions';

import { IntentEditingActions, UpdateResponsesPayload } from '../../../actions/scenarios/intentEditingAction.js';
import { ScenarioChartStoreActions } from '../../../actions/scenarios/scenarioChartStoreAction.js';

import { IntentFormSelectors, ScenarioChartSelectors, ScenarioSelectors } from '../../../selectors/index.js';
import type { CustomNode } from '../../../models/intents/customNode.js';
import type { SceneDto, AiVoiceResponseDto, GlobalAssetFullDto, GlobalAssetIntentDto } from '@/generated-api/index.js';
import { Emotion, TtsProvider } from '@/generated-api/index.js';
import type { CustomChart } from '../../../models/scenarios/customChart.js';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';

import getDefaultPorts from '../../../utils/flowChart/portsHelpers.js';

import { Guid } from 'guid-typescript';
import { categoriesSelector } from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySelectors.js';

function* addUserSays(params: Action<number>) {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);

  currentEditedNode.properties?.intentPhrases.splice(params.payload + 1, 0, { text: '', id: 0 });

  yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
}

function* removeUserSays(params: Action<number>) {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);

  currentEditedNode.properties.intentPhrases.splice(params.payload, 1);

  yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
}

function* changeIntentName(params: Action<string>) {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);
  currentEditedNode.properties.name = params.payload;

  yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
}

function* changeIntentUserSays(params: Action<{ index: number; value: string }>) {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);

  currentEditedNode.properties.intentPhrases[params.payload.index].text = params.payload.value;

  yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
}

function* onIntentEditingClose() {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);

  if (!!currentEditedNode) {
    currentEditedNode.properties.intentPhrases = currentEditedNode.properties.intentPhrases.filter(
      (x) => x.text !== ''
    );

    currentEditedNode.properties.aiResponses = currentEditedNode.properties.aiResponses.filter(
      (x: AiVoiceResponseDto) => x.text !== ''
    );

    yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
  }
}

function* changeLabelColor(params: Action<string>) {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);
  currentEditedNode.properties.labelColor = params.payload;
  yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
}

function* changeIntentScore(params: Action<number>) {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);

  // TODO this proabably should be mapped to something?
  // currentEditedNode.properties.score = params.payload;

  yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
}

function* toggleIntentScenarioLink(params: Action<number>) {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);
  const scenario: SceneDto = yield select(ScenarioSelectors.getScenarioById(params.payload));

  const linkedScenarios = currentEditedNode.properties.linkedScenarios ?? [];
  const indexOf = linkedScenarios.findIndex((x) => x.sceneId == params.payload);

  const updatedLinkedScenarios =
    indexOf >= 0
      ? linkedScenarios.filter((s, sId) => sId !== indexOf)
      : linkedScenarios.concat({
          id: 0,
          sceneName: scenario?.name,
          sceneId: scenario?.id,
          startBlockId: scenario?.startBlockId
        });

  yield put(
    ScenarioChartStoreActions.setNode({
      ...currentEditedNode,
      properties: {
        ...currentEditedNode.properties,
        linkedScenarios: updatedLinkedScenarios
      }
    })
  );
}

function* changeAmkAction(params: Action<number>) {
  const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);
  if (!currentEditedNode.properties.medicalProtocolActions) {
    currentEditedNode.properties.medicalProtocolActions = [];
  }

  // TODO Id check is enough?
  currentEditedNode.properties.medicalProtocolActions.some((x) => x.id === params.payload)
    ? (currentEditedNode.properties.medicalProtocolActions = currentEditedNode.properties.medicalProtocolActions?.filter(
        (e) => e.id !== params.payload
      ))
    : currentEditedNode.properties.medicalProtocolActions?.push({ id: params.payload });

  yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
}

function* setIntentTypeAsStart(params: Action<string>) {
  const currentChart: CustomChart = yield select(ScenarioChartSelectors.scenarioChart);
  const lastTypeAsStartNodeIndex = Object.keys(currentChart.nodes).find(
    (node) => currentChart.nodes[node].type == CustomNodeTypeEnum.Start
  );
  if (lastTypeAsStartNodeIndex) {
    const lastStartTypeNode: CustomNode = currentChart.nodes[lastTypeAsStartNodeIndex];
    lastStartTypeNode.type = CustomNodeTypeEnum.Normal;
    lastStartTypeNode.ports = getDefaultPorts(false);
    yield put(ScenarioChartStoreActions.setNode({ ...lastStartTypeNode }));
  }

  const currentEditedNode: CustomNode = currentChart.nodes[params.payload];
  currentEditedNode.type = CustomNodeTypeEnum.Start;
  currentEditedNode.ports = getDefaultPorts(true);

  yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
}

function* watchSetIntentTypeAsStart() {
  yield debounce(500, IntentEditingActions.Type.SET_INTENT_TYPE_AS_START, setIntentTypeAsStart);
}

function* watchEditIntentScenarioLink() {
  yield debounce(500, IntentEditingActions.Type.TOGGLE_SCENARIO_LINK, toggleIntentScenarioLink);
}

function* watchChangeIntentScore() {
  yield debounce(500, IntentEditingActions.Type.CHANGE_SCORE, changeIntentScore);
}

function* watchChangeLabelColor() {
  yield debounce(500, IntentEditingActions.Type.CHANGE_LABEL_COLOR, changeLabelColor);
}

function* watchIntentEditingClose() {
  yield debounce(500, IntentEditingActions.Type.CLOSE_INTENT_EDITING, onIntentEditingClose);
}

function* watchChangeUserSays() {
  yield debounce(500, IntentEditingActions.Type.CHANGE_INTENT_USER_SAYS, changeIntentUserSays);
}

function* watchChangeIntentName() {
  yield debounce(500, IntentEditingActions.Type.CHANGE_INTENT_NAME, changeIntentName);
}

function* watchRemoveUserSays() {
  yield debounce(500, IntentEditingActions.Type.REMOVE_USER_SAYS, removeUserSays);
}

function* watchAddUserSays() {
  yield debounce(500, IntentEditingActions.Type.ADD_USER_SAYS, addUserSays);
}

function* watchChangeAmkAction() {
  yield debounce(500, IntentEditingActions.Type.CHANGE_AMK_ACTION_ID, changeAmkAction);
}

function* watchUpdateAIResponses() {
  yield takeLatest(IntentEditingActions.Type.UPDATE_AI_RESPONSES, saga);

  function* saga(action: Action<AiVoiceResponseDto[]>) {
    const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);
    currentEditedNode.properties.aiResponses = action.payload;

    yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
  }
}

// TODO Remove this?!
function* watchUpdateFallbackResponses() {
  yield debounce(300, IntentEditingActions.Type.UPDATE_FALLBACK_RESPONSES, saga);

  function* saga(action: Action<AiVoiceResponseDto[]>) {
    const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);
    currentEditedNode.properties.fallbackResponses = action.payload;

    yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
  }
}

function* watchUpdateGlobalAssets() {
  yield takeLatest(IntentEditingActions.Type.UPDATE_GLOBAL_ASSETS, saga);

  function* saga(action: Action<GlobalAssetIntentDto[]> | null) {
    const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);

    currentEditedNode.properties.globalAssetIntents = action.payload;

    if (currentEditedNode.properties.globalAssetIntents.length == 1) {
      const categories: GlobalAssetFullDto[] = yield select(categoriesSelector);
      const templates = categories.flatMap((x) => x.intents);

      const currentEditedNodeTemplateId = currentEditedNode.properties.globalAssetIntents[0];
      const targetTemplate = templates.find((x) => x.id === currentEditedNodeTemplateId.id);
      if (targetTemplate) currentEditedNode.properties.name = targetTemplate.title;
    }
    currentEditedNode.properties = { ...currentEditedNode.properties };

    yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
  }
}

function* watchUpdateBlockType() {
  yield takeLatest(IntentEditingActions.Type.UPDATE_BLOCK_TYPE, saga);

  function* saga(action: Action<string> | null) {
    const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);
    if (action.payload == CustomNodeTypeEnum.Start) {
      yield put(IntentEditingActions.setIntentTypeAsStart(currentEditedNode.id));
    } else {
      currentEditedNode.type = action.payload;
      currentEditedNode.properties = { ...currentEditedNode.properties };

      yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
    }
  }
}

function* watchUpdatePersonaId() {
  yield takeLatest(IntentEditingActions.Type.UPDATE_PERSONA_ID, saga);

  function* saga(action: Action<number> | null) {
    const currentEditedNode: CustomNode = yield select(IntentFormSelectors.intentInEditing);

    currentEditedNode.properties.personaId = action.payload;
    currentEditedNode.properties = { ...currentEditedNode.properties };

    yield put(ScenarioChartStoreActions.setNode({ ...currentEditedNode }));
  }
}

export default function* watchAllIntentEditingSaga() {
  yield fork(watchUpdateAIResponses);
  yield fork(watchUpdateFallbackResponses);
  yield fork(watchUpdateGlobalAssets);

  yield fork(watchChangeAmkAction);
  yield fork(watchEditIntentScenarioLink);
  yield fork(watchChangeIntentScore);
  yield fork(watchChangeLabelColor);
  yield fork(watchIntentEditingClose);

  yield fork(watchChangeUserSays);
  yield fork(watchChangeIntentName);

  yield fork(watchRemoveUserSays);
  yield fork(watchAddUserSays);
  yield fork(watchSetIntentTypeAsStart);
  yield fork(watchUpdateBlockType);
  yield fork(watchUpdatePersonaId);
}
