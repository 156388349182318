import type { Ref } from 'react';
import React, { useCallback, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles/index.js';
import { ArrowRight } from '@material-ui/icons';
import { TextField } from '@material-ui/core';
import QualityIcon from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TransitionWeights/QualityIcon.js';
import type { QualityLevel } from '@/generated-api/scenes/api.js';

const useStyles = makeStyles({
  weightTransition: {
    width: 140,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  weightInput: {
    width: 50
  }
});

export type TransitionWeightInputProps = {
  input: QualityLevel;
  output: QualityLevel;
  value?: number;
  onChange?: (output: QualityLevel, input: QualityLevel, weight: number) => void;
  inputRef?: Ref<unknown>;
  name?: string;
  disabled?: boolean;
};

function TransitionWeightInput(props: TransitionWeightInputProps): JSX.Element {
  const { input, output, value, onChange, disabled, name, inputRef } = props;
  const [innerValue, setInnerValue] = useState(value || 0);

  const onChangeCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = +e.target.value;

      setInnerValue(value);
      onChange?.(output, input, value);
    },
    [input, onChange, output]
  );

  const inputProps =
    inputRef && name
      ? {
          inputRef: inputRef,
          inputProps: {
            name: name
          }
        }
      : { value: value !== undefined ? value : innerValue };

  const classes = useStyles();
  return (
    <div className={classes.weightTransition}>
      <QualityIcon quality={output} />
      <ArrowRight />
      <TextField
        type="number"
        className={classes.weightInput}
        data-cy="inputForTransitionWeight"
        onChange={onChangeCallback}
        {...inputProps}
        disabled={disabled}
      />
      <ArrowRight />
      <QualityIcon quality={input} />
    </div>
  );
}

export default TransitionWeightInput;
