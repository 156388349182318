import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
  groupsManagementLoadingStatusSelector,
  groupsSelector
} from 'app/components/GroupManagement/store/groupManagementSelectors.js';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import useStyles from './useStyles.js';
import Actions from 'app/components/GroupManagement/GroupCard/Actions/Actions.js';
import EditableGroupTitle from 'app/components/GroupManagement/GroupCard/EditableGroupTitle/EditableGroupTitle.js';
import { usersLoaded as usersLoadedeSelector } from 'app/selectors/userManagementSelectors.js';
import { LoadingStatus } from 'app/store/types.js';
import NoGroupData from 'app/components/GroupManagement/GroupCard/NoGroupData/NoGroupData.js';
import MemberList from 'app/components/GroupManagement/GroupCard/MemberList/MemberList.js';
import RightPane from 'app/components/GroupManagement/GroupCard/RightPane/RightPane.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGroupManagementNs } from '@/i18n/dictionaries/interfaces.js';

function GroupCard(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const groups = useSelector(groupsSelector);
  const loadingStatus = useSelector(groupsManagementLoadingStatusSelector);
  const isUsersLoading = !useSelector(usersLoadedeSelector);

  const group = useMemo(() => {
    return groups.find((c) => `${c.id}` == id);
  }, [id, groups]);

  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.GroupManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  if (loadingStatus !== LoadingStatus.Succeeded || !group || isUsersLoading) {
    return <NoGroupData loadingStatus={loadingStatus} isUsersLoading={isUsersLoading} />;
  }

  return (
    <Card square classes={{ root: classes.card }}>
      <EditableGroupTitle group={group} />

      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={7}>
            <Box display={'flex'} flexDirection={'column'}>
              <Actions group={group} />

              <Box mt={4}>
                <Typography variant={'h6'}>
                  {translate(nameof.full<I18nGroupManagementNs>((n) => n.members))}
                </Typography>
              </Box>

              <MemberList group={group} />
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <RightPane group={group} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default GroupCard;
