/* eslint-disable @typescript-eslint/no-namespace */
import type { CompanyDto } from '@/generated-api/index.js';
import { createAction } from 'redux-actions';
import type OnCompanyDeleteAction from '../../models/companies/onCompanyDeleteAction.js';

export namespace CompanyManagementActions {
  export const enum Type {
    GET_COMPANIES = 'GET_COMPANIES',
    SET_COMPANIES = 'SET_COMPANIES',
    SET_COMPANIES_ARE_LOADING = 'SET_COMPANIES_ARE_LOADING',
    ADD_COMPANY = 'ADD_COMPANY',
    EDIT_COMPANY = 'EDIT_COMPANY',
    DELETE_COMPANY = 'DELETE_COMPANY'
  }

  export interface CompanyDeletionActionType {
    id: number;
    deletionAction: OnCompanyDeleteAction;
    moveToCompanyId?: number;
  }

  export const setCompaniesAreLoading = createAction<boolean>(Type.SET_COMPANIES_ARE_LOADING);
  export const getCompanies = createAction<void>(Type.GET_COMPANIES);

  export const setCompanies = createAction<Array<CompanyDto>>(Type.SET_COMPANIES);
  export const addCompany = createAction<Omit<CompanyDto, 'id' | 'medicalIndexIds'>>(Type.ADD_COMPANY);
  export const editCompany = createAction<CompanyDto>(Type.EDIT_COMPANY);
  export const deleteCompany = createAction<CompanyDeletionActionType>(Type.DELETE_COMPANY);
}
