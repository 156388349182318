import type { SceneDto, VariableDto } from '@/generated-api/index.js';
import { VARIABLE_MATCH_REGEX } from 'app/components/ScenarioEditorPage/Variables/VariableSuggestionInput.js';
import type { CustomNode } from 'app/models/intents/customNode.js';

const findUnsavedVariablesInString = (text: string | undefined, variables: VariableDto[]): string[] => {
  if (!text) return [];

  const unsaved: string[] = [];
  const matches = text.match(VARIABLE_MATCH_REGEX);

  if (matches && matches.length > 0) {
    for (const match of matches) {
      const fixedMatch = match.replace('@', '');
      if (!variables.some((v) => v.name === fixedMatch)) {
        unsaved.push(fixedMatch);
      }
    }
  }

  return unsaved;
};

const updateChangedVariableNameInString = (
  text: string | undefined,
  oldVariableName: string,
  newVariableName: string
) => {
  if (!text) return text;

  // Uses negative lookahead to only find the exact variable (and not replace ex. @new2 when only @new should be renamed)
  const regexExpression = new RegExp(`@${oldVariableName}(?!\\w)`, 'g');

  return text.replaceAll(regexExpression, `@${newVariableName}`);
};

/** Finds unsaved variables in the scenario */
export const findUnsavedVariables = (scenario: SceneDto, nodes: CustomNode[], variables: VariableDto[]): string[] => {
  if (!scenario) return [];

  const unsavedVariables: string[] = [];

  // For each block/node
  for (const node of nodes ?? []) {
    // User says
    for (const userSays of node.properties.intentPhrases ?? []) {
      unsavedVariables.push(...findUnsavedVariablesInString(userSays.text, variables));
    }

    // Ai responses
    for (const aiResponse of node.properties.aiResponses ?? []) {
      unsavedVariables.push(...findUnsavedVariablesInString(aiResponse.text, variables));
    }

    // Fallback responses
    for (const fallbackResponse of node.properties.fallbackResponses ?? []) {
      unsavedVariables.push(...findUnsavedVariablesInString(fallbackResponse.text, variables));
    }
  }

  // For each scenario context
  for (const context of scenario.contexts ?? []) {
    // Locations
    for (const location of context.locations ?? []) {
      unsavedVariables.push(...findUnsavedVariablesInString(location.location, variables));
    }

    // Facts
    for (const fact of context.facts ?? []) {
      unsavedVariables.push(...findUnsavedVariablesInString(fact.text, variables));
    }
  }

  return unsavedVariables;
};

export const updateChangedVariableName = (
  scenario: SceneDto,
  nodes: CustomNode[],
  oldVariableName: string,
  newVariableName: string
) => {
  if (!scenario) return { scenario, nodes };

  for (const node of nodes ?? []) {
    // User says
    for (const userSays of node.properties.intentPhrases ?? []) {
      userSays.text = updateChangedVariableNameInString(userSays.text, oldVariableName, newVariableName);
    }

    // Ai responses
    for (const aiResponse of node.properties.aiResponses ?? []) {
      aiResponse.text = updateChangedVariableNameInString(aiResponse.text, oldVariableName, newVariableName);
    }

    // Fallback responses
    for (const fallbackResponse of node.properties.fallbackResponses ?? []) {
      fallbackResponse.text = updateChangedVariableNameInString(
        fallbackResponse.text,
        oldVariableName,
        newVariableName
      );
    }
  }

  // For each scenario context
  for (const context of scenario.contexts ?? []) {
    // Locations
    for (const location of context.locations ?? []) {
      location.location = updateChangedVariableNameInString(location.location, oldVariableName, newVariableName);
    }

    // Facts
    for (const fact of context.facts ?? []) {
      fact.text = updateChangedVariableNameInString(fact.text, oldVariableName, newVariableName);
    }
  }

  return { scenario, nodes };
};

export default findUnsavedVariables;
