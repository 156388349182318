import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

type AudioState = {
  threshold: number;
  interval: number;
  timeout: number;
  disableTimeout: boolean;
  microphoneDeviceId: string;
};

type AudioActions = {
  setThreshold: (threshold: number) => void;
  setInterval: (interval: number) => void;
  setTimeout: (timeout: number) => void;
  setDisableTimeout: (disableTimeout: boolean) => void;
  setMicrophoneDeviceId: (microphoneDeviceId: string) => void;
  reset: () => void;
};

const defaultValues: AudioState = {
  threshold: -50,
  interval: 100,
  timeout: 5000,
  disableTimeout: false,
  microphoneDeviceId: 'default'
};

const useAudioStore = create<AudioState & AudioActions>()(
  devtools(
    persist(
      (set) => ({
        ...defaultValues,
        setThreshold: (threshold) => set({ threshold }),
        setInterval: (interval) => set({ interval }),
        setTimeout: (timeout) => set({ timeout }),
        setDisableTimeout: (disableTimeout) => set({ disableTimeout }),
        setMicrophoneDeviceId: (microphoneDeviceId) => set({ microphoneDeviceId }),
        reset: () => set(defaultValues)
      }),
      { name: 'audioStore' }
    )
  )
);

export default useAudioStore;
