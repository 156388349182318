import { isNil } from 'lodash';
import { boolean, number, object, string } from 'yup';
import {
  Dialog,
  DialogTitle,
  Grid,
  Radio,
  Button,
  MenuItem,
  TextField,
  FormLabel,
  RadioGroup,
  FormControl,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';

import { ScenarioClient } from 'app/apis/api.js';
import { usePrevious } from 'app/utils/customHooks/index.js';
import { ScenarioSettingTts } from 'app/models/scenarios/scenario.js';
// import { TTSServiceOption } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TTSOptions.js';
import type { SceneContextPersonaRoleDto, SceneContextPersonaDto, Speaker } from '@/generated-api/index.js';
import { Sex, PersonaType, PersonaGender } from '@/generated-api/index.js';
import { useQuery } from '@tanstack/react-query';
import { voicesQuery } from 'app/queries/speechQueries.js';
import { useSelector } from 'react-redux';
import { ScenarioSelectors } from 'app/selectors/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const initialDefaultValues: SceneContextPersonaFormValues = {
  type: PersonaType.NUMBER_0.toString(),
  gender: PersonaGender.NUMBER_0.toString(),
  age: null,
  voiceName: null,
  ttsProvider: null,
  sceneContextPersonaRoleId: null,
  roleName: null,
  isDefault: false
};

export interface SceneContextPersonaFormValues extends Omit<SceneContextPersonaDto, 'type' | 'gender' | 'age'> {
  type: string;
  gender: string;
  age: number | null;
}

type PersonaDialogProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: SceneContextPersonaFormValues) => void;
  defaultValues: any;
  onExited: () => void;
  langCode: string;
  defaultPersonaId: number | null;
  scenarioId?: number;
};

const PersonaDialog: React.FunctionComponent<PersonaDialogProps> = ({
  open,
  onClose,
  onSubmit,
  onExited,
  langCode,
  defaultValues,
  scenarioId
}) => {
  const [personaRoles, setPersonaRoles] = useState<SceneContextPersonaRoleDto[]>([]);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const personaValidationScheme = object<SceneContextPersonaFormValues>({
    type: string().required(),
    isDefault: boolean().nullable().notRequired(),
    gender: string().required(),
    sceneContextPersonaRoleId: number().required(),
    age: number()
      .transform((value) => (isNaN(value) ? null : value))
      .nullable()
      .notRequired(),
    ttsProvider: number()
      .nullable()
      .when(
        nameof.full<SceneContextPersonaFormValues>((u) => u.type),
        (type: string, schema) => {
          if (type === PersonaType.NUMBER_0.toString()) {
            return schema.required(
              translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.thisFieldIsRequired))
            );
          }

          return schema.notRequired();
        }
      ),
    voiceName: string()
      .nullable()
      .when(
        [
          nameof.full<SceneContextPersonaFormValues>((u) => u.type),
          nameof.full<SceneContextPersonaFormValues>((u) => u.ttsProvider)
        ],
        (type, ttsProvider, schema) => {
          if (type === PersonaType.NUMBER_0.toString() && !isNil(ttsProvider)) {
            return schema.required(
              translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.thisFieldIsRequired))
            );
          }

          return schema.notRequired();
        }
      )
  });

  const { data: voiceOptionsData } = useQuery(voicesQuery);

  const scenario = useSelector(ScenarioSelectors.getScenarioById(scenarioId));
  const companyId = scenario?.companyId;

  const onEnter = useCallback(
    () =>
      ScenarioClient.scenariosGetPersonaRoles(langCode as string).then((response) => setPersonaRoles(response.data)),
    [langCode]
  );

  const { control, watch, handleSubmit, reset, setValue, formState, errors } = useForm<SceneContextPersonaFormValues>({
    defaultValues: initialDefaultValues,
    resolver: yupResolver(personaValidationScheme),
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const { type, sceneContextPersonaRoleId, ttsProvider, gender } = watch();

  const isUserTypeSelected = type?.toString() === PersonaType.NUMBER_1.toString();

  useEffect(() => {
    if (!sceneContextPersonaRoleId) return;

    const selectedRole = personaRoles.find((role) => role.id === sceneContextPersonaRoleId);

    if (!selectedRole) return;

    setValue(
      nameof.full<SceneContextPersonaFormValues>((c) => c.roleName) as keyof SceneContextPersonaFormValues,
      selectedRole.name
    );
  }, [setValue, personaRoles, sceneContextPersonaRoleId]);

  useEffect(() => {
    if (!defaultValues) return;

    const updatedValues: SceneContextPersonaFormValues = {
      ...defaultValues,
      type: defaultValues.type?.toString(),
      gender: defaultValues.gender?.toString(),
      sceneContextPersonaRoleId: defaultValues.sceneContextPersonaRoleId as number
    };

    reset(updatedValues);
  }, [reset, defaultValues]);

  // Only display voices where the gender, langcode and ttsProvider matches
  const filterTTSVoiceOptions = useCallback(
    (option: Speaker) => {
      const optionGender = (option.sex as unknown) as Sex;
      const selectedGender = parseInt(gender, 10) as PersonaGender;

      const isGenderMatching =
        (optionGender === Sex.NUMBER_0 && selectedGender === PersonaGender.NUMBER_1) ||
        (optionGender === Sex.NUMBER_1 && selectedGender === PersonaGender.NUMBER_0);

      return (
        option.languageCode === langCode &&
        option.ttsProvider == ttsProvider &&
        isGenderMatching &&
        (!option.companyId || option.companyId === companyId)
      );
    },
    [companyId, gender, langCode, ttsProvider]
  );

  const selectedTtsProvider = useWatch({
    control,
    name: nameof.full<SceneContextPersonaFormValues>((u) => u.ttsProvider)
  });

  const previousisUserTypeSelected = usePrevious(isUserTypeSelected);
  const previousSelectedTtsProvider = usePrevious(selectedTtsProvider);

  // reset selected voiceName when selecting ttsProvider
  useEffect(() => {
    if (isNil(previousSelectedTtsProvider)) return;
    if (selectedTtsProvider === previousSelectedTtsProvider) return;

    setValue(
      nameof.full<SceneContextPersonaFormValues>((u) => u.voiceName) as keyof SceneContextPersonaFormValues,
      null,
      {
        shouldValidate: true
      }
    );
  }, [setValue, selectedTtsProvider, previousSelectedTtsProvider]);

  useEffect(() => {
    if (isUserTypeSelected && !previousisUserTypeSelected) {
      setValue(
        nameof.full<SceneContextPersonaFormValues>((u) => u.isDefault) as keyof SceneContextPersonaFormValues,
        false
      );
    }
  }, [isUserTypeSelected, previousisUserTypeSelected, setValue]);

  const voiceOptions = useMemo(() => voiceOptionsData?.filter(filterTTSVoiceOptions) ?? [], [
    filterTTSVoiceOptions,
    voiceOptionsData
  ]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      TransitionProps={{
        onExited: () => {
          onExited();
          reset(initialDefaultValues);
        },
        onEnter
      }}
    >
      <DialogTitle>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.personaSettings))}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              name={nameof.full<SceneContextPersonaFormValues>((c) => c.type)}
              control={control}
              render={(field) => (
                <FormControl error={!!errors.type}>
                  <FormLabel>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.type))}</FormLabel>
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value={PersonaType.NUMBER_0.toString()}
                      control={<Radio color="primary" />}
                      label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.ai))}
                    />
                    <FormControlLabel
                      value={PersonaType.NUMBER_1.toString()}
                      control={<Radio color="primary" />}
                      label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.user))}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              disabled={isUserTypeSelected}
              name={nameof.full<SceneContextPersonaFormValues>((c) => c.gender)}
              control={control}
              render={(field) => (
                <FormControl error={!!errors.gender}>
                  <FormLabel>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.gender))}</FormLabel>
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value={PersonaGender.NUMBER_0.toString()}
                      control={<Radio color="primary" />}
                      label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.male))}
                    />
                    <FormControlLabel
                      value={PersonaGender.NUMBER_1.toString()}
                      control={<Radio color="primary" />}
                      label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.female))}
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={nameof.full<SceneContextPersonaFormValues>((c) => c.sceneContextPersonaRoleId)}
              as={TextField}
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.role))}
              select
              control={control}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              error={!!errors.sceneContextPersonaRoleId}
            >
              {personaRoles.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              ))}
            </Controller>
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={TextField}
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.age))}
              control={control}
              fullWidth
              type="number"
              disabled={isUserTypeSelected}
              name={nameof.full<SceneContextPersonaFormValues>((c) => c.age)}
              error={!!errors.age}
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              disabled={isUserTypeSelected}
              name={nameof.full<SceneContextPersonaFormValues>((c) => c.ttsProvider)}
              as={TextField}
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.textToSpeechProvider))}
              select
              control={control}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              error={!!errors.ttsProvider}
            >
              <MenuItem value={ScenarioSettingTts.Microsoft}>Microsoft</MenuItem>
              <MenuItem value={ScenarioSettingTts.Google}>Google</MenuItem>
              <MenuItem value={ScenarioSettingTts.ElevenLabs}>Eleven Labs</MenuItem>
              <MenuItem value={ScenarioSettingTts.Deepgram}>Deepgram</MenuItem>
              <MenuItem value={ScenarioSettingTts.InHouse}>Sklls</MenuItem>
            </Controller>
          </Grid>
          <Grid item xs={6}>
            <Controller
              disabled={isUserTypeSelected || isNil(ttsProvider)}
              name={nameof.full<SceneContextPersonaFormValues>((c) => c.voiceName)}
              as={TextField}
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.voice))}
              select
              // value={JSON.stringify(voiceAndId)}
              control={control}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              margin="normal"
              error={!!errors.voiceName}
            >
              {voiceOptions.map((option, index) => (
                <MenuItem key={index} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </Controller>
          </Grid>
        </Grid>
        <Controller
          control={control}
          render={() => <input type="hidden" />}
          name={nameof.full<SceneContextPersonaFormValues>((c) => c.id)}
        />
        <Controller
          name={nameof.full<SceneContextPersonaFormValues>((c) => c.roleName)}
          control={control}
          render={() => <input type="hidden" />}
        />
        <Controller
          control={control}
          name={nameof.full<SceneContextPersonaFormValues>((c) => c.isDefault)}
          render={(field) => {
            if (isUserTypeSelected) return null;

            return (
              <FormControl margin="normal">
                <FormControlLabel
                  label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.useAsDefault))}
                  control={
                    <Checkbox
                      {...field}
                      checked={!!field.value}
                      onChange={(event) => field.onChange(event.target.checked)}
                    />
                  }
                />
              </FormControl>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit((values) => {
            if (values.type === PersonaType.NUMBER_0.toString()) {
              // AI type
              onSubmit({ ...values });
            } else {
              onSubmit({ ...values });
            }
          })}
          disabled={!!Object.keys(errors).length || !formState.isValid}
        >
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.personaDialog.save))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonaDialog;
