import * as yup from 'yup';
import type { CreateScheduledCallCommand as Model } from '@/generated-api/index.js';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScheduledCallsNs } from '@/i18n/dictionaries/interfaces.js';

export const phoneRegExp = /^\+[0-9]{1,4}[0-9]{1,14}(#[0-9]{1,6})?$/;

const useScheduledCallSchema = () => {
  const [translate] = useTranslation([I18nNamespace.ScheduledCalls]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return yup.object<Model>({
    sceneId: yup
      .number()
      .moreThan(0, translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.thisFieldIsRequired)))
      .required(translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.thisFieldIsRequired))),
    userId: yup
      .number()
      .transform((value) => (value === 'custom' ? null : value))
      .required(translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.youMustSelectaUser))),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, translate(nameof.full<I18nScheduledCallsNs>((n) => n.validation.phoneNumberMissing))),
    scheduledTime: yup
      .string()
      .required()
      .transform((value) => new Date(value).toISOString())
  });
};

export default useScheduledCallSchema;
