import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import { MenuItem, TextField, Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';

import { LoadingStatus } from 'app/store/types.js';
import LoadingButton from 'app/components/LoadingButton.js';
import * as validationSchemas from 'app/components/CourseManagement/CourseCard/validationSchema.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import type {
  CourseDto,
  UserGroupDto,
  ScheduledCourseDto,
  ScheduledCourseAssignmentCommand
} from '@/generated-api/index.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

export type CreateScheduledCourseUserGroupDialogProps = {
  course: CourseDto;
  isOpen: boolean;
  courseId: string;
  onClose: () => void;
  scheduledCourse: ScheduledCourseDto;
};

export const NO_USER_GROUP_SELECTED = 0;

const CreateScheduledCourseUserGroupDialog = ({
  course,
  isOpen,
  onClose,
  scheduledCourse
}: CreateScheduledCourseUserGroupDialogProps): JSX.Element => {
  const dispatch = useDispatch();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const [userGroups, setUserGroups] = useState<UserGroupDto[]>(null);

  const { control, handleSubmit, reset, formState } = useForm<ScheduledCourseAssignmentCommand>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchemas.createScheduledCourseUser),
    defaultValues: {
      id: NO_USER_GROUP_SELECTED
    }
  });

  const requestAvailableUserGroups = useCallback(
    () =>
      dispatch(
        CourseManagementActions.requestScheduledCourseAvailableUserGroups(course.id, scheduledCourse.id, setUserGroups)
      ),
    [course, scheduledCourse, dispatch]
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) =>
        dispatch(CourseManagementActions.createScheduledCourseUserGroup(course.id, scheduledCourse.id, data, onClose))
      ),
    [course, scheduledCourse, dispatch, handleSubmit, onClose]
  );

  const onExited = useCallback(() => {
    reset();
    setUserGroups(null);
  }, [reset]);

  const loadingStatus = useSelector(CourseManagementSelectors.createScheduledCourseUserGroupLoadingStatus);

  const isUserGroupsReceived = !!userGroups;
  const isUserGroupsEmpty = isUserGroupsReceived && !userGroups.length;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      TransitionProps={{
        onExited,
        onEnter: () => requestAvailableUserGroups()
      }}
    >
      <DialogTitle>{translate(nameof.full<I18nCourseManagementNs>((n) => n.groups.addGroup))}</DialogTitle>
      <DialogContent>
        <form id="create-scheduled-course-user-group-form" onSubmit={onSubmit}>
          <Controller
            select
            required
            fullWidth
            type="text"
            as={TextField}
            margin="normal"
            control={control}
            label={translate(nameof.full<I18nCourseManagementNs>((n) => n.groups.selectGroup))}
            disabled={!isUserGroupsReceived || isUserGroupsEmpty}
            name={nameof.full<ScheduledCourseAssignmentCommand>((c) => c.id)}
          >
            {isUserGroupsReceived && isUserGroupsEmpty && (
              <MenuItem value={NO_USER_GROUP_SELECTED}>
                {translate(nameof.full<I18nCourseManagementNs>((n) => n.groups.noGroupsAvailable))}
              </MenuItem>
            )}
            {isUserGroupsReceived &&
              userGroups.map((userGroup: UserGroupDto) => (
                <MenuItem key={userGroup.id} value={userGroup.id}>
                  {userGroup.title}
                </MenuItem>
              ))}
          </Controller>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loadingStatus === LoadingStatus.Loading}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formState.isValid}
          loadingStatus={loadingStatus}
          form="create-scheduled-course-user-group-form"
        >
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.add))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateScheduledCourseUserGroupDialog;
