import React, { useLayoutEffect, useState } from 'react';
import type { CompanyDto, MedicalProtocolDto } from '@/generated-api/index.js';
import { Popover } from '@material-ui/core';
import ConnectWithCompany from 'app/components/MedicalIndexesManager/MedIndexCard/RightPane/CompanyAssignments/ConnectWithCompany.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import UsedByCompany from 'app/components/MedicalIndexesManager/MedIndexCard/RightPane/CompanyAssignments/UsedByCompany.js';

const useStyles = makeStyles((theme) => {
  const height = 48;

  return {
    root: {
      maxHeight: (height + theme.spacing(2)) * 3,
      overflowY: 'auto'
    }
  };
});

export type UsedByCompanyEditorPopoverProps = {
  medIndex: MedicalProtocolDto;
  disabled: boolean;
  open: boolean;
  onClose: () => void;
  containerRef: React.RefObject<Element>;
  anchorEl: Element;
  companies: CompanyDto[];
  assignedCompanies: CompanyDto[];
};

function UsedByCompanyEditorPopover({
  medIndex,
  disabled,
  containerRef,
  open,
  onClose,
  anchorEl,
  companies,
  assignedCompanies
}: UsedByCompanyEditorPopoverProps): JSX.Element {
  const [controlWidth, setControlWidth] = useState(0);

  useLayoutEffect(() => {
    const setWidth = () => {
      setControlWidth(containerRef.current?.clientWidth ?? 0);
    };

    setWidth();
    window.addEventListener('resize', setWidth);

    return () => {
      window.removeEventListener('resize', setWidth);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current?.clientWidth]);

  const classes = useStyles();

  return (
    <Popover
      PaperProps={{
        style: { width: controlWidth }
      }}
      onClose={onClose}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      container={containerRef.current}
    >
      <div className={classes.root}>
        {assignedCompanies.map((c) => (
          <UsedByCompany key={c.id} medIndex={medIndex} company={c} disabled={disabled} />
        ))}
      </div>

      <ConnectWithCompany
        medIndex={medIndex}
        disabled={disabled}
        companies={companies}
        assignedCompanies={assignedCompanies}
      />
    </Popover>
  );
}

export default UsedByCompanyEditorPopover;
