import { makeStyles } from '@material-ui/core/styles/index.js';
import type { CompanyDto, MedicalProtocolDto } from '@/generated-api/index.js';
import { Button, Grid, MenuItem, TextField } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { MedicalIndexClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import { CompanyManagementActions } from 'app/actions/companyManagment/companyManagementActions.js';
import _ from 'lodash';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

const { getCompanies } = CompanyManagementActions;

const useAddCompanyClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 1, 1)
  }
}));

type ConnectWithCompanyProps = {
  medIndex: MedicalProtocolDto;
  disabled: boolean;
  companies: CompanyDto[];
  assignedCompanies: CompanyDto[];
};

function ConnectWithCompany({
  medIndex,
  disabled,
  companies,
  assignedCompanies
}: ConnectWithCompanyProps): JSX.Element {
  const classes = useAddCompanyClasses();

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [submitState, setSubmitState] = useState({
    status: ProcessStatus.Idle,
    id: 0 // ?
  });

  const dispatch = useDispatch();

  const unmountedRef = useUnmounted();
  useEffect(() => {
    if (submitState.status !== ProcessStatus.StartProcess) {
      return;
    }

    setSubmitState((s) => ({ ...s, status: ProcessStatus.Processing }));

    const process = async () => {
      try {
        await MedicalIndexClient.medicalIndexCreateMedIndexAssignment(medIndex.id, [submitState.id]);
        dispatch(getCompanies());
      } catch (e) {
        logger.error('Some error occurred during deleting company assignment', e);
      } finally {
        if (!unmountedRef.current) {
          setSubmitState({
            id: 0,
            status: ProcessStatus.Idle
          });
        }
      }
    };

    process();
  }, [submitState, medIndex, dispatch]);

  const [id, setId] = useState(0);

  const onAdd = () => {
    setSubmitState({
      id: id,
      status: ProcessStatus.StartProcess
    });
  };

  const [availableCompanies, idsJoined] = useMemo(() => {
    const availableCompanies = _.differenceBy(companies, assignedCompanies, 'id');

    return [availableCompanies, availableCompanies.map((c) => c.id).join()];
  }, [companies, assignedCompanies]);

  useEffect(() => {
    setId(0);
  }, [idsJoined]);

  const isButtonDisabled = disabled || id === 0 || submitState.status !== ProcessStatus.Idle;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TextField
            select
            fullWidth
            value={id}
            onChange={(e) => {
              setId(Number.parseInt(e.target.value, 10));
            }}
          >
            {availableCompanies.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container item xs={3} justifyContent="flex-end">
          <Button disabled={isButtonDisabled} onClick={onAdd}>
            {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.add))}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default ConnectWithCompany;
