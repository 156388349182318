import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RecognitionSelector } from 'app/selectors/index.js';
import Timer from 'react-compound-timer';

import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';

import useStyles from './styles.js';

const SpeechTimer: React.FC<{ paused: boolean }> = ({ children, paused }) => {
  const classes = useStyles();
  const [time, setTime] = useState(0);

  const recognitionstatus = useSelector(RecognitionSelector.recognitionStatusSelector);
  //ref usage caused by https://github.com/volkov97/react-compound-timer/issues/29
  const timerRef = useRef(null);

  useEffect(() => {
    if (recognitionstatus === VoiceRecognitionStatus.UserStoppedSpeaking) {
      setTime(0);
    }
  }, [recognitionstatus]);

  useEffect(() => {
    if (paused) {
      timerRef.current?.pause();
    } else if (recognitionstatus !== VoiceRecognitionStatus.UserStoppedSpeaking) {
      timerRef.current?.resume();
    }
  }, [paused, recognitionstatus]);

  return (
    <div className={classes.default}>
      {recognitionstatus !== VoiceRecognitionStatus.UserStoppedSpeaking && (
        <div>
          <Timer ref={timerRef} initialTime={time} formatValue={(value) => `${value < 10 ? `0${value}` : value} `}>
            <Timer.Minutes />: <Timer.Seconds />
          </Timer>
        </div>
      )}
    </div>
  );
};
export default SpeechTimer;
