import { createContext } from 'react';
import type { HubConnection } from '@microsoft/signalr';

type WebsocketContextValue = {
  connection: HubConnection;
  startConnection: () => Promise<void>;
  stopConnection: () => Promise<void>;
  subscribe: HubConnection['on'];
};
const WebsocketContext = createContext<WebsocketContextValue>({} as WebsocketContextValue);

export default WebsocketContext;
