import React, { useCallback, useMemo } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert.js';
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemText,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import useMenu from 'app/hooks/useMenu.js';
import type { ITableData } from 'app/components/DashboardPage/Table/models/models.js';
import type { DialogStatisticDto } from '@/generated-api/index.js';
import { useSelector } from 'react-redux';
import { isUserAnyAdminSelector } from 'app/selectors/userSelectors.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import { useHistory, useRouteMatch } from 'react-router';
import { useDialogStatisticMutations } from 'app/mutations/dialogStatisticMutations.js';
import TranscriptDialog from 'app/components/DashboardPage/Transcript/TranscriptDialog.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

export interface DashboardTableMenuProps {
  dialogStatistic: ITableData;
  handleDisplayKpiDetails: (dialogStatistic: DialogStatisticDto) => void;
  refreshStatistics: () => void;
}

const DashboardTableMenu: React.FC<DashboardTableMenuProps> = ({
  dialogStatistic,
  handleDisplayKpiDetails,
  refreshStatistics
}) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const isAdmin = useSelector(isUserAnyAdminSelector);
  const history = useHistory();

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [isConfirmOpen, openConfirm, closeConfirm] = useBoolState(false);
  const [isTranscriptOpen, openTranscript, closeTranscript] = useBoolState(false);

  const statisticsRouteMatch = useRouteMatch<{ id: string | undefined }>({
    path: '/dashboard/:id',
    strict: false,
    exact: true
  });

  const dialogStatisticId = useMemo(
    () => (!!statisticsRouteMatch?.params?.id ? Number(statisticsRouteMatch.params.id) : undefined),
    [statisticsRouteMatch]
  );

  const { deleteDialogStatistic } = useDialogStatisticMutations(dialogStatisticId);

  const onDelete = useCallback(async () => {
    await deleteDialogStatistic.mutateAsync(Number(dialogStatistic.id));
    closeConfirm();
    refreshStatistics();

    if (dialogStatisticId) {
      // Move to /dashboard
      history.push('/dashboard');
    }
  }, [closeConfirm, deleteDialogStatistic, dialogStatistic.id, dialogStatisticId, history, refreshStatistics]);

  return (
    <>
      <IconButton
        size="small"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          handleClick(event);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();

          handleClose();
        }}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() =>
            handleDisplayKpiDetails({
              id: parseInt(dialogStatistic.id),
              companyName: dialogStatistic.companyName,
              sceneName: dialogStatistic.startSceneName,
              start: dialogStatistic.start?.toString(),
              end: dialogStatistic.end?.toString(),
              reachTimeScores: dialogStatistic.reachTimeScores,
              reachTimeScoresMax: dialogStatistic.reachTimeScoresMax,
              transitionScores: dialogStatistic.transitionScores,
              procedureScores: dialogStatistic.procedureScores,
              procedureScoresMax: dialogStatistic.procedureScoresMax,
              respondent: {
                userName: dialogStatistic.userName
              }
            })
          }
        >
          <ListItemText primary={translate(nameof.full<I18nDashboardNs>((n) => n.dashboardTableMenu.viewDetails))} />
        </MenuItem>
        <MenuItem onClick={openTranscript}>
          <ListItemText primary={translate(nameof.full<I18nDashboardNs>((n) => n.dashboardTableMenu.getTranscript))} />
        </MenuItem>
        {isAdmin ? (
          <MenuItem onClick={openConfirm}>
            <ListItemText primary={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))} />
          </MenuItem>
        ) : null}
      </Menu>
      {isAdmin ? (
        <Dialog open={isConfirmOpen} onClose={closeConfirm}>
          <DialogTitle>
            {translate(nameof.full<I18nDashboardNs>((n) => n.dashboardTableMenu.confirmDelete))}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {translate(nameof.full<I18nDashboardNs>((n) => n.dashboardTableMenu.confirmationText))}
              <br />
              <b>{translate(nameof.full<I18nDashboardNs>((n) => n.dashboardTableMenu.thisIsDestructive))}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeConfirm} color="secondary">
              {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
            </Button>
            <Button onClick={onDelete} disabled={deleteDialogStatistic.isLoading} color="primary">
              {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {/* Render the TranscriptDialog component */}
      <TranscriptDialog
        open={isTranscriptOpen}
        onClose={closeTranscript}
        dialogStatisticId={Number(dialogStatistic.id)}
      />
    </>
  );
};

export default DashboardTableMenu;