import React, { useCallback } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete.js';
import { useBoolState, useUnmounted } from 'app/utils/customHooks/index.js';
import AppRoutes from 'app/utils/routes.js';
import { useHistory } from 'react-router-dom';
import DeleteCategoryDialog from 'app/components/GlobalAssetsLibrary/CategoryCard/Actions/DeleteCategoryDialog.js';
import useDeleteCategory from 'app/components/GlobalAssetsLibrary/CategoryCard/Actions/hooks/useDeleteCategory.js';
import type { GlobalAssetFullDto } from '@/generated-api/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

export type ActionsProps = {
  category: GlobalAssetFullDto;
};

function Actions({ category }: ActionsProps): JSX.Element {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBoolState(false);

  const history = useHistory();

  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const unmountedRef = useUnmounted();

  const [deleteCategory, isDeleting] = useDeleteCategory(
    useCallback(() => {
      if (!unmountedRef.current) {
        closeDeleteDialog();
      }
      history.replace(AppRoutes.GlobalAssetsLibrary);
    }, [closeDeleteDialog, history, unmountedRef]),
    category?.id
  );

  return (
    <Box display={'flex'}>
      <Box ml={'auto'} mr={2}>
        <Button startIcon={<DeleteIcon />} variant={'contained'} onClick={openDeleteDialog} disabled={isDeleting}>
          {isDeleting ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))
          )}
        </Button>
      </Box>

      <DeleteCategoryDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        category={category}
        deleteCategory={deleteCategory}
        isDeleting={isDeleting}
      />
    </Box>
  );
}

export default Actions;
