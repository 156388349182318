import type { UserGroupDto } from '@/generated-api/index.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useUnmounted } from '../../../../../utils/customHooks/useUnmounted.js';
import { UserGroupClient } from 'app/apis/api.js';
import logger from 'app/utils/logger.js';

enum ProcessStatus {
  StartProcess,
  Processing,
  Idle
}

type SubmitState = {
  id: UserGroupDto['id'];
  status: ProcessStatus;
  onDeleteFinished?: () => void;
};

function useDeleteGroup() {
  const [submitState, setSubmitState] = useState<SubmitState>({
    status: ProcessStatus.Idle,
    id: null
  });

  const deleteGroup = useCallback((id: UserGroupDto['id'], onDeleteFinished?: () => void) => {
    setSubmitState({
      id: id,
      onDeleteFinished: onDeleteFinished,
      status: ProcessStatus.StartProcess
    });
  }, []);

  const unmountedRef = useUnmounted();
  const processingRef = useRef<SubmitState>(null);

  useEffect(() => {
    if (submitState.status !== ProcessStatus.StartProcess) {
      return;
    }

    if (submitState.id == null) {
      return;
    }

    setSubmitState((s) => ({
      ...s,
      status: ProcessStatus.Processing
    }));

    processingRef.current = submitState;

    const submit = async () => {
      try {
        await UserGroupClient.userGroupDeleteGroups({
          ids: [submitState.id]
        });
        if (processingRef.current === submitState) {
          submitState?.onDeleteFinished();
        }
      } catch (e) {
        logger.error(e);
      } finally {
        if (!unmountedRef.current && processingRef.current === submitState) {
          setSubmitState({
            id: null,
            status: ProcessStatus.Idle
          });
        }
      }
    };

    submit();
  }, [submitState, unmountedRef]);

  return [deleteGroup, submitState.status === ProcessStatus.Processing] as const;
}

export default useDeleteGroup;
