import { useCallback, useEffect, useState } from 'react';

import { useBoolState } from 'app/utils/customHooks/index.js';

export type DeleteItemState<T> = {
  selectedItem: T;
  resetSelectedItem: () => void;
  onSelectItem: (item: T) => void;
  isConfirmationDialogOpen: boolean;
  closeConfirmationDialog: () => void;
};

const useDelete = <T>(): DeleteItemState<T> => {
  const [selectedItem, setSelectedItem] = useState<T>(null);

  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useBoolState();

  const onSelectItem = useCallback(
    (item: T) => {
      closeConfirmationDialog();
      setSelectedItem(item);
      openConfirmationDialog();
    },
    [openConfirmationDialog, closeConfirmationDialog, setSelectedItem]
  );

  const resetSelectedItem = useCallback(() => {
    setSelectedItem(null);
  }, [setSelectedItem]);

  useEffect(() => {
    return () => {
      resetSelectedItem();
      closeConfirmationDialog();
    };
  }, [resetSelectedItem, closeConfirmationDialog]);

  return {
    onSelectItem,
    selectedItem,
    resetSelectedItem,
    closeConfirmationDialog,
    isConfirmationDialogOpen
  };
};

export default useDelete;
