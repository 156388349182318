import React, { useEffect } from 'react';
import type { GlobalAssetCategoryDto } from '@/generated-api/index.js';
import EditableCardTitle from 'app/components/CourseManagement/EditableCardTitle/EditableCardTitle.js';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { GlobalAssetsClient } from 'app/apis/api.js';
import { useDispatch, useSelector } from 'react-redux';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import logger from 'app/utils/logger.js';

const { actions } = globalAssetsLibrarySlice;

const schema = object<Partial<GlobalAssetCategoryDto>>({
  title: string().trim().required('This field is required')
});

export type EditableTitleProps = {
  category: GlobalAssetCategoryDto;
};

const nameFieldKey = nameof.full<GlobalAssetCategoryDto>((m) => m.title);

function EditableTitle({ category }: EditableTitleProps): JSX.Element {
  const { errors, reset, getValues, control, handleSubmit } = useForm<GlobalAssetCategoryDto>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      title: category.title
    }
  });

  const dispatch = useDispatch();
  const onSubmit = handleSubmit(
    async (data) => {
      if (!category) {
        return;
      }

      try {
        await GlobalAssetsClient.globalAssetsUpdateCategory(category.id, data);
        dispatch(actions.categoryUpdated({ id: category.id, title: data.title }));
      } catch (e) {
        logger.error(e);
      }
    },
    (data) => {
      logger.error('An error occurred updating category title', data);
    }
  );

  useEffect(() => {
    if (category && getValues(nameFieldKey) !== category?.title) {
      reset({
        title: category.title
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category?.title]);

  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);
  const disableEdit = !isUserSuperAdmin && !category.companyId;

  return (
    <Controller
      control={control}
      name={nameFieldKey}
      render={({ value, onChange, onBlur }) => {
        return (
          <EditableCardTitle
            disable={disableEdit}
            value={value}
            onChange={onChange}
            onBlur={() => {
              onBlur();
              onSubmit();
            }}
            error={errors.title?.message}
          />
        );
      }}
    />
  );
}

export default EditableTitle;
