import type { ReactNode } from 'react';
import React, { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { companiesAreLoadingSelector, companiesSelector } from 'app/selectors/companiesSelector.js';
import type { MedicalProtocolDto } from '@/generated-api/index.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import CompanyAssignmentEditorPopover from 'app/components/MedicalIndexesManager/MedIndexCard/RightPane/CompanyAssignments/UsedByCompanyEditorPopover.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

export type UsedByCompaniesEditorProps = {
  displayModeClassName?: string;
  medIndex: MedicalProtocolDto | undefined;
  containerRef: React.RefObject<Element>;
  title: ReactNode;
  className?: string;
};

const useStyles = makeStyles((theme) => {
  return {
    viewMode: {
      outline: 'none',
      '&:focus': {
        background: theme.palette.action.hover
      }
    },

    viewModeFocused: {
      background: theme.palette.action.hover
    },

    '@keyframes pulse': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)'
      },

      '70%': {
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0)'
      },

      '100%': {
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
      }
    },

    notAssigned: {
      borderRadius: 2,
      margin: '0em 0.5em 0.5em -0.5em',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
      animation: '$pulse 2s infinite'
    }
  };
});

function UsedByCompaniesEditor({ title, medIndex, containerRef, className }: UsedByCompaniesEditorProps): JSX.Element {
  const isCompaniesLoading = useSelector(companiesAreLoadingSelector);
  const companies = useSelector(companiesSelector);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);

  const isLoading = isCompaniesLoading || !medIndex || !companies.length;

  const assignedCompanies = useMemo(() => {
    return companies.filter((c) => c.medicalProtocols.some((mp) => mp.id === medIndex.id));
  }, [companies, medIndex?.id]);

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [open, setOpen] = useState(false);
  const onClick = () => {
    setOpen((o) => !o);
  };

  const onBlur = () => {
    if (open) {
      return;
    }
  };

  const onPopoverClose = () => {
    setOpen(false);
  };

  const valueToDisplay = isLoading
    ? 'Loading...'
    : assignedCompanies.length
    ? assignedCompanies.map((c) => c.name).join(', ')
    : translate(nameof.full<I18nProtocolReferencesNs>((n) => n.notAssigned));

  const captionRef = useRef(null);
  const classes = useStyles();

  return (
    <>
      {title}
      <div
        ref={captionRef}
        onBlur={onBlur}
        onClick={onClick}
        className={clsx(
          classes.viewMode,
          {
            [classes.notAssigned]: assignedCompanies.length == 0
          },
          {
            [classes.viewModeFocused]: open
          },
          className
        )}
        tabIndex={0}
      >
        <Typography noWrap>{valueToDisplay}</Typography>
      </div>
      {isSuperAdmin && (
        <CompanyAssignmentEditorPopover
          medIndex={medIndex}
          disabled={isLoading}
          open={open}
          anchorEl={captionRef.current}
          containerRef={containerRef}
          onClose={onPopoverClose}
          companies={companies}
          assignedCompanies={assignedCompanies}
        />
      )}
    </>
  );
}

export default UsedByCompaniesEditor;
