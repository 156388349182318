import React from 'react';
import { Typography, Button, Link, Grid } from '@material-ui/core';

import useStyles from './styles.js';

interface FallbackPageProps {
  text?: string;
  title?: string;
  redirectUrl?: string;
}

const FallbackPage: React.FC<FallbackPageProps> = ({
  redirectUrl = '/',
  text = 'Page not found',
  title = 'An error has occurred'
}) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <Grid container direction="column" alignItems="center">
        <Typography className={classes.title} variant="h4">
          {title}
        </Typography>
        {text && (
          <Typography className={classes.title} variant="subtitle1">
            <em>{text}</em>
          </Typography>
        )}
        {redirectUrl && (
          <Grid className={classes.buttonContainer}>
            <Link href={redirectUrl}>
              <Button variant="contained">Go home</Button>
            </Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FallbackPage;
