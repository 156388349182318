import { createAction } from 'redux-actions';
import type ScenarioDetails from '../../models/scenarios/scenarioDetails.js';
import type { LinkedScenario, ICurrentScenario } from '../../models/intents/intentNodeProperties.js';
import type { UserSaysPhrases, UserSaysPhrasesRequest } from 'app/models/scenarios/scenario.js';
import { Location } from 'history';
import type { CopyScenarioCommand, SceneDto, SceneExtendedDto } from '@/generated-api/index.js';

export enum Type {
  DELETE_SCENARIO = 'DELETE_SCENARIO',
  GET_SCENARIOS = 'GET_SCENARIOS',
  SET_SCENARIOS = 'SET_SCENARIOS',
  SET_SCENARIOS_LOADING = 'SET_SCENARIOS_LOADING',
  GET_DETAILED_SCENARIO = 'GET_DETAILED_SCENARIO',
  GET_DETAILED_SCENARIO_DATA_BY_SCENARIO_ID = 'GET_DETAILED_SCENARIO_DATA_BY_SCENARIO_ID',
  SET_DETAILED_SCENARIO_DATA_BY_SCENARIO_ID = 'SET_DETAILED_SCENARIO_DATA_BY_SCENARIO_ID',
  SAVE_DETAILED_SCENARIO = 'SAVE_DETAILED_SCENARIO',
  SET_SCENARIO_HAS_NOT_BEEN_MODIFIED = 'SET_SCENARIO_HAS_NOT_BEEN_MODIFIED',
  SET_DETAILED_SCENARIO_LOADING = 'SET_DETAILED_SCENARIO_LOADING',
  SET_DETAILED_SCENARIO_IS_OPENING = 'SET_DETAILED_SCENARIO_IS_OPENING',
  ADD_SCENARIO = 'ADD_SCENARIO',
  UPDATE_SCENARIO_DETAILS = 'UPDATE_SCENARIO_DETAILS',
  COPY_SCENARIO = 'COPY_SCENARIO',
  SET_IS_DETAILED_SCENARIO_UPDATE = 'SET_IS_DETAILED_SCENARIO_UPDATE',
  SET_IS_CURRENT_SCENARIO_UPDATING = 'SET_IS_CURRENT_SCENARIO_UPDATING',
  SET_PARENT_SCENARIO = 'SET_PARENT_SCENARIO',
  SET_ROOT_SCENARIO = 'SET_ROOT_SCENARIO',
  SET_CURRENT_SCENARIO = 'SET_CURRENT_SCENARIO',
  SET_CURRENT_SCENARIO_SAVE_STATUS = 'SET_CURRENT_SCENARIO_SAVE_STATUS',
  SET_LINKED_SCENARIOS = 'SET_LINKED_SCENARIOS',
  SET_CURRENT_TAB_SELECTED = 'SET_CURRENT_TAB_SELECTED',
  SET_LINKS = 'SET_LINKS',
  GO_BACK_TO_PARENT_SCENARIO = 'GO_BACK_TO_PARENT_SCENARIO',
  INIT_TOOLBAR = 'INIT_TOOLBAR',
  SWITCH_CURRENT_SCENARIO_TO = 'SWITCH_CURRENT_SCENARIO_TO',
  TO_NEXT_LINKED_SCENARIO = 'TO_NEXT_LINKED_SCENARIO',
  RESTORE_SCENARIO = 'RESTORE_SCENARIO',
  SET_IS_STREAMING_ENABLED_FOR_SCENARIO = "SET_IS_STREAMING_ENABLED_FOR_SCENARIO"
}

export interface GetDetailedScenarioPayload {
  sceneId: number;
  chartDOMWidth: number;
  chartDOMHeight: number;
}

// export interface CopyScenarioPayload {
//   id: number;
//   newName: string;
//   companyId?: number;
// }

export const deleteScenario = createAction<{ id: string }, { navigationAction: () => void }>(
  Type.DELETE_SCENARIO,
  (sceneId) => sceneId,
  (payload, metaData) => metaData
);
export const setScenarios = createAction<Array<SceneExtendedDto>>(Type.SET_SCENARIOS);
export const setIsCurrentScenarioUpdate = createAction<boolean>(Type.SET_IS_DETAILED_SCENARIO_UPDATE);
export const setIsCurrentScenarioUpdating = createAction<boolean>(Type.SET_IS_CURRENT_SCENARIO_UPDATING);
export const setScenariosLoading = createAction<boolean>(Type.SET_SCENARIOS_LOADING);
export const getDetailedScenario = createAction<GetDetailedScenarioPayload>(Type.GET_DETAILED_SCENARIO);
export const getDetailedScenarioDataByScenarioId = createAction<UserSaysPhrasesRequest>(
  Type.GET_DETAILED_SCENARIO_DATA_BY_SCENARIO_ID
);
export const setDetailedScenarioDataByScenarioId = createAction<UserSaysPhrases>(
  Type.SET_DETAILED_SCENARIO_DATA_BY_SCENARIO_ID
);
export const setIsScenarioOpening = createAction<boolean>(Type.SET_DETAILED_SCENARIO_IS_OPENING);
export const setDetailedScenarioLoading = createAction<boolean>(Type.SET_DETAILED_SCENARIO_LOADING);
export const saveDetailedScenario = createAction(Type.SAVE_DETAILED_SCENARIO);
export const restoreScenario = createAction(Type.RESTORE_SCENARIO);
export const setScenarioHasNotBeenModified = createAction<boolean>(Type.SET_SCENARIO_HAS_NOT_BEEN_MODIFIED);
export const getScenarios = createAction(Type.GET_SCENARIOS);
export const addScenario = createAction<ScenarioDetails>(Type.ADD_SCENARIO);
export const updateScenarioDetails = createAction<ScenarioDetails>(Type.UPDATE_SCENARIO_DETAILS);
export const copyScenario = createAction<CopyScenarioCommand>(Type.COPY_SCENARIO);
export const setRootScenario = createAction<SceneDto | undefined>(Type.SET_ROOT_SCENARIO);
export const setParentScenario = createAction<Array<LinkedScenario> | undefined>(Type.SET_PARENT_SCENARIO);
export const setCurrentScenario = createAction<ICurrentScenario | undefined>(Type.SET_CURRENT_SCENARIO);
export const setLinkedtScenarios = createAction<Array<LinkedScenario>>(Type.SET_LINKED_SCENARIOS);
export const setCurrentTabSelected = createAction<number>(Type.SET_CURRENT_TAB_SELECTED);
export const setLinks = createAction<number>(Type.SET_LINKS);
export const goBackToParentScenario = createAction(Type.GO_BACK_TO_PARENT_SCENARIO);
export const initToolBar = createAction<number>(Type.INIT_TOOLBAR);
export const switchCurrentScenarioTo = createAction<number>(Type.SWITCH_CURRENT_SCENARIO_TO);
export const toNextLinkedScenario = createAction<boolean>(Type.TO_NEXT_LINKED_SCENARIO);
export const setCurrentScenarioSaveStatus = createAction<boolean>(Type.SET_CURRENT_SCENARIO_SAVE_STATUS);
export const setIsStreamingEnabledForScenario = createAction<boolean>(Type.SET_IS_STREAMING_ENABLED_FOR_SCENARIO);
