import type { UseQueryOptions } from '@tanstack/react-query';
import { VoicesClient } from 'app/apis/api.js';

/** Get a voice's audio data */
export const getVoiceAudioQuery = (voiceId: string | undefined): UseQueryOptions<string> => ({
  queryKey: ['voicesDataQuery', voiceId],
  queryFn: async () => {
    const { data } = await VoicesClient.voicesGetVoice(voiceId);
    return `data:audio/wav;base64, ${data}`;
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  enabled: !!voiceId,
  staleTime: 5 * 60 * 1000
  // set long cache time
});
