import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';

export default makeStyles((theme: Theme) =>
  createStyles({
    circleInner: {
      height: 40,
      width: 40
    },
    marginTop: {
      marginTop: 16
    },

    addBtn: {
      color: '#409eff'
    },
    iconMWidth24: {
      minWidth: 24
    },
    paddingRight10: {
      paddingRight: 10
    }
  })
);
