import React, { useEffect } from 'react';
import type { GlobalAssetIntentDto } from '@/generated-api/index.js';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditableInput from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/EditableInput.js';
import { useDispatch } from 'react-redux';
import { GlobalAssetsClient } from 'app/apis/api.js';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

const { actions } = globalAssetsLibrarySlice;

const nameFieldKey = nameof.full<GlobalAssetIntentDto>((t) => t.title);


export type EditableTemplateTitleProps = {
  template: GlobalAssetIntentDto;
  disable: boolean;
};

function EditableTemplateTitle({ template, disable }: EditableTemplateTitleProps): JSX.Element {

  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const schema = yup.object({
    [nameFieldKey]: yup
      .string()
      .required(translate(nameof.full<I18nGlobalAssetsNs>((n) => n.editableTemplateTitle.thisFieldIsRequired)))
  });

  const { errors, reset, control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      title: template?.title
    }
  });
  
  useEffect(() => {
    if (!template) {
      return;
    }

    reset({ title: template.title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const dispatch = useDispatch();
  const onSubmit = handleSubmit(
    async ({ title }) => {
      if (!template || template.title === title) {
        return;
      }

      try {
        const { data } = await GlobalAssetsClient.globalAssetsUpdateIntent(template.id, {
          title
        });

        dispatch(actions.templateUpdated(data));
      } catch (e) {
        logger.error(e);
      }
    },
    (data) => {
      logger.error(translate(nameof.full<I18nGlobalAssetsNs>((n) => n.editableTemplateTitle.anErrorOccurred)), data);
    }
  );

  return (
    <Controller
      control={control}
      name={nameFieldKey}
      render={({ onBlur, ...rest }) => (
        <EditableInput
          disable={disable}
          typographyProps={{ variant: 'h5' }}
          {...rest}
          onBlur={() => {
            onBlur();
            onSubmit();
          }}
          error={errors[nameFieldKey]?.message}
        />
      )}
    />
  );
}

export default EditableTemplateTitle;
