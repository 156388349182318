import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField } from '@material-ui/core';

import { IntentEditingActions } from 'app/actions/scenarios/intentEditingAction.js';
import { useDispatch } from 'react-redux';
import type { CustomNode } from 'app/models/intents/customNode.js';
import _ from 'lodash';
import changeReachTime = IntentEditingActions.changeReachTime;
import ChangeReactTimePayload = IntentEditingActions.ChangeReactTimePayload;
import { ExpandMore } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

export type IntentReachTimeProps = {
  intent?: CustomNode;
  disabled?: boolean;
};

type InnerState = {
  global: number;
};

function IntentReachTime({ intent, disabled }: IntentReachTimeProps): JSX.Element {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [innerState, setInnerState] = useState<InnerState>(initializeInnerState(intent));

  useEffect(() => {
    setInnerState(initializeInnerState(intent));
  }, [intent]);

  const debouncedDispatch = useMemo(() => {
    const dispatchGlobal = _.debounce(
      (nodeId: string, value: number) => dispatch(changeReachTime({ nodeId, scope: 'global', value })),
      300
    );

    return {
      dispatchGlobal
    };
  }, [dispatch]);

  useEffect(() => {
    if (intent?.id == null) {
      return;
    }

    if (intent.properties.secondsFromDialogStartEstimate !== innerState.global) {
      debouncedDispatch.dispatchGlobal(intent.id, innerState.global);
    }
  }, [innerState, debouncedDispatch]);

  const onChange = useCallback(
    (scope: ChangeReactTimePayload['scope'], e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      let value = parseInt(e.target.value);
      value = isNaN(value) || value < 0 ? 0 : value;

      setInnerState((s) => ({
        ...s,
        [scope]: value
      }));
    },
    []
  );

  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion expanded={expanded} onChange={(e, expanded) => setExpanded(expanded)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentReachTime.intentReachTime))}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={disabled}
              value={innerState.global}
              fullWidth
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentReachTime.fromDialogStart))}
              type="number"
              onChange={(e) => onChange('global', e)}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function initializeInnerState(intent: CustomNode): InnerState {
  return {
    global: intent?.properties.secondsFromDialogStartEstimate
  };
}

export default IntentReachTime;
