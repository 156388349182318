import { useCallback, useEffect, useState } from 'react';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { GlobalAssetsClient } from 'app/apis/api.js';
import { ProcessStatus } from 'app/types/UtilityType.js';
import type { GlobalAssetFullDto } from '@/generated-api/index.js';
import { useDispatch } from 'react-redux';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import logger from 'app/utils/logger.js';

function useDeleteCategory(onDeleted: () => void, categoryId: GlobalAssetFullDto['id']) {
  const [status, setStatus] = useState(ProcessStatus.Idle);
  const unmountedRef = useUnmounted();

  const dispatch = useDispatch();

  useEffect(() => {
    if (status !== ProcessStatus.StartProcess) {
      return;
    }

    setStatus(ProcessStatus.Processing);

    const submit = async () => {
      try {
        await GlobalAssetsClient.globalAssetsDeleteCategories({ ids: [categoryId] });
        dispatch(globalAssetsLibrarySlice.actions.categoryRemoved(categoryId));
        onDeleted();
      } catch (e) {
        logger.error(e);
      } finally {
        if (!unmountedRef.current) {
          setStatus(ProcessStatus.Idle);
        }
      }
    };

    submit();
  }, [categoryId, dispatch, onDeleted, status, unmountedRef]);

  return [
    useCallback(() => {
      setStatus(ProcessStatus.StartProcess);
    }, []),
    status !== ProcessStatus.Idle
  ] as const;
}

export default useDeleteCategory;
