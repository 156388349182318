import React, { useCallback } from 'react';
import { DialogContentText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingStatus } from 'app/store/types.js';
import { UserManagementSelectors } from 'app/selectors/index.js';
import type UserManaged from 'app/models/userManagement/userManaged.js';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';

type UserDeleteDialogProps = {
  user: UserManaged;
  open: boolean;
  onClose: () => void;
  onExited: () => void;
};

const UserDeleteDialog: React.FC<UserDeleteDialogProps> = ({ user, open, onClose, onExited }) => {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const onDeleteUser = useCallback(
    (id: number) => {
      dispatch(UserManagementActions.deleteUser({ id, onSuccess: onClose }));
    },
    [onClose, dispatch]
  );

  const isLoading = !useSelector(UserManagementSelectors.usersLoaded);

  const onConfirmDeleteUser = useCallback(() => onDeleteUser(user.id), [user, onDeleteUser]);

  return (
    <DeleteConfirmationDialog
      onClose={onClose}
      title={translate(nameof.full<I18nUserManagementNs>((n) => n.labels.deleteUser))}
      confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
      onConfirm={onConfirmDeleteUser}
      loadingStatus={isLoading ? LoadingStatus.Loading : LoadingStatus.Idle}
      DialogProps={{
        open,
        TransitionProps: {
          onExited
        }
      }}
    >
      <DialogContentText>
        {translate(nameof.full<I18nUserManagementNs>((n) => n.deleteUser.confirmationText))} <b>{`${user?.name}`}</b>?{' '}
        {translate(nameof.full<I18nUserManagementNs>((n) => n.deleteUser.cannotBeUndone))}
      </DialogContentText>
    </DeleteConfirmationDialog>
  );
};

export default UserDeleteDialog;
