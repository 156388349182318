import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';

export default makeStyles(() =>
  createStyles({
    container: {
      paddingLeft: 10,
      paddingTop: 10,
      paddingRight: 10
    },
    rowContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    dateFieldLabel: {
      fontSize: 16,
      fontWeight: 600
    },
    dateFieldLabelContainer: {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center'
    },
    label: {
      marginLeft: 10
    },
    datePickerContainer: {
      marginLeft: 10
    },
    searchInput: {
      padding: `8px 8px`,
      minWidth: 300
    }
  })
);
