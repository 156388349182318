import * as DashboardActions from 'app/actions/dasboard/dashboardActions.js';
import type IntentPlayback from 'app/models/dashboard/intentPlayback.js';
import type { PartialListDialogStatisticDto } from '@/generated-api/index.js';
import type { DialogStatisticFull } from 'app/models/statistic/statistic.js';

export interface IDashboardState {
  dialog?: DialogStatisticFull;
  aggregateVoices?: Array<IntentPlayback>;
  dialogStatisticsResponse: { isLoading: boolean; dialogStatistics: PartialListDialogStatisticDto };
  isIntentVerifying: boolean;
}

const initialState: IDashboardState = {
  dialog: undefined,
  aggregateVoices: undefined,
  dialogStatisticsResponse: { isLoading: false, dialogStatistics: { data: [] } },
  isIntentVerifying: false
};

export const dashboardReducer = (
  state: IDashboardState = initialState,
  action: DashboardActions.Action<DashboardActions.ActionType>
): IDashboardState => {
  switch (action.type) {
    case DashboardActions.ActionType.SetRow: {
      return { ...state, dialog: action.payload.fullDialogStatistic };
    }

    case DashboardActions.ActionType.SetAggregateVoice: {
      return { ...state, aggregateVoices: action.payload.aggregateVoices };
    }

    case DashboardActions.ActionType.SetStatisticsLoading: {
      return {
        ...state,
        dialogStatisticsResponse: { ...state.dialogStatisticsResponse, isLoading: action.payload }
      };
    }

    case DashboardActions.ActionType.PutDialogStatistics: {
      return {
        ...state,
        dialogStatisticsResponse: {
          ...state.dialogStatisticsResponse,
          dialogStatistics: action.payload
        }
      };
    }

    default:
      return state;
  }
};
