import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress
} from '@material-ui/core';
import type { GlobalAssetCategoryDto, GlobalAssetSampleDto, GlobalAssetIntentDto } from '@/generated-api/index.js';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { GlobalAssetsClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

const { actions } = globalAssetsLibrarySlice;

// TODO Rename Intent to Sample (and Template to Intent)
export type DeleteIntentDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  intent: GlobalAssetSampleDto;
  template: GlobalAssetIntentDto;
  categoryId: GlobalAssetCategoryDto['id'];
};

function DeleteIntentDialog({ isOpen, onClose, intent, template, categoryId }: DeleteIntentDialogProps): JSX.Element {
  const [status, setStatus] = useState(ProcessStatus.Idle);
  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const dispatch = useDispatch();
  const unmountedRef = useUnmounted();
  useEffect(() => {
    if (status !== ProcessStatus.StartProcess) {
      return;
    }

    setStatus(ProcessStatus.Processing);
    const process = async () => {
      try {
        const { data } = await GlobalAssetsClient.globalAssetsDeleteSample(template.id, intent?.id);

        if (data) {
          dispatch(actions.templateUpdated(data));
        } else {
          dispatch(
            actions.templateRemoved({
              templateId: template.id,
              categoryId
            })
          );
        }

        if (!unmountedRef.current) {
          setStatus(ProcessStatus.Idle);
        }

        onClose();
      } catch (e) {
        logger.error(translate(nameof.full<I18nGlobalAssetsNs>((n) => n.deleteIntentDialog.anErrorOccurred)));

        if (!unmountedRef.current) {
          setStatus(ProcessStatus.Idle);
        }
      }
    };

    process();
  }, [categoryId, dispatch, intent, onClose, status, template, translate, unmountedRef]);

  const onDelete = () => setStatus(ProcessStatus.StartProcess);

  const processing = status !== ProcessStatus.Idle;

  return (
    <Dialog open={isOpen} maxWidth={'xs'} fullWidth>
      <DialogTitle>{translate(nameof.full<I18nGlobalAssetsNs>((n) => n.deleteIntentDialog.deleteSample))}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography>
            {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.deleteIntentDialog.confirmationText))}{' '}
            {`"${intent?.text}"`}?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Button color={'primary'} form="create-course-form" onClick={onDelete} disabled={processing}>
          {processing ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteIntentDialog;
