import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

interface ConfirmationDialogProps {
  open: boolean;
  versionId: number;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ open, versionId, onConfirm, onCancel }) => {
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.confirmationDialog.confirmRestore))}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.confirmationDialog.confirmationText))} {versionId}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.confirmationDialog.restore))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
