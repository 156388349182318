import { makeStyles } from '@material-ui/core/styles/index.js';

export default makeStyles({
  actionDefault: {
    color: '#4071b0'
  },
  action: {
    fontSize: 18,
    marginBottom: 3,
    textTransform: 'none'
  },
  actionPlay: {
    color: '#cb1a1a'
  },
  actionStop: {
    color: '#4071b0'
  },
  border: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 3,
    height: 24
  },
  borderStop: {
    borderColor: '#4071b0'
  },
  borderPlay: {
    borderColor: '#cb1a1a'
  },
  dividerVert: {
    borderRightWidth: 2,
    borderRightColor: '#ddd',
    borderRightStyle: 'solid'
  },
  default: {
    color: '#000;'
  },
  disableStopA: {
    color: '#949494'
  },
  disablePlayA: { color: '#949494' },
  labelMargin: {
    paddingLeft: 6,
    textTransform: 'none'
  },
  popupField: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px'
  },
  popupLoader: {
    height: '70px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center'
  },
  popupTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 0 12px'
  }
});
