import React, { useEffect } from 'react';
import { Container, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import useStyles from './styles.js';
import { GuiStateSelectors } from 'app/selectors/index.js';
import { CompanyManagementActions } from 'app/actions/companyManagment/companyManagementActions.js';

type MainLoggedProps = {
  children: React.ReactNode;
};

const MainLogged: React.FC<MainLoggedProps> = ({ children }) => {
  const dispatch = useDispatch();

  const isSidebarOpen = useSelector(GuiStateSelectors.sideBarStateSelector);

  const classes = useStyles();

  useEffect(() => {
    dispatch(CompanyManagementActions.getCompanies());
  }, [dispatch]);

  return (
    <Box className={classes.containerWrapper} display="flex">
      <Container
        className={clsx(classes.container, { [classes.container_sidebar_closed]: !isSidebarOpen })}
        maxWidth={false}
        disableGutters
      >
        {children}
      </Container>
    </Box>
  );
};

export default MainLogged;
