import { Guid } from 'guid-typescript';
import { getReasonPhrase } from 'http-status-codes';
import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { emitter } from 'app/apis/api.js';
import { SnackBarElementsSelectors } from 'app/selectors/index.js';
import { deleteError, deleteInfo, deleteWarning } from 'app/actions/snackbar/snackbar.js';
import type { IError, IErrorInterceptor, ISnackBarUiEl } from 'app/models/snackbar/snackbar.js';
import { Link, Snackbar as SnackbarUi } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const SnackBarInterceptorError: FC<IErrorInterceptor> = ({ id, errorText, onDeleteError }) => {
  const handleClose = () => {
    onDeleteError(id);
  };
  return (
    <SnackbarUi
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={true}
      autoHideDuration={200000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error">
        {errorText}
      </Alert>
    </SnackbarUi>
  );
};

const SnackBarUiEl: FC<ISnackBarUiEl> = ({ id, text, onClose, position, duration, severityType, link, linkText }) => {
  const handleClose = () => {
    onClose(id);
  };
  return (
    <SnackbarUi anchorOrigin={position} open autoHideDuration={duration} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severityType}>
        {text}
        {link ? (
          <>
            {' '}
            <Link href={link} rel="noreferrer noopenner" target="_blank">
              {linkText ?? link}
            </Link>
          </>
        ) : null}
      </Alert>
    </SnackbarUi>
  );
};
const Snackbar: FC = () => {
  const errors = useSelector(SnackBarElementsSelectors.errors);
  const info = useSelector(SnackBarElementsSelectors.info);
  const warnings = useSelector(SnackBarElementsSelectors.warnings);
  const dispatch = useDispatch();
  const [interceptorErrors, setInterceptorError] = useState<IError[]>([]);

  useEffect(() => {
    emitter.on('Interceptor error', (error) => {
      if (!error) return;

      const reasonPhrase = getReasonPhrase(error.status);

      const errorText = `${reasonPhrase} (${error.status}) ${error.data}`;

      setInterceptorError((prevInterceptorErrors) => [...prevInterceptorErrors, { id: `${Guid.create()}`, errorText }]);
    });
    return () => {
      emitter.off('Interceptor error');
    };
  }, []);

  const handleDeleteError = (id: string) => {
    setInterceptorError((prevInterceptorErrors) =>
      prevInterceptorErrors.filter((interceptorError) => {
        interceptorError.id !== id;
      })
    );
  };

  const handleCloseInfo = (id: string) => {
    dispatch(deleteInfo({ id }));
  };

  const handleCloseError = (id: string) => {
    dispatch(deleteError({ id }));
  };

  const handleCloseWarning = (id: string) => {
    dispatch(deleteWarning({ id }));
  };

  return (
    <>
      {errors.map((error) => (
        <SnackBarUiEl
          onClose={handleCloseError}
          duration={2000}
          text={error.errorText}
          position={{ vertical: 'bottom', horizontal: 'right' }}
          severityType={'error'}
          id={error.id}
          key={error.id}
        />
      ))}
      {interceptorErrors.map((error) => (
        <SnackBarInterceptorError onDeleteError={handleDeleteError} {...error} key={error.id} />
      ))}
      {warnings.map((warning) => (
        <SnackBarUiEl
          onClose={handleCloseWarning}
          duration={warning.timer ?? 20000}
          text={warning.warningText}
          position={{ vertical: 'top', horizontal: 'center' }}
          severityType="warning"
          id={warning.id}
          key={warning.id}
          link={warning.link}
          linkText={warning.linkText}
        />
      ))}
      {info.map((infoObj) => (
        <SnackBarUiEl
          onClose={handleCloseInfo}
          duration={infoObj?.timer || 20000}
          text={infoObj.infoText}
          position={{ vertical: 'top', horizontal: 'center' }}
          severityType="info"
          id={infoObj.id}
          key={infoObj.id}
        />
      ))}
    </>
  );
};

export default Snackbar;
