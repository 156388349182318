import React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';

import './i18n/i18n.js';
import theme from './app/styles/theme.js';
import history from './core/customHistory.js';
import { configureStore } from './app/store/index.js';
import AppContainer from './app/AppContainer.js';
import Snackbar from './app/components/snackbar/Snackbar.js';
import WebsocketProvider from './app/GlobalContexts/Websocket/WebsocketProvider.js';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import AppUnauthenticated from './app/AppUnauthenticated.js';

// prepare store
const [store] = configureStore();

export const Main = () => (
  <Provider store={store}>
    <Router history={history}>
      <AuthenticatedTemplate>
        <WebsocketProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Switch>
              <Redirect from="/loginExternal" to="/" />
              <Route component={AppContainer} />
            </Switch>
            <Snackbar />
          </ThemeProvider>
        </WebsocketProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Route path="/loginExternal" component={AppUnauthenticated} />
        <Route component={AppUnauthenticated} />
        <Route path="*" render={() => <Redirect to="/" />} />
      </UnauthenticatedTemplate>
    </Router>
  </Provider>
);

export default Main;
