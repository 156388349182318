import React from 'react';
import { Avatar, Box, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image.js';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import AppRoutes from 'app/utils/routes.js';
import { useParams } from 'react-router';
import { groupsSelector } from 'app/components/GroupManagement/store/groupManagementSelectors.js';

function GroupList(): JSX.Element {
  const groups = useSelector(groupsSelector);

  const { id } = useParams<{ id: string }>();

  if (!groups.length) {
    return (
      <Box py={3} display={'flex'} justifyContent={'center'} color={'action.disabled'}>
        <Typography>Group list is empty</Typography>
      </Box>
    );
  }

  return (
    <List>
      {groups.map((g) => (
        <ListItem key={g.id} selected={id === `${g.id}`}>
          <ListItemAvatar>
            <Avatar variant={'square'}>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Link component={RouterLink} to={`${AppRoutes.GroupManagement}/${g.id}`}>
                {g.title}
              </Link>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

export default GroupList;
