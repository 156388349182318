import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { LoadMode } from 'app/store/types.js';
import { LoadingStatus } from 'app/store/types.js';
import type { GlobalAssetFullDto, GlobalAssetCategoryDto, GlobalAssetIntentDto } from '@/generated-api/index.js';

export type GlobalAssetsLibraryState = {
  status: LoadingStatus;
  error: Error | null;
  data: GlobalAssetFullDto[];
};

const initialState: GlobalAssetsLibraryState = {
  status: LoadingStatus.Idle,
  error: null,
  data: []
};

const globalAssetsLibrarySlice = createSlice({
  initialState,
  name: 'globalAssetsLibrary',
  reducers: {
    loadingStarted(state) {
      state.status = LoadingStatus.Loading;
    },
    loadingFinished: {
      prepare(payload: GlobalAssetFullDto[], error?: Error) {
        return { payload, error };
      },
      reducer(state, action: PayloadAction<GlobalAssetFullDto[], string, never, Error | undefined>) {
        if (action.error) {
          state.error = action.error;
          state.status = LoadingStatus.Failed;
        } else {
          state.data = action.payload;
          state.status = LoadingStatus.Succeeded;
        }
      }
    },
    load(state, action: PayloadAction<LoadMode>) {},
    categoryCreated(state, action: PayloadAction<GlobalAssetFullDto>) {
      state.data = [...state.data, { ...action.payload, intents: [] }];
    },
    categoryUpdated(state, action: PayloadAction<GlobalAssetCategoryDto>) {
      state.data = state.data.map((category) =>
        category.id === action.payload.id ? { ...category, ...action.payload } : category
      );
    },
    categoryRemoved(state, action: PayloadAction<GlobalAssetFullDto['id']>) {
      state.data = state.data.filter((a) => a.id !== action.payload);
    },
    templateCreated(state, action: PayloadAction<GlobalAssetIntentDto>) {
      state.data = state.data.map((category) =>
        category.id === action.payload.categoryId
          ? { ...category, intents: [...category.intents, { ...action.payload, samples: action.payload.samples ?? [] }] }
          : category
      );
    },
    templateMoved(
      state,
      action: PayloadAction<{ category: GlobalAssetFullDto; template: GlobalAssetIntentDto }>
    ) {
      const { category: targetCategory, template: sourceTemplate } = action.payload;

      state.data = state.data.map((category) => {
        if (category.id === targetCategory.id) {
          const intentExists = category.intents.some(intent => intent.title === sourceTemplate.title);
          return {
            ...category,
            intents: intentExists
              ? category.intents.map(intent =>
                  intent.title === sourceTemplate.title
                    ? { ...intent, samples: [...intent.samples, ...sourceTemplate.samples] }
                    : intent
                )
              : [...category.intents, sourceTemplate]
          };
        } else {
          return {
            ...category,
            intents: category.intents.filter(intent => intent.id !== sourceTemplate.id)
          };
        }
      });
    },
    templateUpdated(state, action: PayloadAction<GlobalAssetIntentDto>) {
      state.data = state.data.map((category) => {
        if (category.id === action.payload.categoryId) {
          const updatedIntents = category.intents.map((intent) => {
            if (intent.id === action.payload.id) {
              return { ...action.payload };
            }
            return intent;
          });

          return {
            ...category,
            intents: updatedIntents,
          };
        }
        return category;
      });
    },
    templateRemoved(
      state,
      action: PayloadAction<{ categoryId: GlobalAssetFullDto['id']; templateId: GlobalAssetIntentDto['id'] }>
    ) {
      state.data = state.data.map((category) =>
        category.id === action.payload.categoryId
          ? {
              ...category,
              intents: category.intents.filter((intent) => intent.id !== action.payload.templateId)
            }
          : category
      );
    }
  }
});

export default globalAssetsLibrarySlice;
