import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress
} from '@material-ui/core';
import type { UserGroupDto } from '@/generated-api/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGroupManagementNs } from '@/i18n/dictionaries/interfaces.js';

export type DeleteGroupDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  isDeleting: boolean;
  deleteGroup: () => void;
  group: UserGroupDto;
};

function DeleteGroupDialog({ isOpen, onClose, isDeleting, deleteGroup, group }: DeleteGroupDialogProps): JSX.Element {
  
  const [translate] = useTranslation([I18nNamespace.GroupManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Dialog open={isOpen} maxWidth={'md'}>
      <DialogTitle>{translate(nameof.full<I18nGroupManagementNs>((n) => n.deleteGroupForm.deleteGroup))}</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography>
            {translate(nameof.full<I18nGroupManagementNs>((n) => n.deleteGroupForm.confirmationText))} {group?.title}?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Button color={'primary'} form="create-course-form" onClick={deleteGroup} disabled={isDeleting}>
          {isDeleting ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteGroupDialog;
