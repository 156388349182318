import { useSelector } from 'react-redux';
import type { RootState } from 'app/reducers/index.js';
import { object, string, ValidationError } from 'yup';
import type { GlobalAssetFullDto, GlobalAssetSampleDto, GlobalAssetIntentDto } from '@/generated-api/index.js';

function findDuplicate(data: GlobalAssetFullDto[], sample: string, ignoredId?: GlobalAssetSampleDto['id']) {
  let result: {
    alreadyExists: boolean;
    category: GlobalAssetFullDto | null;
    intent: GlobalAssetIntentDto | null;
    sample: GlobalAssetSampleDto | null;
  };

  const lowerCaseSample = sample.toLowerCase();

  for (let i = 0; i < data.length && !result?.alreadyExists; i++) {
    const category = data[i];

    for (let j = 0; j < category.intents.length; j++) {
      const existingIntent = category.intents[j].samples.find((i) => i.text.toLowerCase() === lowerCaseSample);

      if (existingIntent && existingIntent.id !== ignoredId) {
        result = {
          alreadyExists: true,
          category,
          intent: category.intents[j],
          sample: existingIntent
        };
        break;
      }
    }
  }

  return result;
}

function useIntentValidationSchema<T extends string>(
  fieldName: T,
  intent: GlobalAssetIntentDto,
  id?: GlobalAssetSampleDto['id']
) {
  const globalCategoriesToValidate = useSelector((state: RootState) => {
    const currentCompanyId = state.userReducer.currentUser?.companyId;
    const categories = state.globalAssetsLibrary.data;
    if (!(categories && currentCompanyId)) return [];
    if (intent == null) return categories.filter((x) => x.companyId === currentCompanyId);
    const selectedCategory = categories.find((x) => x.id == intent.categoryId);
    if (selectedCategory == null) throw new Error(`Category ${intent.categoryId} is not found`);

    return categories.filter(
      (x) => x.language === selectedCategory.language && x.companyId === selectedCategory.companyId
    );
  });

  const schema = object({
    [fieldName]: string()
      .required('This field is required')
      .test(
        fieldName,
        () => {
          return 'failed';
        },
        (value) => {
          const duplicate = findDuplicate(globalCategoriesToValidate, value, id);

          if (duplicate) {
            return new ValidationError(
              `This intent already exist on category: ${duplicate.category.title}, intent: ${duplicate.intent.title}, text: ${duplicate.sample.text}`,
              value,
              fieldName
            );
          }

          return true;
        }
      )
  });

  return schema;
}

export default useIntentValidationSchema;
