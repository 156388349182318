import { handleActions } from 'redux-actions';
import { UserActions } from 'app/actions/user/userAction.js';
import type { UserDto } from '@/generated-api/index.js';
import { LoadingStatus } from 'app/store/types.js';

export interface IUserState {
  currentUser?: UserDto;
  currentUserNotFound: boolean;
  currentUserLoadingStatus: LoadingStatus;
}

const initialState: IUserState = {
  currentUser: null,
  currentUserNotFound: false,
  currentUserLoadingStatus: LoadingStatus.Idle
};

export const userReducer = handleActions<IUserState, UserDto | Date | boolean>(
  {
    [UserActions.Type.SET_CURRENT_USER]: (state, action): IUserState => {
      return { ...state, currentUser: action.payload as UserDto };
    },
    [UserActions.Type.SET_CURRENT_USER_LOADING_STATUS]: (state, action): IUserState => {
      return { ...state, currentUserLoadingStatus: (action.payload as unknown) as LoadingStatus };
    },
    [UserActions.Type.SET_CURRENT_USER_NOT_FOUND]: (state): IUserState => {
      return { ...state, currentUserNotFound: true };
    }
  },
  initialState
);
