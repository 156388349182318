import type { FC, PropsWithChildren } from 'react';
import React from 'react';
import { Grid, List, Typography, Chip, useTheme } from '@material-ui/core/';
import { InfoOutlined } from '@material-ui/icons/';
import type { DialogStatisticKpiDetailsDto } from '@/generated-api';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/styles';

import KPIDetailsScore from 'app/components/DashboardPage/KPIDetails/KPIDetailsScore';
import KPIDetailsListItem from 'app/components/DashboardPage/KPIDetails/KPIDetailsListItem';
import KPIDetailsScoreIcon from 'app/components/DashboardPage/KPIDetails/KPIDetailsScoreIcon';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

type ProcedureScoresProps = {
  kpiDetails: DialogStatisticKpiDetailsDto;
};

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '250px', // Adjust this value according to your header and other components occupying space.
    overflow: 'auto'
  },
  tooltipIcon: {
    fontSize: 'medium',
    marginLeft: 4,
    verticalAlign: 'middle',
    color: 'forestgreen'
  }
}));

const ProcedureScoresTab: FC<PropsWithChildren<ProcedureScoresProps>> = ({ kpiDetails }) => {
  if (!kpiDetails) return null;

  const theme = useTheme();
  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const getTooltipContent = (reason: number, sentence: string) => {
    switch (reason) {
      case 0:
        return `(${translate(nameof.full<I18nDashboardNs>((n) => n.procedureScores.intentMatch))}) "${sentence}"`;
      case 1:
        return `(${translate(nameof.full<I18nDashboardNs>((n) => n.procedureScores.aiIntentDetection))}) "${sentence}"`;
      case 2:
        return `(${translate(nameof.full<I18nDashboardNs>((n) => n.procedureScores.aiProcedureMatch))}) "${sentence}"`;
      default:
        return null;
    }
  };

  return (
    <List className={classes.root}>
      {kpiDetails.procedureScores
        .sort((a, b) => {
          // Sort by score in descending order
          const scoreDifference = b.score - a.score;
          if (scoreDifference !== 0) return scoreDifference;

          // If the scores are equal, sort by the lowest number in toIntents array
          const aLowestNumber = Math.min(...a.toIntents.map((intent) => intent.number));
          const bLowestNumber = Math.min(...b.toIntents.map((intent) => intent.number));
          return aLowestNumber - bLowestNumber;
        })
        .map((procedureScore, index) => {

          const tooltipContent = getTooltipContent(procedureScore.reason, procedureScore.sentence);

          return (
            <KPIDetailsListItem key={index}>
              <KPIDetailsScoreIcon score={procedureScore.score} />
              <KPIDetailsScore score={procedureScore.score} />
              <Grid item container alignItems="center" wrap="nowrap" xs={12} sm={12}>
                <Grid item xs>
                  <Typography noWrap variant="body2" style={{ fontWeight: theme.typography.fontWeightMedium }}>
                    {procedureScore.medicalProtocolActionName}
                    {tooltipContent && (
                      <Tooltip title={tooltipContent}>
                        <InfoOutlined className={classes.tooltipIcon} />
                      </Tooltip>
                    )}
                  </Typography>
                  {procedureScore.toIntents.map((intent, index) => (
                    <Chip
                      key={index}
                      label={
                        <>
                          {intent.originalIntentName} {`(${intent.number})`}
                        </>
                      }
                      style={{ marginRight: 4, color: 'green' }}
                      variant="outlined"
                    />
                  ))}
                </Grid>
              </Grid>
            </KPIDetailsListItem>
          );
        })}
    </List>
  );
};

export default ProcedureScoresTab;
