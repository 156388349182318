import type { ScheduledCourseDto, ScheduledCourseNotificationDto } from '@/generated-api/index.js';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CourseClient } from 'app/apis/api.js';
import { DateFormat } from 'app/app.constants.js';
import LoadingButton from 'app/components/LoadingButton.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import { LoadingStatus } from 'app/store/types.js';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

type CreateEditScheduledCourseNotificationDialogProps = {
  queryKey: (string | number)[];
  notification: ScheduledCourseNotificationDto | undefined; // TODO Type
  isOpen: boolean;
  onClose: () => void;
  onExited?: () => void;
  scheduledCourse: ScheduledCourseDto;
};

const schema = yup.object({
  date: yup
    .string()
    .required()
    .test('date_test_older_than_now', 'Date is older than now', (value) => {
      const date = new Date(value);
      return date.getTime() >= Date.now();
    }),
  customText: yup.string().nullable().optional()
});

const CreateEditScheduledCourseNotificationDialog: React.FC<CreateEditScheduledCourseNotificationDialogProps> = (
  props
) => {
  const { queryKey, notification, isOpen, onClose, onExited, scheduledCourse } = props;
  const { id: scheduledCourseId } = scheduledCourse;

  const isEdit = !!notification;

  const formatDate = useFormatDate();

  const { handleSubmit, control, formState, reset, watch } = useForm<ScheduledCourseNotificationDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...(isEdit
        ? notification
        : {
            customText: ''
          }),
      date: isEdit ? formatDate(notification.date, DateFormat.DateTimeLocal) : ''
    },
    resolver: yupResolver(schema)
  });
  const { isValid } = formState;

  React.useEffect(() => {
    const values = {
      ...(isEdit ? notification : { customText: '', id: 0 }),
      date: isEdit ? formatDate(notification.date, DateFormat.DateTimeLocal) : ''
    };
    reset(values);
  }, [formatDate, isEdit, notification, reset]);

  const queryClient = useQueryClient();

  const createNotification = useMutation({
    mutationFn: async (values: ScheduledCourseNotificationDto) => {
      await CourseClient.courseCreateScheduledCourseNotification(scheduledCourseId, {
        customText: values.customText,
        date: new Date(values.date).toISOString()
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onClose();
    }
  });

  const editNotification = useMutation({
    mutationFn: async (values: ScheduledCourseNotificationDto) => {
      await CourseClient.courseUpdateScheduledCourseNotification(scheduledCourseId, notification.id, {
        ...values,
        date: new Date(values.date).toISOString(),
        id: notification.id
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
      onClose();
    }
  });

  const onSubmit = async (data: ScheduledCourseNotificationDto) => {
    if (isEdit) {
      editNotification.mutateAsync(data);
    } else {
      createNotification.mutateAsync(data);
    }
  };

  const loadingStatus = (isEdit ? editNotification.status : createNotification.status) as LoadingStatus;
  const date = watch('date');
  const isBeforeCourseEnd = date ? new Date(date) < new Date(scheduledCourse.endDate) : false;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      TransitionProps={{
        onExited
      }}
    >
      <DialogTitle>
        {isEdit ? 'Edit ' : 'Add '}
        notification
      </DialogTitle>
      <DialogContent>
        <form id={`${isEdit ? 'edit' : 'create'}-scheduled-course-notification-form`} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            required
            fullWidth
            type="datetime-local"
            as={TextField}
            margin="normal"
            control={control}
            label="Date and time"
            name="date"
            InputLabelProps={{
              shrink: true
            }}
          />
          <Controller
            fullWidth
            type="text"
            as={TextField}
            margin="normal"
            control={control}
            label="Custom text (optional)"
            name="customText"
            multiline
            minRows={3}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loadingStatus === LoadingStatus.Loading}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={!isValid || !isBeforeCourseEnd}
          loadingStatus={loadingStatus}
          form={`${isEdit ? 'edit' : 'create'}-scheduled-course-notification-form`}
        >
          {isEdit ? 'Save' : 'Add'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEditScheduledCourseNotificationDialog;
