import { Emotion } from '@/generated-api/index.js';
import { makeStyles, IconButton } from '@material-ui/core';
import React, { useMemo } from 'react';

export type EmotionSwitcherProps = {
  value: Emotion;
  onChange: (value: Emotion) => void;
};

const useEmotionSwitcherStyles = makeStyles({
  root: {
    color: 'initial'
  }
});

function EmotionSwitcher({ value, onChange }: EmotionSwitcherProps) {
  const icon = useMemo(() => {
    switch (value) {
      case Emotion.NUMBER_1:
        return '😄';
      case Emotion.NUMBER_2:
        return '😢';
      case Emotion.NUMBER_3:
        return '😡';

      case Emotion.NUMBER_0:
      default:
        return '😐';
    }
  }, [value]);

  const classes = useEmotionSwitcherStyles();

  return (
    <IconButton classes={classes} onClick={() => onChange(getNextOrFirst(value))}>
      {icon}
    </IconButton>
  );
}

function getNextOrFirst(value: Emotion): Emotion {
  if (!Emotion.hasOwnProperty(value)) {
    throw new Error('Unknown emotion');
  }

  const keys = Object.keys(Emotion);
  const maxValue = +keys[keys.length / 2 - 1] as Emotion;

  if (value === maxValue) {
    return Emotion.NUMBER_0;
  }

  return (value + 1) as Emotion;
}

export default EmotionSwitcher;
