import type { I18nValidationNs } from './interfaces.js';

export const ValidationDictionaryEn: I18nValidationNs = {
  fieldInvalid: 'This field is not valid.',
  fieldRequired: 'This field is required.'
};

export const ValidationDictionaryDe: I18nValidationNs = {
  fieldInvalid: 'Dieses Feld ist ungültig.',
  fieldRequired: 'Dieses Feld ist erforderlich.'
};

export const ValidationDictionarySw: I18nValidationNs = {
  fieldInvalid: 'This field is not valid.',
  fieldRequired: 'This field is required.'
};

export const ValidationDictionaryNo: I18nValidationNs = {
  fieldInvalid: 'Dette feltet er ikke gyldig.',
  fieldRequired: 'Dette feltet er obligatorisk.'
};

export const ValidationDictionaryNl: I18nValidationNs = {
  fieldInvalid: 'This field is not valid.',
  fieldRequired: 'This field is required.'
};

export const ValidationDictionaryIs: I18nValidationNs = {
  fieldInvalid: 'This field is not valid.',
  fieldRequired: 'This field is required.'
};

export const ValidationDictionaryUk: I18nValidationNs = {
  fieldInvalid: 'This field is not valid.',
  fieldRequired: 'This field is required.'
};
