import React, { useMemo } from 'react';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { GlobalAssetIntentDto } from '@/generated-api/index.js';

const useTooltipStyles = makeStyles((theme) => ({
  tooltipList: {
    paddingInlineStart: `${theme.spacing(2)}px`,
    paddingInlineEnd: `${theme.spacing(2)}px`,
    maxHeight: 110,
    overflow: 'auto'
  }
}));

interface IntentsInfoTooltipProps {
  template: GlobalAssetIntentDto;
}

const IntentsInfoTooltip: React.FC<IntentsInfoTooltipProps> = ({ template }) => {
  const classes = useTooltipStyles();

  const tooltipContent = useMemo(() => {
    return (
      <ul className={classes.tooltipList}>
        {template.samples.map((i) => (
          <li key={i.id}>{i.text}</li>
        ))}
      </ul>
    );
  }, [template.samples, classes.tooltipList]);

  return (
    <Tooltip title={tooltipContent} interactive>
      <Box ml={1} component={'span'}>
        <InfoOutlined fontSize="small" />
      </Box>
    </Tooltip>
  );
};

export default IntentsInfoTooltip;