import React, { useEffect, useRef } from 'react';
import { Grid, IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import { PlayState } from 'app/components/DashboardPage/IntentDescription/types.js';
import type { DialogStatisticIntentDto, SceneDialogStatisticDto } from '@/generated-api/index.js';
import { useGetAudio } from 'app/hooks/useGetAudio.js';
import logger from 'app/utils/logger.js';
import { Pause, PlayArrow, Stop } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

export type PlaybackCopyProps = {
  onUserSpeechFinished: () => void;
  onAiSpeechFinished: () => void;
  playState: PlayState;
  togglePause: () => void;
  onStop: () => void;
  intent: DialogStatisticIntentDto;
  sceneDialogStatistic: SceneDialogStatisticDto;
};

const Playback = ({
  onUserSpeechFinished,
  onAiSpeechFinished,
  playState,
  togglePause,
  onStop,
  intent,
  sceneDialogStatistic
}: PlaybackCopyProps): JSX.Element => {
  const userAudioRef = useRef<HTMLAudioElement>(null);
  const aiAudioRef = useRef<HTMLAudioElement>(null);

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const { userSays: userAudioSrc, aiResponse: aiAudioSrc } = useGetAudio(intent, sceneDialogStatistic, true);

  const resetAndStop = (element: HTMLAudioElement) => {
    element.pause();
    element.currentTime = 0;
  };

  /* PlayState.User */
  useEffect(() => {
    if (playState === PlayState.User && userAudioSrc) {
      userAudioRef.current.play();
      resetAndStop(aiAudioRef.current);
    }
  }, [playState, userAudioSrc]);

  /* PlayState.AI */
  useEffect(() => {
    if (playState === PlayState.AI && aiAudioSrc) {
      resetAndStop(userAudioRef.current);
      aiAudioRef.current.play();
    }
  }, [aiAudioSrc, playState]);

  /* PlayState.Paused */
  useEffect(() => {
    if (playState === PlayState.Paused) {
      userAudioRef.current.pause();
      aiAudioRef.current.pause();
    }
  }, [playState]);

  /* PlayState.Stopped / PauseAfterUser / PauseAfterAi */
  useEffect(() => {
    if ([PlayState.Stopped, PlayState.PauseAfterAi, PlayState.PauseAfterUser].includes(playState)) {
      resetAndStop(userAudioRef.current);
      resetAndStop(aiAudioRef.current);
    }
  }, [playState]);

  return (
    <>
      {!(userAudioSrc || aiAudioSrc) && (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}

      <audio onEnded={onUserSpeechFinished} ref={userAudioRef} src={userAudioSrc} />
      <audio onEnded={onAiSpeechFinished} ref={aiAudioRef} src={aiAudioSrc} />

      {userAudioSrc && aiAudioSrc ? (
        <>
          {(playState === PlayState.Stopped || playState === PlayState.Paused) && (
            <Tooltip title={translate(nameof.full<I18nDashboardNs>((n) => n.playback.play))} enterDelay={300}>
              <IconButton style={{ color: 'black' }} onClick={togglePause}>
                <PlayArrow />
              </IconButton>
            </Tooltip>
          )}

          {playState !== PlayState.Stopped && (
            <>
              {' '}
              {playState !== PlayState.Paused && (
                <Tooltip title={translate(nameof.full<I18nDashboardNs>((n) => n.playback.pause))} enterDelay={300}>
                  <IconButton style={{ color: 'black' }} onClick={togglePause}>
                    <Pause />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={translate(nameof.full<I18nDashboardNs>((n) => n.playback.stop))} enterDelay={300}>
                <IconButton style={{ color: 'black' }} onClick={onStop}>
                  <Stop />
                </IconButton>
              </Tooltip>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default Playback;
