import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index.js';
import { Box, Button, Grid, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import {
  isMedicalIndexesStatusLoadingSelector,
  isMedicalIndexesStatusSucceedSelector
} from 'app/store/MedicalIndex/medicalIndexSelectors.js';

import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import { LoadMode } from 'app/store/types.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import MedIndexList from 'app/components/MedicalIndexesManager/MedIndexList.js';
import CreateMedIndexDialog from 'app/components/MedicalIndexesManager/CreateMedIndexDialog/CreateMedIndexDialog.js';
import MedIndexCard from 'app/components/MedicalIndexesManager/MedIndexCard/MedIndexCard.js';
import { Add } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

const { actions } = medicalIndexSlice;

const useStyles = makeStyles((theme) => {
  return {
    section: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  };
});

function MedicalIndexManager(): JSX.Element {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  useEffect(() => {
    dispatch(actions.load(LoadMode.Hard));
  }, [dispatch]);

  const isIndexesLoading = useSelector(isMedicalIndexesStatusLoadingSelector);
  const isMedicalIndexInitialized = useSelector(isMedicalIndexesStatusSucceedSelector) || true;
  const isSkeletonDisplayed = isIndexesLoading || !isMedicalIndexInitialized;

  const classes = useStyles();

  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolState(false);

  const [searchText, setSearchText] = useState('');

  return (
    <Box overflow={'hidden'} height={'100%'} display={'flex'}>
      <Grid container>
        <Grid xs={5} md={4} lg={3} item classes={{ root: classes.section }}>
          <Box p={4} overflow={'auto'}>
            <Box mb={1}>
              <TextField
                onChange={(e) => setSearchText(e.target.value)}
                fullWidth
                placeholder={translate(nameof.full<I18nProtocolReferencesNs>((n) => n.freeTextSearch))}
                label={translate(nameof.full<I18nProtocolReferencesNs>((n) => n.search))}
              />
            </Box>

            <Box display={'flex'}>
              {isSkeletonDisplayed ? (
                <Skeleton width={100} height={36} />
              ) : (
                <Button startIcon={<Add />} onClick={openCreateDialog}>
                  {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.add))}
                </Button>
              )}
            </Box>

            {isSkeletonDisplayed ? (
              <Box>
                <Skeleton height={72} />
                <Skeleton height={72} />
                <Skeleton height={72} />
              </Box>
            ) : (
              <MedIndexList searchText={searchText} />
            )}
          </Box>
        </Grid>

        <Grid xs={7} md={8} lg={9} item container classes={{ root: classes.section }}>
          <MedIndexCard />
        </Grid>
      </Grid>
      <CreateMedIndexDialog isOpen={isCreateDialogOpen} onClose={closeCreateDialog} />
    </Box>
  );
}

export default MedicalIndexManager;
