import React from 'react';
// import Cancel from '@material-ui/icons/Cancel.js';
import { CheckCircle, Cancel } from '@material-ui/icons';

import useKPIDetailsScore from 'app/components/DashboardPage/KPIDetails/useKPIDetailsScore.js';

type KPIDetailsScoreProps = {
  score: number;
};

const KPIDetailsScoreIcon: React.FC<KPIDetailsScoreProps> = ({ score }) => {
  const { color } = useKPIDetailsScore(score);

  const Icon = score > 0 ? CheckCircle : Cancel;

  return <Icon htmlColor={color} />;
};

export default KPIDetailsScoreIcon;
