import logger from 'app/utils/logger.js';
import { useState, useCallback } from 'react';

interface GetMediaStream {
  (): Promise<MediaStream | null>;
}

interface StopMediaStream {
  (stream: MediaStream): void;
}

export const getMediaStream: GetMediaStream = async () => {
  try {
    logger.log('Getting media stream (microphone stream)');
    const userMedia = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true
      },
      video: false
    });
    return userMedia;
  } catch (error) {
    logger.error(error);
  }
  return null;
};

export const stopMediaStream: StopMediaStream = (stream) => {
  logger.log('Stopping media stream (microphone stream)');
  if (!stream) return;
  stream.getTracks().forEach((track) => {
    track.stop();
  });
};

export const useMediaStream = () => {
  const [stream, setStream] = useState<null | MediaStream>(null);

  const startStream = useCallback(async () => {
    // logger.log('starting stream');
    const getMediaStream = async () => {
      // logger.log('GETTING MEDIA STREAM!!!');
      try {
        const userMedia = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          },
          video: false
        });
        setStream(userMedia);
      } catch (error) {
        logger.error(error);
      }
    };
    if (!stream) await getMediaStream();
  }, [stream]);

  const stopStream = useCallback(() => {
    // logger.log('stopping stream');
    if (!stream) return;
    stream.getTracks().forEach((track) => {
      track.stop();
    });
    setStream(null);
  }, [stream]);

  return { stream, startStream, stopStream };
};
