import { all, put, select, takeEvery } from 'redux-saga/effects';
import { LoadMode } from 'app/store/types.js';
import { GlobalAssetsClient } from 'app/apis/api.js';

import {
  isGlobalAssetsLibraryStatusLoadingSelector,
  isGlobalAssetsLibraryStatusNotIdleSelector
} from './globalAssetsLibrarySelectors.js';

import globalAssetsLibrarySlice from './globalAssetsLibrarySlice.js';
import type { Unwrap } from 'app/types/UtilityType.js';

const actions = globalAssetsLibrarySlice.actions;

function* globalAssetsLibrarySaga() {
  yield all([
    yield takeEvery(actions.load.toString(), loadAssets)
    // yield takeEvery(actions.updateGroups.toString(), updateGroups)
  ]);
}

function* loadAssets(action: ReturnType<typeof actions.load>) {
  const isLoading: boolean = yield select(isGlobalAssetsLibraryStatusLoadingSelector);

  if (isLoading) {
    return;
  }

  if (action.payload === LoadMode.Soft) {
    if (yield select(isGlobalAssetsLibraryStatusNotIdleSelector)) {
      return;
    }
  }

  yield put(actions.loadingStarted());
  try {
    const response: Unwrap<typeof GlobalAssetsClient.globalAssetsGetFullData> = yield GlobalAssetsClient.globalAssetsGetFullData();
    yield put(actions.loadingFinished(response.data?.length ? response.data : []));
  } catch (e) {
    yield put(actions.loadingFinished([], e as Error));
  }
}

export default globalAssetsLibrarySaga;
