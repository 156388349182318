import type { I18nScenarioSectionNs } from './interfaces.js';

export const ScenarioSectionDictionaryEn: I18nScenarioSectionNs = {
  scenarios: 'Scenarios',
  sharedScenarios: 'Shared scenarios',
  createNewScenario: 'Create new scenario',
  showFolders: 'Show folders',
  search: 'Search',
  movetoFolder: 'Move to folder',
  removeFromFolder: 'Remove from folder',
  createNew: 'Create new',
  folderName: 'Folder name'
};

export const ScenarioSectionDictionaryDe: I18nScenarioSectionNs = {
  scenarios: 'Szenarien',
  sharedScenarios: 'Geteilte Szenarien',
  createNewScenario: 'Neues Szenario erstellen',
  showFolders: 'Ordner anzeigen',
  search: 'Suchen',
  movetoFolder: 'In Ordner verschieben',
  removeFromFolder: 'Aus Ordner entfernen',
  createNew: 'Neu erstellen',
  folderName: 'Ordnername'
};

export const ScenarioSectionDictionarySw: I18nScenarioSectionNs = {
  scenarios: 'Scenarios',
  sharedScenarios: 'Shared scenarios',
  createNewScenario: 'Create new scenario',
  showFolders: 'Show folders',
  search: 'Search',
  movetoFolder: 'Move to folder',
  removeFromFolder: 'Remove from folder',
  createNew: 'Create new',
  folderName: 'Folder name'
};

export const ScenarioSectionDictionaryNo: I18nScenarioSectionNs = {
  scenarios: 'Scenarier',
  sharedScenarios: 'Delte scenarier',
  createNewScenario: 'Nytt scenario',
  showFolders: 'Vis mapper',
  search: 'Søk',
  movetoFolder: 'Flytt til mappe',
  removeFromFolder: 'Fjern fra mappe',
  createNew: 'Opprett ny',
  folderName: 'Mappenavn'
};

export const ScenarioSectionDictionaryNl: I18nScenarioSectionNs = {
  scenarios: 'Scenarios',
  sharedScenarios: 'Shared scenarios',
  createNewScenario: 'Create new scenario',
  showFolders: 'Show folders',
  search: 'Search',
  movetoFolder: 'Move to folder',
  removeFromFolder: 'Remove from folder',
  createNew: 'Create new',
  folderName: 'Folder name'
};

export const ScenarioSectionDictionaryIs: I18nScenarioSectionNs = {
  scenarios: 'Scenarios',
  sharedScenarios: 'Shared scenarios',
  createNewScenario: 'Create new scenario',
  showFolders: 'Show folders',
  search: 'Search',
  movetoFolder: 'Move to folder',
  removeFromFolder: 'Remove from folder',
  createNew: 'Create new',
  folderName: 'Folder name'
};

export const ScenarioSectionDictionaryUk: I18nScenarioSectionNs = {
  scenarios: 'Scenarios',
  sharedScenarios: 'Shared scenarios',
  createNewScenario: 'Create new scenario',
  showFolders: 'Show folders',
  search: 'Search',
  movetoFolder: 'Move to folder',
  removeFromFolder: 'Remove from folder',
  createNew: 'Create new',
  folderName: 'Folder name'
};
