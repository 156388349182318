import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import type { CreateGlobalAssetCategoryCommand } from '@/generated-api/index.js';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUnmounted } from 'app/utils/customHooks/index.js';
import { GlobalAssetsClient } from 'app/apis/api.js';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppRoutes from 'app/utils/routes.js';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import schema from './schema.js';
import { isEmpty } from 'lodash';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

const { actions } = globalAssetsLibrarySlice;

export type CreateCategoryDialog = {
  isOpen: boolean;
  onClose: () => void;
  language: string;
};

function CreateCategoryDialog({ isOpen, onClose, language }: CreateCategoryDialog): JSX.Element {
  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const { control, handleSubmit, errors, setValue } = useForm<Required<CreateGlobalAssetCategoryCommand>>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: translate(nameof.full<I18nGlobalAssetsNs>((n) => n.createCategoryDialog.newCategory)),
      language: language,
      shared: false
    }
  });

  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);

  const [submitState, setSubmitState] = useState<{
    data: CreateGlobalAssetCategoryCommand;
    status: ProcessStatus;
  }>({
    status: ProcessStatus.Idle,
    data: {} as CreateGlobalAssetCategoryCommand
  });

  const dispatch = useDispatch();
  const unmountedRef = useUnmounted();
  const history = useHistory();

  useEffect(() => {
    if (submitState.status !== ProcessStatus.StartProcess) {
      return;
    }

    const submitData = async () => {
      try {
        setSubmitState((s) => ({
          ...s,
          status: ProcessStatus.Processing
        }));
        logger.log(submitState.data);

        const { data: category } = await GlobalAssetsClient.globalAssetsCreateCategory(submitState.data);
        dispatch(actions.categoryCreated(category));
        history.push(`${AppRoutes.GlobalAssetsLibrary}/${category.id}`);
        onClose();
      } catch (e) {
        logger.error(e);
      } finally {
        if (!unmountedRef.current) {
          setSubmitState((s) => ({
            ...s,
            status: ProcessStatus.Idle
          }));
        }
      }
    };

    submitData();
  }, [dispatch, history, onClose, submitState, language, unmountedRef]);

  const onSubmit = handleSubmit((formData) => {
    logger.log(formData);
    setSubmitState({
      status: ProcessStatus.StartProcess,
      data: {
        ...formData,
        language
      }
    });
  });

  return (
    <Dialog open={isOpen} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.createCategoryDialog.createCategory))}
      </DialogTitle>
      <DialogContent>
        <Box overflow={'hidden'} px={2} pt={2} pb={6}>
          <form id="create-assets-category-form" onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      fullWidth
                      label={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.createCategoryDialog.categoryTitle))}
                      {...props}
                      inputRef={props.ref}
                      error={!!errors.title?.message}
                      helperText={errors.title?.message}
                    />
                  )}
                  control={control}
                  name={nameof.full<CreateGlobalAssetCategoryCommand>((c) => c.title)}
                />
              </Grid>
              {isUserSuperAdmin ? (
                <Grid item xs={12}>
                  <Controller
                    render={(props) => (
                      <FormControlLabel
                        label={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.createCategoryDialog.sharedCategory))}
                        control={
                          <Checkbox
                            {...props}
                            onChange={(e) => setValue('shared', e.target.checked)}
                            inputRef={props.ref}
                          />
                        }
                      />
                    )}
                    control={control}
                    name={nameof.full<CreateGlobalAssetCategoryCommand>((c) => c.shared)}
                  />
                </Grid>
              ) : null}
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Button
          color={'primary'}
          form="create-assets-category-form"
          type={'submit'}
          disabled={submitState.status === ProcessStatus.Processing || !isEmpty(errors)}
        >
          {submitState.status === ProcessStatus.Processing ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translate(nameof.full<I18nGlobalAssetsNs>((n) => n.globalAssetsLibrary.create))
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCategoryDialog;
