import type { FC } from 'react';
import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Route } from 'react-router-dom';

import type { RoleId } from 'app/models/users/role.js';
import useHasAccess from 'app/utils/useHasAccess.js';
import MainLogged from 'app/components/MainLogged.js';
import { FallbackPage } from 'app/components/FallbackPage/index.js';

interface ProtectedRouteProps extends RouteProps {
  path: string;
  roles?: RoleId[];
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ roles, ...rest }) => {
  const hasAccess = useHasAccess(roles);

  if (!hasAccess) {
    return <FallbackPage text="No access" />;
  }

  return (
    <MainLogged>
      <Route {...rest} />
    </MainLogged>
  );
};

export default ProtectedRoute;
