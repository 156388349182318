import styled, { css } from 'styled-components';
import type { INodeDefaultProps } from '@mrblenny/react-flow-chart';

import { getOuterNodeBorderWidthPx } from '../../../../../utils/flowChart/sizingHelpers.js';

const borderWidth = getOuterNodeBorderWidthPx();

// @ts-ignore
const CustomNodeComponent = styled.div<INodeDefaultProps>`
  position: absolute;
  background: white;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border: ${borderWidth}px solid #eee;
  ${({ node }) =>
    node.properties?.isDialogProcessOnTheNode &&
    css`
      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba(17, 119, 209, 1);
        }

        80% {
          box-shadow: 0 0 50px 16px rgba(17, 119, 209, 0);
        }

        100% {
          box-shadow: 0 0 0 0 rgba(17, 119, 209, 0);
        }
      }
      margin-top: -2px;
      border: ${borderWidth}px solid #1177d1;
      animation: pulse 0.9s infinite;
    `},
  ${({ isSelected }) =>
    isSelected &&
    css`
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      margin-top: -2px;
      border: ${borderWidth}px solid orange;
    `},
  & > div {
    background: #d2d2d2;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
  }
`;
export default CustomNodeComponent;
