import React, { useCallback } from 'react';
import { Box, Button, CircularProgress } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete.js';
import { useBoolState, useUnmounted } from 'app/utils/customHooks/index.js';
import AppRoutes from 'app/utils/routes.js';
import { useHistory } from 'react-router-dom';
import useDeleteMedIndex from './hooks/useDeleteMedIndex.js';
import type { MedicalProtocolDto } from '@/generated-api/index.js';
import DeleteMedIndexDialog from 'app/components/MedicalIndexesManager/MedIndexCard/Actions/DeleteMedIndexDialog.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

export type ActionsProps = {
  medIndex: MedicalProtocolDto;
};

function Actions({ medIndex }: ActionsProps): JSX.Element {
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBoolState(false);

  const history = useHistory();

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const unmountedRef = useUnmounted();

  const [deleteMedIndex, isDeleting] = useDeleteMedIndex(
    useCallback(() => {
      if (!unmountedRef.current) {
        closeDeleteDialog();
      }
      history.replace(AppRoutes.MedicalIndexManager);
    }, [closeDeleteDialog, history, unmountedRef]),
    medIndex?.id
  );

  return (
    <Box display={'flex'}>
      <Box ml={'auto'} mr={2}>
        <Button startIcon={<DeleteIcon />} variant={'contained'} onClick={openDeleteDialog} disabled={isDeleting}>
          {isDeleting ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))
          )}
        </Button>
      </Box>

      <DeleteMedIndexDialog
        isOpen={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        medIndex={medIndex}
        deleteMedIndex={deleteMedIndex}
        isDeleting={isDeleting}
      />
    </Box>
  );
}

export default Actions;
