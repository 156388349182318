import React from 'react';
import type { MenuProps } from '@material-ui/core/Menu/index.js';
import { Typography, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft.js';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever.js';

import type { CompanyDto } from '@/generated-api/index.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import OnCompanyDeleteAction from 'app/models/companies/onCompanyDeleteAction.js';

interface CompanyDeletionMenuProps extends Pick<MenuProps, 'open' | 'anchorEl'> {
  onClose: () => void;
  onDeleteCompany: (deleteAction: OnCompanyDeleteAction, moveToCompany?: number) => void;
  moveToCompanies: CompanyDto[];
}

const CompanyDeletionMenu: React.FC<CompanyDeletionMenuProps> = ({
  open,
  onClose,
  anchorEl,
  onDeleteCompany,
  moveToCompanies
}) => {
  const innerMenuRef = React.useRef<HTMLLIElement>(null);
  const [innerMenuOpen, openInnerMenu, closeInnerMenu] = useBoolState();

  const closeBoth = () => {
    closeInnerMenu();
    onClose();
  };

  return (
    <>
      <Menu
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        getContentAnchorEl={undefined}
      >
        <MenuItem button onClick={() => onDeleteCompany(OnCompanyDeleteAction.DeleteUsers)}>
          <ListItemIcon>
            <DeleteForeverIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete users</Typography>
        </MenuItem>
        <MenuItem button onClick={openInnerMenu} ref={innerMenuRef}>
          <ListItemIcon>
            <ChevronLeftIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Move users to...</Typography>
        </MenuItem>
      </Menu>
      <Menu
        open={innerMenuOpen}
        onClose={closeBoth}
        anchorEl={innerMenuRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        getContentAnchorEl={undefined}
      >
        {moveToCompanies.map((c) => (
          <MenuItem key={c.id} button onClick={() => onDeleteCompany(OnCompanyDeleteAction.MoveUsers, c.id)}>
            <Typography variant="inherit">{c.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CompanyDeletionMenu;
