import { handleActions } from 'redux-actions';
import { GuiStateActions } from 'app/actions/guiState/guiStateAction.js';
import type ChartDOMSize from '../../models/guiState/chartDomPosition.js';

// FIXME: i've no better ideas atm, use this as fallback in case nothing is specified
function getWindowSize(): ChartDOMSize {
  return {
    chartDOMWidth: window.innerWidth ?? 0,
    chartDOMHeight: window.innerHeight ?? 0
  };
}

export interface IGuiState {
  sideMenuOpen: boolean;
  chartDOMSize: ChartDOMSize;
}

const initialState: IGuiState = {
  sideMenuOpen: false,
  chartDOMSize: getWindowSize()
};

export const guiStateReducer = handleActions<IGuiState, boolean | ChartDOMSize>(
  {
    [GuiStateActions.Type.SET_SIDE_MENU_OPEN]: (state, action): IGuiState => {
      return { ...state, sideMenuOpen: action.payload as boolean };
    },
    [GuiStateActions.Type.SET_CHART_PARAMS]: (state, action): IGuiState => {
      return { ...state, chartDOMSize: action.payload as ChartDOMSize };
    }
  },
  initialState
);
