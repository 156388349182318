import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Collapse,
  DialogTitle as MuiDialogTitle
} from '@material-ui/core';
import FontDownloadIcon from '@material-ui/icons/FontDownload.js';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder.js';
import CloseIcon from '@material-ui/icons/Close.js';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined.js';
import { Emotion } from 'app/models/common/Emotion.js';
import {
  Check,
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles/index.js';
import type { AlertProps } from '@material-ui/lab';
import { Alert } from '@material-ui/lab';
import { isIntentTtsVerifying } from 'app/selectors/dashboardSelectors.js';

import classNames from 'classnames';
import { isUserAnyAdminSelector } from 'app/selectors/userSelectors.js';
import { DialogStatisticsClient } from 'app/apis/api.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import useStyles from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/VoiceRecognition/VoiceRecognition/style.js';
import moment from 'moment';
import uniq from 'lodash/uniq.js';
import type {
  DialogStatisticDto,
  DialogStatisticGlobalIntentPredictionDto,
  DialogStatisticIntentDto,
  SceneDialogStatisticDto
} from '@/generated-api/index.js';
import { useGetAudio } from 'app/hooks/useGetAudio.js';

export type IntentActionsAndInfoProps = {
  emotion: Emotion;
  stats: DialogStatisticDto;
  intent: DialogStatisticIntentDto;
  sceneDialogStatistic: SceneDialogStatisticDto;
};

const useTooltipStyles = makeStyles((theme) => ({
  tooltipList: {
    paddingInlineStart: `${theme.spacing(2)}px`,
    paddingInlineEnd: `${theme.spacing(2)}px`,
    maxHeight: 110,
    overflow: 'auto'
  }
}));

function IntentActionsAndInfo({
  emotion,
  intent,
  stats,
  sceneDialogStatistic
}: IntentActionsAndInfoProps): JSX.Element {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const [dataToReview, setDataToReview] = useState<{
    actualIntentId: number;
    actualIntentName: string;
    actualText: string;
    isGarbageData: boolean;
    isVerified: boolean;
    addSampleToGlobalIntent: boolean;
    actualGlobalIntentId: number;
  }>({
    actualIntentId: intent.originalIntentId,
    actualIntentName: intent.originalIntentName,
    actualText: intent.userSays,
    isGarbageData: false,
    isVerified: intent.verifyStatus,
    addSampleToGlobalIntent: false,
    actualGlobalIntentId: null
  });

  useEffect(() => {
    if (!intent) return;
    setDataToReview({
      actualIntentId: intent.originalIntentId,
      actualIntentName: intent.originalIntentName,
      actualText: intent.userSays,
      isVerified: intent.verifyStatus,
      isGarbageData: false,
      addSampleToGlobalIntent: false,
      actualGlobalIntentId: null
    });
  }, [intent]);

  const [popupVisible, handlePopupOpen, handlePopupClose] = useBoolState(false);
  const userAudioRef = useRef<HTMLAudioElement>(null);

  const { userSays: userAudioSrc } = useGetAudio(intent, sceneDialogStatistic, true);

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const emotionTooltipTitle =
    `${translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.aiEmotion))}: ` + emotion;

  const alertColor = useMemo((): AlertProps['color'] => {
    switch (emotion) {
      case Emotion.Happy:
        return 'success';

      case Emotion.Sad:
      case Emotion.Fear:
        return 'warning';

      case Emotion.Anger:
        return 'error';

      case Emotion.Neutral:
      default:
        return 'info';
    }
  }, [emotion]);

  const approveIntent = async () => {
    await DialogStatisticsClient.dialogStatisticsVerifyStatisticIntent(stats.id, intent.id, dataToReview);
    handlePopupClose();
    setDataToReview((x) => {
      return { ...x, isVerified: true };
    });
    intent.verifyStatus = true;
  };

  const isApproveLoading = useSelector(isIntentTtsVerifying);

  const isApproveVisible = useSelector(isUserAnyAdminSelector);
  const blockSize = isApproveVisible ? 3 : 4;

  const DialogTitle = (props: any) => {
    const { onClose, ...other } = props;
    return (
      <MuiDialogTitle className={classes.popupTitle} {...other}>
        <h2>{translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.reviewIntent))}</h2>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
    );
  };

  interface IntentToolTipListItemProps {
    intent: DialogStatisticIntentDto;
    index: number;
  }

  const getGlobalIntentSamples = (globalIntentPrediction: DialogStatisticGlobalIntentPredictionDto): Array<string> =>
    globalIntentPrediction.globalIntentSamples.map((s) => s.text);

  const IntentToolTipListItem = (props: IntentToolTipListItemProps) => {
    const { intent, index } = props;

    const intentInPrediction = intent.intentsInPrediction[index];

    if (!intentInPrediction) return null;

    const samples = intentInPrediction.samples.map((s) => s.text);
    const globalSamples = intentInPrediction.globalIntentsInPrediction.flatMap(getGlobalIntentSamples);

    return (
      <>
        {uniq(samples.concat(globalSamples)).map((s, i) => (
          <li key={i}>{s}</li>
        ))}
      </>
    );
  };

  const intentsInPrediction = intent.intentsInPrediction;

  const selectedActualIntent = intentsInPrediction.find((i) => i.originalIntentId === dataToReview.actualIntentId);

  const globalIntentsInPrediction = selectedActualIntent?.globalIntentsInPrediction;

  const isGlobalIntentsInPredictionAvailable = !!globalIntentsInPrediction?.length;

  useEffect(() => {
    if (!globalIntentsInPrediction) return;

    const selectedGlobalIntent = globalIntentsInPrediction.find(
      (z) => z.globalIntentId === dataToReview.actualGlobalIntentId
    );

    if (isGlobalIntentsInPredictionAvailable && dataToReview.actualGlobalIntentId && !selectedGlobalIntent) {
      setDataToReview((currentDataToReview) => ({
        ...currentDataToReview,
        actualGlobalIntentId: globalIntentsInPrediction[0].globalIntentId
      }));
    }

    // auto-select first global intent
    if (isGlobalIntentsInPredictionAvailable) {
      if (dataToReview.addSampleToGlobalIntent && !dataToReview.actualGlobalIntentId) {
        setDataToReview((currentDataToReview) => ({
          ...currentDataToReview,
          actualGlobalIntentId: globalIntentsInPrediction[0].globalIntentId
        }));
      }
    }

    // reset actualGlobalIntentId when deselecting the checkbox
    if (!dataToReview.addSampleToGlobalIntent && dataToReview.actualGlobalIntentId) {
      setDataToReview((currentDataToReview) => ({
        ...currentDataToReview,
        actualGlobalIntentId: null
      }));
    }
  }, [dataToReview, globalIntentsInPrediction, isGlobalIntentsInPredictionAvailable]);

  return (
    <>
      <Box display={'flex'} alignItems={'center'} my={1}>
        <Grid spacing={1} justifyContent="flex-start" alignItems="stretch" container>
          {isApproveVisible && (
            <Grid container wrap="nowrap" item xs={3}>
              {dataToReview.isVerified ? (
                <Tooltip
                  title={
                    <Typography>
                      {translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.dataHasBeenSubmitted))}
                    </Typography>
                  }
                >
                  <StyledAlert
                    classes={{
                      message: 'text-truncate'
                    }}
                  >
                    {translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.verified))}
                  </StyledAlert>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <Typography>
                      {translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.reviewToImprove))}
                    </Typography>
                  }
                >
                  <Button
                    fullWidth
                    startIcon={isApproveLoading ? null : <Check />}
                    variant="outlined"
                    onClick={handlePopupOpen}
                    disabled={isApproveLoading}
                  >
                    {isApproveLoading ? <CircularProgress size={20} /> : 'Review'}
                  </Button>
                </Tooltip>
              )}
            </Grid>
          )}

          <Grid item xs={blockSize}>
            <Tooltip
              title={
                <Typography>
                  {translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.numberOfRecognizedWords))}
                </Typography>
              }
            >
              <StyledAlert
                icon={<FontDownloadIcon />}
                classes={{
                  message: 'text-truncate'
                }}
              >
                {intent.userSays?.split(' ').length}{' '}
                {translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.wordsRecognized))}
              </StyledAlert>
            </Tooltip>
          </Grid>

          <Grid item xs={blockSize}>
            <Tooltip
              title={
                <Typography>
                  {translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.intentReachTime))}
                </Typography>
              }
            >
              <StyledAlert
                icon={<QueryBuilderIcon />}
                classes={{
                  message: 'text-truncate'
                }}
              >
                {Math.max(moment(intent?.start).diff(moment(stats.start), 'seconds'), 0) +
                  translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.seconds))}
              </StyledAlert>
            </Tooltip>
          </Grid>

          <Grid item xs={blockSize}>
            <Tooltip title={<Typography>{emotionTooltipTitle}</Typography>}>
              <StyledAlert
                color={alertColor}
                icon={<EmotionIcon emotion={emotion} />}
                classes={{
                  message: 'text-truncate'
                }}
              >
                {emotion}
              </StyledAlert>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>

      <Dialog fullWidth onClose={handlePopupClose} aria-labelledby="customized-dialog-title" open={popupVisible}>
        <DialogTitle onClose={handlePopupClose} disableTypography />
        <DialogContent>
          <Box>
            <Box marginBottom={2}>
              <audio controls ref={userAudioRef} src={userAudioSrc} />
            </Box>
            <Box marginBottom={2}>
              <TextField
                multiline
                maxRows={6}
                fullWidth
                label={translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.actualText))}
                value={dataToReview.actualText}
                onChange={(e) =>
                  setDataToReview((x) => {
                    return {
                      ...x,
                      actualText: e.target.value
                    };
                  })
                }
              />
            </Box>
            <Box marginBottom={2}>
              <InputLabel shrink id="actual-intent-select-label">
                {translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.actualIntent))}
              </InputLabel>
              <Select
                fullWidth
                labelId="actual-intent-select-label"
                id="actual-intent-select"
                value={dataToReview.actualIntentId ?? ''}
                displayEmpty
              >
                <MenuItem
                  key="no-actual-intent-item"
                  value={''}
                  onClick={() =>
                    setDataToReview((x) => {
                      return {
                        ...x,
                        actualIntentId: null,
                        actualIntentName: null
                      };
                    })
                  }
                >
                  None
                </MenuItem>
                {intent.intentsInPrediction.map((intentInPrediction, index) => (
                  <MenuItem
                    key={`${intentInPrediction.originalIntentName}-${intentInPrediction.originalIntentId}`}
                    value={intentInPrediction.originalIntentId}
                    onClick={() =>
                      setDataToReview((x) => {
                        return {
                          ...x,
                          actualIntentId: intent.intentsInPrediction[index].originalIntentId,
                          actualIntentName: intent.intentsInPrediction[index].originalIntentName
                        };
                      })
                    }
                  >
                    {intentInPrediction.originalIntentName}
                    <Tooltip
                      title={
                        <ul className={tooltipClasses.tooltipList}>
                          <IntentToolTipListItem intent={intent} index={index} />
                        </ul>
                      }
                      interactive
                    >
                      <Box ml={1} component={'span'}>
                        <InfoOutlinedIcon fontSize="small" />
                      </Box>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <FormControlLabel
              control={
                <Checkbox
                  name="intent-review-garbage-data"
                  checked={dataToReview.isGarbageData}
                  onChange={() => setDataToReview({ ...dataToReview, isGarbageData: !dataToReview.isGarbageData })}
                />
              }
              label={translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.garbageData))}
            />
            <br />
            {isGlobalIntentsInPredictionAvailable && (
              <FormControlLabel
                control={
                  <Checkbox
                    name="intent-review-add"
                    checked={dataToReview.addSampleToGlobalIntent}
                    onChange={() =>
                      setDataToReview({
                        ...dataToReview,
                        addSampleToGlobalIntent: !dataToReview.addSampleToGlobalIntent
                      })
                    }
                  />
                }
                label={translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.addSampleToIntent))}
              />
            )}
            <Collapse in={dataToReview.addSampleToGlobalIntent && isGlobalIntentsInPredictionAvailable}>
              <Box paddingTop={1}>
                <InputLabel shrink>Select intent</InputLabel>
                {isGlobalIntentsInPredictionAvailable && (
                  <Select fullWidth value={dataToReview.actualGlobalIntentId} displayEmpty>
                    {globalIntentsInPrediction.map((globalIntentInPrediction) => (
                      <MenuItem
                        key={globalIntentInPrediction.globalIntentId}
                        value={globalIntentInPrediction.globalIntentId}
                        onClick={() =>
                          setDataToReview((x) => {
                            return {
                              ...x,
                              actualGlobalIntentId: globalIntentInPrediction.globalIntentId
                            };
                          })
                        }
                      >
                        {globalIntentInPrediction.globalIntentName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </Box>
            </Collapse>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={isApproveLoading ? null : <Check />}
            disabled={isApproveLoading}
            onClick={approveIntent}
            variant="outlined"
            size="medium"
          >
            {translate(nameof.full<I18nDashboardNs>((n) => n.intentActionsAndInfo.submit))}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function EmotionIcon({ emotion }: { emotion: Emotion }) {
  switch (emotion) {
    case Emotion.Happy:
      return <SentimentVerySatisfied />;
    case Emotion.Anger:
      return <SentimentVeryDissatisfied />;
    case Emotion.Fear:
      return <SentimentDissatisfied />;
    case Emotion.Sad:
      return <SentimentDissatisfied />;
    case Emotion.Neutral:
      return <SentimentSatisfied />;
    default:
      return null;
  }
}

const useAlertStyles = makeStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: 'center',
    width: 'inherit'
  }
});

const StyledAlert = React.forwardRef<unknown, AlertProps>(function StyledAlert({ className, ...props }, ref) {
  const classes = useAlertStyles();
  return <Alert className={classNames(classes.root, className)} {...props} ref={ref} />;
});

export default IntentActionsAndInfo;
