import type { I18nDashboardNs } from './interfaces.js';

export const DashboardDictionaryEn: I18nDashboardNs = {
  playback: {
    play: 'Play',
    pause: 'Pause',
    stop: 'Stop'
  },
  scenarioInfoTooltipContent: {
    responseActions: 'Response actions',
    someActionsHaveBeenDuplicated: 'Some actions have been duplicated'
  },
  intentActionsAndInfo: {
    aiEmotion: 'AI emotion',
    reviewIntent: 'Review Intent',
    dataHasBeenSubmitted: 'Data has been submitted',
    verified: 'Verified',
    reviewToImprove: 'Review to improve AI models',
    review: 'Review',
    numberOfRecognizedWords: 'Number of recognized words',
    wordsRecognized: 'Words recognized',
    intentReachTime: 'Intent reach time',
    actualText: 'Actual text',
    actualIntent: 'Actual intent',
    garbageData: 'Garbage data',
    addSampleToIntent: 'Add sample to Intent',
    submit: 'Submit',
    seconds: ' seconds'
  },
  intentDescription: {
    conversationPlayback: 'Conversation playback',
    selectaConversationToPlayBack: 'Select a conversation to play back'
  },
  kpiDetails: {
    kpiDetails: 'KPI details',
    selectaConversationToSeeStatistics: 'Select a conversation to see statistics'
  },
  conversationAnalysis: {
    score: 'Score',
    category: 'Category',
    comment: 'Comment'
  },
  kpiDetailsDialog: {
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    analysisPreview: 'Analysis (preview)',
    kpiDetails: 'KPI details',
    scenario: 'Scenario',
    user: 'User',
    unknown: 'Unknown',
    start: 'Start',
    end: 'End'
  },
  procedureScores: {
    intentMatchWasCorrected: 'Intent match was corrected by AI analysis',
    intentMatch: 'Intent match',
    aiIntentDetection: 'AI intent detection',
    aiProcedureMatch: 'AI procedure match'
  },
  kpiStatisticsFilters: {
    fromDate: 'From date',
    toDate: 'To date',
    search: 'Search'
  },
  dashboardTableMenu: {
    viewDetails: 'View details',
    getTranscript: 'Get transcript',
    confirmDelete: 'Confirm Delete',
    confirmationText: 'Are you sure you want to delete the KPI?',
    thisIsDestructive: 'NB! This is destructive and will permanently delete all data related like voice recordings.',
    conversationTranscript: 'Conversation transcript'
  },
  dialogStatisticsTable: {
    organization: 'Organization',
    department: 'Department',
    scenarioName: 'Scenario name',
    userName: 'User name',
    start: 'Start',
    end: 'End',
    duration: 'Duration',
    kpiProcedureScores: 'Procedure score',
    kpiReachTime: 'Reach time score',
    kpiTransitionScores: 'Transition score'
  },
  transitionWeightsInfo: {
    intentsTransitionWeightsInfo: 'Intents Transition Weights Info',
    unableToFindCorrespondingScenario: 'Unable to find the corresponding scenario'
  }
};

export const DashboardDictionaryDe: I18nDashboardNs = {
  playback: {
    play: 'Abspielen',
    pause: 'Pause',
    stop: 'Stopp'
  },
  scenarioInfoTooltipContent: {
    responseActions: 'Antwortaktionen',
    someActionsHaveBeenDuplicated: 'Einige Aktionen wurden dupliziert'
  },
  intentActionsAndInfo: {
    aiEmotion: 'KI-Emotion',
    reviewIntent: 'Absicht überprüfen',
    dataHasBeenSubmitted: 'Daten wurden übermittelt',
    verified: 'Verifiziert',
    reviewToImprove: 'Überprüfen, um KI-Modelle zu verbessern',
    review: 'Überprüfen',
    numberOfRecognizedWords: 'Anzahl der erkannten Wörter',
    wordsRecognized: 'Erkannte Wörter',
    intentReachTime: 'Absicht-Erreichungszeit',
    actualText: 'Tatsächlicher Text',
    actualIntent: 'Tatsächliche Absicht',
    garbageData: 'Müll-Daten',
    addSampleToIntent: 'Beispiel zur Absicht hinzufügen',
    submit: 'Absenden',
    seconds: ' Sekunden'
  },
  intentDescription: {
    conversationPlayback: 'Konversations-Wiedergabe',
    selectaConversationToPlayBack: 'Wählen Sie eine Konversation zur Wiedergabe aus'
  },
  kpiDetails: {
    kpiDetails: 'KPI-Details',
    selectaConversationToSeeStatistics: 'Wählen Sie eine Konversation aus, um Statistiken zu sehen'
  },
  conversationAnalysis: {
    score: 'Punktzahl',
    category: 'Kategorie',
    comment: 'Kommentar'
  },
  kpiDetailsDialog: {
    procedureScore: 'Prozedur-Punktzahl',
    reachTimeScore: 'Erreichungszeit-Punktzahl',
    transitionScore: 'Übergangs-Punktzahl',
    analysisPreview: 'Analyse (Vorschau)',
    kpiDetails: 'KPI-Details',
    scenario: 'Szenario',
    user: 'Benutzer',
    unknown: 'Unbekannt',
    start: 'Start',
    end: 'Ende'
  },
  procedureScores: {
    intentMatchWasCorrected: 'Absichtsübereinstimmung wurde durch KI-Analyse korrigiert',
    intentMatch: 'Absichtsübereinstimmung',
    aiIntentDetection: 'KI-Absichtserkennung',
    aiProcedureMatch: 'KI-Prozedurabgleich'
  },
  kpiStatisticsFilters: {
    fromDate: 'Von Datum',
    toDate: 'Bis Datum',
    search: 'Suchen'
  },
  dashboardTableMenu: {
    viewDetails: 'Details anzeigen',
    getTranscript: 'Transkript abrufen',
    confirmDelete: 'Löschen bestätigen',
    confirmationText: 'Sind Sie sicher, dass Sie den KPI löschen möchten?',
    thisIsDestructive: 'Achtung! Dies ist destruktiv und löscht dauerhaft alle zugehörigen Daten wie Sprachaufzeichnungen.',
    conversationTranscript: 'Gesprächsprotokoll'
  },
  dialogStatisticsTable: {
    organization: 'Organisation',
    department: 'Abteilung',
    scenarioName: 'Szenarioname',
    userName: 'Benutzername',
    start: 'Start',
    end: 'Ende',
    duration: 'Dauer',
    kpiProcedureScores: 'Prozedur-Punktzahl',
    kpiReachTime: 'Erreichungszeit-Punktzahl',
    kpiTransitionScores: 'Übergangs-Punktzahl'
  },
  transitionWeightsInfo: {
    intentsTransitionWeightsInfo: 'Informationen zu Absichts-Übergangsgewichtungen',
    unableToFindCorrespondingScenario: 'Das entsprechende Szenario konnte nicht gefunden werden'
  }
};

export const DashboardDictionarySw: I18nDashboardNs = {
  playback: {
    play: 'Play',
    pause: 'Pause',
    stop: 'Stop'
  },
  scenarioInfoTooltipContent: {
    responseActions: 'Response actions',
    someActionsHaveBeenDuplicated: 'Some actions have been duplicated'
  },
  intentActionsAndInfo: {
    aiEmotion: 'AI emotion',
    reviewIntent: 'Review Intent',
    dataHasBeenSubmitted: 'Data has been submitted',
    verified: 'Verified',
    reviewToImprove: 'Review to improve AI models',
    review: 'Review',
    numberOfRecognizedWords: 'Number of recognized words',
    wordsRecognized: 'Words recognized',
    intentReachTime: 'Intent reach time',
    actualText: 'Actual text',
    actualIntent: 'Actual intent',
    garbageData: 'Garbage data',
    addSampleToIntent: 'Add sample to Intent',
    submit: 'Submit',
    seconds: ' seconds'
  },
  intentDescription: {
    conversationPlayback: 'Conversation playback',
    selectaConversationToPlayBack: 'Select a conversation to play back'
  },
  kpiDetails: {
    kpiDetails: 'KPI details',
    selectaConversationToSeeStatistics: 'Select a conversation to see statistics'
  },
  conversationAnalysis: {
    score: 'Score',
    category: 'Category',
    comment: 'Comment'
  },
  kpiDetailsDialog: {
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    analysisPreview: 'Analysis (preview)',
    kpiDetails: 'KPI details',
    scenario: 'Scenario',
    user: 'User',
    unknown: 'Unknown',
    start: 'Start',
    end: 'End'
  },
  procedureScores: {
    intentMatchWasCorrected: 'Intent match was corrected by AI analysis',
    intentMatch: 'Intent match',
    aiIntentDetection: 'AI intent detection',
    aiProcedureMatch: 'AI procedure match'
  },
  kpiStatisticsFilters: {
    fromDate: 'From date',
    toDate: 'To date',
    search: 'Search'
  },
  dashboardTableMenu: {
    viewDetails: 'View details',
    getTranscript: 'Get transcript',
    confirmDelete: 'Confirm Delete',
    confirmationText: 'Are you sure you want to delete the KPI?',
    thisIsDestructive: 'NB! This is destructive and will permanently delete all data related like voice recordings.',
    conversationTranscript: 'Conversation transcript'
  },
  dialogStatisticsTable: {
    organization: 'Organization',
    department: 'Department',
    scenarioName: 'Scenario name',
    userName: 'User name',
    start: 'Start',
    end: 'End',
    duration: 'Duration',
    kpiProcedureScores: 'Procedure score',
    kpiReachTime: 'Reach time score',
    kpiTransitionScores: 'Transition score'
  },
  transitionWeightsInfo: {
    intentsTransitionWeightsInfo: 'Intents Transition Weights Info',
    unableToFindCorrespondingScenario: 'Unable to find the corresponding scenario'
  }
};

export const DashboardDictionaryNo: I18nDashboardNs = {
  playback: {
    play: 'Spill',
    pause: 'Pause',
    stop: 'Stopp'
  },
  scenarioInfoTooltipContent: {
    responseActions: 'Responshandlinger',
    someActionsHaveBeenDuplicated: 'Noen handlinger har duplikater'
  },
  intentActionsAndInfo: {
    aiEmotion: 'AI-emosjon',
    reviewIntent: 'Verifiser som treningsdata',
    dataHasBeenSubmitted: 'Treningsdata har blitt sendt inn',
    verified: 'Verifisert',
    reviewToImprove: 'Verifiser for å forbedre AI-modellene',
    review: 'Verifiser',
    numberOfRecognizedWords: 'Antall gjenkjente ord',
    wordsRecognized: 'Ord gjenkjent',
    intentReachTime: 'Intent-tid',
    actualText: 'Faktisk tekst',
    actualIntent: 'Faktisk Intent',
    garbageData: 'Søppeldata',
    addSampleToIntent: 'Legg til tekstfrase til Intent',
    submit: 'Send inn',
    seconds: ' sekunder'
  },
  intentDescription: {
    conversationPlayback: 'Spill av samtale',
    selectaConversationToPlayBack: 'Velg en samtale i listen for å spille av'
  },
  kpiDetails: {
    kpiDetails: 'KPI-detaljer',
    selectaConversationToSeeStatistics: 'Velg en samtale i listen for å se statistikk'
  },
  conversationAnalysis: {
    score: 'Score',
    category: 'Kategori',
    comment: 'Kommentar'
  },
  kpiDetailsDialog: {
    procedureScore: 'Indeksscore',
    reachTimeScore: 'Tidsscore',
    transitionScore: 'Overgangsscore',
    analysisPreview: 'Analyse (preview)',
    kpiDetails: 'KPI-detaljer',
    scenario: 'Scenario',
    user: 'Bruker',
    unknown: 'Ukjent',
    start: 'Start',
    end: 'Slutt'
  },
  procedureScores: {
    intentMatchWasCorrected: 'Intent-match ble korrigert med AI-analyse',
    intentMatch: 'Intentmatch',
    aiIntentDetection: 'Intent detektert av AI',
    aiProcedureMatch: 'Prosedyrematch detektert av AI'
  },
  kpiStatisticsFilters: {
    fromDate: 'Fra dato',
    toDate: 'Til dato',
    search: 'Søk'
  },
  dashboardTableMenu: {
    viewDetails: 'Se detaljer',
    getTranscript: 'Samtaletranskripsjon',
    confirmDelete: 'Bekreft sletting',
    confirmationText: 'Er du sikker på du vil slette?',
    thisIsDestructive: 'NB! Dette er destruktivt og vil slette alle tilhørende data som f.eks. lydopptak.',
    conversationTranscript: 'Samtaletranskripsjon'
  },
  dialogStatisticsTable: {
    organization: 'Organisasjon',
    department: 'Avdeling',
    scenarioName: 'Scenarionavn',
    userName: 'Brukernavn',
    start: 'Start',
    end: 'Slutt',
    duration: 'Varighet',
    kpiProcedureScores: 'Indeksscore',
    kpiReachTime: 'Tidsscore',
    kpiTransitionScores: 'Overgangsscore'
  },
  transitionWeightsInfo: {
    intentsTransitionWeightsInfo: 'Vekting av overganger',
    unableToFindCorrespondingScenario: 'Ikke i stand til å finne tilhørende scenario'
  }
};

export const DashboardDictionaryNl: I18nDashboardNs = {
  playback: {
    play: 'Play',
    pause: 'Pause',
    stop: 'Stop'
  },
  scenarioInfoTooltipContent: {
    responseActions: 'Response actions',
    someActionsHaveBeenDuplicated: 'Some actions have been duplicated'
  },
  intentActionsAndInfo: {
    aiEmotion: 'AI emotion',
    reviewIntent: 'Review Intent',
    dataHasBeenSubmitted: 'Data has been submitted',
    verified: 'Verified',
    reviewToImprove: 'Review to improve AI models',
    review: 'Review',
    numberOfRecognizedWords: 'Number of recognized words',
    wordsRecognized: 'Words recognized',
    intentReachTime: 'Intent reach time',
    actualText: 'Actual text',
    actualIntent: 'Actual intent',
    garbageData: 'Garbage data',
    addSampleToIntent: 'Add sample to Intent',
    submit: 'Submit',
    seconds: ' seconds'
  },
  intentDescription: {
    conversationPlayback: 'Conversation playback',
    selectaConversationToPlayBack: 'Select a conversation to play back'
  },
  kpiDetails: {
    kpiDetails: 'KPI details',
    selectaConversationToSeeStatistics: 'Select a conversation to see statistics'
  },
  conversationAnalysis: {
    score: 'Score',
    category: 'Category',
    comment: 'Comment'
  },
  kpiDetailsDialog: {
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    analysisPreview: 'Analysis (preview)',
    kpiDetails: 'KPI details',
    scenario: 'Scenario',
    user: 'User',
    unknown: 'Unknown',
    start: 'Start',
    end: 'End'
  },
  procedureScores: {
    intentMatchWasCorrected: 'Intent match was corrected by AI analysis',
    intentMatch: 'Intent match',
    aiIntentDetection: 'AI intent detection',
    aiProcedureMatch: 'AI procedure match'
  },
  kpiStatisticsFilters: {
    fromDate: 'From date',
    toDate: 'To date',
    search: 'Search'
  },
  dashboardTableMenu: {
    viewDetails: 'View details',
    getTranscript: 'Get transcript',
    confirmDelete: 'Confirm Delete',
    confirmationText: 'Are you sure you want to delete the KPI?',
    thisIsDestructive: 'NB! This is destructive and will permanently delete all data related like voice recordings.',
    conversationTranscript: 'Conversation transcript'
  },
  dialogStatisticsTable: {
    organization: 'Organization',
    department: 'Department',
    scenarioName: 'Scenario name',
    userName: 'User name',
    start: 'Start',
    end: 'End',
    duration: 'Duration',
    kpiProcedureScores: 'Procedure score',
    kpiReachTime: 'Reach time score',
    kpiTransitionScores: 'Transition score'
  },
  transitionWeightsInfo: {
    intentsTransitionWeightsInfo: 'Intents Transition Weights Info',
    unableToFindCorrespondingScenario: 'Unable to find the corresponding scenario'
  }
};

export const DashboardDictionaryIs: I18nDashboardNs = {
  playback: {
    play: 'Play',
    pause: 'Pause',
    stop: 'Stop'
  },
  scenarioInfoTooltipContent: {
    responseActions: 'Response actions',
    someActionsHaveBeenDuplicated: 'Some actions have been duplicated'
  },
  intentActionsAndInfo: {
    aiEmotion: 'AI emotion',
    reviewIntent: 'Review Intent',
    dataHasBeenSubmitted: 'Data has been submitted',
    verified: 'Verified',
    reviewToImprove: 'Review to improve AI models',
    review: 'Review',
    numberOfRecognizedWords: 'Number of recognized words',
    wordsRecognized: 'Words recognized',
    intentReachTime: 'Intent reach time',
    actualText: 'Actual text',
    actualIntent: 'Actual intent',
    garbageData: 'Garbage data',
    addSampleToIntent: 'Add sample to Intent',
    submit: 'Submit',
    seconds: ' seconds'
  },
  intentDescription: {
    conversationPlayback: 'Conversation playback',
    selectaConversationToPlayBack: 'Select a conversation to play back'
  },
  kpiDetails: {
    kpiDetails: 'KPI details',
    selectaConversationToSeeStatistics: 'Select a conversation to see statistics'
  },
  conversationAnalysis: {
    score: 'Score',
    category: 'Category',
    comment: 'Comment'
  },
  kpiDetailsDialog: {
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    analysisPreview: 'Analysis (preview)',
    kpiDetails: 'KPI details',
    scenario: 'Scenario',
    user: 'User',
    unknown: 'Unknown',
    start: 'Start',
    end: 'End'
  },
  procedureScores: {
    intentMatchWasCorrected: 'Intent match was corrected by AI analysis',
    intentMatch: 'Intent match',
    aiIntentDetection: 'AI intent detection',
    aiProcedureMatch: 'AI procedure match'
  },
  kpiStatisticsFilters: {
    fromDate: 'From date',
    toDate: 'To date',
    search: 'Search'
  },
  dashboardTableMenu: {
    viewDetails: 'View details',
    getTranscript: 'Get transcript',
    confirmDelete: 'Confirm Delete',
    confirmationText: 'Are you sure you want to delete the KPI?',
    thisIsDestructive: 'NB! This is destructive and will permanently delete all data related like voice recordings.',
    conversationTranscript: 'Conversation transcript'
  },
  dialogStatisticsTable: {
    organization: 'Organization',
    department: 'Department',
    scenarioName: 'Scenario name',
    userName: 'User name',
    start: 'Start',
    end: 'End',
    duration: 'Duration',
    kpiProcedureScores: 'Procedure score',
    kpiReachTime: 'Reach time score',
    kpiTransitionScores: 'Transition score'
  },
  transitionWeightsInfo: {
    intentsTransitionWeightsInfo: 'Intents Transition Weights Info',
    unableToFindCorrespondingScenario: 'Unable to find the corresponding scenario'
  }
};

export const DashboardDictionaryUk: I18nDashboardNs = {
  playback: {
    play: 'Play',
    pause: 'Pause',
    stop: 'Stop'
  },
  scenarioInfoTooltipContent: {
    responseActions: 'Response actions',
    someActionsHaveBeenDuplicated: 'Some actions have been duplicated'
  },
  intentActionsAndInfo: {
    aiEmotion: 'AI emotion',
    reviewIntent: 'Review Intent',
    dataHasBeenSubmitted: 'Data has been submitted',
    verified: 'Verified',
    reviewToImprove: 'Review to improve AI models',
    review: 'Review',
    numberOfRecognizedWords: 'Number of recognized words',
    wordsRecognized: 'Words recognized',
    intentReachTime: 'Intent reach time',
    actualText: 'Actual text',
    actualIntent: 'Actual intent',
    garbageData: 'Garbage data',
    addSampleToIntent: 'Add sample to Intent',
    submit: 'Submit',
    seconds: ' seconds'
  },
  intentDescription: {
    conversationPlayback: 'Conversation playback',
    selectaConversationToPlayBack: 'Select a conversation to play back'
  },
  kpiDetails: {
    kpiDetails: 'KPI details',
    selectaConversationToSeeStatistics: 'Select a conversation to see statistics'
  },
  conversationAnalysis: {
    score: 'Score',
    category: 'Category',
    comment: 'Comment'
  },
  kpiDetailsDialog: {
    procedureScore: 'Procedure score',
    reachTimeScore: 'Reach time score',
    transitionScore: 'Transition score',
    analysisPreview: 'Analysis (preview)',
    kpiDetails: 'KPI details',
    scenario: 'Scenario',
    user: 'User',
    unknown: 'Unknown',
    start: 'Start',
    end: 'End'
  },
  procedureScores: {
    intentMatchWasCorrected: 'Intent match was corrected by AI analysis',
    intentMatch: 'Intent match',
    aiIntentDetection: 'AI intent detection',
    aiProcedureMatch: 'AI procedure match'
  },
  kpiStatisticsFilters: {
    fromDate: 'From date',
    toDate: 'To date',
    search: 'Search'
  },
  dashboardTableMenu: {
    viewDetails: 'View details',
    getTranscript: 'Get transcript',
    confirmDelete: 'Confirm Delete',
    confirmationText: 'Are you sure you want to delete the KPI?',
    thisIsDestructive: 'NB! This is destructive and will permanently delete all data related like voice recordings.',
    conversationTranscript: 'Conversation transcript'
  },
  dialogStatisticsTable: {
    organization: 'Organization',
    department: 'Department',
    scenarioName: 'Scenario name',
    userName: 'User name',
    start: 'Start',
    end: 'End',
    duration: 'Duration',
    kpiProcedureScores: 'Procedure score',
    kpiReachTime: 'Reach time score',
    kpiTransitionScores: 'Transition score'
  },
  transitionWeightsInfo: {
    intentsTransitionWeightsInfo: 'Intents Transition Weights Info',
    unableToFindCorrespondingScenario: 'Unable to find the corresponding scenario'
  }
};
