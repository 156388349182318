import { Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import type { GridCellParams, GridColumns, GridRowParams } from '@material-ui/data-grid';
import type { FunctionComponent } from 'react';
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';

import type { Order, OrderBy, ITableData } from './models/models.js';

import AppRoutes from 'app/utils/routes.js';
import { DateFormat } from 'app/app.constants.js';
import { DasboardSelectors } from 'app/selectors/index.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import useDebouncedValue from 'app/hooks/useDebouncedValue.js';
import * as DashboardActions from 'app/actions/dasboard/dashboardActions.js';
import { computeDuration } from 'app/components/DashboardPage/Table/utils/utils.js';
import type { DialogStatisticDto, PartialListDialogStatisticDto } from '@/generated-api/index.js';
import { SortType } from '@/generated-api/index.js';
import DashboardTableMenu from 'app/components/DashboardPage/Table/components/DashboardTableMenu.js';
import KpiStatisticsFilters from 'app/components/DashboardPage/Table/components/ kpiStatisticsFilters.js';
import LinearProgressWithLabel from 'app/components/utils/styledTableElements/LinearProgressWithLabel/index.js';
import { CurrentUserSelector, isUserOwnerSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

const RowsPerPage = 10;

type TableProps = {
  handleDisplayKpiDetails: (dialogStatistic: DialogStatisticDto) => void;
  setSelectedDialogStatistic: (dialogStatistic: DialogStatisticDto) => void;
};

const DialogStatisticsTable: FunctionComponent<TableProps> = ({
  handleDisplayKpiDetails,
  setSelectedDialogStatistic
}) => {
  const [search, setSearch] = useState<string>();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<OrderBy>('start');
  const [timeFrom, setTimeFrom] = useState<Date | null>(null);
  const [timeTo, setTimeTo] = useState<Date | null>(null);
  // TODO Convert to React Query (dialogstatistics is probably not used elsewhere?) instead of just refreshing the redux + http call
  const [refresh, setRefresh] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsPerPage);

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const isUserOwner = useSelector(isUserOwnerSelector);
  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const dispatch = useDispatch();

  const stats: { isLoading: boolean; dialogStatistics: PartialListDialogStatisticDto } = useSelector(
    DasboardSelectors.dialogStatisticsResponse
  );

  const selectedRow = useSelector(DasboardSelectors.displayingDialogSelector);

  const history = useHistory();

  const searchDebounced = useDebouncedValue(search, 300);

  useEffect(() => {
    dispatch(
      DashboardActions.getDialogStatistics({
        skip: rowsPerPage * page,
        take: rowsPerPage,
        order: order == 'asc' ? SortType.NUMBER_0 : SortType.NUMBER_1,
        orderBy,
        from: timeFrom,
        to: timeTo,
        search: searchDebounced
      })
    );
  }, [dispatch, order, orderBy, timeFrom, timeTo, page, searchDebounced, rowsPerPage, refresh]);

  useEffect(() => {
    setPage(0);
  }, [timeFrom, timeTo]);

  const sortedRows: ITableData[] = useMemo(() => {
    return stats.dialogStatistics.data.map((dialogStatistic) => {
      const {
        end,
        start,
        sceneName,
        respondent,
        companyName,
        departmentName,
        procedureScores,
        reachTimeScores,
        transitionScores,
        procedureScoresMax,
        reachTimeScoresMax,
        ...restProps
      } = dialogStatistic;

      const userName = respondent?.userName ?? 'Unknown';

      const data: ITableData = {
        ...restProps,
        kpi: null,
        id: `${restProps.id}`,
        duration: computeDuration(new Date(start), new Date(end)),
        start: new Date(start),
        end: new Date(end),
        userName,
        startSceneName: sceneName,
        companyName,
        departmentName,
        procedureScores,
        reachTimeScores,
        transitionScores,
        procedureScoresMax,
        reachTimeScoresMax
      };

      return data;
    });
  }, [stats.dialogStatistics.data]);

  const handleTableRowClick = (params: GridRowParams) => {
    const id = params.id as string;

    // Search for the selected stat in `sortedRows` and update `selectedDialogStatistic`
    const clickedRow = sortedRows.find((row) => row.id === id);
    if (clickedRow) {
      setSelectedDialogStatistic({
        id: parseInt(clickedRow.id),
        companyName: clickedRow.companyName,
        departmentName: clickedRow.departmentName,
        sceneName: clickedRow.startSceneName,
        start: clickedRow.start?.toString(),
        end: clickedRow.end?.toString(),
        reachTimeScores: clickedRow.reachTimeScores,
        reachTimeScoresMax: clickedRow.reachTimeScoresMax,
        transitionScores: clickedRow.transitionScores,
        procedureScores: clickedRow.procedureScores,
        procedureScoresMax: clickedRow.procedureScoresMax,
        respondent: {
          userName: clickedRow.userName
        }
      });
    }

    if (`${selectedRow?.id}` === id) {
      // dispatch(DashboardActions.setSelectedRow(undefined));
      history.push(AppRoutes.Dashboard);
    } else {
      // dispatch(DashboardActions.setSelectedRow(`${id}`));
      history.push(`${AppRoutes.Dashboard}/${id}`);
    }
  };

  const isLoaded = !stats.isLoading;

  const formatDate = useFormatDate(DateFormat.Time);

  const renderDateCell = useCallback(
    (data) => {
      if (!data.value) return null;

      return formatDate(data.value as string);
    },
    [formatDate]
  );

  const onSortModelChange = useCallback((model) => {
    const initialModel = model[0];

    if (!initialModel) return;

    const { field, sort } = initialModel;

    setOrder(sort);
    setOrderBy(field as OrderBy);
  }, []);

  const refreshStatistics = () => setRefresh((val) => !val);

  const columns: GridColumns = useMemo(() => {
    const cols: GridColumns = [
      {
        filterable: false,
        disableColumnMenu: true,
        field: 'startSceneName',
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.scenarioName)),
        flex: 2.5
      },
      {
        disableColumnMenu: true,
        filterable: false,
        field: 'userName',
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.userName)),
        flex: 1
      },
      {
        filterable: false,
        disableColumnMenu: true,
        field: 'start',
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.start)),
        flex: 1,
        renderCell: renderDateCell
      },
      {
        filterable: false,
        disableColumnMenu: true,
        field: 'end',
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.end)),
        flex: 1,
        renderCell: renderDateCell
      },
      {
        disableColumnMenu: true,
        filterable: false,
        field: 'duration',
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.duration)),
        flex: 0.5
      },
      {
        filterable: false,
        disableColumnMenu: true,
        field: 'procedureScores',
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiProcedureScores)),
        flex: 1,
        renderCell: (data: GridCellParams) => {
          const dialogStatistic = data.row as ITableData;

          return (
            <LinearProgressWithLabel
              value={dialogStatistic.procedureScores}
              scoresMax={dialogStatistic.procedureScoresMax}
            />
          );
        }
      },
      {
        disableColumnMenu: true,
        filterable: false,
        field: 'reachTimeScores',
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiReachTime)),
        flex: 1,
        renderCell: (data: GridCellParams) => {
          const dialogStatistic = data.row as ITableData;

          return (
            <LinearProgressWithLabel
              value={dialogStatistic.reachTimeScores}
              scoresMax={dialogStatistic.reachTimeScoresMax}
            />
          );
        }
      },
      {
        filterable: false,
        field: 'transitionScores',
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiTransitionScores)),
        flex: 0.8
      },
      {
        field: 'kpi',
        width: 50,
        headerName: ' ',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (data: GridCellParams) => {
          const dialogStatistic = data.row as ITableData;

          return (
            <DashboardTableMenu
              refreshStatistics={refreshStatistics}
              dialogStatistic={dialogStatistic}
              handleDisplayKpiDetails={handleDisplayKpiDetails}
            />
          );
        }
      }
    ];

    if (isUserSuperAdmin) {
      cols.splice(0, 0, {
        disableColumnMenu: true,
        field: 'companyName',
        filterable: false,
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.organization)),
        flex: 1
      });

      cols.splice(1, 0, {
        disableColumnMenu: true,
        field: 'departmentName',
        filterable: false,
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.department)),
        flex: 1
      });
    }

    if (isUserOwner) {
      cols.splice(0, 0, {
        disableColumnMenu: true,
        field: 'departmentName',
        filterable: false,
        headerName: translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.department)),
        flex: 1
      });
    }

    return cols;
  }, [translate, renderDateCell, isUserOwner, isUserSuperAdmin, handleDisplayKpiDetails]);

  return (
    <Paper data-cy="table" elevation={0}>
      <KpiStatisticsFilters
        search={search}
        setSearch={setSearch}
        onSetTimeFrom={setTimeFrom}
        onSetTimeTo={setTimeTo}
        timeTo={timeTo}
        timeFrom={timeFrom}
      />
      <SimpleDataGrid
        autoHeight
        page={page}
        columns={columns}
        rows={sortedRows}
        pageSize={rowsPerPage}
        onPageChange={setPage}
        onRowClick={handleTableRowClick}
        rowsPerPageOptions={[RowsPerPage, RowsPerPage * 2, RowsPerPage * 3]}
        loading={!isLoaded}
        paginationMode="server"
        sortingMode="server"
        rowCount={stats.dialogStatistics.total}
        onSortModelChange={onSortModelChange}
        onPageSizeChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default DialogStatisticsTable;
