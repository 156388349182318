import type {
  DialogStatisticIntentDto,
  SceneDialogStatisticDto,
  SynthesizeSpeechCommand
} from '@/generated-api/index.js';
import { Emotion, Sex } from '@/generated-api/index.js';
import type { QueryClient } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { getSynthesizeSpeechAudioQuery } from 'app/queries/speechQueries.js';
import { getVoiceAudioQuery } from 'app/queries/voicesQueries.js';
import { useMemo } from 'react';

const getPayload = (
  intent: DialogStatisticIntentDto,
  sceneDialogStatistic: SceneDialogStatisticDto,
  userAudio?: boolean
): SynthesizeSpeechCommand | undefined => {
  if (userAudio) {
    return !!intent.voiceId
      ? undefined // Voice exists, use that instead
      : {
          text: !intent.userSays || intent.userSays.length === 0 ? 'No user say' : intent.userSays,
          emotion: Emotion.NUMBER_0,
          language: sceneDialogStatistic.langCode,
          sex: sceneDialogStatistic.sex === Sex.NUMBER_0 ? Sex.NUMBER_1 : Sex.NUMBER_0, // ????
          service: sceneDialogStatistic.ttsProvider,
          voiceName: sceneDialogStatistic.voiceName,
          intentId: intent.originalIntentId
        };
  } else {
    return {
      text: !intent.responseText || intent.responseText.length === 0 ? 'No response' : intent.responseText,
      emotion: intent.emotion,
      language: sceneDialogStatistic.langCode,
      sex: sceneDialogStatistic.sex,
      service: sceneDialogStatistic.ttsProvider,
      voiceName: sceneDialogStatistic.voiceName,
      intentId: intent.originalIntentId
    };
  }
};

export const prefetchNextAudio = (
  queryClient: QueryClient,
  intent: DialogStatisticIntentDto,
  sceneDialogStatistic: SceneDialogStatisticDto,
  userAudio?: boolean
) => {
  // No await here, "just do it" aka prefetch
  const userPayload = getPayload(intent, sceneDialogStatistic, userAudio);
  const aiPayload = getPayload(intent, sceneDialogStatistic);
  if (userPayload) {
    const synthesizedAudioQuery = getSynthesizeSpeechAudioQuery(userPayload);
    queryClient.prefetchQuery(synthesizedAudioQuery);
  } else {
    const voiceAudioQuery = getVoiceAudioQuery(intent.voiceId);
    queryClient.prefetchQuery(voiceAudioQuery);
  }

  const aiSynthesizedAudioQuery = getSynthesizeSpeechAudioQuery(aiPayload);
  queryClient.prefetchQuery(aiSynthesizedAudioQuery);
};

export const useGetAudio = (
  intent: DialogStatisticIntentDto | undefined,
  sceneDialogStatistic: SceneDialogStatisticDto | undefined,
  userAudio?: boolean
): { userSays: string | null; aiResponse: string | null } => {
  const userPayload =
    !!intent && !!sceneDialogStatistic ? getPayload(intent, sceneDialogStatistic, userAudio) : undefined;
  const aiPayload = !!intent && !!sceneDialogStatistic ? getPayload(intent, sceneDialogStatistic) : undefined;

  const userSynthesizedAudioQuery = useMemo(() => getSynthesizeSpeechAudioQuery(userPayload), [userPayload]);
  const { data: userSynthesizedAudio } = useQuery(userSynthesizedAudioQuery);
  const voiceAudioQuery = useMemo(() => getVoiceAudioQuery(intent?.voiceId), [intent?.voiceId]);
  const { data: voiceAudio } = useQuery(voiceAudioQuery);

  const aiSynthesizedAudioQuery = useMemo(() => getSynthesizeSpeechAudioQuery(aiPayload), [aiPayload]);
  const { data: aiSynthesizedAudio } = useQuery(aiSynthesizedAudioQuery);

  return { userSays: userSynthesizedAudio ?? voiceAudio ?? null, aiResponse: aiSynthesizedAudio ?? null };
};
