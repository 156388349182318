import type { BlockDto, ProcessResultDto } from '@/generated-api/index.js';

export type DialogProcessorStoredBlock = Omit<ProcessResultDto, 'lastBlock'> & BlockDto; // TODO Changed from BlockLink (had block id + scene id) to BlockDto ??!?

export enum IntentProcessingProvider {
  DEFAULT,
  INTENT_RECOGNITION_WITH_AI
}

export interface IDProcessState {
  sessionId: string;
  blocks: DialogProcessorStoredBlocks;
  isFallback: boolean;
}

export interface DialogProcessorStoredBlocks {
  lastBlock: null | DialogProcessorStoredBlock;
  currentBlock: null | DialogProcessorStoredBlock;
}
