import React from 'react';
import { List, Collapse, ListItem, ListItemText } from '@material-ui/core';

import type { SceneGroupWithScenes, SceneWithCompanyName } from './types.js';

import type { SceneDto } from '@/generated-api/scenes/api.js';
import SceneGroupContainer from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/SceneGroupContainer.js';
import EditableSceneGroupListItem from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/EditableSceneGroupListItem.js';
import NavigationDrawerCompanyScenariosListItem from './NavigationDrawerCompanyScenariosListItem.js';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

type NavigationDrawerCompanyScenariosProps = {
  companyId: number;
  selectedItem: number;
  handleClickOnSubmenu: (_key: number) => void;
  initialScene: SceneWithCompanyName;
  open: boolean;
  isShowGroupsEnabled: boolean;
  groupsWithScenes: SceneGroupWithScenes[];
  classes: {
    selected: string;
    withB: string;
    withTBP: string;
    sharedScenario: string;
    subMenuWidth: string;
    white: string;
  };
  sceneId: number;
  selectedSceneGroupId: number;
  contextMenu: (sceneGroupId?: number) => JSX.Element;
  handleRightClick: (event: React.MouseEvent<HTMLElement>, scene: SceneDto) => void;
  handleSelectScenario: () => void;
  handleClickOnGroupSubmenu: (_key: number) => void;
  isGroupAddEnabled: boolean;
  closeAddGroup: () => void;
  onCreateSceneGroup: (sceneGroupName: any) => Promise<void>;
  scenes: SceneWithCompanyName[];
  sceneIdsInGroups: number[];
  displayCompanyName: boolean;
};

const NavigationDrawerCompanyScenarios: React.FC<NavigationDrawerCompanyScenariosProps> = ({
  companyId,
  selectedItem,
  handleClickOnSubmenu,
  initialScene,
  open,
  isShowGroupsEnabled,
  groupsWithScenes,
  classes,
  sceneId,
  selectedSceneGroupId,
  contextMenu,
  handleRightClick,
  handleSelectScenario,
  handleClickOnGroupSubmenu,
  isGroupAddEnabled,
  closeAddGroup,
  onCreateSceneGroup,
  scenes,
  sceneIdsInGroups,
  displayCompanyName
}): JSX.Element => (
  <div>
    {displayCompanyName && (
      <ListItem
        selected={selectedItem == companyId}
        data-cy="companyList"
        button
        onClick={() => handleClickOnSubmenu(companyId)}
      >
        <ListItemText primary={initialScene.companyName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
    )}
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" data-cy="listFoScnario" disablePadding>
        {isShowGroupsEnabled &&
          groupsWithScenes.map((sceneGroup) => (
            <SceneGroupContainer
              classes={classes}
              sceneId={sceneId}
              key={sceneGroup.id}
              selectedSceneGroupId={selectedSceneGroupId}
              sceneGroup={sceneGroup}
              renderContextMenu={contextMenu}
              handleRightClick={handleRightClick}
              handleSelectScenario={handleSelectScenario}
              handleClickOnGroupSubmenu={handleClickOnGroupSubmenu}
            />
          ))}
        {isGroupAddEnabled && (
          <EditableSceneGroupListItem classes={classes} onClose={closeAddGroup} handleSave={onCreateSceneGroup} />
        )}
        {scenes
          .filter((x) => (isShowGroupsEnabled ? !sceneIdsInGroups.includes(x.id) : true))
          .map((item) => (
            <NavigationDrawerCompanyScenariosListItem
              item={item}
              key={item.id}
              classes={classes}
              companyId={companyId}
              handleRightClick={handleRightClick}
              handleSelectScenario={handleSelectScenario}
              sceneId={sceneId}
            />
          ))}
        {contextMenu()}
      </List>
    </Collapse>
  </div>
);

export default NavigationDrawerCompanyScenarios;
