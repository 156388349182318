import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scenariosSelector } from 'app/selectors/scenarioSelectors.js';
import { DialogSessionClient, SpeechClient } from 'app/apis/api.js';
import type { TtsProvider } from '@/generated-api/index.js';
import { VoiceRecognitionActions } from 'app/actions/voiceRecognition/voiceRecognitionActions.js';
import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';
import { ScenarioChartStoreActions } from 'app/actions/scenarios/scenarioChartStoreAction.js';
import { ScenarioChartSelectors, ScenarioSelectors, UserSelectors } from 'app/selectors/index.js';
import type { AxiosResponse } from 'axios';
import { DialogProcessorActions } from 'app/actions/dialogprocessor/dialogProcessorActions.js';
import { LoadingStatus } from 'app/store/types.js';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
import { debounce } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { voicesQuery } from 'app/queries/speechQueries.js';
import DetectRTC from 'detectrtc';
import { addWarning } from 'app/actions/snackbar/snackbar.js';
import logger from 'app/utils/logger.js';
import { useAudioStreamingHub } from '../hooks/audioStreamingHub.js';

import AudioActions from 'app/actions/audio/audioAction.js';


import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const useScenarioIntro = (startAudioConnection) => {
  const { data: allVoiceOptions } = useQuery(voicesQuery);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);
 

  const currentScenario = useSelector(ScenarioSelectors.currentScenario);
  const startedNodeId = useSelector(ScenarioChartSelectors.startNodeId);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const currentUser = useSelector(UserSelectors.CurrentUserSelector);

  const scenarios = useSelector(scenariosSelector);

  const useStreaming = useSelector(ScenarioSelectors.isStreamingEnabledForScenario);

  const voiceOptions = useMemo(() => {
    if (!allVoiceOptions) return [];
    return allVoiceOptions.filter((v) => !v.companyId || v.companyId === currentUser.companyId);
  }, [allVoiceOptions, currentUser.companyId]);

  const [isScenarioIntroPlaying, setIsContextPlaying] = useState(false);

  const dispatch = useDispatch();

  const playScenario = useCallback(async () => {
    setLoadingStatus(LoadingStatus.Loading);

    try {
      const {data: sessionId}: AxiosResponse<string> = await DialogSessionClient.dialogSessionInitSession(
        currentScenario.sceneId,
        currentScenario.versionId
      );

      dispatch(DialogProcessorActions.setSessionId(sessionId));
      
      if (useStreaming) {
        startAudioConnection(sessionId, (audioData) => {       
          if (useStreaming && audioData) {
            dispatch(AudioActions.setAudioAIResponse(audioData.audio));
          }       
        });
      }

      dispatch(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.BeforeUserIsSpeaking));
      // logger.log('setting recog status to UIS in playScenario');

      setLoadingStatus(LoadingStatus.Succeeded);
    } catch (error) {
      logger.log(error);

      setLoadingStatus(LoadingStatus.Failed);
    }

    if (!!startedNodeId) {
      dispatch(
        ScenarioChartStoreActions.setNodeProperty({
          id: Number.parseInt(startedNodeId, 10),
          propertyName: 'isDialogProcessOnTheNode',
          propertyValue: true
        })
      );
    }
  }, [dispatch, startedNodeId, currentScenario]);

  const playIntroThenStart = async () => {
    try {
      logger.log('Fetching micophone audio stream to check for permission and microphone exists');
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch {
      // do nothing
    }
    DetectRTC.load(() => {
      const hasMicrophone = DetectRTC.hasMicrophone;
      const isMicrophoneGranted = DetectRTC.isWebsiteHasMicrophonePermissions;

      if (hasMicrophone && isMicrophoneGranted) {
        playIntroThenStartMain();
      } else {
        let link = 'https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop';
        if (DetectRTC.browser.isEdge || navigator.userAgent.indexOf('Edg') > -1) {
          link =
            'https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857';
        }
        if (DetectRTC.browser.isFirefox) {
          link = 'https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions';
        }
        if (DetectRTC.browser.isSafari) {
          link = 'https://support.apple.com/guide/safari/websites-ibrwe2159f50/mac';
        }
        const text =
          hasMicrophone && !isMicrophoneGranted
            ? translate(nameof.full<I18nScenarioEditorNs>((n) => n.useScenarioIntro.microphonePermissionNotGranted))
            : translate(nameof.full<I18nScenarioEditorNs>((n) => n.useScenarioIntro.microphoneNotDetected));
        dispatch(
          addWarning({
            warningText: text,
            timer: 99999999,
            link,
            linkText: translate(nameof.full<I18nScenarioEditorNs>((n) => n.useScenarioIntro.followThisGuide))
          })
        );
        logger.warn(text, {
          link,
          browser: DetectRTC.browser,
          inputDevices: DetectRTC.audioInputDevices,
          isMobile: DetectRTC.isMobileDevice,
          supportsWebRtc: DetectRTC.isWebRTCSupported
          // TODO More (audio)
        });
      }
    });
  };

  const playIntroThenStartMain = useCallback(async () => {
    const scenario = scenarios.find((s) => s.id === currentScenario.sceneId);

    const scenarioIntroText = scenario.scenarioContextIntro;

    if (!scenario || !scenarioIntroText || !scenario.playScenarioContextOn) {
      await playScenario();
      return;
    }

    setIsContextPlaying(true);

    // delay the loading indicator by 200ms, cancel if speechSynthesizeSpeech is faster than that
    const setLoadingDebounced = debounce(() => dispatch(ScenarioActions.setDetailedScenarioLoading(true)), 200, {
      leading: false,
      trailing: true
    });

    try {
      setLoadingDebounced();

      const ttsOption = voiceOptions.find(
        (o) =>
          o.ttsProvider === scenario.ttsProvider &&
          o.languageCode === scenario.langCode &&
          o.sex === scenario.sex &&
          o?.name == scenario.voiceName
      );

      const audioBase64 = (
        await SpeechClient.speechSynthesizeSpeech({
          service: +ttsOption.ttsProvider as TtsProvider,
          language: ttsOption.languageCode,
          sex: ttsOption.sex,
          voiceName: ttsOption.name,
          text: scenario.scenarioContextIntro
        })
      ).data;

      if (audioBase64) {
        const audioElement = new Audio(`data:audio/wav;base64, ${audioBase64}`);
        audioElement.addEventListener('ended', async () => {
          audioElement.remove();
          setIsContextPlaying(false);
          await playScenario();
        });

        audioElement.play();
      }
    } catch (e) {
      logger.error('Unable to play scenario context');
      setIsContextPlaying(false);
      await playScenario();
    } finally {
      setLoadingDebounced.cancel();
      dispatch(ScenarioActions.setDetailedScenarioLoading(false));
    }
  }, [currentScenario, dispatch, playScenario, scenarios, voiceOptions]);

  return {
    loadingStatus,
    playIntroThenStart,
    isScenarioIntroPlaying
  };
};

export default useScenarioIntro;
