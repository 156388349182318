import React from 'react';
import { Box, Grid, Card, IconButton, Typography, CardContent, CardHeader } from '@material-ui/core';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import type { SceneContextDto, SceneContextFactDto, VariableDto } from '@/generated-api/index.js';
import type { ScenarioSettingsType } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';
import VariableSuggestionInput from '../Variables/VariableSuggestionInput.js';
import { Add, Delete } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type SceneContextFactsCardProps = {
  index: number;
  variables: VariableDto[];
};

const SceneContextFactsCard: React.FunctionComponent<SceneContextFactsCardProps> = ({ index, variables }) => {
  const contextsName = nameof.full<ScenarioSettingsType>((u) => u.contexts);
  const factsName = nameof.full<SceneContextDto>((u) => u.facts);

  const name = `${contextsName}[${index}].${factsName}`;

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const { control } = useFormContext<ScenarioSettingsType>();

  const { fields, append, remove } = useFieldArray({
    name,
    control,
    keyName: 'key'
  });

  const defaultFact: SceneContextFactDto = {
    text: translate(nameof.full<I18nScenarioEditorNs>((n) => n.sceneContextFactsCard.newFact)),
    id: 0
  };

  return (
    <Grid item component={Card} xs={12}>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h5">
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.sceneContextFactsCard.facts))}
            </Typography>
            <IconButton onClick={() => append(defaultFact)}>
              <Add />
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        <Grid container>
          {!fields.length && (
            <Grid container justifyContent="center">
              <Typography>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.sceneContextFactsCard.noFactsAddedYet))}
              </Typography>
            </Grid>
          )}
          {fields.map((field, index) => {
            const prefix = `${name}[${index}]`;

            const fullName = `${prefix}.${nameof.full<SceneContextFactDto>((u) => u.text)}`;

            return (
              <Grid container item xs={12} key={field.id} wrap="nowrap">
                <Grid item xs>
                  <Controller
                    control={control}
                    name={fullName}
                    defaultValue={field.text}
                    render={({ onChange, ...renderProps }) => (
                      <VariableSuggestionInput
                        inputProps={{
                          ...renderProps,
                          margin: 'normal',
                          InputProps: {
                            inputProps: {
                              maxLength: 100000,
                              maxRows: 30 
                            }
                          }
                        }}
                        value={renderProps.value}
                        variables={variables}
                        onChange={(value) => onChange(value)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`${prefix}.${nameof.full<SceneContextFactDto>((u) => u.id)}`}
                    render={() => <input type="hidden" />}
                    defaultValue={field.id}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Grid>
  );
};

export default SceneContextFactsCard;
