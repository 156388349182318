import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 420,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(2)
    },
    formControlFullWidth_12: {
      width: '93%',
      margin: 8,
      '&&& .MuiSelect-select': {
        width: '94%'
      },
      '&&& option': {
        width: 'auto',
        overflow: 'hidden',
        fontSize: '1rem',
        boxSizing: 'border-box',
        minHeight: 48,
        fontWeight: 400,
        lineHeight: 1.5,
        paddingTop: 10,
        whiteSpace: 'nowrap',
        letterSpacing: '0.00938em',
        paddingBottom: 20
      }
    },
    selectChaptersLabel: {
      fontSize: 12,
      marginLeft: 8
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: theme.spacing('2px', 0, '2px', 1)
    }
  })
);
