import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router';

import { CourseManagementRoutes } from 'app/utils/routes.js';
import { CurrentUserSelector } from 'app/selectors/userSelectors.js';
import CourseCard from 'app/components/CourseManagement/CourseCard/CourseCard.js';
import ScheduledCourseCard from 'app/components/CourseManagement/CourseCard/ScheduledCourseCard.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';

type CourseContainerProps = {
  setSelectedCompanyId: (companyId: number) => void;
};

const CourseContainer: React.FC<CourseContainerProps> = ({ setSelectedCompanyId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(CurrentUserSelector);

  const { courseId } = useParams<{ courseId: string }>();

  const setSelectedCompanyIdAndResetSelectedCourse = useCallback(
    (companyId: number) => {
      setSelectedCompanyId(companyId);
      history.replace(CourseManagementRoutes.Root);
    },
    [history, setSelectedCompanyId]
  );

  useEffect(() => {
    dispatch(
      CourseManagementActions.requestCourse(Number.parseInt(courseId, 10), (course) =>
        course
          ? setSelectedCompanyId(course.companyId)
          : setSelectedCompanyIdAndResetSelectedCourse(currentUser.companyId)
      )
    );
  }, [dispatch, courseId, currentUser, setSelectedCompanyId, setSelectedCompanyIdAndResetSelectedCourse]);

  return (
    <Switch>
      <Route path={CourseManagementRoutes.ScheduledCourse}>
        <ScheduledCourseCard />
      </Route>
      <Route path={CourseManagementRoutes.Course}>
        <CourseCard />
      </Route>
    </Switch>
  );
};

export default CourseContainer;
