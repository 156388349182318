import React, { useEffect } from 'react';
import type { MedicalProtocolChapterDto, MedicalProtocolDto, UpdateChapterCommand } from '@/generated-api/index.js';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import EditableInput from '../EditableInput.js';
import { useDispatch } from 'react-redux';
import { MedicalIndexClient } from 'app/apis/api.js';
import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import logger from 'app/utils/logger.js';

const { actions } = medicalIndexSlice;

const nameFieldKey = nameof.full<MedicalProtocolChapterDto>((t) => t.title);
const schema = yup.object({
  [nameFieldKey]: yup.string().required('This field is required')
});

export type EditableChapterTitleProps = {
  medIndexId: MedicalProtocolDto['id'];
  chapter: MedicalProtocolChapterDto;
};

function EditableChapterTitle({ medIndexId, chapter }: EditableChapterTitleProps): JSX.Element {
  const { errors, reset, control, handleSubmit } = useForm<UpdateChapterCommand>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      title: chapter?.title
    }
  });

  useEffect(() => {
    if (!chapter) {
      return;
    }

    reset({ title: chapter.title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter]);

  const dispatch = useDispatch();
  const onSubmit = handleSubmit(
    async ({ title }) => {
      if (!chapter || chapter.title === title) {
        return;
      }

      try {
        const { data: updatedMedIndex } = await MedicalIndexClient.medicalIndexUpdateChapter(medIndexId, chapter.id, {
          title
        });

        dispatch(actions.indexUpdated(updatedMedIndex));
      } catch (e) {
        logger.error(e);
      }
    },
    (data) => {
      logger.error('An error occurred during updating chapter', data);
    }
  );

  return (
    <Controller
      control={control}
      name={nameFieldKey}
      render={({ onBlur, ...rest }) => (
        <EditableInput
          typographyProps={{ variant: 'h5' }}
          {...rest}
          onBlur={() => {
            onBlur();
            onSubmit();
          }}
          error={errors[nameFieldKey]?.message}
        />
      )}
    />
  );
}

export default EditableChapterTitle;
