import React, { FC, PropsWithChildren } from 'react';
import { Grid, List, Typography } from '@material-ui/core/';
import { DialogStatisticKpiDetailsDto } from '@/generated-api';
import { makeStyles } from '@material-ui/styles';

import { ArrowRight } from '@material-ui/icons';
import KPIDetailsScore from 'app/components/DashboardPage/KPIDetails/KPIDetailsScore';
import KPIDetailsListItem from 'app/components/DashboardPage/KPIDetails/KPIDetailsListItem';
import QualityIcon from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TransitionWeights/QualityIcon';

type TransitionScoresProps = {
  kpiDetails: DialogStatisticKpiDetailsDto;
};

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '250px', // Adjust this value according to your header and other components occupying space.
    overflow: 'auto'
  },
}));

const TransitionScores: FC<PropsWithChildren<TransitionScoresProps>> = ({ kpiDetails }) => {
  if (!kpiDetails) return null;

  const classes = useStyles();

  return (
    <List className={classes.root}>
      {kpiDetails.transitionScores.map((transitionScore, index) => {
        return (
          <KPIDetailsListItem key={index}>
            <Grid item container alignItems="center" wrap="nowrap" xs={12} sm={6}>
              <Grid item xs>
                <Typography noWrap variant="body2">
                  {`${transitionScore.intentNumber}: ${transitionScore.toIntentName}`}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container wrap="nowrap">
                  <QualityIcon quality={transitionScore.fromQualityLevel} />
                  <ArrowRight />
                  <QualityIcon quality={transitionScore.toQualityLevel} />
                </Grid>
              </Grid>
            </Grid>
            <KPIDetailsScore score={transitionScore.score} />
          </KPIDetailsListItem>
        );
      })}
    </List>
  );
};

export default TransitionScores;
