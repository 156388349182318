import type { CreateUserGroupCommand } from '@/generated-api/index.js';
import { number, object, string } from 'yup';

const schema = object<CreateUserGroupCommand>({
  title: string().trim().required('This field is required'),
  companyId: number().optional(),
  departmentId: number().nullable(true)
});

export default schema;
