import React from 'react';

import AddCircle from '@material-ui/icons/AddCircle.js';
import { useBoolState } from '../../../../utils/customHooks/index.js';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ScenarioSettingsDialog from '../../../ScenarioEditorPage/ScenarioSettingsDialog/index.js';
import useStyles from './styles.js';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import type { I18nScenarioSectionNs } from '@/i18n/dictionaries/interfaces.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';

export default function AddScenarioSection() {
  const [translate] = useTranslation(I18nNamespace.ScenarioSection);

  const [settingsOpen, openSettings, closeSettings] = useBoolState();
  // this is used to provide no id to form when creation is requested
  const classes = useStyles();
  return (
    <>
      <ListItem onClick={openSettings} data-cy="createScenarioButton" button>
        <ListItemIcon color="primary" className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
          <AddCircle classes={{ root: classes.addBtn }} />
        </ListItemIcon>
        <ListItemText
          classes={{ root: classes.addBtn }}
          primary={translate(nameof.full<I18nScenarioSectionNs>((l) => l.createNewScenario))}
        />
      </ListItem>
      <ScenarioSettingsDialog open={settingsOpen} onClose={closeSettings} />
    </>
  );
}
