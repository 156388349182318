import { getMaxVolume } from '@/hark/hark.js';
import { Box, LinearProgress, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

type AudioVisualizerProps = {
  stream: MediaStream;
  play?: boolean;
  threshold: number;
};

const audioOptions = {
  smoothing: 0.1,
  interval: 100,
  fftSize: 512,
  historyLimit: 5,
  displayInterval: 300
};

const AudioVisualizer = (props: AudioVisualizerProps) => {
  const { stream, play, threshold } = props;
  const [sourceNode, setSourceNode] = useState<MediaStreamAudioSourceNode | MediaElementAudioSourceNode | null>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode | null>(null);
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [fftBins, setFftBins] = useState<Float32Array | null>(null);
  const [soundLevel, setSoundLevel] = useState(0);
  const [originalSoundLevel, setOriginalSoundLevel] = useState<number | string>(-100);
  const [soundLevelHistory, setSoundLevelHistory] = useState<number[]>([]);
  const [color, setColor] = useState<string>('green');

  const cleanup = useCallback(async () => {
    analyser?.disconnect();
    sourceNode?.disconnect();
    await audioContext?.suspend();
  }, [analyser, sourceNode, audioContext]);

  useEffect(() => {
    if (!stream) {
      cleanup();
    }
  }, [stream, cleanup]);

  useEffect(() => {
    if (stream && stream.active) {
      const audioContextI = new AudioContext();
      setAudioContext(audioContextI);
      const analyserI = audioContextI.createAnalyser();
      analyserI.smoothingTimeConstant = audioOptions.smoothing;
      analyserI.fftSize = audioOptions.fftSize;
      const fftBinsI = new Float32Array(analyserI.frequencyBinCount);
      setFftBins(fftBinsI);

      let sourceNodeI: MediaStreamAudioSourceNode | MediaElementAudioSourceNode;
      if (stream instanceof HTMLAudioElement || stream instanceof HTMLVideoElement) {
        sourceNodeI = audioContextI.createMediaElementSource(stream);
      } else {
        sourceNodeI = audioContextI.createMediaStreamSource(stream);
      }

      sourceNodeI.connect(analyserI);
      setSourceNode(sourceNodeI);
      if (play) {
        analyserI.connect(audioContextI.destination);
      }
      setAnalyser(analyserI);
    }
  }, [play, stream]);

  useEffect(() => {
    if (analyser && fftBins) {
      const intervalId = setInterval(() => {
        const currentVolume = getMaxVolume(analyser, fftBins);
        setSoundLevelHistory((prev) => {
          const copy = [...prev];
          if (prev.length >= audioOptions.historyLimit) {
            copy.shift();
          }
          copy.push(currentVolume);
          return copy;
        });
      }, audioOptions.interval);

      return () => clearInterval(intervalId);
    }
  }, [analyser, fftBins]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentVolume = soundLevelHistory.reduce((a, b) => a + b, 0) / soundLevelHistory.length;
      const clamped = Math.min(Math.max(currentVolume + 100, 0), 100);
      setOriginalSoundLevel(currentVolume.toFixed(1));
      setSoundLevel(clamped);

      switch (true) {
        case currentVolume - 10 <= threshold && currentVolume + 10 >= threshold:
          setColor('orange');
          break;
        case currentVolume - 10 < threshold:
          setColor('red');
          break;
        default:
          setColor('green');
          break;
      }
    });

    return () => clearInterval(intervalId);
  }, [soundLevelHistory, threshold]);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box minWidth="80%" mr={1}>
          <LinearProgress variant="determinate" value={soundLevel} />
        </Box>
        <Box minWidth={50}>
          <Typography style={{ color }}>{originalSoundLevel + ' dB'}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AudioVisualizer;
