import type { Configuration, PopupRequest, SilentRequest } from '@azure/msal-browser';
import { LogLevel } from '@azure/msal-browser';

const backendClientId = process.env.REACT_APP_ENTRAID_BACKEND_CLIENTID;
const frontendClientId = process.env.REACT_APP_ENTRAID_FRONTEND_CLIENTID;
const backendScope = process.env.REACT_APP_ENTRAID_BACKEND_SCOPE;
export const skllsTenantId = process.env.REACT_APP_ENTRAID_TENANTID;

export const guestMsalConfig: Configuration = {
  auth: {
    clientId: frontendClientId,
    authority: `https://login.microsoftonline.com/${skllsTenantId}`,
    redirectUri: '/'
  }
};

export const backendMsalConfig: Configuration = {
  auth: {
    clientId: backendClientId,
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: '/'
  }
};

export const frontendMsalConfig: Configuration = {
  auth: {
    clientId: frontendClientId,
    authority: `https://login.microsoftonline.com/organizations`,
    redirectUri: '/'
  }
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: frontendClientId,
    authority: 'https://login.microsoftonline.com/organizations',
    redirectUri: '/'
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

export const skllsMsalConfig = {
  ...msalConfig,
  auth: guestMsalConfig.auth
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: PopupRequest = {
  scopes: ['User.Read', backendScope],
  prompt: 'select_account',
  redirectUri: '/blank.html'
};

export const apiLoginRequest: SilentRequest = {
  scopes: [backendScope]
};

export const graphLoginRequest: SilentRequest = {
  scopes: ['User.Read']
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
};
