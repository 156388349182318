import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

export type MoveTemplateConfirmDialogProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  templateTitle: string;
  targetCategoryTitle: string;
};

function MoveTemplateConfirmDialog({
  isOpen,
  onConfirm,
  onCancel,
  templateTitle,
  targetCategoryTitle,
}: MoveTemplateConfirmDialogProps): JSX.Element {
  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Dialog open={isOpen} maxWidth={'xs'} fullWidth>
      <DialogTitle>
        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.moveTemplateConfirmDialog.title))}
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography>
             {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.moveTemplateConfirmDialog.confirmationText))}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Button color={'primary'} onClick={onConfirm}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.confirm))}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MoveTemplateConfirmDialog;
