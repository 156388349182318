import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '50%',
      margin: 5,
      height: 400
    },
    header: {
      background: '#f0f2f5',
      color: '#000000de'
    },
    headerTitle: {
      fontWeight: 'bold'
    },
    media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    info: {
      color: '#6d6d6d',
      fontSize: 40
    },
    conversationContainer: {
      height: 'calc(100% - 68px)',
      maxHeight: 400,
      overflowX: 'hidden',
      padding: 16,
      '&::after': {
        content: `''`,
        display: 'table',
        clear: 'both'
      }
    },

    sent: {
      background: '#26c6da',
      borderRadius: '5px 0px 5px 5px',
      float: 'right',
      '&::after': {
        content: `''`,
        borderWidth: '0px 0 10px 10px',
        borderColor: 'transparent transparent transparent #26c6da',
        top: 0,
        right: -10
      }
    },
    message: {
      color: '#fff',
      fontWeight: 'bold',
      clear: 'both',
      fontSize: 15,
      padding: 8,
      position: 'relative',
      margin: 8,
      maxWidth: '85%',
      wordWrap: 'break-word',
      zIndex: 0,
      '&::after': {
        content: `''`,
        position: 'absolute',
        width: 0,
        height: 0,
        borderStyle: 'solid'
      }
    },
    received: {
      background: '#26c6da',
      borderRadius: '0px 5px 5px 5px',
      float: 'left',
      '&::after': {
        content: `''`,
        borderWidth: '0px 10px 10px 0',
        borderColor: 'transparent #26c6da transparent transparent',
        top: 0,
        left: -10
      }
    },
    chip: {
      margin: '5px 5px',
      '&:hover': {
        cursor: 'pointed'
      }
    },
    chipSelected: {
      '&&&': {
        backgroundColor: theme.palette.secondary.main
      }
    },
    chipIcon: {
      fontSize: 15
    },
    tooltip: {
      padding: 0,
      backgroundColor: 'transparent'
    }
  })
);
