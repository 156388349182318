export enum LoadingStatus {
  Idle = 'idle',
  Loading = 'loading',
  Succeeded = 'Succeeded',
  Failed = 'failed'
}

export enum LoadMode {
  Soft,
  Hard
}
