import { handleActions } from 'redux-actions';
import { CompanyManagementActions } from '../../actions/companyManagment/companyManagementActions.js';
import type { CompanyDto } from '@/generated-api/index.js';

export interface ICompanyManagmenstate {
  companiesAreLoading: boolean;
  companies: Array<CompanyDto>;
}

const initialState: ICompanyManagmenstate = {
  companiesAreLoading: false,
  companies: []
};

export const companyReducer = handleActions(
  {
    [CompanyManagementActions.Type.SET_COMPANIES_ARE_LOADING]: (state, action): ICompanyManagmenstate => {
      return { ...state, companiesAreLoading: (action.payload as unknown) as boolean };
    },
    [CompanyManagementActions.Type.SET_COMPANIES]: (state, action): ICompanyManagmenstate => {
      return { ...state, companies: (action.payload as unknown) as CompanyDto[] };
    }
  },
  initialState
);
