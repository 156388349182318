import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import type { Action } from 'redux-actions';
import type { IPosition } from '@mrblenny/react-flow-chart';
import { Guid } from 'guid-typescript';

import { IntentActions } from '../../../actions/scenarios/intentAction.js';
import { ScenarioChartStoreActions } from '../../../actions/scenarios/scenarioChartStoreAction.js';

import { ScenarioChartSelectors } from '../../../selectors/index.js';
import getDefaultPorts from '../../../utils/flowChart/portsHelpers.js';
import { getCopyNodePosition, getNewNodePosition } from '../../../utils/flowChart/positionHelpers.js';
import type { CustomNode } from '../../../models/intents/customNode.js';
import { CustomNodeTypeEnum } from '../../../models/intents/customNode.js';
import type { CustomChart } from '../../../models/scenarios/customChart.js';
import type IntentNodeProperties from '../../../models/intents/intentNodeProperties.js';
import { getDefaultNodeSize } from '../../../utils/flowChart/sizingHelpers.js';
import customHistory from '../../../../core/customHistory.js';
import AppRoutes from '../../../utils/routes.js';
import { deepCopy } from '../../../utils/index.js';
import { MapEnumToColor } from 'app/utils/colorEnumToStringMapper.js';
import { Emotion, VoiceResponseUsecase, QualityLevel } from '@/generated-api/scenes/api.js';

import _ from 'lodash';

function* addIntent(params: Action<IntentActions.AddIntentPayload | undefined>) {
  const lastActionNode: CustomNode = yield select(ScenarioChartSelectors.lastActionNode);

  const prefix = Guid.raw().substring(0, 5);

  const properties: IntentNodeProperties = params.payload?.properties ?? {
    personaId: null,
    name: prefix + '-new Intent',
    intentPhrases: [{ text: '' }],
    globalAssetIntents: [],
    aiResponses: [
      {
        text: '',
        emotion: Emotion.NUMBER_0,
        voiceResponseUsecase: VoiceResponseUsecase.NUMBER_0
      }
    ],
    fallbackResponses: [
      {
        text: '',
        emotion: Emotion.NUMBER_0,
        voiceResponseUsecase: VoiceResponseUsecase.NUMBER_1
      }
    ],
    medicalProtocolActions: [],
    labelColor: MapEnumToColor(QualityLevel.NUMBER_0),
    linkedScenarios: [],
    secondsFromDialogStartEstimate: 0
  };
  const newPosition = {
    x: lastActionNode.position.x + lastActionNode.size.width + lastActionNode.size.width / 4,
    y: lastActionNode.position.y
  };
  yield call(insertIntent, Guid.raw(), newPosition, properties);
}

function* copyIntent(params: Action<string>) {
  const nodes: Record<string, CustomNode> = yield select(ScenarioChartSelectors.chartNodes);
  const sourceNode = nodes[params.payload];

  const copyPosition = getCopyNodePosition(sourceNode.position);
  const copyProperties = deepCopy(sourceNode.properties);

  copyProperties.aiResponses.forEach((ai) => (ai.id = 0));
  copyProperties.fallbackResponses.forEach((fallback) => (fallback.id = 0));
  copyProperties.intentPhrases.forEach((phrase) => (phrase.id = 0));

  yield call(insertIntent, Guid.raw(), copyPosition, copyProperties);
}

function* copyIntentToScenario(params: Action<IntentActions.CopyIntentToScenarioPayload>) {
  const nodes: Record<string, CustomNode> = yield select(ScenarioChartSelectors.chartNodes);
  const sourceNode = deepCopy(nodes[params.payload.intentId]);

  // set up clipboard
  yield put(
    IntentActions.setIntentClipboard({
      intentClipboard: sourceNode,
      clipboardTargetId: params.payload.scenarioId
    })
  );
  // navigate to the scenario page and let the scenario saga handle the rest
  customHistory.push(`${AppRoutes.Scene}/${params.payload.scenarioId}`);
}

function* insertIntent(nodeId: string, position: IPosition, properties: IntentNodeProperties) {
  const newNode: CustomNode = {
    id: nodeId,
    type: CustomNodeTypeEnum.Service,
    position,
    size: getDefaultNodeSize(),
    ports: getDefaultPorts(false),
    properties
  };
  yield put(ScenarioChartStoreActions.setNode(newNode));
}

function* removeIntent(params: Action<string>) {
  const currentChart: CustomChart = yield select(ScenarioChartSelectors.scenarioChart);
  const nodeId = params.payload;
  // delete the node
  delete currentChart.nodes[nodeId];
  // iterate over chart to remove links to deleted node
  const relatedLinks = Object.values(currentChart.links).filter(
    (k) => k.from.nodeId === nodeId || k.to.nodeId === nodeId
  );

  relatedLinks.forEach((k) => {
    delete currentChart.links[k.id];
    const incomingLinks = Object.values(currentChart.links).map((x) => x.to.nodeId);
    const outgoingLinks = Object.values(currentChart.links).map((x) => x.from.nodeId);
    if (
      !incomingLinks.includes(k.from.nodeId) &&
      !outgoingLinks.includes(k.from.nodeId) &&
      currentChart.nodes[k.from.nodeId]?.type === CustomNodeTypeEnum.Normal
    ) {
      currentChart.nodes[k.from.nodeId].type = CustomNodeTypeEnum.Service;
    }
    if (
      !incomingLinks.includes(k.to.nodeId) &&
      !outgoingLinks.includes(k.to.nodeId) &&
      currentChart.nodes[k.to.nodeId]?.type === CustomNodeTypeEnum.Normal
    ) {
      currentChart.nodes[k.to.nodeId].type = CustomNodeTypeEnum.Service;
    }
  });

  if (currentChart.selected) {
    yield put(ScenarioChartStoreActions.setSelected({}));
  }
  yield put(ScenarioChartStoreActions.setNodes(currentChart.nodes));
  yield put(ScenarioChartStoreActions.setLinks(currentChart.links));
}

function* watchAddIntent() {
  yield takeLatest(IntentActions.Type.ADD_INTENT, addIntent);
}

function* watchRemoveIntent() {
  yield takeLatest(IntentActions.Type.REMOVE_INTENT, removeIntent);
}

function* watchCopyIntent() {
  yield takeLatest(IntentActions.Type.COPY_INTENT, copyIntent);
}

function* watchCopyIntentToScenario() {
  yield takeLatest(IntentActions.Type.COPY_INTENT_TO_SCENARIO, copyIntentToScenario);
}

export default function* watchAllIntentsSaga() {
  yield fork(watchAddIntent);
  yield fork(watchRemoveIntent);
  yield fork(watchCopyIntent);
  yield fork(watchCopyIntentToScenario);
}
