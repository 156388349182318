import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GroupManagementActions } from 'app/components/GroupManagement/store/groupManagementActions.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import { Box, Button, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add.js';
import {
  groupsSelector,
  isGroupsStatusLoadingSelector,
  isGroupsStatusSucceedSelector
} from './store/groupManagementSelectors.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import CreateGroupDialog from 'app/components/GroupManagement/CreateGroupDialog/CreateGroupDialog.js';
import GroupList from 'app/components/GroupManagement/GroupList/GroupList.js';
import GroupCard from 'app/components/GroupManagement/GroupCard/GroupCard.js';
import { getUsers } from 'app/actions/userManagement/userManagementActions.js';
import { LoadingStatus } from 'app/store/types.js';
import NoCourseData from 'app/components/CourseManagement/CourseCard/NoCourseData/NoCourseData.js';
import NoGroupData from 'app/components/GroupManagement/GroupCard/NoGroupData/NoGroupData.js';
import { usersLoaded as usersLoadedeSelector } from 'app/selectors/userManagementSelectors.js';
import { Add } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGroupManagementNs } from '@/i18n/dictionaries/interfaces.js';

const useStyles = makeStyles((theme) => {
  return {
    section: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }
  };
});

function GroupManagement(): JSX.Element {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GroupManagementActions.loadGroups());
    dispatch(getUsers());
  }, [dispatch]);

  const [translate] = useTranslation([I18nNamespace.GroupManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const isGroupsLoading = useSelector(isGroupsStatusLoadingSelector);
  const isGroupsInitialized = useSelector(isGroupsStatusSucceedSelector);

  const isSkeletonDisplayed = isGroupsLoading || !isGroupsInitialized;
  const classes = useStyles();

  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolState(false);

  return (
    <Box overflow={'hidden'} height={'100%'} display={'flex'}>
      <Grid container>
        <Grid xs={5} md={4} lg={3} item classes={{ root: classes.section }}>
          <Box p={4} overflow={'auto'}>
            <Box display={'flex'}>
              {isSkeletonDisplayed ? (
                <Skeleton width={100} height={36} />
              ) : (
                <Button startIcon={<Add />} onClick={openCreateDialog}>
                  {translate(nameof.full<I18nGroupManagementNs>((n) => n.create))}
                </Button>
              )}
            </Box>

            {isSkeletonDisplayed ? (
              <Box>
                <Skeleton height={72} />
                <Skeleton height={72} />
                <Skeleton height={72} />
              </Box>
            ) : (
              <GroupList />
            )}
          </Box>
        </Grid>

        <Grid xs={7} md={8} lg={9} item container classes={{ root: classes.section }}>
          <GroupCard />
        </Grid>
      </Grid>
      <CreateGroupDialog isOpen={isCreateDialogOpen} onClose={closeCreateDialog} />
    </Box>
  );
}

export default GroupManagement;
