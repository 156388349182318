import 'regenerator-runtime/runtime';
import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom';
import { msalConfig, apiLoginRequest, skllsMsalConfig } from '@/authConfig.js';
import { PublicClientApplication } from '@azure/msal-browser';
import Main from './main.js';
import { MsalProvider } from '@azure/msal-react';
import { axiosInstance } from './axiosInstance.js';

// Force login with guest user in sklls if account is a work/school account and sklls sign in does not work properly
const useExternalInstance =
  window.location.search === '?external=true' || window.location.pathname === '/loginExternal';
const msalInstance = new PublicClientApplication(useExternalInstance ? msalConfig : skllsMsalConfig);
// console.log('MSAL Login Config: ', useExternalInstance ? msalConfig : skllsMsalConfig);
// create queryClient
const queryClient = new QueryClient();

const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    throw new Error('No active account');
  }

  try {
    const response = await msalInstance.acquireTokenSilent({
      ...apiLoginRequest,
      account: activeAccount || accounts[0]
    });

    return response.accessToken;
  } catch (error) {
    console.error(error);
    const response = await msalInstance.acquireTokenPopup({
      ...apiLoginRequest,
      account: activeAccount || accounts[0],
      prompt: 'consent'
    });

    return response.accessToken;
  }
};

axiosInstance.interceptors.request.use(async (config) => {
  const token = await acquireAccessToken();

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <QueryClientProvider client={queryClient}>
      <Main />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </MsalProvider>,
  document.getElementById('root')
);
