// TS "port" of hark - https://github.com/otalk/hark
import WildEmitter from 'wildemitter';

export function getMaxVolume(analyser: AnalyserNode, fftBins: Float32Array) {
  let maxVolume = -Infinity;
  analyser.getFloatFrequencyData(fftBins);

  for (let i = 4, ii = fftBins.length; i < ii; i++) {
    if (fftBins[i] > maxVolume && fftBins[i] < 0) {
      maxVolume = fftBins[i];
    }
  }

  return maxVolume;
}

let audioContextType: typeof window.AudioContext;
if (typeof window !== 'undefined') {
  audioContextType = window.AudioContext;
}

// use a single audio context due to hardware limits
let audioContext: AudioContext = null;

interface Option {
  smoothing?: number | undefined;
  interval?: number | undefined;
  threshold?: number | undefined;
  play?: boolean | undefined;
  history?: number | undefined;
  audioContext?: AudioContext | undefined;
}

export interface Harker {
  speaking: boolean;
  suspend(): Promise<void>;
  resume(): Promise<void>;
  readonly state: AudioContextState;
  setThreshold(t: number): void;
  setInterval(i: number): void;
  stop(): void;
  speakingHistory: number[];

  on(event: 'speaking' | 'stopped_speaking', listener: () => void): void;
  on(event: 'volume_change', listener: (currentVolume: number, threshold: number) => void): void;
  on(event: 'state_change', listener: (state: AudioContextState) => void): void;
}

const resetHistory = (history: number[]) => {
  for (let i = 0; i < history.length; i++) {
    history[i] = 0;
  }
};

export default function Hark(stream: HTMLAudioElement | HTMLVideoElement | MediaStream, option?: Option): Harker {
  const harker = new WildEmitter();

  // make it not break in non-supported browsers
  if (!audioContextType) return harker;

  //Config
  let threshold = option.threshold ?? -50;
  let play = option.play;
  const actualOptions = option ?? {};
  const smoothing = option.smoothing ?? 0.1;
  let interval = option.interval ?? 50;
  const history = option.history ?? 10;
  let running = true;

  // Ensure that just a single AudioContext is internally created
  audioContext = actualOptions.audioContext ?? new AudioContext();

  let sourceNode: MediaElementAudioSourceNode | MediaStreamAudioSourceNode;

  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 512;
  analyser.smoothingTimeConstant = smoothing;
  const fftBins = new Float32Array(analyser.frequencyBinCount);

  // if (stream.jquery) stream = stream[0];
  if (stream instanceof HTMLAudioElement || stream instanceof HTMLVideoElement) {
    //Audio Tag
    sourceNode = audioContext.createMediaElementSource(stream);
    if (typeof play === 'undefined') play = true;
    threshold = threshold || -50;
  } else {
    //WebRTC Stream
    sourceNode = audioContext.createMediaStreamSource(stream);
    threshold = threshold || -50;
  }

  sourceNode.connect(analyser);
  if (play) analyser.connect(audioContext.destination);

  harker.speaking = false;

  harker.suspend = async function () {
    // logger.log('HARK SUSPEND - BEFORE: ', new Date().getTime());
    await audioContext.suspend();
    // logger.log('HARK SUSPEND - AFTER: ', new Date().getTime());
  };
  harker.resume = async function () {
    // logger.log('HARK RESUME - BEFORE: ', new Date().getTime());
    resetHistory(harker.speakingHistory);
    if (harker.speaking) harker.speaking = false; // Reset to not speaking!
    await audioContext.resume();
    // logger.log('HARK RESUME - AFTER: ', new Date().getTime());
  };
  Object.defineProperty(harker, 'state', {
    get: function () {
      return audioContext.state;
    }
  });
  audioContext.onstatechange = function () {
    harker.emit('state_change', audioContext.state);
  };

  harker.setThreshold = function (t: number) {
    threshold = t;
  };

  harker.setInterval = function (i: number) {
    interval = i;
  };

  harker.stop = function () {
    running = false;
    harker.emit('volume_change', -100, threshold);
    if (harker.speaking) {
      harker.speaking = false;
      harker.emit('stopped_speaking');
    }
    analyser.disconnect();
    sourceNode.disconnect();
  };
  harker.speakingHistory = [];
  for (let i = 0; i < history; i++) {
    harker.speakingHistory.push(0);
  }

  // Poll the analyser node to determine if speaking
  // and emit events if changed
  const looper = function () {
    setTimeout(function () {
      //check if stop has been called
      if (!running) {
        return;
      }

      // logger.log('HARK TIMEOUT TRIGGERED - ', new Date().getTime());

      const currentVolume = getMaxVolume(analyser, fftBins);

      harker.emit('volume_change', currentVolume, threshold);

      let history = 0;
      if (currentVolume > threshold && !harker.speaking) {
        // trigger quickly, short history
        for (let i = harker.speakingHistory.length - 3; i < harker.speakingHistory.length; i++) {
          history += harker.speakingHistory[i];
        }
        if (history >= 2) {
          harker.speaking = true;
          harker.emit('speaking');
        }
      } else if (currentVolume < threshold && harker.speaking) {
        for (let i = 0; i < harker.speakingHistory.length; i++) {
          history += harker.speakingHistory[i];
        }
        if (history == 0) {
          harker.speaking = false;
          harker.emit('stopped_speaking');
        }
      }
      harker.speakingHistory.shift();
      harker.speakingHistory.push(0 + Number(currentVolume > threshold));

      looper();
    }, interval);
  };
  looper();

  return harker;
}
