import React from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import type { DialogProps, Theme } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { LoadingStatus } from 'app/store/types.js';
import LoadingButton from 'app/components/LoadingButton.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type DeleteConfirmationDialogProps = {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
  confirmationText: string;
  DialogProps?: DialogProps;
  loadingStatus?: LoadingStatus | null;
  open: boolean;
};

const DeleteConfirmationDialog: React.FC<DeleteConfirmationDialogProps> = ({
  title,
  onClose,
  children,
  onConfirm,
  DialogProps,
  loadingStatus,
  confirmationText,
  open
}) => {
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.Common]);
  const isLoading = loadingStatus === LoadingStatus.Loading;

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose} {...(DialogProps ? DialogProps : {})}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={onClose}>
          {translate(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={onConfirm}
          disabled={isLoading}
          loadingStatus={loadingStatus}
          className={classes.confirmButton}
        >
          {confirmationText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    confirmButton: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  })
);

export default DeleteConfirmationDialog;
