import type { TenantDto } from '@/generated-api/index.js';
import type { UseQueryOptions } from '@tanstack/react-query';
import { UserClient } from 'app/apis/api.js';

export const tenantsQuery: UseQueryOptions<TenantDto[]> = {
  queryKey: ['tenants'],
  queryFn: async () => {
    const { data } = await UserClient.userGetTenants();
    return data;
  }
};
