import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { companiesSelector } from 'app/selectors/companiesSelector.js';
import AddVariableDialog from 'app/components/ScenarioEditorPage/Variables/AddVariableDialog.js';
import { useParams } from 'react-router';
import { getVariablesQuery } from 'app/queries/variableQueries.js';
import { useQuery } from '@tanstack/react-query';
import { VARIABLE_MATCH_REGEX } from 'app/components/ScenarioEditorPage/Variables/VariableSuggestionInput.js';
import {
    categoriesSelector,
  } from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySelectors.js';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type {
  I18nCommonNs,
  I18nCoreNs,
  I18nGlobalAssetsNs,
  I18nScenarioEditorNs
} from '@/i18n/dictionaries/interfaces.js';
import { makeStyles } from '@material-ui/core/styles';
import { CompanyDto, GlobalAssetFullDto, GlobalAssetIntentDto } from '@/generated-api/index.js';
import { currentChartScenarioLang } from 'app/selectors/scenarioSelectors.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import IntentsInfoTooltip from './IntentsInfoTooltip.js';

type TemplateId = GlobalAssetIntentDto['id'];

const Languages = {
  Danish: 'da-DK',
  'English (UK)': 'en-GB',
  'English (US)': 'en-US',
  'English (Australia)': 'en-AU',
  German: 'de-DE',
  Icelandic: 'is-IS',
  Norwegian: 'nb-NO',
  'Spanish (Mexico)': 'es-MX',
  'Spanish (Spain)': 'es-ES',
  'Spanish (US)': 'es-US',
  Swedish: 'sv-SE',
  'Arabic (UAE)': 'ar-AE'
};

const useStyles = makeStyles((theme) => ({
  select: {
    whiteSpace: 'normal'
  },
  menu: {
    maxWidth: 400,
    maxHeight: 600
  },
  listItemRoot: {
    whiteSpace: 'normal'
  },
}));

interface AddTemplateProps {
  existingIds: TemplateId[];
  onCancel: () => void;
  onConfirm: (id: TemplateId) => void;
}

const AddTemplate: React.FC<AddTemplateProps> = ({
  existingIds,
  onConfirm,
  onCancel
}) => {
  const categories = useSelector(categoriesSelector);
  const scenarioLanguage = useSelector(currentChartScenarioLang);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const companies = useSelector(companiesSelector);

  const [selectedLanguage, setSelectedLanguage] = useState(scenarioLanguage);
  const [selectedCategory, setSelectedCategory] = useState<GlobalAssetFullDto['id']>(0);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateId>(0);
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateGlobalAssets] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const [translateCore] = useTranslation([I18nNamespace.Core]);

  const getAddCategoryName = (category: GlobalAssetFullDto, isSuperAdmin: boolean, companies: CompanyDto[]): string => {
    if (!isSuperAdmin) return '';
    if (category.companyId) {
      return `(${companies.find((company) => company.id === category.companyId)?.name ?? 'Deleted company'})`;
    }

    if (!category.sharedCompanies?.length)
      return `(${translateGlobalAssets(nameof.full<I18nGlobalAssetsNs>((n) => n.categoryList.shared))})`;
    return `(${category.sharedCompanies.map((c) => c.name).join(', ')})`;
  };
  const filteredCategories = useMemo(() => {
    return categories.filter((c) => c.language === selectedLanguage);
  }, [categories, selectedLanguage]);

  const categoryTemplates = useMemo(() => {
    return filteredCategories.find((c) => c.id === selectedCategory)?.intents ?? [];
  }, [filteredCategories, selectedCategory]);

  const { id: scenarioId } = useParams<{ id: string }>();

  const variablesQuery = React.useMemo(() => getVariablesQuery(Number(scenarioId)), [scenarioId]);
  const queryData = useQuery(variablesQuery);
  const variables = useMemo(() => queryData.data ?? [], [queryData.data]);
  const [openAddVariables, setOpenAddVariables] = useState(false);

  const unsavedVariables = useMemo(() => {
    if (selectedTemplate <= 0) return [];
    const template = categoryTemplates.find((t) => t.id === selectedTemplate);
    const sampleVariables =
      template?.samples.flatMap((s) => (s.text?.match(VARIABLE_MATCH_REGEX) as string[]) ?? []) ?? [];
    return sampleVariables.map((v) => v.replace('@', '')).filter((sv) => !!sv && !variables.some((v) => v.name === sv));
  }, [categoryTemplates, selectedTemplate, variables]);

  useEffect(() => {
    setSelectedCategory(0);
    setSelectedTemplate(0);
  }, [selectedLanguage]);

  useEffect(() => {
    setSelectedTemplate(0);
  }, [selectedCategory]);

  const classes = useStyles();

  const categoryTemplatesMenuItems = useMemo(() => {
    return categoryTemplates.map((template) => {
      return (
        <MenuItem
          key={template.id}
          value={template.id}
          disabled={!!existingIds.find((id) => id === template.id)}
          classes={{ root: classes.listItemRoot }}
        >
          {template.title}
          {!!template.samples.length && <IntentsInfoTooltip template={template} />}
        </MenuItem>
      );
    });
  }, [categoryTemplates, existingIds, classes.listItemRoot]);

  const onAdd = () => {
    if (unsavedVariables.length) {
      setOpenAddVariables(true);
    } else {
      onConfirm(selectedTemplate);
    }
  };

  const onAddVariablesSaved = () => {
    setOpenAddVariables(false);
    onConfirm(selectedTemplate);
  };

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.language))}</InputLabel>
        <Select
          disabled
          displayEmpty
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.name)}
          MenuProps={{
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            getContentAnchorEl: null
          }}
        >
          <MenuItem key={Languages['Norwegian']} data-cy="Norwegian" value={Languages['Norwegian']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.norwegian))}
          </MenuItem>
          <MenuItem key={Languages['English (US)']} data-cy="English (US)" value={Languages['English (US)']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishus))}
          </MenuItem>
          <MenuItem key={Languages['English (UK)']} data-cy="English (UK)" value={Languages['English (UK)']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishuk))}
          </MenuItem>
          <MenuItem
            key={Languages['English (Australia)']}
            data-cy="English (Australia)"
            value={Languages['English (Australia)']}
          >
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishAustralia))}
          </MenuItem>
          <MenuItem key={Languages['German']} data-cy="German" value={Languages['German']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.german))}
          </MenuItem>
          <MenuItem key={Languages['Danish']} data-cy="Danish" value={Languages['Danish']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.danish))}
          </MenuItem>
          <MenuItem key={Languages['Swedish']} data-cy="Swedish" value={Languages['Swedish']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.swedish))}
          </MenuItem>
          <MenuItem key={Languages['Icelandic']} data-cy="Icelandic" value={Languages['Icelandic']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.icelandic))}
          </MenuItem>
          <MenuItem key={Languages['Spanish (Spain)']} data-cy="Spanish (Spain)" value={Languages['Spanish (Spain)']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishSpain))}
          </MenuItem>
          <MenuItem key={Languages['Spanish (US)']} data-cy="Spanish (US)" value={Languages['Spanish (US)']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishUs))}
          </MenuItem>
          <MenuItem
            key={Languages['Spanish (Mexico)']}
            data-cy="Spanish (Mexico)"
            value={Languages['Spanish (Mexico)']}
          >
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishMexico))}
          </MenuItem>
          <MenuItem key={Languages['Arabic (UAE)']} data-cy="Arabic (UAE)" value={Languages['Arabic (UAE)']}>
            {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.arabicUae))}
          </MenuItem>
        </Select>
      </FormControl>

      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.category))}</InputLabel>
          <Select
            autoFocus
            displayEmpty
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value as number)}
            MenuProps={{
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              getContentAnchorEl: null
            }}
          >
            {filteredCategories.map((category) => (
              <MenuItem
                key={category.id}
                value={category.id}
                className={!!category.companyId ? '' : (classes as any).sharedCategory}
              >
                {category.title + ' ' + getAddCategoryName(category, isSuperAdmin, companies)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.globalAssets.intent))}</InputLabel>
          <Select
            displayEmpty
            value={selectedTemplate}
            fullWidth
            classes={{ root: classes.select }}
            onChange={(e) => setSelectedTemplate(e.target.value as number)}
            MenuProps={{
              classes: {
                paper: classes.menu
              },
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              getContentAnchorEl: null
            }}
          >
            {categoryTemplatesMenuItems}
          </Select>
        </FormControl>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
        <Button onClick={onCancel}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Box ml={2}>
          <Button disabled={!selectedTemplate} onClick={onAdd}>
            {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.add))}
          </Button>
        </Box>
      </Box>
      <AddVariableDialog
        variables={variables}
        onClose={onAddVariablesSaved}
        open={openAddVariables}
        disableClose
        multiple
        controlledVariables={unsavedVariables}
        scenarioId={Number(scenarioId)}
      />
    </Box>
  );
};

export default AddTemplate;
