import { createStyles, withStyles } from '@material-ui/core/styles/index.js';
import { Badge } from '@material-ui/core';

const HorizontalBadge = withStyles(() => {
  return createStyles({
    root: {
      width: 20,
      marginRight: 8
    },
    badge: {
      top: '50%',
      transform: 'scale(1) translate(0%, -50%)'
    }
  });
})(Badge);

export default HorizontalBadge;
