import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';
import type { IChart, ILink, ISelectedOrHovered } from '@mrblenny/react-flow-chart/src/types/chart.js';
import type { IPosition } from '@mrblenny/react-flow-chart';

import { ActiveLinkStatus } from '../../models/scenarios/customLink.js';
import { ScenarioChartEditingActions } from '../../actions/scenarios/scenarioChartEditingActions.js';
import { ScenarioChartStoreActions } from '../../actions/scenarios/scenarioChartStoreAction.js';
import type { CustomNode } from '../../models/intents/customNode.js';
import type { CustomChart } from '../../models/scenarios/customChart.js';
import SetNodeAdvancedPayload = ScenarioChartStoreActions.SetNodeAdvancedPayload;
import { IntentEditingActions } from 'app/actions/scenarios/intentEditingAction.js';
import ChangeReactTimePayload = IntentEditingActions.ChangeReactTimePayload;
import _, { values } from 'lodash';

type UpdateProperty = {
  id: string;
  propertyName: string;
  propertyValue: any;
  isUnique?: boolean;
};

const initialState: CustomChart & { lastActionNode: CustomNode } = {
  offset: {
    x: 0,
    y: 0
  },
  scale: 0,
  nodes: {},
  links: {},
  selected: {},
  hovered: {},
  properties: {
    id: 0,
    showIntentEdit: false,
    hoveredPort: null
  },
  lastActionNode: null
};

export const scenarioChartReducer = handleActions<
  CustomChart & { lastActionNode?: CustomNode | null },
  | IChart
  | Record<string, CustomNode>
  | CustomNode
  | Record<string, ILink>
  | ILink
  | Pick<CustomChart, 'offset' | 'scale'>
  | IPosition
  | boolean
  | UpdateProperty
  | {
      hoveredPort: null | { hoveredPortNodeId: string; portId: string };
    }
  | SetNodeAdvancedPayload
  | React.SetStateAction<CustomChart>
  | ChangeReactTimePayload
  | { lastActionNode: CustomNode }
>(
  {
    [ScenarioChartStoreActions.Type.INIT_SCENARIO_CHART]: (state, action): typeof initialState => {
      const payload = action.payload as CustomChart;
      const farestNode = _.maxBy(Object.values(payload.nodes), (n) => {
        return n.position.x;
      });

      return {
        ...payload,
        lastActionNode: farestNode
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_NODES]: (state, action): CustomChart => {
      const nodes = action.payload as Record<string, CustomNode>;

      return {
        ...state,
        nodes
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_NODE]: (state, action): CustomChart => {
      return {
        ...state,
        nodes: {
          ...state.nodes,
          [(action.payload as CustomNode).id]: action.payload as CustomNode
        }
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_NODE_PROPERTY]: (state, action): CustomChart => {
      const { payload } = action as Action<UpdateProperty>;
      const { nodes } = state;

      if (payload?.isUnique) {
        Object.keys(nodes).forEach((nodeKey) => {
          if (nodeKey !== payload.id) {
            nodes[nodeKey].properties.isDialogProcessOnTheNode = false;
          }
        }, {});
      }
      // TODO Task 660 (Sometimes(!!!) after the user speech when the dialog must go to the "depending scenario" tab dialog fall.)
      if (nodes[payload.id] && nodes[payload.id].properties)
        nodes[payload.id].properties.isDialogProcessOnTheNode = payload.propertyValue;
      return { ...state, nodes };
    },
    [ScenarioChartStoreActions.Type.SET_SC_LINKS]: (state, action): CustomChart => {
      return {
        ...state,
        links: action.payload as Record<string, ILink>
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_LINK]: (state, action): CustomChart => {
      return {
        ...state,
        links: {
          ...state.links,
          [(action.payload as ILink).id]: action.payload as ILink
        }
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_OFFSET]: (state, action): CustomChart => {
      return {
        ...state,
        offset: action.payload as IPosition
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_ZOOM]: (state, action): CustomChart => {
      const { offset, scale } = action.payload as Pick<CustomChart, 'offset' | 'scale'>;
      return {
        ...state,
        offset,
        scale
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_SELECTED]: (state, action): CustomChart => {
      return {
        ...state,
        selected: action.payload as ISelectedOrHovered
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_HOVERED]: (state, action): CustomChart => {
      return {
        ...state,
        hovered: action.payload as ISelectedOrHovered
      };
    },
    [ScenarioChartStoreActions.Type.SHOW_INTENT_EDIT]: (state, action): CustomChart => {
      return {
        ...state,
        properties: { ...state.properties, showIntentEdit: action.payload as boolean }
      };
    },
    [ScenarioChartEditingActions.Type.ON_HOVERED_PORT]: (state, action): CustomChart => {
      const { hoveredPort } = action.payload as {
        hoveredPort: null | { hoveredPortNodeId: string; portId: string };
      };
      const { links } = state;
      const activeLinkId = Object.keys(links).find((linkId) => {
        return links[linkId].to.hasOwnProperty('position');
      });
      if (!!activeLinkId && !!hoveredPort) {
        const currentLink = links[activeLinkId];
        const isOnEndPort = currentLink.from.nodeId !== hoveredPort.hoveredPortNodeId;
        const isCorrectLink = currentLink.from.portId !== hoveredPort.portId;

        return {
          ...state,
          links: {
            ...state.links,
            [activeLinkId]: {
              ...state.links[activeLinkId],
              properties: {
                linkStatus: isCorrectLink && isOnEndPort ? ActiveLinkStatus.Correct : ActiveLinkStatus.Incorrect
              }
            }
          },
          properties: {
            ...state.properties,
            hoveredPort
          }
        };
      } else if (!hoveredPort && activeLinkId) {
        return {
          ...state,
          links: {
            ...state.links,
            [activeLinkId]: { ...state.links[activeLinkId], properties: { linkStatus: ActiveLinkStatus.Default } }
          },
          properties: {
            ...state.properties,
            hoveredPort
          }
        };
      }
      return {
        ...state,
        properties: {
          ...state.properties,
          hoveredPort
        }
      };
    },
    [ScenarioChartStoreActions.Type.SET_SC_NODE_ADVANCED]: (state, action) => {
      const payload = action.payload as SetNodeAdvancedPayload;

      const newNode = typeof payload.node === 'function' ? payload.node(state.nodes[payload.id]) : payload.node;

      return {
        ...state,
        nodes: {
          ...state.nodes,
          [payload.id]: newNode
        }
      };
    },
    [ScenarioChartEditingActions.Type.SET_SCENARIO_STATE]: (state, action) => {
      const payload = action.payload as React.SetStateAction<CustomChart>;

      if (typeof payload === 'function') {
        return payload(state);
      }

      return payload;
    },
    [IntentEditingActions.Type.CHANGE_REACH_TIME]: (state, action) => {
      const { scope, value, nodeId } = action.payload as ChangeReactTimePayload;

      // TODO: mutable changes
      if (nodeId in state.nodes) {
        switch (scope) {
          case 'global':
            state.nodes[nodeId].properties.secondsFromDialogStartEstimate = value;
            break;
        }

        return {
          ...state,
          nodes: {
            ...state.nodes
          }
        };
      }

      return state;
    },
    [ScenarioChartEditingActions.Type.SET_LAST_ACTION_NODE]: (state, action) => {
      const payload = action.payload as CustomNode;

      return {
        ...state,
        lastActionNode: payload
      };
    },
    [ScenarioChartStoreActions.Type.RESET_IS_DIALOG_PROCESS_ON_THE_NODE]: (state): CustomChart => {
      const { nodes } = state;

      Object.keys(nodes).forEach((nodeKey) => {
        nodes[nodeKey].properties.isDialogProcessOnTheNode = false;
      }, {});
      return { ...state, nodes };
    }
  },
  initialState
);
