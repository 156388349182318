import { Grid, TextField } from '@material-ui/core';
import type { FC } from 'react';
import React from 'react';
import DatePicker from 'react-datepicker';
import useStyles from './kpiFiltersStyle.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

interface KpiStatisticsFiltersProps {
  timeFrom: Date | null;
  onSetTimeFrom: (date: Date) => void;
  timeTo: Date | null;
  onSetTimeTo: (date: Date) => void;
  setSearch: (search: string) => void;
  search: string | undefined;
}

const KpiStatisticsFilters: FC<KpiStatisticsFiltersProps> = ({
  search,
  setSearch,
  timeFrom,
  onSetTimeFrom,
  timeTo,
  onSetTimeTo
}) => {
  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Grid container className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item className={classes.rowContainer}>
          <span className={classes.label}>From date:</span>
          <Grid className={classes.datePickerContainer}>
            <DatePicker
              selected={timeFrom}
              onChange={onSetTimeFrom}
              popperPlacement="top-end"
              placeholderText={translate(nameof.full<I18nDashboardNs>((n) => n.kpiStatisticsFilters.fromDate))}
              dateFormat="MMMM d, yyyy"
              isClearable
            />
          </Grid>
          <span className={classes.label}>To date:</span>
          <Grid className={classes.datePickerContainer}>
            <DatePicker
              selected={timeTo}
              onChange={onSetTimeTo}
              popperPlacement="top-end"
              placeholderText={translate(nameof.full<I18nDashboardNs>((n) => n.kpiStatisticsFilters.toDate))}
              dateFormat="MMMM d, yyyy"
              isClearable
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            placeholder={translate(nameof.full<I18nDashboardNs>((n) => n.kpiStatisticsFilters.search))}
            variant="outlined"
            inputProps={{
              className: classes.searchInput
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KpiStatisticsFilters;
