import React from 'react';
import TabPanel from '../TabPanel.js';
import { Controller, useFormContext } from 'react-hook-form';
import type { ScenarioSettingsType } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';
import { Box, Checkbox, FormControlLabel, TextField } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const ScenarioIntroAudioSettingsTab: React.FC<{ index: string; value: string | number | null }> = ({
  index,
  value
}) => {
  const { register, control, watch } = useFormContext<ScenarioSettingsType>();
  let contextText = watch(nameof.full<ScenarioSettingsType>((f) => f.scenarioContextIntro));
  contextText = (contextText as string)?.trim();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <TabPanel index={index} value={value}>
      <Box pt={2} height={420}>
        <Box>
          <Controller
            register={register}
            control={control}
            render={(props) => {
              return (
                <TextField
                  multiline={true}
                  fullWidth={true}
                  label={translate(
                    nameof.full<I18nScenarioEditorNs>((n) => n.scenarioIntroAudioSettingsTab.introAudio)
                  )}
                  maxRows={15}
                  placeholder={translate(
                    nameof.full<I18nScenarioEditorNs>((n) => n.scenarioIntroAudioSettingsTab.writeAnIntroText)
                  )}
                  {...props}
                  value={props.value ?? ''}
                />
              );
            }}
            name={nameof.full<ScenarioSettingsType>((f) => f.scenarioContextIntro)}
          />
        </Box>

        <Controller
          register={register}
          control={control}
          name={nameof.full<ScenarioSettingsType>((f) => f.playScenarioContextOn)}
          render={({ value, ...props }) => {
            return (
              <Box display={contextText ? 'block' : 'none'}>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      disabled={!contextText}
                      checked={!!value}
                      onChange={(e) => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioIntroAudioSettingsTab.enable))}
                />
              </Box>
            );
          }}
        />
      </Box>
    </TabPanel>
  );
};

export default ScenarioIntroAudioSettingsTab;
