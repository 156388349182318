import { TextField, Button, Dialog, DialogContent, DialogActions, DialogTitle, MenuItem } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { CompaniesSelector } from 'app/selectors/index.js';
import { GroupManagementRoutes } from 'app/utils/routes.js';
import LoadingButton from 'app/components/LoadingButton.js';
import validationSchema from 'app/components/GroupManagement/CreateGroupDialog/schema.js';
import type { CompanyDepartmentDto, CreateUserGroupCommand, UserGroupDto } from '@/generated-api/index.js';
import { CurrentUserSelector, isUserOwnerSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import * as GroupManagementActions from 'app/components/GroupManagement/store/groupManagementActions.js';
import SimpleCompanySelectField from 'app/components/UsersPage/UserInvitation/SimpleCompanySelectField.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as GroupManagementSelectors from 'app/components/GroupManagement/store/groupManagementSelectors.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGroupManagementNs } from '@/i18n/dictionaries/interfaces.js';

export type CreateGroupDialog = {
  isOpen: boolean;
  onClose: () => void;
};

const NO_DEPARTMENT_SELECTED = 0;

function CreateCourseDialog({ isOpen, onClose }: CreateGroupDialog): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.GroupManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const currentUser = useSelector(CurrentUserSelector);
  const isUserOwner = useSelector(isUserOwnerSelector);
  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);

  const [departments, setDepartments] = useState<CompanyDepartmentDto[]>([]);

  const requestCompanyDepartments = useCallback(
    (companyId: number) =>
      dispatch(CourseManagementActions.requestCompanyDepartments(companyId, logger.log, setDepartments)),
    [dispatch]
  );

  const { control, handleSubmit, errors, watch, reset, formState } = useForm<CreateUserGroupCommand>({
    mode: 'onChange',
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      companyId: currentUser.companyId,
      departmentId: currentUser.departmentId ?? NO_DEPARTMENT_SELECTED
    }
  });

  const { companyId } = watch();

  useEffect(() => {
    if (!companyId) return;

    setDepartments([]);

    requestCompanyDepartments(companyId);
  }, [companyId, requestCompanyDepartments]);

  const onUserGroupCreated = useCallback(
    (userGroup: UserGroupDto) => {
      const path = generatePath(GroupManagementRoutes.Group, { groupId: userGroup.id });

      history.push(path);
      onClose();
      reset();
    },
    [history, onClose, reset]
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((data: CreateUserGroupCommand) =>
        dispatch(GroupManagementActions.createUserGroup(data, onUserGroupCreated))
      ),
    [dispatch, handleSubmit, onUserGroupCreated]
  );

  const loadingStatus = useSelector(GroupManagementSelectors.createUserGroupLoadingStatus);

  const companies = useSelector(CompaniesSelector.companiesSelector);

  const displayNoDepartmentOption = isUserSuperAdmin || isUserOwner || !currentUser.departmentId;

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>{translate(nameof.full<I18nGroupManagementNs>((n) => n.createGroupForm.createGroup))}</DialogTitle>
      <DialogContent>
        <form id="create-group-form" onSubmit={onSubmit}>
          <SimpleCompanySelectField
            required
            label={translate(nameof.full<I18nGroupManagementNs>((n) => n.createGroupForm.organization))}
            control={control}
            error={!!errors.companyId}
            disabled={companies.length <= 1}
            name={nameof.full<CreateUserGroupCommand>((c) => c.companyId)}
          />
          <Controller
            control={control}
            name={nameof.full<CreateUserGroupCommand>((c) => c.departmentId)}
            render={(props) => (
              <TextField
                {...props}
                select
                fullWidth
                margin="normal"
                label={translate(nameof.full<I18nGroupManagementNs>((n) => n.createGroupForm.department))}
                inputRef={props.ref}
                error={!!errors.departmentId?.message}
                helperText={errors.departmentId?.message}
                disabled={!departments.length}
              >
                {displayNoDepartmentOption && (
                  <MenuItem value={NO_DEPARTMENT_SELECTED}>
                    {translate(nameof.full<I18nGroupManagementNs>((n) => n.createGroupForm.defaultDepartment))}
                  </MenuItem>
                )}
                {departments.map((department: CompanyDepartmentDto) => (
                  <MenuItem key={department.id} value={department.id}>
                    {department.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            name={nameof.full<CreateUserGroupCommand>((c) => c.title)}
            render={(props) => (
              <TextField
                {...props}
                required
                fullWidth
                label={translate(nameof.full<I18nGroupManagementNs>((n) => n.createGroupForm.title))}
                margin="normal"
                inputRef={props.ref}
                error={!!errors.title?.message}
                helperText={errors.title?.message}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formState.isValid}
          form="create-group-form"
          loadingStatus={loadingStatus}
        >
          {translate(nameof.full<I18nGroupManagementNs>((n) => n.create))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default CreateCourseDialog;
