import type { Speaker, SynthesizeSpeechCommand } from '@/generated-api/index.js';
import type { UseQueryOptions } from '@tanstack/react-query';
import { SpeechClient } from 'app/apis/api.js';

/**
 * Get list of all voices possible to use
 */
export const voicesQuery: UseQueryOptions<Speaker[]> = {
  queryKey: ['voicesQuery'],
  queryFn: async (): Promise<Speaker[]> => {
    return (await SpeechClient.speechGetVoiceList()).data;
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false
};

/** Get a synthesized speech's audio data */
export const getSynthesizeSpeechAudioQuery = (
  payload: SynthesizeSpeechCommand | undefined
): UseQueryOptions<string> => ({
  queryKey: ['synthesizeSpeechQuery', payload],
  queryFn: async () => {
    const { data } = await SpeechClient.speechSynthesizeSpeech(payload);
    return `data:audio/wav;base64, ${data}`;
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  enabled: !!payload,
  staleTime: 5 * 60 * 1000
  // set long cache time
});
