import React from 'react';

export type TabPanelProps = {
  index: number | string;
  value?: number | string | null;
  idPrefix?: string;
  labelPrefix?: string;
  className?: string;
};

const TabPanel: React.FC<TabPanelProps> = function TabPanel({
  value,
  index,
  idPrefix,
  labelPrefix,
  children,
  className
}) {
  const props = {
    ...(idPrefix != null && { id: `${idPrefix}-${index}` }),
    ...(labelPrefix != null && { ['aria-labelledby']: `${labelPrefix}-${index}` }),
    ...(className ? { className } : null)
  };

  return (
    <div role="tabpanel" hidden={value !== index} {...props}>
      {children}
    </div>
  );
};

export default TabPanel;
