import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import type { MedicalProtocolActionDto, MedicalProtocolChapterDto, MedicalProtocolDto } from '@/generated-api/index.js';
import { indexesSelector } from 'app/store/MedicalIndex/medicalIndexSelectors.js';
import ChapterItem from './ChapterItem/ChapterItem.js';
import DeleteActionDialog from 'app/components/MedicalIndexesManager/MedIndexCard/ChapterList/DeleteActionDialog.js';
import DeleteChapterDialog from 'app/components/MedicalIndexesManager/MedIndexCard/ChapterList/DeleteChapterDialog.js';
import AddActionDialog from 'app/components/MedicalIndexesManager/MedIndexCard/ChapterList/AddActionDialog/AddActionDialog.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

export type ChaptersListProps = { medIndex: MedicalProtocolDto };

function ChaptersList({ medIndex }: ChaptersListProps): JSX.Element {
  const medicalIndexes = useSelector(indexesSelector);

  const medIndexId = medIndex.id;

  const chapters = useMemo(() => {
    return medicalIndexes.find((m) => m.id === medIndexId)?.chapters;
  }, [medIndexId, medicalIndexes]);

  const [addActionDialogState, setAddActionDialogState] = useState<{
    isOpen: boolean;
    chapterId: MedicalProtocolChapterDto['id'] | null;
  }>({
    isOpen: false,
    chapterId: null
  });

  const closeAddActionDialog = () => {
    setAddActionDialogState({
      isOpen: false,
      chapterId: null
    });
  };

  const onAddAction = (chapterId: MedicalProtocolChapterDto['id']) =>
    setAddActionDialogState({
      isOpen: true,
      chapterId
    });

  const [deleteActionDialogState, setDeleteActionDialogState] = useState<{
    isOpen: boolean;
    chapterId: MedicalProtocolChapterDto['id'] | null;
    action: MedicalProtocolActionDto | null;
  }>({
    isOpen: false,
    chapterId: null,
    action: null
  });

  const onDeleteAction = (chapterId: MedicalProtocolChapterDto['id'], action: MedicalProtocolActionDto) => {
    setDeleteActionDialogState({
      isOpen: true,
      chapterId,
      action
    });
  };

  const closeDeleteActionDialog = () => {
    setDeleteActionDialogState({
      isOpen: false,
      chapterId: null,
      action: null
    });
  };

  const [deleteChapterDialogState, setDeleteChapterDialogState] = useState<{
    isOpen: boolean;
    chapter: MedicalProtocolChapterDto | null;
  }>({
    isOpen: false,
    chapter: null
  });

  const onDeleteChapter = (chapter: MedicalProtocolChapterDto) => {
    setDeleteChapterDialogState({
      isOpen: true,
      chapter: chapter
    });
  };

  const closeDeleteChapterDialog = () => {
    setDeleteChapterDialogState({
      isOpen: false,
      chapter: null
    });
  };

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  if (!chapters.length) {
    return (
      <Box color={'action.disabled'} py={4} textAlign={'center'}>
        <Typography>
          {translate(nameof.full<I18nProtocolReferencesNs>((n) => n.chapterCard.noChaptersAdded))}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {chapters.map((chapter) => (
        <ChapterItem
          key={chapter.id}
          medIndexId={medIndexId}
          chapter={chapter}
          onDeleteAction={(action) => onDeleteAction(chapter.id, action)}
          onDeleteChapter={() => onDeleteChapter(chapter)}
          onAddAction={() => onAddAction(chapter.id)}
        />
      ))}

      <DeleteChapterDialog {...deleteChapterDialogState} onClose={closeDeleteChapterDialog} medIndexId={medIndexId} />
      <DeleteActionDialog {...deleteActionDialogState} onClose={closeDeleteActionDialog} medIndexId={medIndexId} />
      <AddActionDialog {...addActionDialogState} onClose={closeAddActionDialog} medIndexId={medIndexId} />
    </>
  );
}

export default ChaptersList;
