import { createAction } from 'redux-actions';

export enum Type {
  ADD_ERROR = 'ADD_ERROR',
  DELETE_ERROR = 'DELETE_ERROR',
  ADD_INFO = 'ADD_INFO',
  DELETE_INFO = 'DELETE_INFO',
  DELETE_INFO_BY_TEXT = 'DELETE_INFO_BY_TEXT',
  ADD_WARNING = 'ADD_WARNING',
  DELETE_WARNING = 'DELETE_WARNING',
  DELETE_WARNING_BY_TEXT = 'DELETE_WARNING_BY_TEXT'
}

export const addError = createAction<{ errorText: string }>(Type.ADD_ERROR);
export const deleteError = createAction<{ id: string }>(Type.DELETE_ERROR);
export const addInfo = createAction<{ infoText: string; timer?: number }>(Type.ADD_INFO);
export const deleteInfo = createAction<{ id: string }>(Type.DELETE_INFO);
export const deleteInfoByText = createAction<{ infoText: string }>(Type.DELETE_INFO_BY_TEXT);
export const addWarning = createAction<{ warningText: string; timer?: number; link?: string; linkText?: string }>(
  Type.ADD_WARNING
);
export const deleteWarning = createAction<{ id: string }>(Type.DELETE_WARNING);
export const deleteWarningByText = createAction<{ warningText: string }>(Type.DELETE_WARNING_BY_TEXT);
