import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { CircularProgress } from '@material-ui/core';

import { RoleId } from 'app/models/users/role.js';
import UserGuides from 'app/components/UserGuidesPage/UserGuidesPage.js';
import { Dashboard } from 'app/components/DashboardPage/index.js';
import AppRoutes, { CourseManagementRoutes } from 'app/utils/routes.js';
import UsersPage from 'app/components/UsersPage/UsersPage.js';
import { FallbackPage } from 'app/components/FallbackPage/index.js';
import { ProtectedRoute } from 'app/components/ProtectedRoute/index.js';
import MyCourses from 'app/components/CourseManagement/MyCourses.js';
import CallManagement from 'app/components/CallManagement/CallManagement.js';
import GroupManagement from 'app/components/GroupManagement/GroupManagement.js';
import CourseManagement from 'app/components/CourseManagement/CourseManagement.js';
import ScenarioEditorPage from 'app/components/ScenarioEditorPage/ScenarioEditorPage.js';
import GlobalAssetsLibrary from 'app/components/GlobalAssetsLibrary/GlobalAssetsLibrary.js';
import MedicalIndexManager from 'app/components/MedicalIndexesManager/MedicalIndexManager.js';
import GetPermissionForRecords from 'app/components/GetPermissionForRecords/getPermissionForRecords.js';

import 'app/components/AppInsights.js';

const App: React.FC = () => (
  <React.Fragment>
    <GetPermissionForRecords />
    <React.Suspense fallback={<CircularProgress />}>
      <Switch>
        <ProtectedRoute path={AppRoutes.SceneWithId} component={ScenarioEditorPage} />
        <ProtectedRoute
          roles={[RoleId.MEMBER, RoleId.ADMIN, RoleId.OWNER, RoleId.SUPERADMIN]}
          path={AppRoutes.UserGuides}
          component={UserGuides}
        />
        <ProtectedRoute path={AppRoutes.Dashboard}>
          <Route path={''} component={Dashboard} />
          <Route path={':id'} component={Dashboard} />
        </ProtectedRoute>
        <ProtectedRoute
          path={`${AppRoutes.GroupManagement}/:id?`}
          roles={[RoleId.ADMIN, RoleId.OWNER, RoleId.SUPERADMIN]}
          component={GroupManagement}
        />
        <ProtectedRoute
          roles={[RoleId.ADMIN, RoleId.OWNER, RoleId.SUPERADMIN]}
          path={CourseManagementRoutes.Root}
          component={CourseManagement}
        />
        <ProtectedRoute
          roles={[RoleId.MEMBER, RoleId.ADMIN, RoleId.OWNER, RoleId.SUPERADMIN]}
          path={AppRoutes.MyCourses}
          component={MyCourses}
        />
        <ProtectedRoute
          roles={[RoleId.ADMIN, RoleId.OWNER, RoleId.SUPERADMIN]}
          path={AppRoutes.UserManagement}
          component={UsersPage}
        />
        <ProtectedRoute exact path={AppRoutes.Root}>
          <Redirect to="/dashboard" />
        </ProtectedRoute>
        <ProtectedRoute
          exact
          path={`${AppRoutes.GlobalAssetsLibrary}/:id?`}
          roles={[RoleId.ADMIN, RoleId.OWNER, RoleId.SUPERADMIN]}
          component={GlobalAssetsLibrary}
        />
        <ProtectedRoute
          exact
          path={AppRoutes.CallManagement}
          roles={[RoleId.ADMIN, RoleId.OWNER, RoleId.SUPERADMIN]}
          component={CallManagement}
        />
        <ProtectedRoute
          exact
          path={`${AppRoutes.MedicalIndexManager}/:id?`}
          roles={[RoleId.ADMIN, RoleId.OWNER, RoleId.SUPERADMIN]}
          component={MedicalIndexManager}
        />
        <Route component={FallbackPage} />
      </Switch>
    </React.Suspense>
  </React.Fragment>
);

export default App;
