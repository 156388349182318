export const enum I18nLanguage {
  English = 'en',
  EnglishUs = 'en-US',
  EnglishUk = 'en-GB',
  Norwegian = 'no',
  German = 'de',
  Swedish = 'sv',
  Dutch = 'nl',
  Icelandic = 'is'
}

const I18nLanguageList = [
  I18nLanguage.English,
  I18nLanguage.Norwegian,
  I18nLanguage.German,
  I18nLanguage.EnglishUs,
  I18nLanguage.EnglishUk,
  I18nLanguage.Swedish,
  I18nLanguage.Icelandic,
  I18nLanguage.Dutch
];

export function getI18nLanguageList() {
  return I18nLanguageList;
}

const I18nSupportedLanguageList = [I18nLanguage.English, I18nLanguage.Norwegian];

export function getI18nSupportedLanguageList() {
  return I18nSupportedLanguageList;
}

export const I18nLanguageNames: Record<I18nLanguage, string> = {
  [I18nLanguage.English]: 'English',
  [I18nLanguage.EnglishUs]: 'English (US)',
  [I18nLanguage.EnglishUk]: 'English (UK)',
  [I18nLanguage.Norwegian]: 'Norwegian',
  [I18nLanguage.German]: 'German',
  [I18nLanguage.Swedish]: 'Swedish',
  [I18nLanguage.Dutch]: 'Dutch',
  [I18nLanguage.Icelandic]: 'Icelandic'
};
