import { useBoolState } from 'app/utils/customHooks/index.js';
import React from 'react';
import { Box, Button } from '@material-ui/core';
import type { MedicalProtocolDto } from '@/generated-api/index.js';
import CreateChapterDialog from 'app/components/MedicalIndexesManager/MedIndexCard/ChapterList/ChaptersActions/CreateChapterDialog/CreateChapterDialog.js';
import { Add } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

export type ChapterActionsProps = {
  medIndex: MedicalProtocolDto;
};

function ChapterActions({ medIndex }: ChapterActionsProps): JSX.Element {
  const [isDialogOpen, openDialog, closeDialog] = useBoolState();

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <>
      <Box display={'flex'} mt={1}>
        <Button startIcon={<Add />} onClick={openDialog}>
          {translate(nameof.full<I18nProtocolReferencesNs>((n) => n.createChapter))}
        </Button>
      </Box>
      <CreateChapterDialog medIndex={medIndex} isOpen={isDialogOpen} onClose={closeDialog} />
    </>
  );
}

export default ChapterActions;
