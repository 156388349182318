/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import type { GridColumns } from '@material-ui/data-grid';
import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';

import useDelete from 'app/hooks/useDelete.js';
import { DateFormat } from 'app/app.constants.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import type { CourseDto, ScheduledCourseDto, ScheduledCourseUserGroupDto } from '@/generated-api/index.js';
import CreateScheduledCourseUserGroupDialog from 'app/components/CourseManagement/ScheduledCourse/CreateScheduledCourseUserGroupDialog.js';
import DeleteScheduledCourseUserGroupDialog from 'app/components/CourseManagement/ScheduledCourse/DeleteScheduledCourseUserGroupDialog.js';
import { Add, Delete } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const RowsPerPage = 25;

type ScheduledCourseUserGroupsListProps = {
  courseId: string;
  course: CourseDto;
  scheduledCourse: ScheduledCourseDto;
};

const ScheduledCourseUserGroupsList: React.FC<ScheduledCourseUserGroupsListProps> = ({
  course,
  courseId,
  scheduledCourse
}) => {
  const formatDate = useFormatDate();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolState(false);

  const {
    closeConfirmationDialog,
    isConfirmationDialogOpen,
    onSelectItem: onSelectScheduledCourseUserGroup,
    selectedItem: selectedScheduledCourseUserGroup,
    resetSelectedItem: resetSelectedScheduledCourseUserGroup
  } = useDelete<ScheduledCourseUserGroupDto>();

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Title',
        flex: 2
      },
      {
        field: 'createdDate',
        headerName: 'Added',
        flex: 1,
        renderCell: (data) => formatDate(data.value as string, DateFormat.Time)
      },
      {
        width: 50,
        field: 'actions',
        headerName: ' ',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (data) => {
          const scheduledCourseUserGroup = data.row as ScheduledCourseUserGroupDto;

          return (
            <Tooltip title="Delete" placement="bottom">
              <IconButton size="small" onClick={() => onSelectScheduledCourseUserGroup(scheduledCourseUserGroup)}>
                <Delete />
              </IconButton>
            </Tooltip>
          );
        }
      }
    ],
    [formatDate, onSelectScheduledCourseUserGroup]
  );

  if (!scheduledCourse) return null;

  return (
    <>
      <SimpleDataGrid
        autoHeight
        loading={false}
        columns={columns}
        pageSize={RowsPerPage}
        hideFooterSelectedRowCount
        isCellEditable={() => false}
        isRowSelectable={() => false}
        rows={scheduledCourse.userGroups}
        disableSelectionOnClick
        rowsPerPageOptions={[RowsPerPage]}
        components={{
          Toolbar: () => (
            <Box padding={1}>
              <Grid container justifyContent="flex-start" wrap="nowrap">
                <Button startIcon={<Add />} variant="outlined" onClick={openCreateDialog}>
                  {translate(nameof.full<I18nCourseManagementNs>((n) => n.groups.addGroup))}
                </Button>
              </Grid>
            </Box>
          )
        }}
      />
      <CreateScheduledCourseUserGroupDialog
        course={course}
        courseId={courseId}
        isOpen={isCreateDialogOpen}
        onClose={closeCreateDialog}
        scheduledCourse={scheduledCourse}
      />
      <DeleteScheduledCourseUserGroupDialog
        course={course}
        courseId={courseId}
        open={isConfirmationDialogOpen}
        onClose={closeConfirmationDialog}
        scheduledCourse={scheduledCourse}
        onExited={resetSelectedScheduledCourseUserGroup}
        scheduledCourseUserGroup={selectedScheduledCourseUserGroup}
      />
    </>
  );
};

export default ScheduledCourseUserGroupsList;
