import type { CourseDto, ScheduledCourseDto } from '@/generated-api/index.js';
import type { RootState } from 'app/reducers/index.js';
import { LoadingStatus } from 'app/store/types.js';
import type { DefaultRootState } from 'react-redux';

function create<T>(selector: (state: RootState['courseManagement']) => T): (state: RootState) => T {
  return (state: RootState) => selector(state.courseManagement);
}

export const courseManagementSelector = create((s) => s);
export const coursesManagementLoadingStatusSelector = create((s) => s.loadingStatus);

export const coursesSelector = create((state) => state.courses);
export const scheduledCoursesSelector = create((state) => state.scheduledCourses);

export const scheduledCourseById: (state: DefaultRootState, scheduledCourseId: string) => ScheduledCourseDto = (
  state,
  scheduledCourseId
) =>
  (state as RootState).courseManagement.scheduledCourses.find(
    (c: ScheduledCourseDto) => c.id.toString() === scheduledCourseId
  );

export const courseById: (state: DefaultRootState, courseId: string) => CourseDto = (state, courseId) =>
  (state as RootState).courseManagement.courses.find((c: CourseDto) => c.id.toString() === courseId);

export const isCoursesStatusLoadingSelector = create((s) => s.loadingStatus === LoadingStatus.Loading);
export const isCoursesStatusNotIdleSelector = create((s) => s.loadingStatus !== LoadingStatus.Idle);

export const createCourseLoadingStatus = create((state) => state.loadingStatuses.createCourse);
export const updateCourseLoadingStatus = create((state) => state.loadingStatuses.updateCourse);
export const deleteCourseLoadingStatus = create((state) => state.loadingStatuses.deleteCourse);
export const createCourseSceneLoadingStatus = create((state) => state.loadingStatuses.createCourseScene);
export const deleteCourseSceneLoadingStatus = create((state) => state.loadingStatuses.deleteCourseScene);
export const createScheduledCourseLoadingStatus = create((state) => state.loadingStatuses.createScheduledCourse);
export const deleteScheduledCourseLoadingStatus = create((state) => state.loadingStatuses.deleteScheduledCourse);

export const createScheduledCourseUserLoadingStatus = create(
  (state) => state.loadingStatuses.createScheduledCourseUser
);

export const deleteScheduledCourseUserLoadingStatus = create(
  (state) => state.loadingStatuses.deleteScheduledCourseUser
);

export const createScheduledCourseUserGroupLoadingStatus = create(
  (state) => state.loadingStatuses.createScheduledCourseUserGroup
);

export const deleteScheduledCourseUserGroupLoadingStatus = create(
  (state) => state.loadingStatuses.deleteScheduledCourseUserGroup
);

export const requestScheduledCoursesLoadingStatus = create((state) => state.loadingStatuses.requestScheduledCourses);
