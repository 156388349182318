import type { FC } from 'react';
import React from 'react';
import type { Control, FieldError } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import type IntentNodeProperties from 'app/models/intents/intentNodeProperties.js';
import type { INode } from '@mrblenny/react-flow-chart';
import { IntentEditingActions } from 'app/actions/scenarios/intentEditingAction.js';
import type { IntentEditingFormData } from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/types.js';
import { AccordionDetails, Box, List, ListItem, ListItemIcon, IconButton } from '@material-ui/core';
import CustomAccordion from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/CustomAccordion.js';
import type { IntentPhraseDto } from '@/generated-api/index.js';
import VariableSuggestionInput from 'app/components/ScenarioEditorPage/Variables/VariableSuggestionInput.js';
import { useQuery } from '@tanstack/react-query';
import { getVariablesQuery } from 'app/queries/variableQueries.js';
import { ScenarioSelectors } from 'app/selectors/index.js';
import { Add, Delete } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const UserSaysSettings: FC<{
  intent: INode<IntentNodeProperties, undefined>;
  control: Control<IntentNodeProperties>;
  errors?: FieldError[];
  disabled?: boolean;
}> = ({ disabled, control, errors, ...props }) => {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const isDeleteButtonDisplayed = props.intent?.properties?.intentPhrases.length > 1;
  const count = props.intent?.properties?.intentPhrases.length;

  const currentChartScenario = useSelector(ScenarioSelectors.currentChartScenario);
  const variablesQueryData = useQuery(getVariablesQuery(currentChartScenario.id));
  const variables = variablesQueryData.data ?? [];

  return (
    <CustomAccordion
      title={translate(nameof.full<I18nScenarioEditorNs>((n) => n.userSaysSettings.userSays))}
      badgeNumber={count}
    >
      <AccordionDetails>
        <List className="full-width" dense>
          {props.intent?.properties?.intentPhrases?.map((x: IntentPhraseDto, i: number) => {
            return (
              <ListItem disableGutters dense key={i}>
                <Controller
                  defaultValue={x.text}
                  name={`${nameof.full<IntentEditingFormData>((f) => f.intentPhrases)}[${x.text}:${i}]`}
                  control={control}
                  render={({ onChange, ...props }) => {
                    return (
                      <Box px={1} flexGrow={1}>
                        <VariableSuggestionInput
                          value={props.value}
                          inputProps={{
                            disabled: disabled,
                            InputProps: {
                              inputProps: {
                                maxRows: 5
                              }
                            },
                            error: !!errors?.[i], 
                            helperText: errors?.[i], 
                            ...props
                          }}
                          onChange={(value) => {
                            onChange(value);
                            dispatch(IntentEditingActions.changeIntentUserSays({ index: i, value }));
                          }}
                          variables={variables}
                        />
                      </Box>
                    );
                  }}
                />

                <ListItemIcon>
                  {isDeleteButtonDisplayed && (
                    <IconButton disabled={disabled} onClick={() => dispatch(IntentEditingActions.removeUserSays(i))}>
                      <Delete />
                    </IconButton>
                  )}
                  <IconButton disabled={disabled} onClick={() => dispatch(IntentEditingActions.addUserSays(i))}>
                    <Add />
                  </IconButton>
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      </AccordionDetails>
    </CustomAccordion>
  );
};

export default UserSaysSettings;
