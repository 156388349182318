/* eslint-disable react/display-name */
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import type { GridColumns } from '@material-ui/data-grid';
import { generatePath, Link } from 'react-router-dom';

import {
  Box,
  Menu,
  Grid,
  Button,
  Divider,
  MenuItem,
  IconButton,
  ListItemText,
  Link as MuiLink,
  makeStyles
} from '@material-ui/core';

import useMenu from 'app/hooks/useMenu.js';
import useDelete from 'app/hooks/useDelete.js';
import { DateFormat } from 'app/app.constants.js';
import { CourseManagementRoutes } from 'app/utils/routes.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import type { CourseDto, ScheduledCourseDto } from '@/generated-api/index.js';
import DeleteScheduledCourseDialog from 'app/components/CourseManagement/ScheduledCourse/DeleteScheduledCourseDialog.js';
import CreateScheduledCourseDialog from 'app/components/CourseManagement/ScheduledCourse/CreateScheduledCourseDialog.js';
import { Add, MoreVert } from '@material-ui/icons';
import UpdateScheduledCourseDialog from './ScheduledCourse/UpdateScheduledCourseDialog.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';

type CourseScheduleListProps = {
  course: CourseDto;
  courseId: string;
};

type ScheduledCourseMenuProps = {
  courseId: string;
  scheduledCourse: ScheduledCourseDto;
  onSelectScheduledCourse: (scheduledCourse: ScheduledCourseDto) => void;
  handleClickEdit: (scheduledCourse: ScheduledCourseDto) => void;
};

const ScheduledCourseMenu: React.FC<ScheduledCourseMenuProps> = ({
  courseId,
  scheduledCourse,
  onSelectScheduledCourse,
  handleClickEdit
}) => {
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const [translate] = useTranslation([I18nNamespace.Common]);

  if (!scheduledCourse) return null;

  const path = generatePath(CourseManagementRoutes.ScheduledCourse, {
    courseId: courseId,
    scheduledCourseId: scheduledCourse.id
  });

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu open={isOpen} anchorEl={anchorEl} onClick={handleClose} onClose={handleClose}>
        <MenuItem component={Link} to={path}>
          <ListItemText primary={translate(nameof.full<I18nCommonNs>((n) => n.buttonLabels.viewDetails))} />
        </MenuItem>
        <MenuItem onClick={() => handleClickEdit(scheduledCourse)}>
          <ListItemText primary={translate(nameof.full<I18nCommonNs>((n) => n.buttonLabels.edit))} />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => onSelectScheduledCourse(scheduledCourse)}>
          <ListItemText primary={translate(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))} />
        </MenuItem>
      </Menu>
    </>
  );
};

const CourseScheduleList: React.FC<CourseScheduleListProps> = ({ course, courseId }) => {
  const classes = useStyles();

  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolState(false);
  const [updateScheduledCourse, setUpdatedScheduledCourse] = useState<ScheduledCourseDto | null>(null);
  const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
  const [translate] = useTranslation([I18nNamespace.CourseManagement, I18nNamespace.Common]);

  const handleClickEdit = useCallback((scheduledCourse: ScheduledCourseDto) => {
    setUpdatedScheduledCourse(scheduledCourse);
    setUpdateDialogOpen(true);
  }, []);

  const {
    closeConfirmationDialog,
    isConfirmationDialogOpen,
    onSelectItem: onSelectScheduledCourse,
    selectedItem: selectedScheduledCourse,
    resetSelectedItem: resetSelectedScheduledCourse
  } = useDelete<ScheduledCourseDto>();

  const renderDateCell = useCallback((data) => {
    if (!data.value) return null;

    return format(new Date(data.value as string), DateFormat.Date);
  }, []);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'departmentName',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.department)),
        flex: 1,
        renderCell: (data) => {
          const scheduledCourse = data.row as ScheduledCourseDto;

          const path = generatePath(CourseManagementRoutes.ScheduledCourse, {
            courseId: scheduledCourse.courseId,
            scheduledCourseId: scheduledCourse.id
          });

          return (
            <MuiLink to={path} component={Link} className={classes.departmentName}>
              {scheduledCourse.departmentName ??
                translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.defaultDepartment))}
            </MuiLink>
          );
        }
      },
      {
        field: 'startDate',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.startDate)),
        flex: 1,
        renderCell: renderDateCell
      },
      {
        field: 'endDate',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.endDate)),
        flex: 1,
        renderCell: renderDateCell
      },
      {
        field: 'users',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.table.users)),
        flex: 1,
        renderCell: (data) => {
          const scheduledCourse = data.row as ScheduledCourseDto;

          return scheduledCourse.users.length;
        }
      },
      {
        field: 'userGroups',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.table.groups)),
        flex: 1,
        renderCell: (data) => {
          const scheduledCourse = data.row as ScheduledCourseDto;

          return scheduledCourse.userGroups.length;
        }
      },
      {
        field: 'repeat',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.table.repeat)),
        flex: 1,
        renderCell: () => translate(nameof.full<I18nCourseManagementNs>((n) => n.table.never))
      },
      {
        width: 50,
        field: 'actions',
        headerName: ' ',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (data) => {
          const scheduledCourse = data.row as ScheduledCourseDto;

          return (
            <ScheduledCourseMenu
              handleClickEdit={handleClickEdit}
              courseId={courseId}
              onSelectScheduledCourse={onSelectScheduledCourse}
              scheduledCourse={scheduledCourse}
            />
          );
        }
      }
    ],
    [renderDateCell, classes.departmentName, handleClickEdit, courseId, onSelectScheduledCourse]
  );

  if (!course) return null;

  return (
    <>
      <SimpleDataGrid
        autoHeight
        loading={false}
        columns={columns}
        rows={course.scheduledCourses}
        hideFooterSelectedRowCount
        isCellEditable={() => false}
        isRowSelectable={() => false}
        disableSelectionOnClick
        components={{
          Toolbar: () => (
            <Box padding={1}>
              <Grid container justifyContent="flex-start" wrap="nowrap">
                <Button startIcon={<Add />} variant="outlined" onClick={openCreateDialog}>
                  {translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.scheduleCourse))}
                </Button>
              </Grid>
            </Box>
          )
        }}
      />
      <CreateScheduledCourseDialog
        course={course}
        courseId={courseId}
        isOpen={isCreateDialogOpen}
        onClose={closeCreateDialog}
      />
      <DeleteScheduledCourseDialog
        course={course}
        courseId={courseId}
        open={isConfirmationDialogOpen}
        onClose={closeConfirmationDialog}
        onExited={resetSelectedScheduledCourse}
        scheduledCourse={selectedScheduledCourse}
      />
      <UpdateScheduledCourseDialog
        scheduledCourse={updateScheduledCourse}
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  departmentName: {
    textDecoration: 'underline'
  }
}));

export default CourseScheduleList;
