import React, { useState } from 'react';

import { useBoolState } from 'app/utils/customHooks/index.js';

const useEditUser = () => {
  const [selectedUserId, setSelectedUserId] = useState<number>(null);

  const [isRightPaneOpen, openRightPane, closeRightPane] = useBoolState();

  const onSelectUserId = React.useCallback(
    (userId: number) => {
      closeRightPane();
      setSelectedUserId(userId);
      openRightPane();
    },
    [openRightPane, closeRightPane, setSelectedUserId]
  );

  const handleCloseForm = React.useCallback(() => {
    closeRightPane();
    setSelectedUserId(null);
  }, [setSelectedUserId, closeRightPane]);

  return {
    selectedUserId,
    onSelectUserId,
    isRightPaneOpen,
    handleCloseForm
  };
};

export default useEditUser;
