import React from 'react';
import { IntentActions } from 'app/actions/scenarios/intentAction.js';
import { ScenarioChartEditingActions } from 'app/actions/scenarios/scenarioChartEditingActions.js';
import { ScenarioChartSelectors } from 'app/selectors/index.js';

import { useDispatch, useSelector } from 'react-redux';
import { isSelectedNodeInitial as isSelectedNodeInitialSelector } from 'app/selectors/scenarioChartSelectors.js';
import { Button, Tooltip, Typography } from '@material-ui/core';
import { Add, DeleteOutlineOutlined } from '@material-ui/icons';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const IntentActionsMenu: React.FC<{ isDisabled: boolean }> = (props) => {
  const dispatch = useDispatch();
  const selectedLinkId = useSelector(ScenarioChartSelectors.selectedLinkId);
  const selectedNodeId = useSelector(ScenarioChartSelectors.selectedNodeId);
  const isSelectedNodeInitial = useSelector(isSelectedNodeInitialSelector);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const onConfirm = React.useCallback(() => {
    dispatch(ScenarioChartEditingActions.onDeleteAllLinks());
    setConfirmationDialogOpen(false);
  }, [dispatch]);

  const deleteButtonText =
    selectedNodeId != null
      ? translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentActionsMenu.removeNode))
      : selectedLinkId != null
      ? translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentActionsMenu.removeLink))
      : null;

  // wrapped in order to display tooltip
  const removeButton = (selectedLinkId != null || selectedNodeId != null) && (
    <div>
      <Button
        startIcon={<DeleteOutlineOutlined />}
        disabled={isSelectedNodeInitial || props.isDisabled}
        onClick={() =>
          selectedNodeId != null
            ? dispatch(IntentActions.removeIntent(selectedNodeId))
            : dispatch(ScenarioChartEditingActions.onDeleteSelected())
        }
      >
        <Typography style={{ textTransform: 'none' }}> {deleteButtonText} </Typography>
      </Button>
    </div>
  );

  return (
    <>
      <Button
        startIcon={<Add />}
        data-cy="addIntentButton"
        disabled={props.isDisabled}
        onClick={() => dispatch(IntentActions.addIntent())}
      >
        <Typography style={{ textTransform: 'none' }}>
          {' '}
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentActionsMenu.addIntent))}{' '}
        </Typography>
      </Button>

      {isSelectedNodeInitial
        ? wrapWithTooltip(
            removeButton,
            translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentActionsMenu.startIntentCantBeRemoved))
          )
        : removeButton}
      <Button startIcon={<DeleteOutlineOutlined />} disabled={props.isDisabled}>
        <Typography style={{ textTransform: 'none' }} onClick={() => setConfirmationDialogOpen(true)}>
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentActionsMenu.removeAllLinks))}
        </Typography>
      </Button>
      <DeleteConfirmationDialog
        title={translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentActionsMenu.deleteAllLinks))}
        confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
        onClose={() => setConfirmationDialogOpen(false)}
        onConfirm={onConfirm}
        open={isConfirmationDialogOpen}
      >
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentActionsMenu.confirmDeleteAll))}
      </DeleteConfirmationDialog>
    </>
  );
};

function wrapWithTooltip(element: React.ReactElement, text: React.ReactNode) {
  return <Tooltip title={text}>{element}</Tooltip>;
}

export default IntentActionsMenu;
