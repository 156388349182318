import React, { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, Grid, Typography, Chip } from '@material-ui/core';
import useStyles from 'app/components/GlobalAssetsLibrary/CategoryCard/useStyles.js';
import Actions from './Actions/Actions.js';
import { LoadingStatus } from 'app/store/types.js';
import {
  categoriesSelector,
  globalAssetsLibraryLoadingStatusSelector,
  isGlobalAssetsLibraryStatusLoadingSelector
} from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySelectors.js';
import NoCategoryData from 'app/components/GlobalAssetsLibrary/CategoryCard/NoData/NoCategoryData.js';
import EditableTitle from 'app/components/GlobalAssetsLibrary/CategoryCard/EditableTitle/EditableTitle.js';
import TemplatesList from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/TemplatesList.js';
import TemplateActions from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/TemplateActions/TemplatesActions.js';
import RightPane from 'app/components/GlobalAssetsLibrary/CategoryCard/RightPane/RightPane.js';
import { isUserSuperAdminSelector, isUserOwnerSelector } from 'app/selectors/userSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';
import { GlobalAssetsLibraryContext } from '../Context/GlobalAssetsLibraryContext.js';

function CategoryCard(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const isAssetsLoading = useSelector(isGlobalAssetsLibraryStatusLoadingSelector);
  const loadingStatus = useSelector(globalAssetsLibraryLoadingStatusSelector);
  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);
  const isUserOwner = useSelector(isUserOwnerSelector);
  const { searchText, setSearchText } = useContext(GlobalAssetsLibraryContext);
  const categories = useSelector(categoriesSelector);

  const category = useMemo(() => {
    return categories.find((c) => String(c.id) == id);
  }, [id, categories]);

  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  if (loadingStatus !== LoadingStatus.Succeeded || !category || isAssetsLoading) {
    return <NoCategoryData loadingStatus={loadingStatus} isAssetsLoading={isAssetsLoading} />;
  }

  const disableEdit = !isUserSuperAdmin && !isUserOwner && !category.companyId;
  const handleClearFilter = () => {
    setSearchText('');
  };

  return (
    <Card square classes={{ root: classes.card }}>
      <EditableTitle category={category} />

      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={7}>
            <Box display={'flex'} flexDirection={'column'}>
              {!disableEdit ? <Actions category={category} /> : null}
              <Box mt={2}>
                {searchText && <Chip label={translate(nameof.full<I18nGlobalAssetsNs>((n) => n.categoryCard.filter)) + ': ' + searchText} onDelete={handleClearFilter} />}
              </Box>
              <Box mt={4}>
                <Typography variant={'h6'}>
                  {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.categoryCard.intents))}
                </Typography>
              </Box>

              {!disableEdit ? <TemplateActions category={category} /> : null}
              <TemplatesList disableEdit={disableEdit} category={category} />
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <RightPane category={category} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CategoryCard;
