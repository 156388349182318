import type {
  AddBlockCommand,
  AddBlockLinkCommand,
  SceneDto,
  SceneExtendedDto,
  UpdateScenarioContentCommand
} from '@/generated-api/index.js';
import { BlockType } from '@/generated-api/index.js';
import type { CustomNode } from '../../../models/intents/customNode.js';
import { getNodeDecartPosition, getCenterCoordinates } from '../positionHelpers.js';
import type { ILink } from '@mrblenny/react-flow-chart';
import type { CustomChart } from '../../../models/scenarios/customChart.js';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';
import type ChartDOMPosition from '../../../models/guiState/chartDomPosition.js';
import { MapColorToEnum } from '../../../utils/colorEnumToStringMapper.js';

// TODO: finish this
export type BlockWithRequiredFields = AddBlockCommand &
  Required<Pick<AddBlockCommand, 'secondsFromDialogStartEstimate'>>;

function checkIfValidUUID(str: string): boolean {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}

export function blockFromNode(node: CustomNode): BlockWithRequiredFields {
  const blockPosition = getNodeDecartPosition(node.position);

  const blockId = checkIfValidUUID(node.id) ? 0 : Number.parseInt(node.id, 10) ?? 0;
  return {
    id: blockId,
    type: NodeTypeToblockTypeMap[node.type],
    name: node.properties.name,
    description: node.properties.description,
    intentPhrases: node.properties.intentPhrases,
    aiResponses: (node.properties.aiResponses ?? []).concat(node.properties.fallbackResponses ?? []),
    qualityLevel: MapColorToEnum(node.properties.labelColor) as number,
    medicalProtocolActions:
      !node.properties.medicalProtocolActions || node.properties.medicalProtocolActions.length == 0
        ? []
        : node.properties.medicalProtocolActions,
    posX: Math.trunc(blockPosition.x), // TODO Use double instead of int?
    posY: Math.trunc(blockPosition.y),
    secondsFromDialogStartEstimate: node.properties.secondsFromDialogStartEstimate,
    globalAssetIntents: node.properties.globalAssetIntents,
    frontendBlockId: checkIfValidUUID(node.id) ? node.id : null,
    personaId: node.properties.personaId
  };
}

export function linkFromChartLink(chartLink: ILink, sceneId: number): AddBlockLinkCommand {
  const linkId = checkIfValidUUID(chartLink.id) ? 0 : Number.parseInt(chartLink.id, 10) ?? 0;
  const originId = checkIfValidUUID(chartLink.from.nodeId) ? 0 : Number.parseInt(chartLink.from.nodeId, 10) ?? 0;
  const targetId = checkIfValidUUID(chartLink.to.nodeId) ? 0 : Number.parseInt(chartLink.to.nodeId, 10) ?? 0;
  const link: AddBlockLinkCommand = {
    id: linkId,
    originId,
    targetId,
    sceneId,
    frontendOriginId: checkIfValidUUID(chartLink.from.nodeId) ? chartLink.from.nodeId : null,
    frontendTargetId: checkIfValidUUID(chartLink.to.nodeId) ? chartLink.to.nodeId : null
  };

  return link;
}

function externalLinksFromNodeProperties(
  chart: CustomChart,
  scenarios: Array<SceneExtendedDto>
): Array<AddBlockLinkCommand> {
  const allLinks = Object.values(chart.nodes)
    .filter((x) => x.properties.linkedScenarios != undefined && x.properties.linkedScenarios.length > 0)
    .map((node) => {
      return node.properties.linkedScenarios.map((x) => {
        const originId = checkIfValidUUID(node.id) ? 0 : Number.parseInt(node.id, 10) ?? 0;
        const link: AddBlockLinkCommand = {
          id: x.id,
          originId,
          frontendOriginId: checkIfValidUUID(node.id) ? node.id : null,
          targetId: x.startBlockId ?? scenarios.find((s) => s.id === x.sceneId)?.startBlockId,
          sceneId: chart.properties.id
        };
        return link;
      });
    });

  const formattedLinks = allLinks.reduce((accumulator, currentValue) => [...accumulator, ...currentValue], []);

  return formattedLinks;
}

export function sceneFromChartAndScenario(
  chart: CustomChart,
  chartDOMInfo: ChartDOMPosition,
  scenario: SceneDto,
  scenarios: Array<SceneDto>
): UpdateScenarioContentCommand {
  const internalLinksArray = Object.values(chart.links);

  const blocks = Object.values(chart.nodes).map((n) => blockFromNode(n));

  const internalLinks = internalLinksArray.map((l) => linkFromChartLink(l, chart.properties.id));
  const externalLinks = externalLinksFromNodeProperties(chart, scenarios);
  const allLinks = internalLinks.concat(externalLinks);

  const centerOffset = getCenterCoordinates(
    chart.offset,
    { x: chartDOMInfo.chartDOMWidth, y: chartDOMInfo.chartDOMHeight },
    chart.scale
  );

  const scene: UpdateScenarioContentCommand = {
    ...scenario,
    id: chart.properties.id,
    blocks: blocks,
    blockLinks: allLinks,
    centerX: centerOffset.x,
    centerY: centerOffset.y,
    scale: chart.scale
  };

  return scene;
}

/// enum type maps

const NodeTypeToblockTypeMap: Record<string, BlockType> = {
  [CustomNodeTypeEnum.Normal]: BlockType.NUMBER_0,
  [CustomNodeTypeEnum.Start]: BlockType.NUMBER_1,
  [CustomNodeTypeEnum.End]: BlockType.NUMBER_2,
  [CustomNodeTypeEnum.Service]: BlockType.NUMBER_3
};
