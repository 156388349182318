import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 200,
      overflowY: 'auto',
      border: `1px solid ${theme.palette.divider}`
    },
    subheader: {
      pointerEvents: 'auto',
      backgroundColor: 'white'
    }
  })
);
