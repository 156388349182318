import React from 'react';
import type { TabsProps } from '@material-ui/core';
import { Tabs } from '@material-ui/core';
import { useLocation, useRouteMatch } from 'react-router';

const NavigationTabs: React.FC<TabsProps> = ({ children, ...rest }) => {
  const location = useLocation();

  const match = useRouteMatch({
    path: location.pathname
  });

  return (
    <Tabs value={match?.path} {...rest}>
      {children}
    </Tabs>
  );
};

export default NavigationTabs;
