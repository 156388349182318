import { makeStyles } from '@material-ui/core/styles/index.js';

export default makeStyles({
  flowChartContainer: {
    width: '100%',
    height: 'calc(100vh - 102px)',
    overflow: 'hidden'
  },
  backdropElevated: {
    zIndex: 1
  }
});
