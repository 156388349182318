import type { AxiosError, AxiosResponse } from 'axios';
import { all, put, select, call, takeLeading } from 'redux-saga/effects';

import {
  isCoursesStatusLoadingSelector,
  isCoursesStatusNotIdleSelector
} from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import type {
  UserDto,
  CourseDto,
  UserGroupDto,
  CourseSceneDto,
  ScheduledCourseDto,
  PartialListCourseDto
} from '@/generated-api/index.js';

import { CompanyClient, CourseClient } from 'app/apis/api.js';
import { LoadingStatus, LoadMode } from 'app/store/types.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import logger from 'app/utils/logger.js';
import type { CompanyDepartmentDto } from '@/generated-api/scenes/api.js';

function* requestCompanyDepartments(action: ReturnType<typeof CourseManagementActions.requestCompanyDepartments>) {
  const { companyId, onError, onSuccess } = action.payload;

  try {
    const response: AxiosResponse<CompanyDepartmentDto[]> = yield call(
      [CompanyClient, CompanyClient.companyGetDepartments],
      companyId
    );

    onSuccess(response.data);
  } catch (e) {
    onError();
  }
}

function* loadCourses(action: ReturnType<typeof CourseManagementActions.loadCourses>) {
  const { loadMode, companyId } = action.payload;

  const isLoading: boolean = yield select(isCoursesStatusLoadingSelector);

  if (isLoading) {
    return;
  }

  if (loadMode === LoadMode.Soft) {
    if (yield select(isCoursesStatusNotIdleSelector)) {
      return;
    }
  }

  yield put(CourseManagementActions.loadStarted());
  try {
    const response: AxiosResponse<PartialListCourseDto> = yield CourseClient.courseGet(companyId);

    yield put(CourseManagementActions.loadCoursesFinished(response.data.data.length ? response.data.data : []));
  } catch (error) {
    const axiosError = error as AxiosError;

    yield put(CourseManagementActions.loadCoursesFinished([], axiosError));
  }
}

function* requestCourse(action: ReturnType<typeof CourseManagementActions.requestCourse>) {
  const { courseId, onSuccess } = action.payload;

  try {
    const response: AxiosResponse<CourseDto> = yield call([CourseClient, CourseClient.courseGetCourse], courseId);

    yield put(CourseManagementActions.courseUpdated(response.data));

    if (onSuccess) {
      onSuccess(response.data);
    }

    return response.data;
  } catch (e) {
    logger.log(e);
    return null;
  }
}

function* createCourse(action: ReturnType<typeof CourseManagementActions.createCourse>) {
  const { data, onSuccess } = action.payload;

  yield put(CourseManagementActions.setCreateCourseLoadingStatus(LoadingStatus.Loading));

  try {
    const response: AxiosResponse<CourseDto> = yield call([CourseClient, CourseClient.courseCreateCourse], data);

    yield put(CourseManagementActions.courseCreated(response.data));

    yield put(CourseManagementActions.setCreateCourseLoadingStatus(LoadingStatus.Succeeded));

    onSuccess(response.data);
  } catch (e) {
    yield put(CourseManagementActions.setCreateCourseLoadingStatus(LoadingStatus.Failed));
  }
}

function* deleteCourse(action: ReturnType<typeof CourseManagementActions.deleteCourse>) {
  const { courseId, onSuccess } = action.payload;

  yield put(CourseManagementActions.setDeleteCourseLoadingStatus(LoadingStatus.Loading));

  try {
    yield call([CourseClient, CourseClient.courseDeleteCourse], courseId);

    yield put(CourseManagementActions.courseDeleted(courseId));

    yield put(CourseManagementActions.setDeleteCourseLoadingStatus(LoadingStatus.Succeeded));

    onSuccess();
  } catch (e) {
    yield put(CourseManagementActions.setDeleteCourseLoadingStatus(LoadingStatus.Failed));
  }
}

function* updateCourse(action: ReturnType<typeof CourseManagementActions.updateCourse>) {
  const { courseId, data } = action.payload;

  yield put(CourseManagementActions.setUpdateCourseLoadingStatus(LoadingStatus.Loading));

  try {
    const response: AxiosResponse<CourseDto> = yield call([CourseClient, CourseClient.courseUpdate], courseId, data);

    yield put(CourseManagementActions.courseUpdated(response.data));

    yield put(CourseManagementActions.setUpdateCourseLoadingStatus(LoadingStatus.Succeeded));
  } catch (e) {
    yield put(CourseManagementActions.setUpdateCourseLoadingStatus(LoadingStatus.Failed));
  }
}

function* deleteCourseScenario(action: ReturnType<typeof CourseManagementActions.deleteCourseScenario>) {
  const { courseId, sceneId, onSuccess } = action.payload;

  yield put(CourseManagementActions.setDeleteCourseSceneLoadingStatus(LoadingStatus.Loading));

  try {
    yield call([CourseClient, CourseClient.courseDeleteCourseScene], courseId, sceneId);

    yield call(requestCourse, CourseManagementActions.requestCourse(courseId));

    yield put(CourseManagementActions.setDeleteCourseSceneLoadingStatus(LoadingStatus.Succeeded));

    onSuccess();
  } catch (e) {
    yield put(CourseManagementActions.setDeleteCourseSceneLoadingStatus(LoadingStatus.Failed));
  }
}

function* createCourseScenario(action: ReturnType<typeof CourseManagementActions.createCourseScenario>) {
  const { courseId, sceneId, onSuccess } = action.payload;

  yield put(CourseManagementActions.setCreateCourseSceneLoadingStatus(LoadingStatus.Loading));

  try {
    const response: AxiosResponse<CourseSceneDto> = yield call(
      [CourseClient, CourseClient.courseCreateCourseScene],
      courseId,
      sceneId
    );

    yield put(CourseManagementActions.courseUpdated(response.data));

    yield put(CourseManagementActions.setCreateCourseSceneLoadingStatus(LoadingStatus.Succeeded));

    onSuccess();
  } catch (e) {
    yield put(CourseManagementActions.setCreateCourseSceneLoadingStatus(LoadingStatus.Failed));
  }
}

function* createScheduledCourse(action: ReturnType<typeof CourseManagementActions.createScheduledCourse>) {
  const { courseId, data, onSuccess } = action.payload;

  yield put(CourseManagementActions.setCreateScheduledCourseLoadingStatus(LoadingStatus.Loading));

  try {
    const response: AxiosResponse<ScheduledCourseDto> = yield call(
      [CourseClient, CourseClient.courseCreateScheduledCourse],
      courseId,
      data
    );

    yield call(requestCourse, CourseManagementActions.requestCourse(courseId));

    yield put(CourseManagementActions.setCreateScheduledCourseLoadingStatus(LoadingStatus.Succeeded));

    onSuccess(response.data);
  } catch (e) {
    yield put(CourseManagementActions.setCreateScheduledCourseLoadingStatus(LoadingStatus.Failed));
  }
}

function* deleteScheduledCourse(action: ReturnType<typeof CourseManagementActions.deleteScheduledCourse>) {
  const { courseId, scheduledCourseId, onSuccess } = action.payload;

  yield put(CourseManagementActions.setDeleteScheduledCourseLoadingStatus(LoadingStatus.Loading));

  try {
    yield call([CourseClient, CourseClient.courseDeleteScheduledCourse], scheduledCourseId, scheduledCourseId);

    yield call(requestCourse, CourseManagementActions.requestCourse(courseId));

    yield put(CourseManagementActions.setDeleteScheduledCourseLoadingStatus(LoadingStatus.Succeeded));

    onSuccess();
  } catch (e) {
    yield put(CourseManagementActions.setDeleteScheduledCourseLoadingStatus(LoadingStatus.Failed));
  }
}

function* requestScheduledCourseAvailableUsers(
  action: ReturnType<typeof CourseManagementActions.requestScheduledCourseAvailableUsers>
) {
  const { scheduledCourseId, onSuccess } = action.payload;

  try {
    const response: AxiosResponse<UserDto[]> = yield call(
      [CourseClient, CourseClient.courseGetScheduledCourseAvailableUsers],
      scheduledCourseId
    );

    onSuccess(response.data);
  } catch (error) {
    logger.error(error);
  }
}

function* requestScheduledCourseAvailableUserGroups(
  action: ReturnType<typeof CourseManagementActions.requestScheduledCourseAvailableUserGroups>
) {
  const { scheduledCourseId, onSuccess } = action.payload;

  try {
    const response: AxiosResponse<UserGroupDto[]> = yield call(
      [CourseClient, CourseClient.courseGetScheduledCourseAvailableUserGroups],
      scheduledCourseId
    );

    onSuccess(response.data);
  } catch (error) {
    logger.error(error);
  }
}

function* createScheduledCourseUser(action: ReturnType<typeof CourseManagementActions.createScheduledCourseUser>) {
  const { data, courseId, scheduledCourseId, onSuccess } = action.payload;

  yield put(CourseManagementActions.setCreateScheduledCourseUserLoadingStatus(LoadingStatus.Loading));

  try {
    yield call([CourseClient, CourseClient.courseCreateScheduledCourseUser], scheduledCourseId, data);

    yield call(requestCourse, CourseManagementActions.requestCourse(courseId));

    yield put(CourseManagementActions.setCreateScheduledCourseUserLoadingStatus(LoadingStatus.Succeeded));

    onSuccess();
  } catch (e) {
    yield put(CourseManagementActions.setCreateScheduledCourseUserLoadingStatus(LoadingStatus.Failed));
  }
}

function* deleteScheduledCourseUser(action: ReturnType<typeof CourseManagementActions.deleteScheduledCourseUser>) {
  const { userId, courseId, scheduledCourseId, onSuccess } = action.payload;

  yield put(CourseManagementActions.setDeleteScheduledCourseUserLoadingStatus(LoadingStatus.Loading));

  try {
    yield call([CourseClient, CourseClient.courseDeleteScheduledCourseUser], scheduledCourseId, userId);

    yield call(requestCourse, CourseManagementActions.requestCourse(courseId));

    yield put(CourseManagementActions.setDeleteScheduledCourseUserLoadingStatus(LoadingStatus.Succeeded));

    onSuccess();
  } catch (e) {
    yield put(CourseManagementActions.setDeleteScheduledCourseUserLoadingStatus(LoadingStatus.Failed));
  }
}

function* createScheduledCourseUserGroup(
  action: ReturnType<typeof CourseManagementActions.createScheduledCourseUserGroup>
) {
  const { data, courseId, scheduledCourseId, onSuccess } = action.payload;

  yield put(CourseManagementActions.setCreateScheduledCourseUserGroupLoadingStatus(LoadingStatus.Loading));

  try {
    yield call([CourseClient, CourseClient.courseCreateScheduledCourseUserGroup], scheduledCourseId, data);

    yield call(requestCourse, CourseManagementActions.requestCourse(courseId));

    yield put(CourseManagementActions.setCreateScheduledCourseUserGroupLoadingStatus(LoadingStatus.Succeeded));

    onSuccess();
  } catch (e) {
    yield put(CourseManagementActions.setCreateScheduledCourseUserGroupLoadingStatus(LoadingStatus.Failed));
  }
}

function* deleteScheduledCourseUserGroup(
  action: ReturnType<typeof CourseManagementActions.deleteScheduledCourseUserGroup>
) {
  const { userGroupId, courseId, scheduledCourseId, onSuccess } = action.payload;

  yield put(CourseManagementActions.setDeleteScheduledCourseUserGroupLoadingStatus(LoadingStatus.Loading));

  try {
    yield call([CourseClient, CourseClient.courseDeleteScheduledCourseUserGroup], scheduledCourseId, userGroupId);

    yield call(requestCourse, CourseManagementActions.requestCourse(courseId));

    yield put(CourseManagementActions.setDeleteScheduledCourseUserGroupLoadingStatus(LoadingStatus.Succeeded));

    onSuccess();
  } catch (e) {
    yield put(CourseManagementActions.setDeleteScheduledCourseUserGroupLoadingStatus(LoadingStatus.Failed));
  }
}

function* requestScheduledCourses() {
  yield put(CourseManagementActions.setRequestScheduledCoursesLoadingStatus(LoadingStatus.Loading));

  try {
    const response: AxiosResponse<ScheduledCourseDto[]> = yield call([
      CourseClient,
      CourseClient.courseGetCurrentUserScheduledCourses
    ]);

    yield put(CourseManagementActions.receiveScheduledCourses(response.data));

    yield put(CourseManagementActions.setRequestScheduledCoursesLoadingStatus(LoadingStatus.Succeeded));
  } catch (e) {
    yield put(CourseManagementActions.setRequestScheduledCoursesLoadingStatus(LoadingStatus.Failed));
  }
}

export default function* courseManagementSaga(): Generator {
  yield all([
    yield takeLeading(CourseManagementActions.loadCourses.toString(), loadCourses),
    yield takeLeading(CourseManagementActions.deleteCourse.toString(), deleteCourse),
    yield takeLeading(CourseManagementActions.createCourse.toString(), createCourse),
    yield takeLeading(CourseManagementActions.updateCourse.toString(), updateCourse),
    yield takeLeading(CourseManagementActions.requestCourse.toString(), requestCourse),
    yield takeLeading(CourseManagementActions.createCourseScenario.toString(), createCourseScenario),
    yield takeLeading(CourseManagementActions.deleteCourseScenario.toString(), deleteCourseScenario),
    yield takeLeading(CourseManagementActions.createScheduledCourse.toString(), createScheduledCourse),
    yield takeLeading(CourseManagementActions.deleteScheduledCourse.toString(), deleteScheduledCourse),
    yield takeLeading(CourseManagementActions.requestScheduledCourses.toString(), requestScheduledCourses),
    yield takeLeading(CourseManagementActions.requestCompanyDepartments.toString(), requestCompanyDepartments),
    yield takeLeading(CourseManagementActions.createScheduledCourseUser.toString(), createScheduledCourseUser),
    yield takeLeading(CourseManagementActions.deleteScheduledCourseUser.toString(), deleteScheduledCourseUser),
    yield takeLeading(
      CourseManagementActions.createScheduledCourseUserGroup.toString(),
      createScheduledCourseUserGroup
    ),
    yield takeLeading(
      CourseManagementActions.deleteScheduledCourseUserGroup.toString(),
      deleteScheduledCourseUserGroup
    ),
    yield takeLeading(
      CourseManagementActions.requestScheduledCourseAvailableUsers.toString(),
      requestScheduledCourseAvailableUsers
    ),
    yield takeLeading(
      CourseManagementActions.requestScheduledCourseAvailableUserGroups.toString(),
      requestScheduledCourseAvailableUserGroups
    )
  ]);
}
