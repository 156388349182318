import React from 'react';
import { useSelector } from 'react-redux';

import { Menu, MenuItem } from '@material-ui/core';

import { ScenarioSelectors } from '../../../../../selectors/index.js';

import type { CopyToScenarioMenuProps } from './types.js';
import useStyles from './styles.js';

const CopyToScenarioMenu: React.FC<CopyToScenarioMenuProps> = ({
  currentScenarioId,
  onClickCloneToScenario,
  ...menuProps
}) => {
  const scenarios = useSelector(ScenarioSelectors.scenariosSelector).filter((s) => s.id !== currentScenarioId);

  const onSelectScenario = (scId: number) => {
    menuProps.onClose();
    onClickCloneToScenario(scId);
  };

  const classes = useStyles();
  return (
    <Menu
      {...menuProps}
      MenuListProps={{
        dense: true
      }}
      getContentAnchorEl={undefined}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      PaperProps={{
        className: classes.paper
      }}
    >
      {scenarios.map((s) => (
        <MenuItem key={s.id} button onClick={() => onSelectScenario(s.id)}>
          {s.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CopyToScenarioMenu;
