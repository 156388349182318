import type { AiVoiceResponseDto, IntentPhraseDto } from '@/generated-api/index.js';
import type { ObjectSchema } from 'yup';
import { object, array, string, number } from 'yup';
import type { IntentEditingFormData } from './types.js';

export function getValidationSchema(): ObjectSchema<IntentEditingFormData | undefined> {
  return object<IntentEditingFormData>({
    secondsFromDialogStartEstimate: null,
    name: string().required().max(50),
    aiResponses: array<AiVoiceResponseDto>().required(),
    fallbackResponses: array<AiVoiceResponseDto>().required(),
    intentPhrases: array<IntentPhraseDto>().required(),
    labelColor: string().required(),
    globalAssetIntents: null,
    personaId: number().notRequired()
  });
}
