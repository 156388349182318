/* eslint-disable @typescript-eslint/no-explicit-any */
import appInsights from 'app/components/AppInsights.js';

// TODO Rework so message NEEDS to be a string, and optionalParams needs to be a object???
interface Logger {
  debug: (message?: any, ...optionalParams: any[]) => void;
  info: (message?: any, ...optionalParams: any[]) => void;
  warn: (message?: any, ...optionalParams: any[]) => void;
  error: (message?: any, ...optionalParams: any[]) => void;
  log: (message?: any, ...optionalParams: any[]) => void;
}

const convertPrimitives = (value: any) => {
  if (typeof value === 'object') return value;
  return { value: value };
};

const trackException = (exception: Error, optionalParams: any, severityLevel: number) => {
  appInsights.trackException({ exception, severityLevel }, convertPrimitives(optionalParams));
};

const trackTrace = (message: any, optionalParams: any, severityLevel: number) => {
  let msg = message;
  if (typeof msg !== 'string') {
    msg = JSON.stringify(msg); // message is string, so stringify if not a string!
  }
  appInsights.trackTrace({ message: msg, severityLevel }, convertPrimitives(optionalParams));
};

const appInsightsLog = (message: any, optionalParams: any, severityLevel: number) => {
  if (message instanceof Error) {
    trackException(message, optionalParams, severityLevel);
  } else {
    trackTrace(message, optionalParams, severityLevel);
  }
};

/**
 * Custom logger to log to both console and Application Insights
 */
const logger: Logger = {
  debug: (message, optionalParams) => {
    if (optionalParams) {
      console.debug(message, optionalParams);
    } else {
      console.debug(message);
    }
    appInsightsLog(message, optionalParams, 0);
  },
  info: (message, optionalParams) => {
    if (optionalParams) {
      console.info(message, optionalParams);
    } else {
      console.info(message);
    }
    appInsightsLog(message, optionalParams, 1);
  },
  warn: (message, optionalParams) => {
    if (optionalParams) {
      console.warn(message, optionalParams);
    } else {
      console.warn(message);
    }
    appInsightsLog(message, optionalParams, 2);
  },
  error: (message, optionalParams) => {
    if (optionalParams) {
      console.error(message, optionalParams);
    } else {
      console.error(message);
    }
    appInsightsLog(message, optionalParams, 3);
  },
  log: (message, optionalParams) => {
    if (optionalParams) {
      console.log(message, optionalParams);
    } else {
      console.log(message);
    }
    appInsightsLog(message, optionalParams, 1);
  }
};

export default logger;
