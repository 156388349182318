import React, { useEffect } from 'react';
import type { GlobalAssetSampleDto, GlobalAssetIntentDto } from '@/generated-api/index.js';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { GlobalAssetsClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import EditableInput from 'app/components/GlobalAssetsLibrary/CategoryCard/TemplatesList/EditableInput.js';
import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete.js';
import useIntentValidationSchema from 'app/components/GlobalAssetsLibrary/CategoryCard/hooks/useIntentValidationSchema.js';
import logger from 'app/utils/logger.js';

const { actions } = globalAssetsLibrarySlice;

export type EditableIntentProps = {
  intent: GlobalAssetSampleDto; // sample
  template: GlobalAssetIntentDto; // intent
  onDelete: () => void;
  disableEdit: boolean;
};

const nameFieldKey = nameof.full<GlobalAssetSampleDto>((m) => m.text);

function EditableIntent({ template, intent, onDelete, disableEdit }: EditableIntentProps): JSX.Element {
  const schema = useIntentValidationSchema('text', template, intent.id);

  const { errors, reset, getValues, control, handleSubmit } = useForm<GlobalAssetSampleDto>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      text: intent.text
    }
  });

  const dispatch = useDispatch();
  const onSubmit = handleSubmit(
    async (newIntent) => {
      if (!intent || intent.text === newIntent.text) {
        return;
      }

      try {
        const { data } = await GlobalAssetsClient.globalAssetsUpdateSample(
          template.id,
          intent.id,
          // backend requirements
          JSON.stringify(newIntent.text)
        );

        dispatch(actions.templateUpdated(data));
      } catch (e) {
        logger.error(e);
      }
    },
    (data) => {
      logger.error('An error occurred during updating intent', data);
    }
  );

  useEffect(() => {
    if (intent && getValues(nameFieldKey) !== intent?.text) {
      reset({
        text: intent.text
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intent?.text]);

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1} mr={1} overflow={'hidden'}>
        <Controller
          control={control}
          name={nameFieldKey}
          render={({ value, onChange, onBlur }) => {
            return (
              <EditableInput
                disable={disableEdit}
                value={value}
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                  onSubmit();
                }}
                error={errors.text?.message}
              />
            );
          }}
        />
      </Box>

      {!disableEdit ? (
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      ) : null}
    </Box>
  );
}

export default EditableIntent;
