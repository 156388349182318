import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors/index.js';
import type { LinearProgressProps } from '@material-ui/core/LinearProgress/index.js';
import LinearProgress from '@material-ui/core/LinearProgress/index.js';

import useStyles from './styles.js';

type LinearProgressWithLabelProps = LinearProgressProps & {
  scoresMax: number;
};

const LinearProgressWithLabel: React.FC<LinearProgressWithLabelProps> = ({ value, scoresMax }) => {
  const percent = (value / scoresMax) * 100;

  const color = useMemo(() => {
    if ((value == 0 && scoresMax == 0) || scoresMax == 0) return grey[500];
    if (percent <= 25) return red[500];
    if (percent <= 50) return red[400];

    return green[500];
  }, [value, scoresMax, percent]);

  const classes = useStyles({ color });

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Box marginRight={1}>
          <LinearProgress
            value={percent}
            variant="determinate"
            classes={{ root: classes.root, bar: classes.bar, colorPrimary: classes.colorPrimary }}
          />
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="primary">{`${Math.round(value)} / ${Math.round(scoresMax)}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default LinearProgressWithLabel;
