import ReactDOM from 'react-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  MenuItem,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  ListItemText
} from '@material-ui/core';

import { CourseClient } from 'app/apis/api.js';
import { LoadingStatus } from 'app/store/types.js';
import type { CourseDto, SceneDto } from '@/generated-api/index.js';
import LoadingButton from 'app/components/LoadingButton.js';
import * as validationSchemas from 'app/components/CourseManagement/CourseCard/validationSchema.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type CreateCourseScenarioDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  course: CourseDto;
};

export type CreateCourseScenarioForm = {
  sceneId: number;
};

export const NO_SCENE_SELECTED = '';

const CreateCourseScenarioDialog: React.FC<CreateCourseScenarioDialogProps> = ({ isOpen, onClose, course }) => {
  const dispatch = useDispatch();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const [scenes, setScenes] = useState<SceneDto[]>([]);
  const [scenesLoadingStatus, setScenesLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

  const { control, handleSubmit, errors, reset, formState } = useForm<CreateCourseScenarioForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchemas.createCourseScenario),
    defaultValues: {
      sceneId: NO_SCENE_SELECTED
    }
  });

  const requestAvailableScenes = useCallback(async () => {
    setScenesLoadingStatus(LoadingStatus.Loading);

    try {
      const scenes = await CourseClient.courseGetCourseAvailableScenes(course.id);

      ReactDOM.unstable_batchedUpdates(() => {
        setScenes(scenes.data);
        setScenesLoadingStatus(LoadingStatus.Succeeded);
      });
    } catch (error) {
      setScenesLoadingStatus(LoadingStatus.Failed);
    }
  }, [course]);

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => dispatch(CourseManagementActions.createCourseScenario(course.id, data.sceneId, onClose))),
    [onClose, dispatch, course, handleSubmit]
  );

  const isScenesLoading = scenesLoadingStatus === LoadingStatus.Loading;
  const isError = !scenes.length && scenesLoadingStatus === LoadingStatus.Failed;
  const hasScenesAvailable = !!scenes.length && scenesLoadingStatus === LoadingStatus.Succeeded;

  const displayNoScenesAvailable = !isScenesLoading && !hasScenesAvailable;

  const loadingStatus = useSelector(CourseManagementSelectors.createCourseSceneLoadingStatus);

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      TransitionProps={{
        onExited: () => reset(),
        onEnter: () => requestAvailableScenes()
      }}
    >
      <DialogTitle>{translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarioList.addScenario))}</DialogTitle>
      <DialogContent>
        <form id="create-course-scenario-form" onSubmit={onSubmit}>
          {!isError && (
            <Controller
              control={control}
              name={nameof.full<CreateCourseScenarioForm>((c) => c.sceneId)}
              render={(props) => (
                <TextField
                  select
                  required
                  fullWidth
                  margin="normal"
                  label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarioList.selectScenario))}
                  error={!!errors.sceneId?.message}
                  disabled={isScenesLoading || displayNoScenesAvailable}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  {...props}
                  inputRef={props.ref}
                >
                  {displayNoScenesAvailable && (
                    <MenuItem value={NO_SCENE_SELECTED}>
                      {translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarioList.noScenariosAvailable))}
                    </MenuItem>
                  )}
                  {scenes.map((scene: SceneDto) => (
                    <MenuItem key={scene.id} value={scene.id}>
                      <ListItemText style={{ margin: 0 }} primary={scene.name} secondary={scene.description ?? ''} />
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          )}
          {isError && <DialogContentText>An error occurred</DialogContentText>}
        </form>
      </DialogContent>
      <DialogActions>
        <Button disabled={loadingStatus === LoadingStatus.Loading} onClick={onClose}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loadingStatus={loadingStatus}
          form="create-course-scenario-form"
          disabled={!formState.isValid}
        >
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.add))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCourseScenarioDialog;
