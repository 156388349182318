import { Divider, Grid, Typography, Box, Button, MenuItem, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles/index.js';
import { useDispatch, useSelector } from 'react-redux';

import { matchPath, Route, useHistory, useLocation } from 'react-router';
import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import { LoadMode } from 'app/store/types.js';
import type { CompanyDto } from '@/generated-api/index.js';
import { CompaniesSelector } from 'app/selectors/index.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import SkeletonWrapper from 'app/components/SkeletonWrapper.js';
import AppRoutes, { CourseManagementRoutes } from 'app/utils/routes.js';
import CourseContainer from 'app/components/CourseManagement/CourseContainer.js';
import CourseList from 'app/components/CourseManagement/CourseList/CourseList.js';
import { CurrentUserSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import NoCourseData from 'app/components/CourseManagement/CourseCard/NoCourseData/NoCourseData.js';
import CreateCourseDialog from 'app/components/CourseManagement/CreateCourseDialog/CreateCourseDialog.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import { coursesManagementLoadingStatusSelector } from 'app/components/CourseManagement/store/courseManagementSelectors.js';
import { Add } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const CourseManagement: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(CurrentUserSelector);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const companies = useSelector(CompaniesSelector.companiesSelector);
  const loadingStatus = useSelector(coursesManagementLoadingStatusSelector);
  const companiesLoading = useSelector(CompaniesSelector.companiesAreLoadingSelector);

  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolState(false);

  // do not preselect a company if courseId is defined in URL
  const location = useLocation();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const match = matchPath<{ courseId: string }>(location.pathname, {
    path: CourseManagementRoutes.Course
  });

  // if User is SuperAdmin and a courseId is specified in the URL, we cannot initialize the selectedCompanyId until the Course is fetched and we know the companyId
  const initialSelectedCompanyId = isSuperAdmin && match?.params.courseId ? null : currentUser.companyId;

  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(initialSelectedCompanyId);

  useEffect(() => {
    if (isSuperAdmin && !selectedCompanyId) return;

    dispatch(
      CourseManagementActions.loadCourses({
        loadMode: LoadMode.Hard,
        companyId: isSuperAdmin ? selectedCompanyId : undefined
      })
    );
  }, [dispatch, isSuperAdmin, selectedCompanyId]);

  const displayCompanyField = companies.some((c) => c.id === selectedCompanyId);

  const setSelectedCompanyIdAndResetSelectedCourse = useCallback(
    (companyId: number) => {
      setSelectedCompanyId(companyId);
      history.replace(AppRoutes.CourseManagement);
    },
    [history]
  );

  return (
    <Box overflow={'hidden'} height={'100%'} display={'flex'}>
      <Grid container>
        <Grid xs={5} md={4} lg={3} item classes={{ root: classes.section }}>
          <Box paddingX={2} paddingY={2}>
            <Typography variant="h6" gutterBottom>
              {translate(nameof.full<I18nCourseManagementNs>((n) => n.courses))}
            </Typography>
            <Divider />
          </Box>
          {isSuperAdmin && (
            <Box paddingX={2} marginBottom={2}>
              {companiesLoading && <Skeleton width={100} height={36} />}
              {displayCompanyField && (
                <TextField
                  select
                  fullWidth
                  label={translate(nameof.full<I18nCourseManagementNs>((n) => n.courseForm.organization))}
                  value={selectedCompanyId}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSelectedCompanyIdAndResetSelectedCourse((event.target.value as unknown) as number)
                  }
                >
                  {companies.map((company: CompanyDto) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
          )}
          <Box overflow={'auto'} paddingX={2}>
            <Box display={'flex'}>
              <SkeletonWrapper loadingStatus={loadingStatus} count={1}>
                <Button startIcon={<Add />} onClick={openCreateDialog}>
                  {translate(nameof.full<I18nCourseManagementNs>((n) => n.create))}
                </Button>
              </SkeletonWrapper>
            </Box>
            <SkeletonWrapper loadingStatus={loadingStatus}>
              <CourseList />
            </SkeletonWrapper>
          </Box>
        </Grid>
        <Grid xs={7} md={8} lg={9} item container classes={{ root: classes.section }}>
          <Route path={CourseManagementRoutes.Course}>
            <CourseContainer setSelectedCompanyId={setSelectedCompanyId} />
          </Route>
          <Route exact path={CourseManagementRoutes.Root}>
            <NoCourseData loadingStatus={loadingStatus} isScenariosLoading={false} />
          </Route>
        </Grid>
      </Grid>
      <CreateCourseDialog
        isOpen={isCreateDialogOpen}
        onClose={closeCreateDialog}
        initialCompanyId={selectedCompanyId}
        setSelectedCompanyId={setSelectedCompanyId}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  section: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default CourseManagement;
