import { handleActions } from 'redux-actions';

import { IntentActions } from '../../../actions/scenarios/intentAction.js';

import type { IIntentClipboardState } from './IIntentClipboardState.js';

const initialState: IIntentClipboardState = {
  intentClipboard: undefined,
  clipboardTargetId: undefined
};

export const intentClipboardReducer = handleActions<IIntentClipboardState>(
  {
    [IntentActions.Type.SET_INTENT_CLIPBOARD]: (state, action) => ({ ...action.payload })
  },
  initialState
);
