import { Box, Button, DialogContentText, Grid, IconButton, Tooltip } from '@material-ui/core';
import type { GridColumns } from '@material-ui/data-grid';
import { DateFormat } from 'app/app.constants.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import React, { useMemo, useState } from 'react';
import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import type { ScheduledCourseDto, ScheduledCourseNotificationDto } from '@/generated-api/index.js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CourseClient } from 'app/apis/api.js';
import { getCourseNotificationsQuery } from 'app/queries/courseQueries.js';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';
import type { LoadingStatus } from 'app/store/types.js';

import CreateEditScheduledCourseNotificationDialog from './CreateEditScheduledCourseNotificationDialog.js';
import { Add, CheckCircle, Delete, Edit, PlayCircleFilled } from '@material-ui/icons';
import { green } from '@material-ui/core/colors/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type ScheduledCourseNotificationsProps = {
  scheduledCourse: ScheduledCourseDto;
};

const RowsPerPage = 25;

const ScheduledCourseNotifications: React.FC<ScheduledCourseNotificationsProps> = (props) => {
  const { scheduledCourse } = props;

  const [actionNotification, setActionNotification] = useState<ScheduledCourseNotificationDto>(undefined);
  const [isCreateEditDialogOpen, openCreateEditDialog, closeCreateEditDialog] = useBoolState(false);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBoolState(false);
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);


  // TODO Data should be fetched with the course, but then we have to fix redux as well, so using this as a "hacky way for now"
  // Also for server state something like react-query is better imo..

  const formatDate = useFormatDate();

  const notificationsQuery = useMemo(() => getCourseNotificationsQuery(scheduledCourse.id), [scheduledCourse.id]);
  const queryClient = useQueryClient();
  const { data: notificationQueryData } = useQuery(notificationsQuery);
  const notifications = notificationQueryData ?? [];

  const deleteNotification = useMutation({
    mutationFn: async (notificationId: number) => {
      await CourseClient.courseDeleteScheduledCourseNotification(scheduledCourse.id, notificationId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(notificationsQuery.queryKey);
      closeDeleteDialog();
    }
  });

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'date',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.notifications.date)),
        flex: 1,
        renderCell: (data) => formatDate(data.value as string, DateFormat.TimeWithoutSeconds)
      },
      {
        field: 'customText',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.notifications.customText)),
        flex: 2
      },
      {
        field: '',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.notifications.status)),
        renderCell: (data) => {
          const notification = data.row as ScheduledCourseNotificationDto;
          const isNotificationDone = new Date(notification.date) < new Date();
          return isNotificationDone ? (
            <Tooltip title={translate(nameof.full<I18nCourseManagementNs>((n) => n.notifications.done))}>
              <CheckCircle style={{ color: green[500] }} />
            </Tooltip>
          ) : (
            <Tooltip title={translate(nameof.full<I18nCourseManagementNs>((n) => n.notifications.pending))}>
              <PlayCircleFilled color="action" />
            </Tooltip>
          );
        },
        width: 80,
        sortable: false,
        filterable: false,
        disableColumnMenu: true
      },
      {
        width: 80,
        field: 'actions',
        headerName: ' ',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (data) => {
          const notification = data.row as ScheduledCourseNotificationDto;

          const isNotificationDone = new Date(notification.date) < new Date();
          return (
            <>
              <Tooltip
                title={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.edit))}
                placement="bottom"
              >
                <IconButton
                  disabled={isNotificationDone}
                  size="small"
                  onClick={() => {
                    setActionNotification(notification);
                    openCreateEditDialog();
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
                placement="bottom"
              >
                <IconButton
                  size="small"
                  onClick={() => {
                    setActionNotification(notification);
                    openDeleteDialog();
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </>
          );
        }
      }
    ],
    [formatDate, openCreateEditDialog, openDeleteDialog]
  );

  if (!scheduledCourse) return null;

  return (
    <>
      <SimpleDataGrid
        autoHeight
        loading={false}
        columns={columns}
        pageSize={RowsPerPage}
        hideFooterSelectedRowCount
        rows={notifications}
        isCellEditable={() => false}
        isRowSelectable={() => false}
        disableSelectionOnClick
        rowsPerPageOptions={[RowsPerPage]}
        components={{
          Toolbar: () => (
            <Box padding={1}>
              <Grid container justifyContent="flex-start" wrap="nowrap">
                <Button startIcon={<Add />} variant="outlined" onClick={openCreateEditDialog}>
                  {translate(nameof.full<I18nCourseManagementNs>((n) => n.notifications.addNotification))}
                </Button>
              </Grid>
            </Box>
          )
        }}
      />
      <CreateEditScheduledCourseNotificationDialog
        isOpen={isCreateEditDialogOpen}
        onClose={closeCreateEditDialog}
        onExited={() => setActionNotification(null)}
        notification={actionNotification}
        queryKey={notificationsQuery.queryKey}
        scheduledCourse={scheduledCourse}
      />
      <DeleteConfirmationDialog
        onClose={closeDeleteDialog}
        title={translate(nameof.full<I18nCourseManagementNs>((n) => n.notifications.removeNotification))}
        confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.remove))}
        loadingStatus={deleteNotification.status as LoadingStatus}
        // TODO notification id
        onConfirm={async () => deleteNotification.mutateAsync(actionNotification.id)}
        open={isDeleteDialogOpen}
        DialogProps={{
          open: isDeleteDialogOpen,
          TransitionProps: {
            onExited: () => setActionNotification(null)
          }
        }}
      >
        <DialogContentText>
          {translate(nameof.full<I18nCourseManagementNs>((n) => n.notifications.confirmationText))}
        </DialogContentText>
      </DeleteConfirmationDialog>
    </>
  );
};

export default ScheduledCourseNotifications;
