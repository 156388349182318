import { makeStyles } from '@material-ui/core/styles/index.js';

const useStyles = makeStyles((theme) => {
  return {
    card: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      padding: theme.spacing(4, 0, 0, 4),
      maxWidth: '100%'
    },

    cardEmpty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '1 0 100%'
    },

    cardContentRoot: {
      overflowY: 'auto',
      overflowX: 'hidden'
    },

    summary: {
      display: 'grid',
      gridTemplateColumns: '40% minmax(auto, 60%)',
      gridGap: theme.spacing(1, 2),
      overflow: 'hidden'
    },

    summaryItem: {
      padding: theme.spacing(0.5, 0)
    },

    editable: {
      marginLeft: theme.spacing(-1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer'
      }
    }
  };
});

export default useStyles;
