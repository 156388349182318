import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Slider,
  Tooltip,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Link,
  Divider
} from '@material-ui/core';
import useAudioStore from 'app/zustand/audioStore.js';
import DetectRTC from 'detectrtc';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AudioVisualizer from './AudioVisualizer.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type AudioSettingsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const useStyles = makeStyles(() => ({
  container: {
    // width: 300
  },
  tooltip: {
    fontSize: 12
  }
}));

const AudioSettingsDialog = (props: AudioSettingsDialogProps) => {
  const { open, onClose } = props;
  const classes = useStyles();
  const audioStore = useAudioStore();
  const [threshold, setThreshold] = useState(audioStore.threshold);
  const [interval, setInterval] = useState(audioStore.interval);
  const [timeout, setTimeout] = useState(audioStore.timeout);
  const [disableTimeout, setDisableTimeout] = useState(audioStore.disableTimeout);
  const [microphones, setMicrophones] = useState<typeof DetectRTC.audioInputDevices>([]);
  const [selectedMicrophone, setSelectedMicrophone] = useState<string>(audioStore.microphoneDeviceId);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [os, setOs] = useState<{ name: string; version: string } | undefined>();
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const onLocalClose = () => {
    console.log('onclose!');
    if (stream) {
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);
    }
    onClose();
  };

  const onSave = () => {
    audioStore.setThreshold(threshold);
    audioStore.setInterval(interval);
    audioStore.setTimeout(timeout);
    audioStore.setDisableTimeout(disableTimeout);
    onLocalClose();
  };

  const onReset = () => {
    audioStore.reset();
    onLocalClose();
  };

  const createAudioStream = async (deviceId: string) => {
    const mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        deviceId: deviceId || undefined,
        autoGainControl: true,
        noiseSuppression: true,
        echoCancellation: true
      }
    });
    setStream((old) => {
      if (old) {
        old.getTracks().forEach((track) => {
          track.stop();
        });
      }
      return mediaStream;
    });
  };

  const fetchMicrophones = useCallback(async (deviceId: string) => {
    await createAudioStream(deviceId);

    DetectRTC.load(async () => {
      const microphones = DetectRTC.audioInputDevices;
      setOs({ name: DetectRTC.osName, version: DetectRTC.osVersion });
      setMicrophones(microphones);
      setSelectedMicrophone(microphones.find((m) => m.deviceId === 'default').deviceId ?? 'default');
    });
  }, []);

  useEffect(() => {
    if (open) {
      fetchMicrophones(audioStore.microphoneDeviceId);
      setThreshold(audioStore.threshold);
      setInterval(audioStore.interval);
      setTimeout(audioStore.timeout);
      setDisableTimeout(audioStore.disableTimeout);
    }
  }, [audioStore, fetchMicrophones, open]);

  const onMicrophoneChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const deviceId = e.target.value as string;
    setSelectedMicrophone(deviceId);
    createAudioStream(deviceId);
  };

  const errorUrl = useMemo(() => {
    if (os && os.name) {
      switch (true) {
        case os.name.toLowerCase().includes('windows'):
          return 'https://support.microsoft.com/en-us/windows/how-to-set-up-and-test-microphones-in-windows-ba9a4aab-35d1-12ee-5835-cccac7ee87a4';
        case os.name.toLowerCase().includes('mac'):
          return 'https://support.apple.com/guide/mac-help/change-the-sound-input-settings-mchlp2567/mac';
        case os.name.toLowerCase().includes('chrome'):
          return 'https://support.google.com/chromebook/answer/10045949';
        default:
          return undefined;
      }
    }
    return undefined;
  }, [os]);

  return (
    <Dialog open={open} onClose={onLocalClose}>
      <DialogContent>
        <Grid container direction="column" className={classes.container} spacing={1}>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.testMicrophone))}
            </Typography>
            <FormControl>
              <InputLabel id="microphone-label">
                {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.microphone))}
              </InputLabel>
              <Select
                value={selectedMicrophone}
                onChange={onMicrophoneChange}
                displayEmpty
                style={{ minWidth: 400 }}
                labelId="microphone-label"
                id="microphone"
                input={<Input />}
              >
                {microphones.length === 0 ? (
                  <MenuItem key="empty" value="">
                    {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.noMicrophoneFound))}
                  </MenuItem>
                ) : null}
                {microphones.map((microphone) => (
                  <MenuItem key={microphone.deviceId} value={microphone.deviceId}>
                    {microphone.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Typography>{translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.inputLevel))}:</Typography>
            <AudioVisualizer threshold={threshold} stream={stream} />
            {selectedMicrophone !== 'default' ? (
              <Typography color="error">
                {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.onlyDefaultSupported))}{' '}
                {errorUrl ? (
                  <Link rel="noopener noreferrer" target="_blank" href={errorUrl}>
                    {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.systemSettings))}
                  </Link>
                ) : (
                  translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.systemSettings))
                )}
                {'.'}
              </Typography>
            ) : null}
          </Grid>
          <Divider />
          <Grid item style={{ width: '80%' }}>
            <Typography variant="h6" gutterBottom>
              {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.audioSettings))}
            </Typography>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement="top"
              title={translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.thresholdTooltip))}
            >
              <Typography gutterBottom>
                {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.audioThreshold))} (dB):
              </Typography>
            </Tooltip>
            <Slider
              marks
              valueLabelDisplay="auto"
              value={threshold}
              min={-100}
              max={0}
              step={5}
              onChange={(_, newValue) => setThreshold(newValue as number)}
            />
          </Grid>
          <Grid item>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              placement="top"
              title={translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.intervalTooltip))}
            >
              <Typography gutterBottom>
                {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.audioInterval))} (ms):
              </Typography>
            </Tooltip>
            <Slider
              marks
              valueLabelDisplay="auto"
              value={interval}
              min={50}
              max={300}
              step={10}
              onChange={(_, newValue) => setInterval(newValue as number)}
            />
          </Grid>
          <Grid item>
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.tooltip }}
              title={translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.timeoutTooltip))}
            >
              <Typography gutterBottom>
                {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.audioTimeout))} (ms):
              </Typography>
            </Tooltip>
            <Slider
              marks
              valueLabelDisplay="auto"
              value={timeout}
              min={1000}
              max={20000}
              step={1000}
              onChange={(_, newValue) => setTimeout(newValue as number)}
            />
          </Grid>
          <Grid item>
            <Tooltip
              placement="top"
              classes={{ tooltip: classes.tooltip }}
              title={translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.disableTooltip))}
            >
              <Typography gutterBottom>
                {translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.disableTimeout))}:
              </Typography>
            </Tooltip>
            <FormControlLabel
              control={<Checkbox checked={disableTimeout} onChange={() => setDisableTimeout(!disableTimeout)} />}
              label={translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.disable))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onReset} color="secondary">
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.reset))}
        </Button>
        <Button onClick={onLocalClose}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <Button variant="contained" onClick={onSave}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.save))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AudioSettingsDialog;
