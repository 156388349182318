export enum ActiveLinkStatus {
  Correct,
  Incorrect,
  Default
}

export enum LinkColor {
  Correct = 'green',
  Incorrect = 'red',
  Default = 'cornflowerblue'
}
