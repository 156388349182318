import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LngDetector from 'i18next-browser-languagedetector';
import LanguageDetector from 'i18next-browser-languagedetector';

import { I18nLanguage, getI18nLanguageList } from './types/i18nLanguage.js';
import { I18nNamespace } from './types/i18nNamespace.js';

import {
  UserManagementDictionaryEn,
  UserManagementDictionaryDe,
  UserManagementDictionaryNo,
  UserManagementDictionarySw,
  UserManagementDictionaryNl,
  UserManagementDictionaryIs,
  UserManagementDictionaryUk
} from './dictionaries/userManagement.js';

import {
  CommonDictionaryEn,
  CommonDictionaryDe,
  CommonDictionaryNo,
  CommonDictionarySw,
  CommonDictionaryNl,
  CommonDictionaryIs,
  CommonDictionaryUk
} from './dictionaries/common.js';

import {
  CoreDictionaryEn,
  CoreDictionaryDe,
  CoreDictionaryNo,
  CoreDictionarySw,
  CoreDictionaryNl,
  CoreDictionaryIs,
  CoreDictionaryUk
} from './dictionaries/core.js';

import {
  ValidationDictionaryEn,
  ValidationDictionaryDe,
  ValidationDictionaryNo,
  ValidationDictionarySw,
  ValidationDictionaryNl,
  ValidationDictionaryIs,
  ValidationDictionaryUk
} from './dictionaries/validation.js';

import {
  CourseManagementDictionaryDe,
  CourseManagementDictionaryEn,
  CourseManagementDictionaryIs,
  CourseManagementDictionaryNl,
  CourseManagementDictionaryNo,
  CourseManagementDictionarySw,
  CourseManagementDictionaryUk
} from '@/i18n/dictionaries/courseManagement.js';

import {
  ScenarioSectionDictionaryDe,
  ScenarioSectionDictionaryEn,
  ScenarioSectionDictionaryIs,
  ScenarioSectionDictionaryNl,
  ScenarioSectionDictionaryNo,
  ScenarioSectionDictionarySw,
  ScenarioSectionDictionaryUk
} from '@/i18n/dictionaries/scenarioSection.js';

import {
  GroupManagementDictionaryDe,
  GroupManagementDictionaryEn,
  GroupManagementDictionaryIs,
  GroupManagementDictionaryNl,
  GroupManagementDictionaryNo,
  GroupManagementDictionarySw,
  GroupManagementDictionaryUk
} from '@/i18n/dictionaries/groupManagement.js';

import {
  ScheduledCallsDictionaryDe,
  ScheduledCallsDictionaryEn,
  ScheduledCallsDictionaryIs,
  ScheduledCallsDictionaryNl,
  ScheduledCallsDictionaryNo,
  ScheduledCallsDictionarySw,
  ScheduledCallsDictionaryUk
} from '@/i18n/dictionaries/scheduledCalls.js';

import {
  ProtocolReferencesDictionaryDe,
  ProtocolReferencesDictionaryEn,
  ProtocolReferencesDictionaryIs,
  ProtocolReferencesDictionaryNl,
  ProtocolReferencesDictionaryNo,
  ProtocolReferencesDictionarySw,
  ProtocolReferencesDictionaryUk
} from '@/i18n/dictionaries/protocolReferences.js';

import {
  GlobalAssetsDictionaryDe,
  GlobalAssetsDictionaryEn,
  GlobalAssetsDictionaryIs,
  GlobalAssetsDictionaryNl,
  GlobalAssetsDictionaryNo,
  GlobalAssetsDictionarySw,
  GlobalAssetsDictionaryUk
} from '@/i18n/dictionaries/globalAssets.js';

import {
  DashboardDictionaryDe,
  DashboardDictionaryEn,
  DashboardDictionaryIs,
  DashboardDictionaryNl,
  DashboardDictionaryNo,
  DashboardDictionarySw,
  DashboardDictionaryUk
} from '@/i18n/dictionaries/dashboard.js';

import {
  ScenarioEditorDictionaryDe,
  ScenarioEditorDictionaryEn,
  ScenarioEditorDictionaryIs,
  ScenarioEditorDictionaryNl,
  ScenarioEditorDictionaryNo,
  ScenarioEditorDictionarySw,
  ScenarioEditorDictionaryUk
} from '@/i18n/dictionaries/scenarioEditor.js';

// Fancy stuff like language auto-detection, additional formatters and backend loading is all added here
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: I18nLanguage.English,
    whitelist: getI18nLanguageList(),

    detection: {
      order: ['localStorage', 'navigator'], // Use browser language if user has not actively selected language
      caches: ['localStorage'],
      lookupLocalStorage: 'userLocalization', // custom lookup key
      checkWhitelist: true // only detect languages that are in the whitelist
    },

    resources: {
      [I18nLanguage.English]: {
        [I18nNamespace.Common]: CommonDictionaryEn,
        [I18nNamespace.Core]: CoreDictionaryEn,
        [I18nNamespace.UserManagement]: UserManagementDictionaryEn,
        [I18nNamespace.Validation]: ValidationDictionaryEn,
        [I18nNamespace.CourseManagement]: CourseManagementDictionaryEn,
        [I18nNamespace.ScenarioSection]: ScenarioSectionDictionaryEn,
        [I18nNamespace.GroupManagement]: GroupManagementDictionaryEn,
        [I18nNamespace.ScheduledCalls]: ScheduledCallsDictionaryEn,
        [I18nNamespace.ProtocolReferences]: ProtocolReferencesDictionaryEn,
        [I18nNamespace.GlobalAssets]: GlobalAssetsDictionaryEn,
        [I18nNamespace.Dashboard]: DashboardDictionaryEn,
        [I18nNamespace.ScenarioEditor]: ScenarioEditorDictionaryEn
      },
      [I18nLanguage.German]: {
        [I18nNamespace.Common]: CommonDictionaryDe,
        [I18nNamespace.Core]: CoreDictionaryDe,
        [I18nNamespace.UserManagement]: UserManagementDictionaryDe,
        [I18nNamespace.Validation]: ValidationDictionaryDe,
        [I18nNamespace.CourseManagement]: CourseManagementDictionaryDe,
        [I18nNamespace.ScenarioSection]: ScenarioSectionDictionaryDe,
        [I18nNamespace.GroupManagement]: GroupManagementDictionaryDe,
        [I18nNamespace.ScheduledCalls]: ScheduledCallsDictionaryDe,
        [I18nNamespace.ProtocolReferences]: ProtocolReferencesDictionaryDe,
        [I18nNamespace.GlobalAssets]: GlobalAssetsDictionaryDe,
        [I18nNamespace.Dashboard]: DashboardDictionaryDe,
        [I18nNamespace.ScenarioEditor]: ScenarioEditorDictionaryDe
      },
      [I18nLanguage.Norwegian]: {
        [I18nNamespace.Common]: CommonDictionaryNo,
        [I18nNamespace.Core]: CoreDictionaryNo,
        [I18nNamespace.UserManagement]: UserManagementDictionaryNo,
        [I18nNamespace.Validation]: ValidationDictionaryNo,
        [I18nNamespace.CourseManagement]: CourseManagementDictionaryNo,
        [I18nNamespace.ScenarioSection]: ScenarioSectionDictionaryNo,
        [I18nNamespace.GroupManagement]: GroupManagementDictionaryNo,
        [I18nNamespace.ScheduledCalls]: ScheduledCallsDictionaryNo,
        [I18nNamespace.ProtocolReferences]: ProtocolReferencesDictionaryNo,
        [I18nNamespace.GlobalAssets]: GlobalAssetsDictionaryNo,
        [I18nNamespace.Dashboard]: DashboardDictionaryNo,
        [I18nNamespace.ScenarioEditor]: ScenarioEditorDictionaryNo
      },
      [I18nLanguage.Swedish]: {
        [I18nNamespace.Common]: CommonDictionarySw,
        [I18nNamespace.Core]: CoreDictionarySw,
        [I18nNamespace.UserManagement]: UserManagementDictionarySw,
        [I18nNamespace.Validation]: ValidationDictionarySw,
        [I18nNamespace.CourseManagement]: CourseManagementDictionarySw,
        [I18nNamespace.ScenarioSection]: ScenarioSectionDictionarySw,
        [I18nNamespace.GroupManagement]: GroupManagementDictionarySw,
        [I18nNamespace.ScheduledCalls]: ScheduledCallsDictionarySw,
        [I18nNamespace.ProtocolReferences]: ProtocolReferencesDictionarySw,
        [I18nNamespace.GlobalAssets]: GlobalAssetsDictionarySw,
        [I18nNamespace.Dashboard]: DashboardDictionarySw,
        [I18nNamespace.ScenarioEditor]: ScenarioEditorDictionarySw
      },
      [I18nLanguage.Dutch]: {
        [I18nNamespace.Common]: CommonDictionaryNl,
        [I18nNamespace.Core]: CoreDictionaryNl,
        [I18nNamespace.UserManagement]: UserManagementDictionaryNl,
        [I18nNamespace.Validation]: ValidationDictionaryNl,
        [I18nNamespace.CourseManagement]: CourseManagementDictionaryNl,
        [I18nNamespace.ScenarioSection]: ScenarioSectionDictionaryNl,
        [I18nNamespace.GroupManagement]: GroupManagementDictionaryNl,
        [I18nNamespace.ScheduledCalls]: ScheduledCallsDictionaryNl,
        [I18nNamespace.ProtocolReferences]: ProtocolReferencesDictionaryNl,
        [I18nNamespace.GlobalAssets]: GlobalAssetsDictionaryNl,
        [I18nNamespace.Dashboard]: DashboardDictionaryNl,
        [I18nNamespace.ScenarioEditor]: ScenarioEditorDictionaryNl
      },
      [I18nLanguage.Icelandic]: {
        [I18nNamespace.Common]: CommonDictionaryIs,
        [I18nNamespace.Core]: CoreDictionaryIs,
        [I18nNamespace.UserManagement]: UserManagementDictionaryIs,
        [I18nNamespace.Validation]: ValidationDictionaryIs,
        [I18nNamespace.CourseManagement]: CourseManagementDictionaryIs,
        [I18nNamespace.ScenarioSection]: ScenarioSectionDictionaryIs,
        [I18nNamespace.GroupManagement]: GroupManagementDictionaryIs,
        [I18nNamespace.ScheduledCalls]: ScheduledCallsDictionaryIs,
        [I18nNamespace.ProtocolReferences]: ProtocolReferencesDictionaryIs,
        [I18nNamespace.GlobalAssets]: GlobalAssetsDictionaryIs,
        [I18nNamespace.Dashboard]: DashboardDictionaryIs,
        [I18nNamespace.ScenarioEditor]: ScenarioEditorDictionaryIs
      },
      [I18nLanguage.EnglishUk]: {
        [I18nNamespace.Common]: CommonDictionaryUk,
        [I18nNamespace.Core]: CoreDictionaryUk,
        [I18nNamespace.UserManagement]: UserManagementDictionaryUk,
        [I18nNamespace.Validation]: ValidationDictionaryUk,
        [I18nNamespace.CourseManagement]: CourseManagementDictionaryUk,
        [I18nNamespace.ScenarioSection]: ScenarioSectionDictionaryUk,
        [I18nNamespace.GroupManagement]: GroupManagementDictionaryUk,
        [I18nNamespace.ScheduledCalls]: ScheduledCallsDictionaryUk,
        [I18nNamespace.ProtocolReferences]: ProtocolReferencesDictionaryUk,
        [I18nNamespace.GlobalAssets]: GlobalAssetsDictionaryUk,
        [I18nNamespace.Dashboard]: DashboardDictionaryUk,
        [I18nNamespace.ScenarioEditor]: ScenarioEditorDictionaryUk
      }
    }
  });

export default i18n;
