import { StatusCodes } from 'http-status-codes';
import type { AxiosError, AxiosResponse } from 'axios';
import { call, put, fork, takeEvery } from 'redux-saga/effects';

import history from '@/core/customHistory.js';
import AppRoutes from 'app/utils/routes.js';
import type { UserDto } from '@/generated-api/index.js';
import { LoadingStatus } from 'app/store/types.js';
import { AccountClient, UserClient } from 'app/apis/api.js';
import { UserActions } from 'app/actions/user/userAction.js';
import logger from 'app/utils/logger.js';

function* getCurrentUser() {
  yield put(UserActions.setCurrentUserLoadingStatus(LoadingStatus.Loading));

  try {
    const response: AxiosResponse<UserDto> = yield call([UserClient, UserClient.userGetCurrentUser]);

    if (!response?.status) {
      return yield put(UserActions.setCurrentUserLoadingStatus(LoadingStatus.Failed));
    }

    switch (response.status) {
      case StatusCodes.OK: {
        yield put(UserActions.setCurrentUser(response.data));
        yield put(UserActions.setCurrentUserLoadingStatus(LoadingStatus.Succeeded));
        break;
      }

      default:
        yield put(UserActions.setCurrentUserLoadingStatus(LoadingStatus.Idle));
        break;
    }
  } catch (error) {
    yield put(UserActions.setCurrentUserLoadingStatus(LoadingStatus.Failed));

    const axiosError = error as AxiosError;
    console.log(axiosError);

    if (axiosError?.response?.status === StatusCodes.NOT_FOUND) {
      yield put(UserActions.setCurrentUserNotFound());
    }
  }
}

function* acceptPermissionForRecords() {
  const response: AxiosResponse<UserDto> = yield call([AccountClient, AccountClient.accountAllowRecord]);

  if (response.status == StatusCodes.OK) {
    yield put(UserActions.setCurrentUser(response.data));
    history.push(AppRoutes.Dashboard);
  }
}

function* createUserInvitation(action) {
  const { data, onError, onSuccess } = action.payload;

  try {
    yield call([UserClient, UserClient.userCreateOrganizationInvitation], data);

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      onError();
    }
    logger.log(error);
  }
}

function* watchCreateUserInvitation() {
  yield takeEvery(UserActions.Type.CREATE_USER_INVITATION, createUserInvitation);
}

function* watchGetCurrentUser() {
  yield takeEvery(UserActions.Type.GET_CURRENT_USER, getCurrentUser);
}

function* watchUserAcceptPermissionForRecords() {
  yield takeEvery(UserActions.Type.ACCEPT_PERMISSION_FOR_RECORD, acceptPermissionForRecords);
}

export default function* watchUserActionsSaga() {
  yield fork(watchGetCurrentUser);
  yield fork(watchCreateUserInvitation);
  yield fork(watchUserAcceptPermissionForRecords);
}
