export enum QualityLevel {
  Unset = 0,
  Bad = 1,
  Acceptable = 2,
  Good = 3
}

export const QualityLevelColor = {
  [QualityLevel.Unset]: '#808080',
  [QualityLevel.Good]: '#4CAF50',
  [QualityLevel.Acceptable]: '#FFEB3B',
  [QualityLevel.Bad]: '#F44336'
} as const;

export type QualityLevelColorType = typeof QualityLevelColor;
