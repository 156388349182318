import React, { useCallback, useEffect, useState } from 'react';
import type { Location } from 'history';
import { Prompt } from 'react-router-dom';
import type { PromptProps } from 'react-router';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  render: (props: { visible: boolean; confirm: () => void; cancel: () => void }) => JSX.Element;
}

// https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation, render }: Props): JSX.Element => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleBlockedNavigation: PromptProps['message'] = (nextLocation, action): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);

      // https://github.com/ReactTraining/react-router/issues/5405#issuecomment-706680703
      if (action === 'POP') {
        window.history.forward();
      }
      return false;
    }

    return true;
  };

  useEffect(() => {
    window.onbeforeunload = when ? () => true : null;
    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);

  const onConfirm = useCallback(() => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setConfirmedNavigation(false);
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      {render({ visible: modalVisible, confirm: onConfirm, cancel: closeModal })}
    </>
  );
};
export default RouteLeavingGuard;
