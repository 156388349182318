import type { FC } from 'react';
import React from 'react';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import type IntentNodeProperties from 'app/models/intents/intentNodeProperties.js';
import type { INode } from '@mrblenny/react-flow-chart';
import { IntentEditingActions } from 'app/actions/scenarios/intentEditingAction.js';
import { Box, TextField } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const IntentNameSettings: FC<{
  disabled: boolean;
  intent: INode<IntentNodeProperties, undefined>;
  placeholder?: string;
  error?: string;
  name: string;
  control: Control<IntentNodeProperties>;
}> = ({ name, error, placeholder, intent, disabled, control }) => {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Box pb={2}>
      <Controller
        render={({ onChange, ref, ...rest }) => (
          <TextField
            disabled={disabled}
            placeholder={placeholder}
            fullWidth
            label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.intentNameSettings.intentName))}
            onChange={(e) => {
              dispatch(IntentEditingActions.changeIntentName(e.target.value));
              onChange(e.target.value);
            }}
            error={!!error}
            inputRef={ref}
            {...rest}
          />
        )}
        defaultValue={intent.properties?.name}
        name={name}
        control={control}
        fullWidth
      />
    </Box>
  );
};

export default IntentNameSettings;
