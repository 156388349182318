import { useSelector } from 'react-redux';
import { companiesSelector } from 'app/selectors/companiesSelector.js';
import { users as usersSelector } from 'app/selectors/userManagementSelectors.js';
import { useMemo } from 'react';
import type { CompanyDto } from '@/generated-api/index.js';
import type UserManaged from 'app/models/userManagement/userManaged.js';

export type UsersCompany = {
  users: UserManaged[];
  company?: CompanyDto;
};

function useUsersAndCompanyGroupedByCompanyId() {
  const companies = useSelector(companiesSelector);
  const users = useSelector(usersSelector);

  return useMemo(() => {
    const map = users.reduce<Record<CompanyDto['id'], UsersCompany>>((map, user) => {
      if (map[user.companyId]) {
        map[user.companyId].users.push(user);
      } else {
        map[user.companyId] = {
          users: [user]
        };
      }

      return map;
    }, {});

    Object.keys(map).forEach((key) => {
      map[key].company = companies.find((c) => `${c.id}` === key);
    });

    return map;
  }, [companies, users]);
}

export default useUsersAndCompanyGroupedByCompanyId;
