import type { I18nCoreNs } from './interfaces.js';

export const CoreDictionaryEn: I18nCoreNs = {
  languagePicker: {
    selectLanguage: 'Select language',
    english: 'English',
    englishus: 'English (US)',
    german: 'German',
    swedish: 'Swedish',
    norwegian: 'Norwegian',
    dutch: 'Dutch',
    danish: 'Danish',
    icelandic: 'Icelandic',
    englishuk: 'English (UK)',
    englishAustralia: 'English (Australia)',
    spanishSpain: 'Spanish (Spain)',
    spanishMexico: 'Spanish (Mexico)',
    spanishUs: 'Spanish (US)',
    arabicUae: 'Arabic (UAE)'
  },
  login: {
    header: 'Sign in',
    form: {
      username: 'Username',
      loginButton: 'Login'
    }
  },
  logout: {
    form: {
      logoutButton: 'SIGN OFF'
    }
  }
};

export const CoreDictionaryDe: I18nCoreNs = {
  languagePicker: {
    selectLanguage: 'Sprache auswählen',
    english: 'Englisch',
    englishus: 'Englisch (US)',
    german: 'Deutsch',
    swedish: 'Schwedisch',
    norwegian: 'Norwegisch',
    dutch: 'Niederländisch',
    danish: 'Dänisch',
    icelandic: 'Isländisch',
    englishuk: 'Englisch (UK)',
    englishAustralia: 'Englisch (Australien)',
    spanishSpain: 'Spanisch (Spanien)',
    spanishMexico: 'Spanisch (Mexiko)',
    spanishUs: 'Spanisch (US)',
    arabicUae: 'Arabisch (UAE)'
  },
  login: {
    header: 'Anmelden',
    form: {
      username: 'Benutzername',
      loginButton: 'Anmelden'
    }
  },
  logout: {
    form: {
      logoutButton: 'ABMELDEN'
    }
  }
};

export const CoreDictionarySw: I18nCoreNs = {
  languagePicker: {
    selectLanguage: 'Välj språk',
    english: 'Engelska',
    englishus: 'Engelska (USA)',
    german: 'Tyska',
    swedish: 'Svensk',
    norwegian: 'Norska',
    dutch: 'Holländska',
    danish: 'Danska',
    icelandic: 'Isländska',
    englishuk: 'Engelska (UK)',
    englishAustralia: 'Engelska (Australien)',
    spanishSpain: 'Spanish (Spain)',
    spanishMexico: 'Spanish (Mexico)',
    spanishUs: 'Spanish (US)',
    arabicUae: 'Arabic (UAE)'
  },
  login: {
    header: 'Sign in',
    form: {
      username: 'Username',
      loginButton: 'Login'
    }
  },
  logout: {
    form: {
      logoutButton: 'SIGN OFF'
    }
  }
};

export const CoreDictionaryNo: I18nCoreNs = {
  languagePicker: {
    selectLanguage: 'Velg språk',
    english: 'Engelsk',
    englishus: 'Engelsk (USA)',
    german: 'Tysk',
    swedish: 'Svensk',
    norwegian: 'Norsk',
    dutch: 'Nederlandsk',
    danish: 'Dansk',
    icelandic: 'Islandsk',
    englishuk: 'Engelsk (Storbritannia)',
    englishAustralia: 'Engelsk (Australia)',
    spanishSpain: 'Spansk (Spania)',
    spanishMexico: 'Spansk (Mexico)',
    spanishUs: 'Spansk (USA)',
    arabicUae: 'Arabisk (UAE)'
  },
  login: {
    header: 'Logg inn',
    form: {
      username: 'Brukernavn',
      loginButton: 'Logg inn'
    }
  },
  logout: {
    form: {
      logoutButton: 'LOGG UT'
    }
  }
};

export const CoreDictionaryNl: I18nCoreNs = {
  languagePicker: {
    selectLanguage: 'Selecteer taal',
    english: 'English',
    englishus: 'English (US)',
    german: 'German',
    swedish: 'Swedish',
    norwegian: 'Norwegian',
    dutch: 'Dutch',
    danish: 'Deens',
    icelandic: 'Islandisch',
    englishuk: 'English (UK)',
    englishAustralia: 'English (Australia)',
    spanishSpain: 'Spanish (Spain)',
    spanishMexico: 'Spanish (Mexico)',
    spanishUs: 'Spanish (US)',
    arabicUae: 'Arabic (UAE)'
  },
  login: {
    header: 'Sign in',
    form: {
      username: 'Username',
      loginButton: 'Login'
    }
  },
  logout: {
    form: {
      logoutButton: 'SIGN OFF'
    }
  }
};

export const CoreDictionaryIs: I18nCoreNs = {
  languagePicker: {
    selectLanguage: 'Veldu tungumál',
    english: 'English',
    englishus: 'English (US)',
    german: 'þýska',
    swedish: 'Sænska',
    norwegian: 'Norska',
    dutch: 'Hollenska',
    danish: 'Danska',
    icelandic: 'Islenska',
    englishuk: 'Enska (UK)',
    englishAustralia: 'Enska (Australia)',
    spanishSpain: 'Spanish (Spain)',
    spanishMexico: 'Spanish (Mexico)',
    spanishUs: 'Spanish (US)',
    arabicUae: 'Arabic (UAE)'
  },
  login: {
    header: 'Sign in',
    form: {
      username: 'Username',
      loginButton: 'Login'
    }
  },
  logout: {
    form: {
      logoutButton: 'SIGN OFF'
    }
  }
};

export const CoreDictionaryUk: I18nCoreNs = {
  languagePicker: {
    selectLanguage: 'Select language',
    english: 'English',
    englishus: 'English (US)',
    german: 'German',
    swedish: 'Swedish',
    norwegian: 'Norwegian',
    dutch: 'Dutch',
    danish: 'Danish',
    icelandic: 'Icelandic',
    englishuk: 'English (UK)',
    englishAustralia: 'English (Australia)',
    spanishSpain: 'Spanish (Spain)',
    spanishMexico: 'Spanish (Mexico)',
    spanishUs: 'Spanish (US)',
    arabicUae: 'Arabic (UAE)'
  },
  login: {
    header: 'Sign in',
    form: {
      username: 'Username',
      loginButton: 'Login'
    }
  },
  logout: {
    form: {
      logoutButton: 'SIGN OFF'
    }
  }
};
