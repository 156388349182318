import React from 'react';

import SaveIcon from '@material-ui/icons/Save.js';
import CancelIcon from '@material-ui/icons/Cancel.js';

import type { CompanyNameTextFieldProps } from './types.js';
import { IconButton, TextField, Tooltip } from '@material-ui/core';

const DepartmentNameTextField: React.FC<CompanyNameTextFieldProps> = ({ companyName, onChange, onDiscard, onSave }) => (
  <TextField
    value={companyName}
    onChange={(event) => onChange(event.target.value)}
    fullWidth
    InputProps={{
      endAdornment: (
        <>
          <Tooltip title="Save">
            <span>
              <IconButton disabled={!companyName} onClick={onSave}>
                <SaveIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Cancel">
            <IconButton onClick={onDiscard}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      )
    }}
  />
);

export default DepartmentNameTextField;
