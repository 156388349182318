import { makeStyles } from '@material-ui/core/styles/index.js';

const useStyles = makeStyles((theme) => {
  return {
    card: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1',
      padding: theme.spacing(2),
      maxWidth: '100%'
    },
    cardEmpty: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '1 0 100%'
    },
    cardContentRoot: {
      overflowY: 'auto',
      overflowX: 'hidden'
    },
    summary: {
      display: 'grid',
      gridTemplateColumns: '40% minmax(auto, 60%)',
      gridGap: theme.spacing(1, 2),
      overflow: 'hidden'
    },
    scenariosContainer: {
      overflow: 'auto',
      paddingRight: theme.spacing(3)
    },
    scenarioItem: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    summaryItem: {
      padding: theme.spacing(0.5, 0)
    },
    editable: {
      marginLeft: theme.spacing(-1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),

      '&:hover': {
        backgroundColor: theme.palette.action.hover,
        cursor: 'pointer'
      }
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.divider}`
    },
    summaryContainer: {
      background: theme.palette.grey[50],
      border: `1px dashed ${theme.palette.divider}`
    },
    link: {
      textDecoration: 'underline'
    }
  };
});

export default useStyles;
