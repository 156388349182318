import React from 'react';
import { InputLabel, Typography } from '@material-ui/core';

const Detail: React.FC<{ label: string }> = ({ label, children }) => (
  <>
    <InputLabel shrink>{label}</InputLabel>
    <Typography variant="body2">{children}</Typography>
  </>
);

export default Detail;
