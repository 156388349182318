import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import initYupLocale from '@/i18n/yupLocale.js';
import { Box, Grid } from '@material-ui/core';

import 'app/styles/style.css';
import { UserSelectors } from 'app/selectors/index.js';
import { LoadingStatus } from 'app/store/types.js';
import Header from 'app/components/Header/Header.js';
import { initializeApiClients } from 'app/apis/api.js';
import AppContainerContent from 'app/AppContainerContent.js';
import DefaultLoadingPage from 'app/components/DefaultLoadingPage.js';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { UserActions } from './actions/user/userAction.js';

const AppContainer: React.FC = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated || !instance) return;

    initYupLocale();
    initializeApiClients(instance);
    dispatch(UserActions.getCurrentUser());
  }, [instance, isAuthenticated, dispatch]);

  const currentUserNotFound = useSelector(UserSelectors.CurrentUserNotFoundSelector);
  const loadingStatus = useSelector(UserSelectors.CurrentUserLoadingStatusSelector);

  if (!isAuthenticated && !currentUserNotFound && loadingStatus === LoadingStatus.Loading) {
    return <DefaultLoadingPage />;
  }

  return (
    <Grid container direction="column" component={Box} height="100vh">
      <Grid item>
        <Header />
      </Grid>
      <Grid item xs>
        <AppContainerContent />
      </Grid>
    </Grid>
  );
};

export default AppContainer;
