import { makeStyles } from '@material-ui/core/styles/index.js';

export default makeStyles((theme) => ({
  root: {
    fontSize: 12
  },
  redText: {
    color: theme.palette.error.main
  }
}));
