import { useHistory } from 'react-router-dom';
import { TextField, Button, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo } from 'react';

import AppRoutes from 'app/utils/routes.js';
import { UserSelectors } from 'app/selectors/index.js';
import { LoadingStatus } from 'app/store/types.js';
import LoadingButton from 'app/components/LoadingButton.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { CourseDto, CreateCourseCommand } from '@/generated-api/index.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import type { I18nCourseManagementNs } from '@/i18n/dictionaries/interfaces.js';
import * as validationSchemas from 'app/components/CourseManagement/CourseCard/validationSchema.js';
import SimpleCompanySelectField from 'app/components/UsersPage/UserInvitation/SimpleCompanySelectField.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

export type CreateCourseDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  initialCompanyId: number;
  setSelectedCompanyId: (id: number) => void;
};

const CreateCourseDialog = ({
  isOpen,
  onClose,
  initialCompanyId,
  setSelectedCompanyId
}: CreateCourseDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.CourseManagement, I18nNamespace.Common]);
  const [translateValidation] = useTranslation(I18nNamespace.Validation);

  const currentUser = useSelector(UserSelectors.CurrentUserSelector);

  const { control, handleSubmit, setValue, errors, reset, formState } = useForm<CreateCourseCommand>({
    mode: 'onChange',
    shouldUnregister: false,
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchemas.createCourse),
    defaultValues: {
      title: '',
      companyId: initialCompanyId ?? currentUser.companyId
    }
  });

  useEffect(() => {
    if (!initialCompanyId) return;

    const name = nameof<CreateCourseCommand>((c) => c.companyId) as keyof CreateCourseCommand;

    setValue(name, initialCompanyId);
  }, [setValue, initialCompanyId]);

  const history = useHistory();

  const onCourseCreated = useCallback(
    (course: CourseDto) => {
      history.push(`${AppRoutes.CourseManagement}/${course.id}`);
      setSelectedCompanyId(course.companyId);
      onClose();
      reset();
    },
    [history, onClose, reset, setSelectedCompanyId]
  );

  const isSuperAdmin = useSelector(isUserSuperAdminSelector);

  const loadingStatus = useSelector(CourseManagementSelectors.createCourseLoadingStatus);

  const onSubmit = useMemo(
    () => handleSubmit((data) => dispatch(CourseManagementActions.createCourse(data, onCourseCreated))),
    [dispatch, handleSubmit, onCourseCreated]
  );

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
      TransitionProps={{
        onExited: () => reset()
      }}
    >
      <DialogTitle>{translate(nameof.full<I18nCourseManagementNs>((n) => n.createCourse))}</DialogTitle>
      <DialogContent>
        <form id="create-course-form" onSubmit={onSubmit}>
          <SimpleCompanySelectField
            required
            control={control}
            disabled={!isSuperAdmin}
            error={!!errors.companyId}
            name={nameof.full<CreateCourseCommand>((c) => c.companyId)}
            label={translate(nameof.full<I18nCourseManagementNs>((n) => n.courseForm.organization))}
          />
          <Controller
            control={control}
            name={nameof.full<CreateCourseCommand>((c) => c.title)}
            render={(props) => (
              <TextField
                margin="normal"
                autoFocus
                required
                fullWidth
                error={!!errors.title}
                label={translate(nameof.full<I18nCourseManagementNs>((n) => n.courseForm.title))}
                helperText={translateValidation(errors.title?.message as string)}
                {...props}
                inputRef={props.ref}
              />
            )}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loadingStatus === LoadingStatus.Loading}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          form="create-course-form"
          disabled={!formState.isValid}
          loadingStatus={loadingStatus}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCourseDialog;
