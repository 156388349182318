import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Grid,
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Select,
  InputLabel
} from '@material-ui/core';

import { LoadingStatus } from 'app/store/types.js';
import LoadingButton from 'app/components/LoadingButton.js';
import { CourseManagementRoutes } from 'app/utils/routes.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs } from '@/i18n/dictionaries/interfaces.js';
import { CurrentUserSelector, isUserOwnerSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import * as validationSchemas from 'app/components/CourseManagement/CourseCard/validationSchema.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';
import type { CourseDto, CreateScheduledCourseCommand, ScheduledCourseDto } from '@/generated-api/index.js';
import logger from 'app/utils/logger.js';
import type { CompanyDepartmentDto } from '@/generated-api/scenes/api.js';

export type CreateScheduledCourseDialogProps = {
  course: CourseDto;
  isOpen: boolean;
  courseId: string;
  onClose: () => void;
};

export const NO_DEPARTMENT_SELECTED = 0;

const CreateScheduledCourseDialog = ({
  course,
  courseId,
  isOpen,
  onClose
}: CreateScheduledCourseDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const currentUser = useSelector(CurrentUserSelector);
  const isUserOwner = useSelector(isUserOwnerSelector);
  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);

  const [departments, setDepartments] = useState<CompanyDepartmentDto[]>([]);

  const [translate] = useTranslation([I18nNamespace.CourseManagement, I18nNamespace.Common]);

  const { control, handleSubmit, reset, formState } = useForm<CreateScheduledCourseCommand>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchemas.createScheduledCourse),
    defaultValues: {
      departmentId: currentUser.departmentId ?? NO_DEPARTMENT_SELECTED,
      startDate: '',
      endDate: ''
    }
  });

  const history = useHistory();

  const onScheduleCourseCreated = useCallback(
    (scheduledCourse: ScheduledCourseDto) => {
      onClose();
      reset();

      const path = generatePath(CourseManagementRoutes.ScheduledCourse, {
        courseId: courseId,
        scheduledCourseId: scheduledCourse.id
      });

      history.push(path);
    },
    [courseId, history, onClose, reset]
  );

  const requestCompanyDepartments = useCallback(
    () => dispatch(CourseManagementActions.requestCompanyDepartments(course.companyId, logger.log, setDepartments)),
    [course, dispatch]
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) =>
        dispatch(CourseManagementActions.createScheduledCourse(course.id, data, onScheduleCourseCreated))
      ),
    [course, dispatch, handleSubmit, onScheduleCourseCreated]
  );

  const loadingStatus = useSelector(CourseManagementSelectors.createScheduledCourseLoadingStatus);

  const displayNoDepartmentOption = isUserSuperAdmin || isUserOwner || !currentUser.departmentId;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      TransitionProps={{
        onExited: () => reset(),
        onEnter: () => requestCompanyDepartments()
      }}
    >
      <DialogTitle>
        {translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.scheduleCourse))}
      </DialogTitle>
      <DialogContent>
        <form id="create-scheduled-course-form" onSubmit={onSubmit}>
          <Controller
            select
            required
            fullWidth
            type="text"
            SelectProps={{ displayEmpty: true }}
            InputLabelProps={{ shrink: true }}
            as={TextField}
            margin="normal"
            control={control}
            disabled={!departments.length}
            name={nameof.full<CreateScheduledCourseCommand>((c) => c.departmentId)}
            label={translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.department))}
          >
            {displayNoDepartmentOption && (
              <MenuItem value={NO_DEPARTMENT_SELECTED}>
                {translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.defaultDepartment))}
              </MenuItem>
            )}
            {departments.map((department: CompanyDepartmentDto) => (
              <MenuItem key={department.id} value={department.id}>
                {department.name}
              </MenuItem>
            ))}
          </Controller>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Controller
                required
                fullWidth
                type="date"
                as={TextField}
                control={control}
                name={nameof.full<CreateScheduledCourseCommand>((c) => c.startDate)}
                margin="normal"
                label={translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.startDate))}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                required
                fullWidth
                type="date"
                as={TextField}
                margin="normal"
                control={control}
                name={nameof.full<CreateScheduledCourseCommand>((c) => c.endDate)}
                label={translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.endDate))}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loadingStatus === LoadingStatus.Loading}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formState.isValid}
          loadingStatus={loadingStatus}
          form="create-scheduled-course-form"
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateScheduledCourseDialog;
