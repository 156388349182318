/* eslint-disable react/display-name */
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useMemo } from 'react';
// import DeleteIcon from '@material-ui/icons/Delete.js';
import type { GridColumns } from '@material-ui/data-grid';
import { generatePath, Link } from 'react-router-dom';
import { Box, Button, Grid, IconButton, Tooltip, Link as MuiLink } from '@material-ui/core';

import useDelete from 'app/hooks/useDelete.js';
import { SceneRoutes } from 'app/utils/routes.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import type { CourseDto, CourseSceneDto } from '@/generated-api/index.js';
import DeleteCourseScenarioDialog from 'app/components/CourseManagement/CourseCard/ScenarioList/DeleteCourseScenarioDialog.js';
import CreateCourseScenarioDialog from 'app/components/CourseManagement/CourseCard/AddScenarioDialog/CreateCourseScenarioDialog.js';
import { Add, Delete } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const RowsPerPage = 25;

type ScenarioListProps = {
  course: CourseDto;
};

const ScenarioList: React.FC<ScenarioListProps> = ({ course }) => {
  const classes = useStyles();

  const [
    isCreateCourseScenarioDialogOpen,
    openCreateCourseScenarioDialog,
    closeCreateCourseScenarioDialog
  ] = useBoolState();

  const {
    closeConfirmationDialog,
    isConfirmationDialogOpen,
    onSelectItem: onSelectScenario,
    selectedItem: selectedScenario,
    resetSelectedItem: resetSelectedScenario
  } = useDelete<CourseSceneDto>();

  useEffect(() => {
    return () => {
      resetSelectedScenario();
    };
  }, [resetSelectedScenario]);

  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarioList.name)),
        flex: 1,
        renderCell: (data) => {
          const scene = data.row as CourseSceneDto;

          const path = generatePath(SceneRoutes.Scene, {
            sceneId: scene.id
          });

          return (
            <Tooltip title={scene.description ?? ''}>
              <MuiLink to={path} component={Link} className={classes.link}>
                {scene.name}
              </MuiLink>
            </Tooltip>
          );
        }
      },
      {
        width: 50,
        field: 'actions',
        headerName: ' ',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (data) => {
          const scenario = data.row as CourseSceneDto;

          return (
            <Tooltip
              title={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
              placement="bottom"
            >
              <IconButton size="small" onClick={() => onSelectScenario(scenario)}>
                <Delete />
              </IconButton>
            </Tooltip>
          );
        }
      }
    ],
    [classes.link, onSelectScenario, translate, translateCommon]
  );

  if (!course) return null;

  return (
    <>
      <SimpleDataGrid
        autoHeight
        loading={false}
        columns={columns}
        rows={course.scenes}
        pageSize={RowsPerPage}
        hideFooterSelectedRowCount
        isCellEditable={() => false}
        isRowSelectable={() => false}
        disableSelectionOnClick
        rowsPerPageOptions={[RowsPerPage]}
        components={{
          Toolbar: () => (
            <Box padding={1}>
              <Grid container justifyContent="flex-start" wrap="nowrap">
                <Button startIcon={<Add />} variant="outlined" onClick={openCreateCourseScenarioDialog}>
                  {translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarioList.addScenario))}
                </Button>
              </Grid>
            </Box>
          )
        }}
      />
      <CreateCourseScenarioDialog
        course={course}
        isOpen={isCreateCourseScenarioDialogOpen}
        onClose={closeCreateCourseScenarioDialog}
      />
      <DeleteCourseScenarioDialog
        course={course}
        scenario={selectedScenario}
        open={isConfirmationDialogOpen}
        onClose={closeConfirmationDialog}
        onExited={resetSelectedScenario}
      />
    </>
  );
};

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline'
  }
}));

export default ScenarioList;
