export const enum ScenarioSettingTts {
  AWS = 0,
  Google = 1,
  InHouse = 2,
  Microsoft = 3,
  ElevenLabs = 4,
  Deepgram = 5,
  OpenAi = 6
}

export const enum ScenarioSettingSex {
  Female = 0,
  Male = 1
}

export interface UserSaysPhrases {
  scenarioId: number;
  userSaysPhrases: string[];
}

export interface UserSaysPhrasesRequest {
  sceneId: number;
  startblockId: number;
}

export enum SttProvider {
  Google = 0,
  Inhouse = 1
}

export enum ScenarioSettingsLLM {
  ChatGPT_35 = 0,
  ChatGPT_4 = 1,
  ChatGPT_4o = 2,
  Claude3Haiku = 3,
  Claude3Sonnet = 4,
  Claude3Opus = 5,
  Claude35Sonnet = 6,
  MistralTiny = 7,
  MistralSmall = 8,
  MistralMedium = 9,
  MistralLarge = 10,
  DBRX = 11,
  Llama3_8B = 12,
  Llama3_70B = 13
}
