import type { CompanyVariableDto, VariableDto } from '@/generated-api/index.js';
import { Button, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { useCompanyVariableMutations, useVariableMutations } from 'app/mutations/variableMutations.js';
import { ScenarioChartSelectors, ScenarioSelectors } from 'app/selectors/index.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import { updateChangedVariableName } from 'app/utils/variableUtils.js';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type VariableLineProps = {
  variable: VariableDto;
  variables: VariableDto[];
  scenarioId: number;
  companyVariable?: CompanyVariableDto; // For admins to override variables
  selectedCompanyId: number;
  isSharedScenario: boolean;
};

const useStyles = makeStyles((theme) => ({
  variableUsed: {
    borderColor: theme.palette.success.main + ' !important'
  }
}));

const VariableLine = (props: VariableLineProps) => {
  const { variable, scenarioId, companyVariable, selectedCompanyId, isSharedScenario, variables } = props;
  const [name, setName] = React.useState(variable.name);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [value, setValue] = React.useState(variable.value);
  const [companyValue, setCompanyValue] = React.useState(companyVariable?.value ?? '');

  const [showConfirm, setShowConfirm] = useState(false);

  const { removeVariable, updateVariable } = useVariableMutations(scenarioId);
  const { removeCompanyVariable, updateCompanyVariable, addCompanyVariable } = useCompanyVariableMutations(
    scenarioId,
    selectedCompanyId
  );

  const classes = useStyles();

  const isSuperAdmin = useSelector(isUserSuperAdminSelector);

  const handleRemove = async () => {
    if (companyVariable) {
      await removeCompanyVariable.mutateAsync(companyVariable.id);
    } else {
      await removeVariable.mutateAsync(name);
    }
    setShowConfirm(false);
  };

  const handleUpdate = async () => {
    if (companyVariable) {
      if (companyVariable.id) {
        await updateCompanyVariable.mutateAsync({
          companyVariableId: companyVariable.id,
          command: { value: companyValue }
        });
      } else {
        await addCompanyVariable.mutateAsync({
          value: companyValue,
          variableId: variable.id
        });
      }
    } else {
      if (name !== variable.name) {
        await updateVariable.mutateAsync({
          oldName: variable.name,
          updateVariableCommand: { id: variable.id, name, value }
        });
      }
      await updateVariable.mutateAsync({ updateVariableCommand: { name, value } });
    }
  };

  useEffect(() => {
    if (companyVariable) {
      setCompanyValue(companyVariable?.value ?? '');
    }
  }, [companyVariable]);

  const showRemove =
    (isSuperAdmin && (!!companyVariable?.id || !companyVariable)) ||
    (!isSuperAdmin && !!companyVariable?.id) ||
    !isSharedScenario;

  const isNameValid = useMemo(() => {
    if (name?.includes(' ')) return false;

    const found = variables.find((v) => v.name === name);
    if (found && found.id !== variable.id) return false;
    else return true;
  }, [name, variable.id, variables]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        <TextField
          onChange={(e) => setName(e.target.value)}
          value={name}
          variant="outlined"
          size="small"
          disabled={!!companyVariable || (isSharedScenario && !isSuperAdmin)}
          label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.variable))}
          error={!isNameValid}
          helperText={
            !isNameValid ? translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.nameCantContainSpace)) : ''
          }
        />
      </Grid>
      <Grid item>
        <TextField
          disabled={!!companyVariable || (isSharedScenario && !isSuperAdmin)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          variant="outlined"
          size="small"
          label={
            companyVariable
              ? translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.defaultValue))
              : translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.value))
          }
          InputProps={{
            classes: {
              notchedOutline:
                isSharedScenario && (!companyValue || (isSuperAdmin && !selectedCompanyId))
                  ? classes.variableUsed
                  : undefined
            }
          }}
        />
      </Grid>
      {!!companyVariable ? (
        <Grid item>
          <TextField
            value={companyValue}
            onChange={(e) => setCompanyValue(e.target.value)}
            variant="outlined"
            size="small"
            label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.organizationValue))}
            InputProps={{
              classes: {
                notchedOutline: isSharedScenario && !!companyValue ? classes.variableUsed : undefined
              }
            }}
          />
        </Grid>
      ) : null}
      {(name && name !== variable.name && isNameValid) ||
      (value && value !== variable.value && isNameValid) ||
      (companyVariable && companyValue && companyValue !== companyVariable.value && isNameValid) ? (
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.update))}
          </Button>
        </Grid>
      ) : null}
      {showConfirm ? (
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography>{translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.areYouSure))}</Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="secondary" onClick={() => setShowConfirm(false)}>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.no))}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={handleRemove}>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.yes))}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : showRemove ? (
        <Grid item>
          <Button variant="outlined" color="secondary" onClick={() => setShowConfirm(true)}>
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableLine.remove))}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default VariableLine;
