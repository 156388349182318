import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';
import { DialogStatisticsClient } from 'app/apis/api.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

interface TranscriptDialogProps {
  open: boolean;
  onClose: () => void;
  dialogStatisticId: number;
}

const TranscriptDialog: React.FC<TranscriptDialogProps> = ({ open, onClose, dialogStatisticId }) => {
  const [transcript, setTranscript] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  useEffect(() => {
    if (open) {
      const fetchTranscript = async () => {
        setLoading(true);
        setError('');

        try {
          const response = await DialogStatisticsClient.dialogStatisticsGetDialogTranscript(dialogStatisticId);
          setTranscript(response.data);
        } catch (error) {
          console.error('Error fetching transcript:', error);
          setError('Error fetching transcript');
        }

        setLoading(false);
      };

      fetchTranscript();
    }
  }, [dialogStatisticId, open, translate]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {translate(nameof.full<I18nDashboardNs>((n) => n.dashboardTableMenu.conversationTranscript))}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '16px' }}>
            <CircularProgress />
          </div>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <DialogContentText style={{ whiteSpace: 'pre-wrap' }}>{transcript}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.close))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TranscriptDialog;