import React, { useCallback, useState, useEffect, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  makeStyles
} from '@material-ui/core';
import { ScenarioClient } from 'app/apis/api.js';
import type { ScenarioUpdateDto } from '@/generated-api/index.js';
import { DateFormat } from 'app/app.constants.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck.js';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
import ConfirmationDialog from './ConfirmationDialog.js';

import RestorePageOutlinedIcon from '@material-ui/icons/RestorePageOutlined.js';

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

export type ScenarioChangelogDialogProps = {
  open: boolean;
  onClose: () => void;
  scenarioId: number;
};

export const DialogCloseContext = createContext(false);

interface RowProps {
  onClose: () => void;
  scenarioId: number;
  versionId: number;
  updates: ScenarioUpdateDto[];
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    maxHeight: 600
  },
  tableContainer: {
    maxHeight: 600,
    overflow: 'auto'
  }
});

const Row: React.FC<RowProps> = ({ onClose, scenarioId, versionId, updates }) => {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const formatDate = useFormatDate();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const isDialogOpen = useContext(DialogCloseContext);

  useEffect(() => {
    if (isDialogOpen) {
      return;
    }
    setOpen(false);
  }, [isDialogOpen]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  const onRestoreScenario = (scenarioId: number, versionId: number) => {
    openConfirmationDialog();
  };

  const openConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  };

  const onRestoreScenarioConfirmed = () => {
    closeConfirmationDialog();
    onClose();
    dispatch(ScenarioActions.restoreScenario({ scenarioId: scenarioId, versionId: versionId }));
  };

  return (
    <>
      <TableRow
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          cursor: 'pointer',
          backgroundColor: hover ? 'rgba(0, 0, 0, 0.05)' : undefined
        }}
      >
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <strong>{versionId}</strong>{' '}
          <Button onClick={() => onRestoreScenario(scenarioId, versionId)}>
            <RestorePageOutlinedIcon fontSize="small" />
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.restore))}
          </Button>
        </TableCell>
        <TableCell>{updates[0].createdByUserName ?? 'Unknown user'}</TableCell>
        <TableCell>{formatDate(updates[0].createdDate as string, DateFormat.Time)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.changeType))}
                    </TableCell>
                    <TableCell>
                      {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.oldValue))}
                    </TableCell>
                    <TableCell>
                      {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.newValue))}
                    </TableCell>
                    <TableCell>
                      {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.blockName))}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {updates.map((update) => (
                    <TableRow key={update.id}>
                      <TableCell>{update.scenarioChangeTypeName}</TableCell>
                      <TableCell>{update.oldValue}</TableCell>
                      <TableCell>{update.newValue}</TableCell>
                      <TableCell>{update.blockName}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        open={confirmationDialogOpen}
        versionId={versionId}
        onConfirm={onRestoreScenarioConfirmed}
        onCancel={closeConfirmationDialog}
      />
    </>
  );
};

const ScenarioChangelogDialog: React.FC<ScenarioChangelogDialogProps> = ({ open, onClose, scenarioId }) => {
  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [scenarioUpdates, setScenarioUpdates] = useState<ScenarioUpdateDto[]>([]);

  const onEnter = useCallback(
    () =>
      ScenarioClient.scenariosGetScenarioUpdates(scenarioId as number).then((response) =>
        setScenarioUpdates(response.data)
      ),
    [scenarioId]
  );

  const handleClose = () => {
    onClose();
  };

  const groupedUpdates = scenarioUpdates.reduce((acc, update) => {
    if (acc[update.versionId]) {
      acc[update.versionId].push(update);
    } else {
      acc[update.versionId] = [update];
    }
    return acc;
  }, {} as { [key: number]: ScenarioUpdateDto[] });

  const sortedVersionIds = Object.keys(groupedUpdates)
    .map((versionId) => parseInt(versionId))
    .sort((a, b) => b - a);

  return (
    <DialogCloseContext.Provider value={open}>
      <Dialog
        id={'scenario-changelog-dialog'}
        maxWidth={'md'}
        fullWidth
        open={open}
        keepMounted
        onClose={onClose}
        TransitionProps={{
          onEnter
        }}
      >
        <DialogTitle>
          {' '}
          <PlaylistAddCheckIcon />{' '}
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.changelog))}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <div className={classes.tableContainer}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>
                    {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.version))}
                  </TableCell>
                  <TableCell>
                    {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.updatedBy))}
                  </TableCell>
                  <TableCell>
                    {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.updatedAt))}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedVersionIds.map((versionId) => (
                  <Row
                    key={versionId}
                    onClose={onClose}
                    scenarioId={scenarioId}
                    versionId={versionId}
                    updates={groupedUpdates[versionId]}
                  />
                ))}
              </TableBody>
            </Table>
          </div>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="primary" size="small" onClick={onClose}>
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioChangelogDialog.close))}
          </Button>
        </DialogActions>
      </Dialog>
    </DialogCloseContext.Provider>
  );
};

export default ScenarioChangelogDialog;
