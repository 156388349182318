import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { PlayState } from '../types.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import type { DialogStatisticDto } from '@/generated-api/index.js';

function useScenarioPlayback(
  setCurrentIntentIndex: Dispatch<SetStateAction<number>>,
  dialogStats?: DialogStatisticDto
) {
  const [playState, setPlayState] = useState(PlayState.Stopped);
  const transitionMeta = useRef({
    scheduledTimeout: NaN,
    stateBeforePause: PlayState.User
  });

  const onStop = useCallback(() => {
    clearTimeout(transitionMeta.current.scheduledTimeout);
    setPlayState(PlayState.Stopped);
  }, []);

  const togglePause = useCallback(() => {
    setPlayState((current) => {
      switch (current) {
        case PlayState.Stopped:
          transitionMeta.current.stateBeforePause = PlayState.User;
          return PlayState.User;
        case PlayState.Paused:
          clearTimeout(transitionMeta.current.scheduledTimeout);
          return transitionMeta.current.stateBeforePause;
        default:
          transitionMeta.current.stateBeforePause = current;
          return PlayState.Paused;
      }
    });
  }, []);

  const unmountedRef = useUnmounted();

  const onAiSpeechFinished = useCallback(() => {
    setPlayState(PlayState.PauseAfterAi);
  }, []);

  const onUserSpeechFinished = useCallback(() => {
    setPlayState(PlayState.PauseAfterUser);
  }, []);

  // transitions
  const lastHandledPlayState = useRef<PlayState>(null);
  useEffect(() => {
    if (lastHandledPlayState.current === playState) {
      return;
    }

    lastHandledPlayState.current = playState;

    switch (playState) {
      case PlayState.Stopped:
        clearTimeout(transitionMeta.current.scheduledTimeout);
        break;

      case PlayState.Paused:
        clearTimeout(transitionMeta.current.scheduledTimeout);
        break;

      case PlayState.PauseAfterUser:
        transitionMeta.current.scheduledTimeout = window.setTimeout(setPlayState, 250, PlayState.AI);
        break;

      case PlayState.PauseAfterAi:
        const moveToTheNextIntent = () => {
          ReactDOM.unstable_batchedUpdates(() => {
            if (unmountedRef.current) {
              return;
            }

            setCurrentIntentIndex((current) => {
              if (current < dialogStats?.intents.length - 1) {
                setPlayState(PlayState.User);
                return current + 1;
              }

              setPlayState(PlayState.Stopped);
              return current;
            });
          });
        };
        transitionMeta.current.scheduledTimeout = window.setTimeout(moveToTheNextIntent, 250);
        break;

      default:
        break;
    }
  }, [dialogStats?.intents, playState, unmountedRef, setCurrentIntentIndex]);

  return { playState, setPlayState, onStop, togglePause, onUserSpeechFinished, onAiSpeechFinished };
}

export default useScenarioPlayback;
