import { createAction } from 'redux-actions';

import type * as ChartActionTypes from '@mrblenny/react-flow-chart/src/types/functions.js';
import type { CustomChart } from 'app/models/scenarios/customChart.js';
import type { CustomNode } from 'app/models/intents/customNode.js';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ScenarioChartEditingActions {
  export const enum Type {
    ON_DRAG_NODE = 'ON_DRAG_NODE',
    ON_DRAG_NODE_STOP = 'ON_DRAG_NODE_STOP',
    ON_DRAG_CANVAS = 'ON_DRAG_CANVAS',
    ON_DRAG_CANVAS_STOP = 'ON_DRAG_CANVAS_STOP',
    ON_LINK_START = 'ON_LINK_START',
    ON_LINK_MOVE = 'ON_LINK_MOVE',
    ON_LINK_COMPLETE = 'ON_LINK_COMPLETE',
    ON_LINK_CANCELLED = 'ON_LINK_CANCELLED',
    ON_LINK_MOUSE_ENTER = 'ON_LINK_MOUSE_ENTER',
    ON_LINK_MOUSE_LEAVE = 'ON_LINK_MOUSE_LEAVE',
    ON_LINK_CLICK = 'ON_LINK_CLICK',
    ON_CANVAS_CLICK = 'ON_CANVAS_CLICK',
    ON_NODE_MOUSE_ENTER = 'ON_NODE_MOUSE_ENTER',
    ON_NODE_MOUSE_LEAVE = 'ON_NODE_MOUSE_LEAVE',
    ON_DELETE_SELECTED = 'ON_DELETE_SELECTED',
    ON_DELETE_ALL_LINKS = 'ON_DELETE_ALL_LINKS',
    ON_NODE_CLICK = 'ON_NODE_CLICK',
    ON_NODE_DOUBLE_CLICK = 'ON_NODE_DOUBLE_CLICK',
    ON_NODE_SIZE_CHANGE = 'ON_NODE_SIZE_CHANGE',
    ON_PORT_POSITION_CHANGE = 'ON_PORT_POSITION_CHANGE',
    ON_CANVAS_DROP = 'ON_CANVAS_DROP',
    ON_ZOOM_CANVAS = 'ON_ZOOM_CANVAS',
    ON_HOVERED_PORT = 'ON_HOVERED_PORT',
    SET_SCENARIO_STATE = 'chart/setState',
    SET_LAST_ACTION_NODE = 'SET_LAST_ACTION_NODE'
  }

  export const onDragNode = createAction<ChartActionTypes.IOnDragNodeInput>(Type.ON_DRAG_NODE);
  export const onDragNodeStop = createAction<ChartActionTypes.IOnDragNodeStopInput>(Type.ON_DRAG_NODE_STOP);
  export const onDragCanvas = createAction<ChartActionTypes.IOnDragCanvasInput>(Type.ON_DRAG_CANVAS);
  export const onDragCanvasStop = createAction<ChartActionTypes.IOnDragCanvasStopInput>(Type.ON_DRAG_CANVAS_STOP);
  export const onLinkStart = createAction<ChartActionTypes.IOnLinkBaseEvent>(Type.ON_LINK_START);
  export const onLinkMove = createAction<ChartActionTypes.IOnLinkMoveInput>(Type.ON_LINK_MOVE);
  export const onLinkComplete = createAction<ChartActionTypes.IOnLinkCompleteInput>(Type.ON_LINK_COMPLETE);
  export const onLinkCancel = createAction<ChartActionTypes.IOnLinkBaseEvent>(Type.ON_LINK_CANCELLED);
  export const onLinkMouseEnter = createAction<ChartActionTypes.ILinkBaseInput>(Type.ON_LINK_MOUSE_ENTER);
  export const onLinkMouseLeave = createAction<ChartActionTypes.ILinkBaseInput>(Type.ON_LINK_MOUSE_LEAVE);
  export const onLinkClick = createAction<ChartActionTypes.ILinkBaseInput>(Type.ON_LINK_CLICK);
  export const onCanvasClick = createAction<Parameters<ChartActionTypes.IOnCanvasClick>[0]>(Type.ON_CANVAS_CLICK);
  export const onNodeMouseEnter = createAction<ChartActionTypes.INodeBaseInput>(Type.ON_NODE_MOUSE_ENTER);
  export const onNodeMouseLeave = createAction<ChartActionTypes.INodeBaseInput>(Type.ON_NODE_MOUSE_LEAVE);
  export const onDeleteSelected = createAction<Parameters<ChartActionTypes.IOnDeleteKey>[0] | void>(
    Type.ON_DELETE_SELECTED
  );
  export const onDeleteAllLinks = createAction<void>(Type.ON_DELETE_ALL_LINKS);
  export const onNodeClick = createAction<ChartActionTypes.INodeBaseInput>(Type.ON_NODE_CLICK);
  export const onNodeDoubleClick = createAction<ChartActionTypes.INodeBaseInput>(Type.ON_NODE_DOUBLE_CLICK);
  export const onNodeSizeChange = createAction<ChartActionTypes.IOnNodeSizeChangeInput>(Type.ON_NODE_SIZE_CHANGE);
  export const onPortPositionChange = createAction<ChartActionTypes.IOnPortPositionChangeInput>(
    Type.ON_PORT_POSITION_CHANGE
  );
  export const setLastActionNode = createAction<CustomNode>(Type.SET_LAST_ACTION_NODE);
  export const onCanvasDrop = createAction<ChartActionTypes.IOnCanvasDropInput>(Type.ON_CANVAS_DROP);
  export const onZoomCanvas = createAction<Parameters<ChartActionTypes.IOnZoomCanvas>[0]>(Type.ON_ZOOM_CANVAS);
  export const onHoveredPort = createAction<{ hoveredPort: { hoveredPortNodeId: string; portId: string } | null }>(
    Type.ON_HOVERED_PORT
  );

  /** BE CAREFUL: you must provide mutable changes! */
  export const setChartState = createAction<React.SetStateAction<CustomChart>>(Type.SET_SCENARIO_STATE);
}
