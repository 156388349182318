import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { generatePath, matchPath, useLocation } from 'react-router';
import {
  Avatar,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input
} from '@material-ui/core';

import { MyCoursesRoutes } from 'app/utils/routes.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import type { ScheduledCourseDto } from '@/generated-api/index.js';
import LinearProgressWithLabel from 'app/components/utils/styledTableElements/LinearProgressWithLabel/index.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';
import { Image } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { isAfter, isBefore, isEqual, parseISO, startOfToday } from 'date-fns';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nCourseManagementNs } from '@/i18n/dictionaries/interfaces.js';

const useStyles = makeStyles(() => ({
  formControl: {
    // marginLeft: theme.spacing(2),
    minWidth: 150,
    maxWidth: 300
  }
}));

const ScheduledCourseList: React.FC = () => {
  const classes = useStyles();
  const scheduledCourses = useSelector(CourseManagementSelectors.scheduledCoursesSelector);
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [filter, setFilter] = React.useState<CourseFilter[]>([
    translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.ongoing))
  ]);

  const formatDate = useFormatDate();

  const courseFilters = [
    translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.ongoing)),
    translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.scheduled)),
    translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.completed))
  ] as const;
  type CourseFilter = typeof courseFilters[number];

  const location = useLocation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as CourseFilter[]);
  };

  const filteredCourses = useMemo(() => {
    // All or none -> no filter
    if (filter.length === 0 || filter.length === 3) return scheduledCourses;

    const now = new Date();
    now.setUTCHours(0, 0, 0, 0);

    const final: ScheduledCourseDto[] = [];

    if (filter.includes(translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.ongoing)))) {
      const ongoing = scheduledCourses.filter((course) => {
        const start = new Date(course.startDate);
        const end = new Date(course.endDate);

        return (isEqual(start, now) || isBefore(start, now)) && (isAfter(end, now) || isEqual(end, now));
      });
      final.push(...ongoing);
    }

    if (filter.includes(translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.scheduled)))) {
      const scheduled = scheduledCourses.filter((course) => {
        const start = new Date(course.startDate);

        return isBefore(now, start);
      });

      final.push(...scheduled);
    }

    if (filter.includes(translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.completed)))) {
      const completed = scheduledCourses.filter((course) => {
        const end = new Date(course.endDate);

        return isAfter(now, end);
      });

      final.push(...completed);
    }

    return final;
  }, [filter, scheduledCourses, translate]);

  if (!scheduledCourses.length) {
    return (
      <Box py={3} display={'flex'} justifyContent={'center'} color={'action.disabled'}>
        <Typography>
          {translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.noScheduledCourses))}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel id="course-filter-label">
          {translate(nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.filter))}
        </InputLabel>
        <Select
          id="course-filter"
          labelId="course-filter-label"
          multiple
          value={filter}
          onChange={handleChange}
          input={<Input />}
        >
          {courseFilters.map((x) => (
            <MenuItem value={x} key={x}>
              {x}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <List>
        {filteredCourses.map((scheduledCourse: ScheduledCourseDto) => {
          const path = generatePath(MyCoursesRoutes.ScheduledCourse, { scheduledCourseId: scheduledCourse.id });

          const match = matchPath(location.pathname, {
            path
          });

          return (
            <ListItem key={scheduledCourse.id} component={RouterLink} to={path} selected={!!match}>
              <Grid container alignItems="center">
                <ListItemAvatar>
                  <Avatar variant={'square'}>
                    <Image />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={<Link component={Typography}>{scheduledCourse.courseTitle}</Link>}
                  secondary={`${formatDate(scheduledCourse.startDate)} ${translate(
                    nameof.full<I18nCourseManagementNs>((n) => n.courseStatus.to)
                  )} ${formatDate(scheduledCourse.endDate)}`}
                />
                <Grid container direction="column">
                  <Grid item xs={6}>
                    <LinearProgressWithLabel
                      scoresMax={scheduledCourse.scenesCount}
                      value={scheduledCourse.completedScenesCount}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default ScheduledCourseList;
