import React, { createContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import type { GlobalAssetIntentDto } from '@/generated-api/index.js';
import { useParams } from 'react-router-dom';

export type GlobalAssetsLibraryContextValue = {
  selectedTemplate: GlobalAssetIntentDto | null;
  setSelectedTemplate: (template: GlobalAssetIntentDto | null) => void;
  searchText: string;
  setSearchText: (text: string) => void;
};

export const GlobalAssetsLibraryContext = createContext({} as GlobalAssetsLibraryContextValue);

export type ProviderProps = { children: ReactNode };

export const GlobalAssetsLibraryContextProvider = ({ children }: ProviderProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<GlobalAssetIntentDto | null>(null);
  const [searchText, setSearchText] = useState<string>(''); // Add searchText state

  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    setSelectedTemplate(null);
  }, [id]);

  return (
    <GlobalAssetsLibraryContext.Provider value={{ selectedTemplate, setSelectedTemplate, searchText, setSearchText }}>
      {children}
    </GlobalAssetsLibraryContext.Provider>
  );
};
