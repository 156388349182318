import type React from 'react';
import { useCallback, useState } from 'react';

const useMenu = (): {
  isOpen: boolean;
  anchorEl: HTMLElement;
  handleClose: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
} => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const isOpen = Boolean(anchorEl);

  return {
    isOpen,
    anchorEl,
    handleClick,
    handleClose
  };
};

export default useMenu;
