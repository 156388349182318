import type { ScheduledCourseDto, UpdateScheduledCourseRequestCommand } from '@/generated-api/index.js';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as validationSchemas from 'app/components/CourseManagement/CourseCard/validationSchema.js';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import { useTranslation } from 'react-i18next';
import LoadingButton from 'app/components/LoadingButton.js';
import { LoadingStatus } from 'app/store/types.js';
import { CourseClient } from 'app/apis/api.js';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import { useParams } from 'react-router';


type UpdateScheduledCourseDialogProps = {
  scheduledCourse: ScheduledCourseDto | null;
  open: boolean;
  onClose: () => void;
};

export const NO_DEPARTMENT_SELECTED = '';

const UpdateScheduledCourseDialog = (props: UpdateScheduledCourseDialogProps) => {
  const { scheduledCourse, open, onClose } = props;

  const dispatch = useDispatch();
  const { courseId } = useParams<{ courseId: string }>();

  const { control, handleSubmit, reset, formState } = useForm<UpdateScheduledCourseRequestCommand>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchemas.createScheduledCourse),
    defaultValues: {
      startDate: scheduledCourse?.startDate.split('T')[0],
      endDate: scheduledCourse?.endDate.split('T')[0]
    }
  });

  useEffect(() => {
    reset({ startDate: scheduledCourse?.startDate.split('T')[0], endDate: scheduledCourse?.endDate.split('T')[0] });
  }, [reset, scheduledCourse]);

  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const updateMutation = useMutation({
    mutationFn: async (command: UpdateScheduledCourseRequestCommand) => {
      await CourseClient.courseUpdateScheduledCourse(scheduledCourse.id, {
        ...command,
        departmentId: scheduledCourse.departmentId || null
      });
    },
    onSuccess: () => {
      // TODO Refetch data in redux!!!
      dispatch(
        CourseManagementActions.requestCourse(Number(courseId), () => {
          onClose();
          reset();
        })
      );
    }
  });

  const onSubmit = React.useMemo(
    () =>
      handleSubmit((data: UpdateScheduledCourseRequestCommand) => {
        updateMutation.mutate(data);
      }),
    [handleSubmit, updateMutation]
  );

  const loadingStatus = updateMutation.isLoading ? LoadingStatus.Loading : LoadingStatus.Idle;

  // if (!scheduledCourse) return null;

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="sm"
      onClose={onClose}
      TransitionProps={{
        onExited: () => reset()
      }}
    >
      <DialogTitle>{translate(nameof.full<I18nCourseManagementNs>((n) => n.updateScheduledCourse))}</DialogTitle>
      <DialogContent>
        <form id="update-scheduled-course-form" onSubmit={onSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Controller
                required
                fullWidth
                type="date"
                as={TextField}
                control={control}
                name={nameof.full<UpdateScheduledCourseRequestCommand>((c) => c.startDate)}
                margin="normal"
                label={translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.startDate))}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                required
                fullWidth
                type="date"
                as={TextField}
                margin="normal"
                control={control}
                name={nameof.full<UpdateScheduledCourseRequestCommand>((c) => c.endDate)}
                label={translate(nameof.full<I18nCourseManagementNs>((n) => n.createScheduledCourseForm.endDate))}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loadingStatus === LoadingStatus.Loading}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formState.isValid}
          loadingStatus={loadingStatus}
          form="update-scheduled-course-form"
        >
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.update))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateScheduledCourseDialog;
