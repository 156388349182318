import type { Action } from 'redux-actions';
import { put, select, takeLatest, fork, debounce, putResolve } from 'redux-saga/effects';
import type { IChart } from '@mrblenny/react-flow-chart';
import { actions } from '@mrblenny/react-flow-chart';
import type * as ChartActionTypes from '@mrblenny/react-flow-chart/src/types/functions.js';

import { ScenarioChartStoreActions } from '../../../actions/scenarios/scenarioChartStoreAction.js';
import { ScenarioChartEditingActions } from '../../../actions/scenarios/scenarioChartEditingActions.js';
import { ScenarioChartSelectors } from '../../../selectors/index.js';
import type { CustomChart } from '../../../models/scenarios/customChart.js';
import type { CustomNode } from 'app/models/intents/customNode.js';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';

// FIXME: @NRU hopefully when react-flow-chart is updated, the cast to CustomChart will become unnecessary. Remove it then.

function* onDragNodeSaga(action: Action<ChartActionTypes.IOnDragNodeInput>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  const updatedChart = actions.onDragNode(action.payload)(currentChart) as CustomChart;
  yield put(ScenarioChartStoreActions.setNode(updatedChart.nodes[action.payload.id]));
}

function* onDragCanvasStopSaga(action: Action<ChartActionTypes.IOnDragCanvasStopInput>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  // types for this are straight up wrong
  const updatedChart = ((actions.onDragCanvas as unknown) as ChartActionTypes.IStateCallback<
    ChartActionTypes.IOnDragCanvasStop
  >)(action.payload)(currentChart) as CustomChart;
  yield put(ScenarioChartStoreActions.setOffset(updatedChart.offset));
}

function* onLinkStartSaga(action: Action<ChartActionTypes.IOnLinkBaseEvent>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  const updatedChart = actions.onLinkStart(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setLink(updatedChart.links[action.payload.linkId]));
}

function* onLinkMoveSaga(action: Action<ChartActionTypes.IOnLinkMoveInput>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  const updatedChart = actions.onLinkMove(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setLink(updatedChart.links[action.payload.linkId]));
}

function* onLinkCompleteSaga(action: Action<ChartActionTypes.IOnLinkCompleteInput>) {
  const correctUpdateChartAndLink = (link: ChartActionTypes.IOnLinkCompleteInput, chart: CustomChart) => {
    if (link.fromPortId === 'portIn' && link.toPortId !== link.fromPortId) {
      return {
        link: {
          ...link,
          fromNodeId: link.toNodeId,
          fromPortId: link.toPortId,
          toNodeId: link.fromNodeId,
          toPortId: link.fromPortId
        },
        chartWithOptionalChangedLinks: {
          ...chart,
          links: {
            ...chart.links,
            [link.linkId]: {
              ...chart.links[link.linkId],
              properties: !!chart.links[link.linkId].properties && {
                ...chart.links[link.linkId].properties,
                linkStatus: null
              },
              from: { nodeId: link.toNodeId, portId: link.toPortId }
            }
          }
        }
      };
    }
    return {
      link,
      chartWithOptionalChangedLinks: {
        ...chart,
        links: {
          ...chart.links,
          [link.linkId]: {
            ...chart.links[link.linkId],
            properties: !!chart.links[link.linkId].properties && {
              ...chart.links[link.linkId].properties,
              linkStatus: null
            }
          }
        }
      }
    };
  };
  const currentChart: CustomChart = yield select(ScenarioChartSelectors.scenarioChart);
  const { link, chartWithOptionalChangedLinks } = correctUpdateChartAndLink(action.payload, currentChart);
  let updatedChart: CustomChart;
  if (
    link.fromPortId !== link.toPortId &&
    !Object.values(currentChart.links).some(
      (l) => l.from.nodeId === action.payload.fromNodeId && l.to.nodeId === action.payload.toNodeId
    )
  ) {
    updatedChart = actions.onLinkComplete(link)(chartWithOptionalChangedLinks) as CustomChart;
  } else {
    updatedChart = actions.onLinkCancel(link)(chartWithOptionalChangedLinks) as CustomChart;
  }
  const toNode = updatedChart.nodes[link.toNodeId];
  // const fromNode = updatedChart.nodes[link.fromNodeId];
  if (toNode?.type === CustomNodeTypeEnum.Service) {
    toNode.type = CustomNodeTypeEnum.Normal;
  }
  // if (fromNode?.type === CustomNodeTypeEnum.Service) {
  //   fromNode.type = CustomNodeTypeEnum.Standard;
  // }
  yield put(ScenarioChartStoreActions.setLinks(updatedChart.links));
}

function* onLinkCancelSaga(action: Action<ChartActionTypes.IOnLinkBaseEvent>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  const updatedChart = actions.onLinkCancel(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setLinks(updatedChart.links));
}

function* onLinkMouseEnterSaga(action: Action<ChartActionTypes.ILinkBaseInput>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  const updatedChart = actions.onLinkMouseEnter(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setHovered(updatedChart.hovered));
}

function* onLinkMouseLeaveSaga(action: Action<ChartActionTypes.ILinkBaseInput>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  const updatedChart = actions.onLinkMouseLeave(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setHovered(updatedChart.hovered));
}

function* onLinkClickSaga(action: Action<ChartActionTypes.ILinkBaseInput>) {
  yield putResolve(ScenarioChartStoreActions.onShowIntentEdit(false));

  const currentChart = (yield select(ScenarioChartSelectors.scenarioChart)) as CustomChart;
  const currentId = currentChart?.selected?.id;
  const updatedChart = actions.onLinkClick(action.payload)(currentChart) as CustomChart;

  if (currentId === updatedChart?.selected?.id) {
    yield put(ScenarioChartStoreActions.setSelected({}));
  } else {
    yield put(ScenarioChartStoreActions.setSelected(updatedChart.selected));
  }
}

function* onDeleteSelectedSaga(action: Action<Parameters<ChartActionTypes.IOnDeleteKey>[0] | void>) {
  const currentChart: IChart = yield select(ScenarioChartSelectors.scenarioChart);
  const selectedLinkId: string = yield select(ScenarioChartSelectors.selectedLinkId);
  // this action can be called with no payload
  // if this is the case, create a valid input for the handler
  const input = !!action.payload
    ? action.payload
    : {
        config: {
          readonly: false
        }
      };

  if (selectedLinkId != null) {
    const fromNodeId = currentChart.links[selectedLinkId].from.nodeId;
    const toNodeId = currentChart.links[selectedLinkId].to.nodeId;
    const fromNode = currentChart.nodes[fromNodeId];
    const incomingLinks = Object.values(currentChart.links).map((x) => x.to.nodeId);
    if (!incomingLinks.includes(fromNode.id) && fromNode?.type === CustomNodeTypeEnum.Normal) {
      fromNode.type = CustomNodeTypeEnum.Service;
    }
    const toNode = currentChart.nodes[toNodeId];
    const outgoingLinks = Object.values(currentChart.links).map((x) => x.from.nodeId);
    if (!outgoingLinks.includes(toNode.id) && toNode?.type === CustomNodeTypeEnum.Normal) {
      toNode.type = CustomNodeTypeEnum.Service;
    }
  }

  const updatedChart = actions.onDeleteKey(input)(currentChart) as CustomChart;

  yield putResolve(ScenarioChartStoreActions.onShowIntentEdit(false));
  yield put(ScenarioChartStoreActions.setSelected(updatedChart.selected));
  yield put(ScenarioChartStoreActions.setNodes(updatedChart.nodes));
  yield put(ScenarioChartStoreActions.setLinks(updatedChart.links));
}

function* onDeleteAllLinksSaga() {
  yield put(ScenarioChartStoreActions.setLinks({}));
  yield put(ScenarioChartStoreActions.setSelected({}));
}

function* onNodeClickSaga(action: Action<ChartActionTypes.INodeBaseInput>) {
  const currentChart = (yield select(ScenarioChartSelectors.scenarioChart)) as CustomChart;
  const updatedChart = actions.onNodeClick(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setSelected(updatedChart.selected));
}

function* onNodeSizeChangeSaga(action: Action<ChartActionTypes.IOnNodeSizeChangeInput>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  const updatedChart = actions.onNodeSizeChange(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setNode(updatedChart.nodes[action.payload.nodeId]));
}

function* onPortPositionChangeSaga(action: Action<ChartActionTypes.IOnPortPositionChangeInput>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  const updatedChart = actions.onPortPositionChange(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setNode(updatedChart.nodes[action.payload.node.id]));
}

function* onZoomCanvasSaga(action: Action<Parameters<ChartActionTypes.IOnZoomCanvas>[0]>) {
  const currentChart = yield select(ScenarioChartSelectors.scenarioChart);
  // types for this are also straight up wrong
  const updatedChart = ((actions.onZoomCanvas as unknown) as ChartActionTypes.IStateCallback<
    ChartActionTypes.IOnZoomCanvas
  >)(action.payload)(currentChart) as CustomChart;

  yield put(ScenarioChartStoreActions.setZoom(updatedChart));
}

function* setLastActionPosition(
  action: Action<
    | ChartActionTypes.ILinkBaseInput
    | ChartActionTypes.INodeBaseInput
    | ChartActionTypes.IOnLinkCompleteInput
    | ChartActionTypes.IOnDragNodeStopInput
  >
) {
  const currentChart: IChart = yield select(ScenarioChartSelectors.scenarioChart);

  if ('nodeId' in action.payload) {
    const payload = action.payload as ChartActionTypes.INodeBaseInput;
    const targetNode = currentChart.nodes[payload.nodeId] as CustomNode;
    return yield put(ScenarioChartEditingActions.setLastActionNode(targetNode));
  }
  if ('toNodeId' in action.payload) {
    const payload = action.payload as ChartActionTypes.IOnLinkCompleteInput;
    const targetNode = currentChart.nodes[payload.toNodeId] as CustomNode;
    return yield put(ScenarioChartEditingActions.setLastActionNode(targetNode));
  }
  if ('id' in action.payload) {
    const payload = action.payload as ChartActionTypes.IOnDragNodeStopInput;
    const targetNode = currentChart.nodes[payload.id] as CustomNode;
    return yield put(ScenarioChartEditingActions.setLastActionNode(targetNode));
  }
  if ('linkId' in action.payload) {
    const payload = action.payload as ChartActionTypes.ILinkBaseInput;
    const targetLink = currentChart.links[payload.linkId];
    const targetNode = currentChart.nodes[targetLink.to.nodeId] as CustomNode;
    return yield put(ScenarioChartEditingActions.setLastActionNode(targetNode));
  }
}

function* onNodeDoubleClickSaga() {
  yield put(ScenarioChartStoreActions.onShowIntentEdit(true));
}

function* watchChartCallbacks() {
  yield debounce(
    300,
    [
      ScenarioChartEditingActions.Type.ON_NODE_CLICK,
      ScenarioChartEditingActions.Type.ON_LINK_CLICK,
      ScenarioChartEditingActions.Type.ON_LINK_COMPLETE,
      ScenarioChartEditingActions.Type.ON_DRAG_NODE_STOP
    ],
    setLastActionPosition
  );
}

function* watchOnDragNode() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_DRAG_NODE, onDragNodeSaga);
}
function* watchOnDragCanvasStop() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_DRAG_CANVAS_STOP, onDragCanvasStopSaga);
}
function* watchOnLinkStart() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_LINK_START, onLinkStartSaga);
}
function* watchOnLinkMove() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_LINK_MOVE, onLinkMoveSaga);
}
function* watchOnLinkComplete() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_LINK_COMPLETE, onLinkCompleteSaga);
}
function* watchOnLinkCancel() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_LINK_CANCELLED, onLinkCancelSaga);
}
function* watchOnLinkMouseEnter() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_LINK_MOUSE_ENTER, onLinkMouseEnterSaga);
}
function* watchOnLinkMouseLeave() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_LINK_MOUSE_LEAVE, onLinkMouseLeaveSaga);
}
function* watchOnLinkClick() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_LINK_CLICK, onLinkClickSaga);
}

function* watchOnDeleteAllLinks() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_DELETE_ALL_LINKS, onDeleteAllLinksSaga);
}
function* watchOnDeleteSelected() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_DELETE_SELECTED, onDeleteSelectedSaga);
}
function* watchOnNodeClick() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_NODE_CLICK, onNodeClickSaga);
}
function* watchOnNodeDoubleClick() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_NODE_DOUBLE_CLICK, onNodeDoubleClickSaga);
}
function* watchOnNodeSizeChange() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_NODE_SIZE_CHANGE, onNodeSizeChangeSaga);
}
function* watchOnPortPositionChange() {
  yield takeLatest(ScenarioChartEditingActions.Type.ON_PORT_POSITION_CHANGE, onPortPositionChangeSaga);
}
function* watchOnZoomCanvas() {
  yield debounce(140, ScenarioChartEditingActions.Type.ON_ZOOM_CANVAS, onZoomCanvasSaga);
}

export function* watchScenarioChartEditing() {
  yield fork(watchOnDragNode);
  yield fork(watchOnDragCanvasStop);
  yield fork(watchOnLinkStart);
  yield fork(watchOnLinkMove);
  yield fork(watchOnLinkComplete);
  yield fork(watchOnLinkCancel);
  yield fork(watchOnLinkMouseEnter);
  yield fork(watchOnLinkMouseLeave);
  yield fork(watchOnLinkClick);
  yield fork(watchOnDeleteSelected);
  yield fork(watchOnNodeDoubleClick);
  yield fork(watchOnNodeClick);
  yield fork(watchOnNodeSizeChange);
  yield fork(watchOnPortPositionChange);
  yield fork(watchOnZoomCanvas);
  yield fork(watchChartCallbacks);
  yield fork(watchOnDeleteAllLinks);
}
