export const truncStringPortion = (
  str: string,
  firstCharCount: number = str.length,
  endCharCount = 0,
  dotCount = 3
): string => {
  let convertedStr = '';
  convertedStr += str.substring(0, firstCharCount);
  convertedStr += '.'.repeat(dotCount);
  convertedStr += str.substring(str.length - endCharCount, str.length);
  return convertedStr;
};
