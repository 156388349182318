import type { HubConnection } from '@microsoft/signalr';
import { HubConnectionBuilder, LogLevel, HttpTransportType, HubConnectionState } from '@microsoft/signalr';
import { getUrlFromSwaggerUrl } from 'app/apis/api.js';
import { connect, useDispatch } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';

const getWebsocketUrl = (): string => `${getUrlFromSwaggerUrl(process.env.REACT_APP_SCENES)}/hubs/audio` || '';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAudioStreamingHub = () => {
  const [connection, setConnection] = useState<HubConnection>(null);

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl(getWebsocketUrl(), {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    connection.keepAliveIntervalInMilliseconds = 1000 * 60 * 20; // 20 minutes
    connection.serverTimeoutInMilliseconds = 1000 * 60 * 20; // 20  minutes
    setConnection(connection);
  }, []);

  const [startConnection, stopConnection] = useMemo(() => {
    const startConnection = async (sessionId: string, callBack: (x: any) => void) => {
      console.log('[STREAMING.AUDIO] startConnection', sessionId);
      if (connection?.state != HubConnectionState.Disconnected) {
        console.log('[STREAMING.AUDIO] connection already started');
      } else {
        await connection.start();
      }
      console.log('[STREAMING.AUDIO] connection started');
      connection.invoke('AddToGroupBySessionId', sessionId);
      connection.on('AudioChunk', callBack);
    };

    const stopConnection = async (sessionId: string) => {
      if (connection == null) return;
      console.log('[STREAMING.AUDIO] stopConnection', sessionId);
      connection.off('AudioChunk');
      if (connection.state === HubConnectionState.Disconnected) return;
      await connection.invoke('RemoveFromGroupBySessionId', sessionId);
      await connection.stop();
    };

    return [startConnection, stopConnection];
  }, [connection]);

  return [connection, startConnection, stopConnection] as const;
};
