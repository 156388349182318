import type { CustomChart } from '../models/scenarios/customChart.js';
import type { RootState } from '../reducers/index.js';
import type { CustomNode } from '../models/intents/customNode.js';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';

export const scenarioChart = (state: RootState): CustomChart => state.scenarioChartReducer;

export const chartNodes = (state: RootState) => state.scenarioChartReducer.nodes;
export const chartLinks = (state: RootState) => state.scenarioChartReducer.links;

export const chartSelected = (state: RootState) => state.scenarioChartReducer.selected;
export const chartHovered = (state: RootState) => state.scenarioChartReducer.hovered;

export const selectedLinkId = (state: RootState): string | undefined =>
  state.scenarioChartReducer.selected.type === 'link' ? state.scenarioChartReducer.selected.id : undefined;
export const selectedNodeId = (state: RootState): string | undefined =>
  state.scenarioChartReducer.selected.type === 'node' ? state.scenarioChartReducer.selected.id : undefined;
export const selectedNode = (state: RootState): CustomNode | undefined =>
  state.scenarioChartReducer.selected.type === 'node'
    ? state.scenarioChartReducer.nodes[state.scenarioChartReducer.selected.id!]
    : undefined;
export const showIntentEdit = (state: RootState): boolean => state.scenarioChartReducer.properties.showIntentEdit;
export const chartScenarioProperties = (state: RootState) => state.scenarioChartReducer.properties;
export const startNodeId = (state: RootState): string | undefined =>
  Object.values(state.scenarioChartReducer.nodes)?.find((node) => node.type === CustomNodeTypeEnum.Start)?.id;

export const isSelectedNodeInitial = (state: RootState) => {
  return selectedNode(state)?.type === CustomNodeTypeEnum.Start;
};
export const lastActionNode = (state: RootState) => {
  const lastActionNodeState = state.scenarioChartReducer as CustomChart & {
    lastActionNode: CustomNode;
  };
  return lastActionNodeState.lastActionNode;
};
