import type { PayloadAction } from '@reduxjs/toolkit';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';
import { DialogProcessorActions } from 'app/actions/dialogprocessor/dialogProcessorActions.js';

import type { ProcessResultDto } from '@/generated-api/index.js';
import type { IDProcessState, IntentProcessingProvider } from 'app/models/dialogs/dialogProcess.js';
//TODO: move this to seperate classes
const initialState: IDProcessState = {
  sessionId: null,
  blocks: { lastBlock: null, currentBlock: null },
  isFallback: false
};

export const dialogProcessorReducer = handleActions<
  IDProcessState,
  string | IntentProcessingProvider | ProcessResultDto | any
>(
  {
    [DialogProcessorActions.Type.SET_STORE_RESPONSE_DATA]: (
      state,
      action: Action<ProcessResultDto>
    ): IDProcessState => {
      const { lastBlock, prevBlock, ...restPorps } = action.payload;
      return {
        ...state,
        sessionId: restPorps.sessionId,
        isFallback: restPorps.isFallback,
        blocks:
          state.blocks.currentBlock === null
            ? {
                ...state.blocks,
                currentBlock: { sceneId: lastBlock?.sceneId, id: lastBlock?.id, ...restPorps } // TODO???
              }
            : {
                lastBlock: prevBlock,
                currentBlock: { sceneId: lastBlock?.sceneId, id: lastBlock?.id, ...restPorps } // TODO ???
              }
      };
    },
    [DialogProcessorActions.Type.RESET_BLOCKS]: (state, action): IDProcessState => {
      return {
        ...state,
        blocks: { lastBlock: null, currentBlock: null },
        isFallback: false
      };
    },
    [DialogProcessorActions.Type.SET_SESSION_ID]: (state, action: PayloadAction<string>): IDProcessState => {
      return { ...state, sessionId: action.payload };
    }
  },
  initialState
);
