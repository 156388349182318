import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Box } from '@material-ui/core';

import { UserSelectors } from 'app/selectors/index.js';
import { UserActions } from 'app/actions/user/userAction.js';
import type { UserDto } from '@/generated-api/index.js';

const GetPermissionForRecords: React.FC = () => {
  const user: UserDto | undefined = useSelector(UserSelectors.CurrentUserSelector);
  const dispatch = useDispatch();
  const [visibility, setVisibility] = useState<boolean>(false);

  useEffect(() => {
    user && setVisibility(!user.isAggreementAccepted);
  }, [user]);

  const handleAccept = useCallback(() => {
    user && dispatch(UserActions.acceptPermissionForRecords(user));
  }, [dispatch, user]);

  const onClose = (e: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
  };

  return (
    <Dialog disableEscapeKeyDown onClose={onClose} aria-labelledby="customized-dialog-title" open={visibility}>
      <DialogTitle id="customized-dialog-title">HiSklls Privacy Policy</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          By accepting, you confirm that you have read and understood the{' '}
          <a href="https://sklls.ai/hisklls-privacy-policy/" target="_blank">
            HiSklls Privacy Policy
          </a>{' '}
          that describes the data we collect and how we use it.
        </Typography>
        <Typography gutterBottom>
          When you use HiSklls to initiate conversations, you are interacting with an AI and not a real person. By
          accepting these terms, you confirm that you have been informed and understand this.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} variant="outlined" color="primary">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GetPermissionForRecords;
