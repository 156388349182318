import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Box, Link, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image.js';
import { Link as RouterLink } from 'react-router-dom';
import AppRoutes from 'app/utils/routes.js';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { indexesSelector } from 'app/store/MedicalIndex/medicalIndexSelectors.js';
import type { MedicalProtocolDto } from '@/generated-api/index.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

export type IndexListProps = {
  searchText: string;
};
function MedIndexList({ searchText }: IndexListProps): JSX.Element {
  const indexes = useSelector(indexesSelector);

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const { id } = useParams<{ id: string }>();
  const [filteredIndexes, setFilteredIndexes] = useState([]);

  const unmountedRef = useUnmounted();

  const debouncedFilter = useRef(
    debounce((searchText: string | number, indexes: MedicalProtocolDto[]) => {
      if (!searchText) {
        if (!unmountedRef.current) {
          setFilteredIndexes(indexes);
        }
      }

      const searchTextLowerCase = searchText.toString().toLocaleLowerCase();

      const filtered = indexes.filter((medIndex) => {
        return (
          medIndex.title.toLocaleLowerCase().includes(searchTextLowerCase) ||
          medIndex.chapters.some(
            (t) =>
              t.title.toLocaleLowerCase().includes(searchTextLowerCase) ||
              t.actions.some((wf) => wf.name.toLocaleLowerCase().includes(searchTextLowerCase))
          )
        );
      });

      if (!unmountedRef.current) {
        setFilteredIndexes(filtered);
      }
    }, 300)
  );

  useEffect(() => {
    debouncedFilter.current(searchText, indexes);
  }, [searchText, indexes]);

  if (!indexes.length) {
    return (
      <Box py={3} display={'flex'} justifyContent={'center'} color={'action.disabled'}>
        <Typography>{translate(nameof.full<I18nProtocolReferencesNs>((n) => n.listIsEmpty))}</Typography>
      </Box>
    );
  }

  if (!filteredIndexes.length) {
    return (
      <Box py={3} display={'flex'} justifyContent={'center'} color={'action.disabled'}>
        <Typography>{translate(nameof.full<I18nProtocolReferencesNs>((n) => n.noEntriesFound))}</Typography>
      </Box>
    );
  }

  return (
    <List>
      {filteredIndexes.map((c) => (
        <ListItem key={c.id} selected={id == String(c.id)}>
          <ListItemAvatar>
            <Avatar variant={'square'}>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Link component={RouterLink} to={`${AppRoutes.MedicalIndexManager}/${c.id}`}>
                {c.title}
              </Link>
            }
          />
        </ListItem>
      ))}
    </List>
  );
}

export default MedIndexList;
