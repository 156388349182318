import { createAction } from 'redux-actions';
import type VoiceRecognitionStatus from '../../models/voiceRecognition/VoiceRecognitionStatus.js';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace VoiceRecognitionActions {
  export const enum Type {
    SET_STORE_IS_SPEAKING = 'SET_STORE_IS_SPEAKING',
    SET_STORE_RECOGNITION_STATUS = 'SET_STORE_RECOGNITION_STATUS',
    SET_IS_PAUSED = 'SET_PAUSE',
    PARSE_LATEST_AUDIO = 'PARSE_LATEST_AUDIO',
    SET_USE_INHOUSE_STT = 'USE_INHOUSE_STT',
    SET_LATEST_INHOUSE_TEXT = 'SET_LATEST_INHOUSE_TEXT',
    SET_USE_FALLBACK_STT = 'USE_FALLBACK_STT',
    SET_FALLBACK_STT_TEXT = 'SET_FALLBACK_STT_TEXT'
  }

  export const setRecognitionStatus = createAction<VoiceRecognitionStatus>(Type.SET_STORE_RECOGNITION_STATUS);
  export const setIsPaused = createAction<boolean>(Type.SET_IS_PAUSED);
  export const parseLatestAudio = createAction<{ audio: Blob | null; speechBase64?: string }>(Type.PARSE_LATEST_AUDIO);
  export const setUseInhouseStt = createAction<boolean>(Type.SET_USE_INHOUSE_STT);
  export const setLatestInhouseText = createAction<string>(Type.SET_LATEST_INHOUSE_TEXT);
  export const setUseFallbackStt = createAction<boolean>(Type.SET_USE_FALLBACK_STT);
  export const setFallbackSttText = createAction<string>(Type.SET_FALLBACK_STT_TEXT);
}
