import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addInfo } from 'app/actions/snackbar/snackbar.js';
import { debounce } from 'lodash';

import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';

import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';

import ScenarioSettingsDialog from './ScenarioSettingsDialog/index.js';
import IntentActionsMenu from './IntentActionsMenu/index.js';
import VoiceRecognition from './ScenarioDisplayComponent/VoiceRecognition/VoiceRecognition/index.js';
import LinkedScenarioTabBarComponent from './LinkedScenarioTabBar/index.js';

import { useBoolState, useUnmounted } from '../../utils/customHooks/index.js';
import { RecognitionSelector, ScenarioSelectors, UserSelectors } from 'app/selectors/index.js';
import useStyles from './styles.js';

import ScenarioCopyDialog from './ScenarioCopyDialog/index.js';

import { TabPanel } from './LinkedScenarioTabBar/utils.js';
import { ScenarioDisplay } from './ScenarioDisplayComponent/index.js';
import BreadCrumbs from './BreadCrumbs/index.js';
import AppRoutes from 'app/utils/routes.js';
import ScenarioActionsSection from './ScenarioActionsSection/index.js';
import ScenarioSummaryDialog from 'app/components/ScenarioEditorPage/ScenarioSummaryDialog/ScenarioSummaryDialog.js';
import ScenarioChangelogDialog from 'app/components/ScenarioEditorPage/ScenarioChangelogDialog/ScenarioChangelogDialog.js';
import { useEditingHub } from './hooks/editingHub.js';
import { isUserAnyAdminSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';

import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import { HubConnectionState } from '@microsoft/signalr';
import { useQuery } from '@tanstack/react-query';
import ScenarioVariablesDialog from './Variables/ScenarioVariablesDialog.js';
import { voicesQuery } from 'app/queries/speechQueries.js';
import { FileCopyOutlined } from '@material-ui/icons';
const { actions: MedicalIndexActions } = medicalIndexSlice;

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

const ScenarioEditorPage = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const scenarioId = Number.parseInt(id, 10);
  const history = useHistory();
  //TODO need to exclude menu and their functions to separate component
  const currentScenario = useSelector(ScenarioSelectors.currentScenario);
  // currently unsued for
  const currentUser = useSelector(UserSelectors.CurrentUserSelector);
  const links = useSelector(ScenarioSelectors.linkedScenarios);
  const value = useSelector(ScenarioSelectors.currentTabSelected);
  const isDialogActive = useSelector(RecognitionSelector.isDialogActive);

  useQuery(voicesQuery);

  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [isSummaryDialogOpen, openSummaryDialog, closeSummaryDialog] = useBoolState(false);
  const [isChangelogOpen, openChangelog, closeChangelog] = useBoolState(false);
  const [settingsOpen, openSettings, closeSettings] = useBoolState();
  const [copyOpen, openCopy, closeCopy] = useBoolState();
  const [variablesOpen, openVariables, closeVariables] = useBoolState(false);
  const [visibilityRemoveScenarioDialog, setVisibilityleRemoveScenarioDialog] = useState<boolean>(false);

  const unmountedRef = useUnmounted();
  const onToggleDialogShow = useCallback(
    debounce(() => {
      if (!unmountedRef.current) {
        setVisibilityleRemoveScenarioDialog((prevValue) => !prevValue);
      }
    }, 500),
    []
  );

  const onDelete = useCallback(() => {
    if (!!currentScenario) {
      onToggleDialogShow();
      dispatch(
        ScenarioActions.deleteScenario(
          { id: currentScenario.sceneId },
          { navigationAction: () => history.push(AppRoutes.Dashboard) }
        )
      );
    }
  }, [currentScenario, dispatch, history, onToggleDialogShow]);

  // this is used to provide no id to form when creation is requested
  const [formScenarioId, setFormScenarioId] = useState<number>();

  const closeForm = () => {
    setTimeout(() => {
      if (!unmountedRef.current) {
        setFormScenarioId(undefined);
      }
    }, 200);
    closeSettings();
  };

  useEffect(() => {
    dispatch(MedicalIndexActions.load());
  }, [dispatch]);

  const [connection, startConnection, stopConnection] = useEditingHub();
  const prevScenarioIdRef = useRef<number>();
  useEffect(() => {
    if (scenarioId == prevScenarioIdRef.current && (!connection || connection.state === HubConnectionState.Connected))
      return;

    const notifyOfSave = (user: any) => {
      if (user.id != currentUser.id) {
        dispatch(
          addInfo({
            infoText: `${user.name} ${translate(
              nameof.full<I18nScenarioEditorNs>((n) => n.scenarioEditorPage.hasMadeChanges)
            )}`
          })
        );
      }
    };
    const prevId = prevScenarioIdRef.current;
    const createNewConnection = async () => {
      if (prevId != null) await stopConnection(`${prevId}`);
      startConnection(`${scenarioId}`, notifyOfSave);
    };
    prevScenarioIdRef.current = scenarioId;
    createNewConnection();
  }, [scenarioId, connection, startConnection, stopConnection, currentUser]);

  useEffect(() => {
    return () => {
      stopConnection(`${scenarioId}`);
    };
  }, [connection, stopConnection]);

  const isAnyAdmin = useSelector(isUserAnyAdminSelector);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);

  const classes = useStyles();
  return (
    <>
      <AppBar className={classes.appBarInner} position="static" elevation={0}>
        <Toolbar variant="dense">
          <div>
            <Grid container alignItems="center" className={classes.root}>
              <ScenarioActionsSection
                onToggleDialogShow={onToggleDialogShow}
                openCopy={openCopy}
                openSettings={openSettings}
                closeSettings={closeSettings}
                setFormScenarioId={setFormScenarioId}
                showSummary={openSummaryDialog}
                showChangelog={openChangelog}
                showVariables={openVariables}
                isSharedScenario={!currentScenario?.companyId}
              />
              {(!currentScenario?.companyId && isSuperAdmin) || (!!currentScenario?.companyId && isAnyAdmin) ? (
                <>
                  <Divider orientation="vertical" flexItem className={classes.dividerVert} />
                  <IntentActionsMenu isDisabled={isDialogActive} />
                </>
              ) : null}
            </Grid>
          </div>
          {!currentScenario?.companyId && isAnyAdmin && !isSuperAdmin ? (
            <div>
              <Grid container alignItems="center" className={classes.root}>
                <Button
                  disabled={isDialogActive}
                  startIcon={<FileCopyOutlined className={classes.actionDefault} />}
                  onClick={openCopy}
                >
                  <Typography className={classes.transformNone}>
                    {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioEditorPage.copy))}
                  </Typography>
                </Button>
              </Grid>
            </div>
          ) : null}

          <Typography variant="h6" className={classes.title}>
            <BreadCrumbs />
          </Typography>
          <VoiceRecognition />
        </Toolbar>
      </AppBar>
      <LinkedScenarioTabBarComponent isDisabled={isDialogActive} scenarioId={scenarioId} />
      {links.map((x, i) => {
        return (
          <TabPanel value={value} key={i} index={i}>
            {currentScenario && <ScenarioDisplay />}
          </TabPanel>
        );
      })}
      <Dialog
        open={visibilityRemoveScenarioDialog}
        keepMounted
        onClose={onToggleDialogShow}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" data-cy="acceptActionForm" />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioEditorPage.confirmationText))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" size="small" onClick={onToggleDialogShow}>
            {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
          </Button>
          <Button variant="outlined" color="primary" size="small" data-cy="acceptDeleteButton" onClick={onDelete}>
            {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
          </Button>
        </DialogActions>
      </Dialog>
      <ScenarioSettingsDialog open={settingsOpen} onClose={closeForm} scenarioId={formScenarioId} />
      <ScenarioCopyDialog open={copyOpen} onClose={closeCopy} />
      <ScenarioSummaryDialog open={isSummaryDialogOpen} onClose={closeSummaryDialog} />
      <ScenarioChangelogDialog open={isChangelogOpen} onClose={closeChangelog} scenarioId={scenarioId} />
      <ScenarioVariablesDialog open={variablesOpen} onClose={closeVariables} scenarioId={scenarioId} />
    </>
  );
};

export default ScenarioEditorPage;
