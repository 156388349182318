import { makeStyles } from '@material-ui/core/styles/index.js';

export default makeStyles({
  common: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    bottom: 32,
    '& > div': {
      margin: '3px 0'
    }
  },
  customPortGroupLeft: {
    left: -16
  },
  customPortGroupRight: {
    right: -16
  },
  customPortGroupLinkedScenario: {
    right: -16,
    bottom: 0
  }
});
