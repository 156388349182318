import _ from 'lodash';
import React, { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles/index.js';
import { Tab, Tabs, Button, DialogContent, DialogActions, DialogTitle, Dialog } from '@material-ui/core';

import type { ArrayElementType } from 'app/types/UtilityType.js';
import TabPanel from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TabPanel.js';
import useScenarioForm from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';
import type { ScenarioSettingsComponentProps } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/types.js';
import ScenarioContextSettings from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/ScenarioContextSettings.js';
import { ScenarioGeneralSettings } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/ScenarioGeneralSettings/index.js';
import TransitionWeightsTab from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TransitionWeights/TransitionWeightsTab.js';
import ScenarioIntroAudioSettingsTab from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/ScenarioIntroAudioSettings/ScenarioIntroAudioSettingsTab.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';



const useStyles = makeStyles(() => {
  return {
    actions: {
      marginTop: 'auto'
    }
  };
});


const ScenarioSettingsDialog: React.FC<ScenarioSettingsComponentProps> = (props) => {
  const { open, onClose, scenarioId } = props;
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const tabs = [
    translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSettingsDialog.general)),
    translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSettingsDialog.aiContext)),
    translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSettingsDialog.transitionWeights)),
    translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSettingsDialog.introAudio))
  ] as const;
  type TabsType = ArrayElementType<typeof tabs>;
  const defaultTab = tabs[0];

  const [activeTab, setActiveTab] = useState<TabsType>(defaultTab);
  const { methods, onSubmit } = useScenarioForm(onClose, scenarioId);

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      data-cy="scenarioSettingForm"
      maxWidth="md"
      fullWidth
      TransitionProps={{
        onExited: () => setActiveTab(defaultTab)
      }}
    >
      <DialogTitle id="form-dialog-title">
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSettingsDialog.scenarioSettings))}
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit} autoComplete="off" id="scenario-settings">
            <Tabs
              value={activeTab}
              onChange={(_event, value) => {
                setActiveTab(value);
              }}
            >
              <Tab label={tabs[0]} data-cy="transitionWeightTab" value={tabs[0]} />
              <Tab label={tabs[1]} value={tabs[1]} />
              <Tab label={tabs[2]} value={tabs[2]} />
              <Tab label={tabs[3]} value={tabs[3]} />
            </Tabs>

            <TabPanel index={tabs[0]} value={activeTab}>
              <ScenarioGeneralSettings onClose={onClose} scenarioId={scenarioId} />
            </TabPanel>

            <TabPanel index={tabs[1]} value={activeTab}>
              <ScenarioContextSettings scenarioId={scenarioId} />
            </TabPanel>

            <TransitionWeightsTab index={tabs[2]} value={activeTab} />

            <ScenarioIntroAudioSettingsTab index={tabs[3]} value={activeTab} />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button size="medium" onClick={onClose}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <Button
          variant="contained"
          data-cy="submitCreatedScenarioButton"
          color="primary"
          disabled={!_.isEmpty(methods.errors)}
          type="submit"
          form="scenario-settings"
        >
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioSettingsDialog.save))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScenarioSettingsDialog;
