import type { SceneDialogStatisticDto, SceneGroupDto } from '@/generated-api/index.js';
import type { UseQueryOptions } from '@tanstack/react-query';
import { ScenarioClient, SceneClient } from 'app/apis/api.js';

export const getDialogStatisticScenarioVersionQuery = (
  scenarioId: number | undefined,
  versionId: number | undefined
): UseQueryOptions<SceneDialogStatisticDto> => ({
  queryKey: ['scenarioVersionQuery', scenarioId, versionId],
  queryFn: async () => {
    const { data } = await ScenarioClient.scenariosGetDialogStatisticScenarioVersion(scenarioId, versionId);
    return data;
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  keepPreviousData: true, //???
  enabled: !!scenarioId && !!versionId
});

export const getScenarioGroupsQuery: UseQueryOptions<SceneGroupDto[]> = {
  queryKey: ['scenarioGroups'],
  queryFn: async () => {
    const { data } = await SceneClient.sceneGetSceneGroups();
    return data;
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  keepPreviousData: true
};
