/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react';
import { Box, Button, Grid, Paper, TextField } from '@material-ui/core';
import { GridToolbarExport } from '@material-ui/data-grid';

import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import useTableData from 'app/components/UsersPage/UserTable/hooks/useTableData.js';
import useStyles from 'app/components/DashboardPage/Table/components/kpiFiltersStyle.js';
import useDebouncedValue from 'app/hooks/useDebouncedValue.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';

type UsersTableProps = {
  onDelete: (id: number) => void;
  onSelect: (id: number) => void;
  openUserInviteDialog: () => void;
};

const RowsPerPage = 25;

const UsersTable: React.FC<UsersTableProps> = ({ onSelect, onDelete, openUserInviteDialog }) => {
  const { rows, columns, loading } = useTableData({ onEdit: onSelect, onDelete });
  const [search, setSearch] = useState<string>('');
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const searchDebounced = useDebouncedValue(search, 400);

  // TODO Move search to backend? (and add pagination?)

  const rowsFiltered = useMemo(() => {
    if (!searchDebounced) return rows;

    // Filter on name/email/organization
    return rows.filter((row) => {
      const searchLower = searchDebounced.toLowerCase();
      const name = row.name?.toLowerCase();
      const email = row.email?.toLowerCase();
      const companyName = row.companyName?.toLowerCase();

      if (searchLower === name || searchLower === email || searchLower === companyName) {
        return true;
      }

      if (name?.includes(searchLower) || email?.includes(searchLower) || companyName?.includes(searchLower)) {
        return true;
      }

      return false;
    });
  }, [searchDebounced, rows]);

  return (
    <Box>
      <Grid container justifyContent="flex-end" alignItems="center" className={classes.container}>
        <Grid item>
          <TextField
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            placeholder={translate(nameof.full<I18nUserManagementNs>((n) => n.search))}
            variant="outlined"
            inputProps={{
              className: classes.searchInput
            }}
          />
        </Grid>
      </Grid>
      <SimpleDataGrid
        autoHeight
        rows={rowsFiltered}
        loading={loading}
        columns={columns}
        pageSize={RowsPerPage}
        rowsPerPageOptions={[RowsPerPage]}
        onRowClick={(value) => onSelect(value.id as number)}
        components={{
          Toolbar: () => (
            <Box padding={1}>
              <Grid container justifyContent="space-between" wrap="nowrap">
                <GridToolbarExport />
                <Button variant="contained" color="primary" onClick={openUserInviteDialog}>
                  {translate(nameof.full<I18nUserManagementNs>((n) => n.inviteUser))}
                </Button>
              </Grid>
            </Box>
          )
        }}
      />
    </Box>
  );
};

export default UsersTable;
