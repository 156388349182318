/* eslint-disable @typescript-eslint/no-namespace */
import { createAction } from 'redux-actions';
import type { ILink, IPosition, ISelectedOrHovered } from '@mrblenny/react-flow-chart';
import type { CustomNode } from '../../models/intents/customNode.js';
import type { CustomChart } from '../../models/scenarios/customChart.js';

export namespace ScenarioChartStoreActions {
  export const enum Type {
    INIT_SCENARIO_CHART = 'INIT_SCENARIO_CHART',
    SET_SC_NODES = 'SET_SC_NODES',
    SET_SC_NODE = 'SET_SC_NODE',
    SET_SC_NODE_PROPERTY = 'SET_SC_NODE_PROPERTY',
    SET_SC_LINKS = 'SET_SC_LINKS',
    SET_SC_LINK = 'SET_SC_LINK',
    SET_SC_OFFSET = 'SET_SC_OFFSET',
    SET_SC_ZOOM = 'SET_SC_ZOOM',
    SET_SC_SELECTED = 'SET_SC_SELECTED',
    SET_SC_HOVERED = 'SET_SC_HOVERED',
    SHOW_INTENT_EDIT = 'SHOW_INTENT_EDIT',
    SET_SC_NODE_ADVANCED = 'SET_SC_NODE_ADVANCED',
    RESET_IS_DIALOG_PROCESS_ON_THE_NODE = 'RESET_IS_DIALOG_PROCESS_ON_THE_NODE'
  }

  export const initChart = createAction<CustomChart>(Type.INIT_SCENARIO_CHART);
  export const setNodes = createAction<Record<string, CustomNode>>(Type.SET_SC_NODES);
  export const setNode = createAction<CustomNode>(Type.SET_SC_NODE);
  export const setNodeProperty = createAction<{
    id: number;
    propertyName: string;
    propertyValue: any;
    isUnique?: boolean;
  }>(Type.SET_SC_NODE_PROPERTY);
  export const resetIsDialogProcessOnTheNode = createAction(Type.RESET_IS_DIALOG_PROCESS_ON_THE_NODE);
  export const setLinks = createAction<Record<string, ILink>>(Type.SET_SC_LINKS);
  export const setLink = createAction<ILink>(Type.SET_SC_LINK);
  export const setOffset = createAction<IPosition>(Type.SET_SC_OFFSET);
  export const setZoom = createAction<Pick<CustomChart, 'offset' | 'scale'>>(Type.SET_SC_ZOOM);
  export const setSelected = createAction<ISelectedOrHovered>(Type.SET_SC_SELECTED);
  export const setHovered = createAction<ISelectedOrHovered>(Type.SET_SC_HOVERED);
  export const onShowIntentEdit = createAction<boolean>(Type.SHOW_INTENT_EDIT);

  export type SetNodeAdvancedPayload = {
    id: CustomNode['id'];
    node: React.SetStateAction<CustomNode>;
  };

  export const setNodeAdvanced = (payload: SetNodeAdvancedPayload) => {
    return {
      type: Type.SET_SC_NODE_ADVANCED,
      payload: payload
    };
  };
}
