import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import {
  MenuItem,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  ListItemText
} from '@material-ui/core';

import type {
  CourseDto,
  ScheduledCourseDto,
  ScheduledCourseAssignmentCommand,
  UserDto
} from '@/generated-api/index.js';

import { LoadingStatus } from 'app/store/types.js';
import LoadingButton from 'app/components/LoadingButton.js';
import * as validationSchemas from 'app/components/CourseManagement/CourseCard/validationSchema.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

export type CreateScheduledCourseUserDialogProps = {
  course: CourseDto;
  isOpen: boolean;
  courseId: string;
  onClose: () => void;
  scheduledCourse: ScheduledCourseDto;
};

export const NO_USER_SELECTED = '';

const CreateScheduledCourseUserDialog = ({
  course,
  isOpen,
  onClose,
  scheduledCourse
}: CreateScheduledCourseUserDialogProps): JSX.Element => {
  const dispatch = useDispatch();

  const [users, setUsers] = useState<UserDto[]>(null);

  const { control, handleSubmit, reset, formState } = useForm<ScheduledCourseAssignmentCommand>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchemas.createScheduledCourseUser),
    defaultValues: {
      id: NO_USER_SELECTED
    }
  });

  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const requestAvailableUsers = useCallback(
    () =>
      dispatch(CourseManagementActions.requestScheduledCourseAvailableUsers(course.id, scheduledCourse.id, setUsers)),
    [course, scheduledCourse, dispatch]
  );

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) =>
        dispatch(CourseManagementActions.createScheduledCourseUser(course.id, scheduledCourse.id, data, onClose))
      ),
    [course, scheduledCourse, dispatch, handleSubmit, onClose]
  );

  const onExited = useCallback(() => {
    reset();
    setUsers(null);
  }, [reset]);

  const loadingStatus = useSelector(CourseManagementSelectors.createScheduledCourseUserLoadingStatus);

  const isUsersReceived = !!users;
  const isUsersEmpty = isUsersReceived && !users.length;

  return (
    <Dialog
      fullWidth
      open={isOpen}
      maxWidth="sm"
      onClose={onClose}
      TransitionProps={{
        onExited,
        onEnter: () => requestAvailableUsers()
      }}
    >
      <DialogTitle>{translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.addUser))}</DialogTitle>
      <DialogContent>
        <form id="create-scheduled-course-user-form" onSubmit={onSubmit}>
          <Controller
            select
            required
            fullWidth
            type="text"
            as={TextField}
            margin="normal"
            control={control}
            label={translate(nameof.full<I18nCourseManagementNs>((n) => n.addUserForm.selectUser))}
            disabled={!isUsersReceived || isUsersEmpty}
            name={nameof.full<ScheduledCourseAssignmentCommand>((c) => c.id)}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
          >
            {isUsersReceived && isUsersEmpty && (
              <MenuItem value={NO_USER_SELECTED}>
                {translate(nameof.full<I18nCourseManagementNs>((n) => n.addUserForm.noUsersAvailable))}
              </MenuItem>
            )}
            {isUsersReceived &&
              users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  <ListItemText
                    style={{ margin: 0 }}
                    primary={user.name}
                    secondary={user.name !== user.email ? user.email : ''}
                  />
                </MenuItem>
              ))}
          </Controller>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loadingStatus === LoadingStatus.Loading}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={!formState.isValid}
          loadingStatus={loadingStatus}
          form="create-scheduled-course-user-form"
        >
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.add))}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateScheduledCourseUserDialog;
