import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { SpeechClient } from 'app/apis/api.js';
import type { SceneDto, SynthesizeSpeechCommand } from '@/generated-api/index.js';
import { Emotion } from '@/generated-api/index.js';

import AudioActions from '../../../actions/audio/audioAction.js';
import type { AxiosResponse } from 'axios';
import { DialogProcessorSelector, ScenarioSelectors } from 'app/selectors/index.js';

import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';
import { VoiceRecognitionActions } from 'app/actions/voiceRecognition/voiceRecognitionActions.js';
import type { IDProcessState } from 'app/models/dialogs/dialogProcess.js';
import { LinkedScenario } from 'app/models/intents/intentNodeProperties.js';
import logger from 'app/utils/logger.js';

function* getAudio() {
  const rootScenario: SceneDto = yield select(ScenarioSelectors.rootScenario);
  const dialogProcessorData: IDProcessState = yield select(DialogProcessorSelector.dialogProcessorSelector);

  try {
    const text: string = dialogProcessorData.blocks.currentBlock.text;

    if (!text) {
      throw new Error('No AI response for Text to Speech!');
    }

    const payload: SynthesizeSpeechCommand = {
      language: rootScenario.langCode,
      sex: rootScenario.sex,
      service: rootScenario.ttsProvider as number,
      voiceName: rootScenario.voiceName,
      text: text,
      emotion: dialogProcessorData.blocks.currentBlock.emotion,
      intentId: dialogProcessorData.blocks.currentBlock.id
    };

    const response: AxiosResponse<string> = yield call([SpeechClient, SpeechClient.speechSynthesizeSpeech], payload);

    yield put(AudioActions.setAudioAIResponse(response.data));
    yield put(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.AudioIsPlaying));
  } catch (ex) {
    logger.log(ex);
    yield put(VoiceRecognitionActions.setRecognitionStatus(VoiceRecognitionStatus.AudioWasEnded));
  }
}

function* watchGetAudio() {
  yield takeEvery(AudioActions.Type.GET_AUDIO, getAudio);
}

export default function* watchAllAudiosSaga() {
  yield fork(watchGetAudio);
}
