import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  CircularProgress
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import type { CreateMedicalProtocolCommand } from '@/generated-api/index.js';
import { yupResolver } from '@hookform/resolvers/yup';

import { useUnmounted } from 'app/utils/customHooks/index.js';
import { MedicalIndexClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppRoutes from 'app/utils/routes.js';
import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import schema from './schema.js';
import { isEmpty } from 'lodash';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { CompanyManagementActions } from 'app/actions/companyManagment/companyManagementActions.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

const { actions } = medicalIndexSlice;

export type CreateMedIndexDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

function CreateMedIndexDialog({ isOpen, onClose }: CreateMedIndexDialogProps): JSX.Element {
  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const { control, handleSubmit, errors } = useForm<CreateMedicalProtocolCommand>({
    resolver: yupResolver(schema),
    defaultValues: {
      title: translate(nameof.full<I18nProtocolReferencesNs>((n) => n.createReferenceForm.newProtocolReference))
    }
  });

  const [submitState, setSubmitState] = useState<{
    data: CreateMedicalProtocolCommand;
    status: ProcessStatus;
  }>({
    status: ProcessStatus.Idle,
    data: {} as CreateMedicalProtocolCommand
  });

  const dispatch = useDispatch();
  const unmountedRef = useUnmounted();
  const history = useHistory();

  useEffect(() => {
    if (submitState.status !== ProcessStatus.StartProcess) {
      return;
    }

    const submitData = async () => {
      try {
        setSubmitState((s) => ({
          ...s,
          status: ProcessStatus.Processing
        }));

        const { data: medIndex } = await MedicalIndexClient.medicalIndexAddMedicalIndex(submitState.data);

        dispatch(actions.indexCreated(medIndex));
        dispatch(CompanyManagementActions.getCompanies());
        history.push(`${AppRoutes.MedicalIndexManager}/${medIndex.id}`);

        onClose();
      } catch (e) {
        logger.error(e);
      } finally {
        if (!unmountedRef.current) {
          setSubmitState((s) => ({
            ...s,
            status: ProcessStatus.Idle
          }));
        }
      }
    };

    submitData();
  }, [dispatch, history, onClose, submitState, unmountedRef]);

  const onSubmit = handleSubmit((formData) => {
    setSubmitState({
      status: ProcessStatus.StartProcess,
      data: {
        ...formData
      }
    });
  });

  return (
    <Dialog open={isOpen} maxWidth={'sm'} fullWidth>
      <DialogTitle>
        {translate(nameof.full<I18nProtocolReferencesNs>((n) => n.createReferenceForm.createProtocolReference))}
      </DialogTitle>
      <DialogContent>
        <Box overflow={'hidden'} px={2} pt={2} pb={6}>
          <form id="create-medical-index-form" onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      fullWidth
                      label={translate(
                        nameof.full<I18nProtocolReferencesNs>((n) => n.createReferenceForm.protocolReferenceTitle)
                      )}
                      {...props}
                      inputRef={props.ref}
                      error={!!errors.title}
                      helperText={errors.title?.message}
                    />
                  )}
                  control={control}
                  name={nameof.full<CreateMedicalProtocolCommand>((c) => c.title)}
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color={'primary'}
          form="create-medical-index-form"
          type={'submit'}
          disabled={submitState.status === ProcessStatus.Processing || !isEmpty(errors)}
        >
          {submitState.status === ProcessStatus.Processing ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translate(nameof.full<I18nProtocolReferencesNs>((n) => n.create))
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateMedIndexDialog;
