import React, { PropsWithChildren } from 'react';
import { Box, Grid, List, Typography } from '@material-ui/core/';
import { DialogStatisticKpiDetailsDto, ReachTimeScoreDto } from '@/generated-api';
import { makeStyles } from '@material-ui/styles';

import KPIDetailsScore from 'app/components/DashboardPage/KPIDetails/KPIDetailsScore';
import KPIDetailsListItem from 'app/components/DashboardPage/KPIDetails/KPIDetailsListItem';
import useKPIDetailsScore from 'app/components/DashboardPage/KPIDetails/useKPIDetailsScore';
import KPIDetailsScoreIcon from 'app/components/DashboardPage/KPIDetails/KPIDetailsScoreIcon';

type ReachTimeScoresProps = {
  kpiDetails: DialogStatisticKpiDetailsDto;
};

type ReachTimeScoreProps = {
  reachTimeScore: ReachTimeScoreDto;
};

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '250px', // Adjust this value according to your header and other components occupying space.
    overflow: 'auto'
  },
}));

const ReachTimeScore: React.FC<ReachTimeScoreProps> = ({ reachTimeScore }) => {
  const { color } = useKPIDetailsScore(reachTimeScore.score);

  return (
    <Typography
      noWrap
      variant="body2"
      style={{
        color
      }}
    >
      {`${reachTimeScore.reachTimeSeconds}s / ${reachTimeScore.targetTimeSeconds}s`}
    </Typography>
  );
};

const ReachTimeScores: React.FC<PropsWithChildren<ReachTimeScoresProps>> = ({ kpiDetails }) => {
  if (!kpiDetails) return null;

  const classes = useStyles();

  return (
    <List className={classes.root}>
      {kpiDetails.reachTimeScores.map((reachTimeScore, index) => {
        return (
          <KPIDetailsListItem key={index}>
            <Grid item container alignItems="center" wrap="nowrap" xs={12} sm={6}>
              <Grid item xs>
                <Typography noWrap variant="body2">
                  {`${reachTimeScore.intentNumber}: ${reachTimeScore.toIntentName}`}
                </Typography>
              </Grid>
              <Grid item component={Box} paddingLeft={1} paddingRight={1}>
                <ReachTimeScore reachTimeScore={reachTimeScore} />
              </Grid>
              <KPIDetailsScoreIcon score={reachTimeScore.score} />
            </Grid>
            <KPIDetailsScore score={reachTimeScore.score} />
          </KPIDetailsListItem>
        );
      })}
    </List>
  );
};

export default ReachTimeScores;
