import type { SceneDto, SceneExtendedDto } from '@/generated-api/index.js';

export const mapFromSceneExtendedToScenario = (sceneExtended: SceneExtendedDto[]): SceneDto[] => {
  return sceneExtended.map<SceneDto>((x) => {
    return {
      ...x,
      id: x.id,
      name: x.name,
      sex: x.sex as number,
      voiceName: x.voiceName,
      langCode: x.langCode,
      ttsProvider: x.ttsProvider,
      startBlockId: x.startBlockId,
      medicalProtocolChapters: x.medicalProtocolChapters,
      companyId: x.companyId,
      transitionScoresSettings: x.transitionScoresSettings,
      scenarioContextIntro: x.scenarioContextIntro,
      playScenarioContextOn: x.playScenarioContextOn,
      llm: x.llm,
      useStreaming: x.useStreaming,
      maxTokens: x.maxTokens
    };
  });
};
