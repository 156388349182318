import React from 'react';
import range from 'lodash/range.js';
import { Skeleton } from '@material-ui/lab';

import { LoadingStatus } from 'app/store/types.js';
import { Grid } from '@material-ui/core';

const SkeletonWrapper: React.FC<{ count?: number; height?: number; loadingStatus: LoadingStatus }> = ({
  count = 3,
  height = 72,
  children,
  loadingStatus
}) => {
  const isLoading = loadingStatus === LoadingStatus.Loading;

  if (!isLoading) return <>{children}</>;

  return (
    <Grid container direction="column">
      {range(0, count).map((index) => (
        <Skeleton key={index} height={height} />
      ))}
    </Grid>
  );
};

export default SkeletonWrapper;
