/* eslint-disable react/display-name */
import React from 'react';
import { useSelector } from 'react-redux';
import { UserManagementSelectors } from 'app/selectors/index.js';

import Actions from './Actions.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';

type useTableDataProps = {
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
};

const useTableData = ({ onEdit, onDelete }: useTableDataProps) => {
  const users = useSelector(UserManagementSelectors.users);
  const loading = !useSelector(UserManagementSelectors.usersLoaded);
  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const columns = [
    {
      field: 'name',
      headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.userTable.name)),
      flex: 1
    },
    {
      field: 'email',
      headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.userTable.email)),
      flex: 1
    },
    {
      field: 'roleName',
      headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.userTable.role)),
      flex: 1
    },
    {
      field: 'companyName',
      headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.userTable.organization)),
      flex: 1
    },
    {
      field: 'departmentName',
      headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.userTable.department)),
      flex: 1
    },
    {
      field: 'actions',
      headerName: translate(nameof.full<I18nUserManagementNs>((n) => n.userTable.actions)),
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (data) => <Actions user={data.row} onEdit={onEdit} onDelete={onDelete} />
    }
  ];

  return { columns, rows: users, loading };
};

export default useTableData;
