import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress
} from '@material-ui/core';
import type { MedicalProtocolChapterDto, CreateActionCommand, MedicalProtocolDto } from '@/generated-api/index.js';
import { Controller, useForm } from 'react-hook-form';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { MedicalIndexClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import schema from './schema.js';
import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import logger from 'app/utils/logger.js';

export type AddActionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  medIndexId: MedicalProtocolDto['id'];
  chapterId: MedicalProtocolChapterDto['id'];
};

type SubmitState = CreateActionCommand & {
  status: ProcessStatus;
};

const { actions } = medicalIndexSlice;

function AddActionDialog({ isOpen, onClose, medIndexId, chapterId }: AddActionDialogProps): JSX.Element {
  const { control, handleSubmit, errors } = useForm<CreateActionCommand>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      title: ''
    }
  });

  const [submitState, setSubmitState] = useState<SubmitState>({
    status: ProcessStatus.Idle,
    title: null
  });

  const onSubmit = handleSubmit((data) => {
    if (submitState.status !== ProcessStatus.Idle) {
      return;
    }

    setSubmitState({
      ...data,
      status: ProcessStatus.StartProcess
    });
  });

  const unmountedRef = useUnmounted();

  const dispatch = useDispatch();

  useEffect(() => {
    if (submitState.status !== ProcessStatus.StartProcess) {
      return;
    }

    setSubmitState((s) => ({ ...s, status: ProcessStatus.Processing }));

    const process = async () => {
      try {
        const { data: updatedMedIndex } = await MedicalIndexClient.medicalIndexCreateAction(medIndexId, chapterId, {
          title: submitState.title
        });

        dispatch(actions.indexUpdated(updatedMedIndex));

        if (!unmountedRef.current) {
          setSubmitState({ title: null, status: ProcessStatus.Idle });
          onClose();
        }
      } catch (e) {
        logger.error(e);

        if (!unmountedRef.current) {
          setSubmitState({ title: null, status: ProcessStatus.Idle });
        }
      }
    };

    process();
  }, [dispatch, submitState, chapterId, unmountedRef, onClose, medIndexId]);

  const processing = submitState.status !== ProcessStatus.Idle;

  const error = errors['title'];

  return (
    <Dialog open={isOpen} maxWidth={'xs'} fullWidth>
      <form id="create-action-form" onSubmit={onSubmit}>
        <DialogTitle>Add Action</DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="title"
            render={(props) => (
              <TextField
                {...props}
                autoFocus
                error={!!error}
                helperText={error?.message}
                label="Action Text"
                placeholder="Enter Action Text"
                fullWidth
                multiline
                maxRows={5}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="primary" form="create-action-form" type="submit" disabled={processing}>
            {processing ? <CircularProgress color="secondary" size={24} /> : 'Add Action'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddActionDialog;
