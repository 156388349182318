import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';

const drawerWidth = 300;

export default makeStyles((theme: Theme) => {
  return createStyles({
    containerWrapper: {
      height: `calc(100vh - ${theme.mixins.denseToolbar.minHeight}px)`
    },
    container: {
      height: '100%',
      overflow: 'auto',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      willChange: 'margin',
      marginLeft: drawerWidth
    },
    container_sidebar_closed: {
      marginLeft: 0
    }
  });
});
