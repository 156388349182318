import type { MedicalProtocolChapterDto, MedicalProtocolDto, MedicalProtocolActionDto } from '@/generated-api/index.js';
import React, { useMemo, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Collapse, IconButton, Typography } from '@material-ui/core';
import EditableChapterTitle from 'app/components/MedicalIndexesManager/MedIndexCard/ChapterList/ChapterItem/EditableChapterTitle.js';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles/index.js';
import ActionItem from './ActionItem/ActionItem.js';

const useChapterItemStyles = makeStyles((theme) => {
  return {
    root: {
      margin: theme.spacing(2, 0)
    },
    expand: {
      transform: (props: { isExpanded: boolean }) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'),
      marginRight: theme.spacing(1),
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandCaption: {
      cursor: 'pointer'
    }
  };
});

export type ChapterItemProps = {
  chapter: MedicalProtocolChapterDto;
  medIndexId: MedicalProtocolDto['id'];
  onDeleteChapter: () => void;
  onDeleteAction: (action: MedicalProtocolActionDto) => void;
  onAddAction: () => void;
};

function ChapterItem({ chapter, medIndexId, onDeleteChapter, onDeleteAction, onAddAction }: ChapterItemProps) {
  const countToDisplayOnCollapsed = 3;

  const [isExpanded, setIsExpanded] = useState(false);

  const allActions = useMemo(() => chapter.actions, [chapter.actions]);

  const expandable = allActions.length > countToDisplayOnCollapsed;

  const [topEntries, collapsibleEntries] = useMemo(() => {
    return expandable
      ? [allActions.slice(0, countToDisplayOnCollapsed), allActions.slice(countToDisplayOnCollapsed)]
      : [allActions, []];
  }, [expandable, allActions, countToDisplayOnCollapsed]);

  const classes = useChapterItemStyles({ isExpanded });

  const toggleExpand = () => setIsExpanded((e) => !e);

  const isEmpty = !chapter.actions.length;

  return (
    <Card
      classes={{
        root: classes.root
      }}
    >
      <CardContent>
        <Box mb={1}>
          <EditableChapterTitle medIndexId={medIndexId} chapter={chapter} />
        </Box>
        {isEmpty && (
          <Box color={'action.disabled'} mt={3} mb={1} textAlign={'center'}>
            <Typography>{`There's no actions added yet.`}</Typography>
          </Box>
        )}
        {topEntries.map((action) => (
          <ActionItem
            key={action.id}
            action={action}
            medIndexId={medIndexId}
            chapterId={chapter.id}
            onDelete={() => onDeleteAction(action)}
          />
        ))}
        {expandable && !isExpanded && (
          <Box py={1} onClick={toggleExpand} className={classes.expandCaption}>
            {`...and ${allActions.length - countToDisplayOnCollapsed} more`}
          </Box>
        )}
        {expandable && (
          <Collapse in={isExpanded} unmountOnExit>
            {collapsibleEntries.map((action) => (
              <ActionItem
                key={action.id}
                action={action}
                medIndexId={medIndexId}
                chapterId={chapter.id}
                onDelete={() => onDeleteAction(action)}
              />
            ))}
          </Collapse>
        )}
      </CardContent>

      <CardActions>
        <Box display="flex" justifyContent={'space-between'} width="100%" alignItems="center" px={1}>
          <Box display="flex">
            <Button onClick={onAddAction}>Add Action</Button>
            <Box ml={1}>
              <Button onClick={onDeleteChapter}>Delete Chapter</Button>
            </Box>
          </Box>
          {expandable && (
            <IconButton
              className={classes.expand}
              onClick={toggleExpand}
              aria-expanded={isExpanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          )}
        </Box>
      </CardActions>
    </Card>
  );
}

export default ChapterItem;
