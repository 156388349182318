import type { VariableDto } from '@/generated-api/index.js';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import { useVariableMutations } from 'app/mutations/variableMutations.js';
import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type AddVariableDialogProps = {
  open: boolean;
  onClose: () => void;
  afterAdd?: () => void;
  variables: VariableDto[];
  scenarioId: number;
  controlledVariable?: VariableDto;
  multiple?: boolean;
  controlledVariables?: string[];
  disableClose?: boolean;
};

const AddVariableDialog = (props: AddVariableDialogProps) => {
  const {
    open,
    onClose,
    variables,
    scenarioId,
    controlledVariable,
    controlledVariables,
    multiple,
    disableClose,
    afterAdd
  } = props;
  const [key, setKey] = React.useState('');
  const [value, setValue] = React.useState('');
  const [keyValues, setKeyValues] = React.useState<{ key: string; value: string }[]>([]);
  const [isKeyValid, setIsKeyValid] = React.useState(true);

  const { addVariable, addVariables } = useVariableMutations(scenarioId);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  useEffect(() => {
    if (key?.includes(' ')) setIsKeyValid(false);
    else if (variables.find((v) => v.name === key)) setIsKeyValid(false);
    else setIsKeyValid(true);
  }, [key, variables]);

  // Reset state on open
  useEffect(() => {
    if (open) {
      setKey('');
      setValue('');
      setKeyValues([]);
      setIsKeyValid(true);
      if (!multiple && controlledVariable) {
        setKey(controlledVariable.name);
        setValue(controlledVariable.value);
      } else if (multiple && controlledVariables) {
        setKeyValues(controlledVariables.map((v) => ({ key: v, value: '' })));
      }
    }
  }, [open, controlledVariable, multiple, controlledVariables]);

  const handleAddVariable = async () => {
    if (multiple) {
      await addVariables.mutateAsync(
        keyValues.map((pair) => ({ name: pair.key, value: pair.value })),
        { onSuccess: afterAdd ? afterAdd : onClose }
      );
    } else {
      await addVariable.mutateAsync({ name: key, value: value }, { onSuccess: afterAdd ? afterAdd : onClose });
    }
  };

  return (
    <Dialog open={open} onClose={disableClose ? undefined : onClose} disableEscapeKeyDown={disableClose}>
      <DialogTitle>
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.addVariableDialog.addVariable))}
        {multiple ? 's' : ''}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.addVariableDialog.example))}:{' '}
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.addVariableDialog.name))}: &quot;location&quot;,{' '}
          {translate(nameof.full<I18nScenarioEditorNs>((n) => n.addVariableDialog.value))}: &quot;Oslo&quot;
        </DialogContentText>
        {multiple ? (
          <Grid container>
            {keyValues.map((pair, index) => (
              <Grid item container xs={12} key={pair.key}>
                <Grid item>
                  <TextField
                    autoFocus
                    margin="dense"
                    label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.addVariableDialog.variableName))}
                    disabled={!!controlledVariables}
                    value={pair.key}
                    fullWidth
                  />
                </Grid>
                <Grid>
                  <TextField
                    margin="dense"
                    label="Value"
                    value={pair.value}
                    onChange={(e) => {
                      const copy = [...keyValues];
                      copy[index].value = e.target.value;
                      setKeyValues(copy);
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        ) : (
          <>
            <TextField
              autoFocus
              margin="dense"
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.addVariableDialog.variableName))}
              value={key}
              disabled={!!controlledVariable}
              onChange={(e) => setKey(e.target.value)}
              error={!isKeyValid}
              helperText={
                !isKeyValid
                  ? translate(
                      nameof.full<I18nScenarioEditorNs>((n) => n.addVariableDialog.variableNameCantContainSpaces)
                    )
                  : ''
              }
              fullWidth
            />
            <TextField
              margin="dense"
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.addVariableDialog.value))}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              fullWidth
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={disableClose}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <Button onClick={handleAddVariable} color="primary" disabled={!isKeyValid}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.add))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVariableDialog;
