import React, { useEffect } from 'react';
import type {
  MedicalProtocolActionDto,
  MedicalProtocolChapterDto,
  MedicalProtocolDto,
  UpdateActionCommand
} from '@/generated-api/index.js';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MedicalIndexClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';

import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import EditableInput from '../../EditableInput.js';
import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete.js';
import * as yup from 'yup';
import logger from 'app/utils/logger.js';

const { actions } = medicalIndexSlice;

export type ActionItemProps = {
  medIndexId: MedicalProtocolDto['id'];
  chapterId: MedicalProtocolChapterDto['id'];
  action: MedicalProtocolActionDto;
  onDelete: () => void;
};

const nameFieldKey = nameof.full<UpdateActionCommand>((m) => m.title);

const schema = yup.object({
  [nameFieldKey]: yup.string().required('This field is required')
});

function ActionItem({ medIndexId, chapterId, action, onDelete }: ActionItemProps): JSX.Element {
  const { errors, reset, getValues, control, handleSubmit } = useForm<UpdateActionCommand>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues: {
      title: action.name
    }
  });

  const dispatch = useDispatch();
  const onSubmit = handleSubmit(
    async (data) => {
      if (!action || action.name === data.title) {
        return;
      }

      try {
        const { data: medIndex } = await MedicalIndexClient.medicalIndexUpdateAction(
          medIndexId,
          chapterId,
          action.id,
          data
        );

        dispatch(actions.indexUpdated(medIndex));
      } catch (e) {
        logger.error(e);
      }
    },
    (data) => {
      logger.error('An error occurred during updating action', data);
    }
  );

  useEffect(() => {
    if (action && getValues(nameFieldKey) !== action?.name) {
      reset({
        title: action.name
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action?.name]);

  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1} mr={1} overflow={'hidden'}>
        <Controller
          control={control}
          name={nameFieldKey}
          render={({ value, onChange, onBlur }) => {
            return (
              <EditableInput
                multiline
                value={value}
                onChange={onChange}
                onBlur={() => {
                  onBlur();
                  onSubmit();
                }}
                error={errors.title?.message}
              />
            );
          }}
        />
      </Box>

      <IconButton onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
}

export default ActionItem;
