import type { BlockDto, BlockLinkDto, SceneDto } from '@/generated-api/index.js';
import { BlockType, VoiceResponseUsecase } from '@/generated-api/index.js';
import type { CustomNode, CustomNodeType } from '../../../models/intents/customNode.js';
import { getNodePosition, getChartOffset } from '../positionHelpers.js';
import { getDefaultNodeSize } from '../sizingHelpers.js';
import getDefaultPorts, { getOutputPortId, getInputPortId } from '../portsHelpers.js';
import type { ILink } from '@mrblenny/react-flow-chart';
import type { CustomChart } from '../../../models/scenarios/customChart.js';
import type ChartDOMPosition from '../../../models/guiState/chartDomPosition.js';
import type { LinkedScenario } from '../../../models/intents/intentNodeProperties.js';
import { MapEnumToColor } from '../../colorEnumToStringMapper.js';

import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';

// TODO: another transform
export function nodeFromBlock(block: BlockDto, linkedScenarios: Array<LinkedScenario>): CustomNode {
  return {
    id: `${block.id}`,
    type: nodeTypeFromBlockType(block.type),
    position: getNodePosition({
      x: Math.round(block?.posX ?? 0),
      y: Math.round(block?.posY ?? 0)
    }),

    size: getDefaultNodeSize(),
    ports: getDefaultPorts(nodeTypeFromBlockType(block.type) == CustomNodeTypeEnum.Start),
    properties: {
      personaId: block.personaId,
      name: block.name,
      description: block.description,
      intentPhrases: block.intentPhrases ?? [],
      aiResponses:
        block.aiResponses?.filter((resp) => resp.voiceResponseUsecase === VoiceResponseUsecase.NUMBER_0) ?? [],
      fallbackResponses:
        block.aiResponses?.filter((resp) => resp.voiceResponseUsecase === VoiceResponseUsecase.NUMBER_1) ?? [],
      labelColor: MapEnumToColor(block.qualityLevel as number),
      linkedScenarios: linkedScenarios,
      medicalProtocolActions: block.medicalProtocolActions ?? [],
      secondsFromDialogStartEstimate: block.secondsFromDialogStartEstimate,
      globalAssetIntents: block.globalAssetIntents
    }
  };
}

export function linkFromSceneLink(sceneLink: BlockLinkDto): ILink {
  const link: ILink = {
    id: `${sceneLink.id}`,
    from: {
      nodeId: `${sceneLink.originId}`,
      portId: getOutputPortId()
    },
    to: {
      nodeId: `${sceneLink.targetId}`,
      portId: getInputPortId()
    }
  };

  return link;
}

export function chartFromSceneFull(
  scene: SceneDto,
  chartDOMInfo: ChartDOMPosition,
  scenarios: Array<SceneDto>
): CustomChart {
  const nodes =
    scene.blocks?.reduce<Record<string, CustomNode>>((nodesObject, block) => {
      const externalLinks: Array<LinkedScenario> = scene.blockLinks
        ?.filter((x) => x.origin?.sceneId != x.target?.sceneId && x.originId == block.id)
        .map((x) => {
          const linkedScenario: LinkedScenario = {
            sceneId: x.target?.sceneId,
            sceneName: scenarios.find((s) => s.id === x.target?.sceneId)?.name,
            id: x.id,
            startBlockId: scenarios.find((s) => s.id === x.target?.sceneId)?.startBlockId
          };
          return linkedScenario;
        });

      nodesObject[block.id] = nodeFromBlock(block, externalLinks);
      return nodesObject;
    }, {}) ?? {};

  const links =
    scene.blockLinks
      ?.filter((x) => x.origin?.sceneId == x.target?.sceneId) // or use x.sceneId ??
      .reduce<Record<string, ILink>>((linksObject, sceneLink) => {
        linksObject[sceneLink.id] = linkFromSceneLink(sceneLink);
        return linksObject;
      }, {}) ?? {};

  const chartScale = getValidScale(scene.scale);

  const chart: CustomChart = {
    scale: chartScale,
    offset: getChartOffset(
      {
        x: scene.centerX,
        y: scene.centerY
      },
      {
        x: chartDOMInfo.chartDOMWidth,
        y: chartDOMInfo.chartDOMHeight
      },
      chartScale
    ),
    nodes: nodes,
    links: links,
    selected: {},
    hovered: {},
    properties: {
      id: scene.id,
      hoveredPort: null,
      showIntentEdit: false
    }
  };

  return chart;
}

/// scale info
// these variables are extracted from react-flow-chart source code and are subject to change
const minScale = 0.25;
const maxScale = 2;

function getValidScale(sceneScale?: number): number {
  return Math.min(Math.max(sceneScale ?? 1, minScale), maxScale);
}

/// enum type maps

const blockTypeNodeTypeMap: Record<BlockType, CustomNodeType> = {
  [BlockType.NUMBER_0]: CustomNodeTypeEnum.Normal,
  [BlockType.NUMBER_1]: CustomNodeTypeEnum.Start,
  [BlockType.NUMBER_2]: CustomNodeTypeEnum.End,
  [BlockType.NUMBER_3]: CustomNodeTypeEnum.Service
};

function nodeTypeFromBlockType(bt: BlockType): CustomNodeType {
  return blockTypeNodeTypeMap[bt];
}
