import React, { useCallback } from 'react';
import { DialogContentText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import type { CourseDto, ScheduledCourseDto } from '@/generated-api/index.js';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type DeleteScheduledCourseDialogProps = {
  open: boolean;
  courseId: string;
  course: CourseDto;
  onClose: () => void;
  onExited: () => void;
  scheduledCourse: ScheduledCourseDto;
};

const DeleteScheduledCourseDialog: React.FC<DeleteScheduledCourseDialogProps> = ({
  open,
  course,
  onClose,
  onExited,
  scheduledCourse
}) => {
  const dispatch = useDispatch();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const onDeleteScheduledCourse = useCallback(() => {
    dispatch(CourseManagementActions.deleteScheduledCourse(course.id, scheduledCourse.id, onClose));
  }, [course, dispatch, scheduledCourse, onClose]);

  const loadingStatus = useSelector(CourseManagementSelectors.deleteScheduledCourseLoadingStatus);

  return (
    <DeleteConfirmationDialog
      onClose={onClose}
      confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
      title={translate(nameof.full<I18nCourseManagementNs>((n) => n.deleteScheduledCourseForm.deleteScheduledCourse))}
      loadingStatus={loadingStatus}
      onConfirm={onDeleteScheduledCourse}
      DialogProps={{
        open: open,
        TransitionProps: {
          onExited: onExited
        }
      }}
    >
      <DialogContentText>
        {translate(nameof.full<I18nCourseManagementNs>((n) => n.deleteScheduledCourseForm.confirmationText))} <b>{course?.title}</b>?
      </DialogContentText>
    </DeleteConfirmationDialog>
  );
};

export default DeleteScheduledCourseDialog;
