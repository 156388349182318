import type { VariableDto } from '@/generated-api/index.js';
import { ClickAwayListener, List, ListItem, Paper, Popover, makeStyles } from '@material-ui/core';
import { useBoolState } from 'app/utils/customHooks/index.js';
import React from 'react';
import AddVariableDialog from './AddVariableDialog.js';
import { useSelector } from 'react-redux';
import { ScenarioSelectors } from 'app/selectors/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type VariableSuggestionsProps = {
  field: React.RefObject<HTMLInputElement | HTMLTextAreaElement>;
  anchorEl: null | HTMLElement;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  value: string | undefined;
  setValue: (value: string) => void;
  variables: VariableDto[];
  hasSuggestions: boolean;
  setHasSuggestions: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles(() => ({
  suggestions: {
    maxHeight: '300px'
  }
}));

// const regex = /@(\w*)$/;
// const multiRegex = /@(\w*)/g;

// Inspired from https://codesandbox.io/s/magical-browser-dk981?file=/src/App.tsx:557-562

const VariableSuggestions = (props: VariableSuggestionsProps) => {
  const { field, value, setValue, variables, hasSuggestions, setHasSuggestions, anchorEl, setAnchorEl } = props;
  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [isAddOpen, openAdd, closeAdd] = useBoolState(false);
  const [controlledVariable, setControlledVariable] = React.useState<VariableDto>(undefined);

  const currentScenario = useSelector(ScenarioSelectors.currentChartScenario);

  const valueMatch = React.useMemo(() => {
    const atIndex = value?.slice(0, field.current?.selectionStart).lastIndexOf('@');
    const match = value?.slice(atIndex + 1, field.current?.selectionStart);
    return match;
  }, [field, value]);

  const suggestions = React.useMemo(() => {
    if (valueMatch) {
      return variables.filter((variable) => variable.name.toLowerCase().includes(valueMatch.toLowerCase()));
    }
    return variables;
  }, [valueMatch, variables]);

  const hasExactSuggestion = React.useMemo(() => {
    return !valueMatch || !!variables.find((variable) => variable.name.toLowerCase() === valueMatch?.toLowerCase());
  }, [valueMatch, variables]);

  const handleClick = (item: string) => {
    // Find the position of the @ before the caretPosition
    const atIndex = value?.slice(0, field.current?.selectionStart).lastIndexOf('@');
    const newValue = value.slice(0, atIndex + 1) + item + value.slice(field.current?.selectionStart);
    setValue(newValue);
    setHasSuggestions(false);
    setAnchorEl(null);
    field.current?.focus();
  };

  const onClickAway = () => {
    if (hasSuggestions) {
      setHasSuggestions(false);
      setAnchorEl(null);
      field.current?.focus();
    }
  };

  const openVariableDialog = () => {
    setControlledVariable({ name: valueMatch, value: '' });
    openAdd();
  };

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <Popover
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          open={hasSuggestions}
          style={{ marginTop: 1 }}
          onClose={onClickAway}
          anchorEl={anchorEl}
          disableAutoFocus
          disableEnforceFocus
          classes={{ paper: classes.suggestions }}
        >
          <List component={Paper}>
            {suggestions.map((variable, index) => (
              <ListItem button key={variable.name + index} onClick={() => handleClick(variable.name)}>
                {variable.name}
              </ListItem>
            ))}
            {suggestions.length === 0 || !hasExactSuggestion ? (
              <ListItem button key="add" onClick={openVariableDialog}>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.variableSuggestions.addNewVariable))} &quot;{valueMatch}&quot;
              </ListItem>
            ) : null}
          </List>
        </Popover>
      </ClickAwayListener>
      <AddVariableDialog
        open={isAddOpen}
        onClose={closeAdd}
        variables={variables}
        scenarioId={currentScenario.id}
        controlledVariable={controlledVariable}
      />
    </>
  );
};

export default VariableSuggestions;
