import React, { useCallback, useEffect } from 'react';
import { produce } from 'immer';

import { AccordionDetails, Box, List, IconButton } from '@material-ui/core';

import type { AiVoiceResponseDto } from '@/generated-api/index.js';
import { Emotion, VoiceResponseUsecase } from '@/generated-api/index.js';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import type { Action } from 'redux-actions';
import type { IntentEditingFormData } from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/types.js';

import { debounce } from 'lodash';
import CustomAccordion from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/CustomAccordion.js';
import ResponseListItem from './ResponseListItem.js';
import { ScenarioSelectors } from 'app/selectors/index.js';
import { useQuery } from '@tanstack/react-query';
import { getVariablesQuery } from 'app/queries/variableQueries.js';
import { Add } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

export type ResponseSettingsType = {
  fieldName: keyof Pick<IntentEditingFormData, 'aiResponses' | 'fallbackResponses'>;
  title: React.ReactNode;
  actionCreator: (payload: AiVoiceResponseDto[]) => Action<AiVoiceResponseDto[]>;
  disabled?: boolean;
};

function ResponseSettings({ fieldName, title, actionCreator, disabled }: ResponseSettingsType) {
  const { control, setValue, formState, getValues } = useFormContext<IntentEditingFormData>();

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const currentChartScenario = useSelector(ScenarioSelectors.currentChartScenario);
  const variablesQueryData = useQuery(getVariablesQuery(currentChartScenario.id));
  const variables = variablesQueryData.data ?? [];

  const responses = useWatch<AiVoiceResponseDto[]>({
    name: fieldName,
    control: control
  });

  const onChange: <T>(propName: string, value: T, index: number) => void = (propName, value, index) => {
    setValue(
      fieldName,
      produce(getValues(fieldName), (state) => {
        state[index][propName] = value;
      }),
      {
        shouldDirty: true
      }
    );
  };

  const onAdd = (index: number) => {
    setValue(
      fieldName,
      produce(getValues(fieldName), (state) => {
        state.splice(index + 1, 0, {
          emotion: Emotion.NUMBER_0,
          voiceResponseUsecase:
            fieldName === 'aiResponses' ? VoiceResponseUsecase.NUMBER_0 : VoiceResponseUsecase.NUMBER_1,
          text: ''
        });
      }),
      {
        shouldDirty: true
      }
    );
  };

  const onDelete = (index: number) => {
    setValue(
      fieldName,
      produce(getValues(fieldName), (state) => {
        state.splice(index, 1);
      }),
      {
        shouldDirty: true
      }
    );
  };

  const dispatch = useDispatch();

  const isFieldDirty = formState.dirtyFields[fieldName];
  const isFormDirty = formState.isDirty;

  // TODO: move into hook
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDispatch = useCallback(
    debounce((responses: Action<AiVoiceResponseDto[]>) => {
      dispatch(responses);
    }, 300),
    []
  );

  useEffect(() => {
    if (isFormDirty) {
      debouncedDispatch(actionCreator(responses));
    }
  }, [debouncedDispatch, actionCreator, isFieldDirty, isFormDirty, responses]);

  const isEmpty = responses && !responses.length;

  const isDeleteButtonDisplayed = responses?.length > 1;

  return (
    <CustomAccordion title={title} badgeNumber={responses?.length}>
      <AccordionDetails>
        {isEmpty && (
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} flexGrow={1}>
            {translate(nameof.full<I18nScenarioEditorNs>((n) => n.responseSettings.noResponsesAddedYet))}
            <Box mt={1}>
              <IconButton
                onClick={() => {
                  onAdd(0);
                }}
              >
                <Add />
              </IconButton>
            </Box>
          </Box>
        )}

        {!isEmpty && (
          <List className="full-width" dense>
            {responses?.map?.((r, index) => (
              <ResponseListItem
                response={r}
                index={index}
                key={r.id ?? index}
                isDeleteButtonDisplayed={isDeleteButtonDisplayed}
                onChange={onChange}
                onDelete={onDelete}
                onAdd={onAdd}
                disabled={disabled}
                variables={variables}
              />
            ))}
          </List>
        )}
      </AccordionDetails>
    </CustomAccordion>
  );
}

export default ResponseSettings;
