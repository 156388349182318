import { colors } from '@material-ui/core';

enum ScoreType {
  Neutral = '',
  Positive = '+',
  Negative = '-'
}

const getScoreType = (score: number) => {
  if (!score) return ScoreType.Neutral;

  return score > 0 ? ScoreType.Positive : ScoreType.Negative;
};

const getScoreColor: (score: number) => string = (score) => {
  const type = getScoreType(score);

  switch (type) {
    case ScoreType.Positive:
      return colors.green[500];
    case ScoreType.Negative:
      return colors.red[500];
    default:
      return colors.grey[600];
  }
};

const useKPIDetailsScore: (score: number) => { color: string; prefix: string } = (score) => {
  const color = getScoreColor(score);
  const prefix = getScoreType(score) as string;

  return {
    color,
    prefix
  };
};

export default useKPIDetailsScore;
