import React from 'react';
import type { Theme } from '@material-ui/core';
import {
  IconButton,
  List,
  Tooltip,
  Divider,
  ListItem,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createStyles, makeStyles } from '@material-ui/styles';

import type { CompanyDto } from '@/generated-api/index.js';
import { CompaniesSelector } from 'app/selectors/index.js';
import type OnCompanyDeleteAction from 'app/models/companies/onCompanyDeleteAction.js';
import { CompanyManagementActions } from 'app/actions/companyManagment/companyManagementActions.js';
import CompanyDeletionMenu from 'app/components/UsersPage/UserForm/UserCompanyField/CompanyManager/CompanyDeletionMenu/CompanyDeletionMenu.js';
import CompanyNameTextField from 'app/components/UsersPage/UserForm/UserCompanyField/CompanyManager/CompanyNameTextField/CompanyNameTextField.js';
import { Add, Cancel, Delete, Edit } from '@material-ui/icons';

type ManagedCompany = Partial<Pick<CompanyDto, 'id'>> & Omit<CompanyDto, 'id'>;

type CompanyManagerProps = {
  onClose: () => void;
};

function isAdd(comp?: ManagedCompany) {
  return !!comp && !comp?.id;
}

const CompanyManager: React.FC<CompanyManagerProps> = ({ onClose }) => {
  const dispatch = useDispatch();

  const companies = useSelector(CompaniesSelector.companiesSelector);

  const [companyToEdit, setCompanyToEdit] = React.useState<ManagedCompany>();
  const [companyToDeleteId, setCompanyToDeleteId] = React.useState<number>();
  const deletionMenuRef = React.useRef<HTMLButtonElement>();

  const resetSelection = () => {
    setCompanyToEdit(undefined);
    setCompanyToDeleteId(undefined);
    deletionMenuRef.current = undefined;
  };

  const onAddCompany = () => {
    dispatch(CompanyManagementActions.addCompany({ name: companyToEdit?.name }));
    resetSelection();
  };

  const onEditCompany = () => {
    dispatch(
      CompanyManagementActions.editCompany({
        id: companyToEdit?.id,
        name: companyToEdit?.name,
        medicalProtocols: companyToEdit?.medicalProtocols
      })
    );
    resetSelection();
  };

  const onDeleteCompany = (deletionAction: OnCompanyDeleteAction, moveToCompanyId?: number) => {
    dispatch(
      CompanyManagementActions.deleteCompany({
        id: companyToDeleteId,
        deletionAction,
        moveToCompanyId
      })
    );
  };

  const classes = useStyles();

  return (
    <>
      <List
        className={classes.root}
        subheader={
          <ListSubheader className={classes.subheader}>
            {isAdd(companyToEdit) ? (
              <CompanyNameTextField
                companyName={companyToEdit?.name}
                onChange={(name) => setCompanyToEdit({ name, medicalProtocols: companyToEdit?.medicalProtocols })}
                onSave={onAddCompany}
                onDiscard={resetSelection}
              />
            ) : (
              <>
                <Typography variant="inherit">Company Manager</Typography>
                <ListItemSecondaryAction>
                  <Tooltip title="Add company">
                    <IconButton onClick={() => setCompanyToEdit({ name: '', medicalProtocols: [] })}>
                      <Add />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Close manager">
                    <IconButton onClick={onClose}>
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </>
            )}
            <Divider />
          </ListSubheader>
        }
      >
        {companies.map((c) => (
          <ListItem key={c.id}>
            {c.id === companyToEdit?.id ? (
              <CompanyNameTextField
                companyName={companyToEdit?.name}
                onChange={(name) => setCompanyToEdit((ce: ManagedCompany) => ({ ...ce, name }))}
                onSave={onEditCompany}
                onDiscard={resetSelection}
              />
            ) : (
              <>
                <ListItemText primary={c.name}></ListItemText>
                <ListItemSecondaryAction>
                  <Tooltip title="Edit company">
                    <IconButton onClick={() => setCompanyToEdit({ ...c })}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete company">
                    <span>
                      <IconButton
                        onClick={(event) => {
                          deletionMenuRef.current = event.currentTarget;
                          setCompanyToDeleteId(c.id);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </span>
                  </Tooltip>
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
        ))}
      </List>
      <CompanyDeletionMenu
        open={!!companyToDeleteId}
        onClose={resetSelection}
        anchorEl={deletionMenuRef.current}
        onDeleteCompany={onDeleteCompany}
        moveToCompanies={React.useMemo(() => companies.filter((c) => c.id !== companyToDeleteId), [
          companies,
          companyToDeleteId
        ])}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxHeight: 200,
      overflowY: 'auto',
      border: `1px solid ${theme.palette.divider}`
    },
    subheader: {
      pointerEvents: 'auto',
      backgroundColor: 'white'
    }
  })
);

export default CompanyManager;
