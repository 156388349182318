import type { FC } from 'react';
import React from 'react';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles.js';
import { List, Collapse, Grid } from '@material-ui/core';

import type { SceneDto, SceneGroupDto } from '@/generated-api/index.js';
import type { SceneGroupListItemProps } from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/SceneGroupListItem.js';
import SceneGroupListItem from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/SceneGroupListItem.js';

import SceneGroupContainerListItem from './SceneGroupContainerListItem.js';
import type { SceneGroupWithScenes } from './types.js';

interface SceneGroupContainerProps extends SceneGroupListItemProps {
  classes: ClassNameMap;
  sceneId: number | null;
  sceneGroup: SceneGroupWithScenes;
  selectedSceneGroupId: number;
  handleSelectScenario: () => void;
  renderContextMenu: (sceneGroupId: number) => void;
  handleClickOnGroupSubmenu: (sceneGroupId: number) => void;
  handleRightClick: (event: React.MouseEvent<HTMLElement>, scene: SceneDto) => void;
}

const SceneGroupContainer: FC<SceneGroupContainerProps> = ({
  classes,
  sceneId,
  sceneGroup,
  handleRightClick,
  renderContextMenu,
  selectedSceneGroupId,
  handleSelectScenario,
  handleClickOnGroupSubmenu,
  isSubGroup
}): JSX.Element => {
  const [selectedSubGroupSceneGroupId, setSelectedSubGroupSceneGroupId] = React.useState<number | null>(null);

  const handleClickOnSubGroup = (id: number) => {
    selectedSubGroupSceneGroupId !== id ? setSelectedSubGroupSceneGroupId(id) : setSelectedSubGroupSceneGroupId(null);
  };

  return (
    <Grid key={'sceneGroupList' + sceneGroup.id}>
      <SceneGroupListItem
        classes={classes}
        sceneGroup={sceneGroup}
        selectedSceneGroupId={selectedSceneGroupId}
        handleClickOnGroupSubmenu={handleClickOnGroupSubmenu}
        isSubGroup={isSubGroup}
      />
      <Collapse in={selectedSceneGroupId == sceneGroup.id} timeout="auto" unmountOnExit>
        <List component="div" data-cy="listForScnario" disablePadding>
          {sceneGroup.subGroups?.map((subGroup) => (
            <SceneGroupContainer
              key={subGroup.id}
              classes={classes}
              sceneId={sceneId}
              selectedSceneGroupId={selectedSubGroupSceneGroupId}
              handleSelectScenario={handleSelectScenario}
              handleClickOnGroupSubmenu={handleClickOnSubGroup}
              handleRightClick={handleRightClick}
              renderContextMenu={renderContextMenu}
              sceneGroup={subGroup}
              isSubGroup
            />
          ))}
          {sceneGroup.scenes.map((scene) => (
            <SceneGroupContainerListItem
              scene={scene}
              key={scene.id}
              classes={classes}
              handleRightClick={handleRightClick}
              handleSelectScenario={handleSelectScenario}
              sceneId={sceneId}
              isSubGroupItem={isSubGroup}
              disableActions={sceneGroup.disableActions}
            />
          ))}
          {renderContextMenu(sceneGroup.id)}
        </List>
      </Collapse>
    </Grid>
  );
};

export default SceneGroupContainer;
