import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import App from 'app/components/App.js';
import { UserSelectors } from 'app/selectors/index.js';
import { LoadingStatus } from 'app/store/types.js';
import { FallbackPage } from 'app/components/FallbackPage/index.js';
import NavigationDrawer from 'app/components/NavigationDrawer/index.js';
import DefaultLoadingPage from 'app/components/DefaultLoadingPage.js';
import { useIsAuthenticated } from '@azure/msal-react';
import appInsights from './components/AppInsights.js';

const AppContainerContent: React.FC = () => {
  const currentUser = useSelector(UserSelectors.CurrentUserSelector);
  const currentUserNotFound = useSelector(UserSelectors.CurrentUserNotFoundSelector);
  const currentUserLoadingStatus = useSelector(UserSelectors.CurrentUserLoadingStatusSelector);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated && currentUser) {
      appInsights.setAuthenticatedUserContext(currentUser.id.toString(), undefined, true);
    } else {
      appInsights.clearAuthenticatedUserContext();
    }
  }, [currentUser, isAuthenticated]);

  if (currentUserNotFound && currentUserLoadingStatus === LoadingStatus.Failed) {
    return (
      <FallbackPage
        redirectUrl={null}
        title="The user does not belong to an organization"
        text="Make sure you registered using the invitation link sent to your email. If you have not yet received this, please contact our support at support@sklls.ai"
      />
    );
  }

  if (currentUserLoadingStatus === LoadingStatus.Failed) {
    return (
      <FallbackPage redirectUrl={null} text="The page isn't loading correctly. Please reload the page to try again" />
    );
  }

  // if (error) {
  //   const redirectUrl = buildLogoutUrl({ returnTo: window.location.origin });

  //   return <FallbackPage text={error.message} redirectUrl={redirectUrl} />;
  // }

  if (!(currentUser && isAuthenticated)) {
    return <DefaultLoadingPage />;
  }

  return (
    <React.Fragment>
      <NavigationDrawer />
      <App />
    </React.Fragment>
  );
};

export default AppContainerContent;
