import type { Ref } from 'react';
import React from 'react';
import TabPanel from '../TabPanel.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import type { TransitionWeightInputProps } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TransitionWeights/TransitionWeightInput.js';
import TransitionWeightInput from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TransitionWeights/TransitionWeightInput.js';
import { QualityLevel } from '@/generated-api/index.js';
import { useFormContext } from 'react-hook-form';
import type { ScenarioSettingsType } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';

const useStyles = makeStyles(() => ({
  container: {
    height: 250,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    justifyItems: 'center',
    alignItems: 'center'
  }
}));

export const qualities = [
  QualityLevel.NUMBER_3,
  QualityLevel.NUMBER_2,
  QualityLevel.NUMBER_1,
  QualityLevel.NUMBER_0
] as const;

const TransitionWeightsTab: React.FC<{ index: string; value: string | number | null }> = ({ index, value }) => {
  const classes = useStyles();

  const { register } = useFormContext<ScenarioSettingsType>();

  return (
    <TabPanel index={index} data-cy="tableTransitionWeight" value={value}>
      <div className={classes.container}>
        {qualities.map((q) => (
          <div key={q}>
            {createTransitionsForOutput(
              q,
              register,
              nameof.full<ScenarioSettingsType>((s) => s.transitionScoresSettings)
            )}
          </div>
        ))}
      </div>
    </TabPanel>
  );
};

function createTransitionsForOutput(
  output: TransitionWeightInputProps['output'],
  register: Ref<unknown>,
  name: string
) {
  return qualities.map((input) => (
    <TransitionWeightInput
      key={String(input) + output}
      output={output}
      input={input}
      // disabled={input === output}
      inputRef={register}
      name={`${name}[${output}][${input}]`}
    />
  ));
}

export default TransitionWeightsTab;
