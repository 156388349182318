import type { I18nScheduledCallsNs } from './interfaces.js';

export const ScheduledCallsDictionaryEn: I18nScheduledCallsNs = {
  scheduledCalls: 'Scheduled calls',
  scheduleCall: 'Schedule call',
  scenario: 'Scenario',
  organization: 'Organization',
  user: 'User',
  time: 'Time',
  phoneNumber: 'Phone number',
  status: 'Status',
  actions: 'Actions',
  cancelCall: 'Cancel call',
  editCallSettings: 'Edit call settings',
  showUpcomingCallsOnly: 'Show upcoming calls only',
  createCallForm: {
    user: 'User',
    now: 'Now',
    startNow: 'Start now',
    selectaScenario: 'Select a scenario',
    selectaUser: 'Select a user',
    noScenarioSelected: 'No scenario selected',
    noUserSelected: 'No user selected',
    phoneNumber: 'Phone number',
    scheduledTime: 'Scheduled time',
    scenario: 'Scenario',
    setToNow: 'Set to now'
  },
  editCallForm: {
    title: 'Edit Call',
    scenario: 'Scenario',
    selectScenario: 'Select a scenario',
    user: 'User',
    selectUser: 'Select a user',
    noScenarioSelected: 'No scenario selected',
    noUserSelected: 'No user selected',
    targetUserName: 'Target User Name',
    phoneNumber: 'Phone number',
    scheduledTime: 'Scheduled time',
    startNow: 'Start now',
    errorMissingCallData: 'Error: Missing call data',
    errorUpdatingCall: 'Error updating call',
    setToNow: 'Set to now'
  },
  validation: {
    phoneNumberMissing: 'Phone number is not valid or country code is missing',
    youMustSelectaUser: 'You must select a user',
    thisFieldIsRequired: 'This field is required'
  }
};

export const ScheduledCallsDictionaryDe: I18nScheduledCallsNs = {
  scheduledCalls: 'Geplante Anrufe',
  scheduleCall: 'Anruf planen',
  scenario: 'Szenario',
  organization: 'Organisation',
  user: 'Benutzer',
  time: 'Zeit',
  phoneNumber: 'Telefonnummer',
  status: 'Status',
  actions: 'Aktionen',
  cancelCall: 'Anruf abbrechen',
  editCallSettings: 'Anrufeinstellungen bearbeiten',
  showUpcomingCallsOnly: 'Nur bevorstehende Anrufe anzeigen',
  createCallForm: {
    user: 'Benutzer',
    now: 'Jetzt',
    startNow: 'Jetzt starten',
    selectaScenario: 'Wählen Sie ein Szenario aus',
    selectaUser: 'Wählen Sie einen Benutzer aus',
    noScenarioSelected: 'Kein Szenario ausgewählt',
    noUserSelected: 'Kein Benutzer ausgewählt',
    phoneNumber: 'Telefonnummer',
    scheduledTime: 'Geplante Zeit',
    scenario: 'Szenario',
    setToNow: 'Auf jetzt setzen'
  },
  editCallForm: {
    title: 'Anruf bearbeiten',
    scenario: 'Szenario',
    selectScenario: 'Wählen Sie ein Szenario aus',
    user: 'Benutzer',
    selectUser: 'Wählen Sie einen Benutzer aus',
    noScenarioSelected: 'Kein Szenario ausgewählt',
    noUserSelected: 'Kein Benutzer ausgewählt',
    targetUserName: 'Zielbenutzer-Name',
    phoneNumber: 'Telefonnummer',
    scheduledTime: 'Geplante Zeit',
    startNow: 'Jetzt starten',
    errorMissingCallData: 'Fehler: Fehlende Anrufdaten',
    errorUpdatingCall: 'Fehler beim Aktualisieren des Anrufs',
    setToNow: 'Auf jetzt setzen'
  },
  validation: {
    phoneNumberMissing: 'Telefonnummer ist ungültig oder Ländervorwahl fehlt',
    youMustSelectaUser: 'Sie müssen einen Benutzer auswählen',
    thisFieldIsRequired: 'Dieses Feld ist erforderlich'
  }
};

export const ScheduledCallsDictionarySw: I18nScheduledCallsNs = {
  scheduledCalls: 'Scheduled calls',
  scheduleCall: 'Schedule call',
  scenario: 'Scenario',
  organization: 'Organization',
  user: 'User',
  time: 'Time',
  phoneNumber: 'Phone number',
  status: 'Status',
  actions: 'Actions',
  cancelCall: 'Cancel call',
  editCallSettings: 'Edit call settings',
  showUpcomingCallsOnly: 'Show upcoming calls only',
  createCallForm: {
    user: 'User',
    now: 'Now',
    startNow: 'Start now',
    selectaScenario: 'Select a scenario',
    selectaUser: 'Select a user',
    noScenarioSelected: 'No scenario selected',
    noUserSelected: 'No user selected',
    phoneNumber: 'Phone number',
    scheduledTime: 'Scheduled time',
    scenario: 'Scenario',
    setToNow: 'Set to now'
  },
  editCallForm: {
    title: 'Edit Call',
    scenario: 'Scenario',
    selectScenario: 'Select a scenario',
    user: 'User',
    selectUser: 'Select a user',
    noScenarioSelected: 'No scenario selected',
    noUserSelected: 'No user selected',
    targetUserName: 'Target User Name',
    phoneNumber: 'Phone number',
    scheduledTime: 'Scheduled time',
    startNow: 'Start now',
    errorMissingCallData: 'Error: Missing call data',
    errorUpdatingCall: 'Error updating call',
    setToNow: 'Set to now'
  },
  validation: {
    phoneNumberMissing: 'Phone number is not valid or country code is missing',
    youMustSelectaUser: 'You must select a user',
    thisFieldIsRequired: 'This field is required'
  }
};

export const ScheduledCallsDictionaryNo: I18nScheduledCallsNs = {
  scheduledCalls: 'Telefonsamtaler',
  scheduleCall: 'Planlegg telefonsamtale',
  scenario: 'Scenario',
  organization: 'Organisasjon',
  user: 'Bruker',
  time: 'Tid',
  phoneNumber: 'Telefonnummer',
  status: 'Status',
  actions: 'Handlinger',
  cancelCall: 'Avbryt planlagt telefonsamtale',
  editCallSettings: 'Rediger innstillinger',
  showUpcomingCallsOnly: 'Vis kun kommende samtaler',
  createCallForm: {
    user: 'Bruker',
    now: 'Nå',
    startNow: 'Start nå',
    selectaScenario: 'Velg et scenario',
    selectaUser: 'Velg en bruker',
    noScenarioSelected: 'Scenario ikke valgt',
    noUserSelected: 'Bruker ikke valgt',
    phoneNumber: 'Telefonnummer',
    scheduledTime: 'Planlagt tid',
    scenario: 'Scenario',
    setToNow: 'Sett til nå'
  },
  editCallForm: {
    title: 'Rediger samtale',
    scenario: 'Scenario',
    selectScenario: 'Velg et scenario',
    user: 'Bruker',
    selectUser: 'Velg en bruker',
    noScenarioSelected: 'Scenario ikke valgt',
    noUserSelected: 'Bruker ikke valgt',
    targetUserName: 'Målbruker navn',
    phoneNumber: 'Telefonnummer',
    scheduledTime: 'Planlagt tid',
    startNow: 'Start nå',
    errorMissingCallData: 'Feil: Manglende samtaleinformasjon',
    errorUpdatingCall: 'Feil ved oppdatering av samtale',
    setToNow: 'Sett til nå'
  },
  validation: {
    phoneNumberMissing: 'Telefonnummer er ikke gyldig eller landkode mangler',
    youMustSelectaUser: 'Du må velge en bruker',
    thisFieldIsRequired: 'Dette feltet er obligatorisk'
  }
};

export const ScheduledCallsDictionaryNl: I18nScheduledCallsNs = {
  scheduledCalls: 'Geplande gesprekken',
  scheduleCall: 'Gesprek plannen',
  scenario: 'Scenario',
  organization: 'Organisatie',
  user: 'Gebruiker',
  time: 'Tijd',
  phoneNumber: 'Telefoonnummer',
  status: 'Status',
  actions: 'Acties',
  cancelCall: 'Gesprek annuleren',
  editCallSettings: 'Gespreksinstelling bewerken',
  showUpcomingCallsOnly: 'Toon alleen aankomende gesprekken',
  createCallForm: {
    user: 'Gebruiker',
    now: 'Nu',
    startNow: 'Nu starten',
    selectaScenario: 'Selecteer een scenario',
    selectaUser: 'Selecteer een gebruiker',
    noScenarioSelected: 'Geen scenario geselecteerd',
    noUserSelected: 'Geen gebruiker geselecteerd',
    phoneNumber: 'Telefoonnummer',
    scheduledTime: 'Geplande tijd',
    scenario: 'Scenario',
    setToNow: 'Zet op nu'
  },
  editCallForm: {
    title: 'Gesprek bewerken',
    scenario: 'Scenario',
    selectScenario: 'Selecteer een scenario',
    user: 'Gebruiker',
    selectUser: 'Selecteer een gebruiker',
    noScenarioSelected: 'Geen scenario geselecteerd',
    noUserSelected: 'Geen gebruiker geselecteerd',
    targetUserName: 'Doelgebruiker naam',
    phoneNumber: 'Telefoonnummer',
    scheduledTime: 'Geplande tijd',
    startNow: 'Nu starten',
    errorMissingCallData: 'Fout: Ontbrekende gespreksgegevens',
    errorUpdatingCall: 'Fout bij het bijwerken van het gesprek',
    setToNow: 'Zet op nu'
  },
  validation: {
    phoneNumberMissing: 'Telefoonnummer is niet geldig of landcode ontbreekt',
    youMustSelectaUser: 'Je moet een gebruiker selecteren',
    thisFieldIsRequired: 'Dit veld is verplicht'
  }
};

export const ScheduledCallsDictionaryIs: I18nScheduledCallsNs = {
  scheduledCalls: 'Scheduled calls',
  scheduleCall: 'Schedule call',
  scenario: 'Scenario',
  organization: 'Organization',
  user: 'User',
  time: 'Time',
  phoneNumber: 'Phone number',
  status: 'Status',
  actions: 'Actions',
  cancelCall: 'Cancel call',
  editCallSettings: 'Edit call settings',
  showUpcomingCallsOnly: 'Show upcoming calls only',
  createCallForm: {
    user: 'User',
    now: 'Now',
    startNow: 'Start now',
    selectaScenario: 'Select a scenario',
    selectaUser: 'Select a user',
    noScenarioSelected: 'No scenario selected',
    noUserSelected: 'No user selected',
    phoneNumber: 'Phone number',
    scheduledTime: 'Scheduled time',
    scenario: 'Scenario'
  },
  editCallForm: {
    title: 'Edit Call',
    scenario: 'Scenario',
    selectScenario: 'Select a scenario',
    user: 'User',
    selectUser: 'Select a user',
    noScenarioSelected: 'No scenario selected',
    noUserSelected: 'No user selected',
    targetUserName: 'Target User Name',
    phoneNumber: 'Phone number',
    scheduledTime: 'Scheduled time',
    startNow: 'Start now',
    errorMissingCallData: 'Error: Missing call data',
    errorUpdatingCall: 'Error updating call'
  },
  validation: {
    phoneNumberMissing: 'Phone number is not valid or country code is missing',
    youMustSelectaUser: 'You must select a user',
    thisFieldIsRequired: 'This field is required'
  }
};

export const ScheduledCallsDictionaryUk: I18nScheduledCallsNs = {
  scheduledCalls: 'Scheduled calls',
  scheduleCall: 'Schedule call',
  scenario: 'Scenario',
  organization: 'Organization',
  user: 'User',
  time: 'Time',
  phoneNumber: 'Phone number',
  status: 'Status',
  actions: 'Actions',
  cancelCall: 'Cancel call',
  editCallSettings: 'Edit call settings',
  showUpcomingCallsOnly: 'Show upcoming calls only',
  createCallForm: {
    user: 'User',
    now: 'Now',
    startNow: 'Start now',
    selectaScenario: 'Select a scenario',
    selectaUser: 'Select a user',
    noScenarioSelected: 'No scenario selected',
    noUserSelected: 'No user selected',
    phoneNumber: 'Phone number',
    scheduledTime: 'Scheduled time',
    scenario: 'Scenario'
  },
  editCallForm: {
    title: 'Edit Call',
    scenario: 'Scenario',
    selectScenario: 'Select a scenario',
    user: 'User',
    selectUser: 'Select a user',
    noScenarioSelected: 'No scenario selected',
    noUserSelected: 'No user selected',
    targetUserName: 'Target User Name',
    phoneNumber: 'Phone number',
    scheduledTime: 'Scheduled time',
    startNow: 'Start now',
    errorMissingCallData: 'Error: Missing call data',
    errorUpdatingCall: 'Error updating call'
  },
  validation: {
    phoneNumberMissing: 'Phone number is not valid or country code is missing',
    youMustSelectaUser: 'You must select a user',
    thisFieldIsRequired: 'This field is required'
  }
};
