import { createAction } from 'redux-actions';
import type { CustomNode } from '../../models/intents/customNode.js';
import type { IIntentClipboardState } from '../../reducers/scenarios/intentClipboard/index.js';

export namespace IntentActions {
  export const enum Type {
    ADD_INTENT = 'ADD_INTENT',
    REMOVE_INTENT = 'REMOVE_INTENT',
    COPY_INTENT = 'COPY_INTENT',
    COPY_INTENT_TO_SCENARIO = 'COPY_INTENT_TO_SCENARIO',
    SET_INTENT_CLIPBOARD = 'SET_INTENT_CLIPBOARD'
  }

  export type AddIntentPayload = Partial<Pick<CustomNode, 'properties'>>;

  export interface CopyIntentToScenarioPayload {
    scenarioId: number;
    intentId: string;
  }

  export const addIntent = createAction<AddIntentPayload | void>(Type.ADD_INTENT);
  export const removeIntent = createAction<string>(Type.REMOVE_INTENT);
  export const copyIntent = createAction<string>(Type.COPY_INTENT);
  export const copyIntentToScenario = createAction<CopyIntentToScenarioPayload>(Type.COPY_INTENT_TO_SCENARIO);
  export const setIntentClipboard = createAction<IIntentClipboardState>(Type.SET_INTENT_CLIPBOARD);
}
