import type { FC } from 'react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Tooltip, Avatar, Chip, Typography } from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility.js';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff.js';

import { RecognitionSelector } from 'app/selectors/index.js';

import useStyles from '../styles.js';
import { truncStringPortion } from './utils.js';
import VoiceRecognitionStatus from 'app/models/voiceRecognition/VoiceRecognitionStatus.js';

interface IChipWithTooltipComponent {
  containerDOMWidth: number | null;
  id: number;
  scenarioId: number;
  scenarioName: string;
  index: number;
  userPhrases: string[];
  onClick: (scenarioId: number) => void;
}

const ChipWithTooltipComponent: FC<IChipWithTooltipComponent> = (props): JSX.Element => {
  const classes = useStyles();

  const [visible, setVisible] = useState(false);

  const recognitionStatus = useSelector(RecognitionSelector.recognitionStatusSelector);

  const onShowTooltip = () => {
    setVisible(!visible);
  };

  const onClick = () => {
    recognitionStatus === VoiceRecognitionStatus.UserIsReallySpeaking
      ? onShowTooltip()
      : props.onClick(props.scenarioId);
  };

  return (
    <div key={props.index}>
      <Tooltip
        disableFocusListener
        disableTouchListener
        disableHoverListener
        open={visible}
        placement="right"
        arrow
        title={
          <ul className={classes.tooltipUserPhrases}>
            {props.userPhrases?.map((phrase, index) => (
              <li key={index}>{phrase}</li>
            ))}
          </ul>
        }
      >
        <Chip
          key={props.id + props.index}
          style={{ left: props.containerDOMWidth - 20 }}
          color="primary"
          className={classes.chipDefaultWidth}
          avatar={<Avatar>LS</Avatar>}
          deleteIcon={visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          label={
            <Typography style={{ color: 'white' }}>
              {props.scenarioName?.length > 25 ? truncStringPortion(props.scenarioName, 15, 10, 3) : props.scenarioName}
            </Typography>
          }
          onClick={onClick}
          onDelete={onShowTooltip}
          clickable
        />
      </Tooltip>
    </div>
  );
};

export default ChipWithTooltipComponent;
