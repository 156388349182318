import React from 'react';
import { useSelector } from 'react-redux';
import type { Theme } from '@material-ui/core';
import { Box, createStyles, makeStyles } from '@material-ui/core';

import LoadingButton from 'app/components/LoadingButton.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type ActionButtonsProps = {
  onDelete: () => void;
};

function CourseActionButtons({ onDelete }: ActionButtonsProps): JSX.Element {
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const loadingStatus = useSelector(CourseManagementSelectors.deleteCourseLoadingStatus);

  return (
    <Box display={'flex'} justifyContent="flex-start">
      <LoadingButton
        className={classes.deleteButton}
        variant="contained"
        onClick={onDelete}
        loadingStatus={loadingStatus}
        color="primary"
      >
        {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
      </LoadingButton>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deleteButton: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    }
  })
);

export default CourseActionButtons;
