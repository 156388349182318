import React from 'react';
import GlobalAssetsLibraryContent from './GlobalAssetsLibraryContent.js';
import { GlobalAssetsLibraryContextProvider } from 'app/components/GlobalAssetsLibrary/Context/GlobalAssetsLibraryContext.js';

function GlobalAssetsLibrary(): JSX.Element {
  return (
    <GlobalAssetsLibraryContextProvider>
      <GlobalAssetsLibraryContent />
    </GlobalAssetsLibraryContextProvider>
  );
}

export default GlobalAssetsLibrary;
