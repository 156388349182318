import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Grid, Card, IconButton, Typography, CardContent, CardHeader } from '@material-ui/core';

import type { SceneContextDto, SceneContextLocationDto, VariableDto } from '@/generated-api/index.js';
import type { ScenarioSettingsType } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';
import VariableSuggestionInput from '../Variables/VariableSuggestionInput.js';
import { Add, Delete } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

type SceneContextLocationsCardProps = {
  index: number;
  variables: VariableDto[];
};

const ScenarioContextLocationsCard: React.FunctionComponent<SceneContextLocationsCardProps> = ({
  index,
  variables
}) => {
  const contextsName = nameof.full<ScenarioSettingsType>((u) => u.contexts);
  const locationsName = nameof.full<SceneContextDto>((u) => u.locations);

  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const name = `${contextsName}[${index}].${locationsName}`;

  const { control } = useFormContext<ScenarioSettingsType>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
    keyName: 'key'
  });

  const defaultLocation: SceneContextLocationDto = {
    location: translate(nameof.full<I18nScenarioEditorNs>((n) => n.sceneContextLocationsCard.newLocation)),
    id: 0
  };

  return (
    <Grid item component={Card} xs={12}>
      <CardHeader
        title={
          <Box display="flex" alignItems="center">
            <Typography variant="h5">
              {translate(nameof.full<I18nScenarioEditorNs>((n) => n.sceneContextLocationsCard.locations))}
            </Typography>
            <IconButton onClick={() => append(defaultLocation)}>
              <Add />
            </IconButton>
          </Box>
        }
      />
      <CardContent>
        <Grid container>
          {!fields.length && (
            <Grid container justifyContent="center">
              <Typography>
                {translate(nameof.full<I18nScenarioEditorNs>((n) => n.sceneContextLocationsCard.noLocationsAddedYet))}
              </Typography>
            </Grid>
          )}
          {fields.map((field, index) => {
            const prefix = `${name}[${index}]`;

            const fullName = `${prefix}.${nameof.full<SceneContextLocationDto>((u) => u.location)}`;

            return (
              <Grid item xs={12} key={field.id} container wrap="nowrap">
                <Grid item xs>
                  <Controller
                    control={control}
                    name={fullName}
                    defaultValue={field.location}
                    render={({ onChange, ...renderProps }) => (
                      <VariableSuggestionInput
                        inputProps={{
                          ...renderProps,
                          margin: 'normal',
                          InputProps: {
                            inputProps: {
                              maxLength: 100,
                              maxRows: 5
                            }
                          }
                        }}
                        value={renderProps.value}
                        variables={variables}
                        onChange={(value) => onChange(value)}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`${prefix}.${nameof.full<SceneContextLocationDto>((u) => u.id)}`}
                    defaultValue={field.id}
                    render={() => <input type="hidden" />}
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={() => remove(index)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Grid>
  );
};

export default ScenarioContextLocationsCard;
