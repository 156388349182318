import { createAction } from 'redux-actions';
import type { ProcessResultDto } from '@/generated-api/index.js';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace DialogProcessorActions {
  export const enum Type {
    SET_STORE_IS_LOADED_RESPONSE_FROM_DP = 'SET_STORE_IS_LOADED_RESPONSE_FROM_DP',
    SET_STORE_RESPONSE_DATA = 'SET_STORE_RESPONSE_DATA',
    RESET_BLOCKS = 'RESET_BLOCKS',
    GET_RESPONSE_DATA = 'GET_RESPONSE_DATA',
    SET_STATUS_INTENT_PROCESSING_UPDATE = 'SET_STATUS_INTENT_PROCESSING_UPDATE',
    SET_SESSION_ID = 'SET_SESSION_ID'
  }

  export const getResponseData = createAction<{ text: string; speechBase64?: string; start?: string; end?: string }>(
    Type.GET_RESPONSE_DATA
  );

  export const setSessionId = createAction<string>(Type.SET_SESSION_ID);
  export const setResponseDataStore = createAction<ProcessResultDto>(Type.SET_STORE_RESPONSE_DATA);
  export const resetBlocks = createAction(Type.RESET_BLOCKS);
  export const setIsLoadedStore = createAction<boolean>(Type.SET_STORE_IS_LOADED_RESPONSE_FROM_DP);
  export const setStatusIntentProcessingUpdate = createAction<{ status: string }>(
    Type.SET_STATUS_INTENT_PROCESSING_UPDATE
  );
}
