import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import type { INodeInnerDefaultProps } from '@mrblenny/react-flow-chart';

import { Card, CardContent, Typography, List, ListItem, IconButton, ListItemText, Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu.js';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff.js';
import FlightLandIcon from '@material-ui/icons/FlightLand.js';
import { getInnerNodeSizePx } from '../../../../../utils/flowChart/sizingHelpers.js';
import type { IOnActionButtonClickHandler } from '../../../../../utils/flowChart/configManager.js';
import { onActionButtonClickPropName } from '../../../../../utils/flowChart/configManager.js';
import useStyles from './styles.js';
import clsx from 'clsx';
import { CustomNodeTypeEnum } from 'app/models/intents/customNode.js';
import truncate from 'truncate';
import { useSelector } from 'react-redux';
import { isUserAnyAdminSelector } from 'app/selectors/userSelectors.js';
import { categoriesSelector } from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySelectors.js';

const nodeSize = getInnerNodeSizePx();

const DarkBox = styled.div`
  display: flex;
  flex-direction: column;
  width: ${nodeSize}px;
  height: ${nodeSize}px;

  padding: 7px;
  background: #d2d2d2;
  color: white;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
`;

const StatisticColorCircle = styled.div<{ color: string }>`
  padding: 5px;
  width: 17px;
  height: 17px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`;

const TruncateWithLeftMargin = 15;
const TruncateWitoutLeftMargin = 19;

const getTruncateSize = (labelColor: string, type: string) => {
  if (labelColor || type == CustomNodeTypeEnum.Start) return TruncateWithLeftMargin;
  return TruncateWitoutLeftMargin;
};

const CustomInnerNodeComponent = ({ node, config }: INodeInnerDefaultProps) => {
  const classes = useStyles({ backgroundColor: node.properties.labelColor });

  const categories = useSelector(categoriesSelector);
  const intents = useMemo(() => {
    if (!node.properties.globalAssetIntents) {
      return [];
    }

    let result: string[] = [];
    const intentIds = node.properties.globalAssetIntents.map((intent) => intent.id);
    for (let i = 0; i < categories.length; i++) {
      for (let j = 0; j < categories[i].intents.length; j++) {
        const template = categories[i].intents[j];
        if (intentIds.includes(template.id)) {
          result = result.concat(template.samples.map((x) => x.text));
        }
      }
    }

    return result;
  }, [categories, node.properties.globalAssetIntents]);

  const intentPhrases =
    node.properties?.intentPhrases
      ?.map((x) => x.text)
      .concat(intents)
      .filter((x: string) => !!x) ?? [];

  const onClickHandler = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      const handler: IOnActionButtonClickHandler = config.nodeProps[onActionButtonClickPropName];
      handler({ nodeId: node.id, nodeAnchor: event.currentTarget });
    },
    [config, node]
  );

  const isAnyAdmin = useSelector(isUserAnyAdminSelector);

  return (
    <DarkBox>
      <div className={classes.header}>
        <Grid alignContent="center" alignItems="center" data-cy="intentHeader" direction="row" container>
          {node.type == CustomNodeTypeEnum.Start && (
            <FlightTakeoffIcon
              className={clsx(classes.startIcon, node.properties.labelColor && classes.flightTakeoffIcon)}
            />
          )}
          {node.type == CustomNodeTypeEnum.End && (
            <FlightLandIcon
              className={clsx(classes.startIcon, node.properties.labelColor && classes.flightTakeoffIcon)}
            />
          )}
          {node.properties.labelColor &&
            node.type != CustomNodeTypeEnum.Start &&
            node.type != CustomNodeTypeEnum.End && <StatisticColorCircle color={node.properties.labelColor} />}
          <Typography variant="subtitle1" color="textSecondary" component="h2">
            {truncate(node.properties?.name, getTruncateSize(node.properties.labelColor, node.type))}
          </Typography>
        </Grid>
        {isAnyAdmin && (
          <IconButton size="small" disableRipple disableTouchRipple data-cy="intentEditPanel" onClick={onClickHandler}>
            <MenuIcon />
          </IconButton>
        )}
      </div>
      <Card className={classes.cardFlexGrow} data-cy="newIntent">
        <CardContent className={classes.cardContent}>
          <List className={classes.list} dense>
            {intentPhrases?.map((phrase: string, idx: any) => (
              <ListItem key={idx}>
                <ListItemText color="primary">
                  <Typography variant="subtitle1" color="textSecondary" component="h2">
                    {phrase}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </DarkBox>
  );
};

export default memo(CustomInnerNodeComponent);
