import { Box, Grid, Card, CardContent } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Route, useParams } from 'react-router';

import {
  courseById,
  coursesManagementLoadingStatusSelector
} from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import useDelete from 'app/hooks/useDelete.js';
import { DateFormat } from 'app/app.constants.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import NavigationTab from 'app/components/NavigationTab.js';
import { CourseManagementRoutes } from 'app/utils/routes.js';
import NavigationTabs from 'app/components/NavigationTabs.js';
import type { CourseDto, UpdateCourseCommand } from '@/generated-api/index.js';
import SkeletonWrapper from 'app/components/SkeletonWrapper.js';
import Detail from 'app/components/CourseManagement/Details/Detail.js';
import useStyles from 'app/components/CourseManagement/CourseCard/useStyles.js';
import CourseScheduleList from 'app/components/CourseManagement/CourseScheduleList.js';
import DetailsContainer from 'app/components/CourseManagement/Details/DetailsContainer.js';
import ScenarioList from 'app/components/CourseManagement/CourseCard/ScenarioList/ScenarioList.js';
import * as validationSchemas from 'app/components/CourseManagement/CourseCard/validationSchema.js';
import EditableCardTitle from 'app/components/CourseManagement/EditableCardTitle/EditableCardTitle.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import CourseActionButtons from 'app/components/CourseManagement/CourseCard/ActionButtons/ActionButtons.js';
import DeleteCourseDialog from 'app/components/CourseManagement/CourseCard/DeleteCourseDialog/DeleteCourseDialog.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const CourseCard: React.FC = () => {
  const dispatch = useDispatch();
  const formatDate = useFormatDate();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const { courseId } = useParams<{ courseId: string }>();

  const loadingStatus = useSelector(coursesManagementLoadingStatusSelector);

  const course = useSelector((state) => courseById(state, courseId));

  const formContext = useForm<UpdateCourseCommand>({
    mode: 'onBlur',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchemas.updateCourse),
    defaultValues: {
      title: ''
    }
  });

  const { reset, control, errors, handleSubmit } = formContext;

  useEffect(() => {
    if (!course) return;

    reset(course);
  }, [reset, course]);

  const classes = useStyles();

  const {
    selectedItem: selectedCourseToDelete,
    onSelectItem: onSelectCourseToDelete,
    resetSelectedItem: resetSelectedCourseToDelete,
    closeConfirmationDialog: closeDeleteCourseConfirmationDialog,
    isConfirmationDialogOpen: isDeleteCourseConfirmationDialogOpen
  } = useDelete<CourseDto>();

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        if (course.title === data.title) return;

        dispatch(CourseManagementActions.updateCourse(course.id, data));
      }),
    [course, dispatch, handleSubmit]
  );

  const coursePath = generatePath(CourseManagementRoutes.Course, { courseId });
  const courseSchedulePath = generatePath(CourseManagementRoutes.Schedule, { courseId });

  if (!course) return null;

  return (
    <Card square classes={{ root: classes.card }}>
      <form id="update-course-form" onSubmit={onSubmit}>
        <Controller
          name={nameof.full<UpdateCourseCommand>((c) => c.title)}
          control={control}
          render={(props) => (
            <Box component={Grid} display="flex" alignItems="center">
              <Grid item xs>
                <EditableCardTitle
                  {...props}
                  changed={false}
                  ref={props.ref}
                  onBlur={onSubmit}
                  error={errors.title?.message}
                />
              </Grid>
              <Grid item>
                <CourseActionButtons onDelete={() => onSelectCourseToDelete(course)} />
              </Grid>
            </Box>
          )}
        />
      </form>
      <Box paddingX={2} marginBottom={2}>
        <DetailsContainer>
          <Grid container spacing={2}>
            <Grid item>
              <Detail label={translate(nameof.full<I18nCourseManagementNs>((n) => n.courseForm.organization))}>
                {course.companyName}
              </Detail>
            </Grid>
            <Grid item container spacing={4}>
              <Grid item>
                <Detail label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.created))}>
                  {formatDate(course.createdDate, DateFormat.Time)}
                </Detail>
              </Grid>
              <Grid item>
                <Detail label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.createdBy))}>
                  {course.createdByUserName ?? 'Unknown'}
                </Detail>
              </Grid>
            </Grid>
          </Grid>
        </DetailsContainer>
      </Box>
      <Box paddingX={2}>
        <NavigationTabs className={classes.tabs}>
          <NavigationTab
            label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.scenarios))}
            exact
            value={coursePath}
          />
          <NavigationTab
            label={translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.schedule))}
            value={courseSchedulePath}
          />
        </NavigationTabs>
      </Box>
      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Box display={'flex'} flexDirection={'column'}>
          <Route exact path={CourseManagementRoutes.Course}>
            <SkeletonWrapper loadingStatus={loadingStatus}>
              <ScenarioList course={course} />
            </SkeletonWrapper>
          </Route>
          <Route path={CourseManagementRoutes.Schedule}>
            <SkeletonWrapper loadingStatus={loadingStatus}>
              <CourseScheduleList course={course} courseId={courseId} />
            </SkeletonWrapper>
          </Route>
        </Box>
      </CardContent>
      <DeleteCourseDialog
        course={selectedCourseToDelete}
        onExited={resetSelectedCourseToDelete}
        open={isDeleteCourseConfirmationDialogOpen}
        onClose={closeDeleteCourseConfirmationDialog}
      />
    </Card>
  );
};

export default CourseCard;
