import type { ChangeEventHandler, FocusEventHandler } from 'react';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Box, CardHeader, Grid, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles/index.js';
import classNames from 'classnames';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline.js';

export type EditableCardTitleProps = {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  changed?: boolean;
  error?: string;
  disable?: boolean;
};

const useStyles = makeStyles((theme) => {
  return {
    cardTitleContent: {
      overflow: 'hidden'
    },
    title: {
      marginLeft: theme.spacing(-1),
      padding: theme.spacing(1),
      ...theme.shape,

      // TODO: refer to the height of the text field
      height: 56,

      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    changed: {
      '&::before': {
        content: '"*"',
        color: theme.palette.warning.main,
        marginRight: theme.spacing(0.5)
      }
    }
  };
});

const EditableCardTitle = forwardRef<HTMLInputElement, EditableCardTitleProps>(function EditableCartTitle(
  { value, changed, error, disable, ...props },
  ref
) {
  const [edit, setEdit] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    inputRef.current?.focus();
  }, [edit]);

  const classes = useStyles();

  const displayTextField = edit || error != null;

  return (
    <>
      <Box display={displayTextField ? 'block' : 'none'} p={2}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              inputRef={(e) => {
                if (typeof ref === 'function') {
                  ref(e);
                } else {
                  if (ref) {
                    ref.current = e;
                  }
                }
                inputRef.current = e;
              }}
              {...props}
              value={value ?? ''}
              InputProps={{
                ...(error != null && {
                  endAdornment: (
                    <Tooltip title={<Typography>{error}</Typography>}>
                      <InputAdornment position={'end'}>
                        <ErrorOutlineIcon color={'error'} />
                      </InputAdornment>
                    </Tooltip>
                  )
                })
              }}
              error={error != null}
              onBlur={(e) => {
                props?.onBlur(e);
                setEdit(false);
              }}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>

      <Box display={displayTextField ? 'none' : 'block'}>
        <Grid container>
          <Grid item xs={12}>
            <CardHeader
              title={value}
              classes={{
                content: classes.cardTitleContent
              }}
              titleTypographyProps={{
                variant: 'h4',
                className: classNames(classes.title, 'text-truncate', { [classes.changed]: changed })
              }}
              onClick={() => (disable ? undefined : setEdit(true))}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

export default EditableCardTitle;
