import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Grid, MenuItem, TextField, Checkbox, Typography } from '@material-ui/core';
import type { CreateOrganizationInvitationRequestCommand, TenantDto } from '@/generated-api/index.js';

import { CompaniesSelector, UserSelectors } from 'app/selectors/index.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';
import SimpleCompanySelectField from 'app/components/UsersPage/UserInvitation/SimpleCompanySelectField.js';
import { skllsTenantId } from '@/authConfig.js';

type UserInvitationFormProps = FieldValues & {
  isLoading: boolean;
  selectedCompanyId?: number | null;
  selectedTenantId?: string | null;
  selectedTenantDomain?: string | null;
  isTenantsLoading?: boolean;
  tenants: TenantDto[];
};

const UserInvitationForm: React.FC<UserInvitationFormProps> = ({
  errors,
  control,
  selectedCompanyId,
  tenants,
  selectedTenantId,
  selectedTenantDomain,
  isTenantsLoading
}) => {
  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const [translateValidation] = useTranslation(I18nNamespace.Validation);
  const [newExternalTenant, setNewExternalTenant] = React.useState(false);

  const companies = useSelector(CompaniesSelector.companiesSelector);
  const isLoading = useSelector(CompaniesSelector.companiesAreLoadingSelector);
  const isUserSuperAdmin = useSelector(UserSelectors.isUserSuperAdminSelector);

  const companiesDisabled = isLoading || companies.length <= 1;

  const user = useSelector(UserSelectors.CurrentUserSelector);
  const isExternalTenantUser = user.tenant?.tenantId !== skllsTenantId;

  return (
    <Grid>
      <SimpleCompanySelectField
        required
        control={control}
        error={!!errors.companyId}
        disabled={companiesDisabled}
        autoFocus={!selectedCompanyId}
        helperText={translateValidation(errors.companyId?.message as string)}
        name={nameof<CreateOrganizationInvitationRequestCommand>((f) => f.companyId)}
        label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.company))}
      />
      <Controller
        required
        fullWidth
        type="email"
        as={TextField}
        margin="normal"
        control={control}
        error={!!errors.email}
        placeholder="email@example.org"
        autoFocus={!!selectedCompanyId}
        helperText={translateValidation(errors.email?.message as string)}
        name={nameof<CreateOrganizationInvitationRequestCommand>((f) => f.email)}
        label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.email))}
      />
      {isUserSuperAdmin ? (
        <>
          <FormControlLabel
            control={
              <Checkbox
                name="newExternalTenant"
                value={newExternalTenant}
                onChange={(e) => setNewExternalTenant(e.target.checked)}
              />
            }
            label={translate(nameof.full<I18nUserManagementNs>((n) => n.inviteUserInExternalTenant))}
          />
          {newExternalTenant ? (
            <>
              <Controller
                select
                fullWidth
                name={nameof<CreateOrganizationInvitationRequestCommand>((f) => f.tenantId)}
                type="text"
                error={!!errors.tenantId}
                label="Tenant"
                as={TextField}
                margin="normal"
                control={control}
                helperText={translateValidation(errors.tenantId?.message as string)}
                disabled={!!isTenantsLoading || !!selectedTenantDomain}
                // autoFocus={autoFocus && !isLoading}
              >
                <MenuItem value="" key="empty">
                  New tenant
                </MenuItem>
                {tenants.map((tenant) => (
                  <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                    {tenant.name} - {tenant.domain}
                  </MenuItem>
                ))}
              </Controller>
              <Controller
                fullWidth
                type="text"
                as={TextField}
                margin="normal"
                control={control}
                error={!!errors.tenantDomain}
                placeholder="Ex. somecompany.com"
                disabled={!!selectedTenantId}
                // autoFocus={!!selectedCompanyId}
                helperText={translateValidation(errors.tenantDomain?.message as string)}
                name={nameof<CreateOrganizationInvitationRequestCommand>((f) => f.tenantDomain)}
                label="Tenant Domain to invite"
              />
            </>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};

export default UserInvitationForm;
