import { createAction } from 'redux-actions';

enum Type {
  GET_AUDIO = 'GET_AUDIO',
  SET_AUDIO_STORE = 'SET_AUDIO_STORE',
  SET_STORE_IS_LOADED = 'SET_STORE_IS_LOADED',
}

const getAudio = createAction(Type.GET_AUDIO);
const setAudioAIResponse = createAction<any>(Type.SET_AUDIO_STORE);
const setIsLoadedStore = createAction<boolean>(Type.SET_STORE_IS_LOADED);

export default {
  Type,
  getAudio,
  setAudioAIResponse,
  setIsLoadedStore
};
