import React from 'react';

import { Card, CardContent, createStyles, Typography } from '@material-ui/core';
import type { MedicalProtocolActionDto } from '@/generated-api/index.js';
import { QualityLevel } from '@/generated-api/index.js';
import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles } from '@material-ui/core/styles/index.js';
import classNames from 'classnames';
import { ArrowRightAlt } from '@material-ui/icons';
import type { IScoresChanges } from 'app/models/statistic/scoresChanges.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

export type ScoresInfoProps = {
  scoresChanges: IScoresChanges;
  actions: MedicalProtocolActionDto[];
  isInitialBlock?: boolean;
};

const useCardStyles = makeStyles(() => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 400
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }
  };
});

export function ScoreInfoTooltipContent(props: ScoresInfoProps): JSX.Element {
  const { scoresChanges, actions } = props;
  const classes = useCardStyles();

  return (
    <Card className={classes.root} data-cy="score-info-tooltip">
      <CardContent className={classes.content}>
        <ScoreChange scoresChanges={scoresChanges} actions={actions} />
        <ReplayQualityTransition {...props} />
        <UsedIndexes scoresChanges={scoresChanges} actions={actions} />
      </CardContent>
    </Card>
  );
}

export type ScoresChangesProps = Pick<ScoresInfoProps, 'scoresChanges' | 'actions'>;

const useScoreChangesStyles = makeStyles<Theme, ScoresChangesProps['scoresChanges']>((theme) => {
  return createStyles({
    score: {
      color: (scoreChanges) => {
        const score = scoreChanges.scores;
        return score > 0
          ? theme.palette.qualityLevel[QualityLevel.NUMBER_3]
          : score < 0
          ? theme.palette.qualityLevel[QualityLevel.NUMBER_1]
          : 'inherit';
      }
    }
  });
});

function ScoreChange({ scoresChanges }: ScoresChangesProps) {
  const classes = useScoreChangesStyles(scoresChanges);
  const scoreChange = scoresChanges.scores;

  return (
    <Typography variant="h6">
      Score Change:{' '}
      <span className={classes.score} data-cy="score-info-tooltip__score-change">
        {scoreChange > 0 && '+'}
        {scoreChange}
      </span>
    </Typography>
  );
}

const useReplayQualityTransitionStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center'
    },

    arrow: {
      margin: `0 ${theme.spacing(1)}px`
    }
  };
});

function ReplayQualityTransition({ scoresChanges, isInitialBlock }: ScoresInfoProps) {
  const classes = useReplayQualityTransitionStyles();

  return (
    <div className={classes.root}>
      {!isInitialBlock && <ColoredBlock quality={scoresChanges.previousColor} />}
      {!isInitialBlock && <ArrowRightAlt className={classes.arrow} />}
      <ColoredBlock quality={scoresChanges.color} />
    </div>
  );
}

type ColoredBlockProps = {
  quality: QualityLevel;
};

const useColoredBlockStyles = makeStyles<Theme, ColoredBlockProps>((theme) => {
  return createStyles({
    root: {
      width: 30,
      height: 20,
      borderRadius: theme.spacing(2)
    },

    colored: { backgroundColor: (props) => theme.palette.qualityLevel[props.quality] }
  });
});

function ColoredBlock(props: ColoredBlockProps) {
  const classes = useColoredBlockStyles(props);
  return <div className={classNames(classes.root, classes.colored)} data-cy="score-info-tooltip__colored-block" />;
}

const useUsedIndexesStyles = makeStyles((theme) => {
  return {
    header: {
      marginTop: theme.spacing(2)
    },

    list: {
      overflowY: 'auto',
      marginTop: theme.spacing(1)
    }
  };
});

function UsedIndexes({ actions, scoresChanges }: ScoresChangesProps) {
  const classes = useUsedIndexesStyles();

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  if (!actions.length) {
    return null;
  }

  return (
    <>
      <Typography variant="h6" className={classes.header}>
        {translate(nameof.full<I18nDashboardNs>((n) => n.scenarioInfoTooltipContent.responseActions))}:
      </Typography>
      <ul className={classes.list}>
        {actions.map((index) => (
          <li className={''} key={index.id}>
            {index.name}
          </li>
        ))}
      </ul>

      {!!scoresChanges?.duplicates.length && (
        <div className={''}>
          <b>
            {translate(nameof.full<I18nDashboardNs>((n) => n.scenarioInfoTooltipContent.someActionsHaveBeenDuplicated))}
          </b>
        </div>
      )}
    </>
  );
}

export default ScoreInfoTooltipContent;
