import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getScenarios } from 'app/actions/scenarios/scenarioAction.js';
import { ScenarioClient } from 'app/apis/api.js';
import { getScenarioGroupsQuery } from 'app/queries/scenarioQueries.js';
import { useDispatch } from 'react-redux';

export const useScenarioMutations = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // TODO? Avoid fetching all scenarios again?
  const convertScenarioToShared = useMutation({
    mutationFn: async (scenarioId: number) => {
      await ScenarioClient.scenariosConvertSceneToSharedScene(scenarioId);
    },
    onSuccess: () => {
      dispatch(getScenarios());
      queryClient.invalidateQueries(getScenarioGroupsQuery.queryKey);
    }
  });

  const convertSharedToScenario = useMutation({
    mutationFn: async (values: { scenarioId: number; companyId: number }) => {
      await ScenarioClient.scenariosConvertSharedSceneToScene(values.scenarioId, { targetCompanyId: values.companyId });
    },
    onSuccess: () => {
      dispatch(getScenarios());
    }
  });

  const addCompaniesToShared = useMutation({
    mutationFn: async (values: { scenarioId: number; companies: number[] }) => {
      await ScenarioClient.scenariosAddCompaniesToSharedScene(values.scenarioId, values.companies);
    },
    onSuccess: () => {
      dispatch(getScenarios());
    }
  });

  const removeCompaniesFromShared = useMutation({
    mutationFn: async (values: { scenarioId: number; companies: number[] }) => {
      await ScenarioClient.scenariosRemoveCompaniesFromSharedScene(values.scenarioId, values.companies);
    },
    onSuccess: () => {
      dispatch(getScenarios());
    }
  });

  return { convertScenarioToShared, convertSharedToScenario, addCompaniesToShared, removeCompaniesFromShared };
};

export default useScenarioMutations;
