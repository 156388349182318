import 'app/store/MedicalIndex/medicalIndexSlice.js';
import { fork } from 'redux-saga/effects';
import watchUserActionsSaga from './users/userSaga.js';
import { watchUserManagement } from './userManagement/userManagementSaga.js';
import watchAllScenarioSagas from './scenarios/scenarioSaga.js';
import watchAllIntentsSaga from './scenarios/intentSaga.js';
import watchAllIntentEditingSaga from './scenarios/intentEditingSaga.js';
import { watchScenarioChartEditing } from './scenarios/scenarioChartEditingSaga.js';
import watchAllAudiosSaga from './audio/audioSaga.js';
import { watchAllDPResponseSaga } from './dialogProcessor/dialogProcessorSaga.js';
import { watchCompanyManagement } from './companyManagment/companyManagementSaga.js';
import watchAllDashboardsSaga from './dashboard/dashboardSaga.js';
import watchAllStatisticSaga from './statistic/statisticSaga.js';
import { watchMedicalIndexSagas } from 'app/store/MedicalIndex/medicalIndexSaga.js';
import watchSpeechToTextSaga from './speechToText/speechToTextSaga.js';
import groupManagementSaga from 'app/components/GroupManagement/store/groupManagementSaga.js';
import courseManagementSaga from 'app/components/CourseManagement/store/courseManagementSaga.js';
import globalAssetsLibrarySaga from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySaga.js';

export default function* rootSaga() {
  yield fork(watchMedicalIndexSagas);
  yield fork(watchScenarioChartEditing);
  yield fork(watchUserActionsSaga);
  yield fork(watchUserManagement);
  yield fork(watchAllScenarioSagas);
  yield fork(watchAllIntentsSaga);
  yield fork(watchAllIntentEditingSaga);
  yield fork(watchAllAudiosSaga);
  yield fork(watchAllDPResponseSaga);
  yield fork(watchCompanyManagement);
  yield fork(watchAllDashboardsSaga);
  yield fork(watchAllStatisticSaga);
  yield fork(watchSpeechToTextSaga);
  yield fork(groupManagementSaga);
  yield fork(courseManagementSaga);
  yield fork(globalAssetsLibrarySaga);
}
