import { createAction } from 'redux-actions';
import type ChartDOMPosition from '../../models/guiState/chartDomPosition.js';

export namespace GuiStateActions {
  export const enum Type {
    SET_SIDE_MENU_OPEN = 'SET_SIDE_MENU_OPEN',
    SET_CHART_PARAMS = 'SET_CHART_PARAMS'
  }

  export const sideMenuOpen = createAction<boolean>(Type.SET_SIDE_MENU_OPEN);
  export const setChartParams = createAction<ChartDOMPosition>(Type.SET_CHART_PARAMS);
}
