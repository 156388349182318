/* eslint-disable @typescript-eslint/no-namespace */
import { createAction } from 'redux-actions';
import type IntentNodeProperties from 'app/models/intents/intentNodeProperties.js';
import type { CustomNode } from 'app/models/intents/customNode.js';
import type { AiVoiceResponseDto, BlockDto } from '@/generated-api/index.js';

export namespace IntentEditingActions {
  export enum Type {
    CLOSE_INTENT_EDITING = 'CLOSE_INTENT_EDITING',
    CHANGE_INTENT_NAME = 'CHANGE_INTENT_NAME',
    ADD_USER_SAYS = 'ADD_USER_SAYS',
    REMOVE_USER_SAYS = 'REMOVE_USER_SAYS',
    CHANGE_INTENT_USER_SAYS = ' CHANGE_INTENT_USER_SAYS',
    CHANGE_LABEL_COLOR = 'SET_LABEL_COLOR',
    CHANGE_SCORE = 'CHANGE_SCORE',
    CHANGE_AMK_ACTION_ID = 'CHANGE_AMK_ACTION_ID',
    TOGGLE_SCENARIO_LINK = 'TOGGLE_SCENARIO_LINK',
    SET_INTENT_TYPE_AS_START = 'SET_INTENT_TYPE_AS_START',
    CHANGE_REACH_TIME = 'CHANGE_REACH_TIME',
    UPDATE_AI_RESPONSES = 'UPDATE_AI_RESPONSES',
    UPDATE_FALLBACK_RESPONSES = 'UPDATE_FALLBACK_RESPONSES',
    UPDATE_GLOBAL_ASSETS = 'UPDATE_GLOBAL_ASSETS',
    UPDATE_BLOCK_TYPE = 'UPDATE_BLOCK_TYPE',
    UPDATE_PERSONA_ID = 'UPDATE_PERSONA_ID'
  }

  export type ChangeReactTimePayload = {
    nodeId: CustomNode['id'];
    scope: 'local' | 'global';
    value: number | null;
  };

  export const onIntentEditingClose = createAction<void>(Type.CLOSE_INTENT_EDITING);
  export const changeIntentName = createAction<string>(Type.CHANGE_INTENT_NAME);

  export const addUserSays = createAction<number>(Type.ADD_USER_SAYS);
  export const removeUserSays = createAction<number>(Type.REMOVE_USER_SAYS);
  export const changeIntentUserSays = createAction<{ index: number; value: string }>(Type.CHANGE_INTENT_USER_SAYS);

  export const updateGlobalAssets = createAction<BlockDto['globalAssetIntents']>(Type.UPDATE_GLOBAL_ASSETS);

  export const changeAmkAction = createAction<string>(Type.CHANGE_AMK_ACTION_ID);

  export const updateAIResponses = createAction<AiVoiceResponseDto[]>(Type.UPDATE_AI_RESPONSES);
  export const updateFallbackResponses = createAction<AiVoiceResponseDto[]>(Type.UPDATE_FALLBACK_RESPONSES);

  export const changeLabelColor = createAction<string>(Type.CHANGE_LABEL_COLOR);
  export const changeScore = createAction<number>(Type.CHANGE_SCORE);

  export const toggleScenarioLink = createAction<number>(Type.TOGGLE_SCENARIO_LINK);

  export const setIntentTypeAsStart = createAction<string>(Type.SET_INTENT_TYPE_AS_START);

  export const changeReachTime = createAction<ChangeReactTimePayload>(Type.CHANGE_REACH_TIME);

  export const updateBlockType = createAction<string>(Type.UPDATE_BLOCK_TYPE);

  export const updatePersonaId = createAction<number>(Type.UPDATE_PERSONA_ID);
}

export type UpdateResponsesPayload = {
  field: keyof Pick<IntentNodeProperties, 'aiResponses'>;
  responses: AiVoiceResponseDto[];
};
