import { createAction } from 'redux-actions';
import type { AddDialogStatisticCommand } from '@/generated-api/index.js';

export namespace statisticActions {
  export const enum Type {
    POST_STATISTICS = 'POST_STATISTICS'
  }

  export const stopStatistic = createAction<AddDialogStatisticCommand>(Type.POST_STATISTICS);
}
