import React, { useCallback } from 'react';
import { DialogContentText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import type { CourseDto, SceneDto } from '@/generated-api/index.js';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type DeleteCourseScenarioDialogProps = {
  open: boolean;
  course: CourseDto;
  scenario: SceneDto;
  onClose: () => void;
  onExited: () => void;
};

const DeleteCourseScenarioDialog: React.FC<DeleteCourseScenarioDialogProps> = ({
  open,
  course,
  onClose,
  scenario,
  onExited
}) => {
  const dispatch = useDispatch();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const onDeleteCourseScenario = useCallback(
    (courseId: number, scenarioId: number) => {
      dispatch(CourseManagementActions.deleteCourseScenario(courseId, scenarioId, onClose));
    },
    [onClose, dispatch]
  );

  const loadingStatus = useSelector(CourseManagementSelectors.deleteCourseSceneLoadingStatus);

  return (
    <DeleteConfirmationDialog
      onClose={onClose}
      title={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.remove))}
      confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.remove))}
      loadingStatus={loadingStatus}
      onConfirm={() => onDeleteCourseScenario(course.id, scenario.id)}
      DialogProps={{
        open,
        TransitionProps: {
          onExited
        }
      }}
    >
      <DialogContentText>
        {translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarioList.confirmationText))}{' '}
        <b>{`${scenario?.name}`}</b> {translate(nameof.full<I18nCourseManagementNs>((n) => n.scenarioList.from))}{' '}
        <b>{course?.title}</b>?
      </DialogContentText>
    </DeleteConfirmationDialog>
  );
};

export default DeleteCourseScenarioDialog;
