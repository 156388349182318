import { makeStyles } from '@material-ui/core/styles/index.js';

const drawerWidth = 300;

export default makeStyles((theme) => ({
  drawerPaper: {
    width: drawerWidth,
    borderRight: 0,
    background: theme.palette.primary.main,
    color: 'white',
    marginTop: theme.mixins.denseToolbar.minHeight,
    // TODO: remove this by overriding MUI theme colors
    '& > .MuiListItem-root .Mui-selected': {
      background: '#1f2d3d'
    },
    paddingBottom: 50
  },
  drawerIcons: {
    color: 'white'
  },
  drawerHeader: {
    background: '#162a35'
  },
  languagePicker: {
    padding: '4px 16px'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    // marginTop: theme.spacing(3)
  },
  textField: {
    width: 'calc( 100% - 24px )',
    padding: theme.spacing(0, 0, 0, 1.5)
  },
  hr: {
    borderBottomWidth: 2,
    borderBottomColor: '#ddd',
    borderBottomStyle: 'solid'
  },
  withoutTBP: {
    paddingTop: 0,
    paddingBottom: 0
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  withB: {
    borderBottomWidth: 2,
    borderBottomColor: '#ddd',
    borderBottomStyle: 'solid'
  },
  withTBP: {
    padding: theme.spacing(0.8, 2, 0.8, 2)
  },
  iconMWidth24: {
    minWidth: 24
  },
  paddingRight10: {
    paddingRight: 10
  },
  white: {
    color: 'white'
  },

  selectedListItem: {
    backgroundColor: theme.palette.secondary.main
  }
}));
