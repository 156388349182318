export const enum I18nNamespace {
  Common = 'common',
  Core = 'core',
  UserManagement = 'userManagement',
  Validation = 'validation',
  CourseManagement = 'courseManagement',
  ScenarioSection = 'scenarioSection',
  GroupManagement = 'groupManagement',
  ScheduledCalls = 'scheduledCalls',
  ProtocolReferences = 'protocolReferences',
  GlobalAssets = 'globalAssets',
  Dashboard = 'dashboard',
  ScenarioEditor = 'scenarioEditor'
}
