import { combineReducers } from 'redux';
import { userReducer } from './users/userReducer.js';
import { scenarioReducer } from './scenarios/scenarioReducer.js';
import { guiStateReducer } from './guiState/guiStateReducer.js';
import { userManagementReducer } from './userManagement/userManagementReducer.js';
import { scenarioChartReducer } from './scenarios/scenarioChartReducer.js';
import { intentClipboardReducer } from './scenarios/intentClipboard/index.js';
import { audioReducer } from './audio/audioReducer.js';
import { VoiceRecognitionReducer } from './recognition/VoiceRecognitionReducer.js';
import { dialogProcessorReducer } from './dialogProcess/dialogProcessorReducer.js';
import { companyReducer } from '../reducers/companyManagment/companyReducer.js';
import { dashboardReducer } from '../reducers/dashboard/dashboardReducer.js';
import { snackBarElementsReducer } from '../reducers/snackBarElements/snackBarElements.js';
import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import { groupManagementSlice } from 'app/components/GroupManagement/store/groupManagementSlice.js';
import courseManagementReducer, {
  courseManagementSlice
} from 'app/components/CourseManagement/store/courseManagementReducer.js';
import globalAssetsLibrarySlice from 'app/store/GlobalAssetsLibrary/globalAssetsLibrarySlice.js';
import callManagementSlice from 'app/components/CallManagement/store/callManagementSlice.js';

export const rootReducer = combineReducers({
  scenarioReducer: scenarioReducer,
  scenarioChartReducer: scenarioChartReducer,
  userReducer: userReducer,
  guiStateReducer: guiStateReducer,
  audioReducer: audioReducer,
  voiceRecognitionReducer: VoiceRecognitionReducer,
  userManagementReducer: userManagementReducer,
  dialogProcessorReducer: dialogProcessorReducer,
  companyReducer: companyReducer,
  dashboardReducer: dashboardReducer,
  intentClipboardReducer: intentClipboardReducer,
  snackBarElements: snackBarElementsReducer,
  [medicalIndexSlice.name]: medicalIndexSlice.reducer,
  groupManagement: groupManagementSlice.reducer,
  [courseManagementSlice.name]: courseManagementReducer,
  [globalAssetsLibrarySlice.name]: globalAssetsLibrarySlice.reducer,
  [callManagementSlice.name]: callManagementSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;
