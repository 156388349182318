import React, { useRef } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../useStyles.js';
import type { MedicalProtocolDto } from '@/generated-api/index.js';
import UsedByCompaniesEditor from 'app/components/MedicalIndexesManager/MedIndexCard/RightPane/CompanyAssignments/UsedByCompaniesEditor.js';
import classNames from 'classnames';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

export type RightPaneProps = {
  medIndex: MedicalProtocolDto;
};

function RightPane({ medIndex }: RightPaneProps): JSX.Element {
  const classes = useStyles();

  const containerRef = useRef();

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <div className={classes.summary} ref={containerRef}>
      <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
        {translate(nameof.full<I18nProtocolReferencesNs>((n) => n.chaptersCount))}
      </Typography>
      <Typography component="span" className={classes.summaryItem}>
        {medIndex.chapters.length}
      </Typography>

      <UsedByCompaniesEditor
        medIndex={medIndex}
        containerRef={containerRef}
        className={classNames(classes.summaryItem, classes.editable)}
        title={
          <Typography variant="subtitle2" component="span" className={classes.summaryItem}>
            {translate(nameof.full<I18nProtocolReferencesNs>((n) => n.usedBy))}
          </Typography>
        }
      />
    </div>
  );
}

export default RightPane;
