import React from 'react';
import { useSelector } from 'react-redux';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { TextFieldProps } from '@material-ui/core';
import { MenuItem, TextField } from '@material-ui/core';

import type { CompanyDto } from '@/generated-api/index.js';
import { CompaniesSelector } from 'app/selectors/index.js';

type SimpleCompanySelectFieldProps = TextFieldProps & {
  control: Control;
};

const SimpleCompanySelectField: React.FC<SimpleCompanySelectFieldProps> = ({
  name,
  error,
  label,
  control,
  disabled,
  required,
  autoFocus,
  helperText
}) => {
  const companies = useSelector(CompaniesSelector.companiesSelector);
  const isLoading = useSelector(CompaniesSelector.companiesAreLoadingSelector);

  return (
    <Controller
      select
      fullWidth
      name={name}
      type="text"
      error={error}
      label={label}
      as={TextField}
      margin="normal"
      control={control}
      required={required}
      helperText={helperText}
      disabled={disabled || isLoading}
      autoFocus={autoFocus && !isLoading}
    >
      {companies.map((company: CompanyDto) => (
        <MenuItem key={company.id} value={company.id}>
          {company.name}
        </MenuItem>
      ))}
    </Controller>
  );
};

export default SimpleCompanySelectField;
