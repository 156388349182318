import { makeStyles } from '@material-ui/core/styles/index.js';

export default makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#f0f3f6'
  },
  textField: {
    width: 'calc( 100% - 24px )',
    padding: theme.spacing(0, 0, 0, 1.5)
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutTBP: {
    paddingTop: 0,
    paddingBottom: 0
  },
  withB: {
    borderBottomWidth: 0,
    borderBottomColor: '#ddd',
    borderBottomStyle: 'solid'
  },
  withTBP: {
    padding: theme.spacing(0.8, 2, 0.8, 0.8)
  },
  iconMWidth24: {
    minWidth: 24
  },
  paddingRight10: {
    paddingRight: 10
  },
  selected: {
    backgroundColor: theme.palette.secondary.main
  },
  selectedIcon: {
    color: '#4071b0'
  },
  white: {
    color: 'white'
  },
  subMenuWidth: {
    minWidth: 44
  },
  subGroupSubMenuWidth: {
    minWidth: 64
  },
  inputClasses: {
    '&:before': {
      borderBottomColor: '#ffffffa0'
    },
    '&&&:hover:before': {
      borderBottomColor: '#ffffff'
    }
  },
  colapsedEntered: {
    backgroundColor: theme.palette.secondary.light
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  subHeader: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  nested: {
    paddingLeft: theme.spacing(2)
  },
  switchLight: {
    color: '#ffffffa0',
    justifyContent: 'flex-start'
  },
  sharedScenario: {
    color: 'lightgreen'
  }
}));
