import { setLocale } from 'yup';
import type { I18nValidationNs } from './dictionaries/interfaces.js';

export default function initYupLocale() {
  setLocale({
    mixed: {
      default: nameof.full<I18nValidationNs>((n) => n.fieldInvalid),
      required: nameof.full<I18nValidationNs>((n) => n.fieldRequired)
    }
  });
}
