import React from 'react';
import { LoadingStatus } from 'app/store/types.js';
import { Box, Card, CircularProgress, Typography } from '@material-ui/core';
import useStyles from '../useStyles.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

export type NoMedIndexDataProps = {
  loadingStatus: LoadingStatus;
  isMedIndexesLoading: boolean;
};

function NoMedIndexData({ loadingStatus, isMedIndexesLoading }: NoMedIndexDataProps): JSX.Element {
  let loadingIcon: React.ReactNode = null;
  let caption: React.ReactNode = null;

  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  if (isMedIndexesLoading) {
    loadingIcon = <CircularProgress size={80} />;
  } else {
    switch (loadingStatus) {
      case LoadingStatus.Idle:
      case LoadingStatus.Loading:
        loadingIcon = <CircularProgress size={80} />;
        break;

      case LoadingStatus.Succeeded:
        caption = translate(nameof.full<I18nProtocolReferencesNs>((n) => n.selectaProtocolReference));
        break;

      case LoadingStatus.Failed:
        caption = 'An error occurred :(';
        break;
    }
  }

  return (
    <Card square classes={{ root: classes.cardEmpty }}>
      {loadingIcon ?? (
        <Box color={'action.disabled'}>
          <Typography variant={'h4'}>{caption}</Typography>
        </Box>
      )}
    </Card>
  );
}

export default NoMedIndexData;
