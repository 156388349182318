import React from 'react';
import ScenarioListItem from '../ScenarioListItem.js';
import type { SceneDto } from '@/generated-api/scenes/api.js';

type SceneGroupContainerListItemProps = {
  scene: SceneDto;
  sceneId: number;
  classes: {
    selected: string;
    withB: string;
    withTBP: string;
    sharedScenario: string;
    subMenuWidth: string;
    white: string;
    nested: string;
    subGroupSubMenuWidth: string;
  };
  handleSelectScenario: () => void;
  handleRightClick: (event: React.MouseEvent<HTMLElement>, scene: SceneDto) => void;
  isSubGroupItem?: boolean;
  disableActions?: boolean;
};

const SceneGroupContainerListItem: React.FC<SceneGroupContainerListItemProps> = ({
  scene,
  sceneId,
  classes,
  handleSelectScenario,
  handleRightClick,
  isSubGroupItem,
  disableActions
}) => (
  <ScenarioListItem
    scene={scene}
    classes={classes}
    handleRightClick={handleRightClick}
    handleSelectScenario={handleSelectScenario}
    isSelected={scene.id === sceneId}
    isSubGroupItem={isSubGroupItem}
    disableActions={disableActions}
  />
);

export default SceneGroupContainerListItem;
