import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress
} from '@material-ui/core';
import type { MedicalProtocolActionDto, MedicalProtocolChapterDto, MedicalProtocolDto } from '@/generated-api/index.js';
import { ProcessStatus } from 'app/types/UtilityType.js';
import { useUnmounted } from 'app/utils/customHooks/index.js';
import { MedicalIndexClient } from 'app/apis/api.js';
import { useDispatch } from 'react-redux';
import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';
import logger from 'app/utils/logger.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nProtocolReferencesNs } from '@/i18n/dictionaries/interfaces.js';

const { actions } = medicalIndexSlice;

export type DeleteActionDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  medIndexId: MedicalProtocolDto['id'];
  chapterId: MedicalProtocolChapterDto['id'];
  action: MedicalProtocolActionDto;
};

function DeleteActionDialog({ isOpen, onClose, medIndexId, chapterId, action }: DeleteActionDialogProps): JSX.Element {
  const [status, setStatus] = useState(ProcessStatus.Idle);

  const [translate] = useTranslation([I18nNamespace.ProtocolReferences]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const dispatch = useDispatch();
  const unmountedRef = useUnmounted();
  useEffect(() => {
    if (status !== ProcessStatus.StartProcess) {
      return;
    }

    setStatus(ProcessStatus.Processing);
    const process = async () => {
      try {
        const { data: updatedMedIndex } = await MedicalIndexClient.medicalIndexDeleteAction(
          medIndexId,
          chapterId,
          action?.id
        );

        dispatch(actions.indexUpdated(updatedMedIndex));

        if (!unmountedRef.current) {
          setStatus(ProcessStatus.Idle);
          onClose();
        }
      } catch (e) {
        logger.error('An error occurred during deleting action');

        if (!unmountedRef.current) {
          setStatus(ProcessStatus.Idle);
        }
      }
    };

    process();
  }, [dispatch, action, onClose, status, chapterId, unmountedRef, medIndexId]);

  const onDelete = () => setStatus(ProcessStatus.StartProcess);

  const processing = status !== ProcessStatus.Idle;

  return (
    <Dialog open={isOpen} maxWidth={'xs'} fullWidth>
      <DialogTitle>
        {translate(nameof.full<I18nProtocolReferencesNs>((n) => n.deleteActionForm.deleteAction))}
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography>
            {translate(nameof.full<I18nProtocolReferencesNs>((n) => n.deleteActionForm.confirmationText))}{' '}
            {`"${action?.name}"`}?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}</Button>
        <Button color={'primary'} form="create-course-form" onClick={onDelete} disabled={processing}>
          {processing ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteActionDialog;
