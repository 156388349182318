import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getI18nSupportedLanguageList, I18nLanguageNames } from '../../../i18n/types/i18nLanguage.js';

import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nCoreNs } from '@/i18n/dictionaries/interfaces.js';

type LanguageSettingsDialogProps = {
  open: boolean;
  onClose: () => void;
};

const enum BrowserLanguages {
  English = 'en',
  Norwegian = 'no',
  German = 'de',
  EnglishUs = 'en-US',
  EnglishUk = 'en-GB'
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  formControl: {
    minWidth: 200
  },
  tooltip: {
    fontSize: 12
  }
}));

const LanguageSettingsDialog = (props: LanguageSettingsDialogProps) => {
  const { open, onClose } = props;
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const [translateCore] = useTranslation([I18nNamespace.Core]);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const handleLanguageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedLanguage(event.target.value as string);
  };

  const onLocalClose = () => {
    console.log('onclose!');
    onClose();
  };

  const onSave = () => {
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('userLocalization', selectedLanguage);
    onLocalClose();
  };

  return (
    <Dialog open={open} onClose={onLocalClose}>
      <DialogContent>
        <Grid container direction="column" className={classes.container} spacing={2}>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              {translateCommon(nameof.full<I18nCommonNs>((n) => n.languageSettings.selectLanguage))}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" gutterBottom>
              <b>Hi</b>
              <i>Sklls</i> {translateCommon(nameof.full<I18nCommonNs>((n) => n.languageSettings.explanationText))}
            </Typography>
          </Grid>
          <Grid item>
            <FormControl>
              <InputLabel id="language-select-label">Language</InputLabel>
              <Select labelId="language-select-label" value={selectedLanguage} onChange={handleLanguageChange}>
                <MenuItem key={BrowserLanguages.English} data-cy="English" value={BrowserLanguages.English}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.english))}
                </MenuItem>
                <MenuItem key={BrowserLanguages.Norwegian} data-cy="Norwegian" value={BrowserLanguages.Norwegian}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.norwegian))}
                </MenuItem>
                <MenuItem key={BrowserLanguages.German} data-cy="German" value={BrowserLanguages.German}>
                  {translateCore(nameof.full<I18nCoreNs>((l) => l.languagePicker.german))}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLocalClose}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <Button variant="contained" onClick={onSave}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.save))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LanguageSettingsDialog;
