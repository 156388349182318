import React from 'react';
import { Tab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';

export type NavigationTabProps = {
  value: string;
  label: string;
  exact?: boolean;
  disabled?: boolean;
};

const NavigationTab: React.FC<NavigationTabProps> = ({ value, label, exact = false, ...rest }) => {
  const match = useRouteMatch({
    exact,
    path: value
  });

  return <Tab {...rest} component={Link} to={value} label={label} value={match?.path} />;
};

export default NavigationTab;
