import React from 'react';
import type { ButtonProps } from '@material-ui/core';
import { Button, CircularProgress } from '@material-ui/core';

import { LoadingStatus } from 'app/store/types.js';

type LoadingButtonProps = ButtonProps & {
  loadingStatus: LoadingStatus;
};

const LoadingButton: React.FC<LoadingButtonProps> = ({ children, disabled, loadingStatus, ...rest }) => {
  const isLoading = loadingStatus === LoadingStatus.Loading;

  return (
    <Button disabled={disabled || isLoading} {...rest}>
      {isLoading ? <CircularProgress color="secondary" size={24} /> : children}
    </Button>
  );
};

export default LoadingButton;
