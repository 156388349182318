import type { FC } from 'react';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ScenarioActions from '../../../actions/scenarios/scenarioAction.js';
import { CompaniesSelector, ScenarioSelectors, UserSelectors } from '../../../selectors/index.js';
import { ListItem, ListItemText, ListItemIcon, Button, Switch, Collapse } from '@material-ui/core';

import clsx from 'clsx';

import AddScenarioSection from './AddScenarioSection/index.js';
import useStyles from './styles.js';

import type { SceneDto } from '@/generated-api/index.js';

import ScenarioSearch from './ScenarioSearch/index.js';
import { isUserAnyAdminSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import type { SceneWithCompanyName } from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/types.js';
import NavigationDrawerScenarioList from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/NavigationDrawerScenarioList.js';
import logger from 'app/utils/logger.js';
import { AccountTree, ExpandLess, ExpandMore } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import type { I18nScenarioSectionNs } from '@/i18n/dictionaries/interfaces.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';

const ScenarioSection: FC = () => {
  const [translate] = useTranslation(I18nNamespace.ScenarioSection);
  const classes = useStyles();
  const dispatch = useDispatch();
  const scenarios = useSelector(ScenarioSelectors.scenariosSelector);
  const companies = useSelector(CompaniesSelector.companiesSelector);
  const isUserSuperAdmin = useSelector(isUserSuperAdminSelector);
  const currentUser = useSelector(UserSelectors.CurrentUserSelector);

  const [open, setOpen] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [isShowGroupsEnabled, setIsShowGroupsEnabled] = useState(true);

  const scenesWithCompanyName = useMemo(() => {
    return scenarios
      .map((scene: SceneDto) => {
        if (!scene.companyId) {
          if (!isUserSuperAdmin && scene.sharedCompanies?.find((sc) => sc.id === currentUser.companyId)) {
            return { ...scene, companyName: currentUser.companyName };
          }

          return {
            ...scene,
            companyName: translate(nameof.full<I18nScenarioSectionNs>((l) => l.sharedScenarios)),
            companyId: 0
          };
        }
        const company = companies.find((company) => company.id === scene.companyId);
        return { ...scene, companyName: company?.name };
      })
      .filter((scenario) => !!scenario.companyName)
      .sort((a, b) => a.companyName.localeCompare(b.companyName));
  }, [companies, currentUser, isUserSuperAdmin, scenarios]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSelectScenario = () => {
    logger.log('dispatch was done!');
    dispatch(ScenarioActions.toNextLinkedScenario(false));
  };

  const filterScenes = useMemo((): Array<SceneWithCompanyName> => {
    if (!!searchWord && !searchWord.trim()) {
      return scenesWithCompanyName;
    }

    return scenesWithCompanyName.filter((x) => x.name.toLowerCase().indexOf(searchWord.toLocaleLowerCase()) >= 0);
  }, [searchWord, scenesWithCompanyName]);

  const search = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchWord(event.target.value);
  };

  const handleShowGroups = () => {
    setIsShowGroupsEnabled(!isShowGroupsEnabled);
  };

  const getScenarios = useCallback(() => dispatch(ScenarioActions.getScenarios()), [dispatch]);

  useEffect(() => {
    getScenarios();
  }, [getScenarios]);

  const isAnyAdmin = useSelector(isUserAnyAdminSelector);

  return (
    <>
      <ListItem button data-cy="scenarioOpenListButton" onClick={handleClick}>
        <ListItemIcon className={clsx(classes.iconMWidth24, classes.paddingRight10)}>
          <AccountTree classes={{ root: classes.white }} />
        </ListItemIcon>
        <ListItemText primary={translate(nameof.full<I18nScenarioSectionNs>((l) => l.scenarios))} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit classes={{ entered: classes.colapsedEntered }}>
        {isAnyAdmin && (
          <Button fullWidth className={classes.switchLight} onClick={handleShowGroups}>
            <Switch size="small" checked={isShowGroupsEnabled} />
            {translate(nameof.full<I18nScenarioSectionNs>((l) => l.showFolders))}
          </Button>
        )}
        <ScenarioSearch search={search} />

        {isAnyAdmin && <AddScenarioSection />}

        <NavigationDrawerScenarioList
          filterScenes={filterScenes}
          isShowGroupsEnabled={isShowGroupsEnabled}
          handleSelectScenario={handleSelectScenario}
        />
      </Collapse>
    </>
  );
};

export default ScenarioSection;
