import React, { useCallback } from 'react';
import { DialogContentText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import type { CourseDto, ScheduledCourseDto, ScheduledCourseUserDto } from '@/generated-api/index.js';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';
import * as CourseManagementActions from 'app/components/CourseManagement/store/courseManagementActions.js';
import * as CourseManagementSelectors from 'app/components/CourseManagement/store/courseManagementSelectors.js';

import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';

type DeleteScheduledCourseUserDialogProps = {
  open: boolean;
  courseId: string;
  course: CourseDto;
  onClose: () => void;
  onExited: () => void;
  scheduledCourse: ScheduledCourseDto;
  scheduledCourseUser: ScheduledCourseUserDto;
};

const DeleteScheduledCourseUserDialog: React.FC<DeleteScheduledCourseUserDialogProps> = ({
  open,
  course,
  onClose,
  onExited,
  scheduledCourse,
  scheduledCourseUser
}) => {
  const dispatch = useDispatch();

  const onDeleteScheduledCourse = useCallback(
    () =>
      dispatch(
        CourseManagementActions.deleteScheduledCourseUser(
          course.id,
          scheduledCourse.id,
          scheduledCourseUser.userId,
          onClose
        )
      ),
    [course, dispatch, scheduledCourse, onClose, scheduledCourseUser]
  );
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const loadingStatus = useSelector(CourseManagementSelectors.deleteScheduledCourseUserLoadingStatus);

  return (
    <DeleteConfirmationDialog
      onClose={onClose}
      title={translate(nameof.full<I18nCourseManagementNs>((n) => n.removeUserForm.removeUser))}
      confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.remove))}
      loadingStatus={loadingStatus}
      onConfirm={onDeleteScheduledCourse}
      DialogProps={{
        open: open,
        TransitionProps: {
          onExited: onExited
        }
      }}
    >
      <DialogContentText>
        {translate(nameof.full<I18nCourseManagementNs>((n) => n.removeUserForm.confirmationText))}{' '}
        <b>{scheduledCourseUser?.name}</b>{' '}
        {translate(nameof.full<I18nCourseManagementNs>((n) => n.removeUserForm.from))} <b>{course?.title}</b>?
      </DialogContentText>
    </DeleteConfirmationDialog>
  );
};

export default DeleteScheduledCourseUserDialog;
