import { number, object, string } from 'yup';
import type {
  CreateCourseCommand,
  CreateScheduledCourseCommand,
  ScheduledCourseAssignmentCommand,
  UpdateCourseCommand,
  UpdateScheduledCourseRequestCommand
} from '@/generated-api/index.js';
import type { CreateCourseScenarioForm } from 'app/components/CourseManagement/CourseCard/AddScenarioDialog/CreateCourseScenarioDialog.js';

export const createCourse = object<CreateCourseCommand>({
  title: string().trim().required(),
  companyId: number().required()
});

export const updateCourse = object<UpdateCourseCommand>({
  title: string().trim().required()
});

export const createCourseScenario = object<CreateCourseScenarioForm>({
  sceneId: number().required().notOneOf([0])
});

export const createScheduledCourse = object<CreateScheduledCourseCommand>({
  departmentId: number(),
  startDate: string().required(),
  endDate: string().required()
});

export const updateScheduledCourse = object<UpdateScheduledCourseRequestCommand>({
  startDate: string().required(),
  endDate: string().required()
});

export const createScheduledCourseUser = object<ScheduledCourseAssignmentCommand>({
  id: number().required().notOneOf([0])
});
