import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ScenarioSelectors, UserSelectors } from 'app/selectors/index.js';
import * as ScenarioActions from 'app/actions/scenarios/scenarioAction.js';
// import { TTSOptions } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TTSOptions.js';
import type { QualitySettingsDto, SceneContextDto, SceneDto, Speaker } from '@/generated-api/index.js';
import { IntentProcessingProvider, QualityLevel, SttProvider, LLM } from '@/generated-api/index.js';
import { ScenarioSettingsLanguageVals } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/types.js';
import { useValidationSchema } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/ScenarioGeneralSettings/validation.js';
import type { ScenarioSettingsFormProps } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/ScenarioGeneralSettings/types.js';
import { useQuery } from '@tanstack/react-query';
import { voicesQuery } from 'app/queries/speechQueries.js';
import logger from 'app/utils/logger.js';

export const qualities = [
  QualityLevel.NUMBER_3,
  QualityLevel.NUMBER_2,
  QualityLevel.NUMBER_1,
  QualityLevel.NUMBER_0
] as const;

export type TransitionScoresSettingsFormType = Record<QualityLevel, Record<QualityLevel, number>>;

export const defaultWeights: TransitionScoresSettingsFormType = {
  [QualityLevel.NUMBER_3]: {
    [QualityLevel.NUMBER_3]: 0,
    [QualityLevel.NUMBER_2]: -5,
    [QualityLevel.NUMBER_1]: -10,
    [QualityLevel.NUMBER_0]: 0
  },
  [QualityLevel.NUMBER_2]: {
    [QualityLevel.NUMBER_3]: 3,
    [QualityLevel.NUMBER_2]: 0,
    [QualityLevel.NUMBER_1]: -5,
    [QualityLevel.NUMBER_0]: 0
  },
  [QualityLevel.NUMBER_1]: {
    [QualityLevel.NUMBER_3]: 6,
    [QualityLevel.NUMBER_2]: 3,
    [QualityLevel.NUMBER_1]: 0,
    [QualityLevel.NUMBER_0]: 0
  },
  [QualityLevel.NUMBER_0]: {
    [QualityLevel.NUMBER_3]: 6,
    [QualityLevel.NUMBER_2]: 3,
    [QualityLevel.NUMBER_1]: 0,
    [QualityLevel.NUMBER_0]: 0
  }
};

export type ScenarioSettingsType = Pick<
  SceneDto,
  | 'id'
  | 'name'
  | 'description'
  | 'sex'
  | 'voiceName'
  | 'langCode'
  | 'ttsProvider'
  | 'medicalProtocolChapters'
  | 'medicalProtocolChapterIds'
  | 'scenarioContextIntro'
  | 'playScenarioContextOn'
  | 'sttProvider'
  | 'contexts'
  | 'defaultPersonaId'
  | 'intentProcessingProvider'
  | 'llm'
  | 'useStreaming'
  | 'maxTokens'
> & {
  createSharedScenario?: boolean;
  transitionScoresSettings: TransitionScoresSettingsFormType;
};

const defaultContext: SceneContextDto = {
  id: 0,
  facts: [],
  personas: [],
  locations: []
};

// const defaultTTSOption = TTSOptions.find((o) => o.languageCode === ScenarioSettingsLanguageVals.English);

const defaultFormValues: ScenarioSettingsType = {
  id: 0,
  name: '',
  description: '',
  medicalProtocolChapters: [],
  medicalProtocolChapterIds: [],
  transitionScoresSettings: defaultWeights,
  scenarioContextIntro: null,
  playScenarioContextOn: false,
  sttProvider: SttProvider.NUMBER_0,
  contexts: [defaultContext],
  defaultPersonaId: null,
  intentProcessingProvider: IntentProcessingProvider.NUMBER_1,
  createSharedScenario: false,
  llm: LLM.NUMBER_2,
  useStreaming: false,
  maxTokens: 25
};

function qualityLevelToFormData(qualityLevels: SceneDto['transitionScoresSettings']): TransitionScoresSettingsFormType {
  return qualities.reduce<TransitionScoresSettingsFormType>((obj, output) => {
    obj[output] = qualities.reduce((obj, input) => {
      obj[input] =
        qualityLevels?.find(
          (transitionWeight) => transitionWeight.origin === output && transitionWeight.destination === input
        )?.value ?? 0;
      return obj;
    }, {} as Record<QualityLevel, number>);

    return obj;
  }, {} as TransitionScoresSettingsFormType);
}

function getFormDefaultData(scenario?: SceneDto, defaultTTSOption?: Speaker) {
  return scenario != null
    ? {
        ...scenario,
        severityWorkFlowIds: scenario.medicalProtocolChapters ?? [],
        transitionScoresSettings: qualityLevelToFormData(scenario.transitionScoresSettings),
        scenarioContextIntro: scenario.scenarioContextIntro ?? '',
        playScenarioContextOn: scenario.playScenarioContextOn ?? false,
        sttProvider: scenario.langCode === 'nb-NO' ? SttProvider.NUMBER_1 : SttProvider.NUMBER_0,
        llm: scenario.llm == null ? LLM.NUMBER_2 : scenario.llm,
        useStreaming: scenario.useStreaming == null ? false : scenario.useStreaming,
        maxTokens: scenario.maxTokens ?? 25,
        contexts: scenario.contexts?.length ? scenario.contexts : [defaultContext]
      }
    : {
        ...defaultFormValues,
        ttsProvider: defaultTTSOption?.ttsProvider,
        langCode: defaultTTSOption?.languageCode,
        sex: defaultTTSOption?.sex,
        voiceName: defaultTTSOption?.name ?? null,
        maxTokens: 25
      };
}

const useScenarioForm = (
  onClose: ScenarioSettingsFormProps['onClose'],
  scenarioId?: ScenarioSettingsFormProps['scenarioId']
) => {
  const { data: allVoiceOptions } = useQuery(voicesQuery);
  const scenario = useSelector(ScenarioSelectors.getScenarioById(scenarioId));
  const currentUser = useSelector(UserSelectors.CurrentUserSelector);

  const defaultTTSOption = useMemo(() => {
    if (!allVoiceOptions) return undefined;
    return allVoiceOptions
      .filter((v) => !v.companyId || v.companyId === currentUser.companyId)
      .find((v) => v.languageCode === ScenarioSettingsLanguageVals.English);
  }, [allVoiceOptions, currentUser.companyId]);

  const defaultValues = useMemo(() => {
    return getFormDefaultData(scenario, defaultTTSOption);
  }, [defaultTTSOption, scenario]);

  const methods = useForm<ScenarioSettingsType>({
    resolver: yupResolver(useValidationSchema()),
    defaultValues: defaultValues,
    mode: 'onChange',
    shouldUnregister: false
  });

  useEffect(() => {
    methods.reset(defaultValues);
    methods.register({ name: 'sex', type: 'custom' });
    methods.register({ name: 'voiceName', type: 'custom' });
    methods.register({ name: 'Id', type: 'custom' });
  }, [defaultValues, methods.reset]);

  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = useCallback(
    methods.handleSubmit((formData: ScenarioSettingsType) => {
      const submitAction = scenarioId != null ? ScenarioActions.updateScenarioDetails : ScenarioActions.addScenario;

      logger.log(formData);

      const transformed: Omit<ScenarioSettingsType, 'transitionScoresSettings'> &
        Pick<SceneDto, 'transitionScoresSettings' | 'companyId'> = {
        ...formData,
        transitionScoresSettings: Object.entries(formData.transitionScoresSettings).flatMap<QualitySettingsDto>(
          ([output, record]) =>
            Object.entries(record).map<QualitySettingsDto>(([input, value]) => ({
              origin: +output,
              destination: +input,
              value: +(value ?? 0)
            }))
        ),
        playScenarioContextOn: !!formData.playScenarioContextOn,
        contexts: formData.contexts?.map((context) => ({
          ...context,
          personas: context.personas?.map((persona) => ({
            ...persona
          }))
        })),
        companyId: formData.createSharedScenario ? null : currentUser.companyId,
        description: formData.description,
        useStreaming: formData.useStreaming,
        maxTokens: formData.maxTokens
      };

      logger.log(transformed);

      dispatch(
        submitAction({
          ...transformed,
          id: scenarioId
        })
      );

      onClose();
    }),
    [methods.handleSubmit, dispatch, scenarioId, onClose]
  );

  return { methods, onSubmit };
};

export default useScenarioForm;
