import { makeStyles } from '@material-ui/core/styles/index.js';

interface StyleProps {
  backgroundColor: string;
}

export default makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 6
  },
  cardFlexGrow: {
    flexGrow: 1
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    '&:last-child': {
      padding: 8
    }
  },
  list: {
    overflowY: 'auto'
  },
  startIcon: {
    marginRight: 10,
    color: '#607D8B'
  },
  flightTakeoffIcon: (props: StyleProps) => ({
    color: 'white',
    backgroundColor: props.backgroundColor,
    borderRadius: 17,
    padding: 3
  })
});
