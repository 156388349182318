import { makeStyles } from '@material-ui/core/styles/index.js';

const drawerWidth = '32vw';
// this is hardcoded into MUI, so I don't feel bad about hardcoding it here
const denseToolbarHeightPx = 48;

export default makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  offsetPosition: {
    top: denseToolbarHeightPx * 2,
    height: `calc(100vh - ${denseToolbarHeightPx * 2}px)`
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    overflowY: 'hidden'
  },
  drawerBorderUnset: {
    border: 'none'
  },

  formContainer: {
    overflowY: 'auto',
    backgroundColor: 'white',
    padding: 20,
    width: drawerWidth
  },

  displayNone: {
    display: 'none'
  },
  displayFlex: {
    display: 'flex'
  },
  buttonContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    pointerEvents: 'none'
  },
  openButtonContainer: {
    position: 'absolute',
    right: 0,
    zIndex: 1
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    pointerEvents: 'all',
    minWidth: 36,
    padding: 6,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  doubleArrowLeft: {
    transform: 'scaleX(-1)'
  }
}));
