import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import useStyles from './useStyles.js';
import Actions from './Actions/Actions.js';
import { LoadingStatus } from 'app/store/types.js';

import {
  indexesSelector,
  medicalIndexesLoadingStatusSelector,
  isMedicalIndexesStatusLoadingSelector
} from 'app/store/MedicalIndex/medicalIndexSelectors.js';

import NoMedIndexData from './NoData/NoMedIndexData.js';
import EditableTitle from './EditableTitle/EditableTitle.js';
import RightPane from './RightPane/RightPane.js';
import ChaptersActions from './ChapterList/ChaptersActions/ChaptersActions.js';
import ChaptersList from './ChapterList/ChaptersList.js';

function MedIndexCard(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const isMedIndexesLoading = useSelector(isMedicalIndexesStatusLoadingSelector);
  const loadingStatus = useSelector(medicalIndexesLoadingStatusSelector);

  const medIndexes = useSelector(indexesSelector);

  const medIndex = useMemo(() => {
    return medIndexes.find((c) => String(c.id) == id);
  }, [id, medIndexes]);

  const classes = useStyles();

  if (loadingStatus !== LoadingStatus.Succeeded || !medIndex || isMedIndexesLoading) {
    return <NoMedIndexData loadingStatus={loadingStatus} isMedIndexesLoading={isMedIndexesLoading} />;
  }

  return (
    <Card square classes={{ root: classes.card }}>
      <EditableTitle medIndex={medIndex} />

      <CardContent classes={{ root: classes.cardContentRoot }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={7}>
            <Box display={'flex'} flexDirection={'column'}>
              <Actions medIndex={medIndex} />

              <Box mt={4}>
                <Typography variant={'h6'}>Chapters</Typography>
              </Box>

              <ChaptersActions medIndex={medIndex} />
              <ChaptersList medIndex={medIndex} />
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <RightPane medIndex={medIndex} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default MedIndexCard;
