import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Box,
  Grid,
  Menu,
  Avatar,
  Divider,
  MenuItem,
  Typography,
  ButtonBase,
  IconButton,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles.js';
import AppRoutes from 'app/utils/routes.js';
import { GuiStateSelectors, UserSelectors } from 'app/selectors/index.js';
import { GuiStateActions } from 'app/actions/guiState/guiStateAction.js';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import type { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { loginRequest, graphLoginRequest } from '@/authConfig.js';
import axios from 'axios';
import AudioSettingsDialog from '../Audio/AudioSettingsDialog.js';
import LanguageSettingsDialog from './LanguageSettingsDialog.js';
import { ViewHeadline, VolumeUp, MenuOpen, ExitToApp, Language, Domain, Check } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

type HeaderProps = {
  displaySidebarMenu?: boolean;
};

const logout = (instance: IPublicClientApplication) => {
  instance.logoutRedirect({
    ...loginRequest,
    postLogoutRedirectUri: window.location.origin,
    // This is to avoid logging out everywhere, only logging out in the app
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onRedirectNavigate: (_) => false
  });
};

const getLoggedInUserPhoto = async (instance: IPublicClientApplication, account: AccountInfo) => {
  return ''; // The code below doesn't work yet

  try {
    const tokenResponse = await instance.acquireTokenSilent({ ...graphLoginRequest, account });
    const graphEndpoint = 'https://graph.microsoft.com/v1.0/me/photo/$value';
    const response = await axios(graphEndpoint, {
      headers: {
        Authorization: `Bearer ${tokenResponse.accessToken}`
      }
    });
    const avatar = Buffer.from(response.data, 'binary').toString('base64');

    return 'data:image/jpeg;base64, ' + avatar;
  } catch (e) {
    console.log('Failed to get user photo');
    return '';
  }
};

const Header: React.FC<HeaderProps> = ({ displaySidebarMenu = true }) => {
  const classes = useStyles();
  const { accounts, instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const user = useMemo(() => (accounts.length ? accounts[0] : undefined), [accounts]);
  const [userPhoto, setUserPhoto] = useState<string>('');
  const [translateCommon] = useTranslation([I18nNamespace.Common]);
  const dispatch = useDispatch();
  const state = useSelector(GuiStateSelectors.sideBarStateSelector);

  const currentUser = useSelector(UserSelectors.CurrentUserSelector);

  const setState = () => {
    dispatch(GuiStateActions.sideMenuOpen(!state));
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const [audioSettingsOpen, setAudioSettingsOpen] = useState(false);
  const [languageSettingsOpen, setLanguageSettingsOpen] = useState(false);

  const isUserMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    const asyncFunc = async () => {
      const photo = await getLoggedInUserPhoto(instance, accounts[0]);
      setUserPhoto(photo);
    };

    if (isAuthenticated && !userPhoto && accounts.length) {
      // TODO This does not work! 401 unauthorized..
      asyncFunc();
    }
  }, [accounts, instance, isAuthenticated, userPhoto]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar variant="dense">
          <Grid className={classes.iconIsleWidth} direction="row" alignItems="center" container item xs={12}>
            <Grid direction="row" alignItems="center" container item className={classes.maxWidth}>
              <Grid item>
                <Box display={'flex'} flexDirection={'column'} className={classes.headerToolbar}>
                  <Link to={AppRoutes.Dashboard} className={classes.headerToolbarLink}>
                    <Typography variant="h1" className={classes.headerToolbarTitle}>
                      <strong>Hi</strong>
                      <i>Sklls by</i>
                      <br />
                    </Typography>
                    <div className={classes.logo} />
                  </Link>
                </Box>
              </Grid>
              <Grid item style={{ marginLeft: 'auto' }}>
                {displaySidebarMenu && (
                  <IconButton onClick={setState} data-cy="sidebarWrapper" color="inherit">
                    {!state ? <ViewHeadline /> : <MenuOpen />}
                  </IconButton>
                )}
              </Grid>
            </Grid>
            {isAuthenticated && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                className={classes.headerToolsWidth}
              >
                <Grid item>
                  <Avatar src={userPhoto} onClick={handleClick} component={ButtonBase} className={classes.userAvatar} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Toolbar>
        <Menu open={isUserMenuOpen} anchorEl={anchorEl} onClick={handleClose} onClose={handleClose}>
          <MenuItem disabled>
            <ListItemIcon>
              <Avatar src={userPhoto} component={ButtonBase} className={classes.userAvatar} />
            </ListItemIcon>
            <ListItemText primary={user?.name}></ListItemText>
          </MenuItem>
          <MenuItem disabled>
            <ListItemIcon>
              <Domain fontSize="medium" />
            </ListItemIcon>
            <ListItemText primary={currentUser?.companyName} secondary={currentUser?.departmentName}></ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => setAudioSettingsOpen(true)}>
            <ListItemIcon>
              <VolumeUp fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={translateCommon(nameof.full<I18nCommonNs>((n) => n.audioSettings.audioSettings))}
            ></ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setLanguageSettingsOpen(true)}>
            <ListItemIcon>
              <Language fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translateCommon(nameof.full<I18nCommonNs>((n) => n.language))}></ListItemText>
          </MenuItem>
          <MenuItem onClick={() => logout(instance)}>
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={translateCommon(nameof.full<I18nCommonNs>((n) => n.signOut))}></ListItemText>
          </MenuItem>
        </Menu>
      </AppBar>
      {/*https://material-ui.com/components/app-bar/#fixed-placement*/}
      <div className={classes.appbarOffset} />
      <AudioSettingsDialog open={audioSettingsOpen} onClose={() => setAudioSettingsOpen(false)} />
      <LanguageSettingsDialog open={languageSettingsOpen} onClose={() => setLanguageSettingsOpen(false)} />
    </>
  );
};

export default Header;
