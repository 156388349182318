import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress
} from '@material-ui/core';
import type { GlobalAssetFullDto } from '@/generated-api/index.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nGlobalAssetsNs } from '@/i18n/dictionaries/interfaces.js';

export type DeleteDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  isDeleting: boolean;
  deleteCategory: () => void;
  category: GlobalAssetFullDto;
};

function DeleteCategoryDialog({
  isOpen,
  onClose,
  isDeleting,
  deleteCategory,
  category
}: DeleteDialogProps): JSX.Element {
  const [translate] = useTranslation([I18nNamespace.GlobalAssets]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Dialog open={isOpen} maxWidth={'md'}>
      <DialogTitle>
        {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.deleteCategoryDialog.deleteCategory))}
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography>
            {translate(nameof.full<I18nGlobalAssetsNs>((n) => n.deleteCategoryDialog.confirmationText))}{' '}
            {category?.title}?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isDeleting}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel))}
        </Button>
        <Button color={'primary'} onClick={deleteCategory} disabled={isDeleting}>
          {isDeleting ? (
            <CircularProgress color="secondary" size={24} />
          ) : (
            translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCategoryDialog;
