import React, { useState } from 'react';
import { Accordion, AccordionSummary, styled } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore.js';
import HorizontalBadge from 'app/components/ScenarioEditorPage/ScenarioDisplayComponent/IntentEditingComponent/IntentEditingForm/HorizontalBadge.js';

export type CustomAccordionProps = {
  disableBadge?: boolean;
  badgeNumber?: number | null;
  title: React.ReactNode;
  defaultExpanded?: boolean;
  children?: React.ReactNode;
};

const ShadowlessAccordion = styled(Accordion)({
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
});

function CustomAccordion({
  badgeNumber,
  disableBadge = false,
  title,
  defaultExpanded = true,
  children
}: CustomAccordionProps): JSX.Element {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const isBadgeDisplayed = !disableBadge && badgeNumber != null && !expanded;

  return (
    <ShadowlessAccordion expanded={expanded} onChange={(e, expanded) => setExpanded(expanded)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {isBadgeDisplayed && (
          <HorizontalBadge badgeContent={badgeNumber} color={badgeNumber === 0 ? 'error' : 'primary'} showZero />
        )}
        {title}
      </AccordionSummary>
      {children}
    </ShadowlessAccordion>
  );
}

export default CustomAccordion;
