import type { UseQueryOptions } from '@tanstack/react-query';
import { DialogStatisticsClient } from 'app/apis/api.js';
import { getIntentsWithduplicatesAmkNames } from 'app/middleware/sagas/dashboard/utils.js';
import type { DialogStatisticFull } from 'app/models/statistic/statistic.js';

export const getDialogStatisticsFullQuery = (id: number | undefined): UseQueryOptions<DialogStatisticFull> => ({
  queryKey: ['dialogStatisticsFullQuery', id],
  queryFn: async () => {
    if (!id) return null; // This fixes delete
    const { data } = await DialogStatisticsClient.dialogStatisticsGetDialogStatistic(id);

    // TODO Check if this is really necessary??
    const full = getIntentsWithduplicatesAmkNames(data);
    return full;
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 5 * 60 * 1000,
  keepPreviousData: true
  // set long cache time
});
