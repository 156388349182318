import { Drawer, Box } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles.js';
import useDelete from 'app/hooks/useDelete.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import { UserManagementSelectors } from 'app/selectors/index.js';
import UsersTable from 'app/components/UsersPage/UserTable/index.js';
import UserEditForm from 'app/components/UsersPage/UserForm/index.js';
import useEditUser from 'app/components/UsersPage/UserForm/useEditUser.js';
import UserDeleteDialog from 'app/components/UsersPage/UserDelete/UserDeleteDialog.js';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import UserInvitationDialog from 'app/components/UsersPage/UserInvitation/UserInvitationDialog.js';

const UsersPage: React.FC = () => {
  const {
    isRightPaneOpen,
    handleCloseForm,
    selectedUserId: selectedUserIdToEdit,
    onSelectUserId: onSelectUserIdToEdit
  } = useEditUser();

  const {
    closeConfirmationDialog,
    isConfirmationDialogOpen,
    selectedItem: selectedUserIdToDelete,
    onSelectItem: onSelectUserIdToDelete,
    resetSelectedItem: resetSelectedUserIdToDelete
  } = useDelete<number>();

  const [isUserInviteDialogOpen, openUserInviteDialog, closeUserInviteDialog] = useBoolState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserManagementActions.getUsers());

    return () => {
      handleCloseForm();
    };
  }, [dispatch, handleCloseForm, closeConfirmationDialog, resetSelectedUserIdToDelete]);

  const userToDelete = useSelector(UserManagementSelectors.userById(selectedUserIdToDelete));

  const classes = useStyles();

  return (
    <Box>
      <UserInvitationDialog open={isUserInviteDialogOpen} onClose={closeUserInviteDialog} />
      <UsersTable
        onSelect={onSelectUserIdToEdit}
        onDelete={onSelectUserIdToDelete}
        openUserInviteDialog={openUserInviteDialog}
      />
      <Drawer
        anchor="right"
        variant="persistent"
        onClose={handleCloseForm}
        open={isRightPaneOpen && !!selectedUserIdToEdit}
        PaperProps={{
          square: true,
          className: classes.userFormContainer
        }}
      >
        <UserEditForm userId={selectedUserIdToEdit} onClose={handleCloseForm} />
      </Drawer>
      <UserDeleteDialog
        user={userToDelete}
        onClose={closeConfirmationDialog}
        onExited={resetSelectedUserIdToDelete}
        open={isConfirmationDialogOpen && !!userToDelete}
      />
    </Box>
  );
};

export default UsersPage;
