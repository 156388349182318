import { CourseClient } from 'app/apis/api.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCourseNotificationsQuery = (scheduledCourseId: number) => ({
  queryKey: ['courseNotifications', scheduledCourseId],
  queryFn: async () => {
    const response = await CourseClient.courseGetScheduledCourseNotifications(scheduledCourseId);
    return response.data;
  }
});
