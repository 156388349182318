/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import type { GridColumns } from '@material-ui/data-grid';
import { Box, Button, Grid, IconButton, Tooltip } from '@material-ui/core';

import useDelete from 'app/hooks/useDelete.js';
import { DateFormat } from 'app/app.constants.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import type { CourseDto, ScheduledCourseDto, ScheduledCourseUserDto } from '@/generated-api/index.js';
import CreateScheduledCourseUserDialog from 'app/components/CourseManagement/ScheduledCourse/CreateScheduledCourseUserDialog.js';
import DeleteScheduledCourseUserDialog from 'app/components/CourseManagement/ScheduledCourse/DeleteScheduledCourseUserDialog.js';
import { Add, Delete } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCourseManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

const RowsPerPage = 25;

type ScheduledCourseUsersListProps = {
  courseId: string;
  course: CourseDto;
  scheduledCourse: ScheduledCourseDto;
};

const ScheduledCourseUsersList: React.FC<ScheduledCourseUsersListProps> = ({ course, courseId, scheduledCourse }) => {
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useBoolState(false);

  const formatDate = useFormatDate();
  const [translate] = useTranslation([I18nNamespace.CourseManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const {
    closeConfirmationDialog,
    isConfirmationDialogOpen,
    onSelectItem: onSelectScheduledCourseUser,
    selectedItem: selectedScheduledCourseUser,
    resetSelectedItem: resetSelectedScheduledCourseUser
  } = useDelete<ScheduledCourseUserDto>();

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'name',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.name)),
        flex: 2
      },
      {
        field: 'createdDate',
        headerName: translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.added)),
        flex: 1,
        renderCell: (data) => formatDate(data.value as string, DateFormat.Time)
      },
      {
        width: 50,
        field: 'actions',
        headerName: ' ',
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        renderCell: (data) => {
          const scenario = data.row as ScheduledCourseUserDto;

          return (
            <Tooltip
              title={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
              placement="bottom"
            >
              <IconButton size="small" onClick={() => onSelectScheduledCourseUser(scenario)}>
                <Delete />
              </IconButton>
            </Tooltip>
          );
        }
      }
    ],
    [formatDate, onSelectScheduledCourseUser]
  );

  if (!scheduledCourse) return null;

  return (
    <>
      <SimpleDataGrid
        autoHeight
        loading={false}
        columns={columns}
        pageSize={RowsPerPage}
        hideFooterSelectedRowCount
        rows={scheduledCourse.users}
        isCellEditable={() => false}
        isRowSelectable={() => false}
        disableSelectionOnClick
        rowsPerPageOptions={[RowsPerPage]}
        components={{
          Toolbar: () => (
            <Box padding={1}>
              <Grid container justifyContent="flex-start" wrap="nowrap">
                <Button startIcon={<Add />} variant="outlined" onClick={openCreateDialog}>
                  {translate(nameof.full<I18nCourseManagementNs>((n) => n.scheduledCourseCard.addUser))}
                </Button>
              </Grid>
            </Box>
          )
        }}
      />
      <CreateScheduledCourseUserDialog
        course={course}
        courseId={courseId}
        isOpen={isCreateDialogOpen}
        onClose={closeCreateDialog}
        scheduledCourse={scheduledCourse}
      />
      <DeleteScheduledCourseUserDialog
        course={course}
        courseId={courseId}
        open={isConfirmationDialogOpen}
        onClose={closeConfirmationDialog}
        scheduledCourse={scheduledCourse}
        onExited={resetSelectedScheduledCourseUser}
        scheduledCourseUser={selectedScheduledCourseUser}
      />
    </>
  );
};

export default ScheduledCourseUsersList;
