import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DialogStatisticsClient } from 'app/apis/api.js';
import { getDialogStatisticsFullQuery } from 'app/queries/dialogStatisticsQueries.js';
import { useMemo } from 'react';

export const useDialogStatisticMutations = (statisticId: number | undefined) => {
  const queryClient = useQueryClient();

  const dialogStatisticQuery = useMemo(() => getDialogStatisticsFullQuery(statisticId), [statisticId]);

  const deleteDialogStatistic = useMutation({
    mutationFn: async (id: number) => {
      await DialogStatisticsClient.dialogStatisticsDeleteDialogStatistic(id);
    },
    onSuccess: () => {
      queryClient.removeQueries(dialogStatisticQuery.queryKey);
    }
  });

  return { deleteDialogStatistic };
};
