import React from 'react';
import { ListItem, makeStyles } from '@material-ui/core';

const KPIDetailsListItem: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters className={classes.listItem}>
      {children}
    </ListItem>
  );
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  }
}));

export default KPIDetailsListItem;
